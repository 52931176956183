/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  RiAddLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiDeleteBinLine,
  RiEditLine,
  RiSearchLine,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Ability } from '../../components/ability'
import { AlertDialogConfirm } from '../../components/AlertDialogConfirm'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { SearchBox } from '../../components/SearchBox'
import useQueryParamUpdater from '../../hooks/useQueryParamUpdater'
import { Layout } from '../../layout'
import { Pagination } from '../../layout/Pagination'
import { useDeleteUser } from '../../services/endpoints/users/deleteUser'
import { useGetUsers } from '../../services/endpoints/users/getUsers'
import { handlePrefetchUser } from '../../services/endpoints/users/prefetchUser'
import { UpdateUserModal } from './UpdateUserModal'

interface FilterFormData {
  email?: string
  phone?: string
}

export function ListUsers(): JSX.Element {
  const [page, setPage] = useState(1)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { updateQueryParams, getParams } = useQueryParamUpdater<{
    name?: string
    page?: string
    email?: string
    phone?: string
  }>()
  const { isOpen: isOpenUpdateUser, onClose: onCloseUpdateUser, onOpen: onOpenUpdateUser } = useDisclosure()
  const [userName, setUserName] = useState<string>()
  const [userIdToDelete, setUserIdToDelete] = useState<string>()
  const [orderByName, setOrderByName] = useState<'asc' | 'desc' | undefined>()
  const [orderByDate, setOrderByDate] = useState<'asc' | 'desc' | undefined>()
  const [userIdToUpdate, setUserIdToUpdate] = useState<string>()
  const [name, setName] = useState<string>()
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({})
  const { isSubmitting } = formState
  const [filters, setFilters] = useState<FilterFormData>()

  const {
    data: users,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetUsers({
    ...filters,
    page,
    per_page: 10,
    name,
    orderByName,
    orderByDate,
  })

  const deleteUser = useDeleteUser({ onSuccess: () => refetch() })

  async function handleDeleteUser(user_id: string) {
    const response = await deleteUser.mutateAsync(user_id)
    if (response.status === 204) {
      toast({
        title: `${userName?.toUpperCase()} deletado com sucesso`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      })
    }
  }

  const handleFilter: SubmitHandler<FilterFormData> = async data => {
    const dataFilters = {
      email: data.email,
      phone: data.phone,
    }
    const { email, phone } = dataFilters
    setPage(1)
    setFilters({ email, phone })
  }

  useEffect(() => {
    updateQueryParams({
      name,
      page: page >= 1 ? page.toString() : undefined,
      email: filters?.email ? filters?.email : undefined,
      phone: filters?.phone ? filters?.phone : undefined,
    })
  }, [page, name, filters])

  useEffect(() => {
    const pageQuery = getParams('page')
    const nameQuery = getParams('name')
    const emailQuery = getParams('email')
    const phoneQuery = getParams('phone')

    if (pageQuery) {
      setPage(Number(pageQuery))
    }
    if (nameQuery) {
      setName(nameQuery)
    }
    if (emailQuery) {
      setFilters(prevState => ({ ...prevState, email: emailQuery }))
    }
    if (phoneQuery) {
      setFilters(prevState => ({ ...prevState, phone: phoneQuery }))
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Layout hasBackground>
      <form onSubmit={handleSubmit(handleFilter)} noValidate>
        <Flex
          mb={['6', '8']}
          justify="space-between"
          align="center"
          direction={['column', 'row']}
          gridGap={2}
        >
          <Heading size="lg" fontWeight="normal">
            Usuários
            {!isLoading && isFetching && <Spinner size="sm" ml="4" />}
          </Heading>

          <Ability module="account" action="create-user">
            <Button
              as={Link}
              to="/users/create"
              size="sm"
              colorScheme="orange"
              leftIcon={<Icon as={RiAddLine} fontSize="18" />}
            >
              Novo
            </Button>
          </Ability>
        </Flex>
      </form>

      <form onSubmit={handleSubmit(handleFilter)} noValidate>
        <Grid templateColumns="repeat(12, 1fr)" gap="3" mb={4}>
          <GridItem colSpan={[12, 12, 4]}>
            <SearchBox
              defaultValue={name}
              onChange={async v => {
                if (v) {
                  setName(v)
                  setPage(1)
                } else if (v.length === 0) setName(undefined)
              }}
              placeholder="Filtrar por nome"
              clearState={setName}
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 4]}>
            <Input
              name="email"
              defaultValue={filters?.email}
              initialValue={filters?.email}
              setValue={setValue}
              onChange={() => {
                setPage(1)
              }}
              placeholder="Filtrar por email"
            />
          </GridItem>

          <GridItem colSpan={[12, 5, 3]}>
            <InputMask
              mask="(99) 9 9999-9999"
              registerOnlyNumbers
              maskPlaceholder=""
              name="phone"
              initialValue={filters?.phone}
              defaultValue={filters?.phone}
              setValue={setValue}
              placeholder="Filtrar por telefone"
            />
          </GridItem>

          <GridItem colSpan={[12, 1]} mt={2}>
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </GridItem>
        </Grid>
      </form>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex flexDir="column" justify="center" align="center" gridGap={4} my="44">
          <Heading size="md">Nenhum resultado encontrado</Heading>
          <Text>Por favor, tente novamente ou verifique os filtros informados</Text>
          <Flex gridGap={2}>
            <Button onClick={() => refetch()} colorScheme="blue">
              Tentar novamente
            </Button>
            <Button
              onClick={() => {
                setFilters({ email: undefined, phone: undefined })
                setName(undefined)
              }}
              variant="outline"
              colorScheme="orange"
            >
              Limpar filtros
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Table d={['block', 'block', 'table']} overflowX="auto" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByName('asc')
                        setOrderByDate(undefined)

                        if (orderByName && orderByName.includes('asc')) {
                          setOrderByName('desc')
                          setOrderByDate(undefined)
                        }
                      }}
                      cursor="pointer"
                    >
                      Usuários
                    </Box>
                    {orderByName && (
                      <>
                        {orderByName.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByName.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>
                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByDate('asc')
                        setOrderByName(undefined)

                        if (orderByDate && orderByDate.includes('asc')) {
                          setOrderByDate('desc')
                          setOrderByName(undefined)
                        }
                      }}
                      cursor="pointer"
                    >
                      Data de cadastro
                    </Box>
                    {orderByDate && (
                      <>
                        {orderByDate.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByDate.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {users &&
                users.data.map(user => (
                  <Tr key={user.id}>
                    <Td>
                      <Box>
                        <Text fontWeight="bold">{user.name}</Text>
                        <Text fontSize="sm" color="gray.400">
                          {user.email}
                        </Text>
                      </Box>
                    </Td>
                    <Td>{user.created_at}</Td>

                    <Td textAlign="right">
                      <HStack spacing={['2', '4']} justifyContent="flex-end">
                        <Ability module="account" action="update-user">
                          <Button
                            size="xs"
                            colorScheme="yellow"
                            onMouseEnter={() => handlePrefetchUser(user.id)}
                            onClick={() => {
                              setUserIdToUpdate(user.id)
                              onOpenUpdateUser()
                            }}
                          >
                            <Icon as={RiEditLine} fontSize={16} />
                          </Button>
                        </Ability>

                        <Ability module="account" action="delete-user">
                          <Button
                            size="xs"
                            colorScheme="red"
                            onClick={() => {
                              setUserIdToDelete(user.id)
                              setUserName(user.name)
                              onOpen()
                            }}
                            isLoading={deleteUser.isLoading && user.id === userIdToDelete}
                          >
                            <Icon as={RiDeleteBinLine} fontSize={16} />
                          </Button>
                        </Ability>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          <Pagination
            currentPage={page}
            totalCountOfRegisters={Number(users?.total)}
            registersPerPage={10}
            onPageChange={setPage}
            mt="8"
          />
        </>
      )}

      {userIdToDelete && userName && (
        <AlertDialogConfirm
          isOpen={isOpen}
          title="Apagar usuário"
          description={`Tem certeza que deseja excluir ${userName.toUpperCase()} ?`}
          onClose={onClose}
          onConfirm={() => handleDeleteUser(userIdToDelete)}
        />
      )}

      {userIdToUpdate && (
        <UpdateUserModal
          onClose={() => {
            onCloseUpdateUser()
            refetch()
          }}
          isOpen={isOpenUpdateUser}
          user_id={userIdToUpdate}
        />
      )}
    </Layout>
  )
}
