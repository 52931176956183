import { Alert, AlertIcon, AlertTitle, Flex, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { IoMdAdd } from 'react-icons/io'
import { AlertModal } from '../../../../components/AlertModal'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { VehicleResponseFormData } from '../../../../services/endpoints/vehicles/getVehicle'
import { usePostVehicle, VehiclePayload } from '../../../../services/endpoints/vehicles/postVehicle'
import { useCreateAttachmentToVehicle } from '../../../../services/endpoints/vehicles/vehicle-attachments/createVehicleAttachment'
import { useUpdateAttachmentToVehicle } from '../../../../services/endpoints/vehicles/vehicle-attachments/updateAttachment'
import { ResponseOcrCrlv } from '../../../../services/types'
import { addMoreOneParamToUrl } from '../../../../services/utils/format-url/addMoreOneParamToUrl'
import { alterColorsGenderDatamex } from '../../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setVehicleId } from '../../../../store/slices/freightPendenciesSlice'
import { setCrlvVehicleFile, setCrlvVehicleImg, setIsNewImage } from '../../../../store/slices/ocrCrlvSlice'
import { DrawerWrapper } from '../components/DrawerWrapper'
import { VehicleSearch } from '../components/VehicleSearch'
import {
  VehicleForm,
  vehicleFormInitialValues,
  VehicleFormValues,
  VehicleSchema,
} from '../../../../components/forms/vehicle'
import { createCityInitialValue } from '../utils'
import UploadCRLVModal from './UploadCRLV.modal'

type MotoristFormProps = {
  isOpen: boolean
  onClose: () => void
  // vehicle?: VehicleResponseFormData
}

const VehicleModalForm = ({ isOpen, onClose }: MotoristFormProps): JSX.Element => {
  const [vehicleFound, setVehicleFound] = useState<VehicleResponseFormData | null>(null)
  const { vehicle, motorist, trailers } = useAppSelector(state => state.freightPendenciesSlice)
  const { crlvVehicleFile, isNewImage } = useAppSelector(state => state.ocrCrlvSlice)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const methods = useForm<VehicleFormValues>({
    mode: 'onChange',
    defaultValues: vehicleFormInitialValues,
    resolver: yupResolver(VehicleSchema),
  })

  // Get data for blocked entities
  const { data: blockedEntity } = useIsBlockEntity({
    vehicle_id: vehicle?.id,
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = methods

  const { mutateAsync: createVehicle } = usePostVehicle()
  const { mutateAsync: createAttachmentVehicle } = useCreateAttachmentToVehicle({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
      dispatch(setCrlvVehicleFile({} as File))
    },
  })
  const { mutateAsync: updateAttachmentVehicle } = useUpdateAttachmentToVehicle({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
      dispatch(setCrlvVehicleFile({} as File))
    },
  })

  // Function to show a success toast
  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const linkVehicleToMotorist = async (motoristId?: string, vehicleId?: string) => {
    if (!motoristId || !vehicleId) {
      toast({
        title: 'Falha ao vincular veículo e motorista, por favor tente novamente',
        description: 'Motorista ou veículo inexistente',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }
    try {
      await apiServer.post('/motorist-attach-vehicle', {
        motorist_id: motoristId,
        vehicle_id: vehicleId,
      })

      toast({
        title: 'Vinculado veículo e motorista com sucesso',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  const handleAttachmentUpload = async (vehicle_id: string) => {
    if (crlvVehicleFile?.name) {
      const attachment = vehicleFound?.attachments?.find(item => item.type === 'crlv')
      if (attachment?.id) {
        await updateAttachmentVehicle({
          id: attachment.id,
          attachment_file: crlvVehicleFile,
          vehicle_id,
        })
      } else {
        await createAttachmentVehicle({
          attachment_file: crlvVehicleFile,
          vehicle_id,
          name: 'Crlv do veículo',
          type: 'crlv',
        })
      }
    }
  }

  const onSubmit: SubmitHandler<VehicleFormValues> = async data => {
    if (blockedEntity) {
      toast({
        status: 'error',
        title: 'Edição bloqueada',
        description: 'Edição bloqueada. Solicite correção da análise de risco.',
        position: 'top-right',
        duration: 8000,
      })
      return
    }

    const payload = {
      license_plate: data.license_plate.toUpperCase(),
      city_id: data.city_id.value,
      antt: data.license_plate.toUpperCase(),
      axes: data.axes,
      brand: data.brand,
      capacity_kg: data.capacity_kg,
      capacity_m3: data.capacity_m3,
      capacity_tara: data.capacity_tara,
      chassi: data.chassi,
      color: data.color,
      has_insurance: !!data.has_insurance,
      has_tracker: !!data.has_tracker,
      license_uf: data.license_uf,
      model: data.model,
      model_year: data.model_year,
      release_year: data.release_year,
      renavam: data.renavam,
      vehicle_body_id: data.vehicle_body_id,
      vehicle_category_id: data.vehicle_category_id,
    } as VehiclePayload

    if (vehicleFound?.id) {
      try {
        await createVehicle({
          id: vehicleFound.id,
          ...payload,
        })
        dispatch(setVehicleId(vehicleFound.id))
        showSuccessToast('Veículo atualizado com sucesso!')
        await linkVehicleToMotorist(motorist?.id, vehicleFound.id)
        await handleAttachmentUpload(vehicleFound.id)
        addMoreOneParamToUrl('vehicle_id', vehicleFound.id)
        reset({
          ...data,
        })
        onClose()
      } catch (error) {
        toastError({ toast, error })
      } finally {
        queryClient.invalidateQueries('vehicle')
        queryClient.invalidateQueries('check-pendencies')
      }
    } else {
      try {
        const { data: vehicleCreated } = await createVehicle(payload)
        const vehicle_id = vehicleCreated.data.id
        showSuccessToast('Veículo cadastrado e vinculado ao motorista com sucesso!')
        await linkVehicleToMotorist(motorist?.id, vehicle_id)
        await handleAttachmentUpload(vehicle_id)
        dispatch(setVehicleId(vehicle_id))
        addMoreOneParamToUrl('vehicle_id', vehicle_id)
        queryClient.invalidateQueries('vehicle')
        onClose()
      } catch (error) {
        toastError({ toast, error })
      } finally {
        queryClient.invalidateQueries('check-pendencies')
      }
    }
  }

  const handleClose = () => {
    if (isDirty) {
      toast({
        title: 'Alterações não salvas',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (isNewImage && crlvVehicleFile?.name) {
      toast({
        title: 'Alterações não salvas, nova CRLV',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    onClose()
  }

  const handleGetValuesByCRLV = (CRLV: Partial<ResponseOcrCrlv>) => {
    if (CRLV) {
      reset({
        ...getValues(),
        license_plate: CRLV.licensePlate,
        antt: CRLV.licensePlate,
        release_year: CRLV.manufactureYear,
        model_year: CRLV.modelYear,
        axes: CRLV.axes ? Number(CRLV.axes) : undefined,
        capacity_kg: CRLV.capacity ? Number(CRLV.capacity) * 1000 : undefined,
        brand: CRLV.brandName,
        chassi: CRLV.chassis,
        color: CRLV.predominantColor,
        model: CRLV.brandModelVersion,
        renavam: CRLV.renavam,
      })
    }
  }

  const checkIfRenavamIsTypeTrailer = () => {
    const trailersRevam = trailers?.map(item => item?.renavam)
    if (!trailersRevam) return
    if (trailersRevam?.includes(getValues('renavam'))) {
      return (
        <Alert status="error" rounded="md">
          Renavam do veículo é do tipo de tração
        </Alert>
      )
    }
  }

  // Function to create initial values for the form
  const createInitialValues = useCallback(
    (vehicleData?: VehicleResponseFormData) => {
      if (vehicleData) {
        const { city, attachments } = vehicleData

        const attach = attachments?.find(item => item.type === 'crlv')
        if (attach) {
          dispatch(setCrlvVehicleImg(attach?.attachment_file_url || 'placeholder.png'))
        } else {
          dispatch(setCrlvVehicleImg(''))
        }

        const capitalizeWord = (word: string) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }

        reset({
          license_plate: vehicleData.license_plate || '',
          renavam: vehicleData.renavam || undefined,
          brand: vehicleData.brand || '',
          color: capitalizeWord(alterColorsGenderDatamex(vehicleData.color)) || '',
          model: vehicleData.model || '',
          model_year: vehicleData.model_year?.toString() || '',
          chassi: vehicleData.chassi || '',
          release_year: vehicleData.release_year?.toString() || '',
          antt: vehicleData.license_plate || '',
          license_uf: vehicleData.license_uf || '',
          has_insurance: vehicleData.has_insurance || false,
          has_tracker: vehicleData.has_tracker || false,
          vehicle_category_id: vehicleData?.vehicle_category.id || '',
          vehicle_body_id: vehicleData?.vehicle_body.id || '',
          axes: vehicleData?.axes || undefined,
          capacity_kg: vehicleData?.capacity_kg || undefined,
          capacity_m3: vehicleData?.capacity_m3 || undefined,
          capacity_tara: vehicleData?.capacity_tara || undefined,
          ...(!!city && {
            city_id: createCityInitialValue(city),
          }),
        })
      }
    },
    [dispatch, reset],
  )

  // useEffect to set initial values
  useEffect(() => {
    if (vehicle) {
      setVehicleFound(vehicle)
      createInitialValues(vehicle)
    }
  }, [reset, dispatch, vehicle, createInitialValues])

  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        onClose={handleClose}
        onForceClose={() => {
          onClose()
          dispatch(setCrlvVehicleFile({} as File))
        }}
        title="Cadastrar veículo"
        onSave={() => handleSubmit(onSubmit)()}
        isSubmitting={isSubmitting}
        showForceClose={isDirty}
      >
        <Flex mb={2} gridGap={2} flexDirection={{ base: 'column', md: 'row' }}>
          <VehicleSearch
            onVehicleFound={data => {
              if (data) {
                setVehicleFound(data)
                createInitialValues(data)
              }
            }}
          />
          <AlertModal
            size="md"
            titleButton="Novo Veículo"
            title="Deseja Criar um novo Veículo ?"
            description="Tem certeza que deseja criar um novo Veículo ?"
            colorScheme="green"
            leftIcon={<IoMdAdd />}
            onConfirm={() => {
              setVehicleFound(null)
              reset(vehicleFormInitialValues)
            }}
          />
        </Flex>
        <UploadCRLVModal
          onSaveInformations={handleGetValuesByCRLV}
          onVehicleFound={data => {
            if (data) {
              setVehicleFound(data)
              createInitialValues(data)
            }
          }}
        />
        {blockedEntity && (
          <Alert variant="subtle" status="error" borderRadius="md">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="sm">
              Edição bloqueada. Solicite correção da análise de risco
            </AlertTitle>
          </Alert>
        )}
        {checkIfRenavamIsTypeTrailer()}
        <FormProvider {...methods}>
          <VehicleForm
            disabled={blockedEntity}
            type="vehicle"
            onVehicleFound={data => {
              if (data) {
                setVehicleFound(data)
                createInitialValues(data)
              }
            }}
          />
        </FormProvider>
      </DrawerWrapper>
    </>
  )
}

export default VehicleModalForm
