import { Grid, GridItem, GridProps, Heading, useColorModeValue } from '@chakra-ui/react'
import { format, formatDistance } from 'date-fns'
import localeBR from 'date-fns/locale/pt-BR'
import React from 'react'
import InfoDetails from '../../../../components/InfoDetails/InfoDetails'
import { FormattedOneFreight } from '../../../../services/endpoints/freights'
import { useGetFreightHistory } from '../../../../services/endpoints/freights/getHistoryFreight'

interface FreightEstimateProps extends GridProps {
  freight: FormattedOneFreight
}

const FreightEstimate = ({ freight, ...rest }: FreightEstimateProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  let calcSlaCollection = ''
  let calcSlaDelivery = ''
  const { data: history } = useGetFreightHistory(freight.id)
  const statusCollection = history?.find(h => h.status === 'collecting')
  if (statusCollection && statusCollection.created_at_original && freight?.estimated_collection_date) {
    calcSlaCollection = formatDistance(
      new Date(String(statusCollection?.created_at_original)),
      new Date(String(freight?.estimated_collection_date)),
      { locale: localeBR },
    )
  }

  const statusDelivery = history?.find(h => h.status === 'delivered')
  if (statusDelivery && statusDelivery.created_at_original && freight?.delivered_cargo_at) {
    calcSlaDelivery = formatDistance(
      new Date(String(statusDelivery?.created_at_original)),
      new Date(String(freight?.delivered_cargo_at)),
      { locale: localeBR },
    )
  }

  return (
    <Grid p="4" borderRadius="md" bg={bg} gap="4" templateColumns="repeat(12, 1fr)" mt="4" {...rest}>
      <GridItem colSpan={[12]} d="flex" justifyContent="space-between" alignItems="center">
        <Heading fontSize="md" fontWeight="medium">
          Estimativas:
        </Heading>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <InfoDetails
          label="Estimativa de coleta:"
          content={
            freight?.estimated_collection_date
              ? format(new Date(String(freight?.estimated_collection_date)), 'dd/MM/yyyy - HH:mm')
              : ''
          }
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <InfoDetails
          label="Estimativa de entrega:"
          content={
            freight?.estimated_delivery_date
              ? format(new Date(String(freight?.estimated_delivery_date)), 'dd/MM/yyyy - HH:mm')
              : ''
          }
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <InfoDetails label="SLA de coleta:" content={calcSlaCollection} />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <InfoDetails label="SLA de entrega:" content={calcSlaDelivery} />
      </GridItem>
    </Grid>
  )
}

export default FreightEstimate
