import { Button, useColorModeValue } from '@chakra-ui/react'

interface PaginationItemProps {
  number: number
  isCurrent?: boolean
  onPageChange: (page: number) => void
}

export function PaginationItem({
  number,
  isCurrent = false,
  onPageChange,
}: PaginationItemProps): JSX.Element {
  const bg = useColorModeValue('gray.100', 'gray.700')

  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        w="4"
        colorScheme="orange"
        disabled
        _disabled={{
          cursor: 'default',
        }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button size="sm" fontSize="xs" w="4" bg={bg} onClick={() => onPageChange(number)}>
      {number}
    </Button>
  )
}
