import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleType } from '../../types'

type Input = Partial<VehicleType & { disable_entities_block?: boolean }>
export async function updateVehicle({
  id,
  ...data
}: Input): Promise<AxiosResponse<ResponseApiType<VehicleType>>> {
  return apiServer.put(`/update-vehicle/${id}`, { ...data })
}

export function usePutVehicle(
  options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>,
): UseMutationResult<AxiosResponse<ResponseApiType<VehicleType>>, unknown, Input, unknown> {
  return useMutation(async (data: Input) => updateVehicle(data), options)
}
