import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightStubsType, ResponseApiType } from '../../types'

type PostStubParams = {
  freight_id: string
  created_at: Date
  attachments: File[]
}

type ResponseApi = {
  data: FreightStubsType
}

async function createStub(data: PostStubParams): Promise<ResponseApi> {
  const formData = new FormData()

  formData.append('created_at', data.created_at.toISOString())
  formData.append('freight_id', data.freight_id)

  data.attachments.forEach(attachment => {
    formData.append('attachments', attachment)
  })

  const { data: response } = await apiServer.post<ResponseApiType<ResponseApi>>('/stubs', formData)

  return response.data
}

export function usePostStub(
  options?: UseMutationOptions<ResponseApi, unknown, PostStubParams, unknown>,
): UseMutationResult<ResponseApi, unknown, PostStubParams, unknown> {
  return useMutation(async (data: PostStubParams) => createStub(data), options)
}
