import { Box, Tooltip, Icon, ButtonGroupProps } from '@chakra-ui/react'
import { RiUser2Fill } from 'react-icons/ri'
import { Ability } from '../../../../components/ability'
import { useAppDispatch } from '../../../../store'
import { setOpen, setReasonStatusModalProps } from '../../../../store/slices/riskAnalysisSlice'
import { ReasonStatusModal } from '../ReasonStatusModal'

type ButtonProps = ButtonGroupProps

export default function ToForceApprovalIcon({ ...props }: ButtonProps): JSX.Element {
  const dispatch = useAppDispatch()
  return (
    <Ability module="spotHub" action="force-approve-risk-analysis">
      <Box {...props}>
        <Tooltip hasArrow label="Forçar Aprovação">
          <Box
            cursor="pointer"
            color="purple.500"
            onClick={() => {
              dispatch(setOpen())
              dispatch(
                setReasonStatusModalProps({
                  title: 'Forçar a aprovação do motorista',
                  status: 'approved',
                  consultation_code: 'PERFIL ADEQUADO AO RISCO',
                }),
              )
            }}
          >
            <Icon fontSize="2xl" as={RiUser2Fill} />
          </Box>
        </Tooltip>

        <ReasonStatusModal />
      </Box>
    </Ability>
  )
}
