import { Box, Flex, Heading, Spinner, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { format, startOfMonth } from 'date-fns'
import { useEffect } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDailyClosureExpectation } from '../../../../services/endpoints/datamex/getDailyClosureExpectation'
import { useGetTotalCteValues } from '../../../../services/endpoints/datamex/getTotalCteValues'
import parseCurrency from '../../../../services/utils/parseCurrency'

type DisplayValueProps = {
  value: string
  label: string
  count: string
}

const DisplayValue = ({ value, label, count }: DisplayValueProps) => {
  return (
    <Box>
      <Heading color="gray.500" fontWeight="normal" fontSize="sm">
        {label}
      </Heading>
      <Flex mt="1" fontWeight="medium" fontSize="sm">
        <Text>{value}</Text>
        <Text size="8px" ml="1" color="gray.500">
          ({count})
        </Text>
      </Flex>
    </Box>
  )
}

export function ComercialCard(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { user } = useAuth()

  const initialMonthDate = () => {
    const now = startOfMonth(new Date())
    return format(now, 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const { getSellerIdToListFreights } = useAuth()

  const { data: monthCteValues, isLoading: monthCteValuesLoading } = useGetTotalCteValues({
    emit_date_ini: `${initialMonthDate()}`,
    emit_date_final: `${finalDate()}`,
    seller: user.name,
  })
  const { data: dayCteValues, isLoading: dayCteValuesLoading } = useGetTotalCteValues({
    emit_date_ini: `${finalDate()}`,
    emit_date_final: `${finalDate()}`,
    seller: user.name,
  })

  useEffect(() => {
    getSellerIdToListFreights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const splitEmail = (email: string) => {
    return email.split('@')[0]
  }

  const { data: dataDailyClosureExpectation } = useDailyClosureExpectation({
    seller: splitEmail(user.email),
  })

  if (user.role !== 'seller') return <></>

  if (monthCteValuesLoading || dayCteValuesLoading) {
    return (
      <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md" overflow="auto" maxH="440">
        <Spinner />
      </Box>
    )
  }
  return (
    <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md" overflow="auto" maxH="440">
      <Box justifyContent="space-between" mt={1} alignItems="baseline" mb="4" fontWeight="bold">
        <Text fontSize="lg">Meu Faturamento Comercial</Text>
        <Text fontSize="sm" color="gray.500" fontWeight="light">
          {user.name}
        </Text>
      </Box>
      <VStack spacing="2" align="stretch">
        <Flex gridGap="2" flexDir="column">
          {monthCteValues && (
            <DisplayValue
              count={monthCteValues ? `${monthCteValues.totalFreights} Fretes` : ''}
              value={monthCteValues ? parseCurrency(monthCteValues?.totalValue) : 'R$ 0,00'}
              label="Faturamento do mês"
            />
          )}
        </Flex>
        <Flex gridGap="2">
          {dayCteValues && (
            <DisplayValue
              count={dayCteValues ? `${dayCteValues.totalFreights} Fretes` : ''}
              value={dayCteValues ? parseCurrency(dayCteValues?.totalValue) : 'R$ 0,00'}
              label="Faturamento do dia"
            />
          )}
        </Flex>
        <Flex gridGap="2">
          <Box>
            <Heading color="gray.500" fontWeight="normal" fontSize="sm">
              Fechamento esperado do dia;
            </Heading>
            <Text mt="1" fontWeight="medium" fontSize="sm">
              {dataDailyClosureExpectation ? (
                <Text fontSize="sm">{parseCurrency(dataDailyClosureExpectation)}</Text>
              ) : (
                'R$ 0,00'
              )}
            </Text>
          </Box>
        </Flex>
      </VStack>
    </Box>
  )
}
