import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import useClickOutside from '../../hooks/useClickOutside'

type Option = {
  label: string
  value: string | number
  [key: string]: any
}

type UISelectAutoProps = {
  name?: string
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  options?: Option[]
  value?: Option
  onChange?: (value: Option) => void
  placeholder?: string
}

export const UISelectAuto = ({
  helperText,
  isRequired,
  isError,
  errorMessage,
  label,
  options,
  onChange,
  value,
  placeholder,
  name,
}: UISelectAutoProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [optionsFiltres, setOptionsFiltres] = useState<Option[]>(options?.slice(0, 10) || [])
  const [inputValue, setInputValue] = useState<string>(value?.label || '')
  const containerRef = useRef<HTMLDivElement>(null)

  useClickOutside(containerRef, () => {
    if (isOpen) {
      onClose()
    }
  })

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value === '') {
      setInputValue(e.target.value)
      onChange?.({
        label: '',
        value: '',
      })
      return
    }

    setInputValue(e.target.value)
    if (e.target.value) {
      setOptionsFiltres(
        options?.filter(option => option.label.toUpperCase().includes(e.target.value.toUpperCase())) || [],
      )
    }
  }

  useEffect(() => {
    if (value) {
      setInputValue(value.label)
    }
  }, [value])

  return (
    <Box ref={containerRef}>
      <Popover
        matchWidth
        flip
        preventOverflow
        boundary="scrollParent"
        autoFocus={false}
        isOpen={isOpen}
        placement="bottom"
        isLazy
      >
        <FormControl isInvalid={isError} isRequired={isRequired}>
          {label && (
            <FormLabel fontSize="14" mb="1.5">
              {label}
            </FormLabel>
          )}
          <PopoverTrigger>
            <InputGroup>
              <Input
                name={name}
                px="2"
                onFocus={() => {
                  onOpen()
                }}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                focusBorderColor="orange.500"
              />
              <InputRightElement pointerEvents="none" w="fit-content" px="2">
                <Icon ml="2" as={isOpen ? RiArrowUpSLine : RiArrowDownSLine} />
              </InputRightElement>
            </InputGroup>
          </PopoverTrigger>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </FormControl>

        <PopoverContent w="100%">
          <PopoverBody w="100%" maxH="300px" overflowY="auto" px="0">
            <SimpleGrid columns={1} spacing={1} px="0" w="100%">
              {optionsFiltres.length === 0 && (
                <Box as="p" px="2">
                  Nenhum resultado encontrado
                </Box>
              )}
              {optionsFiltres.map(option => (
                <Button
                  key={option.value}
                  value={option.value}
                  rounded="none"
                  variant="ghost"
                  aria-label={option.label}
                  onClick={() => {
                    setInputValue(option.label)
                    onChange?.(option)
                    onClose()
                    setOptionsFiltres(options?.slice(0, 10) || [])
                  }}
                  textAlign="left"
                  justifyContent="left"
                  fontWeight="normal"
                  fontSize="14"
                  py="2"
                  textOverflow="ellipsis"
                  w="100%"
                >
                  {option.label}
                </Button>
              ))}
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
