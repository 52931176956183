import { queryClient } from '../../../config/react-query'
import { fetchUser } from './getUser'

// PREFETCH DOS DADOS (Carregar com antecedencia e guardar em estado global por 10 minutos)
export async function handlePrefetchUser(user_id: string): Promise<void> {
  await queryClient.prefetchQuery(
    ['user', user_id],
    async () => {
      const res = await fetchUser(user_id)
      return res
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  )
}
