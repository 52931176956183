/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, GridItem } from '@chakra-ui/react'
import { FieldValues, FormState } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form'
import { Input, Select, InputPassword, TextArea } from '../../../components/form'
import { FormattedOneFreight } from '../../../services/endpoints/freights'
import { TrackerDeviceType } from '../../../services/types/TrackerDevicesType'

export interface FormCreateOrEditPros {
  freight: FormattedOneFreight
  tracker?: TrackerDeviceType
  formState: FormState<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

export function FormCreateOrEdit({
  freight,
  tracker,
  formState,
  setValue,
}: FormCreateOrEditPros): JSX.Element {
  const { errors } = formState
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap="4"
      disableAutocomplete
      disableGoogleAutocomplete
      autoComplete="off"
    >
      <GridItem colSpan={[12, 6]}>
        <Input
          label="Marca"
          type="text"
          name="brand"
          initialValue={tracker?.brand}
          error={errors.brand}
          setValue={setValue}
          isRequired
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Select
          label="Tipo"
          name="type"
          setValue={setValue}
          isRequired
          options={
            freight.vehicle_id
              ? [
                  { label: '', value: '' },
                  { label: 'Isca', value: 'BAIT' },
                  { label: 'Rastreador', value: 'TRACKER' },
                  { label: 'Localizador', value: 'LOCALIZER' },
                  { label: 'Híbrido', value: 'HYBRID' },
                ]
              : [
                  { label: '', value: '' },
                  { label: 'Isca', value: 'BAIT' },
                ]
          }
          initialValue={tracker?.type}
          error={errors.type}
        />
      </GridItem>

      <GridItem colSpan={[12]}>
        <Input
          label="Link"
          type="text"
          name="link"
          initialValue={tracker?.link}
          error={errors.link}
          setValue={setValue}
          isRequired
          autoComplete="off"
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Input
          label="Login"
          type="text"
          name="login"
          setValue={setValue}
          initialValue={tracker?.login}
          error={errors.login}
          autoComplete="off"
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <InputPassword
          label="Senha"
          name="password"
          setValue={setValue}
          initialValue={tracker?.password}
          error={errors.password}
        />
      </GridItem>

      <GridItem colSpan={[12]}>
        <TextArea
          label="Observação"
          name="note"
          setValue={setValue}
          initialValue={tracker?.note}
          error={errors.note}
          autoComplete="off"
          placeholder="Adicione uma observação aqui..."
        />
      </GridItem>
    </Grid>
  )
}
