import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { GiRadarDish } from 'react-icons/gi'

interface IconHasMonitoringBuonnyProps {
  has_monitoring_buonny?: boolean
  fontSize?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'large'
    | 'medium'
    | 'small'
    | 'x-large'
    | 'x-small'
    | 'xx-large'
    | 'xx-small'
    | 'xxx-large'
}

export function IconHasMonitoringBuonny({
  has_monitoring_buonny,
  fontSize = 'md',
}: IconHasMonitoringBuonnyProps): JSX.Element {
  return (
    <>
      {has_monitoring_buonny && (
        <Tooltip hasArrow label="Frete monitorado pelo rastreador">
          <Flex>
            <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
              <Flex direction="row">
                <Icon as={GiRadarDish} fontSize={fontSize} mr="1" />
              </Flex>
            </Text>
          </Flex>
        </Tooltip>
      )}
    </>
  )
}
