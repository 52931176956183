import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image,
  Icon,
  ModalFooter,
  Button,
  Flex,
} from '@chakra-ui/react'
import { useState } from 'react'
import { BsArrowClockwise, BsTrash3 } from 'react-icons/bs'

type DeleteStubModalProps = {
  isOpen: boolean
  onClose: () => void
  stub: {
    attachment_file_url: string
    handleDeleteStub: () => Promise<void>
  }
}

export function DeleteStubModal({ isOpen, onClose, stub }: DeleteStubModalProps): JSX.Element {
  const [rotateImage, setRotateImage] = useState(0)

  function handleRotateImage(): void {
    if (rotateImage === 270) {
      setRotateImage(0)
    } else {
      setRotateImage(prevState => prevState + 90)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody mt={2}>
          <Image
            src={stub.attachment_file_url}
            w="100%"
            maxH="500px"
            borderRadius={5}
            style={{ rotate: `${rotateImage}deg` }}
          />
          <Flex justify="flex-end" mt={2}>
            <Button size="xs" colorScheme="orange" onClick={handleRotateImage}>
              <Icon as={BsArrowClockwise} fontSize="18" />
            </Button>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>

          <Button
            colorScheme="red"
            leftIcon={<Icon as={BsTrash3} />}
            onClick={() => {
              stub.handleDeleteStub()
              onClose()
            }}
          >
            Deletar canhoto
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
