/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

async function deleteFreightExpense(id: string) {
  const output = await apiServer.delete(`/delete-freight-expense/${id}`)
  queryClient.invalidateQueries('freight')
  queryClient.invalidateQueries('list-all-financial-title')
  queryClient.invalidateQueries('list-all-financial-title-history')
  return output
}

export function useDeleteFreightExpense(
  options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>,
) {
  return useMutation(async (id: string) => deleteFreightExpense(id), options)
}
