import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Icon,
  ModalFooter,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine, RiFileCopyLine } from 'react-icons/ri'
import * as yup from 'yup'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { FormattedOneFreight } from '../../../services/endpoints/freights'
import { ReturnFormattedTrackerDevices } from '../../../services/endpoints/tracker-devices/getTrackerDevices'
import { useCreateTrackerDevice } from '../../../services/endpoints/tracker-devices/postTrackerDevices'
import { useUpdateTrackerDevice } from '../../../services/endpoints/tracker-devices/putTrackerDevices'
import { FormCreateOrEdit } from '../forms/FormCreateOrEdit'

const formSchemaValidator = yup.object().shape({
  brand: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  link: yup.string().required('Campo obrigatório'),
  login: yup.string(),
  password: yup.string(),
  note: yup.string(),
})

export interface FormTrackerDeviceType {
  brand: string
  type: string
  link: string
  login: string
  password: string
  note: string
}

type CreateOrEditModalProps = {
  isOpen: boolean
  onClose: () => void
  freight: FormattedOneFreight
  tracker?: ReturnFormattedTrackerDevices
}

export function CreateOrEditModal({
  freight,
  isOpen,
  onClose,
  tracker,
}: CreateOrEditModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(formSchemaValidator),
  })
  const createTrackerDevice = useCreateTrackerDevice({
    onSuccess: () => {
      queryClient.invalidateQueries('list-tracker-devices')
    },
  })
  const updateTrackerDevice = useUpdateTrackerDevice({
    onSuccess: () => {
      queryClient.invalidateQueries('list-tracker-devices')
    },
  })

  const handleSubmitTrackerDevice = useCallback<SubmitHandler<FormTrackerDeviceType>>(
    async data => {
      if (tracker) {
        try {
          await updateTrackerDevice.mutateAsync({
            id: tracker.id,
            freight_id: data.type === 'BAIT' ? freight.id : null,
            vehicle_id: data.type !== 'BAIT' ? freight.vehicle_id : null,
            ...data,
          })
          toast({
            title: 'Dispositivo atualizado com sucesso!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          })
          onClose()
        } catch (error) {
          toastError({ toast, error })
        }
      } else {
        try {
          await createTrackerDevice.mutateAsync({
            ...data,
            freight_id: data.type === 'BAIT' ? freight.id : null,
            vehicle_id: data.type !== 'BAIT' ? freight.vehicle_id : null,
          })
          toast({
            title: 'Dispositivo verificado com sucesso!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          })
          onClose()
        } catch (error) {
          toastError({ toast, error })
        }
      }
    },
    [createTrackerDevice, freight.id, freight.vehicle_id, onClose, toast, tracker, updateTrackerDevice],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="2xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleSubmitTrackerDevice)} noValidate>
        <ModalHeader fontSize="xl">{tracker ? 'Editar Dispositivo' : 'Cadastrar Dispositivo'}</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <FormCreateOrEdit freight={freight} formState={formState} setValue={setValue} tracker={tracker} />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme={tracker ? 'orange' : 'blue'}
            leftIcon={<Icon as={tracker ? RiEditLine : RiFileCopyLine} />}
            isLoading={formState.isSubmitting}
          >
            {tracker ? 'Editar' : 'Cadastrar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
