import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { CalcAverage } from '../../../../components/CalcAverage'
import { FormattedQuotation } from '../../../../services/endpoints/freights/getAllQuotations'
import { RoutePricesFormatted } from '../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import { ListRoutePrices } from '../list'

interface changeStatusProps {
  isOpen: boolean
  onClose: () => void
  prices?: RoutePricesFormatted[]
  quotation: FormattedQuotation
}

export function ShowListRoutePricesModal({
  isOpen,
  onClose,
  prices,
  quotation,
}: changeStatusProps): JSX.Element {
  const values = prices?.map(price => price.value)
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex gridGap={3} direction="column">
            <Heading size="lg">Valores do motorista</Heading>
            <Box>Média: {values && <CalcAverage values={values} total={values.length} />}</Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ListRoutePrices quotation={quotation} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
