import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import { RiCheckLine, RiCloseFill } from 'react-icons/ri'
import { FormattedOneFreight } from '../../services/endpoints/freights'
import { FormattedFreightStatusHistory } from '../../services/endpoints/freights/getHistoryFreight'
import { freightStatusInProgressOptions } from '../../services/endpoints/freights/getFreights'
import { getLabelFromAutoComplete } from '../../services/utils/getLabelAutoComplete'
import { FreightStatusHistoryHandler } from './FreightStatusHistoryHandler'

interface FreightStatusProps {
  freight: FormattedOneFreight
  listFreightStatusHistory?: FormattedFreightStatusHistory[]
}

export function FreightStatus({ freight, listFreightStatusHistory }: FreightStatusProps): JSX.Element {
  const StatusClientPage = freightStatusInProgressOptions.filter(
    status => !['in_origin', 'finished'].includes(status.value),
  )
  return (
    <Box mb={8}>
      <Text fontSize={['lg', 'x-large']} fontWeight="bold" mb="2" mr="10" mt={4}>
        Status do frete: #{freight.freight_number}
      </Text>
      <Box>
        {StatusClientPage.map(item => {
          const handle = new FreightStatusHistoryHandler(item.value, freight.status, listFreightStatusHistory)
          return (
            <Tooltip key={item.value} cursor="pointer" label={handle.getTooltipTitle()}>
              <Text
                fontSize={['lg', 'md']}
                fontWeight="bold"
                cursor="pointer"
                mb="2"
                color={handle.getColorFreightStatusHistory()}
              >
                <Flex direction="row" gridGap="2" alignItems="center" fontSize={['xs', 'md']}>
                  {handle.statusConcluded() ? <RiCheckLine fontSize="20" /> : <RiCloseFill fontSize="20" />}
                  {getLabelFromAutoComplete({ value: item.value, array: StatusClientPage })}{' '}
                  {handle.getStatusDateForEachFreightPhase()}
                </Flex>
              </Text>
            </Tooltip>
          )
        })}
      </Box>
    </Box>
  )
}
