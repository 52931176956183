import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { Autocomplete } from '../../../../../../components/form/Autocomplete'
import { AutocompleteOption } from '../../../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { useGetInteractionsTimeline } from '../../../../../../services/endpoints/freights/interactions-timeline/getInteractionsTimeline'
import {
  UpdateInteractionsTimeline,
  usePutInteractionsTimeline,
} from '../../../../../../services/endpoints/freights/interactions-timeline/putInteractionsTimeline'
import { useUploadAttachToTimeline } from '../../../../../../services/endpoints/freights/interactions-timeline/uploadAttachToTimeline'
import { fetchUsers } from '../../../../../../services/endpoints/users/getUsers'
import InteractionsTimelineType from '../../../../../../services/types/InteractionsTimelineType'

interface OccurrencesModalProps {
  isOpen: boolean
  onClose: () => void
  setCommentUpdated: (data: InteractionsTimelineType) => void
  interaction_id: string
}

export function UpdateCommentInTimelineModal({
  isOpen,
  onClose,
  setCommentUpdated,
  interaction_id,
}: OccurrencesModalProps): JSX.Element {
  const { setValue } = useForm({})
  const { data: interaction, refetch } = useGetInteractionsTimeline(interaction_id)

  const toast = useToast()
  const [users, setUsers] = useState<AutocompleteOption[]>([])
  const [userId, setUserId] = useState<string>()
  const [newComment, setNewComment] = useState<string>()
  const [fileAttachment, setFileAttachment] = useState<File>()

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = usePutInteractionsTimeline({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-interactions-timeline')
    },
  })
  const { mutateAsync: mutateAsyncUpload, isLoading: isLoadingUpload } = useUploadAttachToTimeline({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-interactions-timeline')
    },
  })

  const handleUpdateInteraction = async ({
    id,
    comment,
    user_id,
  }: UpdateInteractionsTimeline): Promise<void> => {
    try {
      const { data: commentUpdated } = await mutateAsyncUpdate({
        id,
        comment,
        user_id,
      })
      setCommentUpdated(commentUpdated)
      if (fileAttachment) {
        try {
          if (id) {
            const { data: commentUpdatedWithAttach } = await mutateAsyncUpload({
              interactions_timeline_id: id,
              file: fileAttachment,
              comment,
            })
            setCommentUpdated(commentUpdatedWithAttach)
            toast({
              title: 'Anexo e comentário alterados com sucesso!',
              isClosable: true,
              position: 'top-right',
              status: 'success',
            })
            onClose()
          }
        } catch (err) {
          const error = err as AxiosError
          toast({
            status: 'error',
            title:
              error.response?.data.message || 'Não foi possível anexar o arquivo. Tente novamente mais tarde',
            position: 'top-right',
            isClosable: true,
          })
        }
      } else {
        toast({
          title: 'Comentário editado com sucesso!',
          isClosable: true,
          position: 'top-right',
          status: 'success',
        })
        onClose()
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    async function loadUsers() {
      const allUsers = await fetchUsers({ per_page: 100 })
      const usersFormatted = allUsers.data.map(user => {
        return {
          label: user.name,
          value: user.id,
        }
      })
      if (allUsers) setUsers(usersFormatted)
    }
    loadUsers()
  }, [])

  useEffect(() => {
    refetch()
  }, [refetch, isOpen])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          refetch()
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="x-large">Editar Interação</ModalHeader>

          <ModalCloseButton />
          {interaction && (
            <ModalBody pb={6} gridGap={2}>
              {users.length > 0 && interaction.user && (
                <Autocomplete
                  label="Alterar autor"
                  name="user_id"
                  defaultValue={interaction.user.name}
                  setValue={setValue}
                  onSelectOption={option => {
                    setUserId(String(option.value))
                  }}
                  placeholder="Filtrar por usuário"
                  options={users}
                />
              )}

              <FormControl>
                <FormLabel>Alterar comentário</FormLabel>
                <Textarea
                  defaultValue={interaction.comment}
                  autoFocus
                  onChange={e => setNewComment(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4}>
                <Icon as={RiAttachmentLine} mr="1" /> Alterar anexo
                <Input
                  type="file"
                  h="32px"
                  p="-1"
                  onChange={e => {
                    if (e.target.files) {
                      setFileAttachment(e.target.files[0])
                    }
                  }}
                />
              </FormControl>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              colorScheme="red"
              size="sm"
              h="7"
              mr="3"
              onClick={() => {
                onClose()
                refetch()
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              size="sm"
              h="7"
              mr="3"
              isLoading={isLoadingUpdate || isLoadingUpload}
              onClick={() => {
                refetch()
                handleUpdateInteraction({
                  id: interaction_id,
                  comment: newComment,
                  user_id: userId,
                })
              }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
