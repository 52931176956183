import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

type UploadLoadingRequestAttachmentsType = {
  data: FormData
}

async function uploadLoadingRequestAttachments(data: UploadLoadingRequestAttachmentsType) {
  const response = await apiServer.post('/loading-requests/upload', data.data)
  queryClient.invalidateQueries('loading-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUploadLoadingRequestAttachments(
  options?: UseMutationOptions<AxiosResponse, unknown, UploadLoadingRequestAttachmentsType, unknown>,
) {
  return useMutation(
    async (data: UploadLoadingRequestAttachmentsType) => uploadLoadingRequestAttachments(data),
    options,
  )
}
