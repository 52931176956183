import {
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  Box,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState } from 'react'
import { RiLineChartLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { useReportMotorists } from '../../../../services/endpoints/motorists/reportMotorists'

type ReportMotoristsProps = {
  bg: string
}

export function ReportMotoristsCard({ bg }: ReportMotoristsProps): JSX.Element {
  const [initialDateMotorists, setInitialDateMotorists] = useState<string>(() => {
    const today = new Date()
    const week = today.setDate(today.getDate() - 1)
    return format(week, 'yyyy-MM-dd')
  })
  const [finalDateMotorists, setFinalDateMotorists] = useState<string>(initialDateMotorists)

  const reportMotorists = useReportMotorists(initialDateMotorists, finalDateMotorists)

  return (
    <>
      {reportMotorists.isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : reportMotorists.error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos motoristas. Verifique a conexão com a ou entre em contato com a equipe
          de suporte do sistema.
        </Alert>
      ) : (
        <Box p={['2', '3']} bg={bg} borderRadius={8} pb="4" shadow="md" overflow="auto" maxH="230">
          <Flex justifyContent="space-between" pl="4" pr="4" alignItems="baseline" fontWeight="bold">
            <Flex direction="column" gridGap="2" alignItems="center">
              <Flex fontSize="lg" color="linkedin.400" alignItems="center" gridGap={2}>
                <Tooltip placement="top" label="Relatório de motoristas" hasArrow>
                  <Flex color="linkedin.400" fontWeight="bold" cursor="pointer">
                    <Link to="/motorists-reports">
                      <RiLineChartLine />
                    </Link>
                  </Flex>
                </Tooltip>
                MOTORISTAS
                {!reportMotorists.isLoading && reportMotorists.isFetching && <Spinner ml={2} />}
              </Flex>
            </Flex>

            <Text fontSize="x-large" color="linkedin.400">
              {reportMotorists.data && reportMotorists.data.total}
            </Text>
          </Flex>

          <Flex direction={['column']} alignItems="center" my="2">
            <Box flex="1" textAlign="center" mb="2">
              Cadastros nesse período
            </Box>
            <Flex w="100%" alignItems="center">
              <Text mr="5" fontSize={['x-small', 'sm']}>
                De
              </Text>
              <Input
                fontSize={['xs']}
                h="20px"
                mt={['1', '']}
                type="date"
                name="initial_date"
                defaultValue={initialDateMotorists}
                onChange={e => {
                  setInitialDateMotorists(e.target.value)
                }}
              />
            </Flex>
            <Flex w="100%" alignItems="center">
              <Text mr="4" fontSize={['x-small', 'sm']}>
                Até
              </Text>
              <Input
                mt={['1', '']}
                fontSize={['xs']}
                h="20px"
                type="date"
                name="final_date"
                defaultValue={finalDateMotorists}
                onChange={e => {
                  setFinalDateMotorists(e.target.value)
                }}
              />
            </Flex>
          </Flex>
          {reportMotorists.data && reportMotorists.data.total > 0 ? (
            <Table variant="simple" size="sm" my="2">
              <Thead>
                <Tr>
                  <Th fontSize="sm">Operador</Th>
                  <Th fontSize="sm">Cadastros</Th>
                </Tr>
              </Thead>
              <Tbody justifyContent="flex-end">
                {reportMotorists.data.data.map(motorists => (
                  <Tr key={motorists.creator_id}>
                    <Td fontSize="xs">{motorists.name}</Td>
                    <Td fontSize="xs">{motorists.count}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Alert status="info">
              <AlertIcon />
              Nenhum motorista foi cadastrado nesse período
            </Alert>
          )}
        </Box>
      )}
    </>
  )
}
