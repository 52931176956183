import { addHours } from 'date-fns'
import { UseQueryResult, useQuery } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'

export type FetchCteReportParams = {
  unpaged?: boolean
  start_date?: string
  end_date?: string
}
export type IReceivedBillModel = {
  cte_id: string
  cte_key: string
  freight_id: string
  freight_number: string // FRETE
  freight_requester: string // SOLICITANTE
  cte_type: string // TIPO CTE PURO
  cte_status: string // TIPO CTE PURO
  cte_emitter: string // EMPRESA
  freight_creator: string // OPERADOR
  cte_operator: string // EMISSOR
  cte_number: string // CTE
  cte_serie: string // SÉRIE
  type_spothub: string // TIPO DE CTE
  shared_cte: string // CTE COMPARTILHADO
  month: string // MÊS
  cte_emit_date: string // EMISSÃO
  sender: string // REMETENTE
  receiver: string // DESTINATÁRIO
  cte_taker: string // TOMADOR
  cte_taker_cnpj: string // TOMADOR
  cte_taker_alias: string // CLIENTE TRATADO
  origin: string // ORI
  destination: string // DES
  freight_distance: number // DISTÂNCIA
  is_municipal: string // Municipal
  par_uf: string // d
  aliquot_icms: string // Aliquota ICMS
  aliquot_iss: string // Aliquota ISS
  pis_confins: string // Alíquota PIS/COFINS Receita
  freight_seller: string // VENDEDOR
  client_type: string // Tipo de Cliente
  cargo_value: string // VALOR NF (R$)
  cargo_weight: string // PESO (KG)
  vehicle_category: string // TIPO DE EQUIPAMENTO
  vehicle_weight: string // CATEGORIA
  sector: string // MODALIDADE
  vehicle_owner_type: string // ENQUADR.
  cte_installment_value: string // VALOR FRETE (R$)
  motorist_value: string // CONTRATO DE FRETE (R$)
  freight_expenses: string // DESPESAS OPERACIONAIS  (R$)
  reimbursable_expenses: string // DESPESAS REEMBOLSÁVEIS
  pocket_insurance_percent: string // SEGURO (%)
  insurance_value: string // VALOR SEGURO (R$)
  cprb_expense: string // DESPESA CPRB (R$)
  payment_expense: string // DESPESA PAGAMENTO (R$)
  gris: string // GRIS E CUSTOS COM CAD (R$)
  icms_value: string // ICMS (R$)
  iss_value: string // ISS (R$)
  pis_cofins_value: string // PIS/COFINS (R$)
  icms_value_to_consider: string // ICMS (considerar) (R$)
  pis_cofins_freight_contract: string // CONTRATO DE FRETE (crédito PIS/COFINS) (R$)
  pis_cofins_operational_expenses: string // DESPESAS OPERACIONAIS (crédito PIS/COFINS) (R$)
  pis_cofins_without_icms: string // PIS/COFINS (sem ICMS na BC) (R$)
  pis_cofins_insurance_value: string // VALOR SEGURO (crédito PIS/COFINS) (R$)
  total_taxes: string // IMPOSTO TOTAL (R$)
  adjustment_coefficient: string // Coeficiente ajuste
  net_profit: string // RECEITA LÍQUIDA (R$)
  gross_profit: string // LUCRO BRUTO (R$)
  gross_profit_without_pocket: string // LUCRO BRUTO SEM BOLSÃO
  pocket_insurance: string // BOLSÃO SEGURO
  financial_expense: string // DESPESA FINANCEIRA (R$)
  bonus: string // COMISSÃO / BONUS (R$)
  net_contribution_margin_value: string // MARGEM DE CONTRIBUIÇÃO LIQUIDO
  gross_contribution_margin: string // MARGEM DE CONTRIBUIÇÃO BRUTO
  net_contribution_margin_percent: string // MARGEM CONTRIBUIÇÃO LIQUIDA (5)
  cte_observation: string // OBSERVAÇÃO
  toll_cost: string // CUSTO PEDÁGIO
  is_shared: boolean // É COMPARTILHADO
  vehicle_body: string // CARROCERIA
  mdfe: string // MDFE
  ciot: string // CIOT
  checked_payment_date: string // PREVISÃO PAGAMENTO
  payment_status: string // STATUS PAGAMENTO
  payment_date: string // DATA PAGAMENTO
}

export type FetchCtesResponse = {
  total: number
  data: IReceivedBillModel[]
  sales_report: any
  sales_report_complete: any
}

export async function fetchReceivedBillsReport(data: FetchCteReportParams): Promise<FetchCtesResponse> {
  const { data: receivedBillsReportResponse } = await apiServer.get<
    ResponseListApiType<IReceivedBillModel[]>
  >(`/received-bills-report`, {
    params: {
      ...data,
      start_date: data.start_date ? `${data.start_date}T00:00:00` : undefined,
      end_date: data.end_date ? `${data.end_date}T23:59:59` : undefined,
    },
  })

  const formattedReport = receivedBillsReportResponse.data.data.map(report => {
    return {
      ...report,
      cte_emit_date: addHours(new Date(report.cte_emit_date), 3),
      payment_date: report.payment_date ? addHours(new Date(report.payment_date), 3) : '',
      checked_payment_date: report.checked_payment_date
        ? addHours(new Date(report.checked_payment_date), 3)
        : '',
    }
  })

  const salesReport = formattedReport.map(report => {
    return [
      [report.freight_number], // FRETE
      [report.freight_distance], // DISTÂNCIA
      [report.cte_number], // CTE
      [report.cte_emitter], // EMPRESA
      [report.freight_creator], // OPERADOR
      [report.cte_operator], // EMISSOR
      [report.cte_serie], // SÉRIE
      [report.cte_type], // TIPO CTE
      [report.type_spothub], // TIPO SPOTHUB
      [report.cte_status], // STATUS
      [report.cte_emit_date], // EMISSÃO
      [report.sender], // REMETENTE
      [report.receiver], // DESTINATÁRIO
      [report.cte_taker], // TOMADOR
      [report.cte_taker_cnpj], // CNPJ TOMADOR
      [report.freight_requester], // SOLICITANTE
      [report.origin], // ORI
      [report.destination], // DES
      [report.par_uf], // parUF
      [report.aliquot_icms], // Alíquota ICMS
      [report.pis_confins], // Alíquota PIS/COFINS Receita
      [report.freight_seller], // VENDEDOR
      [report.client_type], // Tipo de Cliente
      [report.cargo_value], // VALOR NF (R$)
      [report.cargo_weight], // PESO
      [report.vehicle_category], // TIPO EQUIPAMENTO
      [report.vehicle_body], // CARROCERIA
      [report.vehicle_weight], // CATEGORIA
      [report.sector], // MODALIDADE
      [report.vehicle_owner_type], // ENQUADRAMENTO
      [report.cte_installment_value], // VALOR FRETE (R$)
      [report.motorist_value], // VALOR MOTORISTA (R$)
      ['--'], // VALR CIT
      [report.cte_observation], // OBSERVAÇÃO
      [report.freight_expenses], // DESPESAS
      [report.mdfe], // MDFE
      [report.ciot], // CIOT
      [report.cte_key], // Chave
      [report.checked_payment_date], // PREVISÃO PAGAMENTO
      [report.payment_status], // STATUS PAGAMENTO
      [report.payment_date], // DATA PAGAMENTO
    ]
  })

  const salesReportComplete = formattedReport.map(report => {
    return [
      [report.freight_number], // FRETE
      [report.freight_requester], // SOLICITANTE
      [report.cte_type], // TIPO CTE PURO
      [report.cte_emitter], // EMPRESA
      [report.freight_creator], // OPERADOR
      [report.cte_operator], // EMISSOR
      [report.cte_number], // CTE
      [report.cte_serie], // SÉRIE
      [report.type_spothub], // TIPO DE CTE
      [report.shared_cte], // CTE COMPARTILHADO
      [report.month], // MÊS
      [report.cte_emit_date], // EMISSÃO
      [report.sender], // REMETENTE
      [report.receiver], // DESTINATÁRIO
      [report.cte_taker], // TOMADOR
      [report.cte_taker_alias], // CLIENTE TRATADO
      [report.origin], // ORI
      [report.destination], // DES
      [report.is_municipal], // Municipal
      [report.par_uf], // d
      [report.aliquot_icms], // Aliquota ICMS
      [report.aliquot_iss], // Aliquota ISS
      [report.pis_confins], // Alíquota PIS/COFINS Receita
      [report.freight_seller], // VENDEDOR
      [report.client_type], // Tipo de Cliente
      [report.cargo_value], // VALOR NF (R$)
      [report.cargo_weight], // PESO (KG)
      [report.vehicle_category], // TIPO DE EQUIPAMENTO
      [report.vehicle_weight], // CATEGORIA
      [report.sector], // MODALIDADE
      [report.vehicle_owner_type], // ENQUADR.
      [report.cte_installment_value], // VALOR FRETE (R$)
      [report.motorist_value], // CONTRATO DE FRETE (R$)
      [report.freight_expenses], // DESPESAS OPERACIONAIS  (R$)
      ['--'], // DESPESAS REEMBOLSÁVES
      [report.pocket_insurance_percent], // SEGURO (%)
      [report.insurance_value], // VALOR SEGURO (R$)
      [report.cprb_expense], // DESPESA CPRB (R$)
      [report.payment_expense], // DESPESA PAGAMENTO (R$)
      [report.gris], // GRIS E CUSTOS COM CAD (R$)
      [report.icms_value], // ICMS (R$)
      [report.iss_value], // ISS (R$)
      [report.pis_cofins_value], // PIS/COFINS (R$)
      [report.icms_value_to_consider], // ICMS (considerar) (R$)
      [report.pis_cofins_freight_contract], // CONTRATO DE FRETE (crédito PIS/COFINS) (R$)
      [report.pis_cofins_operational_expenses], // DESPESAS OPERACIONAIS (crédito PIS/COFINS) (R$)
      [report.pis_cofins_without_icms], // PIS/COFINS (sem ICMS na BC) (R$)
      [report.pis_cofins_insurance_value], // VALOR SEGURO (crédito PIS/COFINS) (R$)
      [report.total_taxes], // IMPOSTO TOTAL (R$)
      [report.adjustment_coefficient], // Coeficiente ajuste
      [report.net_profit], // RECEITA LÍQUIDA (R$)
      [report.gross_profit], // LUCRO BRUTO (R$)
      [report.gross_profit_without_pocket], // LUCRO BRUTO SEM BOLSÃO
      [report.pocket_insurance], // BOLSÃO SEGURO
      [report.financial_expense], // DESPESA FINANCEIRA (R$)
      [report.bonus], // COMISSÃO / BONUS (R$)
      [report.net_contribution_margin_value], // MARGEM DE CONTRIBUIÇÃO LIQUIDO
      [report.gross_contribution_margin], // MARGEM DE CONTRIBUIÇÃO BRUTO
      [report.net_contribution_margin_percent], // MARGEM CONTRIBUIÇÃO LIQUIDA (5)
      [report.cte_observation], // OBSERVAÇÃO
      [report.toll_cost], // CUSTO PEDÁGIO
    ]
  })

  const { total } = receivedBillsReportResponse.data

  return {
    total,
    data: receivedBillsReportResponse.data.data,
    sales_report: salesReport,
    sales_report_complete: salesReportComplete,
  }
}

export function useGetCtes(data: FetchCteReportParams): UseQueryResult<FetchCtesResponse> {
  return useQuery(['list_ctes', data], () => fetchReceivedBillsReport(data), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  })
}
