import { Button, Flex, Grid, GridItem, Icon, useDisclosure } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiDocumentCheck } from 'react-icons/hi2'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../components/form'
import Filter from '../../../../components/UI/Filter'
import { PageHeader } from '../../../../components/UI/PageHeader'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { CiotTable } from './CiotTable'

const filterFormSchema = yup.object().shape({
  page: yup.number().nullable(),
  ciot_number: yup.number().nullable(),
  freight_number: yup.number().nullable(),
  owner_name: yup.string().nullable(),
})

export type QueryParams = {
  ciot_number?: number
  page?: number
  freight_number?: number
  owner_name?: string
}

export const ListCiots = (): JSX.Element => {
  const [filters, setFilters] = useState<QueryParams>({
    ciot_number: undefined,
    freight_number: undefined,
    page: 1,
    owner_name: undefined,
  })

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const { upsertQueryParams, getParams, addPage } = useQueryParamUpdater<QueryParams>()

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<QueryParams>({
    mode: 'onSubmit',
    resolver: yupResolver(filterFormSchema),
  })

  const handleFilter: SubmitHandler<QueryParams> = data => {
    upsertQueryParams({
      page: undefined,
      ciot_number: data.ciot_number?.toString(),
      freight_number: data.freight_number?.toString(),
      owner_name: data.owner_name?.toString(),
    })
  }

  const ciot_number_params = getParams('ciot_number')
  const freight_number_params = getParams('freight_number')
  const owner_name_params = getParams('owner_name')
  const page_params = getParams('page')

  useEffect(() => {
    const filersParams = {
      ciot_number: ciot_number_params ? Number(ciot_number_params) : undefined,
      freight_number: freight_number_params ? Number(freight_number_params) : undefined,
      owner_name: owner_name_params || undefined,
      page: page_params ? Number(page_params) : undefined,
    }
    setFilters(filersParams)
  }, [ciot_number_params, freight_number_params, owner_name_params, page_params])

  return (
    <Layout>
      <PageHeader
        title="Lista de CIOT"
        icon={HiDocumentCheck}
        rightContent={
          <Button
            leftIcon={<RiFilter2Line />}
            variant="outline"
            size="sm"
            colorScheme="blue"
            onClick={onToggleFilter}
          >
            Filtros
          </Button>
        }
      />

      <Filter showFilter={onShowFilter} key="filter">
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[6, 4]}>
              <Input
                name="ciot_number"
                label="Ciot"
                type="number"
                placeholder="Número do ciot"
                setValue={setValue}
                error={errors.ciot_number}
                initialValue={filters?.ciot_number}
              />
            </GridItem>
            <GridItem colSpan={[6, 4]}>
              <Input
                label="Frete"
                name="freight_number"
                placeholder="Número do frete"
                setValue={setValue}
                initialValue={filters?.freight_number}
                error={errors.freight_number}
              />
            </GridItem>
            <GridItem colSpan={[6, 4]}>
              <Input
                setValue={setValue}
                label="Proprietário"
                name="owner_name"
                initialValue={filters?.owner_name}
                error={errors.owner_name}
                placeholder="Nome do proprietário"
              />
            </GridItem>
          </Grid>
          <Flex justify="flex-end" mt="4" gridGap="2">
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              // isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>
      <CiotTable filters={filters} onChangePage={addPage} />
    </Layout>
  )
}
