import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Autocomplete } from '../../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../../components/form/AutocompleteAsync'
import { AutocompleteMulti } from '../../../components/form/AutocompleteMulti'
import { Input } from '../../../components/form/Input'
import { InputMask } from '../../../components/form/InputMask'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { Select } from '../../../components/form/Select'
import { Switch } from '../../../components/form/Switch'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { ReloadButton } from '../../../components/ReloadButton'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import { useAuth } from '../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { searchMotoristByName } from '../../../services/endpoints/motorists/searchMotoristByName'
import {
  fetchAllRiskAnalysis,
  FiltersAllRiskAnalysisParams,
  useGetAllRiskAnalysis,
} from '../../../services/endpoints/risk-analysis/getAllRiskAnalysis'
import { getUserOperation } from '../../../services/endpoints/users/getUserOperation'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../../../services/endpoints/vehicles/getVehicleCategories'
import { formatDateForInitialValue } from '../../../services/utils/dates/formatDateForInitialValue'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'
import parseCurrency from '../../../services/utils/parseCurrency'
import { boxWidth } from '../../../services/utils/tableAdaptable'
import { TableListAllRiskAnalysisPage } from './TableListAllRiskAnalysisPage'

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  per_page: yup.number(),
  freight_id: yup.string(),
  vehicle_id: yup.string(),
  user_id: yup.object().shape({}),
  vehicle_categories: yup.array().of(yup.object()),
  vehicle_bodies: yup.array().of(yup.object()),
  freight_number: yup.string(),
  depends_on_check: yup.string(),
  status: yup.string(),
  initial_date: yup.date(),
  final_date: yup.date(),
})

interface FiltersRiskAnalysis
  extends Omit<FiltersAllRiskAnalysisParams, 'vehicle_categories' | 'vehicle_bodies'> {
  vehicle_categories?: AutocompleteOption[]
  vehicle_bodies?: AutocompleteOption[]
  initial_date: string
  final_date: string
  user_label?: string
  motorist_label?: string
}

export function ListAllRiskAnalysis(): JSX.Element {
  const initialDate = () => {
    const now = new Date()
    return format(now.setDate(now.getDate() - 30), 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const bg = useColorModeValue('white', 'gray.800')
  const { getUserIdToListFreights, setUserIdToListFreights } = useAuth()
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState<AutocompleteOption[]>([])
  const [filters, setFilters] = useState<FiltersAllRiskAnalysisParams>({
    initial_date: initialDate(),
    final_date: finalDate(),
  })
  const [depends_on_check, setDepends_on_check] = useState<boolean>()
  const [isFilters, setIsFilters] = useState<boolean>()
  const { updateQueryParams, getParams } = useQueryParamUpdater<FiltersRiskAnalysis>()

  const {
    data: allRiskAnalysis,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetAllRiskAnalysis({
    ...filters,
    page,
    per_page: 8,
  })

  const [riskAnalysis, setRiskAnalysis] = useState(allRiskAnalysis)

  useEffect(() => {
    setRiskAnalysis(allRiskAnalysis)
  }, [allRiskAnalysis, setUserIdToListFreights])

  const [vehicleCategories, setVehicleCategories] = useState<AutocompleteOption[]>()
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors } = formState

  useEffect(() => {
    if (isFilters) {
      getUserOperation().then(res => setUsers(res))
      getUserIdToListFreights()
      getVehicleCategories().then(res => setVehicleCategories(res))
      getVehicleBodies().then(res => setVehicleBodies(res))
    }
  }, [getUserIdToListFreights, isFilters])

  const handleFilterFreightExpense: SubmitHandler<FiltersRiskAnalysis> = async data => {
    setDepends_on_check(undefined)

    const dataFilter = {
      freight_number: data.freight_number,
      status: data.status,
      user_id: data.user_id?.value,
      depends_on_check,
      cpf: data.cpf ? getOnlyNumbers(String(data.cpf)) : undefined,
      vehicle_categories: data.vehicle_categories?.map(i => i.value),
      vehicle_bodies: data.vehicle_bodies?.map(i => i.value),
      initial_date: formatDateForInitialValue(data.initial_date),
      final_date: formatDateForInitialValue(data.final_date),
    } as FiltersAllRiskAnalysisParams
    const {
      freight_number,
      cpf,
      user_id,
      status,
      vehicle_categories,
      vehicle_bodies,
      initial_date,
      final_date,
    } = dataFilter
    updateQueryParams({
      initial_date,
      final_date,
      status,
      freight_number,
      cpf,
      user_id: data.user_id?.value ? String(data.user_id?.value) : undefined,
      user_label: data.user_id?.label ? String(data.user_id?.label) : undefined,
      motorist_id: data.motorist_id?.value ? String(data.motorist_id?.value) : undefined,
      motorist_label: data.motorist_id?.label ? String(data.motorist_id?.label) : undefined,
      page: String(page),
    })
    setFilters({
      freight_number,
      motorist_id: data.motorist_id?.value as any,
      motorist_label: data.motorist_id?.label,
      user_id,
      user_label: data.user_id?.label,
      cpf,
      vehicle_categories,
      vehicle_bodies,
      depends_on_check,
      status,
      initial_date,
      final_date,
    })
  }

  useEffect(() => {
    const userLabelParam = getParams('user_label')
    const motoristLabelParam = getParams('motorist_label')

    updateQueryParams({
      initial_date: filters.initial_date,
      final_date: filters.final_date,
      status: filters.status,
      freight_number: filters.freight_number,
      cpf: filters.cpf,
      user_id: filters.user_id as any,
      user_label: userLabelParam,
      motorist_id: filters.motorist_id as any,
      motorist_label: motoristLabelParam,
      page: String(page),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    const initialDateParam = getParams('initial_date')
    const finalDateParam = getParams('final_date')
    const statusParam = getParams('status')
    const freightNumberParam = getParams('freight_number')
    const cpfParam = getParams('cpf')
    const userValueParam = getParams('user_id')
    const userLabelParam = getParams('user_label')
    const motoristValueParam = getParams('motorist_id')
    const motoristLabelParam = getParams('motorist_label')
    const pageParam = getParams('page')

    if (pageParam) {
      setPage(Number(pageParam))
    }

    if (
      initialDateParam ||
      finalDateParam ||
      statusParam ||
      freightNumberParam ||
      cpfParam ||
      userValueParam ||
      userLabelParam ||
      motoristValueParam ||
      motoristLabelParam
    ) {
      setIsFilters(true)
      handleFilterFreightExpense({
        initial_date: initialDateParam,
        final_date: finalDateParam,
        status: statusParam,
        cpf: cpfParam,
        user_id: {
          label: userLabelParam,
          value: userValueParam,
        },
        motorist_id: {
          label: motoristLabelParam,
          value: motoristValueParam,
        },
        freight_number: freightNumberParam,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Box bg={bg} w={boxWidth} p="6" borderRadius="8" shadow="md">
        <Flex justify="space-between" alignItems="baseline" direction={['column', 'row']}>
          <Flex gridGap={4} mb="6" alignItems="center">
            <Heading size="lg" fontWeight="normal">
              Lista de análise de risco
            </Heading>
            <ReloadButton isFetching={isFetching} refetch={refetch} />
          </Flex>
          <Box>
            <Switch
              name="filters"
              label="Filtros"
              setValue={setValue}
              onChange={e => {
                setIsFilters(e.target.checked)
                setFilters(prevState => prevState)
              }}
            />
          </Box>
        </Flex>

        <Box>
          {isFilters && <Divider mb="4" />}
          {isFilters && (
            <form onSubmit={handleSubmit(handleFilterFreightExpense)} noValidate>
              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Input
                    type="date"
                    label="Data inicial"
                    name="initial_date"
                    setValue={setValue}
                    initialValue={filters?.initial_date || initialDate()}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Input
                    label="Data Final"
                    type="date"
                    name="final_date"
                    setValue={setValue}
                    initialValue={filters?.final_date || finalDate()}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <RadioGroup
                    name="depends_on_check"
                    label="Check List"
                    error={errors.depends_on_check}
                    setValue={setValue}
                    onSelectOption={op => {
                      if (op.value === 'yes') {
                        setDepends_on_check(true)
                      } else if (op.value === 'not') {
                        setDepends_on_check(false)
                      } else setDepends_on_check(undefined)
                    }}
                    options={[
                      { label: 'Sim', value: 'yes' },
                      { label: 'Não', value: 'not' },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Select
                    name="status"
                    label="Status"
                    setValue={setValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Aguardando', value: 'awaiting' },
                      { label: 'Aguardando GR', value: 'awaiting_gr' },
                      { label: 'Aprovado', value: 'approved' },
                      { label: 'Reprovado', value: 'disapproved' },
                      { label: 'A Corrigir', value: 'to_correct' },
                      { label: 'A Checar', value: 'to_check' },
                      { label: 'A Aprovar', value: 'awaiting_approval' },
                      { label: 'Cancelado', value: 'canceled' },
                    ]}
                    error={errors.status}
                    initialValue={filters.status}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  {users.length > 0 && (
                    <Autocomplete
                      name="user_id"
                      label="Usuário"
                      onSelectOption={userSelected => {
                        if (userSelected.value) {
                          setUserIdToListFreights(userSelected)
                          getUserIdToListFreights()
                        } else {
                          setUserIdToListFreights()
                          getUserIdToListFreights()
                        }
                      }}
                      setValue={setValue}
                      options={users}
                      clearState={() => {
                        setUserIdToListFreights(undefined)
                        getUserIdToListFreights()
                      }}
                      initialValue={
                        filters.user_id && filters.user_label
                          ? {
                              label: filters.user_label,
                              value: filters.user_id as any,
                            }
                          : undefined
                      }
                    />
                  )}
                </GridItem>

                {vehicleCategories && vehicleBodies && (
                  <>
                    <GridItem colSpan={[12, 6, 3]}>
                      <AutocompleteMulti
                        name="vehicle_categories"
                        label="Veículo"
                        error={errors.vehicle_categories}
                        setValue={setValue}
                        options={[{ label: '', value: '' }, ...(vehicleCategories as AutocompleteOption[])]}
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 6, 3]}>
                      <AutocompleteMulti
                        name="vehicle_bodies"
                        label="Carroceria"
                        error={errors.vehicle_bodies}
                        setValue={setValue}
                        options={[{ label: '', value: '' }, ...(vehicleBodies as AutocompleteOption[])]}
                      />
                    </GridItem>
                  </>
                )}

                <GridItem colSpan={[12, 6, 3]}>
                  <Input
                    name="freight_number"
                    label="Número do frete"
                    error={errors.freight_number}
                    setValue={setValue}
                    initialValue={filters.freight_number}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <InputMask
                    name="cpf"
                    mask="999.999.999-99"
                    maskPlaceholder=""
                    label="CPF"
                    error={errors.cpf}
                    setValue={setValue}
                    registerOnlyNumbers
                    initialValue={filters.cpf}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <AutocompleteAsync
                    name="motorist_id"
                    setValue={setValue}
                    label="Motorista"
                    loadOptions={searchMotoristByName}
                    initialValue={
                      filters.motorist_id && filters.motorist_label
                        ? {
                            label: filters.motorist_label,
                            value: filters.motorist_id as any,
                          }
                        : undefined
                    }
                  />
                </GridItem>
                <GridItem colSpan={[12, 6]} mt="6">
                  <Stack direction="row" mt={4} justify="flex-end">
                    {riskAnalysis && riskAnalysis.data.length > 0 && (
                      <ReportToExcel
                        isLoading={isLoading}
                        documentTitle="Relatório de análises de risco"
                        columnsTitle={[
                          'Frete',
                          'Status',
                          'Motorista',
                          'Veículo',
                          'Placa',
                          'Cliente',
                          'Checagem',
                          'Código buonny',
                          'Usuário',
                          'Custos',
                          'Data',
                        ]}
                        onGenerateReport={async () => {
                          const report = await fetchAllRiskAnalysis({
                            initial_date: `${filters.initial_date}`,
                            final_date: `${filters.final_date}`,
                            unpaged: true,
                          })

                          return report.data.map(analysis => {
                            return [
                              `#${analysis.freight.freight_number}`,
                              analysis.status,
                              analysis.motorist.name,
                              `${analysis.vehicle.vehicle_category.name} - ${analysis.vehicle.vehicle_body.name}`,
                              analysis.vehicle.license_plate || '',
                              analysis.freight.client.name,
                              analysis.depends_check,
                              analysis.consultation_code,
                              analysis.user.name,
                              analysis.costRiskAnalysisPaidOut === 'Pendente'
                                ? analysis.costRiskAnalysisPaidOut
                                : parseCurrency(
                                    analysis.costRiskAnalysis?.reduce(
                                      (acc, elem) => Number(acc) + Number(elem.value),
                                      0,
                                    ),
                                  ),
                              analysis.created_at,
                            ]
                          })
                        }}
                      />
                    )}
                    <Box>
                      <Button
                        type="submit"
                        size="md"
                        colorScheme="blue"
                        leftIcon={<Icon as={RiSearchLine} />}
                        isLoading={isLoading}
                      >
                        Filtrar
                      </Button>
                    </Box>
                  </Stack>
                </GridItem>
              </Grid>
            </form>
          )}
          {isFilters && <Divider my="4" />}
        </Box>

        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : isError ? (
          <Text>Deu erro</Text>
        ) : (
          <>
            <TableListAllRiskAnalysisPage riskAnalysis={riskAnalysis} />
          </>
        )}

        {riskAnalysis && (
          <Pagination
            currentPage={page}
            totalCountOfRegisters={riskAnalysis.total}
            registersPerPage={8}
            onPageChange={setPage}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
