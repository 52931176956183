import { Flex, Spinner, Alert, AlertIcon, Box, Table, Tbody, Tr, Td, Text } from '@chakra-ui/react'
import parseCurrency from '../../../../services/utils/parseCurrency'

interface IFreightsActive {
  total: number
  pending_freights_service_price: number
  pending_freights_expired: number
  pending_freights_to_match: number
}

interface IFreightsActiveProps {
  freightsActive?: IFreightsActive
  isLoading: boolean
  isFetching: boolean
  error: unknown
  bg: string
}

export function FreightsActiveCard({
  freightsActive,
  isLoading,
  isFetching,
  error,
  bg,
}: IFreightsActiveProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes Ativos. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        freightsActive && (
          <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md">
            <Flex justifyContent="space-between" pl="5" pr="5" alignItems="baseline" mb="4" fontWeight="bold">
              <Text fontSize="lg" color="green.400">
                ABERTOS
                {!isLoading && isFetching && <Spinner ml={2} />}
              </Text>

              <Text fontSize="x-large" color="green.400">
                {freightsActive.total}
              </Text>
            </Flex>
            <Table size="md">
              <Tbody>
                <Tr>
                  <Td fontSize="md" color="green.400">
                    Serviço
                  </Td>
                  <Td isNumeric>
                    <Text fontSize="sm" color="green.400">
                      {parseCurrency(freightsActive.pending_freights_service_price)}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text
                      fontSize="md"
                      color={freightsActive.pending_freights_expired > 0 ? 'red' : 'gray.500'}
                    >
                      Atrasados
                    </Text>
                  </Td>
                  <Td
                    fontSize="md"
                    color={freightsActive.pending_freights_expired > 0 ? 'red' : 'gray.500'}
                    isNumeric
                  >
                    {freightsActive.pending_freights_expired}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text
                      fontSize="md"
                      color={freightsActive.pending_freights_to_match > 0 ? 'yellow.500' : 'gray.500'}
                    >
                      A combinar
                    </Text>
                  </Td>
                  <Td
                    fontSize="md"
                    color={freightsActive.pending_freights_to_match > 0 ? 'yellow.500' : 'gray.500'}
                    isNumeric
                  >
                    {freightsActive.pending_freights_to_match}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        )
      )}
    </>
  )
}
