/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

type ResponseGetPisInformationsParams = {
  name: string
  motherName?: string
  cpf: string
  birthDate: string | Date
}

type ResponseGetPisInformationsType = {
  nit: string
  site_receipt: string
}

async function postGetPisInformations(
  params: ResponseGetPisInformationsParams,
): Promise<ResponseGetPisInformationsType> {
  const birthDateFormatted = new Date(params.birthDate).toISOString().split('T')[0]
  const { data: response } = await apiServer.post<ResponseApiType<ResponseGetPisInformationsType>>(
    '/get-pis-informations',
    { ...params, birthDateFormatted },
  )
  return response.data
}

export function usePostGetPisInformations(
  options?: UseMutationOptions<
    ResponseGetPisInformationsType,
    Error,
    ResponseGetPisInformationsParams,
    unknown
  >,
) {
  const queryClient = useQueryClient()

  return useMutation<ResponseGetPisInformationsType, Error, ResponseGetPisInformationsParams>(
    async params => {
      const birthDateFormatted = new Date(params.birthDate).toISOString().split('T')[0]
      const localStorageKey = `pisData_${params.cpf}_${birthDateFormatted}`

      const cachedData = localStorage.getItem(localStorageKey)

      if (cachedData) {
        return JSON.parse(cachedData) as ResponseGetPisInformationsType
      }

      const data = await postGetPisInformations(params)
      if (data) {
        localStorage.setItem(localStorageKey, JSON.stringify(data))
      }
      return data
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries('pisData')
      },
    },
  )
}
