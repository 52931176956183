import { Box, Button, Flex, Grid, GridItem, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaTruckArrowRight } from 'react-icons/fa6'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Autocomplete, AutocompleteMulti, AutocompleteMultiAsync } from '../../../../components/form'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { InputMask } from '../../../../components/form/InputMask'
import { InputNumberMask } from '../../../../components/form/InputNumberMask'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { SkeletonLoadingTable } from '../../../../components/SkeletonLoadingTable'
import Filter from '../../../../components/UI/Filter'
import { PageHeader } from '../../../../components/UI/PageHeader'
import { useAuth } from '../../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { getContactsAutocomplete } from '../../../../services/endpoints/clients/client-contacts/getContactsAutocomplete'
import { searchClientsByName } from '../../../../services/endpoints/clients/searchClients'
import {
  FiltersFreightsParams,
  freightStatusInProgressOptions,
  ManyStatus,
} from '../../../../services/endpoints/freights/getFreights'
import { searchMotoristByName } from '../../../../services/endpoints/motorists/searchMotoristByName'
import { useGetFreightsTolls } from '../../../../services/endpoints/truckpad/getFreightsTolls'
import { getUserOperation } from '../../../../services/endpoints/users/getUserOperation'
import { getUserSales } from '../../../../services/endpoints/users/getUserSales'
import {
  decodeEncodeAutocompleteToObject,
  encodeAutocompleteToUrl,
} from '../../../../services/utils/AutocompleteToUrl'
import ReportTollsToExcel from './ReportTollsToExcel/ReportTollsToExcel'
import { TableContainerFreightsTollStatus } from './table'

interface FiltersFreights {
  motorist_id?: AutocompleteOption
  client_id?: AutocompleteOption
  creator_id?: AutocompleteOption
  seller_id?: AutocompleteOption
  client_contact_id?: AutocompleteOption[]
  origin_id?: AutocompleteOption
  destination_id?: AutocompleteOption
  is_monitoring?: boolean
  status?: AutocompleteOption[]
  many_status?: string[]
  freight_number?: number | string
  initial_date?: Date | string
  final_date?: Date | string
  has_monitoring_type?: 'app' | 'buonny' | 'whatsapp' | 'tracker_devices'
  place_vehicle?: string
  page?: number
  per_page?: number
}

export function FreightsTollStatus(): JSX.Element {
  const bgMain = useColorModeValue('white', 'gray.900')

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const {
    getUserIdToListFreights,
    getSellerIdToListFreights,
    setUserIdToListFreights,
    setSellerIdToListFreights,
  } = useAuth()

  const [filters, setFilters] = useState<FiltersFreightsParams>()
  const { addPage, getParams, upsertQueryParams, removeQueryParam } = useQueryParamUpdater<{
    page?: string
    freight_number?: string
    origin_id?: string
    destination_id?: string
    creator_id?: string
    seller_id?: string
    client_id?: string
    place_vehicle?: string
    motorist_id?: string
  }>()

  const { setValue, handleSubmit, formState, getValues } = useForm<FiltersFreights>({})

  const {
    data: allFreights,
    isLoading: isLoadingAllFreights,
    isFetching: isFetchingAllFreights,
  } = useGetFreightsTolls({
    ...filters,
    many_status: filters?.status
      ? (filters.status as ManyStatus)
      : ['pending', 'in_origin', 'collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'],
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
    page: filters?.page || undefined,
    per_page: 15,
  })

  const handleFilterFreightsInProgress: SubmitHandler<FiltersFreights> = async data => {
    setFilters({
      ...({
        initial_date: data.initial_date,
        final_date: data.final_date,
        freight_number: data.freight_number,
        status: data.status?.map(item => item.value),
        motorist_id: data.motorist_id?.value,
        has_monitoring_app: data.has_monitoring_type === 'app' ? true : undefined,
        has_monitoring_buonny: data.has_monitoring_type === 'buonny' ? true : undefined,
        has_monitoring_whatsapp: data.has_monitoring_type === 'whatsapp' ? true : undefined,
        has_monitoring_tracker_devices: data.has_monitoring_type === 'tracker_devices' ? true : undefined,
        place_vehicle: data.place_vehicle,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        client_contact_id: data.client_contact_id?.map(item => item.value),
        client_id: data.client_id?.value,
        seller_id: data.seller_id?.value,
        user_id: data.creator_id?.value,
      } as FiltersFreightsParams),
      page: data.page || undefined,
    })

    upsertQueryParams({
      page: data.page?.toString() || undefined,
      freight_number: data.freight_number?.toString() || undefined,
      origin_id: encodeAutocompleteToUrl(data.origin_id || undefined),
      destination_id: encodeAutocompleteToUrl(data.destination_id || undefined),
      creator_id: encodeAutocompleteToUrl(data.creator_id || undefined),
      seller_id: encodeAutocompleteToUrl(data.seller_id || undefined),
      client_id: encodeAutocompleteToUrl(data.client_id || undefined),
      place_vehicle: data.place_vehicle || undefined,
      motorist_id: encodeAutocompleteToUrl(data.motorist_id || undefined),
    })
  }

  useEffect(() => {
    const decripteQueryParams = (string: string, key: string) => {
      if (!string) {
        return undefined
      }
      const decoded = decodeEncodeAutocompleteToObject(string)
      if (!decoded) {
        removeQueryParam(key)
      }
      return decoded
    }

    const pageQuery = getParams('page')
    const freightNumberQuery = getParams('freight_number')
    const originIdQuery = getParams('origin_id')
    const destinationIdQuery = getParams('destination_id')
    const creatorIdQuery = getParams('creator_id')
    const sellerIdQuery = getParams('seller_id')
    const clientIdQuery = getParams('client_id')
    const placeVehicleQuery = getParams('place_vehicle')
    const motoristIdQuery = getParams('motorist_id')

    setValue('freight_number', Number(freightNumberQuery) || undefined)
    if (pageQuery && pageQuery !== '1') {
      setValue('page', Number(pageQuery))
    }
    setValue('origin_id', decripteQueryParams(originIdQuery, 'origin_id'))
    setValue('destination_id', decripteQueryParams(destinationIdQuery, 'destination_id'))
    setValue('creator_id', decripteQueryParams(creatorIdQuery, 'creator_id'))
    setValue('seller_id', decripteQueryParams(sellerIdQuery, 'seller_id'))
    setValue('client_id', decripteQueryParams(clientIdQuery, 'client_id'))
    setValue('place_vehicle', placeVehicleQuery || undefined)
    setValue('motorist_id', decripteQueryParams(motoristIdQuery, 'motorist_id'))
    handleSubmit(handleFilterFreightsInProgress)()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      getUserIdToListFreights()
      getSellerIdToListFreights()
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFreights, filters])

  return (
    <Layout SidebarMobile>
      <PageHeader
        title="Lista de pedágio dos fretes"
        icon={FaTruckArrowRight}
        rightContent={
          <>
            {allFreights && allFreights.data?.length > 0 && <ReportTollsToExcel />}
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilterFreightsInProgress)}>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            {/* <DateRange title="Data de Coleta" /> */}

            <GridItem colSpan={[12, 6, 3]}>
              <AutocompleteAsync
                name="origin_id"
                placeholder="Origem"
                initialValue={getValues('origin_id') || undefined}
                setValue={setValue}
                loadOptions={searchCitiesByName}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <AutocompleteAsync
                name="destination_id"
                placeholder="Destino"
                initialValue={getValues('destination_id') || undefined}
                setValue={setValue}
                loadOptions={searchCitiesByName}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Autocomplete
                name="creator_id"
                placeholder="Analista"
                initialValue={getValues('creator_id') || undefined}
                setValue={setValue}
                onSelectOption={userSelected => {
                  if (userSelected.value) {
                    setUserIdToListFreights(userSelected)
                    getUserIdToListFreights()
                  } else {
                    setUserIdToListFreights()
                    getUserIdToListFreights()
                  }
                }}
                clearState={() => {
                  setUserIdToListFreights(undefined)
                  getUserIdToListFreights()
                }}
                loadOptions={getUserOperation}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Autocomplete
                name="seller_id"
                placeholder="Vendedor"
                initialValue={getValues('seller_id') || undefined}
                setValue={setValue}
                onSelectOption={userSelected => {
                  if (userSelected.value) {
                    setSellerIdToListFreights(userSelected)
                    getSellerIdToListFreights()
                  } else {
                    setSellerIdToListFreights()
                    getSellerIdToListFreights()
                  }
                }}
                clearState={() => {
                  setSellerIdToListFreights(undefined)
                  getSellerIdToListFreights()
                }}
                loadOptions={getUserSales}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <InputNumberMask
                name="freight_number"
                initialValue={Number(filters?.freight_number) || undefined}
                placeholder="Número do frete"
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <AutocompleteAsync
                name="client_id"
                placeholder="Cliente"
                initialValue={getValues('client_id') || undefined}
                setValue={setValue}
                loadOptions={searchClientsByName}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 4]}>
              <AutocompleteMulti
                name="status"
                placeholder="Status"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Ativo', value: 'pending' },
                  ...freightStatusInProgressOptions,
                ]}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 2]}>
              <InputMask
                name="place_vehicle"
                mask="aaa9*99"
                maskPlaceholder=""
                placeholder="Placa"
                setValue={setValue}
                uppercaseAll
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 6]}>
              <AutocompleteMultiAsync
                name="client_contact_id"
                placeholder="Solicitante"
                setValue={setValue}
                loadOptions={getContactsAutocomplete}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 6]}>
              <AutocompleteAsync
                name="motorist_id"
                setValue={setValue}
                placeholder="Motorista"
                initialValue={getValues('motorist_id') || undefined}
                loadOptions={searchMotoristByName}
              />
            </GridItem>
          </Grid>
          <Flex justify="flex-end" mt="4" gridGap="2">
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isFetchingAllFreights || formState.isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>
      <Box bg={bgMain}>
        {isLoadingAllFreights ? (
          <SkeletonLoadingTable />
        ) : (
          <>
            {allFreights && (
              <>
                <TableContainerFreightsTollStatus freights={allFreights.data} />
                <Box mb={8}>
                  <Pagination
                    currentPage={filters?.page}
                    totalCountOfRegisters={allFreights.total}
                    onPageChange={newPage => {
                      setFilters(prev => ({ ...prev, page: newPage }))
                      addPage(newPage)
                    }}
                    p="6"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
