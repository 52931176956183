import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { Authorization, ResponseApiType } from '../../types'

type Output = Authorization[]

export async function getAllPermissions(): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output>>('/authorization/permissions')
  return data.data
}

export function useGetAllPermissions(): UseQueryResult<Output, unknown> {
  return useQuery(['getAllPermissions'], () => getAllPermissions(), {
    refetchOnWindowFocus: false,
  })
}
