import { FormControl, FormControlProps, FormErrorMessage, FormLabel, HStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { Star } from './Star'

interface RateStarProps extends FormControlProps {
  name: string
  label?: string
  setValue: UseFormSetValue<FieldValues>
  initialValue?: number
  error?: FieldError
  isDisabled?: boolean
  isRequired?: boolean
}

export function RateStar({
  name,
  label,
  setValue,
  initialValue,
  error,
  isDisabled = false,
  isRequired = false,
  ...rest
}: RateStarProps): JSX.Element {
  const [rate, setRate] = useState(initialValue)

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
    } else {
      setValue(name, undefined)
    }
  }, [initialValue, setValue, name])

  return (
    <FormControl isInvalid={!!error} isDisabled={isDisabled} isRequired={isRequired} {...rest}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}

      <HStack spacing="2">
        <Star
          onClick={() => {
            setRate(1)
            setValue(name, 1)
          }}
          selected={Number(rate) >= 1}
          color={error ? 'red.500' : undefined}
          isDisabled={isDisabled}
        />
        <Star
          onClick={() => {
            setRate(2)
            setValue(name, 2)
          }}
          selected={Number(rate) >= 2}
          color={error ? 'red.500' : undefined}
          isDisabled={isDisabled}
        />
        <Star
          onClick={() => {
            setRate(3)
            setValue(name, 3)
          }}
          selected={Number(rate) >= 3}
          color={error ? 'red.500' : undefined}
          isDisabled={isDisabled}
        />
        <Star
          onClick={() => {
            setRate(4)
            setValue(name, 4)
          }}
          selected={Number(rate) >= 4}
          color={error ? 'red.500' : undefined}
          isDisabled={isDisabled}
        />
        <Star
          onClick={() => {
            setRate(5)
            setValue(name, 5)
          }}
          selected={Number(rate) >= 5}
          color={error ? 'red.500' : undefined}
          isDisabled={isDisabled}
        />
      </HStack>

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
