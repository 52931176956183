/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

export interface UpdateFreightExpense {
  id: string
  freight_id?: string
  user_id?: string
  cte_id?: string
  document_recipient?: string
  name_recipient?: string
  transporter?: string
  number?: string
  observation?: string
  pix?: string
  bank?: string
  account_type?: string
  agency?: string
  account?: string
  payment_at?: Date
  receipt_at?: Date
  paid?: boolean
  value?: number
  attachment_file?: string
  status?: string
  event?: string
  type?: string
}

async function updateFreightExpense({ id, ...data }: UpdateFreightExpense) {
  const output = await apiServer.put(`/update-freight-expense/${id}`, data)
  queryClient.invalidateQueries('freight')
  queryClient.invalidateQueries('list-all-financial-title')
  queryClient.invalidateQueries('list-all-financial-title-history')
  return output
}

export function usePutFreightExpense(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateFreightExpense, unknown>,
) {
  return useMutation(async (data: UpdateFreightExpense) => updateFreightExpense(data), options)
}
