/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export interface IUpdateRoutePrices {
  id: string
  name?: string
  phone?: string
  value?: number
  origin?: string
  quotation_id: string
  motorist_id?: string
}

async function putRoutePrices({ id, ...data }: IUpdateRoutePrices) {
  return apiServer.put(`/update-route-prices/${id}`, data)
}

export function usePutRoutePrices(
  options?: UseMutationOptions<AxiosResponse, unknown, IUpdateRoutePrices, unknown>,
) {
  return useMutation(async (data: IUpdateRoutePrices) => putRoutePrices(data), options)
}
