import { Divider, Flex, Heading } from '@chakra-ui/react'
import { Layout } from '../../../layout'
import PisForm from './Forms/PisForm'

export default function PisConsultation(): JSX.Element {
  return (
    <Layout>
      <Flex justify="space-between" alignItems="baseline" direction={['column', 'row']}>
        <Flex gridGap={4} mb="6" alignItems="center">
          <Heading size="lg" fontWeight="normal">
            Consulta Pis
          </Heading>
        </Flex>
      </Flex>
      <Divider my={3} />

      <PisForm />
    </Layout>
  )
}
