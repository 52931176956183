import {
  Box,
  Divider,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  WrapItem,
} from '@chakra-ui/react'
import { RiCaravanLine, RiStarLine, RiTruckLine } from 'react-icons/ri'
import { SiAdblock } from 'react-icons/si'
import { getFormatEntityType } from '../../../services/endpoints/black-list/getAllBlackList'
import BlackListType from '../../../services/types/BlackListType'

interface ShowInfosBlackListModalProps {
  isOpen: boolean
  onClose: () => void
  black_list: BlackListType
}

export function ShowInfosBlackListModal({
  isOpen,
  onClose,
  black_list,
}: ShowInfosBlackListModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="x-large">
          <Stack direction={['column', 'row']} align="center" justify="flex-start">
            <Heading size="md" color="red" fontWeight="normal">
              O {getFormatEntityType(String(black_list?.entity_type))} consta na lista de restrição!{' '}
              <Icon mb="1" fontSize="3xl" as={SiAdblock} />
            </Heading>

            <ModalCloseButton />
          </Stack>
        </ModalHeader>

        <ModalBody pb={6} gridGap={2}>
          <Divider my="1" />

          <Box>
            <SimpleGrid minChildWidth="300px" spacing="4">
              <WrapItem>
                <Text color="gray.400">Responsável:</Text>
                <Text fontWeight="medium" ml="1">
                  {black_list.creator?.name}
                </Text>
              </WrapItem>
              {black_list?.created_at && (
                <WrapItem>
                  <Text color="gray.400">Data da inclusão:</Text>
                  <Text fontWeight="medium" ml="1">
                    {black_list?.created_at}
                  </Text>
                </WrapItem>
              )}
              <WrapItem>
                <Text color="gray.400">Motivo:</Text>
                <Text fontWeight="medium" ml="1">
                  {black_list?.reason}
                </Text>
              </WrapItem>
            </SimpleGrid>
          </Box>
          <Divider my="4" />
          <Box>
            <SimpleGrid minChildWidth="300px" spacing="4">
              <WrapItem>
                {black_list?.entity_type && (
                  <Text color="gray.400">{`${getFormatEntityType(black_list?.entity_type)}: `} </Text>
                )}

                {black_list?.entity_type === 'motorist' ? (
                  <Text fontWeight="medium" ml="1">
                    {black_list?.motorist?.name || 'Sem cadastro'}
                  </Text>
                ) : black_list?.entity_type === 'owner' ? (
                  <Text fontWeight="medium" ml="1">
                    {black_list?.owner?.name || 'Sem cadastro'}
                  </Text>
                ) : black_list?.vehicle?.vehicle_category || black_list?.vehicle?.vehicle_body ? (
                  <>
                    {black_list?.vehicle?.vehicle_category && (
                      <Tag size="lg" borderRadius="full" ml="2">
                        <TagLeftIcon as={RiTruckLine} />
                        <TagLabel>{black_list?.vehicle?.vehicle_category?.name}</TagLabel>
                      </Tag>
                    )}

                    <Tag size="lg" borderRadius="full" ml="2">
                      <TagLeftIcon as={RiCaravanLine} />
                      <TagLabel>{black_list?.vehicle?.vehicle_body?.name}</TagLabel>
                    </Tag>
                  </>
                ) : (
                  <Text ml="1">Sem cadastro</Text>
                )}
              </WrapItem>

              {black_list.entity_type === 'motorist' ? (
                <WrapItem>
                  <Text color="gray.400">Telefone:</Text>
                  <Text fontWeight="medium" ml="1">
                    {black_list?.motorist?.phone}
                  </Text>
                </WrapItem>
              ) : black_list?.entity_type === 'owner' ? (
                <WrapItem>
                  <Text color="gray.400">Telefone:</Text>
                  <Text fontWeight="medium" ml="1">
                    {black_list?.owner?.phone}
                  </Text>
                </WrapItem>
              ) : (
                black_list?.entity_type === 'vehicle' && (
                  <WrapItem>
                    <Text color="gray.400">Placa:</Text>
                    <Text fontWeight="medium" ml="1">
                      {black_list?.vehicle?.license_plate || black_list.license_plate}
                    </Text>
                  </WrapItem>
                )
              )}

              {black_list.entity_type === 'motorist' ? (
                <WrapItem>
                  <Text color="gray.400">CPF:</Text>
                  <Text fontWeight="medium" ml="1">
                    {black_list?.motorist?.cpf || black_list?.cpf}
                  </Text>
                </WrapItem>
              ) : (
                black_list?.entity_type === 'owner' &&
                (black_list?.owner?.cpf ? (
                  <WrapItem>
                    <Text color="gray.400">CPF:</Text>
                    <Text fontWeight="medium" ml="1">
                      {black_list?.owner?.cpf || black_list?.cpf}
                    </Text>
                  </WrapItem>
                ) : (
                  <WrapItem>
                    <Text color="gray.400">CNPJ:</Text>
                    <Text fontWeight="medium" ml="1">
                      {black_list?.owner?.cnpj || black_list.cnpj}
                    </Text>
                  </WrapItem>
                ))
              )}

              {black_list?.entity_type === 'motorist' && (
                <WrapItem d="flex" alignItems="center">
                  <Text color="gray.400">Nota média:</Text>
                  <Text fontWeight="bold" color="yellow.500">
                    <Icon as={RiStarLine} fontSize={20} mx="1" />
                    {black_list?.motorist?.rate_score}
                  </Text>
                </WrapItem>
              )}
            </SimpleGrid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
