import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { FormattedAllFreights } from '../../services/endpoints/freights/getFreights'
import { KanbanCardActive } from './cards/KanbanCardActive'
import { KanbanCardCurrent } from './cards/KanbanCardCurrent'
import { KanbanCardDisabled } from './cards/KanbanCardDisabled'
import { KanbanCardFinished } from './cards/KanbanCardFinished'
import { KanbanCardNoShow } from './cards/KanbanCardNoShow'

interface KanbanListProps {
  type: 'active' | 'current' | 'finished' | 'no-show' | 'disabled' | 'on_road' | 'in_destination' | 'started'
  title: string
  colorProps: {
    color: string
  }
  total: number
  freights: FormattedAllFreights[]
  isLoading?: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export function KanbanList({
  type,
  title,
  colorProps,
  freights,
  total,
  isLoading,
  setPage,
}: KanbanListProps): JSX.Element | null {
  const bg = useColorModeValue('white', 'gray.800')

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPage(state => state + 1)
      }
    })

    if (freights.length > 4 && freights.length < total && document.querySelector(`#sentry${type}`)) {
      intersectionObserver.observe(document.querySelector(`#sentry${type}`) as Element)
    }

    return () => intersectionObserver.disconnect()
  }, [freights.length, type, total, setPage])

  return (
    <Box minW="380px" w="100%">
      <Box bg={bg} borderRadius={10} shadow="lg">
        <Flex alignItems="baseline" justify="space-between" p="2" px="3">
          <Text fontSize="xs" mb={2} fontWeight="bold" color={colorProps.color}>
            {title}
          </Text>
          <Text fontSize="sm" mb={2} fontWeight="bold" color={colorProps.color}>
            {total}
          </Text>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center">
            <Spinner mb="8" thickness="4px" speed="0.65s" emptyColor="gray.200" color={colorProps.color} />
          </Flex>
        ) : freights ? (
          <Box
            px={['1', '2']}
            mt="-3"
            maxH={['calc(100vh - 150px)', 'calc(100vh - 126px)']}
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
                borderRadius: '2px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `gray.500`,
              },
            }}
          >
            {freights.map(freight => {
              switch (type) {
                case 'active':
                  return <KanbanCardActive key={freight.id} freight={freight} />

                case 'current':
                  return <KanbanCardCurrent key={freight.id} freight={freight} />

                case 'started':
                  return <KanbanCardCurrent key={freight.id} freight={freight} />

                case 'on_road':
                  return <KanbanCardCurrent key={freight.id} freight={freight} />

                case 'in_destination':
                  return <KanbanCardCurrent key={freight.id} freight={freight} />

                case 'finished':
                  return <KanbanCardFinished key={freight.id} freight={freight} />

                case 'no-show':
                  return <KanbanCardNoShow key={freight.id} freight={freight} />

                case 'disabled':
                  return <KanbanCardDisabled key={freight.id} freight={freight} />

                default:
                  return ''
              }
            })}
            {freights.length > 4 && freights.length < total && (
              <Flex justifyContent="center" id={`sentry${type}`}>
                <Spinner
                  mb="8"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color={colorProps.color}
                />
              </Flex>
            )}
          </Box>
        ) : (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription justifyContent="center">
              <Text fontSize="lg" color="red.500" fontWeight="bold">
                Erro ao carregar fretes!
              </Text>
              Verifique sua conexão com a internet ou entre em contato com o administrador do sistema.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Box>
  )
}
