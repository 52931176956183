/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'

export interface CreateFreightExpense {
  freight_id: string
  user_id: string
  cte_id?: string
  document_recipient: string
  name_recipient: string
  transporter?: string
  number?: string
  observation?: string
  pix?: string
  bank?: string
  account_type?: string
  agency?: string
  account?: string
  payment_at?: Date
  receipt_at?: Date
  paid?: boolean
  value: number
  attachment_file?: FileList
  status: string
  event: string
  type: string
}

export const eventOptions = [
  { label: '', value: '' },
  { label: 'Adiantamento de Diesel', value: 'diesel_advance' },
  { label: 'Adiantamento do frete', value: 'freight_advance' },
  { label: 'Compra de equipamentos', value: 'purchase_of_equipment' },
  { label: 'Conhecimento de transporte', value: 'transport_knowledge' },
  {
    label: 'Conhecimento de transporte eletrônico',
    value: 'electronic_transport_knowledge',
  },
  { label: 'Cupom fiscal', value: 'tax_coupon' },
  { label: 'Despesa aérea', value: 'air_expense' },
  { label: 'Despesas operacionais', value: 'operational_expenses' },
  { label: 'Diesel', value: 'diesel' },
  { label: 'Fatura', value: 'bill' },
  { label: 'Nota fiscal', value: 'invoice' },
  { label: 'Nota fiscal eletrônica', value: 'electronic_invoice' },
  { label: 'Nota fiscal de fatura', value: 'bill_invoice' },
  { label: 'Nota fiscal de serviço', value: 'service_invoice' },
  { label: 'Ordens de serviço', value: 'service_orders' },
  { label: 'Recibo', value: 'receipt' },
  { label: 'Sem recibo', value: 'no_receipt' },
  { label: 'Saldos de frete', value: 'freight_balances' },
  { label: 'Total da prestação do CTRC', value: 'total_ctrc_installment' },
]

export const typeOptions = [
  { label: '', value: '' },
  { label: 'Amarração', value: 'lashing' },
  { label: 'Chapa', value: 'laborer' },
  { label: 'Coleta frustrada', value: 'failed_pickup' },
  { label: 'Coletas', value: 'pickups' },
  { label: 'Combustível', value: 'fuel' },
  { label: 'Danos materiais', value: 'material_damage' },
  { label: 'Deslocamento', value: 'displacement' },
  { label: 'Despacho aeroporto', value: 'airport_dispatch' },
  { label: 'Despesa de sinistro', value: 'claim_expense' },
  { label: 'Entregas', value: 'deliveries' },
  { label: 'Escolta de cargas', value: 'cargo_escort' },
  { label: 'Hospedagem', value: 'accommodation' },
  { label: 'Hotline', value: 'hotline' },
  { label: 'Materiais para carga e descarga', value: 'loading_and_unloading_materials' },
  { label: 'Multas', value: 'fines' },
  { label: 'Passagem aérea', value: 'airfare' },
  { label: 'Pgto motorista', value: 'driver_payment' },
  { label: 'Serviços', value: 'services' },
  { label: 'Transporte', value: 'transportation' },
  { label: 'Agenciamento', value: 'agency' },
  { label: 'Complemento de frete', value: 'freight_complement' },
  { label: 'Despesas aéreas', value: 'air_expenses' },
  { label: 'Diária', value: 'daily' },
  { label: 'Descarga', value: 'discharge' },
  { label: 'Pedágio', value: 'toll' },
]

async function createFreightExpense(data: CreateFreightExpense) {
  const output = await apiServer.post('/create-freight-expense/', data)
  queryClient.invalidateQueries('freight')
  queryClient.invalidateQueries('list-all-financial-title')
  queryClient.invalidateQueries('list-all-financial-title-history')
  return output
}

export function usePostFreightExpense(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateFreightExpense, unknown>,
) {
  return useMutation(async (data: CreateFreightExpense) => createFreightExpense(data), options)
}
