import { Button, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

type Props = {
  handleSavePermissions: () => Promise<void>
  isLoading: boolean
}

export function FormEditPermissionsToOneRole({ handleSavePermissions, isLoading }: Props): JSX.Element {
  const history = useHistory()

  return (
    <>
      <Flex justifyContent="space-between" gridGap="2">
        <Button colorScheme="blue" variant="outline" onClick={() => history.goBack()}>
          Cancelar
        </Button>
        <Button colorScheme="orange" variant="outline" onClick={handleSavePermissions} isLoading={isLoading}>
          Salvar
        </Button>
      </Flex>
    </>
  )
}
