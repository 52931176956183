import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { RiDislikeLine } from 'react-icons/ri'
import { toastError } from '../../../../../config/error/toastError'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { unlinkMotorist } from '../../../../../store/slices/freightSlice'

interface UnlinkMotoristModalProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
}

export function UnlinkMotoristModal({ isOpen, onClose, freight_id }: UnlinkMotoristModalProps): JSX.Element {
  const toast = useToast()
  const { isLoading } = useAppSelector(state => state.freightSlice)
  const dispatch = useAppDispatch()

  async function handleUnlink() {
    const error = await dispatch(unlinkMotorist(freight_id))
    if (!error) {
      onClose()
      toast({
        title: 'Motorista desvinculado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    } else {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tem certeza que deseja desvincular o motorista?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>O motorista será desvinculado do frete</ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="red"
            leftIcon={<Icon as={RiDislikeLine} />}
            isLoading={isLoading}
            onClick={handleUnlink}
          >
            Desvincular
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
