import { Button, Icon, useDisclosure } from '@chakra-ui/react'
import { RiLinksLine } from 'react-icons/ri'
import { RiskAnalysisOneFormatted } from '../../../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { LinkMotoristToFreightModal } from '../LinkMotoristToFreightModal'

interface ButtonOptionsProps {
  riskAnalysis: RiskAnalysisOneFormatted
  onCloseShowModal?: () => void
}

export default function ButtonOptions({ riskAnalysis, onCloseShowModal }: ButtonOptionsProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      {riskAnalysis && (
        <>
          {['Aprovado', 'A Checar'].includes(riskAnalysis.status) && (
            <Button
              size="sm"
              leftIcon={<Icon as={RiLinksLine} />}
              colorScheme="green"
              variant="outline"
              onClick={onOpen}
            >
              Link
            </Button>
          )}

          {['Aguardando', 'A Aprovar', 'Aguardando GR', 'A Corrigir', 'Reprovado', 'Cancelado'].includes(
            riskAnalysis.status,
          ) && (
            <Button size="sm" onClick={onOpen} colorScheme={riskAnalysis.color_status}>
              {riskAnalysis.status}
            </Button>
          )}

          <LinkMotoristToFreightModal
            riskAnalysis={riskAnalysis}
            isOpen={isOpen}
            onClose={() => {
              if (onCloseShowModal) onCloseShowModal()
              onClose()
            }}
          />
        </>
      )}
    </>
  )
}
