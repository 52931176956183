import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { VehicleType } from '../../types'

export async function attachTrailerToVehicle(
  vehicle_id: string,
  trailer_id: string,
): Promise<AxiosResponse<VehicleType>> {
  return apiServer.patch('/attach-trailer', {
    vehicle_id,
    trailer_id,
  })
}

interface AttachTrailerParams {
  vehicle_id: string
  trailer_id: string
}

export function useAttachTrailer(
  options?: UseMutationOptions<AxiosResponse, unknown, AttachTrailerParams, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, AttachTrailerParams, unknown> {
  return useMutation(
    async (data: AttachTrailerParams) => attachTrailerToVehicle(data.vehicle_id, data.trailer_id),
    options,
  )
}
