/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

import { apiServer } from '../../api'

import { UpdateMonitoringOccurenceType } from '../../types/MonitoringOccurenceType'

async function updateMonitoringOccurence(data: UpdateMonitoringOccurenceType) {
  const editMonitoringOccurence = await apiServer.put(
    `/freights/monitoring-occurrences/${data.monitoring_occurence_id}`,
    {
      monitoring_type: data.monitoring_type,
      observation: data.observation,
      reason_id: data.reason_id,
    },
  )

  return editMonitoringOccurence.data
}

export function usePutMonitoringOccurence(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateMonitoringOccurenceType, unknown>,
) {
  return useMutation(async (data: UpdateMonitoringOccurenceType) => updateMonitoringOccurence(data), options)
}
