import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { MotoristType, ResponseApiType } from '../../../types'

export interface MotoristPayload {
  id?: string
  cpf: string
  name: string
  phone: string
  mother_name: string
  father_name: string
  rg: string
  rg_dispatcher: string
  rg_uf: string
  rg_dispatch_date: string
  birth: string
  email?: string
  zipcode: string
  address: string
  address_number: string
  district: string
  address_city_id: string | number
  birth_state: string
  driver_license: DriverLicense
  motorist_contacts: MotoristContact[]
}

export interface DriverLicense {
  category: string
  dispatch_date: string
  dispatcher_uf: string
  dispatcher: string
  insurance_number: string
  first_dispatch_date: string
  protocol_number: string
  register_number: string
  validity: string
}

export interface MotoristContact {
  id?: string
  name: string
  phone: string
  type: string
}

const createMotorist = async (data: MotoristPayload): Promise<ResponseApiType<MotoristType>> => {
  const { data: response } = await apiServer.post<ResponseApiType<MotoristType>>(`/create-motorist`, data)

  return response
}

export const useCreateMotorist = (
  options?: UseMutationOptions<ResponseApiType<MotoristType>, unknown, MotoristPayload, unknown>,
): UseMutationResult<ResponseApiType<MotoristType>, unknown, MotoristPayload, unknown> => {
  return useMutation((data: MotoristPayload) => createMotorist(data), options)
}
