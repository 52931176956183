import { useColorMode } from '@chakra-ui/react'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'

export function ToggleColorMode(): JSX.Element {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <>
      {colorMode === 'light' ? (
        <RiMoonLine
          size="30px"
          cursor="pointer"
          onClick={() => {
            toggleColorMode()
          }}
        />
      ) : (
        <RiSunLine
          size="30px"
          cursor="pointer"
          onClick={() => {
            toggleColorMode()
          }}
        />
      )}
    </>
  )
}
