type ArrayAutoComplete = {
  label: string
  value: string | number
}

type getLabelFromAutoCompleteProps = {
  value?: string | number
  array: ArrayAutoComplete[]
}

export const getLabelFromAutoComplete = ({ value, array }: getLabelFromAutoCompleteProps): string => {
  if (!value) return ''
  const object = array.find(obj => String(obj.value) === String(value))
  return String(object?.label)
}
