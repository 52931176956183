import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Switch,
  SwitchProps,
} from '@chakra-ui/react'
import React from 'react'

type UISwitchProps = SwitchProps & {
  name: string
  isDisabled?: string
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
}

export const UISwitch = ({
  helperText,
  isRequired,
  isError,
  errorMessage,
  label,
  name,
  isDisabled,
  ...props
}: UISwitchProps): JSX.Element => {
  return (
    <FormControl isInvalid={isError} isRequired={isRequired} isDisabled={isDisabled}>
      <Box>
        <FormLabel htmlFor={name} fontSize="14" mb="1.5">
          {label}
        </FormLabel>
        <Switch id={name} {...props} focusBorderColor="orange.500" />
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}
