/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

async function deleteUser(user_id: string) {
  return apiServer.delete('/users', {
    params: {
      id: user_id,
    },
  })
}

export function useDeleteUser(options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (user_id: string) => deleteUser(user_id), options)
}
