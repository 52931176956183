import { Button, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { RiskAnalysisOneFormatted } from '../../../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { usePatchRiskAnalysis } from '../../../../../../services/endpoints/risk-analysis/patchRiskAnalysis'

interface UpdateRiskAnalysisInfosProps {
  riskAnalysis: RiskAnalysisOneFormatted
}

export function UpdateRiskAnalysisInfos({ riskAnalysis }: UpdateRiskAnalysisInfosProps): JSX.Element {
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const [reason, setReason] = useState<string>()

  const handleActiveRiskAnalysis = async () => {
    mutateAsyncChangeStatus({
      id: riskAnalysis.id,
      status: 'awaiting',
    })
  }

  const handleCancelRiskAnalysis = async () => {
    mutateAsyncChangeStatus({
      id: riskAnalysis.id,
      status: 'canceled',
      reason,
    })
  }
  return (
    <SimpleGrid minChildWidth="300px" spacing="4" mb="3">
      {riskAnalysis.status === 'A Corrigir' && (
        <>
          <Text>Motivo da correção</Text>
          <Textarea value={riskAnalysis.reason} />
          <Button
            colorScheme="orange"
            onClick={() => {
              window.location.href = `/freights/show/${riskAnalysis.freight_id}/pendencies?motorist_id=${riskAnalysis.motorist_id}&vehicle_id=${riskAnalysis.vehicle_id}&integration=buonny&risk_analysis_id=${riskAnalysis.id}`
            }}
            rightIcon={<FaArrowRight />}
          >
            Corrigir cadastro
          </Button>
        </>
      )}

      {!['Aprovado', 'Reprovado', 'Cancelado'].includes(riskAnalysis.status) && (
        <>
          <Text>Para cancelar informe o motivo</Text>
          <Textarea onChange={e => setReason(e.target.value)} />

          <Button
            colorScheme="red"
            isDisabled={!reason}
            isLoading={isLoadingChangeStatus}
            onClick={() => {
              handleCancelRiskAnalysis()
            }}
          >
            Cancelar análise
          </Button>
        </>
      )}

      {riskAnalysis.status === 'Cancelado' && (
        <Button
          colorScheme="green"
          isLoading={isLoadingChangeStatus}
          onClick={() => {
            handleActiveRiskAnalysis()
          }}
        >
          Reenviar para análise
        </Button>
      )}

      {riskAnalysis.status === 'Reprovado' && (
        <>
          <Text>Motivo da reprovação</Text>
          <Textarea value={riskAnalysis.reason} />
        </>
      )}
    </SimpleGrid>
  )
}
