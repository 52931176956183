import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface FreightPendenciesResult {
  cnh: string[]
  motorist: string[]
  vehicle: string[]
  anttOwner: string[]
  vehicleOwner: string[] | null
  trailer: string[]
  trailerOwner: string[] | null
  routePoints: string[]
}

export interface CheckPendenciesParams {
  freight_id: string
  motorist_id: string
  vehicle_id: string
}

export const checkAllPendencies = async ({
  freight_id,
  motorist_id,
  vehicle_id,
}: CheckPendenciesParams): Promise<FreightPendenciesResult | undefined> => {
  if (
    freight_id &&
    motorist_id &&
    vehicle_id &&
    freight_id !== 'undefined' &&
    freight_id !== 'null' &&
    motorist_id !== 'undefined' &&
    motorist_id !== 'null' &&
    vehicle_id !== 'undefined' &&
    vehicle_id !== 'null'
  ) {
    const { data: response } = await apiServer.get<ResponseApiType<FreightPendenciesResult>>(
      'check-pendencies-freight',
      { params: { freight_id, motorist_id, vehicle_id } },
    )
    const { data } = response
    return data
  }
  return undefined
}

export function useCheckAllPendencies(
  params: CheckPendenciesParams,
): UseQueryResult<FreightPendenciesResult> {
  return useQuery(['check-pendencies', params], () => checkAllPendencies(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
