import { Button, Divider, Flex, Heading, HStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { apiServer } from '../../../services/api'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setIsQuotation } from '../../../store/slices/freightSlice'
import { FreightAndQuotationForm, FreightFormData } from '../../operations/forms/FreightAndQuotationForm'
import { freightAndQuotationValidateSchema } from '../../operations/forms/FreightAndQuotationValidation'

export function CreateFreight(): JSX.Element {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(freightAndQuotationValidateSchema),
  })
  const { client_business_group_id } = useAppSelector(state => state.freightSlice)

  const handleCreateFreight = useCallback<SubmitHandler<FreightFormData>>(
    async data => {
      const freightFormData = {
        type: data.type,
        transporter: data.transporter,
        client_id: data.client_id?.value,
        client_contact_id:
          !data.client_contact?.switch_add_contact && data.client_contact?.client_contact_id?.value,
        received_at: data.received_at,
        link_motorist_at: data.link_motorist_at,
        delivered_cargo_at: data.delivered_cargo_at,
        collect_cargo_at: data.collect_cargo_at,
        creator_id: data.creator_id?.value,
        seller_id: data.seller_id?.value,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        suggested_price: data.suggested_price,
        suggested_price_type: data.suggested_price_type,
        service_price: data.service_price,
        has_toll: Boolean(data.has_toll),
        description: data.description,
        collect_complement: data.address_collect,
        delivery_complement: data.address_delivery,
        required_items: data.required_items,
        sector: data.sector,
        vehicle_categories_id: data.vehicle_categories?.map(i => i.value),
        vehicle_bodies_id: data.vehicle_bodies?.map(i => i.value),
        client_contact: data.client_contact?.switch_add_contact && {
          id: data.client_contact?.switch_add_contact ? undefined : data.client_contact_id?.value,
          client_id: data.client_id?.value,
          name: data.client_contact?.name,
          email: data.client_contact?.email,
          phone: data.client_contact?.phone,
          phone2: data.client_contact?.phone2,
          role: data.client_contact?.role,
          client_business_group_id,
        },
        cargo: data.cargo && {
          name: data.cargo.name,
          cargo_category_fretebras_id: data.cargo.cargo_category_fretebras_id,
          width: data.cargo.width,
          height: data.cargo.height,
          depth: data.cargo.depth,
          weight: data.cargo.weight,
          value: data.cargo.value,
          require_tracker: Boolean(data.cargo.require_tracker),
          has_complement: Boolean(data.cargo.has_complement),
        },
        publish_on_fretebras: data.publish_on_fretebras,
      } as FreightFormData

      try {
        const res = await apiServer.post('/create-freight', freightFormData)
        const { data: newFreight } = res.data
        toast({
          title: 'Frete criado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        window.location.href = `/freights/show/${newFreight.id}`
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [client_business_group_id, toast],
  )

  useEffect(() => {
    dispatch(setIsQuotation(false))
  }, [dispatch])

  return (
    <Layout hasBackground>
      <Heading size="lg" fontWeight="normal">
        Cadastrar frete
      </Heading>

      <Divider my="6" />

      <form onSubmit={handleSubmit(handleCreateFreight)} noValidate>
        <FreightAndQuotationForm setValue={setValue} formState={formState} />

        <Flex mt="8" justify="flex-end">
          <HStack spacing="4">
            <Link to="/freights">
              <Button>Cancelar</Button>
            </Link>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={formState.isSubmitting}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log('Validate errors:', formState.errors)
              }}
            >
              Salvar
            </Button>
          </HStack>
        </Flex>
      </form>
    </Layout>
  )
}
