import { Box, Flex, HStack, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import KanbanFiltersFreight from '../../../components/kanban/KanbanFiltersFreight'
import { KanbanList } from '../../../components/kanban/KanbanList'
import { toastError } from '../../../config/error/toastError'
import { useAuth } from '../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import {
  fetchAllFreights,
  FiltersFreightsParams,
  FormattedAllFreights,
  useGetFreights,
} from '../../../services/endpoints/freights/getFreights'

export function ListFreights(): JSX.Element {
  const toast = useToast()
  const { getParams } = useQueryParamUpdater<{
    user_id?: string
    client_id?: string
    initial_date?: string
    final_date?: string
  }>()

  const { storageSeller, storageUser } = useAuth()
  const [filters, setFilters] = useState<FiltersFreightsParams>({
    initial_date: getParams('initial_date') || format(new Date(), 'yyyy-MM-dd'),
    final_date: getParams('final_date') || format(new Date(), 'yyyy-MM-dd'),
    user_id: (storageUser && String(storageUser.value)) || getParams('user_id'),
    client_id: getParams('client_id'),
    seller_id: storageSeller ? String(storageSeller.value) : undefined,
  } as FiltersFreightsParams)

  const [pageFreightsActive, setPageFreightsActive] = useState(1)
  const [pageFreightsStarted, setPageFreightsStarted] = useState(1)
  const [pageOnRoad, setPageFreightsOnRoad] = useState(1)
  const [pageFreightsFinished, setPageFreightsFinished] = useState(1)
  const [pageFreightsNoShow, setPageFreightsNoShow] = useState(1)
  const [pageFreightsDisabled, setPageFreightsDisabled] = useState(1)
  const [pageInDestination, setPageFreightsInDestination] = useState(1)

  const [freightsActive, setFreightsActive] = useState<FormattedAllFreights[]>([])
  const [freightsActiveTotal, setFreightsActiveTotal] = useState<number>(0)
  const [freightsStarted, setFreightsStarted] = useState<FormattedAllFreights[]>([])
  const [freightsTotalStarted, setFreightsTotalStarted] = useState(0)
  const [freightsOnRoad, setFreightsOnRoad] = useState<FormattedAllFreights[]>([])
  const [freightsTotalOnRoad, setFreightsTotalOnRoad] = useState(0)
  const [freightsInDestination, setFreightsInDestination] = useState<FormattedAllFreights[]>([])
  const [freightsTotalInDestination, setFreightsTotalInDestination] = useState(0)
  const [freightsFinished, setFreightsFinished] = useState<FormattedAllFreights[]>([])
  const [freightsFinishedTotal, setFreightsFinishedTotal] = useState(0)
  const [freightsNoShow, setFreightsNoShow] = useState<FormattedAllFreights[]>([])
  const [freightsNoShowTotal, setFreightsNoShowTotal] = useState(0)
  const [freightsDisabled, setFreightsDisabled] = useState<FormattedAllFreights[]>([])
  const [freightsDisabledTotal, setFreightsDisabledTotal] = useState(0)

  const { data: NoShow, isLoading: isLoadingNoShow } = useGetFreights({
    ...filters,
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
    is_no_show: true,
    status: 'disabled',
  })

  useEffect(() => {
    if (NoShow) setFreightsNoShow(NoShow.data)
    if (NoShow) setFreightsNoShowTotal(NoShow.total)
  }, [NoShow])

  const { data: Disabled, isLoading: isLoadingDisabled } = useGetFreights({
    ...filters,
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
    is_no_show: false,
    status: 'disabled',
  })

  useEffect(() => {
    if (Disabled) setFreightsDisabled(Disabled.data)
    if (Disabled) setFreightsDisabledTotal(Disabled.total)
  }, [Disabled])

  useEffect(() => {
    if (isLoadingDisabled) setFreightsDisabled([])
    if (pageFreightsDisabled > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
        page: pageFreightsDisabled,
        is_no_show: false,
        status: 'disabled',
      })
        .then(response => {
          setFreightsDisabled(prevFreights => [...prevFreights, ...response.data])
          setFreightsDisabledTotal(response.total)
        })
        .catch(error => toastError({ toast, error }))
    }
  }, [pageFreightsDisabled, filters, isLoadingDisabled, toast])

  useEffect(() => {
    if (isLoadingNoShow) setFreightsNoShow([])
    if (pageFreightsNoShow > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
        page: pageFreightsNoShow,
        is_no_show: true,
        status: 'disabled',
      })
        .then(response => {
          setFreightsNoShow(prevFreights => [...prevFreights, ...response.data])
          setFreightsNoShowTotal(response.total)
        })
        .catch(error => toastError({ toast, error }))
    }
  }, [pageFreightsNoShow, filters, isLoadingNoShow, toast])

  // KANBAN LIST ACTIVE
  const { data: Active, isLoading: isLoadingActive } = useGetFreights({
    ...filters,
    initial_date: undefined,
    final_date: undefined,
    status: 'active',
  })

  useEffect(() => {
    if (Active) {
      setFreightsActive(Active.data)
      setFreightsActiveTotal(Active.total)
    }
  }, [Active])

  // useEffect(() => {
  //   if (isLoadingActive) setFreightsActive([])
  //   if (pageFreightsActive > 1) {
  //     fetchAllFreights({
  //       ...filters,
  //       initial_date: undefined,
  //       final_date: undefined,
  //       page: pageFreightsActive,
  //       status: 'active',
  //     }).then(response => {
  //       setFreightsActive(prevFreights => [...prevFreights, ...response.data])
  //       setFreightsActiveTotal(response.total)
  //     })
  //   }
  // }, [pageFreightsActive, filters, isLoadingActive])

  // KANBAN LIST STARTED
  const { data: started, isLoading: isLoadingStarted } = useGetFreights({
    ...filters,
    initial_date: undefined,
    final_date: undefined,
    status: 'started',
    place_vehicle: filters?.place_vehicle,
  })

  useEffect(() => {
    if (started) {
      setFreightsStarted(started.data)
      setFreightsTotalStarted(started.total)
    }
  }, [started])

  useEffect(() => {
    if (isLoadingStarted) return
    if (pageFreightsStarted > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: undefined,
        final_date: undefined,
        page: pageFreightsStarted,
        status: 'started',
      }).then(response => {
        setFreightsStarted(prevFreights => [...prevFreights, ...response.data])
      })
    }
  }, [pageFreightsStarted, filters, isLoadingStarted])

  // KANBAN LIST ON ROAD
  const { data: OnRoad, isLoading: isLoadingOnRoad } = useGetFreights({
    ...filters,
    initial_date: undefined,
    final_date: undefined,
    status: 'on_road',
    place_vehicle: filters?.place_vehicle,
  })

  useEffect(() => {
    if (OnRoad) {
      setFreightsOnRoad(OnRoad.data)
      setFreightsTotalOnRoad(OnRoad.total)
    }
  }, [OnRoad])

  useEffect(() => {
    if (isLoadingOnRoad) return
    if (pageOnRoad > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: undefined,
        final_date: undefined,
        page: pageOnRoad,
        status: 'on_road',
      }).then(response => {
        setFreightsOnRoad(prevFreights => [...prevFreights, ...response.data])
      })
    }
  }, [pageOnRoad, filters, isLoadingOnRoad])

  // KANBAN LIST IN DESTINATION
  const { data: onDestination, isLoading: isLoadingInDestination } = useGetFreights({
    ...filters,
    initial_date: undefined,
    final_date: undefined,
    status: 'in_destination',
    place_vehicle: filters?.place_vehicle,
  })

  useEffect(() => {
    if (onDestination) {
      setFreightsInDestination(onDestination.data)
      setFreightsTotalInDestination(onDestination.total)
    }
  }, [onDestination])

  useEffect(() => {
    if (isLoadingInDestination) return
    if (pageInDestination > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: undefined,
        final_date: undefined,
        page: pageInDestination,
        status: 'in_destination',
      }).then(response => {
        setFreightsInDestination(prevFreights => [...prevFreights, ...response.data])
      })
    }
  }, [pageInDestination, filters, isLoadingInDestination])

  // KANBAN LIST FINISHED
  const { data: finished, isLoading: isLoadingFinished } = useGetFreights({
    ...filters,
    initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
    final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
    status: 'finished',
  })

  useEffect(() => {
    if (isLoadingFinished) setFreightsFinished([])
    if (pageFreightsFinished > 1) {
      fetchAllFreights({
        ...filters,
        per_page: 5,
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
        page: pageFreightsFinished,
        status: 'finished',
      }).then(response => {
        setFreightsFinished(prevFreights => [...prevFreights, ...response.data])
      })
    }
  }, [pageFreightsFinished, filters, isLoadingFinished])

  useEffect(() => {
    if (finished) {
      setFreightsFinished(finished.data)
      setFreightsFinishedTotal(finished.total)
    }
  }, [finished])

  // FILTERS EFFECTS
  useEffect(() => {
    setPageFreightsActive(1)
    setPageFreightsFinished(1)
    setPageFreightsNoShow(1)
    setPageFreightsDisabled(1)
    setPageFreightsStarted(1)
    setPageFreightsOnRoad(1)
    setPageFreightsInDestination(1)
  }, [filters])

  useEffect(() => {
    if (isLoadingActive) setFreightsActive([])
    if (pageFreightsActive > 1) {
      fetchAllFreights({
        ...filters,
        initial_date: undefined,
        final_date: undefined,
        page: pageFreightsActive,
        status: 'active',
      })
        .then(response => {
          setFreightsActive(prevFreights => [...prevFreights, ...response.data])
          setFreightsActiveTotal(response.total)
        })
        .catch(error => toastError({ toast, error }))
    }
  }, [pageFreightsActive, filters, isLoadingActive, toast])

  useEffect(() => {
    if (isLoadingFinished) setFreightsFinished([])
    if (pageFreightsFinished > 1) {
      fetchAllFreights({
        ...filters,
        per_page: 5,
        initial_date: filters?.initial_date ? `${filters?.initial_date}T00:00:00` : undefined,
        final_date: filters?.final_date ? `${filters?.final_date}T23:59:59` : undefined,
        page: pageFreightsFinished,
        status: 'finished',
      })
        .then(response => {
          setFreightsFinished(prevFreights => [...prevFreights, ...response.data])
          setFreightsFinishedTotal(response.total)
        })
        .catch(error => toastError({ toast, error }))
    }
  }, [pageFreightsFinished, filters, isLoadingFinished, toast])

  return (
    <Layout isFullScreen SidebarMobile>
      <Box position="absolute" top="-10px" zIndex="10" w="100vw" px="4">
        <KanbanFiltersFreight
          setFilters={setFilters}
          filters={filters}
          isLoading={
            isLoadingActive || isLoadingStarted || isLoadingFinished || isLoadingDisabled || isLoadingNoShow
          }
        />
      </Box>
      {/* <KanbanHeaderFreight setFilters={setFilters} /> */}
      <Flex h="100%">
        <HStack spacing="3" align="flex-start" overflowX="scroll" px="2" pt="10">
          {/* ACTIVE */}
          <KanbanList
            isLoading={isLoadingActive}
            total={freightsActive.some(i => i.status === 'pending') ? freightsActiveTotal : 0}
            setPage={setPageFreightsActive}
            type="active"
            title="ABERTOS"
            colorProps={{ color: 'green.400' }}
            freights={freightsActive.some(i => i.status === 'pending') ? freightsActive : []}
          />

          {/* STARTED */}
          <KanbanList
            isLoading={isLoadingStarted}
            total={freightsTotalStarted}
            setPage={setPageFreightsStarted}
            type="started"
            title="INICIO"
            colorProps={{ color: 'blue.400' }}
            freights={freightsStarted ?? []}
          />
          {/* ON ROAD */}
          <KanbanList
            isLoading={isLoadingOnRoad}
            total={freightsTotalOnRoad}
            setPage={setPageFreightsOnRoad}
            type="on_road"
            title="EM VIAGEM"
            colorProps={{ color: 'purple' }}
            freights={freightsOnRoad ?? []}
          />
          {/* IN DESTINATION */}
          <KanbanList
            isLoading={isLoadingInDestination}
            total={freightsTotalInDestination}
            setPage={setPageFreightsInDestination}
            type="in_destination"
            title="NO DESTINO"
            colorProps={{ color: 'yellow.500' }}
            freights={freightsInDestination ?? []}
          />

          <KanbanList
            isLoading={isLoadingFinished}
            total={freightsFinished.some(i => i.status === 'finished') ? freightsFinishedTotal : 0}
            setPage={setPageFreightsFinished}
            type="finished"
            title="FINALIZADOS"
            colorProps={{ color: 'red.400' }}
            freights={freightsFinished.some(i => i.status === 'finished') ? freightsFinished : []}
          />

          <KanbanList
            isLoading={isLoadingNoShow}
            total={freightsNoShow.some(i => i.status === 'disabled') ? freightsNoShowTotal : 0}
            setPage={setPageFreightsNoShow}
            type="no-show"
            title="NO-SHOW"
            colorProps={{ color: 'red' }}
            freights={freightsNoShow.some(i => i.status === 'disabled') ? freightsNoShow : []}
          />

          <KanbanList
            isLoading={isLoadingDisabled}
            total={freightsDisabled.some(i => i.status === 'disabled') ? freightsDisabledTotal : 0}
            setPage={setPageFreightsDisabled}
            type="disabled"
            title="DESATIVADOS"
            colorProps={{ color: 'magenta' }}
            freights={freightsDisabled.some(i => i.status === 'disabled') ? freightsDisabled : []}
          />
        </HStack>
      </Flex>
    </Layout>
  )
}
