import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'

export function BuonnyOutOfRangeAlert(): JSX.Element {
  return (
    <Alert
      status="error"
      variant="solid"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      borderRadius="md"
      mb={4}
      py={8}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        ATENÇÃO!
      </AlertTitle>
      <AlertDescription maxWidth="lg" fontSize="lg">
        O motorista vinculado a este frete foi consultado junto a Buonny com um valor da carga que não condiz
        com o valor real obtido na emissão do CTe. Por favor, verifique se o valor da carga está correto e
        faça uma nova consulta
      </AlertDescription>
    </Alert>
  )
}
