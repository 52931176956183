import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { apiServer } from '../api'
import { searchCitiesByName } from '../endpoints/cities/searchCities'
import getOnlyNumbers from './getOnlyNumbers'

export interface CepResponse {
  cep: string
  state: string
  city: string
  neighborhood?: string
  street?: string
  service: string
}
export interface CEP {
  zipcode: string
  uf: string
  city?: AutocompleteOption
  district?: string
  address?: string
  service: string
}

export async function searchAddressByCep(zipcode: string): Promise<CEP | null> {
  const cep = getOnlyNumbers(zipcode)
  if (cep) {
    try {
      const { data } = await apiServer.get<CepResponse>(
        `https://brasilapi.com.br/api/cep/v1/${getOnlyNumbers(cep)}`,
      )

      const cityName = String(data.city)
      let cityNameFormatted = String(data.city)

      if (cityName.includes('(')) {
        cityNameFormatted = cityName.split('(')[1].replace(')', '')
      } else {
        cityNameFormatted = cityName
      }

      const cityArray = await searchCitiesByName(cityNameFormatted)

      const city = cityArray.find(
        i => String(i.label).trim().toUpperCase().slice(-2) === String(data.state).trim().toUpperCase(),
      )

      const dataCep = {
        city,
        city_string: data.city,
        state: data.state,
        zipcode: getOnlyNumbers(data.cep),
        district: data.neighborhood,
        address: data.street,
        service: data.service,
        uf: data.state,
      }

      return dataCep
    } catch {
      return null
    }
  }
  return null
}
