import { FaArrowLeft } from 'react-icons/fa'
import QuotationsButtonForm from '.'
import { SelectOption } from '../../../../components/form/types/SelectOption'
import { FormattedQuotation } from '../../../../services/endpoints/freights'

export function getPreviousStatusQuotation(status?: string): SelectOption | undefined {
  if (status) {
    const result = {
      label: '',
      value: '',
    } as SelectOption

    if (status === 'disabled') {
      result.label = 'Finalizada'
      result.value = 'finished'
    }

    if (status === 'finished') {
      result.label = 'A aprovar'
      result.value = 'to_approve'
    }

    if (status === 'to_approve') {
      result.label = 'Em cotação'
      result.value = 'in_quotation'
    }

    if (status === 'in_quotation') {
      result.label = 'Solicitação'
      result.value = 'pending'
    }
    return result
  }
  return undefined
}

export interface ButtonProps {
  quotation: FormattedQuotation
  change_status: SelectOption
}

export default function PreviousStatusQuotationButton({
  quotation,
  change_status,
}: ButtonProps): JSX.Element {
  return (
    <>
      {change_status.label && (
        <QuotationsButtonForm
          change_status={change_status}
          colorScheme="orange"
          leftIcon={FaArrowLeft}
          quotation={quotation}
          label="Voltar fase"
        />
      )}
    </>
  )
}
