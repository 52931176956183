/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEventHandler, forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { IconType } from 'react-icons'
import { MdClear } from 'react-icons/md'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { AutocompleteOption } from './types/AutocompleteOption'

interface AutocompleteProps extends InputProps {
  name: string
  options?: AutocompleteOption[]
  setValue: UseFormSetValue<FieldValues>
  initialValue?: AutocompleteOption
  clearState?: (value: any | undefined) => void
  label?: string
  error?: FieldError
  OptionIcon?: IconType
  onSelectOption?: (option: AutocompleteOption) => void
  loadOptions?: () => Promise<AutocompleteOption[]>
}

const AutocompleteBase: ForwardRefRenderFunction<HTMLInputElement, AutocompleteProps> = (
  {
    name,
    options,
    label,
    error,
    setValue,
    initialValue,
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'off',
    onChange,
    clearState,
    onBlur,
    OptionIcon,
    onSelectOption,
    loadOptions,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)
  const [itemSelected, setItemSelected] = useState<AutocompleteOption>()
  const [items, setItems] = useState(options)
  const [showOptions, setShowOptions] = useState(false)

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  // Esse useEffect garante que só o que foi selecionado seja levado em conta
  // Ele precisa vir primeiro, NÃO pode alterar a ordem
  useEffect(() => {
    if (!initialValue?.value) {
      setValue(name, undefined)
    }
    // Desabilitado a dependência do "initialValue" para evitar de eliminar o estado no onSave
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, name])

  // Esse useEffect garante que o estado seja atualizado quando o valor do input ou do inicialValue for alterado
  // Ele precisa vir depois, NÃO pode alterar a ordem
  useEffect(() => {
    if (itemSelected) {
      setValue(name, itemSelected)
      setInputValue(itemSelected.label)
    } else if (initialValue && initialValue.value) {
      setValue(name, initialValue)
      setInputValue(initialValue.label)
    }
  }, [setValue, name, initialValue, itemSelected])

  const filterOptions = useRef(
    debounce(
      async (value: string) => {
        if (options && options.length > 0) {
          setItems(options.filter(option => option.label.toLowerCase().includes(value.toLowerCase())))
        }
      },
      500,
      {},
    ),
  ).current

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target
    if (value) {
      await filterOptions(value)
    }
  }

  return (
    <FormControl position="relative" isInvalid={!!error} isDisabled={isDisabled} isRequired={isRequired}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}
      <InputGroup size={size} ref={inputRef}>
        <Input
          ref={ref}
          id={name}
          bg={bg}
          onChange={e => {
            setValue(name, undefined) // Limpar o estado ao digitar para não submeter o form sem nada selecionado.
            handleInputChange(e)
            if (onChange) onChange(e)
          }}
          onBlur={e => {
            // setShowOptions(false);
            if (onBlur) onBlur(e)
          }}
          onFocus={async () => {
            setShowOptions(true)
            if (loadOptions) {
              const loadItems = await loadOptions()
              setItems([{ label: '', value: '' }, ...loadItems])
            }
            if (options) setItems([{ label: '', value: '' }, ...options])
          }}
          isDisabled={isDisabled}
          _disabled={{
            opacity: 0.7,
            cursor: 'not-allowed',
          }}
          disableAutocomplete
          disableGoogleAutocomplete
          autoComplete={autoComplete}
          {...rest}
        />
        <InputRightElement h="100%" cursor={isDisabled ? 'not-allowed' : 'pointer'}>
          {!isDisabled && (
            <>
              {showOptions ? (
                <Icon
                  onClick={() => {
                    setShowOptions(false)
                  }}
                  as={RiArrowUpSLine}
                />
              ) : (
                <Icon
                  onClick={() => {
                    setShowOptions(state => !state)
                  }}
                  as={RiArrowDownSLine}
                />
              )}
              <Icon
                fontSize="md"
                onClick={() => {
                  setInputValue('')
                  setShowOptions(false)
                  setValue(name, undefined)
                  if (clearState) {
                    clearState(undefined)
                  }
                }}
                as={MdClear}
              />
            </>
          )}
        </InputRightElement>
      </InputGroup>

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}

      {showOptions && (
        <Stack
          direction="column"
          mt="2"
          bg={bg}
          rounded="md"
          shadow="md"
          borderWidth="1px"
          zIndex={10}
          position="absolute"
          w="100%"
          spacing={-3}
          overflowY="scroll"
          maxHeight="400"
        >
          {items && items.length > 0 ? (
            items.map(option => (
              <Button
                minH="10"
                key={option.value}
                leftIcon={OptionIcon && <Icon as={OptionIcon} fontSize={18} />}
                bg={bg}
                justifyContent="flex-start"
                fontWeight="normal"
                fontSize="md"
                onClick={() => {
                  setItemSelected(option)
                  setValue(name, option)
                  setInputValue(option.label)
                  setShowOptions(false)
                  if (onSelectOption) onSelectOption(option)
                }}
              >
                {option.label}
              </Button>
            ))
          ) : (
            <Text p="2" px="4" textColor="gray.400">
              Nenhuma opção
            </Text>
          )}
        </Stack>
      )}
    </FormControl>
  )
}

export const Autocomplete = forwardRef(AutocompleteBase)
