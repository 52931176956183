import { Select } from '../../../../../components/form'

interface VehicleCategorySelectProps {
  vehicleCategoryCode: string
  setVehicleCategoryCode: (value: string) => void
  errors: any
  setValue: any
}

const initialVehicleCategory = [
  {
    value: 'truck',
    label: 'Caminhão',
  },
  {
    value: 'car',
    label: 'Carro',
  },
  {
    value: 'moto',
    label: 'Motocicleta',
  },
  {
    value: 'bus',
    label: 'Ônibus',
  },
]

export const VehicleCategorySelect = ({
  vehicleCategoryCode,
  setVehicleCategoryCode,
  errors,
  setValue,
}: VehicleCategorySelectProps): JSX.Element => (
  <>
    <Select
      name="vehicleCategory"
      label="Categoria do Veículo"
      placeholder="Selecione a categoria"
      onChange={e => setVehicleCategoryCode(e.target.value)}
      initialValue={vehicleCategoryCode}
      setValue={setValue}
      options={initialVehicleCategory}
      error={errors?.vehicleCategory}
    />
  </>
)
