import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'

import { TrackerDeviceType } from '../../types/TrackerDevicesType'
import {
  getFreightTrackerDevices,
  ReturnFormattedFreightTrackerDevices,
} from './freight-tracker-devices/getAllFreightTrackerDevices'

export interface FiltersTrackerDevices {
  freight_id: string
  vehicle_id: string
}
export interface ReturnFormattedTrackerDevices extends TrackerDeviceType {
  type_formatted: string
  brand_formatted: string
  link_formatted: string
  login_formatted: string
  password_formatted: string
  note_formatted: string
  updated_at_formatted: string
  is_tracker_verified: boolean
}

type GetTrackerDevicesResponse = {
  total: number
  data: ReturnFormattedTrackerDevices[]
}

type VerifyTrackerInput = {
  tracker: TrackerDeviceType
  relations: ReturnFormattedFreightTrackerDevices[]
}

const formatTypeDevice = (type: string): string => {
  if (type === 'BAIT') return 'Isca'
  if (type === 'TRACKER') return 'Rastreador'
  if (type === 'LOCALIZER') return 'Localizador'
  if (type === 'HYBRID') return 'Híbrido'
  return ''
}

const verifyTracker = ({ tracker, relations }: VerifyTrackerInput): boolean => {
  return relations.some(relation => relation.tracker_id === tracker.id)
}

async function getTrackerDevices(params: FiltersTrackerDevices): Promise<GetTrackerDevicesResponse> {
  const { data: trackerDevices } = await apiServer.get<ResponseListApiType<TrackerDeviceType[]>>(
    `/tracker-devices`,
    {
      params,
    },
  )

  const { data: freightTrackerDevices } = await getFreightTrackerDevices({
    freight_id: params.freight_id,
    unpaged: true,
  })

  const { total } = trackerDevices.data
  const trackerDevicesFormatted = trackerDevices.data.data.map((device: TrackerDeviceType) => {
    return {
      ...device,
      is_tracker_verified: verifyTracker({
        tracker: device,
        relations: freightTrackerDevices,
      }),
      type_formatted: formatTypeDevice(device.type),
      brand_formatted: device.brand ? device.brand : 'Não informado',
      note_formatted: device.note || '',
      updated_at_formatted:
        device && device?.updated_at ? format(new Date(device.updated_at), 'dd/MM/yy') : 'Não informado',
    } as ReturnFormattedTrackerDevices
  })
  return { data: trackerDevicesFormatted, total }
}

export function useGetTrackerDevices(
  params: FiltersTrackerDevices,
): UseQueryResult<GetTrackerDevicesResponse> {
  return useQuery(['list-tracker-devices', params], () => getTrackerDevices(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes
  })
}
