import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightType } from '../../types'

export interface IMotoristFeedback {
  statusCode: number
  data: {
    total: number
    data: [
      {
        id: string
        freight_id: string
        motorist_id: string
        reason: string
        recommendation_score: number
        updated_at: string
        created_at: string
        freight: FreightType
      },
    ]
  }
}
interface ReportParams {
  initialDateFeedbacksMotorist: string
  finalDateFeedbacksMotorist: string
}

export async function fetchReportsFeedbacksMotorist({
  initialDateFeedbacksMotorist,
  finalDateFeedbacksMotorist,
}: ReportParams): Promise<IMotoristFeedback> {
  const { data: reports } = await apiServer.get<IMotoristFeedback>(`/list-nps-motorist`, {
    params: {
      initial_date: `${initialDateFeedbacksMotorist}T00:00:00`,
      final_date: `${finalDateFeedbacksMotorist}T23:59:59`,
    },
  })
  return reports
}

export function useReportsFeedbacksMotorist(params: ReportParams): UseQueryResult<IMotoristFeedback> {
  return useQuery(['reports-feedbacks-motorist', params], () => fetchReportsFeedbacksMotorist(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
