import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillEye, AiOutlineLink } from 'react-icons/ai'
import { FaArrowUp } from 'react-icons/fa'
import { FcOvertime } from 'react-icons/fc'
import { ImAttachment } from 'react-icons/im'
import { RiAddCircleFill, RiAddFill, RiMore2Fill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { Ability } from '../../../../../components/ability'
import { AlertDialogConfirm } from '../../../../../components/AlertDialogConfirm'
import { ShowMonitoringOccurences } from '../../../../../components/MonitoringOccurrences/ShowMonitoringOccurences'
import { toastError } from '../../../../../config/error/toastError'
import { queryClient } from '../../../../../config/react-query'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { useDeleteInteractionsTimeline } from '../../../../../services/endpoints/freights/interactions-timeline/deleteInteractionsTimeline'
import {
  fetchAllInteractionsTimeline,
  useGetAllInteractionsTimeline,
} from '../../../../../services/endpoints/freights/interactions-timeline/getAllInteractionsTimeline'
import { usePostInteractionsTimeline } from '../../../../../services/endpoints/freights/interactions-timeline/postInteractionsTimeline'
import { useUploadAttachToTimeline } from '../../../../../services/endpoints/freights/interactions-timeline/uploadAttachToTimeline'
import { UserType } from '../../../../../services/types'
import InteractionsTimelineType from '../../../../../services/types/InteractionsTimelineType'
import { UpdateCommentInTimelineModal } from './modals/UpdateCommentInTimelineModal'

interface InteractionsTimelineProps {
  freight: FormattedOneFreight
  bg: string
}
export interface UpdateInteractionsTimelineData {
  id: string
  comment: string
  user: UserType
}

export function InteractionsTimeline({ freight, bg }: InteractionsTimelineProps): JSX.Element | null {
  const toast = useToast()
  const colorLineTimeline = useColorModeValue('#718096', 'white')
  const bgTimeline = useColorModeValue('#EDF2F7', '#2D3748')
  const { setValue } = useForm({})
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()

  const { isOpen: isOpenUpdate, onClose: onCloseUpdate, onOpen: onOpenUpdate } = useDisclosure()

  const {
    isOpen: isOpenShowMonitoringOccurrence,
    onOpen: onOpenShowMonitoringOccurrence,
    onClose: onCloseShowMonitoringOccurrence,
  } = useDisclosure()

  const [page, setPage] = useState(1)
  const [comment, setComment] = useState('')
  const [fileAttachment, setFileAttachment] = useState<File>()
  const [idToDeleteInteraction, setIdToDeleteInteraction] = useState<string>()
  const [idToEditComment, setIdToEditComment] = useState<string>()
  const [showComment, setShowComment] = useState<boolean>(false)
  const [monitoringOccurrenceId, setMonitoringOccurrenceId] = useState<string>()

  const history = useHistory()

  const [commentUpdated, setCommentUpdated] = useState<InteractionsTimelineType>()

  const {
    data: interactionsTimeline,
    isLoading: isLoadingTimeline,
    isError: isErrorTimeline,
  } = useGetAllInteractionsTimeline({
    freight_id: freight.id,
    per_page: 4,
    page: 1,
  })
  const [interactions, setInteractions] = useState<InteractionsTimelineType[]>([])

  useEffect(() => {
    if (interactionsTimeline) setInteractions(interactionsTimeline.data)
  }, [interactionsTimeline])

  useEffect(() => {
    if (commentUpdated) {
      setInteractions(state => state.filter(i => i.id !== idToEditComment))
      if (interactionsTimeline) setInteractions(interactionsTimeline.data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentUpdated])

  useEffect(() => {
    if (isLoadingTimeline) {
      setInteractions([])
    }
    if (page > 1) {
      fetchAllInteractionsTimeline({
        freight_id: freight.id,
        per_page: 4,
        page,
      }).then(response => {
        setInteractions(state => [...state, ...response.data])
      })
    }
  }, [freight.id, isLoadingTimeline, page])

  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingPost } = usePostInteractionsTimeline({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-interactions-timeline')
    },
  })
  const { mutateAsync: mutateAsyncUpload, isLoading: isLoadingUpload } = useUploadAttachToTimeline({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-interactions-timeline')
    },
  })
  const { mutateAsync: mutateAsyncDelete } = useDeleteInteractionsTimeline({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-interactions-timeline')
    },
  })

  const handleCreateInteraction = async (): Promise<void> => {
    const data = {
      motorist_id: freight?.motorist_id ? String(freight.motorist_id) : undefined,
      freight_id: freight.id,
      comment,
    }
    try {
      const { data: newComment } = await mutateAsyncPost(data)

      toast({
        title: 'Comentário adicionado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })

      setPage(1)
      if (fileAttachment) {
        try {
          if (!newComment.id) return
          await mutateAsyncUpload({
            interactions_timeline_id: newComment.id,
            file: fileAttachment,
            comment,
          })

          toast({
            title: 'Arquivo anexado com sucesso!',
            isClosable: true,
            position: 'top-right',
            status: 'success',
          })

          setFileAttachment(undefined)

          setComment('')
        } catch (err) {
          const error = err as AxiosError

          toast({
            status: 'error',
            title:
              error.response?.data.message || 'Não foi possível anexar o arquivo. Tente novamente mais tarde',
            position: 'top-right',
            isClosable: true,
          })
        }
      } else {
        setComment('')
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  const handleDeleteInteraction = async (id: string): Promise<void> => {
    try {
      await mutateAsyncDelete(id)

      toast({
        title: 'Comentário apagado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })

      setPage(1)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return freight ? (
    <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md">
      <Flex mb={['6', '8']} gridGap="4" direction="column" align="center">
        <Heading size="lg" fontWeight="normal">
          Nova interação
        </Heading>
        <Flex direction="column" w="100%" gridGap={2}>
          <Textarea
            placeholder="Escrever um comentário..."
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
          <Stack direction={['column', 'row']}>
            <Button
              isDisabled={!comment}
              isLoading={isLoadingPost || isLoadingUpload}
              onClick={handleCreateInteraction}
              size="sm"
              colorScheme="green"
              leftIcon={<Icon as={RiAddFill} />}
            >
              Salvar
            </Button>

            <FormControl mt={4} flexDirection="row">
              <Flex align="center" gridGap={2}>
                <Icon as={ImAttachment} mr="1" />
                <Input
                  type="file"
                  name="file"
                  h="32px"
                  p="-1"
                  placeholder="Anexar arquivo"
                  setValue={setValue}
                  onChange={e => {
                    if (e.target.files) {
                      setFileAttachment(e.target.files[0])
                    }
                  }}
                />
              </Flex>
            </FormControl>
          </Stack>
        </Flex>
      </Flex>
      {isLoadingTimeline ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : interactionsTimeline && interactionsTimeline.data.length > 0 ? (
        <>
          <VerticalTimeline lineColor={colorLineTimeline} layout="1-column">
            {interactions.map(timeline => (
              <VerticalTimelineElement
                key={timeline.id}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: bgTimeline,
                  color: colorLineTimeline,
                  paddingBottom: 0,
                  paddingTop: 0,
                  borderRadius: 8,
                }}
                contentArrowStyle={{
                  borderRight: `10px solid  ${bgTimeline}`,
                }}
                iconStyle={{
                  background: bgTimeline,
                  MozBorderTopColors: colorLineTimeline,
                }}
                icon={<FcOvertime />}
              >
                <Flex alignContent="center" justify="space-between">
                  <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    direction={['column', 'column', 'row']}
                    gridGap={[0, 0, 10]}
                    mb={-3}
                    mr={[0, 4]}
                  >
                    <Flex gridGap={4}>
                      {timeline?.user?.name ? (
                        <Text marginRight={4}>
                          {timeline?.user?.name} {timeline.created_at}
                        </Text>
                      ) : (
                        <Text marginRight={4}>{timeline.created_at}</Text>
                      )}

                      <Text
                        maxW={550}
                        cursor={timeline.comment.length > 65 ? 'pointer' : undefined}
                        onClick={() => setShowComment(!showComment)}
                        px="3"
                        borderRadius="8"
                        shadow="md"
                        border="1px"
                      >
                        {showComment
                          ? timeline.comment
                          : timeline.comment.length > 65
                          ? `${timeline.comment.substring(0, 65)}...`
                          : timeline.comment}
                      </Text>
                    </Flex>

                    {timeline.link && (
                      <Box>
                        <Button
                          size="xs"
                          colorScheme="green"
                          justifyContent="right"
                          onClick={() => {
                            if (timeline.action === 'unlinkMotorist') {
                              history.push(timeline.url_redirect)
                            }
                            if (timeline.action === 'newAttachment') {
                              window.open(timeline.url_redirect)
                            }
                            if (timeline.action === 'newMonitoringOccurence') {
                              setMonitoringOccurrenceId(timeline.link)
                              onOpenShowMonitoringOccurrence()
                            }
                          }}
                        >
                          <Icon
                            as={timeline.action === 'newMonitoringOccurence' ? AiFillEye : AiOutlineLink}
                          />
                        </Button>
                      </Box>
                    )}
                  </Flex>

                  <Tooltip hasArrow label="Baixar anexo">
                    <Box position="absolute" bottom={1} right="1">
                      {timeline.attachment_file_url && (
                        <Link target="_blank" href={timeline.attachment_file_url}>
                          <Icon as={ImAttachment} />
                        </Link>
                      )}
                    </Box>
                  </Tooltip>

                  <Box position="absolute" top={1} right="1">
                    <Popover isLazy>
                      <Tooltip hasArrow label="Opções">
                        <Flex fontSize="xs" fontWeight="bold" cursor="pointer" alignItems="center">
                          <PopoverTrigger>
                            <Flex>
                              <Icon as={RiMore2Fill} />
                            </Flex>
                          </PopoverTrigger>
                        </Flex>
                      </Tooltip>

                      <PopoverContent width={125}>
                        <PopoverArrow />

                        <PopoverFooter>
                          <Flex gridGap="2" justifyContent="center">
                            <Stack direction="row">
                              <Ability module="operation" action="update-interactions-timeline">
                                <Button
                                  onClick={() => {
                                    setIdToEditComment(timeline.id)
                                    onOpenUpdate()
                                    setPage(1)
                                  }}
                                  size="xs"
                                  colorScheme="orange"
                                >
                                  Editar
                                </Button>
                              </Ability>

                              <Ability module="operation" action="delete-interaction-timeline">
                                <Button
                                  onClick={() => {
                                    setIdToDeleteInteraction(timeline.id)
                                    onOpenConfirm()
                                  }}
                                  size="xs"
                                  colorScheme="red"
                                >
                                  Apagar
                                </Button>
                              </Ability>
                            </Stack>
                          </Flex>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </Box>
                </Flex>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
          {interactions.length < interactionsTimeline.total ? (
            <VerticalTimeline lineColor={colorLineTimeline} layout="1-column">
              <Box cursor="pointer" onClick={() => setPage(pageCurrent => pageCurrent + 1)}>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: bgTimeline,
                  }}
                  icon={<RiAddCircleFill />}
                />
              </Box>
            </VerticalTimeline>
          ) : (
            <VerticalTimeline lineColor={colorLineTimeline} layout="1-column">
              <Box
                cursor="pointer"
                onClick={() => {
                  setPage(1)

                  setInteractions(interactionsTimeline.data)
                }}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: bgTimeline,
                  }}
                  icon={<FaArrowUp />}
                />
              </Box>
            </VerticalTimeline>
          )}
        </>
      ) : (
        isErrorTimeline && <Text>Erro ao carregar timeline</Text>
      )}

      {idToDeleteInteraction && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title="Apagar interação"
          description="Tem certeza que deseja excluir esta interação da timeline?"
          onClose={onCloseConfirm}
          onConfirm={() => handleDeleteInteraction(idToDeleteInteraction)}
        />
      )}

      {idToEditComment && (
        <UpdateCommentInTimelineModal
          isOpen={isOpenUpdate}
          onClose={() => {
            onCloseUpdate()
          }}
          interaction_id={idToEditComment}
          setCommentUpdated={setCommentUpdated}
        />
      )}

      {isOpenShowMonitoringOccurrence && monitoringOccurrenceId && (
        <ShowMonitoringOccurences
          isOpen={isOpenShowMonitoringOccurrence}
          onClose={onCloseShowMonitoringOccurrence}
          freight_id={freight.id}
          monitoringOccurrenceId={monitoringOccurrenceId}
        />
      )}
    </Box>
  ) : null
}
