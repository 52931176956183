import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { apiServer } from '../../../../../services/api'
import { Select } from '../../../../../components/form/Select'
import { Input } from '../../../../../components/form/Input'
import { toastError } from '../../../../../config/error/toastError'
import { MotoristAttachmentType, ResponseApiType } from '../../../../../services/types'

interface AddAttachmentModalProps {
  isOpen: boolean
  onClose: () => void
  motorist_id: string
}

interface AddAttachmentFormData {
  type: string
  name: string
  description: string
  attachment: File
}

const addAttachmentFormSchema = yup.object().shape({
  type: yup.string().required('campo obrigatório'),
  name: yup.string().required('campo obrigatório'),
  attachment: yup.mixed().required('campo obrigatório'),
  description: yup.string(),
})

export function AddAttachmentModal({ isOpen, onClose, motorist_id }: AddAttachmentModalProps): JSX.Element {
  let attachmentId: string
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(addAttachmentFormSchema),
  })
  const { errors } = formState

  const handleAddAttachment: SubmitHandler<AddAttachmentFormData> = async data => {
    try {
      const formData = new FormData()
      formData.append('attachment', data.attachment)
      formData.append('motorist_id', motorist_id)
      formData.append('type', data.type)
      formData.append('name', data.name)
      formData.append('description', data.description)
      const { data: res } = await apiServer.post<ResponseApiType<MotoristAttachmentType>>(
        '/motorists/attachments',
        formData,
      )
      const { data: responseAttach } = res
      attachmentId = responseAttach.id

      toast({
        status: 'success',
        title: 'Anexo adicionado com sucesso!',
        position: 'top-right',
      })
      onClose()
    } catch (error) {
      await apiServer.delete(`/motorists/attachments/${attachmentId}`)
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleAddAttachment)} noValidate>
          <ModalHeader>Adicionar anexo para o motorista</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="6">
              <Select
                name="type"
                label="Tipo de documento"
                setValue={setValue}
                error={errors.type}
                options={[
                  { label: '', value: '' },
                  { label: 'CNH', value: 'cnh' },
                  { label: 'RG', value: 'rg' },
                  {
                    label: 'Comprovante de residência',
                    value: 'address_proof',
                  },
                  {
                    label: 'Documento de dependente',
                    value: 'dependent_doc',
                  },
                  { label: 'Outro', value: 'other' },
                ]}
                isRequired
              />
              <Input name="name" label="Nome do arquivo" setValue={setValue} error={errors.name} isRequired />
              <Input name="description" label="Descrição" setValue={setValue} error={errors.description} />
              <Input
                name="attachment"
                type="file"
                label="Arquivo"
                setValue={setValue}
                error={errors.attachment}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiAttachmentLine} />}
              isLoading={formState.isSubmitting}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
