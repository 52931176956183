/* eslint-disable no-continue */
function calcDiffInMinutes(initialDateTime: Date, finalDateTime: Date): number {
  const diffInMilliseconds = Math.abs(finalDateTime.getTime() - initialDateTime.getTime())
  const diffInMinutes = diffInMilliseconds / 1000 / 60

  return Math.ceil(diffInMinutes)
}

function addMinutesToDate(date: Date, minutes: number): Date {
  let minutesToSet = minutes
  let hoursToSet = 0
  let daysToSet = 0

  if (minutes > 60) {
    hoursToSet = Math.floor(minutes / 60)
    minutesToSet = minutes % 60
  }

  if (hoursToSet > 24) {
    daysToSet = Math.floor(hoursToSet / 24)
    hoursToSet %= 24
  }

  const newDate = new Date(date)
  newDate.setMinutes(newDate.getMinutes() + minutesToSet)
  newDate.setHours(newDate.getHours() + hoursToSet)
  newDate.setDate(newDate.getDate() + daysToSet)

  return newDate
}

function defineInitialDateTime(startDateTime: Date, validStartHour: number, validEndHour: number): Date {
  const actualInitialDateTime = startDateTime
  let actualInitialDate = actualInitialDateTime.getDate()
  let actualInitialHour = actualInitialDateTime.getHours()

  if (actualInitialHour < validStartHour) {
    actualInitialHour = validStartHour
  }
  if (actualInitialHour >= validEndHour) {
    actualInitialDate += 1
    actualInitialHour = validStartHour
  }

  const initialDateTime = new Date(
    actualInitialDateTime.getFullYear(),
    actualInitialDateTime.getMonth(),
    actualInitialDate,
    actualInitialHour,
    0,
  )

  return initialDateTime
}

function calcSubHours(date: Date, maxConsecutiveDrivingTimeInMinutes: number): number {
  const limitDate = new Date(date)
  limitDate.setHours(20)
  limitDate.setMinutes(0)
  limitDate.setSeconds(0)
  limitDate.setMilliseconds(0)

  const diffInMinutes = calcDiffInMinutes(date, limitDate)
  if (diffInMinutes > maxConsecutiveDrivingTimeInMinutes) {
    return maxConsecutiveDrivingTimeInMinutes
  }

  return diffInMinutes
}

export function formatDuration(durationMinutes: number): string {
  const minutesPerDay = 1440
  const minutesPerHour = 60

  const days = Math.floor(durationMinutes / minutesPerDay)
  const hours = Math.floor((durationMinutes % minutesPerDay) / minutesPerHour)
  const minutes = Math.round(durationMinutes % minutesPerHour)

  let durationString = ''

  if (days > 0) {
    durationString += `${days} dia${days > 1 ? 's' : ''} `
  }

  if (hours > 0) {
    durationString += `${hours} hora${hours > 1 ? 's' : ''} `
  }

  if (minutes > 0) {
    durationString += `${minutes} minuto${minutes > 1 ? 's' : ''}`
  }

  return durationString
}

export type TripDurationResult = {
  totalTripDurationInMinutes: number
  relativeTripDuration: string
}

export function calculateTripDuration(
  distanceKm: number,
  averageSpeedKmh: number,
  startDateTime: Date,
): TripDurationResult {
  const maxDrivingTimePerDayInMinutes = 600 // 10 hours
  const maxConsecutiveDrivingTimeInMinutes = 330 // 5.5 hours
  const restTimePerDayInMinutes = 660 // 11 hours
  const validStartHour = 5 // 5am
  const validEndHour = 20 // 8pm
  const restTimeAfterConsecutiveDrivingTimeInMinutes = 60 // 1 hour
  let totalDrivingTimeInMinutes = (distanceKm / averageSpeedKmh) * 60
  let totalTripDurationInMinutes = 0
  let dayTripCountInMinutes = 0

  let actualInitialDateTime = defineInitialDateTime(startDateTime, validStartHour, validEndHour)

  const diffInMinutes = calcDiffInMinutes(startDateTime, actualInitialDateTime)
  totalTripDurationInMinutes += diffInMinutes

  while (totalDrivingTimeInMinutes > 0) {
    let tripTimePart = calcSubHours(actualInitialDateTime, maxConsecutiveDrivingTimeInMinutes)
    if (tripTimePart > totalDrivingTimeInMinutes) {
      tripTimePart = totalDrivingTimeInMinutes
    }

    dayTripCountInMinutes += tripTimePart
    totalTripDurationInMinutes += tripTimePart
    totalDrivingTimeInMinutes -= tripTimePart
    if (totalDrivingTimeInMinutes <= 0) break

    if (dayTripCountInMinutes >= maxDrivingTimePerDayInMinutes) {
      totalTripDurationInMinutes += restTimePerDayInMinutes
      dayTripCountInMinutes = 0

      let newInitialDateTime = addMinutesToDate(actualInitialDateTime, restTimePerDayInMinutes)

      newInitialDateTime = defineInitialDateTime(newInitialDateTime, validStartHour, validEndHour)

      const newDiffInMinutes = calcDiffInMinutes(actualInitialDateTime, newInitialDateTime)
      totalTripDurationInMinutes += newDiffInMinutes

      actualInitialDateTime = newInitialDateTime
      continue
    }

    if (tripTimePart === maxConsecutiveDrivingTimeInMinutes) {
      totalTripDurationInMinutes += restTimeAfterConsecutiveDrivingTimeInMinutes
    }

    let newInitialDateTime = addMinutesToDate(actualInitialDateTime, tripTimePart)

    newInitialDateTime = defineInitialDateTime(newInitialDateTime, validStartHour, validEndHour)

    const newDiffInMinutes = calcDiffInMinutes(actualInitialDateTime, newInitialDateTime)
    totalTripDurationInMinutes += newDiffInMinutes

    actualInitialDateTime = newInitialDateTime
  }

  return {
    relativeTripDuration: formatDuration(totalTripDurationInMinutes),
    totalTripDurationInMinutes,
  }
}
