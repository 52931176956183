import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import { apiServer } from '../../../api'

export interface IChangeStatusChecklist {
  checklist_id: string
  analyst_id: string
  status: string
  evaluated_at: string | Date
  comments?: string
  checklist_items?: IChecklistItem[]
}

export interface IChecklistItem {
  id: string
  content_label: string
  type: string
  checklist_risk_analysis_id: string
  comments: string
  content_value: string
}

async function patchChecklistRiskAnalysis(data: IChangeStatusChecklist) {
  return apiServer.patch('/change-status-checklist-risk-analysis', data)
}

export function usePatchChecklistRiskAnalysis(
  options: UseMutationOptions<AxiosResponse, unknown, IChangeStatusChecklist, unknown>,
): UseMutationResult<AxiosResponse, unknown, IChangeStatusChecklist, unknown> {
  return useMutation(async data => patchChecklistRiskAnalysis(data), options)
}
