import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import InteractionsTimelineType from '../../../types/InteractionsTimelineType'
import { ResponseListApiType } from '../../../types/ResponseApiType'

type GetInteractionsTimelineResponse = {
  total: number
  data: InteractionsTimelineType[]
}

interface FilterInteractionsTimelineData {
  freight_id?: string
  motorist_id?: string
  user_id?: string
  initial_date?: Date
  final_date?: Date
  page?: number
  per_page?: number
}

export async function fetchAllInteractionsTimeline(
  params: FilterInteractionsTimelineData,
): Promise<GetInteractionsTimelineResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<InteractionsTimelineType[]>>(
    '/list-interaction-timeline',
    {
      params,
    },
  )
  const { data } = res
  const { total } = data

  const InteractionsTimeline = data.data.map(timeline => {
    return {
      ...timeline,
      created_at: format(new Date(timeline.created_at), "dd/MM/yy 'às' HH:mm"),
    }
  })

  return { total, data: InteractionsTimeline }
}

export function useGetAllInteractionsTimeline(
  params: FilterInteractionsTimelineData,
): UseQueryResult<GetInteractionsTimelineResponse> {
  return useQuery(['list-all-interactions-timeline', params], () => fetchAllInteractionsTimeline(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
