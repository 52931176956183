import { Grid, GridItem, Text, Switch, VStack } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

interface RouteConfigSwitchesProps {
  isOptimizedRoute: boolean
  setIsOptimizedRoute: (value: boolean) => void
  alternativeRoutes: string
  setAlternativeRoutes: (value: string) => void
  typeRoute: string
  setTypeRoute: (value: string) => void
  setValue: any
  errors?: any
}

export const RouteConfigSwitches = ({
  isOptimizedRoute,
  setIsOptimizedRoute,
  alternativeRoutes,
  setAlternativeRoutes,
  typeRoute,
  setTypeRoute,
  setValue,
  errors,
}: RouteConfigSwitchesProps): JSX.Element => (
  <VStack align="stretch" spacing="6" my="6" w="full">
    <Grid templateColumns="1fr auto" gap={4} alignItems="center">
      <GridItem>
        <Text size="md" fontWeight="bold">
          Tipo da Rota
        </Text>
      </GridItem>
      <GridItem>
        <Select
          value={typeRoute}
          onChange={e => setTypeRoute(e.target.value)}
          maxW="250px"
          options={[
            { value: 'efficient', label: 'Rota Eficiente' },
            { value: 'short', label: 'Rota Curta' },
            { value: 'avoid_tolls', label: 'Evitar Pedágios' },
          ]}
          setValue={setValue}
          name="typeRoute"
          error={errors.typeRoute}
        />
      </GridItem>

      <GridItem>
        <Text size="md" fontWeight="bold">
          Qtd. Rotas Alternativas
        </Text>
      </GridItem>
      <GridItem>
        <Select
          value={alternativeRoutes}
          onChange={e => setAlternativeRoutes(e.target.value)}
          maxW="250px"
          setValue={setValue}
          name="alternativeRoutes"
          options={[
            { value: '0', label: '0' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
          ]}
          error={errors.alternativeRoutes}
        />
      </GridItem>

      <GridItem>
        <Text size="md" fontWeight="bold">
          Otimizar Rota
        </Text>
      </GridItem>
      <GridItem>
        <Switch isChecked={isOptimizedRoute} onChange={() => setIsOptimizedRoute(!isOptimizedRoute)} />
      </GridItem>
    </Grid>
  </VStack>
)
