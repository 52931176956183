import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form'
import { apiServer } from '../../api'
import { CteIssuanceRequestType } from '../../types/CteIssuanceRequestType'
import { CteIssuanceRequestStatusEnum } from '../../types/EnumTypes'
import { formatCteIssuanceRequestStatus } from '../../utils/formatCteIssuanceRequestStatus'

type CteIssuanceRequestTypeFormatted = CteIssuanceRequestType & {
  formatted_status: string
}

type ListCteIssuanceRequestResponse = {
  total: number
  data: CteIssuanceRequestTypeFormatted[]
}

export type FilterCteIssuanceRequestsType = {
  freight_number?: string
  status?: CteIssuanceRequestStatusEnum
  creator_id?: SelectOption
  initial_date?: string
  final_date?: string
  motorist_id?: SelectOption
  page?: number
}

async function listCteIssuanceRequest(params: FilterCteIssuanceRequestsType) {
  Object.assign(params, {
    ...params,
    motorist_id: params.motorist_id?.value,
    creator_id: params.creator_id?.value,
  })

  const { data: response } = await apiServer.get(`/cte-issuance-requests`, {
    params,
  })

  const { data } = response
  const { total } = data

  const formattedCteIssuanceRequests = data.data.map((cteIssuanceRequest: CteIssuanceRequestType) => {
    return {
      ...cteIssuanceRequest,
      formatted_status: formatCteIssuanceRequestStatus(cteIssuanceRequest.status),
      created_at: format(new Date(cteIssuanceRequest.created_at), 'dd/MM/yy HH:mm'),
    }
  })

  return { total, data: formattedCteIssuanceRequests }
}

export function useGetAllCteIssuanceRequest(
  params: FilterCteIssuanceRequestsType,
): UseQueryResult<ListCteIssuanceRequestResponse> {
  return useQuery(['cte-issuance-requests', params], () => listCteIssuanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
