import {
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { getStatus } from '../../../services/endpoints/freights/getFreights'
import { colorStatusFreight } from '../../../services/utils/colorStatusFreight'
import { useAppDispatch, useAppSelector } from '../../../store'
import { changeFrightStatus } from '../../../store/slices/freightSlice'

interface FreightStatusPopoverProps {
  status: string
  freight_id: string
  next_status: string
}

const FreightStatusPopover = ({
  status,
  freight_id,
  next_status,
}: FreightStatusPopoverProps): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleOpen = () => setIsPopoverOpen(true)
  const handleClose = () => setIsPopoverOpen(false)

  const { isLoading: isLoadingFreight } = useAppSelector(state => state.freightSlice)
  const dispatch = useAppDispatch()
  const toast = useToast()
  async function handleModelNextStatus(id: string, currentStatus: string) {
    const error = await dispatch(changeFrightStatus({ freight_id: id, status: currentStatus }))
    if (!error) {
      toast({
        title: 'Status alterado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    } else {
      toastError({ toast, error })
    }
  }

  return (
    <Popover isOpen={isPopoverOpen} onClose={handleClose} size="md" isLazy>
      <PopoverTrigger>
        <Button
          size="sm"
          color={colorStatusFreight(getStatus(status))}
          rounded="full"
          cursor="pointer"
          bg="whiteAlpha.800"
          onClick={handleOpen}
        >
          <Tooltip label="Clique aqui para atualizar o status do frete.">
            <>{getStatus(status)}</>
          </Tooltip>
        </Button>
      </PopoverTrigger>
      {isPopoverOpen && (
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton onClick={handleClose} />
          <PopoverHeader>Atualize o status do frete</PopoverHeader>
          <PopoverBody>
            <Button
              disabled={isLoadingFreight || next_status === status || !next_status || !freight_id}
              colorScheme="blue"
              key={freight_id}
              isLoading={isLoadingFreight}
              onClick={() => handleModelNextStatus(freight_id, String(next_status))}
            >
              Avançar o status do frete
              <Icon as={RiArrowRightSLine} fontSize={20} />
            </Button>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default FreightStatusPopover
