import {
  Button,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiCloseCircleLine } from 'react-icons/ri'
import { Select } from '../../../components/form/Select'
import { toastError } from '../../../config/error/toastError'
import { useAppDispatch, useAppSelector } from '../../../store'
import { disableFreight } from '../../../store/slices/freightSlice'

interface DisableFreightModalProps {
  isOpen: boolean
  onClose: () => void
  freight_ids: string[]
}
export interface DisableData {
  status: string
  disabled_reason: string
  description: string
}

export function DisableFreightModal({ isOpen, onClose, freight_ids }: DisableFreightModalProps): JSX.Element {
  const { setValue } = useForm()
  const [description, setDescription] = useState('')
  const [selectedReason, setSelectedReason] = useState<string>()
  const toast = useToast()
  const { isLoading } = useAppSelector(state => state.freightSlice)
  const dispatch = useAppDispatch()

  async function handleDisableFreight() {
    if (selectedReason === undefined || selectedReason === '') {
      toast({
        title: 'Motivo obrigatório!',
        status: 'error',
        position: 'top',
      })
      return
    }
    if (selectedReason === 'other' && description.trim().length === 0) {
      toast({
        title: 'Descrição obrigatória nesse caso!',
        status: 'error',
        position: 'top',
      })
      return
    }

    const error = await dispatch(
      disableFreight(freight_ids, {
        status: 'disabled',
        disabled_reason: selectedReason,
        description,
      }),
    )

    if (!error) {
      onClose()
      toast({
        title: 'Frete desativado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    } else if (error) {
      toastError({ toast, error })
    }

    setSelectedReason('')
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={() => {
        onClose()
        setSelectedReason('')
        setDescription('')
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl">Tem certeza que deseja desativar o frete?</ModalHeader>
        <ModalCloseButton />

        <Grid gap="4" templateColumns="repeat(12, 1fr)" justifyContent="center" alignItems="center" p="6">
          <GridItem colSpan={12}>
            <Text fontSize="md" fontWeight="bold" my="2">
              Informar o motivo:
            </Text>
            <Select
              name="select_reason"
              setValue={setValue}
              onSelectOption={option => {
                setSelectedReason(option.value)
              }}
              options={[
                { label: '', value: '' },
                { label: 'Não achou motorista (no-show)', value: 'no_show' },
                { label: 'Sinistro', value: 'sinister' },
                {
                  label: 'Cliente cancelou a carga',
                  value: 'canceled_by_client',
                },
                { label: 'Outro', value: 'other' },
              ]}
            />
          </GridItem>

          <GridItem colSpan={12}>
            <Text fontSize="md" fontWeight="bold" my="2">
              Descrição:
            </Text>
            <Textarea
              name="description"
              size="md"
              value={description}
              onChange={e => {
                setDescription(e.target.value)
              }}
            />
          </GridItem>

          <GridItem colSpan={4}>
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose()
                setSelectedReason('')
                setDescription('')
              }}
            >
              Cancelar
            </Button>
          </GridItem>
          <GridItem colSpan={4}>
            <Button
              colorScheme="red"
              leftIcon={<Icon as={RiCloseCircleLine} />}
              isLoading={isLoading}
              onClick={handleDisableFreight}
            >
              Desativar
            </Button>
          </GridItem>
        </Grid>
      </ModalContent>
    </Modal>
  )
}
