import { FreightType } from '../../../types'

export const isFreightWithoutCte = (freight: FreightType): boolean => {
  return (
    freight.origin_id !== freight.destination_id &&
    !freight.cte &&
    ['on_road', 'in_destination', 'delivered', 'finished'].includes(freight.status) &&
    Array.isArray(freight.ctes) &&
    freight.ctes.length === 0
  )
}
