import { Button, useDisclosure, useToast } from '@chakra-ui/react'
import { RiUser2Fill } from 'react-icons/ri'
import { toastError } from '../../config/error/toastError'
import { queryClient } from '../../config/react-query'
import { AttachOwnerToVehicleOrTrailerModal } from '../../pages/owners/modals/AttachOwnerToVehicleOrTrailerModal'
import { apiServer } from '../../services/api'
import OwnerType from '../../services/types/OwnerType'
import { Ability } from '../ability'
import { AlertDialogConfirm } from '../AlertDialogConfirm'

type LinkOrUnlinkOwnerProps = {
  trailer_id?: string
  vehicle_id?: string
  motorist_id?: string
  owner?: OwnerType
  onUnlinkRevalidate?: () => void
  onLinkRevalidate?: () => void
}

export function LinkOrUnlinkOwner({
  trailer_id,
  vehicle_id,
  motorist_id,
  owner,
  onUnlinkRevalidate,
  onLinkRevalidate,
}: LinkOrUnlinkOwnerProps): JSX.Element {
  const toast = useToast()
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const {
    isOpen: isOpenAttachOwnerToTrailerOrTrailerModal,
    onOpen: onOpenAttachOwnerToTrailerOrTrailerModal,
    onClose: onCloseAttachOwnerToTrailerOrTrailerModal,
  } = useDisclosure()

  const handleDisconnectOwner = async () => {
    if (owner) {
      if (vehicle_id) {
        try {
          await apiServer.patch(`/owner-detach-vehicle`, {
            owner_id: owner.id,
            vehicle_id,
          })
          onCloseAlertDialog()
          toast({
            title: 'Desvinculado com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          queryClient.invalidateQueries('vehicle')
          queryClient.invalidateQueries('motorist')
          queryClient.invalidateQueries('check-pendencies')
          if (onUnlinkRevalidate) onUnlinkRevalidate()
        } catch (error) {
          toastError({ toast, error })
        }
      } else if (trailer_id) {
        try {
          await apiServer.patch(`/owner-detach-trailer`, {
            owner_id: owner.id,
            trailer_id,
          })
          onCloseAlertDialog()
          toast({
            title: 'Desvinculado com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          queryClient.invalidateQueries('vehicle')
          queryClient.invalidateQueries('motorist')
          queryClient.invalidateQueries('trailer')
          queryClient.invalidateQueries('check-pendencies')
          if (onUnlinkRevalidate) onUnlinkRevalidate()
        } catch (error) {
          toastError({ toast, error })
        }
      } else {
        toastError({ toast, error: 'Não foi possível desvincular' })
      }
    }
  }

  return (
    <>
      {owner ? (
        <>
          <Ability module="operation" action="detach-vehicle-owner">
            <Button
              minWidth="200"
              size="sm"
              colorScheme="red"
              leftIcon={<RiUser2Fill />}
              onClick={() => {
                onOpenAlertDialog()
              }}
            >
              Desvincular proprietário
            </Button>
          </Ability>

          <AlertDialogConfirm
            title="Desvincular Proprietário"
            description={`Tem certeza que deseja desvincular ${owner.name}?`}
            isOpen={isOpenAlertDialog}
            onClose={onCloseAlertDialog}
            onConfirm={handleDisconnectOwner}
          />
        </>
      ) : (
        <>
          <Button
            minWidth="160"
            size="sm"
            colorScheme="green"
            leftIcon={<RiUser2Fill />}
            onClick={() => {
              onOpenAttachOwnerToTrailerOrTrailerModal()
            }}
          >
            Vincular proprietário
          </Button>

          <AttachOwnerToVehicleOrTrailerModal
            refetchVehicles={() => {
              queryClient.invalidateQueries('vehicle')
              queryClient.invalidateQueries('motorist')
              queryClient.invalidateQueries('owner')
              queryClient.invalidateQueries('trailer')
            }}
            isOpen={isOpenAttachOwnerToTrailerOrTrailerModal}
            onClose={() => {
              queryClient.invalidateQueries('vehicle')
              queryClient.invalidateQueries('motorist')
              queryClient.invalidateQueries('owner')
              queryClient.invalidateQueries('trailer')
              queryClient.invalidateQueries('check-pendencies')
              onCloseAttachOwnerToTrailerOrTrailerModal()
              if (onLinkRevalidate) onLinkRevalidate()
            }}
            motorist_id={motorist_id}
            vehicleOrTrailerId={vehicle_id || trailer_id}
          />
        </>
      )}
    </>
  )
}
