/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEventHandler, forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { IconType } from 'react-icons'
import { MdClear } from 'react-icons/md'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { AutocompleteOption } from './types/AutocompleteOption'

interface AutocompleteOrTypeAsyncProps extends InputProps {
  name: string
  loadOptions: (value: string) => Promise<AutocompleteOption[]>
  setValue: UseFormSetValue<FieldValues>
  initialValue?: AutocompleteOption
  clearState?: (value: string | undefined) => void
  label?: string
  error?: FieldError
  OptionIcon?: IconType
  onSelectOption?: (option: AutocompleteOption) => void
}

const AutocompleteOrTypeAsyncBase: ForwardRefRenderFunction<
  HTMLInputElement,
  AutocompleteOrTypeAsyncProps
> = (
  {
    name,
    loadOptions,
    label,
    error,
    setValue,
    initialValue,
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    onChange,
    onBlur,
    clearState,
    OptionIcon,
    onSelectOption,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)
  const [items, setItems] = useState<AutocompleteOption[]>([])
  const [itemSelected, setItemSelected] = useState<AutocompleteOption>()
  const [isLoading, setIsLoading] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  useEffect(() => {
    if (itemSelected) {
      setValue(name, itemSelected)
      setInputValue(itemSelected.label)
    } else if (initialValue) {
      setValue(name, initialValue)
      setInputValue(initialValue.label)
    } else {
      setValue(name, undefined)
      if (inputRef.current) {
        const inputDOM = inputRef.current.querySelector('input')
        if (inputDOM) {
          inputDOM.value = ''
        }
      }
    }
  }, [setValue, name, itemSelected])

  const getOptions = useRef(
    debounce(
      async (value: string) => {
        setIsLoading(true)
        const options = await loadOptions(value)
        setItems(options)
        setIsLoading(false)

        return options
      },
      500,
      {},
    ),
  ).current

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target
    if (value) {
      await getOptions(value)
    }
  }

  return (
    <FormControl position="relative" isInvalid={!!error} isDisabled={isDisabled} isRequired={isRequired}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}

      <InputGroup size={size} ref={inputRef}>
        <Input
          ref={ref}
          id={name}
          bg={bg}
          onChange={e => {
            handleInputChange(e)
            if (onChange) onChange(e)
            setValue(name, {
              label: e.target.value,
              value: null,
            })
          }}
          onBlur={e => {
            if (onBlur) onBlur(e)
            setTimeout(() => {
              setShowOptions(false)
            }, 300)
          }}
          onFocus={() => {
            setShowOptions(true)
          }}
          isDisabled={isDisabled}
          _disabled={{
            opacity: 0.7,
            cursor: 'not-allowed',
          }}
          disableAutocomplete
          disableGoogleAutocomplete
          autoComplete={autoComplete}
          {...rest}
        />
        <InputRightElement h="100%" cursor={isDisabled ? 'not-allowed' : 'pointer'}>
          {isLoading ? (
            <Spinner size="xs" />
          ) : showOptions ? (
            <Icon
              onClick={() => {
                if (!isDisabled) setShowOptions(false)
              }}
              as={RiArrowUpSLine}
            />
          ) : (
            <Icon
              onClick={() => {
                if (!isDisabled) setShowOptions(state => !state)
              }}
              as={RiArrowDownSLine}
            />
          )}
          {!isDisabled && (
            <Icon
              fontSize="md"
              onClick={() => {
                setInputValue('')
                setShowOptions(false)
                setValue(name, undefined)
                if (clearState) {
                  clearState(undefined)
                }
              }}
              as={MdClear}
            />
          )}
        </InputRightElement>
      </InputGroup>

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}

      {showOptions && items.length > 0 && (
        <Stack
          direction="column"
          mt="2"
          py="2"
          bg={bg}
          rounded="md"
          shadow="md"
          borderWidth="1px"
          zIndex={10}
          position="absolute"
          w="100%"
        >
          {items.map(option => (
            <Button
              key={option.value}
              leftIcon={OptionIcon && <Icon as={OptionIcon} fontSize={18} />}
              bg={bg}
              d="flex"
              alignItems="flex-start"
              flex={1}
              fontWeight="normal"
              fontSize="md"
              onClick={() => {
                setItemSelected(option)
                setValue(name, option)
                setInputValue(option.label)
                setShowOptions(false)
                if (onSelectOption) onSelectOption(option)
              }}
            >
              <Flex w="100%">
                <Heading size="sm">{option.label}</Heading>
                {option.address && <Text color="gray.400">{option.address}</Text>}
              </Flex>
            </Button>
          ))}
        </Stack>
      )}
    </FormControl>
  )
}

export const AutocompleteOrTypeAsync = forwardRef(AutocompleteOrTypeAsyncBase)
