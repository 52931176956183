import { Box, Divider, Flex, Heading, Icon, ModalCloseButton, Text, Tooltip } from '@chakra-ui/react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RiEditFill } from 'react-icons/ri'
import { TextArea } from '../../../components/form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { FormattedAllFreightsInProgress } from '../../../services/endpoints/freights/getFreightsInProgress'
import { usePostInteractionsTimeline } from '../../../services/endpoints/freights/interactions-timeline/postInteractionsTimeline'

interface IObservation {
  id: string
  motorist_id?: string
  comment: string
  last_observation?: string
  freight_number?: string | number
}
interface AddInteractionModalProps {
  freight: FormattedAllFreightsInProgress
}
export function AddInteractionModal({ freight }: AddInteractionModalProps): JSX.Element {
  const filterFormSchema = yup.object().shape({
    comment: yup.string().required('Campo obrigatório'),
  })
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors } = formState
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: postInteractionsTimeline, isLoading: isLoadingInteractionsTimeline } =
    usePostInteractionsTimeline({
      onSuccess: () => {
        queryClient.invalidateQueries('list-all-interactions-timeline')
        queryClient.invalidateQueries('show-interactions-timeline')
        queryClient.invalidateQueries('list-freight-in-progress')
        toast({
          title: 'Comentário salvo com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        })
        onClose()
      },
    })

  const handleCreateNewObservation: SubmitHandler<IObservation> = async (data: IObservation) => {
    try {
      await postInteractionsTimeline({
        comment: data.comment,
        freight_id: freight.id,
        motorist_id: freight.motorist_id,
      })
    } catch (error) {
      toastError({ error, toast })
    }
  }

  return (
    <>
      {freight.last_interaction ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          fontSize="xs"
          direction="row"
          gridGap="2"
        >
          <Tooltip hasArrow placement="top" label={`${freight.last_interaction?.comment}`}>
            <Text textAlign="center" fontWeight="bold">
              {freight.last_interaction_long_at}
            </Text>
          </Tooltip>
          <Tooltip hasArrow placement="top" label="Ver ou cadastrar observação">
            <Flex
              direction="row"
              onClick={onOpen}
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
            >
              <Box>
                <Icon as={RiEditFill} />
              </Box>
            </Flex>
          </Tooltip>
        </Flex>
      ) : (
        <Tooltip hasArrow placement="top" label="Cadastrar uma observação">
          <Flex direction="row" onClick={onOpen} cursor="pointer" justifyContent="center" alignItems="center">
            <Box>
              <Icon as={RiEditFill} />
            </Box>
          </Flex>
        </Tooltip>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="2xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(handleCreateNewObservation)} noValidate>
              <Heading size="md" my="4">
                Cadastre uma nova observação para o frete #{freight.freight_number}
              </Heading>
              <TextArea
                name="comment"
                error={errors.comment}
                setValue={setValue}
                placeholder="Digite uma observação"
                style={{ width: '100%' }}
              />

              <Flex justifyContent="flex-end" gridGap="2" mt="2">
                <Button colorScheme="orange" type="submit" isLoading={isLoadingInteractionsTimeline}>
                  Enviar
                </Button>
                <Button colorScheme="red" onClick={onClose}>
                  Fechar
                </Button>
              </Flex>
              {freight.last_interaction && (
                <>
                  <Divider my="4" />

                  <Heading size="md" mb="4">
                    Última Observação - #{freight.freight_number}
                  </Heading>

                  <Flex justifyContent="flex-start" direction="column" fontSize="lg">
                    <Text whiteSpace="pre-wrap" mb="2">
                      <b>Mensagem:</b> {freight.last_interaction?.comment}
                    </Text>
                    <Text whiteSpace="pre-wrap">
                      <b>Data:</b> {freight.last_interaction_long_at}
                    </Text>
                  </Flex>
                </>
              )}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
