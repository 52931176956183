import { CteIssuanceRequestStatusEnum } from '../types/EnumTypes'

export function getCteIssuanceRequestColorByStatus(status: CteIssuanceRequestStatusEnum): string {
  const loadingRequestStatusWithColor = {
    awaiting_analysis: 'gray',
    rejected: 'red',
    awaiting_issuance_cte: 'blue',
    cte_issued: 'yellow',
    cte_return_to_motorist: 'blue',
    awaiting_advance_request: 'blue',
    advance_request_ongoing: 'yellow',
    advance_request_finished: 'blue',
    finished: 'green',
  }

  return loadingRequestStatusWithColor[status]
}
