/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export async function deleteContactFromClient(client_contact_id: string) {
  return apiServer.delete(`/clients/contacts/${client_contact_id}`)
}

export function useDeleteContactByClient(
  options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>,
) {
  return useMutation(async (client_contact_id: string) => deleteContactFromClient(client_contact_id), options)
}
