import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { TbLayoutColumns } from 'react-icons/tb'
import Sortable from '../DragAndDrop/Sortable'

type CollEditor = {
  column: string
  columnId: string
  onVisibility: boolean
  onVisibilityToggle: () => void
}
export const ColEditor = ({
  column,
  columnId,
  onVisibility = false,
  onVisibilityToggle,
}: CollEditor): JSX.Element => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      aria-label={columnId}
      opacity={onVisibility ? 1 : 0.1}
    >
      <Text size="xs" px={2} fontSize="smaller" fontWeight="bold">
        {column}
      </Text>

      <IconButton
        size="sm"
        backgroundColor="transparent"
        aria-label="columns"
        borderColor="gray.300"
        icon={onVisibility ? <IoMdEye /> : <IoMdEyeOff />}
        onClick={() => onVisibilityToggle()}
      />
    </Flex>
  )
}

type ColumnOrderProps = {
  columns: CollEditor[]
  onColumnOrderChange: (active: string, over: string) => void
  keyValue?: string
}

const TableColumnOrder = ({ columns = [], onColumnOrderChange, keyValue }: ColumnOrderProps): JSX.Element => {
  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <IconButton size="md" aria-label="columns" variant="outline" icon={<TbLayoutColumns />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Ordenar</PopoverHeader>
        <PopoverBody>
          <Sortable
            key={keyValue}
            sortables={columns.map((column, i) => ({
              id: i + 1,
              content: (
                <ColEditor
                  column={column.column}
                  columnId={column.columnId}
                  onVisibility={column.onVisibility}
                  onVisibilityToggle={column.onVisibilityToggle}
                />
              ),
              columnId: column.columnId,
            }))}
            onDragEndChange={onColumnOrderChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default TableColumnOrder
