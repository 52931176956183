import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'

export interface ShowCteResponse {
  id: string
  key: string
  nct: string
  serie: number
  type: string
  emit_date: string
  emitter: string
  origin: string
  destination: string
  taker: string
  installment_value: string
  receive_value: string
  status: string
  freight_id: string
  xml_file: string
  created_at: string
  updated_at: string
  freight: string
  xml_file_url: string
  freight_url: string
  cte_events: {
    id: string
    type: string
    emit_date: string
    cte_id: string
    xml_file?: string
    created_at: string
    updated_at: string
    xml_file_url?: string
  }[]
}

export async function fetchCteDatamex(cte_id: string): Promise<ShowCteResponse | undefined> {
  if (cte_id && cte_id !== 'undefined') {
    const { data } = await apiServer.get<{ data: ShowCteResponse }>(`/ctes/find`, {
      params: {
        id: cte_id,
      },
    })
    return data.data
  }
  return undefined
}

export function useShowCteDatamex(cte_id: string): UseQueryResult<ShowCteResponse> {
  return useQuery(['show_cte_datamex', cte_id], () => fetchCteDatamex(cte_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
