import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

import { CreateTrackerDeviceRequest } from './postTrackerDevices'

interface UpdateTrackerDeviceRequest extends CreateTrackerDeviceRequest {
  id: string
}

async function putTrackerDevice(data: UpdateTrackerDeviceRequest) {
  return apiServer.put(`/tracker-devices/${data.id}`, { ...data })
}
export function useUpdateTrackerDevice(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateTrackerDeviceRequest, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, UpdateTrackerDeviceRequest, unknown> {
  return useMutation(async (data: UpdateTrackerDeviceRequest) => putTrackerDevice(data), options)
}
