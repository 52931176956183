import {
  Button,
  Flex,
  Grid,
  GridItem,
  GridProps,
  Heading,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'
import { useState } from 'react'
import { RiCheckboxCircleFill, RiFileCopyLine, RiSearchFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toastError } from '../../../../config/error/toastError'
import { FormattedOneFreight } from '../../../../services/endpoints/freights'
import { usePostPredictAgreedPrice } from '../../../../services/endpoints/freights/postPredictAgreedPrice'
import { calculateTripDuration } from '../../../../services/utils/calculateTripDuration'
import { copyToClipboard } from '../../../../services/utils/copyToClipboard'
import { dateIsThisMonthCurrent } from '../../../../services/utils/dates/dateIsThisMonthCurrent'
import { buonnyItemTypesOptions } from '../../../../services/utils/getBuonnyItemTypes'
import parseCurrency from '../../../../services/utils/parseCurrency'

interface FreightInfoDetailsProps extends GridProps {
  freight: FormattedOneFreight
}

const FreightInfoDetails = ({ freight }: FreightInfoDetailsProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const { REACT_APP_BASE_WEB } = process.env
  const toast = useToast()
  const [agreedPrice, setAgreedPrice] = useState<string | undefined>(undefined)
  const buonny_cargo_type = buonnyItemTypesOptions.find(i => i.value === freight?.buonny_cargo_type)
  const { mutate: postPredictAgreedPrice, isLoading: loadingPredictAgreedPrice } = usePostPredictAgreedPrice({
    onSuccess: data => {
      if (data && data.data) {
        setAgreedPrice(parseCurrency(String(data.data)))
      }
      toast({
        title: 'Sucesso!',
        description: `O preço foi previsto com sucesso. Valor: ${parseCurrency(
          Number(data.data).toFixed(2),
        )}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  let deliveryDate
  let tripDuration
  if (freight?.distance) {
    const date = new Date()
    date.setHours(12)
    tripDuration = calculateTripDuration(freight.distance, 60, date)
    if (freight.collect_cargo_at) {
      deliveryDate = new Date(freight.collect_cargo_at)
      tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)

      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
    }
  }

  const refClient =
    freight?.client_ref_type && freight?.client_ref_number
      ? `${freight?.client_ref_type?.toUpperCase()}-${freight?.client_ref_number}`
      : '-'

  return (
    <Grid p="4" borderRadius="md" bg={bg} gap="4" templateColumns="repeat(12, 1fr)">
      <GridItem colSpan={[12]} display="flex" justifyContent="space-between">
        <Heading fontSize="md" fontWeight="medium">
          Detalhes do Frete:
        </Heading>

        {freight?.status === 'finished' && (
          <Flex gridGap="2" alignItems="center" fontSize={['sm', 'md', 'lg']}>
            {!dateIsThisMonthCurrent(freight.client_contact?.last_monthly_nps_at) ? (
              <Tooltip
                label={
                  freight.client_contact_id
                    ? 'Copiar link de feedback mensal'
                    : 'Nenhum solicitante selecionado!'
                }
                hasArrow
                placement="auto"
              >
                <Button
                  rightIcon={<Icon as={RiFileCopyLine} fontSize={16} />}
                  size="sm"
                  colorScheme="red"
                  variant="outline"
                  isDisabled={!freight.client_contact_id}
                  onClick={() => {
                    copyToClipboard(
                      `${REACT_APP_BASE_WEB}/client-monthly-nps?client_id=${freight.client_id}&client_contact_id=${freight.client_contact_id}`,
                    )
                    toast({
                      title: 'Link copiado com sucesso!',
                      status: 'success',
                      position: 'top-right',
                      duration: 5000,
                      isClosable: true,
                    })
                  }}
                >
                  NPS mensal
                </Button>
              </Tooltip>
            ) : (
              <Tooltip label="NPS mensal já respondido esse mês" hasArrow placement="auto">
                <Flex color="green">
                  <Icon cursor="pointer" fontSize="2xl" as={RiCheckboxCircleFill} />
                  NPS Mensal respondido
                </Flex>
              </Tooltip>
            )}
          </Flex>
        )}
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Cliente:
        </Heading>
        <Text as={Link} to={`/clients/show/${freight.client_id}`} fontWeight="medium" mt="1" fontSize="sm">
          {freight?.client?.name}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Nº ref cliente:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {refClient}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Operador:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.creator?.name}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Vendedor:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.seller?.name}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Valor (NF):
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.cargo_value}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Valor do cliente:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.service_price && parseCurrency(freight.service_price)}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Preço acordado:{' '}
          <Tooltip
            label="Prever valor do motorista com IA"
            hasArrow
            placement="top"
            bg="gray.500"
            color="white"
          >
            <Button
              size="sm"
              colorScheme="blue"
              variant="outline"
              isLoading={loadingPredictAgreedPrice}
              onClick={() => postPredictAgreedPrice({ freight_id: freight.id })}
            >
              <Icon as={RiSearchFill} fontSize="md" />
            </Button>
          </Tooltip>
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {agreedPrice ||
            (freight?.formatted_agreed_price &&
              `${freight.formatted_agreed_price} / ${freight.formatted_suggested_price_type || ''}`)}
        </Text>
        <Flex alignItems="center" mt="2">
          <a
            href="https://docs.google.com/document/d/1JDHaF3Ds0vWXJ0WoBfCWYvdxo2ur4dz_s02VetveLmE/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <Text fontSize="sm" fontWeight="medium" color="blue.500" textDecoration="underline">
              Entenda mais
            </Text>
          </a>
        </Flex>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Preço sugerido:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {`${freight?.formatted_suggested_price} / ${freight?.formatted_suggested_price_type}`}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Data:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.created_at}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Prev. coleta:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.collect_cargo_at && format(new Date(freight.collect_cargo_at), 'dd/MM/yyyy  HH:mm')}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Prev. entrega:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.delivered_cargo_at && format(new Date(freight?.delivered_cargo_at), 'dd/MM/yyyy HH:mm')}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Prazo de entrega (algoritmo):
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {tripDuration?.relativeTripDuration}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Tipo:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.formatted_type}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Carga:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.cargo?.name}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Peso:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.cargo?.weight} kg
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Tipo de carga:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {buonny_cargo_type?.label}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Tipo de veiculo:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight.vehicle_categories?.map(({ name }) => name).join(' / ')}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Tipo de carroceria:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight.vehicle_bodies?.map(({ name }) => name).join(' / ')}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Exige rastreamento:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.cargo_require_tracker}
        </Text>
      </GridItem>

      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Itens obrigatórios:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.required_items}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Tipo de veículo
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight.vehicle_categories?.map(category => category.name).join(' / ')}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Carrocerias:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight.vehicle_bodies?.map(category => category.name).join(' / ')}
        </Text>
      </GridItem>
    </Grid>
  )
}

export default FreightInfoDetails
