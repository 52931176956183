import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemeTypings,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { InputMask } from '../../../../../../components/form'

interface AlertNodalProps extends ButtonProps {
  title?: string
  titleButton: string
  onConfirm: () => void
  confirmLabel?: string
  cancelLabel?: string
  colorScheme?: ThemeTypings['colorSchemes']
}

export function ConfirmFreightVpoModal({
  title = ' Atenção: Caso a rota exija pagamento de pedágio, a negociação com o motorista deve ser baseada na regra do VPO. Em caso de dúvidas, consulte o GR.',
  titleButton,
  onConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  colorScheme = 'red',
  ...rest
}: AlertNodalProps): JSX.Element {
  const { REACT_APP_ENVIRONMENT } = process.env
  const [numbersProvided, setNumbersProvided] = useState('')
  const [randomNumber, setRandomNumber] = useState<number | undefined>(() => {
    return Math.floor(10000 + Math.random() * 90000)
  })
  const { setValue, setError, formState } = useForm({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  if (isOpen && REACT_APP_ENVIRONMENT === 'local') {
    onConfirm()
    onClose()
  }
  return (
    <>
      <Button
        size={rest.size || 'sm'}
        onClick={() => {
          onOpen()
          setRandomNumber(Math.floor(10000 + Math.random() * 90000))
        }}
        colorScheme={colorScheme}
        {...rest}
      >
        {titleButton}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          setRandomNumber(undefined)
        }}
        closeOnOverlayClick={false}
        size="md"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {`Para confirmar que está ciente, preencha o campo abaixo com o número: `}
            <strong>{randomNumber}</strong>
            <InputMask
              mask="99999"
              name="number"
              setValue={setValue}
              value={numbersProvided}
              onChange={e => setNumbersProvided(e.target.value)}
              error={formState.errors.number}
              clearState={setNumbersProvided}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{cancelLabel}</Button>
            <Button
              colorScheme="red"
              onClick={() => {
                if (Number(numbersProvided) === randomNumber) {
                  onConfirm()
                  onClose()
                } else {
                  setError('number', { message: 'O número digitado precisa ser correto' })
                }
              }}
              ml={3}
            >
              {confirmLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
