/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiRestartLine, RiSearchLine } from 'react-icons/ri'
import { Input } from '../../../../components/form'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { ReportToExcel } from '../../../../components/reports/ReportToExcel'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import {
  FiltersRoutePricesParams,
  fetchAllRoutePrices,
  trackingDeviceType,
  useGetAllRoutePrices,
} from '../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import { getLabelFromAutoComplete } from '../../../../services/utils/getLabelAutoComplete'
import { TableRoutePricesListPage } from './TableRoutePricesListPage'

interface FiltersRoutePrices {
  initial_date?: string
  final_date?: string
  freight_id?: string
  motorist_id?: string
  origin_city_id?: AutocompleteOption
  destination_city_id?: AutocompleteOption
  nome?: string
  phone?: string
  page?: number
  per_page?: number
}

export function HistoryPricesQuotations(): JSX.Element {
  const { getParams, updateQueryParams, addPage } = useQueryParamUpdater<{
    initial_date?: string
    final_date?: string
    destination_name?: string
    destination_value?: string
    origin_name?: string
    origin_value?: string
    page?: number
  }>()

  const initialDate = () => {
    const now = new Date()
    return format(now.setDate(now.getDate() - 30), 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const bg = useColorModeValue('white', 'gray.800')
  const [filters, setFilters] = useState<FiltersRoutePricesParams>({
    initial_date: `${initialDate()}T00:00:00`,
    final_date: `${finalDate()}T23:59:59`,
    page: 1,
    per_page: 8,
  })
  const {
    data: allPrices,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetAllRoutePrices({
    ...filters,
    page: filters.page,
    per_page: 8,
  })

  const { setValue, handleSubmit, getValues } = useForm({})

  const handlePageChange = (newPage: number) => {
    setFilters({ ...filters, page: newPage })
    addPage(newPage)
  }

  const handleFilter: SubmitHandler<FiltersRoutePrices> = async data => {
    const dataFilter = {
      origin_city_id: data.origin_city_id?.value,
      destination_city_id: data.destination_city_id?.value,
      initial_date: `${data.initial_date}T00:00:00`,
      final_date: `${data.final_date}T23:59:59`,
      page: data.page ? Number(data.page) : 1,
    } as FiltersRoutePricesParams
    setFilters(dataFilter)
    updateQueryParams({
      initial_date: data.initial_date,
      final_date: data.final_date,
      page: data.page?.toString() || '1',
      destination_name: data.destination_city_id?.label,
      destination_value: data.destination_city_id?.value?.toString(),
      origin_name: data.origin_city_id?.label,
      origin_value: data.origin_city_id?.value?.toString(),
    })
  }

  useEffect(() => {
    const initialDateQuery = getParams('initial_date')
    const finalDateQuery = getParams('final_date')
    const pageQuery = getParams('page')
    const destinationNameQuery = getParams('destination_name')
    const destinationValueQuery = getParams('destination_value')
    const originNameQuery = getParams('origin_name')
    const originValueQuery = getParams('origin_value')

    if (
      initialDateQuery ||
      finalDateQuery ||
      pageQuery ||
      destinationNameQuery ||
      originNameQuery ||
      originValueQuery ||
      destinationValueQuery
    ) {
      setValue('initial_date', initialDateQuery)
      setValue('final_date', finalDateQuery)
      setValue('page', pageQuery)
      setValue('destination_city_id', {
        label: destinationNameQuery,
        value: destinationValueQuery,
      })
      setValue('origin_city_id', {
        label: originNameQuery,
        value: originValueQuery,
      })

      handleSubmit(handleFilter)()
    }
  }, [])

  return (
    <Layout>
      <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md">
        <Flex justify="space-between" alignItems="baseline" direction={['column', 'row']}>
          <Flex gridGap={4} justify="space-between">
            <Heading size="lg" fontWeight="normal" mb="6">
              Histórico de preços de cotação
            </Heading>
            <Heading size="md" mt={2} cursor="pointer">
              {isFetching ? (
                <Spinner size="sm" />
              ) : (
                <RiRestartLine
                  onClick={() => {
                    refetch()
                  }}
                />
              )}
            </Heading>
          </Flex>
        </Flex>

        <Box>
          <Divider mb="4" />

          <form onSubmit={handleSubmit(handleFilter)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="3">
              <GridItem colSpan={[12, 6, 3]} mr={2}>
                <Input
                  type="date"
                  label="Data inicial"
                  name="initial_date"
                  setValue={setValue}
                  initialValue={getValues('initial_date')}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <Input
                  label="Data Final"
                  type="date"
                  name="final_date"
                  setValue={setValue}
                  initialValue={getValues('final_date')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 3]}>
                <AutocompleteAsync
                  name="origin_city_id"
                  label="Origem"
                  setValue={setValue}
                  initialValue={getValues('origin_city_id')}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 3]}>
                <AutocompleteAsync
                  name="destination_city_id"
                  label="Destino"
                  setValue={setValue}
                  initialValue={getValues('destination_city_id')}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>
              <GridItem colSpan={[12]}>
                <Stack direction="row" justify="flex-end">
                  {allPrices && allPrices.data.length > 0 && (
                    <ReportToExcel
                      isLoading={isLoading}
                      documentTitle="Relatório de histórico de preços de cotações"
                      columnsTitle={[
                        'Data',
                        'Motorista',
                        'Telefone',
                        'Valor',
                        'Dispositivo',
                        'Origem',
                        'Destino',
                        'Veículo',
                        'Carroceria',
                        'Fonte',
                      ]}
                      onGenerateReport={async () => {
                        const report = await fetchAllRoutePrices({
                          initial_date: `${filters.initial_date}`,
                          final_date: `${filters.final_date}`,
                          unpaged: true,
                        })
                        return report.data.map(price => {
                          return [
                            price.created_at_formatted,
                            price.name,
                            price.phone || '',
                            price.value_formatted,
                            getLabelFromAutoComplete({
                              value: price.tracking_device_type,
                              array: trackingDeviceType,
                            }),
                            price.quotation.origin.name,
                            price.quotation.destination.name,
                            price.vehicle_category?.name || '',
                            price.vehicle_body?.name || '',
                            price.source_formatted || '',
                          ]
                        })
                      }}
                    />
                  )}

                  <Box>
                    <Button
                      type="submit"
                      size="md"
                      colorScheme="blue"
                      leftIcon={<Icon as={RiSearchLine} />}
                      isLoading={isLoading}
                    >
                      Filtrar
                    </Button>
                  </Box>
                </Stack>
              </GridItem>
            </Grid>
          </form>

          <Divider my="4" />
        </Box>

        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : isError ? (
          <Text>Deu erro</Text>
        ) : (
          allPrices && <TableRoutePricesListPage prices={allPrices} />
        )}

        {allPrices?.data && (
          <Pagination
            currentPage={filters?.page || 1}
            totalCountOfRegisters={allPrices.total}
            registersPerPage={10}
            onPageChange={handlePageChange}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
