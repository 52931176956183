import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState } from 'react'
import { BiFileFind } from 'react-icons/bi'
import { GrDocumentPdf } from 'react-icons/gr'
import { Pagination } from '../../../../../../layout/Pagination'
import { formatStubStatus } from '../../../../../../services/endpoints/freights/getStubs'
import { useGetNfes } from '../../../../../../services/endpoints/freights/nfes/getNfes'

type ListNfesTypes = {
  freight_id: string
}

export function ListNfes({ freight_id }: ListNfesTypes): JSX.Element {
  const [page, setPage] = useState(1)

  const { data } = useGetNfes({
    freight_id,
    page,
  })

  return (
    <Box mt={4}>
      <Flex flexDirection="column">
        <Heading size="sm" fontWeight="normal">
          Notas fiscais
        </Heading>

        {data && data.data?.length > 0 ? (
          <Table mt={2} size="sm">
            <Thead>
              <Tr>
                <Th pl={2}>CTE</Th>
                <Th>NCTE</Th>
                <Th>NFE</Th>
                <Th>Status</Th>
                <Th>Análise</Th>
                <Th>Data</Th>
                <Th>Canhoto</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.data.map(
                nfe =>
                  nfe && (
                    <>
                      <Tr key={nfe.id}>
                        <Td pl={2}>
                          {nfe.cte && nfe.cte.xml_file_url && (
                            <Tooltip label="Visualizar CTE" hasArrow placement="top">
                              <Button
                                as={Link}
                                size="xs"
                                colorScheme="blue"
                                target="_blank"
                                rel="noreferrer"
                                href={nfe.cte?.xml_file_url}
                              >
                                <Icon as={GrDocumentPdf} fontSize="18" />
                              </Button>
                            </Tooltip>
                          )}
                        </Td>
                        <Td>{nfe.cte?.nct}</Td>
                        <Td>{nfe.number}</Td>
                        <Td>
                          <Tag colorScheme="green" p="2" borderRadius="8" gridGap={2}>
                            {nfe.stubs && nfe.stubs[0]
                              ? formatStubStatus(nfe.stubs[0].status).toLocaleUpperCase()
                              : 'SEM ANEXO'}
                          </Tag>
                        </Td>
                        <Td>
                          {nfe.stubs && nfe.stubs[0] && nfe.stubs[0].user ? nfe.stubs[0].user.name : ''}
                        </Td>
                        <Td>
                          {nfe.stubs && nfe.stubs[0] && nfe.stubs[0].receipt_date
                            ? format(new Date(nfe.stubs[0].receipt_date), 'dd/MM/yyyy')
                            : ''}
                        </Td>
                        <Td>
                          {nfe.stubs && nfe.stubs[0] && nfe.stubs[0].attachment_file_url && (
                            <Tooltip label="Visualizar anexo" hasArrow mt="-4" placement="top">
                              <Button
                                as={Link}
                                href={nfe.stubs[0].attachment_file_url}
                                target="_blank"
                                rel="noreferrer"
                                bg="none"
                                _hover={undefined}
                              >
                                <Icon as={BiFileFind} fontSize={22} />
                              </Button>
                            </Tooltip>
                          )}
                        </Td>
                      </Tr>
                    </>
                  ),
              )}
            </Tbody>
          </Table>
        ) : (
          <Alert status="warning" mt={2}>
            <AlertIcon />
            <AlertTitle mr={2}>Não há nenhuma nota fiscal para este frete no momento</AlertTitle>
          </Alert>
        )}

        {data && data.data?.length > 0 && (
          <Pagination
            currentPage={page}
            totalCountOfRegisters={data.total}
            registersPerPage={15}
            onPageChange={setPage}
            p="6"
          />
        )}
      </Flex>
    </Box>
  )
}
