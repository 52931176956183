/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

import { CreateInteractionsTimeline } from '../../../types'

async function createInteractionsTimeline(data: CreateInteractionsTimeline) {
  return apiServer.post('/create-interaction-timeline', data)
}

export function usePostInteractionsTimeline(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateInteractionsTimeline, unknown>,
) {
  return useMutation(async (data: CreateInteractionsTimeline) => createInteractionsTimeline(data), options)
}
