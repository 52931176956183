import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FiMapPin } from 'react-icons/fi'
import { IconType } from 'react-icons/lib'

interface InfoBoxProps extends BoxProps {
  icon?: IconType
  title?: string
  content?: string

  subInfoContent?: string
  subInfoTitle?: string
}

const InfoBox: React.FC<InfoBoxProps> = ({
  icon: Icon = FiMapPin,
  title = 'Origem',
  content,
  subInfoContent,
  subInfoTitle,
  ...props
}) => {
  return (
    <Box {...props}>
      <Flex alignItems="center" textColor="gray.500">
        <Icon size={16} />
        <Text ml="1" fontWeight="medium" fontSize="xs">
          {title}
        </Text>
      </Flex>
      <Text fontWeight="medium" fontSize="sm" mt="1">
        {content}
      </Text>
      {subInfoContent && subInfoTitle && (
        <Box mt="2">
          <Text fontWeight="medium" fontSize="xs" textColor="gray.500">
            {subInfoTitle}
          </Text>
          <Text fontWeight="medium" fontSize="sm" mt="1" whiteSpace="pre-wrap">
            {subInfoContent}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default InfoBox
