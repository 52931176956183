import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { apiServer } from '../api'
import { ResponseApiType } from '../types'
import getOnlyNumbers from './getOnlyNumbers'
import { searchAddressByCep } from './searchAddressByCep'

interface CnpjResponse {
  atividade_principal: {
    text: string
    code: string
  }[]
  nome: string
  uf: string
  telefone: string
  atividades_secundarias: {
    text: string
    code: string
  }[]
  cep: string
  bairro: string
  logradouro: string
  numero: string
  municipio: string
  porte: string
  abertura: string
  natureza_juridica: string
  fantasia: string
  cnpj: string
  status: string
  complemento: string
  email: string
}

export interface CnpjData {
  cnpj: string
  name: string
  nickname: string
  main_activity: string
  phone: string
  email: string
  city?: AutocompleteOption
  zipcode: string
  address: string
  address_number: number
  district: string
  address_complement: string
  address_uf: string
}

function getCnpjPhoneFormatted(phone: string) {
  let phoneNumber = getOnlyNumbers(phone)
  // PEGA O PRIMEIRO NÚMERO QUANDO VIER 2.
  if (phone.length > 16) {
    const [first] = phone.split('/')
    phoneNumber = getOnlyNumbers(first)
  }

  // ADICIONAR O "9" AO NÚMERO DE TELEFONE QUANDO NÃO TIVER.

  if (phoneNumber.length === 10) {
    const telefone_com_9 = Array.from(phoneNumber) // Transforma em array separado por vírgula.
    telefone_com_9.splice(2, 0, '9') // Acrescenta o "9" na posição 2 do array.
    phoneNumber = telefone_com_9.join('') // Números juntos formando uma string => "88994833370".
  } else {
    phoneNumber = phone
  }

  return getOnlyNumbers(phoneNumber)
}
export function firstLetterUppercase(name: string | undefined): string {
  if (name) {
    const text = String(name)
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => {
        return a.toUpperCase()
      })
    return text
  }
  return ''
}

export async function searchPersonByCnpj(CNPJ: string): Promise<CnpjData | null> {
  try {
    const { data: res } = await apiServer.get<ResponseApiType<CnpjResponse>>(`/people/receita-federal/cnpj`, {
      params: { cnpj: getOnlyNumbers(CNPJ) },
    })

    const city = await searchAddressByCep(res.data.cep)

    const cnpjData = {
      city: city?.city,
      city_string: firstLetterUppercase(res.data.municipio),
      cnpj: res.data.cnpj,
      name: firstLetterUppercase(res.data.nome),
      nickname: firstLetterUppercase(res.data.fantasia),
      main_activity: firstLetterUppercase(res.data.atividade_principal[0].text),
      email: res.data.email,
      phone: getCnpjPhoneFormatted(res.data.telefone),
      zipcode: getOnlyNumbers(res.data.cep),
      address: firstLetterUppercase(res.data.logradouro),
      address_number: Number(res.data.numero),
      district: firstLetterUppercase(res.data.bairro),
      address_complement: firstLetterUppercase(res.data.complemento),
      address_uf: res.data.uf,
    }
    return cnpjData
  } catch {
    return null
  }
}
