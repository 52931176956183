export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString)
  return !Number.isNaN(date)
}

export const validateDate = (value?: Date | string): boolean => {
  if (!value || value === 'undefined' || value === 'null') return true
  const year = new Date(value).getFullYear()
  return year > 1900 && year < 2100
}

export const isValidOCRDateFormat = (date: string): boolean => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
  return regex.test(date)
}
