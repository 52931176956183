import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { CalcProposalResponse, ProposalResponse } from '../FreightCalculation'

interface ProposalInfoModalProps {
  isOpen: boolean
  onClose: () => void
  proposalInfo?: CalcProposalResponse
  proposalResponse?: ProposalResponse
}

export function ProposalInfoModal({
  isOpen,
  onClose,
  proposalInfo,
  proposalResponse,
}: ProposalInfoModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xx-large">Informações gerais do frete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table
            variant="unstyled"
            colorScheme="blackAlpha"
            d={['block', 'block', 'table']}
            overflowX="auto"
            size="sm"
          >
            <Thead>
              <Tr>
                <Th whiteSpace="nowrap" fontSize="md">
                  TÍTULO
                </Th>
                <Th whiteSpace="nowrap" fontSize="md">
                  AUTÔNOMO (PF)
                </Th>
                <Th whiteSpace="nowrap" fontSize="md">
                  EMPRESA (PJ)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>TOTAL COM ICMS</Td>
                <Td>R$ {proposalInfo?.content.PF.valor_a_ser_cobrado__BRL}</Td>
                <Td>R$ {proposalInfo?.content.PJ.valor_a_ser_cobrado__BRL}</Td>
              </Tr>
              <Tr>
                <Td>TOTAL SEM ICMS</Td>
                <Td>R$ {proposalInfo?.content.PF.valor_a_ser_cobrado_sem_icms__BRL}</Td>
                <Td>R$ {proposalInfo?.content.PJ.valor_a_ser_cobrado_sem_icms__BRL}</Td>
              </Tr>
              <Tr>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
              </Tr>

              {proposalResponse?.data.info_frete.locations.origem.ibge_id ===
              proposalResponse?.data.info_frete.locations.destino.ibge_id ? (
                <>
                  <Tr>
                    <Td>ISS</Td>
                    <Td>
                      R$ {proposalInfo?.content.PF.ISS_valor__BRL} | {proposalInfo?.content.PF.ISS}
                    </Td>
                    <Td>
                      R$ {proposalInfo?.content.PJ.ISS_valor__BRL} | {proposalInfo?.content.PJ.ISS}
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  <Tr>
                    <Td>ICMS</Td>
                    <Td>
                      R$ {proposalInfo?.content.PF.ICMS_valor__BRL} | {proposalInfo?.content.PF.ICMS}
                    </Td>
                    <Td>
                      R$ {proposalInfo?.content.PJ.ICMS_valor__BRL} | {proposalInfo?.content.PJ.ICMS}
                    </Td>
                  </Tr>
                </>
              )}
              <Tr>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
              </Tr>
              <Tr>
                <Td>RECEITA LÍQUIDA</Td>
                <Td>R$ {proposalInfo?.content.PF.receita_liquida}</Td>
                <Td>R$ {proposalInfo?.content.PJ.receita_liquida}</Td>
              </Tr>
              <Tr>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
                <Td>
                  <Divider my="2" />
                </Td>
              </Tr>
              <Tr>
                <Td>MOTORISTA S/PED.</Td>
                <Td>R$ {proposalInfo?.content.PF.custo_motorista}</Td>
                <Td>R$ {proposalInfo?.content.PJ.custo_motorista}</Td>
              </Tr>

              <Tr>
                <Td>PEDÁGIO</Td>
                <Td>R$ {proposalInfo?.content.PF.valor_pedagio}</Td>
                <Td>R$ {proposalInfo?.content.PJ.valor_pedagio}</Td>
              </Tr>

              <Tr>
                <Td>SEGURO</Td>
                <Td>
                  R$ {proposalInfo?.content.PF.valor_seguro} | ({proposalInfo?.content.PF.aliquota_seguro})
                </Td>
                <Td>
                  R$ {proposalInfo?.content.PJ.valor_seguro} | ({proposalInfo?.content.PJ.aliquota_seguro})
                </Td>
              </Tr>

              <Tr>
                <Td>RPA</Td>
                <Td>R$ {proposalInfo?.content.PF.taxa_contador_motorista}</Td>
                <Td>R$ {proposalInfo?.content.PJ.taxa_contador_motorista}</Td>
              </Tr>
              <Tr>
                <Td>BACKGROUND CHECK</Td>
                <Td>R$ {proposalInfo?.content.PF.background_check}</Td>
                <Td>R$ {proposalInfo?.content.PJ.background_check}</Td>
              </Tr>
              <Tr>
                <Td>MARGEM</Td>
                <Td>
                  R$ {proposalInfo?.content.PF.margem_contribuicao_valor} | (
                  {proposalInfo?.content.PF.margem_contribuicao}%)
                </Td>
                <Td>
                  R$ {proposalInfo?.content.PJ.margem_contribuicao_valor} | (
                  {proposalInfo?.content.PJ.margem_contribuicao}%)
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
