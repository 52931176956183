import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { SelectOption } from '../../../../components/form'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'
import { LoadingRequestType } from '../../../types/LoadingRequestType'

type CreateLoadingRequestType = {
  freight_id: string
  motorist: SelectOption
  motorist_vehicle_id: string
  cte_id: string
  discount_buonny: string
  reason: string
  type: string
  total_value: string
  total_gross: string
  origin?: string
  destination?: string
  beneficiary: SelectOption
  e_frete?: string
  payment_type: string
  pix?: string
  account_type?: string
  bank?: SelectOption
  agency?: string
  account?: string
  account_digit?: string
  observation?: string
}

async function createLoadingRequest(data: CreateLoadingRequestType): Promise<LoadingRequestType> {
  const { data: response } = await apiServer.post('/loading-requests', {
    freight_id: data.freight_id,
    motorist_id: data.motorist.value,
    motorist_vehicle_id: data.motorist_vehicle_id,
    cte_id: data.cte_id,
    discount_buonny: Number(data.discount_buonny),
    reason: data.reason,
    type: data.type,
    total_value: Number(data.total_value),
    total_gross: Number(data.total_gross),
    origin: data.origin,
    destination: data.destination,
    beneficiary_id: data.beneficiary.value,
    e_frete: data.e_frete === 'yes',
    pix: data.payment_type === 'pix' ? data.pix : null,
    account_type: data.payment_type === 'account' ? data.account_type : null,
    bank: data.payment_type === 'account' ? data.bank?.value : null,
    agency: data.payment_type === 'account' ? data.agency : null,
    account: data.payment_type === 'account' ? data.account : null,
    account_digit: data.payment_type === 'account' ? data.account_digit : null,
    observation: data.observation,
  })
  queryClient.invalidateQueries('loading-requests')
  return response.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePostLoadingRequest(
  options?: UseMutationOptions<LoadingRequestType, unknown, CreateLoadingRequestType, unknown>,
): UseMutationResult<LoadingRequestType, unknown, CreateLoadingRequestType, unknown> {
  return useMutation(async (data: CreateLoadingRequestType) => createLoadingRequest(data), options)
}
