/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Table,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCaravanLine, RiSearchLine, RiTruckLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Autocomplete } from '../../../components/form/Autocomplete'
import { InputMask } from '../../../components/form/InputMask'
import { Select } from '../../../components/form/Select'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../config/error/toastError'
import { useAuth } from '../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import {
  FiltersAllBlackListParams,
  getFormatEntityType,
  useGetAllBlackList,
} from '../../../services/endpoints/black-list/getAllBlackList'
import { usersAutoComplete } from '../../../services/endpoints/users/usersAutoComplete'
import { boxWidth, displayAdaptable } from '../../../services/utils/tableAdaptable'
import { validateCpf } from '../../../services/utils/validateCpf'
import ButtonIncludeInBlackListByDocument from '../modals/buttons/ButtonIncludeInBlackListByCPF'
import ButtonRemoveOfTheBlackList from '../modals/buttons/ButtonRemoveOfTheBlackList'
import ButtonShowInfosBlackList from '../modals/buttons/ButtonShowInfosBlackList'

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  license_plate: yup.string(),
  per_page: yup.number(),
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  cnpj: yup.string(),
  entity_type: yup.string(),
  creator_id: yup.string(),
})

export function BlackList(): JSX.Element {
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const { storageUser, getUserIdToListFreights, setUserIdToListFreights } = useAuth()
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState<AutocompleteOption[]>([])
  const [filters, setFilters] = useState<FiltersAllBlackListParams>()
  const { updateQueryParams, getParams } = useQueryParamUpdater<FiltersAllBlackListParams>()
  const {
    data: allBlackList,
    isLoading,
    isError,
    error,
  } = useGetAllBlackList({
    ...filters,
    page,
  })
  const [blackList, setBlackList] = useState(allBlackList)

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error])

  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors, isSubmitting } = formState

  useEffect(() => {
    setBlackList(allBlackList)
  }, [allBlackList])

  const handleFilterBlackList: SubmitHandler<FiltersAllBlackListParams> = async data => {
    const dataFilter = {
      license_plate: data.license_plate,
      entity_type: data.entity_type || undefined,
      cpf: data.cpf,
      cnpj: data.cnpj,
      creator_id: storageUser?.value,
    } as FiltersAllBlackListParams
    const { license_plate, entity_type, cpf, cnpj, creator_id } = dataFilter
    updateQueryParams({
      license_plate: data.license_plate,
      entity_type: data.entity_type,
      cpf: data.cpf,
      cnpj: data.cnpj,
      creator_id: data.creator_id,
      page: String(page),
    })
    setFilters({
      license_plate,
      entity_type,
      cpf,
      cnpj,
      creator_id,
    })
  }

  useEffect(() => {
    updateQueryParams({
      license_plate: filters?.license_plate,
      entity_type: filters?.entity_type,
      cpf: filters?.cpf,
      cnpj: filters?.cnpj,
      creator_id: filters?.creator_id,
      page: page > 1 ? page.toString() : undefined,
    })
  }, [page, filters])

  useEffect(() => {
    async function loadUsers() {
      const usersFiltered = await usersAutoComplete()
      setUsers(usersFiltered)
    }
    loadUsers()
    getUserIdToListFreights()
  }, [])

  useEffect(() => {
    const licensePlateParam = getParams('license_plate')
    const entityTypeParam = getParams('entity_type') as 'motorist' | 'owner' | 'vehicle'
    const cpfParam = getParams('cpf')
    const cnpjParam = getParams('cnpj')
    const creatorIdParam = getParams('creator_id')
    const pageParam = getParams('page')

    if (pageParam) {
      setPage(Number(pageParam))
    }

    if (licensePlateParam || entityTypeParam || cpfParam || cnpjParam || creatorIdParam) {
      handleFilterBlackList({
        license_plate: licensePlateParam,
        entity_type: entityTypeParam,
        cpf: cpfParam,
        cnpj: cnpjParam,
        creator_id: creatorIdParam,
      })
    }
  }, [])

  return (
    <Layout>
      <Box bg={bg} p="6" w={boxWidth} borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>

        <Divider my="4" />

        <form onSubmit={handleSubmit(handleFilterBlackList)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[6, 3]}>
              <InputMask
                name="license_plate"
                mask="aaa9*99"
                placeholder="Placa"
                error={errors.license_plate}
                setValue={setValue}
                uppercaseAll
                initialValue={filters?.license_plate}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputMask
                name="cnpj"
                placeholder="CNPJ"
                mask="99.999.999/9999-99"
                maskPlaceholder=""
                error={errors.cnpj}
                setValue={setValue}
                registerOnlyNumbers
                initialValue={filters?.cnpj}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <InputMask
                name="cpf"
                placeholder="CPF"
                mask="999.999.999-99"
                maskPlaceholder=""
                error={errors.cpf}
                setValue={setValue}
                registerOnlyNumbers
                initialValue={filters?.cpf}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <Select
                name="entity_type"
                error={errors.entity_type}
                setValue={setValue}
                options={[
                  { label: 'Todos', value: '' },
                  { label: 'Motorista', value: 'motorist' },
                  { label: 'Proprietário', value: 'owner' },
                  { label: 'Veículo', value: 'vehicle' },
                ]}
                initialValue={filters?.entity_type}
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              {users.length > 0 && (
                <Autocomplete
                  name="user_id"
                  defaultValue={storageUser?.label}
                  onSelectOption={userSelected => {
                    if (userSelected.value) {
                      setUserIdToListFreights(userSelected)
                      getUserIdToListFreights()
                    } else {
                      setUserIdToListFreights()
                      getUserIdToListFreights()
                    }
                  }}
                  setValue={setValue}
                  placeholder="Filtrar por usuário"
                  options={users}
                  clearState={() => {
                    setUserIdToListFreights(undefined)
                    getUserIdToListFreights()
                  }}
                />
              )}
            </GridItem>
          </Grid>
          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Box>

      <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md">
        <Flex mb={['6', '8']} justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Lista de restrição
          </Heading>
          <ButtonIncludeInBlackListByDocument />
        </Flex>
        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : isError ? (
          <Text>Erro ao carregar lista de restrição</Text>
        ) : (
          <>
            <Table size="sx" d={displayAdaptable} overflowX="auto">
              <Thead>
                <Tr>
                  <Th />
                  <Th />
                  <Th>Tipo</Th>
                  <Th>Nome</Th>
                  <Th>Doc | Fone | placa</Th>
                  <Th>Motivo</Th>
                  <Th>Responsável / Data</Th>
                </Tr>
              </Thead>
              <Tbody>
                {blackList &&
                  blackList.data.map(item => {
                    return (
                      <Tr key={item.id}>
                        <Td>
                          <ButtonRemoveOfTheBlackList blackList={item} />
                        </Td>
                        <Td>
                          <ButtonShowInfosBlackList black_list={item} />
                        </Td>

                        <Td>{getFormatEntityType(item?.entity_type)}</Td>

                        {item.entity_type === 'motorist' ? (
                          <Td whiteSpace="nowrap">
                            <Link to={`motorists/show/${item?.motorist_id}`}>
                              {item?.motorist?.name.substring(0, 30) || 'Sem cadastro'}
                            </Link>
                          </Td>
                        ) : item?.entity_type === 'owner' ? (
                          <Td whiteSpace="nowrap">
                            <Link to={`owners/show/${item?.owner_id}`}>
                              {item?.owner?.name.substring(0, 30) || 'Sem cadastro'}
                            </Link>
                          </Td>
                        ) : (
                          item?.entity_type === 'vehicle' && (
                            <Td whiteSpace="nowrap">
                              {item?.vehicle?.vehicle_category?.name || item?.vehicle?.vehicle_body?.name ? (
                                <Link to={`vehicles/?license_plate=${item?.vehicle?.license_plate}`}>
                                  <Tag size="lg" borderRadius="full" ml="2">
                                    <TagLeftIcon as={RiTruckLine} />
                                    <TagLabel>{item?.vehicle?.vehicle_category?.name}</TagLabel>
                                  </Tag>{' '}
                                  <Tag size="lg" borderRadius="full" ml="2">
                                    <TagLeftIcon as={RiCaravanLine} />
                                    <TagLabel>{item?.vehicle?.vehicle_body?.name}</TagLabel>
                                  </Tag>
                                </Link>
                              ) : (
                                <Text>Sem cadastro</Text>
                              )}
                            </Td>
                          )
                        )}

                        {item.entity_type === 'motorist' ? (
                          <Td whiteSpace="nowrap">
                            <Text fontSize="sm">
                              {item?.motorist?.cpf || item?.motorist?.phone || item?.cpf}
                            </Text>
                          </Td>
                        ) : item.entity_type === 'owner' ? (
                          <Td whiteSpace="nowrap">
                            {item?.owner?.type === 'pf' || item?.cpf ? (
                              <Text fontSize="sm">{item?.owner?.cpf || item?.owner?.phone || item?.cpf}</Text>
                            ) : (
                              <Text fontSize="sm">
                                {item?.owner?.cnpj || item?.owner?.phone || item?.cnpj}
                              </Text>
                            )}
                          </Td>
                        ) : (
                          item.entity_type === 'vehicle' && (
                            <Td whiteSpace="nowrap">{item?.vehicle?.license_plate || item.license_plate}</Td>
                          )
                        )}
                        <Td whiteSpace="nowrap">{item?.reason.substring(0, 10)}</Td>
                        <Td whiteSpace="nowrap">
                          <Box>
                            <Text>{item?.creator?.name}</Text>
                            <Text fontSize="xs">{item?.created_at}</Text>
                          </Box>
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </>
        )}

        {blackList && (
          <Pagination
            currentPage={page}
            totalCountOfRegisters={blackList.total}
            registersPerPage={15}
            onPageChange={setPage}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
