import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { Input } from '../../../../../../components/form'
import { usePostNfes } from '../../../../../../services/endpoints/freights/postNfes'
import { queryClient } from '../../../../../../config/react-query'
import { toastError } from '../../../../../../config/error/toastError'

type CreateNfesModelProps = {
  isOpen: boolean
  onClose: () => void
}

type CreateNfesProps = {
  number: string
}

export function CreateNfesModal({ isOpen, onClose }: CreateNfesModelProps): JSX.Element {
  const createStubFormSchema = yup.object().shape({
    number: yup.string().required('Informe a chave'),
  })

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(createStubFormSchema),
  })

  const { errors } = formState

  const toast = useToast()

  const {
    mutateAsync: mutateAsyncCreateNfes,
    isLoading: isLoadingCreateNfes,
    error: errorCreateNfes,
    isError: isErrorCreateNfes,
  } = usePostNfes({
    onSuccess: () => {
      queryClient.invalidateQueries('Nfes')
    },
  })

  useEffect(() => {
    if (isErrorCreateNfes) {
      toastError({ toast, error: errorCreateNfes })
    }
  }, [isErrorCreateNfes, errorCreateNfes, toast])

  const handleCreateNfes: SubmitHandler<CreateNfesProps> = async data => {
    await mutateAsyncCreateNfes({
      number: data.number,
    })

    onClose()

    toast({
      title: 'Nota fiscal criada com sucesso',
      status: 'success',
      position: 'top-right',
      isClosable: true,
      duration: 4000,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar nota fiscal</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleCreateNfes)} noValidate>
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap="3" mb={4}>
              <GridItem colSpan={[12, 12]}>
                <Input name="number" label="Chave" setValue={setValue} error={errors.number} />
              </GridItem>
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="orange" isLoading={isLoadingCreateNfes}>
              Adicionar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
