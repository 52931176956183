import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Divider, Flex, Icon } from '@chakra-ui/react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { FormDatamex } from './datamex/FormDatamex'

interface SendToDatamexProps {
  freight: FormattedOneFreight
}

export function SendToDatamex({ freight }: SendToDatamexProps): JSX.Element {
  return (
    <>
      <Alert variant="subtle" status="success" borderRadius="md" justifyContent="space-between">
        <Flex alignItems="center" direction={['column', 'row']}>
          <AlertIcon />
          <AlertTitle mr={2} fontSize="lg">
            Parabéns, você preencheu todas as pendências!
          </AlertTitle>
        </Flex>
        <AlertDescription>
          <Flex alignItems="center" gridGap="1" direction={['column', 'row']}>
            <Button
              colorScheme="blue"
              ml="4"
              size="sm"
              leftIcon={<Icon as={RiArrowLeftLine} fontSize="x-large" />}
              as={Link}
              to={`/freights/show/${freight.id}`}
            >
              Voltar para o frete
            </Button>
          </Flex>
        </AlertDescription>
      </Alert>
      <Divider my={6} />

      <FormDatamex freight={freight} />
    </>
  )
}
