import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { isFreightWithoutCte } from '../../../services/endpoints/freights/utils/isFreightWithoutCte'

type ContainerProps = {
  freight: FormattedAllFreights
  children: ReactNode
  header?: ReactNode
}

export function Container({ children, freight, header }: ContainerProps): JSX.Element {
  const box = useColorModeValue('gray.100', 'gray.600')
  const headerBG = useColorModeValue('gray.200', 'gray.500')
  function borderColor(): string | undefined {
    if (isFreightWithoutCte(freight) || freight.buonny_value_out_of_range) {
      return 'red.500'
    }
    return undefined
  }

  return (
    <Flex
      borderColor={borderColor() || undefined}
      borderWidth={borderColor() ? 4 : 0}
      bg={box}
      borderRadius={8}
      direction="column"
      mb="2"
    >
      {header && (
        <Box bg={headerBG} borderTopLeftRadius={8} borderTopRightRadius={8} p="2" fontWeight="bold" pb="1">
          {header}
        </Box>
      )}

      <Box p="2" pt="1.5">
        {children}
      </Box>
    </Flex>
  )
}
