import { Button, Divider, Grid, GridItem, HStack, Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { Input } from '../../../../components/form/Input'
import { InputMask } from '../../../../components/form/InputMask'
import { RadioGroup } from '../../../../components/form/RadioGroup'
import { Select } from '../../../../components/form/Select'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { MotoristType } from '../../../../services/types'

interface OwnerTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  motorist: MotoristType
  formState: FormState<FieldValues>
}

export function OwnerTabPanel({ setValue, tabNav, motorist, formState }: OwnerTabPanelProps): JSX.Element {
  const { errors } = formState

  const [motoristIsOwner, setMotoristIsOwner] = useState(true)
  const [hasCart, setHasCart] = useState(false)
  const [motoristIsCartOwner, setMotoristIsCartOwner] = useState(true)
  const [cartOwnerIsVehicleOwner, setCartOwnerIsVehicleOwner] = useState(true)

  const [ownerDocumentText, setOwnerDocumentText] = useState('')
  const [ownerCartDocumentText, setOwnerCartDocumentText] = useState('')

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={12}>
          <RadioGroup
            name="motorist_is_owner"
            label="Motorista é proprietário do veículo?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="S"
            error={errors.motorist_is_owner}
            onChange={e => {
              if (e === 'S') setMotoristIsOwner(true)
              else setMotoristIsOwner(false)
            }}
          />
        </GridItem>

        {!motoristIsOwner ? (
          <>
            <GridItem colSpan={[12, 4]}>
              <InputMask
                name="owner_document"
                label="CPF / CNPJ"
                mask={ownerDocumentText.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'}
                maskPlaceholder=""
                setValue={setValue}
                initialValue={motorist.cpf}
                error={errors.owner_document}
                onChange={e => {
                  setOwnerDocumentText(e.target.value)
                }}
              />
            </GridItem>

            <GridItem colSpan={[12, 8]}>
              <Input
                name="owner_name"
                label="Nome / Razão social"
                setValue={setValue}
                initialValue={motorist.name}
                error={errors.owner_name}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Input
                name="owner_rg_ie"
                label="RG / IE"
                setValue={setValue}
                initialValue={motorist.rg}
                error={errors.owner_rg_ie}
              />
            </GridItem>

            <GridItem colSpan={[12, 2]}>
              <Input name="owner_rntrc" label="RNTRC" setValue={setValue} error={errors.owner_rntrc} />
            </GridItem>

            <GridItem colSpan={12} my="6">
              <Divider />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <InputMask
                name="owner_address_cep"
                label="CEP"
                mask="99.999-999"
                registerOnlyNumbers
                maskPlaceholder=""
                setValue={setValue}
                initialValue={motorist.zipcode}
                error={errors.owner_address_cep}
              />
            </GridItem>

            <GridItem colSpan={[12, 7]}>
              <Input
                name="owner_address"
                label="Endereço"
                setValue={setValue}
                initialValue={motorist.address}
                error={errors.owner_address}
              />
            </GridItem>

            <GridItem colSpan={[12, 2]}>
              <Input
                name="owner_address_number"
                label="Número"
                setValue={setValue}
                initialValue={motorist.address_number}
                error={errors.owner_address_number}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Input
                name="owner_address_district"
                label="Bairro"
                setValue={setValue}
                initialValue={motorist.district}
                error={errors.owner_address_district}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Input
                name="owner_address_complement"
                label="Complemento"
                setValue={setValue}
                error={errors.owner_address_complement}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <AutocompleteAsync
                name="address_city_id"
                label="Cidade"
                error={errors.address_city_id}
                setValue={setValue}
                initialValue={{
                  label: motorist?.address_city ? String(motorist?.address_city?.name) : '',
                  value: motorist?.address_city ? Number(motorist?.address_city?.ibge_id) : '',
                }}
                loadOptions={searchCitiesByName}
              />
            </GridItem>

            <GridItem colSpan={12} my="6">
              <Divider />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Select
                name="owner_contact_type"
                label="Tipo de contato"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Residencial', value: '1' },
                  { label: 'Comercial', value: '2' },
                  { label: 'Referência', value: '7' },
                ]}
                error={errors.owner_contact_type}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Select
                name="owner_contact_return_type"
                label="Tipo de retorno"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Telefone', value: '1' },
                ]}
                error={errors.owner_contact_return_type}
              />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <InputMask
                mask="(99)99999-9999"
                maskPlaceholder=""
                name="owner_contact_value"
                label="Contato"
                setValue={setValue}
                initialValue={motorist.phone}
                error={errors.owner_contact_value}
              />
            </GridItem>
          </>
        ) : (
          <GridItem colSpan={[12, 2]}>
            <Input
              name="professional_rntrc"
              label="RNTRC"
              setValue={setValue}
              initialValue={motorist.vehicles[0].antt}
              error={errors.professional_rntrc}
            />
          </GridItem>
        )}

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <RadioGroup
            name="has_cart"
            label="Possui Carreta?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.has_cart}
            onChange={e => {
              if (e === 'S') setHasCart(true)
              else setHasCart(false)
            }}
          />
        </GridItem>

        {hasCart && (
          <>
            <GridItem colSpan={[12, 4]}>
              <RadioGroup
                name="motorist_is_cart_owner"
                label="Motorista é proprietário da carreta?"
                setValue={setValue}
                options={[
                  { label: 'Sim', value: 'S' },
                  { label: 'Não', value: 'N' },
                ]}
                initialValue="S"
                error={errors.motorist_is_cart_owner}
                onChange={e => {
                  if (e === 'S') setMotoristIsCartOwner(true)
                  else setMotoristIsCartOwner(false)
                }}
              />
            </GridItem>
            {!motoristIsCartOwner && (
              <GridItem colSpan={[12, 4]}>
                <RadioGroup
                  name="cart_owner_is_vehicle_owner"
                  label="Proprietário da carreta é igual ao do veículo?"
                  setValue={setValue}
                  options={[
                    { label: 'Sim', value: 'S' },
                    { label: 'Não', value: 'N' },
                  ]}
                  initialValue="S"
                  error={errors.cart_owner_is_vehicle_owner}
                  onChange={e => {
                    if (e === 'S') setCartOwnerIsVehicleOwner(true)
                    else setCartOwnerIsVehicleOwner(false)
                  }}
                />
              </GridItem>
            )}

            {!motoristIsCartOwner && !cartOwnerIsVehicleOwner && (
              <>
                <GridItem colSpan={[12, 4]}>
                  <InputMask
                    name="owner_cart_document"
                    label="CPF / CNPJ"
                    mask={ownerCartDocumentText.length < 15 ? '999.999.999-999' : '99.999.999/9999-99'}
                    maskPlaceholder=""
                    setValue={setValue}
                    error={errors.owner_cart_document}
                    onChange={e => {
                      setOwnerCartDocumentText(e.target.value)
                    }}
                  />
                </GridItem>

                <GridItem colSpan={[12, 8]}>
                  <Input
                    name="owner_cart_name"
                    label="Nome / Razão social"
                    setValue={setValue}
                    error={errors.owner_cart_name}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Input
                    name="owner_cart_rg_ie"
                    label="RG / IE"
                    setValue={setValue}
                    error={errors.owner_cart_rg_ie}
                  />
                </GridItem>

                <GridItem colSpan={[12, 2]}>
                  <Input
                    name="owner_cart_rntrc"
                    label="RNTRC"
                    setValue={setValue}
                    error={errors.owner_cart_rntrc}
                  />
                </GridItem>

                <GridItem colSpan={12} my="6">
                  <Divider />
                </GridItem>

                <GridItem colSpan={[12, 3]}>
                  <InputMask
                    name="owner_cart_address_cep"
                    label="CEP"
                    mask="99.999-999"
                    registerOnlyNumbers
                    maskPlaceholder=""
                    setValue={setValue}
                    error={errors.owner_cart_address_cep}
                  />
                </GridItem>

                <GridItem colSpan={[12, 7]}>
                  <Input
                    name="owner_cart_address"
                    label="Endereço"
                    setValue={setValue}
                    error={errors.owner_cart_address}
                  />
                </GridItem>

                <GridItem colSpan={[12, 2]}>
                  <Input
                    name="owner_cart_address_number"
                    label="Número"
                    setValue={setValue}
                    error={errors.owner_cart_address_number}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Input
                    name="owner_cart_address_district"
                    label="Bairro"
                    setValue={setValue}
                    error={errors.owner_cart_address_district}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Input
                    name="owner_cart_address_complement"
                    label="Complemento"
                    setValue={setValue}
                    error={errors.owner_cart_address_complement}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Input
                    name="owner_cart_address_city"
                    label="Cidade"
                    setValue={setValue}
                    error={errors.owner_cart_address_city}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Input
                    name="owner_cart_address_uf"
                    label="Estado"
                    setValue={setValue}
                    error={errors.owner_cart_address_uf}
                  />
                </GridItem>

                <GridItem colSpan={12} my="6">
                  <Divider />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Select
                    name="owner_cart_contact_type"
                    label="Tipo de contato"
                    setValue={setValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Residencial', value: '1' },
                      { label: 'Comercial', value: '2' },
                      { label: 'Referência', value: '7' },
                    ]}
                    error={errors.owner_cart_contact_type}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <Select
                    name="owner_cart_contact_return_type"
                    label="Tipo de retorno"
                    setValue={setValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Telefone', value: '1' },
                    ]}
                    error={errors.owner_cart_contact_return_type}
                  />
                </GridItem>

                <GridItem colSpan={[12, 4]}>
                  <InputMask
                    mask="(99)99999-9999"
                    maskPlaceholder=""
                    name="owner_cart_contact_value"
                    label="Contato"
                    setValue={setValue}
                    error={errors.owner_cart_contact_value}
                  />
                </GridItem>
              </>
            )}
          </>
        )}
      </Grid>
      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(3)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(5)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
