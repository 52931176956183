/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

import { CreateMonitoringOccurenceType } from '../../types'

import { apiServer } from '../../api'

async function createMonitoringOccurence(data: CreateMonitoringOccurenceType) {
  const newMonitoringOccurence = apiServer.post('/freights/monitoring-occurrences', data)
  return newMonitoringOccurence
}

export function usePostMonitoringOccurence(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateMonitoringOccurenceType, unknown>,
) {
  return useMutation(async (data: CreateMonitoringOccurenceType) => createMonitoringOccurence(data), options)
}
