import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { FreightType, ResponseApiType } from '../../types'
import { FreightStatusOptions } from '../../types/FreightType'

export interface ForceFreightStatus {
  freight_id: string
  status: FreightStatusOptions
}

export async function forceFreightStatus({
  freight_id,
  status,
}: ForceFreightStatus): Promise<AxiosResponse<ResponseApiType<FreightType>>> {
  const freightUpdated = await apiServer.patch(`/force-freight-status/${freight_id}`, { status })
  if (freightUpdated) queryClient.invalidateQueries('freight')
  return freightUpdated
}

export function useForceFreightStatus(
  options?: UseMutationOptions<AxiosResponse, unknown, ForceFreightStatus, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, ForceFreightStatus, unknown> {
  return useMutation(async (data: ForceFreightStatus) => forceFreightStatus(data), options)
}
