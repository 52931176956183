import { Box, Button, Divider, Flex, Heading, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { usePostAdvanceRequest } from '../../../../../services/endpoints/freights/postAdvanceRequest'
import { useUploadAdvanceRequestAttachments } from '../../../../../services/endpoints/freights/UploadAdvanceRequestAttachments'
import { AdvanceRequestAttachmentTypeEnum } from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateAdvanceRequestForm } from '../forms/CreateOrUpdateAdvanceRequestForm'

type CreateAdvanceRequestType = {
  reason: string
  account_digit?: string
  account?: string
  agency?: string
  account_type?: string
  bank?: SelectOption
  pix?: string
  e_frete?: string
  payment_type: string
  freight_id: string
  observation?: string
  cte_issuance_request_id?: string
  attachment_file?: File[]
}

const CreateAdvanceRequestSchema = yup.object().shape({
  freight_id: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório'),
  cte_issuance_request_id: yup.string(),
  payment_type: yup.string().required('Campo obrigatório'),
  pix: yup.string().when('payment_type', {
    is: 'pix',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account_type: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  bank: yup
    .object()
    .shape({})
    .when('payment_type', {
      is: 'account',
      then: yup.object().shape({}).required('Campo obrigatório'),
      otherwise: yup.object().shape({}),
    }),
  agency: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  e_frete: yup.string(),
  account_digit: yup.string(),
  observation: yup.string(),
})

export function CreateAdvanceRequest(): JSX.Element {
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const history = useHistory()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(CreateAdvanceRequestSchema),
  })

  const { errors } = formState

  const { mutateAsync: createAdvanceRequest, isLoading: isLoadingCreateAdvanceRequest } =
    usePostAdvanceRequest({
      onSuccess: () => {
        toast({
          title: 'Nova solicitação de adiantamento criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const {
    mutateAsync: uploadAdvanceRequestAttachments,
    isLoading: isLoadingUploadAdvanceRequestAttachments,
  } = useUploadAdvanceRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleCreateAdvanceRequest: SubmitHandler<CreateAdvanceRequestType> = async data => {
    try {
      if (data.freight_id) {
        const newAdvanceRequest = await createAdvanceRequest(data)

        if (newAdvanceRequest && data.attachment_file && data.attachment_file.length > 0) {
          forEach(data.attachment_file, async file => {
            const formData = new FormData()

            formData.append('advance_request_id', newAdvanceRequest.id)
            formData.append('type', AdvanceRequestAttachmentTypeEnum.CONTRACT)
            formData.append('attachment', file)
            await uploadAdvanceRequestAttachments({ data: formData })
          })
        }

        history.push('/advance-requests')
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <Heading size="lg" fontWeight="normal" mb={4}>
          Nova solicitação de adiantamento
        </Heading>

        <Divider my="4" />

        <form onSubmit={handleSubmit(handleCreateAdvanceRequest)} noValidate>
          <CreateOrUpdateAdvanceRequestForm setValue={setValue} errors={errors} />

          <Flex mt={4} justifyContent="flex-end">
            <Link to="/advance-requests">
              <Button mr={3}>Cancelar</Button>
            </Link>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingCreateAdvanceRequest || isLoadingUploadAdvanceRequestAttachments}
              isDisabled={isLoadingCreateAdvanceRequest || isLoadingUploadAdvanceRequestAttachments}
            >
              Cadastrar
            </Button>
          </Flex>
        </form>
      </Box>
    </Layout>
  )
}
