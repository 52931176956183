/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputProps,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEventHandler, forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { IconType } from 'react-icons'
import { MdClear } from 'react-icons/md'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { AutocompleteOption } from './types/AutocompleteOption'

interface AutocompleteSelectOneAsyncProps extends InputProps {
  name: string
  loadOptions: (value: string) => Promise<AutocompleteOption[]>
  setValue: UseFormSetValue<FieldValues>
  initialValue?: AutocompleteOption
  label?: string
  error?: FieldError
  OptionIcon?: IconType
  clearState?: (value: any | undefined) => void
}

const AutocompleteSelectOneAsyncBase: ForwardRefRenderFunction<
  HTMLInputElement,
  AutocompleteSelectOneAsyncProps
> = (
  {
    name,
    loadOptions,
    label,
    error,
    setValue,
    initialValue,
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    onChange,
    onBlur,
    onFocus,
    OptionIcon,
    clearState,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)
  const [items, setItems] = useState<AutocompleteOption[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedItem, setSelectedItem] = useState<AutocompleteOption | null>(initialValue || null)

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  useEffect(() => {
    if (!selectedItem) {
      setValue(name, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, name])

  useEffect(() => {
    if (selectedItem) {
      setValue(name, selectedItem)
    }
  }, [name, selectedItem, setValue])

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
      setSelectedItem(initialValue)
      setInputValue(initialValue.label)
    }
  }, [initialValue, name, setValue])

  const filterOptions = useRef(
    debounce(
      async (value: string) => {
        setIsLoading(true)
        const options = await loadOptions(value)
        setIsLoading(false)
        setItems(options)
      },
      500,
      {},
    ),
  ).current

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target
    if (value) {
      await filterOptions(value)
    }
  }

  function handleSelectItem(item: AutocompleteOption) {
    setSelectedItem(item)
    setShowOptions(false)
    setInputValue(item.label)
  }

  return (
    <Box position="relative" cursor={isDisabled ? 'not-allowed' : undefined} opacity={isDisabled ? 0.7 : 1}>
      {label && (
        <FormLabel htmlFor={name}>
          {label}
          {isRequired && (
            <Box as="span" color="red.500" ml="1">
              *
            </Box>
          )}
        </FormLabel>
      )}

      <Flex
        ref={inputRef}
        alignItems="center"
        borderWidth={!error ? 1 : 2}
        borderColor={error ? 'red.500' : undefined}
        rounded="md"
        px="3"
        pt="2"
        bg={bg}
      >
        <Input
          flex={1}
          mb="2"
          ref={ref}
          id={name}
          variant="unstyled"
          size={size}
          onChange={e => {
            setSelectedItem(null)
            handleInputChange(e)
            if (onChange) onChange(e)
          }}
          onBlur={e => {
            if (onBlur) onBlur(e)
          }}
          onFocus={e => {
            setShowOptions(true)
            if (onFocus) onFocus(e)
          }}
          isDisabled={isDisabled}
          _disabled={{
            opacity: 0.7,
            cursor: 'not-allowed',
          }}
          cursor={isDisabled ? 'not-allowed' : undefined}
          isReadOnly={!!selectedItem}
          autoComplete={autoComplete}
          disableAutocomplete
          disableGoogleAutocomplete
          {...rest}
        />

        {isLoading ? (
          <Spinner size="xs" mb="2" />
        ) : (
          !isDisabled && (
            <>
              <Icon
                mb="2"
                as={showOptions ? RiArrowUpSLine : RiArrowDownSLine}
                fontSize={20}
                onClick={() => {
                  if (!isDisabled) setShowOptions(state => !state)
                }}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
              />
              <Icon
                mb="2"
                fontSize={20}
                onClick={() => {
                  setSelectedItem(null)
                  setInputValue('')
                  setShowOptions(false)
                  setValue(name, undefined)
                  if (clearState) clearState(undefined)
                }}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                as={MdClear}
              />
            </>
          )
        )}
      </Flex>

      {error && (
        <Text fontSize="sm" mt="2" textColor="red.500">
          {error.message}
        </Text>
      )}

      {showOptions && (
        <Stack
          direction="column"
          mt="2"
          py="2"
          bg={bg}
          rounded="md"
          shadow="md"
          borderWidth="1px"
          zIndex={10}
          position="absolute"
          w="100%"
        >
          {items.length > 0 ? (
            items.map(option => {
              const isSelected = selectedItem?.value === option.value
              const selectedButtonBg = bg === 'white' ? 'gray.100' : 'gray.700'

              return (
                <Button
                  key={option.value}
                  leftIcon={OptionIcon && <Icon as={OptionIcon} fontSize={18} />}
                  bg={isSelected ? selectedButtonBg : bg}
                  justifyContent="flex-start"
                  fontWeight="normal"
                  fontSize="md"
                  onClick={() => handleSelectItem(option)}
                >
                  {option.label}
                </Button>
              )
            })
          ) : (
            <Text p="2" px="4" textColor="gray.400">
              Nenhuma opção
            </Text>
          )}
        </Stack>
      )}
    </Box>
  )
}

export const AutocompleteSelectOneAsync = forwardRef(AutocompleteSelectOneAsyncBase)
