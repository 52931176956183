/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Thead,
  useColorModeValue,
  useDisclosure,
  useToast,
  Table,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Text,
  Tooltip,
  Spinner,
} from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GiReceiveMoney } from 'react-icons/gi'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { AutocompleteAsync, Input, Select, SelectOption } from '../../../../../components/form'
import Filter from '../../../../../components/UI/Filter'
import { toastError } from '../../../../../config/error/toastError'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../../layout'
import { Pagination } from '../../../../../layout/Pagination'
import {
  FilterAdvanceRequestsType,
  useGetAllAdvanceRequest,
} from '../../../../../services/endpoints/freights/getAllAdvanceRequests'
import { getAllUsers } from '../../../../../services/endpoints/users/getAllUsers'
import { AdvanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { advanceRequestStatusLegend } from '../../../../../services/utils/advanceRequestStatusLegend'
import { getAdvanceRequestColorByStatus } from '../../../../../services/utils/getAdvanceRequestColorByStatus'

type FormFiltersAdvanceRequests = {
  freight_number?: string
  status?: AdvanceRequestStatusEnum
  creator_id?: SelectOption
  initial_date?: string
  final_date?: string
}

export function ListAdvanceRequests(): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>()
  const [finalDate, setFinalDate] = useState<string>()
  const [filters, setFilters] = useState<FilterAdvanceRequestsType>({} as FilterAdvanceRequestsType)
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const hoverBgLineTable = useColorModeValue('gray.200', 'gray.700')

  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    freight_number?: string
    status?: AdvanceRequestStatusEnum
    initial_date?: string
    final_date?: string
    creator_label?: string
    creator_value?: string
  }>()

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const {
    data: advanceRequests,
    isLoading,
    isError,
    error,
  } = useGetAllAdvanceRequest({
    initial_date: filters.initial_date || initialDate,
    final_date: filters.final_date || finalDate,
    ...filters,
  })

  const { handleSubmit, setValue } = useForm({})

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  function handleInitialDate(event: FormEvent<HTMLInputElement>): void {
    setInitialDate(event.currentTarget.value)
  }

  function handleFinalDate(event: FormEvent<HTMLInputElement>): void {
    setFinalDate(event.currentTarget.value)
  }

  const handleFilterAdvanceRequests: SubmitHandler<FormFiltersAdvanceRequests> = data => {
    const formattedFilters: FilterAdvanceRequestsType = {
      status: data.status,
      freight_number: data.freight_number,
      creator_id: data.creator_id,
      initial_date: data.initial_date,
      final_date: data.final_date,
    }

    setFilters(formattedFilters)

    updateQueryParams({
      freight_number: data.freight_number || undefined,
      status: data.status || undefined,
      initial_date: data.initial_date || undefined,
      final_date: data.final_date || undefined,
      creator_label: data.creator_id?.label || undefined,
      creator_value: data.creator_id?.value || undefined,
    })
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  useEffect(() => {
    const freightNumberQuery = getParams('freight_number')
    const initialDateQuery = getParams('initial_date')
    const finalDateQuery = getParams('final_date')
    const statusQuery = getParams('status')
    const creatorLabelQuery = getParams('creator_label')
    const creatorValueQuery = getParams('creator_value')

    if (
      freightNumberQuery ||
      statusQuery ||
      initialDateQuery ||
      finalDateQuery ||
      creatorLabelQuery ||
      creatorValueQuery
    ) {
      setValue('freight_number', freightNumberQuery || '')
      setValue('initial_date', initialDateQuery || '')
      setValue('final_date', finalDateQuery || '')
      setValue('status', statusQuery || '')
      setValue('creator_id', { label: creatorLabelQuery || '', value: creatorValueQuery || '' })

      handleSubmit(handleFilterAdvanceRequests)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading fontSize="2xl">Solicitações de adiantamento</Heading>

        <Box>
          <Button
            as={Link}
            to="/advance-requests/create"
            colorScheme="orange"
            leftIcon={<Icon as={GiReceiveMoney} />}
          >
            Nova solicitação de adiantamento
          </Button>
          <Button
            leftIcon={<RiFilter2Line />}
            variant="outline"
            colorScheme="blue"
            ml={2}
            onClick={onToggleFilter}
          >
            Filtros
          </Button>
        </Box>
      </Flex>

      <Filter showFilter={onShowFilter} key={1}>
        <form onSubmit={handleSubmit(handleFilterAdvanceRequests)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Input
                label="Número do frete"
                name="freight_number"
                placeholder="Filtrar pelo número"
                setValue={setValue}
                initialValue={filters?.freight_number}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Select
                label="Status"
                name="status"
                setValue={setValue}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Aguardando Análise',
                    value: 'awaiting_analysis',
                  },
                  {
                    label: 'Reprovado',
                    value: 'rejected',
                  },
                  {
                    label: 'Aguardando Pagamento',
                    value: 'awaiting_payment',
                  },
                  {
                    label: 'Pago',
                    value: 'paid',
                  },
                  {
                    label: 'Finalizado',
                    value: 'finished',
                  },
                ]}
                initialValue={filters?.status}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 6]} mr={2}>
              <AutocompleteAsync
                label="Responsável"
                name="creator_id"
                placeholder="Filtrar pelo responsável"
                setValue={setValue}
                loadOptions={getAllUsers}
                initialValue={filters?.creator_id}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data inicial"
                name="initial_date"
                type="date"
                initialValue={filters.initial_date || initialDate}
                onBlur={handleInitialDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data final"
                name="final_date"
                type="date"
                initialValue={filters.final_date || finalDate}
                onBlur={handleFinalDate}
                setValue={setValue}
              />
            </GridItem>
          </Grid>

          <Flex justifyContent="flex-end" mt={2}>
            <Button type="submit" size="md" colorScheme="blue" leftIcon={<Icon as={RiSearchLine} />}>
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} p={4} borderRadius="8" shadow="md">
        {!isLoading ? (
          advanceRequests && advanceRequests.data && advanceRequests.data.length > 0 ? (
            <>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Frete</Th>
                    <Th>Status</Th>
                    <Th>Motivo</Th>
                    <Th>Reponsável</Th>
                    <Th>Data</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {advanceRequests?.data.map(advanceRequest => (
                    <Tr
                      key={advanceRequest.id}
                      cursor="pointer"
                      _hover={{ bg: hoverBgLineTable, transition: '0.3s' }}
                      onClick={() => {
                        window.open(`/advance-requests/show/${advanceRequest.id}`, '_blank')?.focus()
                      }}
                    >
                      <Td>#{advanceRequest?.freight?.freight_number}</Td>
                      <Td>
                        <Tooltip
                          label={
                            advanceRequest.reject_reason || advanceRequestStatusLegend(advanceRequest.status)
                          }
                          hasArrow
                          placement="top"
                          arrowSize={15}
                        >
                          <Badge
                            px="2"
                            py="1"
                            colorScheme={getAdvanceRequestColorByStatus(advanceRequest.status)}
                            w="100%"
                            textAlign="center"
                          >
                            {advanceRequest.formatted_status}
                          </Badge>
                        </Tooltip>
                      </Td>
                      <Td>
                        {advanceRequest.reason.length > 20 ? (
                          <Tooltip label={advanceRequest.reason} hasArrow placement="top" arrowSize={15}>
                            <Text>
                              {String(advanceRequest.reason).substring(0, 20)}
                              {advanceRequest.reason.length > 20 ? '...' : ''}
                            </Text>
                          </Tooltip>
                        ) : (
                          advanceRequest.reason
                        )}
                      </Td>
                      <Td>{advanceRequest.creator!.name}</Td>
                      <Td>{advanceRequest.created_at}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                currentPage={filters.page || 1}
                totalCountOfRegisters={advanceRequests?.total ?? 0}
                registersPerPage={15}
                onPageChange={handleChangePage}
                pt="4"
                pb="1"
              />
            </>
          ) : isError ? (
            <Text textAlign="center">Erro ao carregar as solicitações de adiantamento</Text>
          ) : (
            <Text textAlign="center">Nenhuma solicitação de adiantamento encontrada</Text>
          )
        ) : (
          <Flex justifyContent="center" alignItems="center">
            <Spinner mr={2} />
            <Text>Carregando dados</Text>
          </Flex>
        )}
      </Box>
    </Layout>
  )
}
