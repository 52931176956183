import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseListApiType } from '../../../types'
import { FormattedFreightExpenseType } from './getAllFinancialTitles'

export type GetFreightExpenseResponse = {
  data: FormattedFreightExpenseType[]
}

export async function fetchIsAllFreightExpenseFinishedOrCanceled(freight_id: string): Promise<boolean> {
  const { data: res } = await apiServer.get<ResponseListApiType<FormattedFreightExpenseType[]>>(
    'freights/financial-title',
    { params: { freight_id, per_page: 10 } },
  )
  const { data } = res
  const isIsAllFreightExpenseFinishedOrCanceledOrCanceled = data.data.every(
    (financial: FormattedFreightExpenseType) =>
      financial.status === 'finished' || financial.status === 'canceled',
  )
  return isIsAllFreightExpenseFinishedOrCanceledOrCanceled
}

export function useIsAllFreightExpenseFinishedOrCanceled(freight_id: string): UseQueryResult<boolean> {
  return useQuery(
    ['list-all-financial-title', freight_id],
    () => fetchIsAllFreightExpenseFinishedOrCanceled(freight_id),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
      refetchOnWindowFocus: 'always',
      retry: false,
    },
  )
}
