import React from 'react'
import { useLocation } from 'react-router-dom'

export function useQueryParams(queryName: string): string {
  const { search } = useLocation()
  const query = React.useMemo(() => new URLSearchParams(search), [search])
  const result = query.get(queryName)
  if (result) return result
  return ''
}
