import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import {
  RiAddFill,
  RiCheckLine,
  RiEditLine,
  RiFileCopyLine,
  RiMapPinLine,
  RiRoadMapLine,
} from 'react-icons/ri'
import { Ability } from '../../../../../components/ability'
import { QuotationInfos } from '../../../../../components/card-infos/QuotationInfos'
import { useGlobalVariables } from '../../../../../contexts/GlobalVariables'
import { useGetMapDirections } from '../../../../../services/endpoints/freights/getMapDirections'
import { useGetOneQuotation } from '../../../../../services/endpoints/freights/getOneQuotation'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { setActionFreightType } from '../../../../../store/slices/freightSlice'
import { EditOrDuplicateFreightModal } from '../../../../freights/edit/EditOrDuplicateFreightModal'
import CreateRoutePricesButton from '../../../../pricing/route-prices/modals/buttons-actions/CreatePriceMotoristButton'
import ShowRoutePricesButton from '../../../../pricing/route-prices/modals/buttons-actions/ShowPriceMotoristButton'
import DisabledStatusQuotationButton from '../../../modals/buttons-actions-status/DisabledStatusQuotationButton'
import NextStatusQuotationButton from '../../../modals/buttons-actions-status/NextStatusQuotationButton'
import PreviousStatusQuotationButton from '../../../modals/buttons-actions-status/PreviousStatusQuotationButton'
import { EditOrDuplicateQuotationModal } from '../../../modals/edit/EditOrDuplicateQuotationModal'
import { EnableQuotationModal } from '../../../modals/edit/EnableQuotationModal'

interface QuotationInfoProps {
  quotation_id: string
  bg: string
}

export interface FreightPendenciesResult {
  motorist: string[]
  vehicle: string[]
  vehicleOwner: string[] | null
  trailer: string[]
  trailerOwner: string[] | null
}

export function getStatusQuotation(status: string): string {
  if (status === 'pending') return 'Solicitação'
  if (status === 'in_quotation') return 'Em cotação'
  if (status === 'to_approve') return 'A aprovar'
  if (status === 'disabled') return 'Desativada'
  return 'Finalizada'
}

export function QuotationInfo({ quotation_id, bg }: QuotationInfoProps): JSX.Element {
  const { setPageTitle } = useGlobalVariables()
  const dispatch = useAppDispatch()
  const { actionFreightType } = useAppSelector(state => state.freightSlice)

  const {
    isOpen: isEnableFreightModalOpen,
    onOpen: onEnableFreightModalOpen,
    onClose: onEnableFreightModalClose,
  } = useDisclosure()
  const {
    isOpen: isOpenQuotationFormModal,
    onOpen: onOpenQuotationFormModal,
    onClose: onCloseQuotationFormModal,
  } = useDisclosure()
  const {
    isOpen: isOpenFreightFormModal,
    onOpen: onOpenFreightFormModal,
    onClose: onCloseFreightFormModal,
  } = useDisclosure()

  const { data: quotation, isLoading } = useGetOneQuotation(quotation_id)

  useEffect(() => {
    if (quotation) {
      setPageTitle(
        `Cotação #${quotation?.quotation_number} |
              ${quotation?.client?.nickname} |
              ${quotation?.origin_city} / ${quotation?.destination_city} |
              SpotX Admin`,
      )
    }
  }, [quotation, setPageTitle])
  const getMapDirections = useGetMapDirections(quotation?.origin, quotation?.destination)

  return (
    <>
      {quotation && (
        <>
          <Flex direction={['column', 'row']} justifyContent="space-between" gridGap={5} mb={4}>
            <Text fontSize={['lg', 'x-large']} fontWeight="normal">
              Cotação #{quotation?.quotation_number}
              {isLoading && <Spinner size="sm" ml="4" />}
            </Text>

            <Tag colorScheme="blue" borderRadius="full" minW="110">
              <Text fontWeight="medium" fontSize="xl">
                {getStatusQuotation(quotation?.status)}
              </Text>
            </Tag>
          </Flex>
          <Flex
            justify="space-between"
            direction={['column', 'column', 'row']}
            gridGap="4"
            alignItems="center"
            mb={4}
          >
            <Flex justify="space-between" gridGap="2" direction={['column', 'column', 'row']}>
              <WrapItem alignItems="center">
                <Tag size="lg" borderRadius="full" backgroundColor="whiteAlpha.900" color="gray.700">
                  <TagLeftIcon fontSize={['xx-small', 'sm', 'sm']} as={RiMapPinLine} />
                  <TagLabel mr="3" fontSize={['xx-small', 'sm', 'sm']}>
                    {quotation.origin_city.substring(0, 20)}
                  </TagLabel>
                  {'>'}
                  <TagLeftIcon as={RiRoadMapLine} ml="2" />
                  <TagLabel fontSize={['xx-small', 'sm', 'sm']}>
                    {quotation.destination_city.substring(0, 20)}
                  </TagLabel>
                  <Text textAlign="center" direction={['row']} fontSize={['xx-small', 'sm', 'sm']} ml="2">
                    ({getMapDirections.data?.metrics.distance})
                  </Text>
                </Tag>
              </WrapItem>
            </Flex>

            <Stack direction={['column', 'row', 'row']} spacing="2" alignItems="center">
              {quotation?.calculation_ref && (
                <Tooltip label="Ir para a calculadora">
                  <Text as={Link} href={quotation.calculation_ref}>
                    <Icon as={AiOutlineCalculator} color="orange" fontSize="3xl" mr="1" />
                  </Text>
                </Tooltip>
              )}

              <Button
                size="sm"
                colorScheme="green"
                leftIcon={<Icon as={RiAddFill} />}
                onClick={() => {
                  onOpenFreightFormModal()
                  dispatch(setActionFreightType('duplicate'))
                }}
              >
                Criar frete
              </Button>

              {quotation?.status !== 'disabled' && <DisabledStatusQuotationButton quotation={quotation} />}

              <>
                {quotation?.status === 'disabled' && (
                  <Button
                    size="sm"
                    colorScheme="green"
                    leftIcon={<Icon as={RiCheckLine} />}
                    onClick={onEnableFreightModalOpen}
                  >
                    Reativar
                  </Button>
                )}

                <EnableQuotationModal
                  quotation_id={quotation.id}
                  isOpen={isEnableFreightModalOpen}
                  onClose={() => {
                    onEnableFreightModalClose()
                  }}
                />
              </>

              <Ability module="operation" action="create-quotation">
                <Button
                  size="sm"
                  colorScheme="blue"
                  leftIcon={<Icon as={RiFileCopyLine} />}
                  onClick={() => {
                    onOpenQuotationFormModal()
                    dispatch(setActionFreightType('duplicate'))
                  }}
                >
                  Duplicar
                </Button>
              </Ability>

              <Ability module="operation" action="update-quotation">
                <Button
                  size="sm"
                  colorScheme="yellow"
                  leftIcon={<Icon as={RiEditLine} />}
                  onClick={() => {
                    onOpenQuotationFormModal()
                    dispatch(setActionFreightType('edit'))
                  }}
                >
                  Editar
                </Button>
              </Ability>
            </Stack>
          </Flex>

          {quotation?.status === 'disabled' && (
            <Alert status="error" colorScheme="red" flexDirection="column" borderRadius="md" my="4">
              <Flex>
                <AlertIcon />
                <Heading fontSize="lg">Cotação desativada</Heading>
              </Flex>
            </Alert>
          )}

          <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
            {quotation ? (
              <>
                <Box mb="6">
                  <Flex alignItems="center" justifyContent="space-between" mb="6">
                    <Heading size="md" fontWeight="normal" mr="4">
                      Informações gerais
                    </Heading>
                    <Stack direction={['column', 'row']}>
                      {quotation.previous_status_quotation && (
                        <PreviousStatusQuotationButton
                          quotation={quotation}
                          change_status={quotation.previous_status_quotation}
                        />
                      )}
                      {quotation.next_status_quotation && (
                        <NextStatusQuotationButton
                          quotation={quotation}
                          change_status={quotation.next_status_quotation}
                        />
                      )}
                      <CreateRoutePricesButton quotation={quotation} />

                      <ShowRoutePricesButton quotation={quotation} />
                    </Stack>
                  </Flex>
                </Box>

                <QuotationInfos quotation={quotation} />
              </>
            ) : isLoading ? (
              <Spinner />
            ) : (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>Erro ao carregar os dados do mapa!</AlertTitle>
                <AlertDescription>
                  Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o
                  administrador do sistema.
                </AlertDescription>
              </Alert>
            )}

            {quotation && (
              <Box mb="6">
                <EditOrDuplicateQuotationModal
                  type={actionFreightType}
                  quotation={quotation}
                  isOpen={isOpenQuotationFormModal}
                  onClose={() => {
                    onCloseQuotationFormModal()
                  }}
                />
                <EditOrDuplicateFreightModal
                  type={actionFreightType}
                  freight_id={quotation.id}
                  isOpen={isOpenFreightFormModal}
                  onClose={() => {
                    onCloseFreightFormModal()
                  }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}
