import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import VehicleAttachmentType from '../../../types/VehicleAttachmentType'

export interface CreateAttachToVehicle {
  id?: string
  owner_id: string
  type: 'cpf' | 'rg' | 'cnh'
  attachment_file: File
}

async function createAttachmentToOwner({ id, type, attachment_file, owner_id }: CreateAttachToVehicle) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  if (id) formData.append('id', id)
  formData.append('type', type)
  formData.append('owner_id', owner_id)
  return apiServer.post<ResponseApiType<VehicleAttachmentType>>('/owners/owner-attachment', formData)
}

export function useCreateAttachmentToOwner(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateAttachToVehicle, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, CreateAttachToVehicle, unknown> {
  return useMutation(async (data: CreateAttachToVehicle) => createAttachmentToOwner(data), options)
}
