import { Grid, GridItem } from '@chakra-ui/react'
import { DeepMap, FieldError, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Input, Select } from '../../../components/form'

interface FiltersStubsProps {
  setValue: UseFormSetValue<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  getValues?: UseFormGetValues<FieldValues>
}

export function FiltersStubs({ setValue, errors, getValues }: FiltersStubsProps): JSX.Element {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="3" mt={3}>
      <GridItem colSpan={[12, 12, 4]}>
        <Input
          label="Número do frete"
          name="freight_number"
          initialValue={getValues?.('freight_number')}
          setValue={setValue}
          error={errors.freight_number}
          placeholder="Número do frete"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Input
          label="Número da CTE"
          name="cte_number"
          initialValue={getValues?.('cte_number')}
          setValue={setValue}
          error={errors.cte_number}
          placeholder="Número da CTE"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Select
          name="status"
          label="Status"
          initialValue={getValues?.('status')}
          error={errors.status}
          setValue={setValue}
          options={[
            { label: '', value: '' },
            { label: 'Aprovados', value: 'approved' },
            { label: 'Reprovados', value: 'disapproved' },
            { label: 'Pendentes', value: 'pending' },
          ]}
        />
      </GridItem>
    </Grid>
  )
}
