import { Box, Button, Divider, Flex, Heading, Stack, useDisclosure } from '@chakra-ui/react'
import { MdPostAdd } from 'react-icons/md'
import { Ability } from '../../../../../components/ability'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { TableListFreightExpense } from '../../list/list-all-financial-title/TableListFinancialTitle'
import { CreateFreightExpenseModal } from '../../modals/CreateFinancialTitleModal'

interface ListFreightExpenseCardProps {
  bg: string
  freight: FormattedOneFreight
}

export function ListFreightExpenseByFreight({
  bg,
  freight,
}: ListFreightExpenseCardProps): JSX.Element | null {
  const { isOpen: isOpenCreate, onClose: onCloseCreate, onOpen: onOpenCreate } = useDisclosure()

  return freight ? (
    <Box bg={bg} p="4" borderRadius="8" mt="4">
      <Stack direction={['column', 'row']} justify="space-between">
        <Flex gridGap={4} align="center">
          <Heading fontSize="md" fontWeight="medium">
            Despesas
          </Heading>
        </Flex>
        <Ability module="financial" action="create-freight-expense">
          <Button
            size="sm"
            onClick={onOpenCreate}
            colorScheme="orange"
            leftIcon={<MdPostAdd />}
            variant="outline"
          >
            Adicionar despesa
          </Button>
        </Ability>
      </Stack>

      <Divider my="4" />

      {freight.formatted_freight_expenses && (
        <TableListFreightExpense FreightExpense={freight.formatted_freight_expenses} />
      )}

      <CreateFreightExpenseModal
        freight_id={freight.id}
        transporter={freight.transporter}
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
      />
    </Box>
  ) : null
}
