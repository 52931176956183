import { BalanceRequestStatusEnum } from '../types/EnumTypes'

export function getBalanceRequestColorByStatus(status: BalanceRequestStatusEnum): string {
  const advanceRequestStatusWithColor = {
    awaiting_analysis: 'gray',
    rejected: 'red',
    awaiting_contract: 'blue',
    awaiting_payment: 'yellow',
    paid: 'blue',
    finished: 'green',
  }

  return advanceRequestStatusWithColor[status]
}
