import { Box, Button, Divider, Flex, Heading, HStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { toastError } from '../../config/error/toastError'
import { queryClient } from '../../config/react-query'
import { Layout } from '../../layout'
import { CreateUserFormData, usePostUser } from '../../services/endpoints/users/postUser'
import { UserForm, userFormSchema } from './show/UserForm'

export function CreateUser(): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(userFormSchema),
  })
  const { isSubmitting } = formState
  const createUsers = usePostUser({
    onSuccess: () => {
      queryClient.invalidateQueries('users')
    },
  })

  const handleCreateUser: SubmitHandler<CreateUserFormData> = async data => {
    try {
      await createUsers.mutateAsync(data)
      history.push('/users')
      toast({
        title: 'Usuário criado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout hasBackground>
      <Heading size="lg" fontWeight="normal">
        Criar usuário
      </Heading>

      <Divider my="6" />

      <Box as="form" onSubmit={handleSubmit(handleCreateUser)} noValidate>
        <UserForm formState={formState} setValue={setValue} />
        <Flex mt="8" justify="flex-end">
          <HStack spacing="4">
            <Link to="/users">
              <Button>Cancelar</Button>
            </Link>
            <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
              Salvar
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Layout>
  )
}
