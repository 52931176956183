import {
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { VehicleOwnerInfos } from '../cards/VehicleOwnerInfos'
import {
  VehicleCompleteForm,
  VehicleCompleteFormData,
  vehicleCompleteFormSchema,
} from '../VehicleCompleteForm'

interface EditVehicleModalProps {
  vehicle_id: string
  motorist_id?: string
  isOpen: boolean
  onClose: () => void
}
export function EditVehicleModal({
  vehicle_id,
  motorist_id,
  isOpen,
  onClose,
}: EditVehicleModalProps): JSX.Element {
  const toast = useToast()
  const { data: vehicle, refetch } = useGetVehicle(vehicle_id)
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(vehicleCompleteFormSchema),
  })

  const handleEditVehicle = useCallback<SubmitHandler<VehicleCompleteFormData>>(
    async data => {
      const vehicleFormData = {
        type: data.type,
        license_plate: String(data?.license_plate).toUpperCase(),
        license_uf: data.license_uf,
        vehicle_category_id: String(data?.vehicle_category),
        vehicle_body_id: String(data?.vehicle_body),
        release_year: data.release_year,
        model_year: data.model_year,
        has_tracker: data.has_tracker,
        brand: data.brand,
        model: data.model,
        renavam: data.renavam,
        chassi: data.chassi,
        antt: data.antt,
        capacity_m3: data.capacity_m3,
        capacity_tara: data.capacity_tara,
        capacity_kg: data.capacity_kg,
        has_insurance: data.has_insurance,
        city_id: data.city_id?.value,
        color: data.color,
        axes: data.axes,
      }

      try {
        await apiServer.put(`/update-vehicle/${vehicle_id}`, vehicleFormData)
        onClose()
        toast({
          title: 'Veículo editado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        refetch()
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [vehicle_id, toast, onClose, refetch],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleEditVehicle)} noValidate>
          <ModalHeader>Editar Veículo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <VehicleCompleteForm
                setValue={setValue}
                formState={formState}
                initialData={vehicle}
                isEditVehicle
              />
              <Divider my="2" />
            </>

            <Flex>
              <VehicleOwnerInfos vehicle_id={vehicle_id} motorist_id={motorist_id} />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>

              <Ability module="operation" action="update-vehicle">
                <Button
                  type="submit"
                  colorScheme="orange"
                  leftIcon={<Icon as={RiEditLine} />}
                  isLoading={formState.isSubmitting}
                >
                  Editar
                </Button>
              </Ability>
            </>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
