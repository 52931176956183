import { Button, Divider, Grid, GridItem, Heading, HStack, Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'
import { Autocomplete } from '../../../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { Input } from '../../../../components/form/Input'
import { InputMask } from '../../../../components/form/InputMask'
import { Select } from '../../../../components/form/Select'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { searchBuonnyFabricators } from '../../../../services/endpoints/vehicles/searchBuonnyFabricators'
import { searchBuonnyModels } from '../../../../services/endpoints/vehicles/searchBuonnyModels'
import { MotoristType } from '../../../../services/types'
import { buonnyTechnologiesOptions } from '../../../../services/utils/getBuonnyTechnologies'

interface VehicleTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  motorist: MotoristType
  formState: FormState<FieldValues>
}

const vehicleColorsBuonny = [
  { label: '', value: '' },
  { label: 'ALUMINIO', value: 'ALUMINIO' },
  { label: 'AMARELO', value: 'AMARELO' },
  { label: 'AZUL', value: 'AZUL' },
  { label: 'BEGE', value: 'BEGE' },
  { label: 'BORDÔ', value: 'BORDÔ' },
  { label: 'BRANCO', value: 'BRANCO' },
  { label: 'CHUMBO', value: 'CHUMBO' },
  { label: 'CIANO', value: 'CIANO' },
  { label: 'CINZA', value: 'CINZA' },
  { label: 'CREME', value: 'CREME' },
  { label: 'DOURADA', value: 'DOURADA' },
  { label: 'FANTASIA', value: 'FANTASIA' },
  { label: 'GELO', value: 'GELO' },
  { label: 'GRENA', value: 'GRENA' },
  { label: 'LARANJA', value: 'LARANJA' },
  { label: 'LILÁS', value: 'LILÁS' },
  { label: 'MAGENTA', value: 'MAGENTA' },
  { label: 'MARROM', value: 'MARROM' },
  { label: 'OLIVA', value: 'OLIVA' },
  { label: 'OUTROS', value: 'OUTROS' },
  { label: 'PRATA', value: 'PRATA' },
  { label: 'PRETO', value: 'PRETO' },
  { label: 'ROSA', value: 'ROSA' },
  { label: 'ROXO', value: 'ROXO' },
  { label: 'SALMÃO', value: 'SALMÃO' },
  { label: 'TURQUESA', value: 'TURQUESA' },
  { label: 'VERDE', value: 'VERDE' },
  { label: 'VERMELHO', value: 'VERMELHO' },
  { label: 'VINHO', value: 'VINHO' },
  { label: 'VIOLETA', value: 'VIOLETA' },
]

export function VehicleTabPanel({
  setValue,
  tabNav,
  motorist,
  formState,
}: VehicleTabPanelProps): JSX.Element {
  const { errors } = formState
  const [buonnyModels, setBuonnyModels] = useState<AutocompleteOption[]>([])
  const [buonnyModelsCart, setBuonnyModelsCart] = useState<AutocompleteOption[]>([])

  return (
    <>
      <Heading
        size="md"
        fontWeight="normal"
        mb="6"
        pb="2"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
      >
        Dados do veículo
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 2]}>
          <InputMask
            name="vehicle_license_plate"
            label="Placa do veículo"
            mask="aaa9*99"
            maskPlaceholder=""
            setValue={setValue}
            initialValue={motorist.vehicles[0].license_plate}
            error={errors.vehicle_license_plate}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <AutocompleteAsync
            name="vehicle_city"
            label="Cidade"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            error={errors.vehicle_city}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="vehicle_renavam"
            label="RENAVAM"
            setValue={setValue}
            initialValue={motorist.vehicles[0].renavam}
            error={errors.vehicle_renavam}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="vehicle_chassi"
            label="CHASSI"
            setValue={setValue}
            initialValue={motorist.vehicles[0].chassi}
            error={errors.vehicle_chassi}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <AutocompleteAsync
            name="vehicle_brand"
            label="Marca"
            setValue={setValue}
            loadOptions={searchBuonnyFabricators}
            error={errors.vehicle_brand}
            onSelectOption={async option => {
              const options = await searchBuonnyModels(String(option.value))

              setBuonnyModels(options)
            }}
          />
        </GridItem>

        {buonnyModels.length > 0 && (
          <GridItem colSpan={[12, 3]}>
            <Autocomplete
              name="vehicle_model"
              label="Modelo"
              setValue={setValue}
              options={buonnyModels}
              error={errors.vehicle_model}
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 2]}>
          <Input
            type="number"
            name="vehicle_release_year"
            label="Ano de fabricação"
            setValue={setValue}
            error={errors.vehicle_release_year}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Input
            type="number"
            name="vehicle_model_year"
            label="Ano do modelo"
            setValue={setValue}
            error={errors.vehicle_model_year}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="vehicle_color"
            label="Cor"
            setValue={setValue}
            options={vehicleColorsBuonny}
            error={errors.vehicle_color}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Autocomplete
            name="vehicle_technology"
            label="Tecnologia"
            setValue={setValue}
            options={buonnyTechnologiesOptions}
            error={errors.vehicle_technology}
          />
        </GridItem>

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>
      </Grid>

      <Heading
        size="md"
        fontWeight="normal"
        mb="6"
        pb="2"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
      >
        Dados da carreta
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 2]}>
          <InputMask
            name="vehicle_cart_license_plate"
            label="Placa da carreta"
            mask="aaa9*99"
            maskPlaceholder=""
            setValue={setValue}
            initialValue={motorist.vehicles[0].body_license_plate}
            error={errors.vehicle_cart_license_plate}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <AutocompleteAsync
            name="vehicle_cart_city"
            label="Cidade"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            error={errors.vehicle_cart_city}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="vehicle_cart_renavam"
            label="RENAVAM"
            setValue={setValue}
            error={errors.vehicle_cart_renavam}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="vehicle_cart_chassi"
            label="CHASSI"
            setValue={setValue}
            error={errors.vehicle_cart_chassi}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <AutocompleteAsync
            name="vehicle_cart_brand"
            label="Marca"
            setValue={setValue}
            loadOptions={searchBuonnyFabricators}
            error={errors.vehicle_brand}
            onSelectOption={async option => {
              const options = await searchBuonnyModels(String(option.value))

              setBuonnyModelsCart(options)
            }}
          />
        </GridItem>

        {buonnyModelsCart.length > 0 && (
          <GridItem colSpan={[12, 3]}>
            <Autocomplete
              name="vehicle_cart_model"
              label="Modelo"
              setValue={setValue}
              options={buonnyModelsCart}
              error={errors.vehicle_model}
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 2]}>
          <Input
            name="vehicle_cart_release_year"
            label="Ano de fabricação"
            setValue={setValue}
            error={errors.vehicle_cart_release_year}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Input
            name="vehicle_cart_model_year"
            label="Ano do modelo"
            setValue={setValue}
            error={errors.vehicle_cart_model_year}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="vehicle_cart_color"
            label="Cor"
            setValue={setValue}
            options={vehicleColorsBuonny}
            error={errors.vehicle_cart_color}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Autocomplete
            name="vehicle_cart_technology"
            label="Tecnologia"
            setValue={setValue}
            options={buonnyTechnologiesOptions}
            error={errors.vehicle_cart_technology}
          />
        </GridItem>
      </Grid>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(4)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(6)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
