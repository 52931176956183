import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { NfeType } from '../../../types'

type FetchNfesParams = {
  freight_id?: string
  cte_id?: string
  only_not_attached?: boolean
  only_without_cte?: boolean
  unpaged?: boolean
  page?: number
}

type fetchNfesResponse = {
  total: number
  data: NfeType[]
}

export async function fetchNfes(data: FetchNfesParams): Promise<fetchNfesResponse> {
  const params = data && { ...data }

  const { data: response } = await apiServer.get('/nfes', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useGetNfes(data: FetchNfesParams): UseQueryResult<fetchNfesResponse> {
  return useQuery(['nfes', data], () => fetchNfes(data), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  })
}
