import { useQuery, UseQueryResult } from 'react-query'
import { format } from 'date-fns'
import { apiServer } from '../../api'
import FreightAttachmentType from '../../types/FreightAttachmentType'
import { getLabelAttachmentFreight } from '../../utils/attachmentsFreightOptions'
import { FreightAttachmentsTypeEnum } from '../../types/EnumTypes'
import { ResponseApiType } from '../../types'

export interface IFiltersFreightAttachments {
  freight_id?: string
  freight_status?: string
  type?: FreightAttachmentsTypeEnum
  document?:
    | 'nf-e'
    | 'ct-e'
    | 'ciot'
    | 'mdf-e'
    | 'collecting_order'
    | 'stub'
    | 'proof_paid_advance'
    | 'proof_paid_daily'
    | 'proof_paid_delivery'
    | 'proof_paid_GNRE'
    | 'proof_paid_balance'
    | 'proof_request_payment'
    | 'freight_contract'
    | 'pictures_sealed_truck'
    | 'GNRE_guide'
    | 'unloading_receipt'
    | 'concierge_ticket'
    | 'other'
  id?: string
}

export interface FormattedFreightAttachments extends FreightAttachmentType {
  document_formatted: string
  formatted_delivery_receipt_at: string
}

export async function getAttachments(
  params: IFiltersFreightAttachments,
): Promise<FormattedFreightAttachments[]> {
  if (params.freight_id) {
    const { data: res } = await apiServer.get<ResponseApiType<FreightAttachmentType[]>>(
      `/list-freight-attachment/${params.freight_id}`,
      {
        params: { document: params.document, id: params.id },
      },
    )
    const { data } = res
    if (data && data.length > 0) {
      const attachments = data.map((attach: FreightAttachmentType) => {
        return {
          ...attach,
          document_formatted: getLabelAttachmentFreight(attach.document) || '',
          formatted_delivery_receipt_at: attach.delivery_receipt_at
            ? format(new Date(attach.delivery_receipt_at), 'dd/MM/yyyy')
            : '',
        }
      })
      return attachments
    }
    return []
  }
  return []
}
export function useAttachments(
  params: IFiltersFreightAttachments,
): UseQueryResult<FormattedFreightAttachments[]> {
  return useQuery(['attachments', params], () => getAttachments(params), {
    staleTime: 1000 * 60 * 10, // 10 minutos
  })
}
