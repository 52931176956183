import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'
import { invalidateCache } from './putRiskAnalysis'

export interface ChangeStatusRiskAnalysis {
  id: string
  status: string
  reason?: string
  depends_on_check?: boolean
  consultation_code?: string
}

export async function patchRiskAnalysis({
  id,
  ...data
}: ChangeStatusRiskAnalysis): Promise<AxiosResponse<ResponseApiType<RiskAnalysisType>>> {
  const riskAnalysisUpdated = await apiServer.patch(`/change-status-risk-analysis/${id}`, data)
  if (riskAnalysisUpdated) await invalidateCache()
  return riskAnalysisUpdated
}

export function usePatchRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, ChangeStatusRiskAnalysis, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, ChangeStatusRiskAnalysis, unknown> {
  return useMutation(async (data: ChangeStatusRiskAnalysis) => patchRiskAnalysis(data), options)
}
