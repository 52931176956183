import {
  Button,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { RiCheckboxCircleLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { useAppDispatch, useAppSelector } from '../../../store'
import { changeFrightStatus } from '../../../store/slices/freightSlice'

interface EnableFreightModalProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
}

export function EnableFreightModal({ isOpen, onClose, freight_id }: EnableFreightModalProps): JSX.Element {
  const toast = useToast()
  const { isLoading } = useAppSelector(state => state.freightSlice)
  const dispatch = useAppDispatch()
  async function handleEnableFreight() {
    const error = await dispatch(changeFrightStatus({ freight_id, status: 'pending' }))
    if (!error) {
      onClose()
      toast({
        title: 'Frete ativado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    } else {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tem certeza que deseja reativar o frete?</ModalHeader>
        <ModalCloseButton />

        <ModalFooter>
          <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            leftIcon={<Icon as={RiCheckboxCircleLine} />}
            isLoading={isLoading}
            onClick={handleEnableFreight}
          >
            Reativar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
