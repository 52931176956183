import { Flex, Spinner, Alert, AlertIcon, Text } from '@chakra-ui/react'

interface IFreightsNoShowProps {
  freightsNoShow?: number
  isLoading: boolean
  isFetching: boolean
  error: unknown
}

export function FreightsNoShowCard({
  freightsNoShow,
  isLoading,
  isFetching,
  error,
}: IFreightsNoShowProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes No-Show. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        <Flex justifyContent="space-between" pl="4" pr="4" alignItems="baseline" fontWeight="bold">
          <Flex direction="row" gridGap="2" alignItems="center">
            <Text fontSize="lg" color="red">
              NO-SHOW
              {!isLoading && isFetching && <Spinner ml={2} />}
            </Text>
          </Flex>
          <Text fontSize="x-large" color="red">
            {freightsNoShow && freightsNoShow}
          </Text>
        </Flex>
      )}
    </>
  )
}
