import { Box, Table, Tbody, Td, Th, Thead, Tr, Heading, Text, Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { RiStarLine } from 'react-icons/ri'
import { Pagination } from '../../../../../layout/Pagination'
import { useGetAllClientMonthlyNps } from '../../../../../services/endpoints/clients/client-monthly-nps/getAllClientMonthlyNps'

export interface ContactByClientProps {
  client_id: string
  bg: string
}

export function HistoryClientMonthlyNps({ client_id, bg }: ContactByClientProps): JSX.Element {
  const [page, setPage] = useState(1)

  const { data: clientMonthlyNps } = useGetAllClientMonthlyNps({
    client_id,
    page,
  })

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Heading fontSize="2xl" fontWeight="medium">
        Histórico de feedbacks
      </Heading>

      <Table overflowX="auto" mt={4} w="100%">
        <Thead>
          <Tr>
            <Th>Data da avaliação</Th>
            <Th>Motivo</Th>
            <Th>Nota</Th>
            <Th>Solicitante</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clientMonthlyNps?.data?.map(feedback => (
            <Tr key={feedback.id}>
              <Td>{feedback.formatted_created_at}</Td>
              <Td>{feedback.reason}</Td>
              <Td>
                <Text fontWeight="bold" color="yellow.500" d="flex" alignItems="center">
                  <Icon as={RiStarLine} fontSize={20} mx="1" />
                  {feedback.recommendation_score}
                </Text>
              </Td>
              <Td>{feedback.client_contact?.name ?? 'Não informado'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {clientMonthlyNps && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={clientMonthlyNps.total}
          registersPerPage={10}
          onPageChange={setPage}
          p="6"
        />
      )}
    </Box>
  )
}
