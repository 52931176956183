import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseListApiType } from '../../../types'
import { RoutePricesType } from '../../../types/MotoristType'
import parseCurrency from '../../../utils/parseCurrency'

export interface RoutePricesFormatted extends RoutePricesType {
  toll_formatted: string
  value_formatted: string
  source_formatted: string
  created_at_formatted: string
}

export type GetRoutePricesListResponse = {
  total: number
  data: RoutePricesFormatted[]
}

export interface FiltersRoutePricesParams {
  initial_date?: string
  final_date?: string
  quotation_id?: string
  motorist_id?: string
  origin_city_id?: string
  destination_city_id?: string
  nome?: string
  phone?: string
  page?: number
  per_page?: number
  unpaged?: boolean
}

export const trackingDeviceType = [
  { label: '', value: '' },
  { label: 'Rastreador', value: 'tracker' },
  { label: 'Localizador', value: 'locator' },
  { label: 'Híbrido', value: 'hybrid' },
]

export async function fetchAllRoutePrices(
  params: FiltersRoutePricesParams,
): Promise<GetRoutePricesListResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<RoutePricesFormatted[]>>(
    '/list-route-prices',
    {
      params,
    },
  )

  const { data } = res
  const { total } = data

  const routePricesType = data.data.map(cargo => {
    return {
      ...cargo,
      created_at_formatted: format(new Date(cargo.created_at), 'dd/MM/yy - HH:mm'),
      value_formatted: parseCurrency(cargo.value),
      source_formatted: cargo.source === 'data_base' ? 'Base' : 'Fretebras',
      toll_formatted: parseCurrency(cargo.toll ? cargo.toll : 0),
    }
  })

  return { total, data: routePricesType }
}

export function useGetAllRoutePrices(
  params: FiltersRoutePricesParams,
): UseQueryResult<GetRoutePricesListResponse> {
  return useQuery(['prices-motorists', params], () => fetchAllRoutePrices(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
