import { createContext, ReactNode, useContext, useState } from 'react'
import { ResponseOcrCnh } from '../services/types'

interface PendenciesOCRContextProviderProps {
  children: ReactNode
}

interface PendenciesOCRContextData {
  setResultOCR: React.Dispatch<React.SetStateAction<ResponseOcrCnh | undefined>>
  resultOCR: ResponseOcrCnh | undefined
}

const PendenciesOCRContext = createContext({} as PendenciesOCRContextData)

export function PendenciesOCRContextProvider({ children }: PendenciesOCRContextProviderProps): JSX.Element {
  const [resultOCR, setResultOCR] = useState<ResponseOcrCnh>()

  return (
    <PendenciesOCRContext.Provider value={{ setResultOCR, resultOCR }}>
      {children}
    </PendenciesOCRContext.Provider>
  )
}

export function usePendenciesOCRContext(): PendenciesOCRContextData {
  const context = useContext(PendenciesOCRContext)
  if (!context) {
    throw new Error('usePendenciesOCRContext must be used within a PendenciesOCRContextProvider!')
  }
  return context
}
