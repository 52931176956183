/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  isLoading: boolean
  isNewImage: boolean
  driverLicenseImg: string
  driverLicenseOwnerImg: string
  driverLicenseFile: File
}

const initialState: State = {
  isLoading: false,
  isNewImage: false,
  driverLicenseImg: '',
  driverLicenseOwnerImg: '',
  driverLicenseFile: {} as File,
}

const ocrCnhSlice = createSlice({
  name: 'ocrCnh',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },

    setIsNewImage(state, action: PayloadAction<boolean>) {
      state.isNewImage = action.payload
    },

    setDriverLicenseImg(state, action: PayloadAction<string>) {
      state.driverLicenseImg = action.payload
    },

    setDriverLicenseOwnerImg(state, action: PayloadAction<string>) {
      state.driverLicenseOwnerImg = action.payload
    },

    setDriverLicenseFile(state, action: PayloadAction<File>) {
      state.driverLicenseFile = action.payload
    },
  },
})

export const { setDriverLicenseImg, setDriverLicenseOwnerImg, setDriverLicenseFile, setIsNewImage } =
  ocrCnhSlice.actions
export default ocrCnhSlice.reducer
