/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

async function deleteRiskAnalysis(id: string) {
  return apiServer.delete(`delete-risk-analysis/${id}`)
}

export function useDeleteRiskAnalysis(options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (id: string) => deleteRiskAnalysis(id), options)
}
