import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { BalanceRequestTypeFormatted } from '../../../../../services/endpoints/freights/getBalanceRequest'
import { usePutBalanceRequest } from '../../../../../services/endpoints/freights/putBalanceRequest'
import {
  BalanceRequestAttachmentTypeEnum,
  BalanceRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateBalanceRequestForm } from '../forms/CreateOrUpdateBalanceRequestForm'

type UpdateBalanceRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  initial_value: BalanceRequestTypeFormatted
}

type RequestParams = {
  id: string
}

type UpdateBalanceRequestType = {
  cte_id: string
  total_value: string
  payment_type: string
  pix?: string
  e_frete?: string
  account_type?: string
  bank?: SelectOption
  agency?: string
  account?: string
  account_digit?: string
  observation?: string
}

const UpdateBalanceRequestSchema = yup.object().shape({
  cte_id: yup.string(),
  total_value: yup.string().required('Campo obrigatório'),
  payment_type: yup.string().required('Campo obrigatório'),
  pix: yup.string().when('payment_type', {
    is: 'pix',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account_type: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  bank: yup
    .object()
    .shape({})
    .when('payment_type', {
      is: 'account',
      then: yup.object().shape({}).required('Campo obrigatório'),
      otherwise: yup.object().shape({}),
    }),
  agency: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  e_frete: yup.string().required('Campo obrigatório'),
  account_digit: yup.string(),
  observation: yup.string(),
})

export function UpdateBalanceRequestModal({
  isOpen,
  onClose,
  initial_value,
}: UpdateBalanceRequestModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateBalanceRequestSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateBalanceRequest, isLoading: isLoadingUpdateBalanceRequest } =
    usePutBalanceRequest({
      onSuccess: () => {
        toast({
          title: 'Solicitação de saldo atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })

        onClose()
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const handleUpdateBalanceRequest: SubmitHandler<UpdateBalanceRequestType> = async data => {
    const updateBalanceRequestData = {
      status: BalanceRequestStatusEnum.AWAITING_ANALYSIS,
      cte_id: data.cte_id,
      total_value: Number(data.total_value),
      pix: data.payment_type === 'pix' ? data.pix : null,
      account_type: data.payment_type === 'account' ? data.account_type : null,
      bank: data.payment_type === 'account' ? data.bank?.label : null,
      agency: data.payment_type === 'account' ? data.agency : null,
      account: data.payment_type === 'account' ? data.account : null,
      account_digit: data.payment_type === 'account' ? data.account_digit : null,
      e_frete: data.e_frete === 'yes',
      observation: data.observation,
    }

    const balanceRequestHaveContract = initial_value.attachments?.filter(
      attachment => attachment.type === BalanceRequestAttachmentTypeEnum.CONTRACT,
    )

    if (initial_value && balanceRequestHaveContract && balanceRequestHaveContract?.length > 0) {
      Object.assign(updateBalanceRequestData, {
        status: BalanceRequestStatusEnum.AWAITING_PAYMENT,
      })
    }

    await updateBalanceRequest({
      id,
      data: updateBalanceRequestData,
    })
  }

  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Atualizar solicitação de saldo</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUpdateBalanceRequest)} noValidate>
          <ModalBody>
            <CreateOrUpdateBalanceRequestForm
              setValue={setValue}
              errors={errors}
              initial_value={initial_value}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingUpdateBalanceRequest}
              isDisabled={isLoadingUpdateBalanceRequest}
            >
              Atualizar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
