import { Box, Flex, Icon, Link, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { GrAttachment } from 'react-icons/gr'
import { TiDeleteOutline } from 'react-icons/ti'
import { toastError } from '../config/error/toastError'
import { queryClient } from '../config/react-query'
import { FreightExpenseAttachmentsType } from '../services/types/FreightExpenseType'
import { Ability } from './ability'
import { AlertDialogConfirm } from './AlertDialogConfirm'

interface AttachmentProps {
  attach?: FreightExpenseAttachmentsType
  attach_id: string
  attachment_file_url: string
  handleDeleteAttachment: (data: string) => Promise<void>
}

export function AttachmentIcon({
  attach,
  attach_id,
  attachment_file_url,
  handleDeleteAttachment,
}: AttachmentProps): JSX.Element {
  const toast = useToast()

  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()

  return (
    <Flex direction="row">
      <Tooltip
        label={
          attach ? (
            <Text>
              Ver o anexo enviado por <strong>{attach?.user?.name}</strong>
            </Text>
          ) : (
            'Ver o anexo'
          )
        }
        hasArrow
        placement="auto"
      >
        <Link href={attachment_file_url} target="_blank">
          <Icon fontSize="3xl" as={GrAttachment} />
        </Link>
      </Tooltip>

      <Ability module="spotHub" action="show-delete-attachment">
        <Tooltip label="Apagar anexo" hasArrow mt="-4" placement="auto">
          <Box>
            <Icon
              fontSize="sm"
              color="red"
              cursor="pointer"
              onClick={onOpenConfirm}
              mb="4"
              as={TiDeleteOutline}
            />
          </Box>
        </Tooltip>
      </Ability>
      {attach_id && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title="Remover anexo"
          description="Tem certeza que deseja remover este anexo?"
          onClose={onCloseConfirm}
          onConfirm={async () => {
            try {
              await handleDeleteAttachment(attach_id)
              queryClient.invalidateQueries('list-all-financial-title-attachments')
              queryClient.invalidateQueries('list-all-financial-title')
              queryClient.invalidateQueries('financial-title')
              queryClient.invalidateQueries('freight')
              queryClient.invalidateQueries('attachments')
              toast({
                title: 'Anexo apagado com sucesso!',
                status: 'success',
                position: 'top-right',
                isClosable: true,
                duration: 8000,
              })
            } catch (error) {
              toastError({ toast, error })
            }
          }}
        />
      )}
    </Flex>
  )
}
