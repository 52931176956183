/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { zonedTimeToUtc } from 'date-fns-tz'
import { apiServer } from '../../api'

export interface createFreightStatusHistory {
  status_date?: string
  freight_id: string
  status: string
  origin?: 'app' | 'tms'
}

async function createFreightStatusHistory(data: createFreightStatusHistory) {
  const statusDate = zonedTimeToUtc(new Date(String(data.status_date)), 'America/Sao_Paulo').toISOString()

  return apiServer.post(`/create-freight-status-history`, {
    ...data,
    status_date: statusDate,
  })
}

export function useCreateFreightStatusHistory(
  options?: UseMutationOptions<AxiosResponse, unknown, createFreightStatusHistory, unknown>,
) {
  return useMutation(async (data: createFreightStatusHistory) => createFreightStatusHistory(data), options)
}
