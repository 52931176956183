import { Text } from '@chakra-ui/react'
import { Marker, Popup } from 'react-leaflet'
import { useGetOneFreight } from '../../services/endpoints/freights'
import { useGetWaypoints } from '../../services/endpoints/motorists/getWaypoints'
import { mapCurrentPositionIcon, mapDestinationIcon, mapFirstPositionIcon, mapOriginIcon } from './icons'
import { LoadingMapSpinner } from './LoadingMapSpinner'
import { Map } from './Map'

type FreightMapProps = {
  source?: 'openstreetmap' | 'mapbox' | 'google'
  freight_id: string
  type: 'app' | 'whatsapp' | 'buonny'
}

export function FreightMap({ source = 'openstreetmap', freight_id, type }: FreightMapProps): JSX.Element {
  const { data: freight, isLoading: isLoadingFreight } = useGetOneFreight({
    freight_id,
    relations: ['origin', 'destination', 'route_points'],
  })

  const { data: outputWayPoints, isLoading: isLoadingWaypoints } = useGetWaypoints({
    type,
    freight,
  })
  const waypoints = outputWayPoints?.allWaypoints
  const firstWayPoint = outputWayPoints?.firstWayPoint
  const lastWayPoint = outputWayPoints?.lastWayPoint

  const directionsPositions =
    freight?.route_points?.map(point => {
      return [+point.latitude, +point.longitude] as [number, number]
    }) ?? []

  const motoristPositions =
    waypoints?.map(waypoint => {
      return [+waypoint.lat, +waypoint.lng] as [number, number]
    }) ?? []

  return isLoadingFreight || isLoadingWaypoints || !freight ? (
    <LoadingMapSpinner />
  ) : (
    <Map
      source={source}
      distance={freight?.route_points && +freight?.route_points[0]?.distance?.split(' ')[0]}
      polylines={[
        {
          positions: motoristPositions,
          color: 'red',
          opacity: 0.8,
          weight: 5,
        },
        {
          positions: directionsPositions,
          color: 'blue',
          dashArray: [10, 5],
          opacity: 0.5,
        },
      ]}
    >
      <>
        <Marker position={[freight.origin?.lat, freight.origin?.lng]} icon={mapOriginIcon}>
          <Popup>
            <Text as="span" d="block">
              <strong>Cidade Origem</strong>

              <br />
              {`${freight.origin?.name} - ${freight.origin?.state.uf}`}
            </Text>
          </Popup>
        </Marker>

        <Marker position={[freight.destination?.lat, freight.destination?.lng]} icon={mapDestinationIcon}>
          <Popup>
            <Text as="span" d="block">
              <strong>Cidade Destino</strong>
              <br />
              {`${freight.destination?.name} - ${freight.destination?.state.uf}`}
            </Text>
          </Popup>
        </Marker>

        {firstWayPoint && (
          <Marker position={[firstWayPoint?.lat, firstWayPoint?.lng]} icon={mapFirstPositionIcon}>
            <Popup>
              <Text fontWeight="bold">Dados da primeira posição do veículo</Text>
              {firstWayPoint.city && (
                <Text as="span" d="block">
                  <strong>Cidade: </strong>
                  {`${firstWayPoint.city.name} - ${firstWayPoint.city.uf}`}
                </Text>
              )}
              {firstWayPoint.public_place && (
                <Text as="span" d="block">
                  <strong>Logradouro: </strong>
                  {firstWayPoint.public_place}
                </Text>
              )}
              <Text as="span" d="block">
                <strong>Enviado em: </strong>
                {firstWayPoint.waypoint_date_formatted}
              </Text>
            </Popup>
          </Marker>
        )}

        {lastWayPoint && (
          <Marker position={[lastWayPoint?.lat, lastWayPoint?.lng]} icon={mapCurrentPositionIcon}>
            <Popup>
              <Text fontWeight="bold">Dados da posição atual do veículo</Text>
              {lastWayPoint.city && (
                <Text as="span" d="block">
                  <strong>Cidade: </strong>
                  {`${lastWayPoint.city.name} - ${lastWayPoint.city.uf}`}
                </Text>
              )}
              {lastWayPoint.public_place && (
                <Text as="span" d="block">
                  <strong>Logradouro: </strong>
                  {lastWayPoint.public_place}
                </Text>
              )}
              <Text as="span" d="block">
                <strong>Enviado em: </strong>
                {lastWayPoint.waypoint_date_formatted}
              </Text>
            </Popup>
          </Marker>
        )}
      </>
    </Map>
  )
}
