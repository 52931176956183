import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export async function searchBuonnyFabricators(name: string): Promise<AutocompleteOption[]> {
  if (name) {
    const { data } = await apiServer.get<ResponseApiType<string[]>>('/buonny/vehicle-fabricators', {
      params: {
        name,
      },
    })
    if (data.statusCode === 200) {
      return data.data.map(fabricator => {
        const label = fabricator
        const value = fabricator

        return { label, value }
      })
    }
  }

  return []
}
