/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export interface UpdateAttachToMotorist {
  id: string
  attachment_file: File
}

async function updateAttachmentToMotorist({ id, attachment_file }: UpdateAttachToMotorist) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  return apiServer.patch(`/motorists/attachments/${id}`, formData)
}

export function useUpdateAttachmentToMotorist(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateAttachToMotorist, unknown>,
) {
  return useMutation(async (data: UpdateAttachToMotorist) => updateAttachmentToMotorist(data), options)
}
