import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { FreightType } from '../../types'

export interface putFreight extends Partial<FreightType> {
  has_monitoring_buonny?: boolean
  freight_id: string
}

export async function updateFreight({ freight_id, ...data }: putFreight): Promise<AxiosResponse<any>> {
  const output = await apiServer.put(`update-freight/${freight_id}`, data)
  if (output.status === 200) {
    queryClient.invalidateQueries('freight')
    queryClient.invalidateQueries('freights')
    queryClient.invalidateQueries('list-freight-in-progress')
  }
  return output
}

export function usePutFreight(
  options?: UseMutationOptions<AxiosResponse<any>, unknown, putFreight, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, putFreight, unknown> {
  return useMutation<AxiosResponse<any>, unknown, putFreight, unknown>(
    (data: putFreight) => updateFreight(data),
    options,
  )
}
