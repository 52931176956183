import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import * as yup from 'yup'

import { queryClient } from '../../config/react-query'

import { usePostMonitoringOccurence } from '../../services/endpoints/freights/postMonitoringOccurence'

import { Form } from './Form'

interface ICreateMonitoringOccurencesProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
}

const CreateFormSchema = yup.object().shape({
  monitoring_type: yup.string().required('Campo obrigatório'),
  observation: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório'),
})

interface ICreateMonitoringOccurence {
  observation: string
  reason: string
}

export function CreateMonitoringOccurences({
  isOpen,
  onClose,
  freight_id,
}: ICreateMonitoringOccurencesProps): JSX.Element {
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(CreateFormSchema),
  })

  const { errors } = formState

  const toast = useToast()

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } = usePostMonitoringOccurence({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
    },
  })

  const handleCreateMonitoringOccurence: SubmitHandler<ICreateMonitoringOccurence> = async data => {
    try {
      await mutateAsyncCreate({
        observation: data.observation,
        reason_id: data.reason,
        freight_id,
      })

      onClose()
    } catch (err) {
      const error = err as AxiosError

      toast({
        status: 'error',
        title:
          error.response?.data.message ||
          'Não foi possível criar a ocorrência de monitoramento. Tente novamente mais tarde.',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit(handleCreateMonitoringOccurence)} noValidate>
        <ModalContent>
          <ModalHeader>Nova ocorrência de monitoramento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form setValue={setValue} errors={errors} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiCheckLine} />}
              isLoading={isLoadingCreate}
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
