import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { queryClient } from '../../../../../../config/react-query'
import { RiskAnalysisOneFormatted } from '../../../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { LinkMotoristToFreight } from './LinkMotoristToFreight'
import { UpdateRiskAnalysisInfos } from './UpdateRiskAnalysisInfos'

interface LinkMotoristToFreightModalProps {
  riskAnalysis: RiskAnalysisOneFormatted
  isOpen: boolean
  onClose: () => void
}

export function LinkMotoristToFreightModal({
  riskAnalysis,
  isOpen,
  onClose,
}: LinkMotoristToFreightModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        queryClient.invalidateQueries('freight')
        onClose()
      }}
      closeOnOverlayClick={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Opções</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {['Aprovado', 'A Checar'].includes(riskAnalysis.status) ? (
            <LinkMotoristToFreight riskAnalysis={riskAnalysis} onClose={onClose} />
          ) : (
            <UpdateRiskAnalysisInfos riskAnalysis={riskAnalysis} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
