import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FiPaperclip } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { toastError } from '../../../../../config/error/toastError'
import { usePutBalanceRequest } from '../../../../../services/endpoints/freights/putBalanceRequest'
import { useUploadBalanceRequestAttachments } from '../../../../../services/endpoints/freights/UploadBalanceRequestAttachments'
import {
  BalanceRequestStatusEnum,
  BalanceRequestAttachmentTypeEnum,
} from '../../../../../services/types/EnumTypes'

type UploadBalanceRequestContractModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

type UpdateBalanceRequestFormData = {
  attachments: File[]
}

export function UploadBalanceRequestContractModal({
  isOpen,
  onClose,
}: UploadBalanceRequestContractModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue } = useForm({})
  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateBalanceRequestStatus, isLoading } = usePutBalanceRequest({
    onSuccess: () => {
      toast({
        title: 'Solicitação de saldo atualizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadBalanceRequestAttachments,
    isLoading: isLoadingUploadBalanceRequestAttachments,
  } = useUploadBalanceRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateBalanceRequest: SubmitHandler<UpdateBalanceRequestFormData> = async data => {
    const uploadFormData = {
      status: BalanceRequestStatusEnum.AWAITING_PAYMENT,
    }

    await updateBalanceRequestStatus({
      id,
      data: uploadFormData,
    })

    if (data.attachments && data.attachments.length > 0) {
      forEach(data.attachments, async file => {
        const formData = new FormData()

        formData.append('balance_request_id', id)
        formData.append('type', BalanceRequestAttachmentTypeEnum.CONTRACT)
        formData.append('attachment', file)
        await uploadBalanceRequestAttachments({ data: formData })
      })
    }

    onClose()
  }
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateBalanceRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              Deseja realmente anexar o contrato?
            </Text>

            <Text color="gray.400" mt={2} mb={2}>
              Ao anexar o contrato o responsável receberá uma notificação para revisão.
            </Text>

            <DropzoneImageUpload
              name="attachments"
              setValue={setValue}
              displayMode="list"
              label="Arquivo CF"
              multiple
            />

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={isLoading || isLoadingUploadBalanceRequestAttachments}
              >
                <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
