/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  isLoading: boolean
  isNewImage: boolean
  crlvVehicleImg: string
  crlvTrailerImg: string
  crlvVehicleFile: File | null
  crlvTrailerFile: File | null
}

const initialState: State = {
  isLoading: false,
  isNewImage: false,
  crlvVehicleImg: '',
  crlvTrailerImg: '',
  crlvVehicleFile: null,
  crlvTrailerFile: null,
}

const ocrCrlvSlice = createSlice({
  name: 'ocrCrlv',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setIsNewImage(state, action: PayloadAction<boolean>) {
      state.isNewImage = action.payload
    },
    setCrlvVehicleImg(state, action: PayloadAction<string>) {
      state.crlvVehicleImg = action.payload
    },
    setCrlvTrailerImg(state, action: PayloadAction<string>) {
      state.crlvTrailerImg = action.payload
    },
    setCrlvVehicleFile(state, action: PayloadAction<File | null>) {
      state.crlvVehicleFile = action.payload
    },
    setCrlvTrailerFile(state, action: PayloadAction<File | null>) {
      state.crlvTrailerFile = action.payload
    },
  },
})

export const {
  setIsLoading,
  setIsNewImage,
  setCrlvVehicleImg,
  setCrlvVehicleFile,
  setCrlvTrailerImg,
  setCrlvTrailerFile,
} = ocrCrlvSlice.actions
export default ocrCrlvSlice.reducer
