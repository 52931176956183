import { Heading, Text } from '@chakra-ui/react'

type InfoDetailsProps = {
  label: string

  content: string
}
const InfoDetails = ({ label, content = '-' }: InfoDetailsProps): JSX.Element => {
  return (
    <>
      <Heading fontSize="sm" fontWeight="medium" color="gray.500">
        {label}
      </Heading>
      <Text fontWeight="medium" mt="1" fontSize="sm">
        {content}
      </Text>
    </>
  )
}

export default InfoDetails
