import { Button, Flex, Heading, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Logo } from '../../layout/Header/Logo'
import { useGetFreightTrackingClient } from '../../services/endpoints/freights'
import ClientCardInfos from './show/cards/client-page'

type CreateHashToCompareTypes = {
  freight_number?: number
  created_at?: string
}
export interface RequestParams {
  freight_id: string
}

function createHashToCompare({ freight_number, created_at }: CreateHashToCompareTypes): string {
  if (freight_number && created_at) {
    const freightCreatedAt = new Date(created_at)
    const calc = freight_number * (freightCreatedAt.getDay() + 1)

    return String(calc)
  }
  return '12345'
}
export function ClientPage(): JSX.Element {
  const bg = useColorModeValue('gray.100', 'gray.800')
  const [modalLogin, setModalLogin] = useState(true)
  const { freight_id } = useParams<RequestParams>()
  const [formData, setFormData] = useState({
    password: '',
  })
  const [errorAuthForm, setErrorAuthForm] = useState({
    password: '',
  })

  const { data: freightTrackingClient } = useGetFreightTrackingClient({
    freight_id,
    relations: [
      'origin',
      'destination',
      'vehicle',
      'vehicle.vehicle_category',
      'vehicle.vehicle_body',
      'motorist',
      'client',
    ],
  })

  const handleSignIn: SubmitHandler<{ password: string }> = async () => {
    try {
      const generateHash = createHashToCompare({
        created_at: freightTrackingClient?.created_at_default?.toString(),
        freight_number: freightTrackingClient?.freight_number,
      })

      if (formData.password === generateHash) {
        setModalLogin(false)
        return true
      }
      setErrorAuthForm({
        password: 'Senha incorreta.',
      })
      return false
    } catch (error) {
      return false
    }
  }

  const handleSubmitAuthForm = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!formData.password) {
      setErrorAuthForm({
        password: 'Por favor, informe a senha.',
      })
      return
    }

    const success = await handleSignIn(formData)

    if (success) {
      setModalLogin(false)
    }
  }

  const setValueAuthForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })

    setErrorAuthForm({
      password: '',
    })
  }

  return modalLogin && freightTrackingClient?.has_monitoring_buonny ? (
    <Flex direction="column" w="100%" h="100vh" align="center" justify="center" p="4">
      <Flex
        as="form"
        w="100%"
        maxW={360}
        bg={bg}
        shadow="md"
        p="8"
        borderRadius={8}
        direction="column"
        backgroundColor="white"
        onSubmit={handleSubmitAuthForm}
      >
        <Stack spacing="4" display="flex" align="center" justify="center">
          <Heading size="lg" w="100%" />
          <Logo />
          <Text fontSize="sm" textAlign="start" color="gray.500">
            Informe a senha de acesso
          </Text>
          <Input
            name="password"
            type="password"
            label="Senha"
            backgroundColor="#efefef"
            autoComplete="new-password"
            onChange={setValueAuthForm}
            value={formData.password}
            isInvalid={!!errorAuthForm.password}
          />
          {errorAuthForm.password && <Text color="red.500">{errorAuthForm.password}</Text>}
        </Stack>

        <Button type="submit" mt="6" colorScheme="orange" size="lg">
          Acessar
        </Button>
      </Flex>
    </Flex>
  ) : (
    <ClientCardInfos freight={freightTrackingClient} />
  )
}
