import { Heading, SimpleGrid, WrapItem, Flex, Tooltip, Link, Text } from '@chakra-ui/react'
import { LinkOrUnlinkOwner } from '../../../components/vehicles/LinkOrUnlinkOwner'
import { useGetOwner } from '../../../services/endpoints/owners/getOwner'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'

interface VehicleOwnerInfosProps {
  vehicle_id: string
  motorist_id?: string
}

export function VehicleOwnerInfos({ vehicle_id, motorist_id }: VehicleOwnerInfosProps): JSX.Element {
  const { data: vehicle } = useGetVehicle(vehicle_id)
  const { data: owner } = useGetOwner(String(vehicle?.owner_id))

  return (
    <Flex justifyContent="center" direction="column" flex={1} mt={4}>
      {vehicle_id && (
        <>
          {owner && (
            <Heading size="md" my="3">
              Proprietário
            </Heading>
          )}
          <SimpleGrid minChildWidth="200px" spacing="3">
            {owner && (
              <>
                <Tooltip label="Ir para pagina do proprietário" hasArrow>
                  <WrapItem alignItems="center" as={Link} href={`/owners/show/${owner.id}`}>
                    <Text fontWeight="bold" color="gray.400">
                      Nome:
                    </Text>
                    <Text fontWeight="medium" ml="1">
                      {owner.name}
                    </Text>
                  </WrapItem>
                </Tooltip>
                <WrapItem alignItems="center">
                  <Text fontWeight="bold" color="gray.400">
                    Telefone:
                  </Text>
                  <Text fontWeight="medium" ml="1">
                    {owner?.phone}
                  </Text>
                </WrapItem>
              </>
            )}
            <WrapItem>
              <LinkOrUnlinkOwner owner={owner} vehicle_id={vehicle_id} motorist_id={motorist_id} />
            </WrapItem>
          </SimpleGrid>
        </>
      )}
    </Flex>
  )
}
