import { CteIssuanceRequestStatusEnum } from '../types/EnumTypes'

type AnalysisStatusType =
  | 'awaiting_issuance_cte'
  | 'rejected'
  | 'cte_issued'
  | 'cte_return_to_motorist'
  | 'awaiting_advance_request'
  | 'finished'

export function getNextStatusCteIssuanceRequest(
  status: CteIssuanceRequestStatusEnum,
): AnalysisStatusType | null {
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ANALYSIS) return 'awaiting_issuance_cte'
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ISSUANCE_CTE) return 'cte_issued'
  if (status === CteIssuanceRequestStatusEnum.CTE_ISSUED) return 'cte_return_to_motorist'
  if (status === CteIssuanceRequestStatusEnum.CTE_RETURN_TO_MOTORIST) return 'awaiting_advance_request'
  if (status === CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_ONGOING) return 'finished'
  return null
}
