import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { ChangeEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import { BsArrowClockwise, BsCheck2 } from 'react-icons/bs'
import { GrDocumentPdf } from 'react-icons/gr'
import * as yup from 'yup'
import { Input } from '../../../../../../components/form/Input'
import { TextArea } from '../../../../../../components/form/TextArea'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { useGetNfes } from '../../../../../../services/endpoints/freights/nfes/getNfes'
import { usePutAttachNfesStub } from '../../../../../../services/endpoints/freights/putAttachNfesStub'
import { FreightStubsType } from '../../../../../../services/types'
import { validateDate } from '../../../../../../services/utils/dates/isValidDate'

type ViewStubModalProps = {
  isOpen: boolean
  onClose: () => void
  stub: FreightStubsType | null
}

type StringOrNumber = string | number

type AttachNfesStubParams = {
  occurrence?: string
  nfes: string[]
  receipt_date: Date
  status: 'approved' | 'dessaproved'
}

export function AttachStubNfesAttachment({ isOpen, onClose, stub }: ViewStubModalProps): JSX.Element {
  const [cteId, setCteId] = useState<string>('')
  const [nfesId, setNfesId] = useState<StringOrNumber[]>([])
  const [rotateImage, setRotateImage] = useState(0)
  const [getNfesOnlyWithoutCte, setGetNfesOnlyWithoutCte] = useState<boolean | undefined>(false)
  const [magnifyStyle, setMagnifyStyle] = useState({})
  const [imageZoomPercentage, setImageZoomPercentage] = useState(800)

  const receiptDateInitialValue = format(new Date(), 'yyyy-MM-dd')

  const updateStubFormSchema = yup.object().shape({
    receipt_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Informe uma data'),
  })

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(updateStubFormSchema),
  })

  const { errors } = formState

  const toast = useToast()

  const {
    mutateAsync: mutateAsyncAttachNfesStub,
    isLoading: isLoadingAttachNfesStub,
    error: errorAttachNfesStub,
    isError: isErrorAttachNfesStub,
  } = usePutAttachNfesStub({
    onSuccess: () => {
      queryClient.invalidateQueries('stubs')
      queryClient.invalidateQueries('nfes')
      onClose()
    },
  })

  useEffect(() => {
    if (isErrorAttachNfesStub) {
      toastError({ toast, error: errorAttachNfesStub })
    }
  }, [isErrorAttachNfesStub, errorAttachNfesStub, toast])

  const handleUpdateStubs: SubmitHandler<AttachNfesStubParams> = async data => {
    if (!nfesId.length && data.status === 'approved') {
      toastError({ toast, error: 'Selecione pelo menos uma nota fiscal' })
      return
    }

    if (stub) {
      await mutateAsyncAttachNfesStub({
        nfes_id: nfesId,
        receipt_date: data.receipt_date,
        status: data.status,
        occurrence: data.occurrence,
        stub_id: stub.id,
      })

      setNfesId([])
    }
  }

  function handleNfesToAttach(event: ChangeEvent<HTMLInputElement>): void {
    const { value, checked } = event.target

    if (checked && value) {
      if (!nfesId.includes(value)) {
        setNfesId([...nfesId, value])
      }
    } else {
      setNfesId(nfesId.filter(item => item !== value))
    }
  }

  function handleRotateImage(): void {
    if (rotateImage === 270) {
      setRotateImage(0)
    } else {
      setRotateImage(prevState => prevState + 90)
    }
  }

  const { data: nfes } = useGetNfes({
    freight_id: stub?.freight_id,
    cte_id: cteId,
    only_not_attached: true,
    only_without_cte: getNfesOnlyWithoutCte,
    unpaged: true,
  })

  useEffect(() => {
    if (stub?.freight?.ctes && stub.freight.ctes.length > 0) {
      setCteId(stub.freight.ctes[0].id)
    }

    if (stub && stub.freight && stub.freight.ctes && stub.freight.ctes?.length === 0) {
      setGetNfesOnlyWithoutCte(true)
    }
  }, [stub])

  const handleMouseMove = (e: any) => {
    const { offsetX, offsetY } = e.nativeEvent
    const { offsetWidth, offsetHeight } = e.nativeEvent.target

    const xPercentage = (offsetX / offsetWidth) * 100
    const yPercentage = (offsetY / offsetHeight) * 100

    setMagnifyStyle(prev => ({
      ...prev,
      backgroundImage: `url('${stub?.attachment_file_url}')`,
      display: 'block',
      top: `${offsetY - 100}px`,
      left: `${offsetX - 100}px`,
      backgroundPosition: `${xPercentage}% ${yPercentage}%`,
      rotate: `${rotateImage}deg`,
    }))
  }

  const handleMouseLeave = () => {
    setMagnifyStyle({
      display: 'none',
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />

        <form onSubmit={handleSubmit(handleUpdateStubs)} noValidate>
          <ModalBody mt={2}>
            <Flex w="100%">
              <Flex w="30%" direction="column">
                <Box position="relative" cursor="none">
                  <Image
                    src={stub?.attachment_file_url}
                    w="100%"
                    maxH="500px"
                    borderRadius={5}
                    style={{ rotate: `${rotateImage}deg` }}
                    draggable={false}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onWheel={e => {
                      const { deltaY } = e
                      if (deltaY < 0 && imageZoomPercentage <= 1500) {
                        setImageZoomPercentage(prevState => prevState + 100)
                      } else if (deltaY > 0 && imageZoomPercentage >= 300) {
                        setImageZoomPercentage(prevState => prevState - 100)
                      }
                    }}
                  />
                  <Box
                    width="200px"
                    height="200px"
                    backgroundSize={`${imageZoomPercentage}%`}
                    backgroundPosition="center"
                    position="absolute"
                    backgroundRepeat="no-repeat"
                    style={magnifyStyle}
                    pointerEvents="none"
                    border="2px solid #000000"
                    borderRadius="100%"
                    zIndex="9999"
                    display="none"
                  />
                </Box>

                <Flex justify="flex-end" mt={2}>
                  <Button size="xs" colorScheme="orange" onClick={handleRotateImage}>
                    <Icon as={BsArrowClockwise} fontSize="18" />
                  </Button>
                </Flex>
              </Flex>

              <Box w="70%">
                <Grid templateColumns="repeat(12, 1fr)" gap="4" pl="6">
                  <GridItem colSpan={[12, 5]} maxH="300px" overflowY="auto">
                    <Text fontSize="18">Número de CTe*</Text>

                    {stub && stub.freight && stub.freight.ctes && stub.freight.ctes?.length > 0 ? (
                      <RadioGroup onChange={setCteId} mt="4" value={cteId} mr={2} ml={1}>
                        <Stack direction="column">
                          {stub.freight.ctes?.map(cte => (
                            <Flex justifyContent="space-between" alignItems="center" w="100%" mb="2">
                              <Radio value={cte.id}>{cte.nct}</Radio>
                              <Button
                                size="xs"
                                colorScheme="cyan"
                                // as={Link}
                                // href={cte.xml_file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon as={GrDocumentPdf} fontSize="18" />
                              </Button>
                            </Flex>
                          ))}
                        </Stack>
                      </RadioGroup>
                    ) : (
                      <Text>Nenhuma CTe encontrado.</Text>
                    )}
                  </GridItem>

                  <GridItem colSpan={[12, 7]} maxH="300px" overflowY="auto" pl={1}>
                    <Text fontSize="18">Nota fiscal (NFe)*</Text>
                    {nfes && nfes.data && nfes.data.length > 0 ? (
                      nfes.data.map(nfe => (
                        <CheckboxGroup>
                          <Checkbox
                            marginRight={7}
                            marginBottom={2}
                            marginTop={2}
                            value={nfe.id}
                            onChange={handleNfesToAttach}
                          >
                            {nfe.number}
                          </Checkbox>
                        </CheckboxGroup>
                      ))
                    ) : (
                      <Text>Nenhuma nota fiscal encontrada</Text>
                    )}
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Divider />
                  </GridItem>

                  <GridItem colSpan={[12, 5]} overflowY="auto">
                    <Text>
                      <Input
                        name="receipt_date"
                        type="date"
                        initialValue={receiptDateInitialValue}
                        setValue={setValue}
                        error={errors.receipt_date}
                        label="Data de envio"
                        isRequired
                      />
                    </Text>
                  </GridItem>
                  <GridItem colSpan={[12, 7]} overflowY="auto">
                    <TextArea
                      minH={120}
                      name="occurrence"
                      setValue={setValue}
                      label="Ocorrência"
                      placeholder="Escreva uma ocorrência"
                    />
                  </GridItem>
                </Grid>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>

            <Button
              type="submit"
              colorScheme="red"
              leftIcon={<Icon as={AiOutlineClose} />}
              onClick={() => setValue('status', 'disapproved')}
              mr={2}
              isLoading={isLoadingAttachNfesStub}
            >
              Reprovar canhoto
            </Button>

            <Button
              type="submit"
              colorScheme="green"
              leftIcon={<Icon as={BsCheck2} />}
              onClick={() => setValue('status', 'approved')}
              isLoading={isLoadingAttachNfesStub}
            >
              Aprovar canhoto
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
