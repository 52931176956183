import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'

interface ICheckCostRiskAnalysisParams {
  risk_analysis_id?: string
  freight_id?: string
}

interface ICheckCostRiskAnalysisType {
  cost_risk_analysis_created_at: Date
  cost_risk_analysis_freight_id: string
  cost_risk_analysis_id: string
  cost_risk_analysis_motorist_id: string
  cost_risk_analysis_paid_out: boolean
  cost_risk_analysis_risk_analysis_id: string
  cost_risk_analysis_risk_manager: string
  cost_risk_analysis_updated_at: Date
  cost_risk_analysis_value: number
}

export type ICostRiskAnalysisResponse = ICheckCostRiskAnalysisType[]

async function checkCostRiskAnalysis(
  params: ICheckCostRiskAnalysisParams,
): Promise<ICostRiskAnalysisResponse> {
  const { data: costRiskAnalysis } = await apiServer.get(`/list-cost-risk-analysis/`, {
    params: {
      risk_analysis_id: params.risk_analysis_id,
      freight_id: params.freight_id,
    },
  })

  return costRiskAnalysis.data
}

export function useCheckCostRiskAnalysis(
  params: ICheckCostRiskAnalysisParams,
): UseQueryResult<ICostRiskAnalysisResponse> {
  return useQuery(['check-cost-risk-analysis', params], () => checkCostRiskAnalysis(params), {
    staleTime: 1000 * 60 * 60, // 60 minutes,
    retry: false,
  })
}
