import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { ResponseApiType } from '../../types'
import { apiServer } from '../../api'

export type InputPredictAgreedPrice = {
  freight_id: string
}

type OutputPredictAgreedPrice = {
  prediction: string
}

async function predictAgreedPrice(
  data: InputPredictAgreedPrice,
): Promise<ResponseApiType<OutputPredictAgreedPrice>> {
  const response = await apiServer.post<ResponseApiType<OutputPredictAgreedPrice>>(
    '/predict-agreed-price',
    data,
  )

  return response.data
}

export function usePostPredictAgreedPrice(
  options?: UseMutationOptions<
    ResponseApiType<OutputPredictAgreedPrice>,
    Error,
    InputPredictAgreedPrice,
    unknown
  >,
): UseMutationResult<ResponseApiType<OutputPredictAgreedPrice>, Error, InputPredictAgreedPrice, unknown> {
  return useMutation(async (data: InputPredictAgreedPrice) => predictAgreedPrice(data), options)
}
