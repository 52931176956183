import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export interface ErrorFeedBackProps {
  message: string
}
const ErrorFeedBack = ({ message }: ErrorFeedBackProps): JSX.Element => {
  return (
    <Flex direction="column" gridGap={2} py="8">
      <Text fontWeight="bold" align="center">
        {message}
      </Text>
    </Flex>
  )
}

export default ErrorFeedBack
