import { useEffect, useState } from 'react'
import { Icon, Text } from '@chakra-ui/react'
import { RiTimerFlashLine } from 'react-icons/ri'

interface TimerProps {
  date: Date
  refreshBy?: 'seconds' | 'minutes'
}

interface Time {
  days?: string
  hours?: string
  minutes?: string
  seconds?: string
}

const TimerComponent = ({ date, refreshBy = 'seconds' }: TimerProps): JSX.Element => {
  const dateTimestamp = date.getTime()
  const interval = refreshBy === 'seconds' ? 1000 : 1000 * 60

  const [time, setTime] = useState<Time>(() => {
    const nowTimestamp = new Date().getTime()

    const remainingAllSeconds = Math.round((dateTimestamp - nowTimestamp) / 1000)
    const remainingAllMinutes = Math.floor(remainingAllSeconds / 60)
    const remainingAllHours = Math.floor(remainingAllMinutes / 60)
    const days = Math.floor(remainingAllHours / 24)

    const secondsNumber = remainingAllSeconds % 60
    const minutesNumber = remainingAllMinutes % 60
    const hoursNumber = remainingAllHours % 24

    let seconds = String(secondsNumber)
    let minutes = String(minutesNumber)
    let hours = String(hoursNumber)

    if (hoursNumber < 10) hours = `0${hours}`
    if (minutesNumber < 10) minutes = `0${minutes}`
    if (secondsNumber < 10) seconds = `0${seconds}`

    return {
      days: days >= 1 ? `${days} dia${days > 1 ? 's' : ''}` : undefined,
      hours,
      minutes,
      seconds,
    }
  })

  useEffect(() => {
    setInterval(() => {
      const nowTimestamp = new Date().getTime()

      const remainingAllSeconds = Math.round((dateTimestamp - nowTimestamp) / 1000)
      const remainingAllMinutes = Math.floor(remainingAllSeconds / 60)
      const remainingAllHours = Math.floor(remainingAllMinutes / 60)
      const days = Math.floor(remainingAllHours / 24)

      const secondsNumber = remainingAllSeconds % 60
      const minutesNumber = remainingAllMinutes % 60
      const hoursNumber = remainingAllHours % 24

      let seconds = String(secondsNumber)
      let minutes = String(minutesNumber)
      let hours = String(hoursNumber)

      if (hoursNumber < 10) hours = `0${hours}`
      if (minutesNumber < 10) minutes = `0${minutes}`
      if (secondsNumber < 10) seconds = `0${seconds}`

      setTime({
        days: days >= 1 ? `${days} dia${days > 1 ? 's' : ''}` : undefined,
        hours,
        minutes,
        seconds,
      })
    }, interval)
  }, [dateTimestamp, interval])

  return (
    <Text color="green.400" d="flex" alignItems="center">
      <Icon as={RiTimerFlashLine} fontSize="md" mr="1" />
      {time.days && (
        <Text as="span" mr="1">
          {time.days}
        </Text>
      )}
      <Text as="span">{time.hours}</Text>
      <Text as="span">:{time.minutes}</Text>
      {refreshBy === 'seconds' && <Text as="span">:{time.seconds}</Text>}
    </Text>
  )
}

export const Timer = TimerComponent
