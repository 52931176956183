import { Button, Grid, GridItem, HStack, Icon } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'
import { Autocomplete } from '../../../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { Select } from '../../../../components/form/Select'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { FreightType } from '../../../../services/types'
import { buonnyItemTypesOptions } from '../../../../services/utils/getBuonnyItemTypes'

interface CargoTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  freight: FreightType
  formState: FormState<FieldValues>
}

export function CargoTabPanel({ setValue, tabNav, freight, formState }: CargoTabPanelProps): JSX.Element {
  const { errors } = formState
  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 6]}>
          <Autocomplete
            name="buonny_cargo_type"
            label="Tipo de carga"
            setValue={setValue}
            options={buonnyItemTypesOptions}
            error={errors.buonny_cargo_type}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <Select
            name="buonny_cargo_price"
            label="Valor da carga"
            setValue={setValue}
            options={[
              { label: '', value: '' },
              { label: 'De R$ 0,01 a R$ 100.000,00', value: '50000' },
              { label: 'De R$ 100.000,01 a R$ 200.000,00', value: '150000' },
              { label: 'De R$ 200.000,01 a R$ 300.000,00', value: '250000' },
              { label: 'De R$ 300.000,01 a R$ 400.000,00', value: '350000' },
              { label: 'De R$ 400.000,01 a R$ 500.000,00', value: '450000' },
              { label: 'De R$ 500.000,01 a R$ 800.000,00', value: '550000' },
              { label: 'De R$ 800.000,01 a R$ 1.000.000,00', value: '900000' },
              {
                label: 'De R$ 1.000.000,01 a R$ 3.000.000,00',
                value: '2000000',
              },
              {
                label: 'De R$ 3.000.000,01 a R$ 9.999.999,00',
                value: '5000000',
              },
            ]}
            error={errors.buonny_cargo_price}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <AutocompleteAsync
            name="cargo_origin"
            label="Origem"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            initialValue={{
              label: freight.origin.name,
              value: freight.origin.state.uf,
            }}
            error={errors.cargo_origin}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <AutocompleteAsync
            name="cargo_destination"
            label="Destino"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            initialValue={{
              label: freight.destination.name,
              value: freight.destination.state.uf,
            }}
            error={errors.cargo_destination}
          />
        </GridItem>
      </Grid>
      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(5)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(7)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
