import { Box, Button, Divider, Flex, FormLabel, Heading, Icon, Link, Text, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaWhatsapp } from 'react-icons/fa'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { InputMask } from '../../../components/form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { Layout } from '../../../layout'
import {
  ISendRecoverLinkFormData,
  useSendRecoverLink,
} from '../../../services/endpoints/users/sendRecoverLink'

const sendRecoverLinkSchema = yup.object().shape({
  phone: yup.string().required('Telefone obrigatório'),
})

export function SendRecoverLink(): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendRecoverLinkSchema),
  })

  const { mutateAsync: sendRecoverLink, isLoading } = useSendRecoverLink({
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      toast({
        title: 'O link de recuperação foi enviado com sucesso',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleRecoverPassword: SubmitHandler<ISendRecoverLinkFormData> = async data => {
    await sendRecoverLink(data)
  }

  return (
    <Layout hasBackground showSidebar={false} showHeader={false}>
      <Flex direction="column" align="left" justify="center" p="4">
        <Flex direction="row" align="left" justify="left">
          <Button
            as={Link}
            leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}
            onClick={() => {
              history.push('/')
            }}
          >
            Voltar
          </Button>
          <Heading size="lg" fontWeight="normal" ml={4}>
            Recuperar senha
          </Heading>
        </Flex>
        <Divider my="4" />
        <Box
          as="form"
          w="100%"
          maxW="400px"
          p="8"
          borderRadius="8px"
          shadow="md"
          onSubmit={handleSubmit(handleRecoverPassword)}
          noValidate
        >
          <Flex direction="column" align="left" justify="center">
            <FormLabel htmlFor="phone" mt="4">
              Digite seu número de telefone:
            </FormLabel>
            <InputMask
              mask="(99) 9 9999-9999"
              registerOnlyNumbers
              id="phone"
              name="phone"
              type="tel"
              placeholder="Telefone"
              isInvalid={!!errors.phone}
              errorBorderColor="red.300"
              setValue={setValue}
            />
            {errors.phone && (
              <Text mt="4" color="red.300" fontSize="sm">
                {errors.phone.message}
              </Text>
            )}
          </Flex>

          <Flex mt="4" justify="left">
            <Button type="submit" colorScheme="blue" isLoading={isLoading} leftIcon={<FaWhatsapp />}>
              Enviar Link de Recuperação
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  )
}
