import * as yup from 'yup'
import { validateDate } from '../../../services/utils/dates/isValidDate'

export const freightAndQuotationValidateSchema = yup.object().shape({
  // VALIDATION COMMON BETWEEN QUOTATIONS AND FREIGHTS
  isQuotation: yup.boolean(),
  sector: yup.string().required('Campo obrigatório'),
  client_ref_type: yup.string(),
  client_ref_number: yup.string().max(20, 'Máximo 20 caracteres'),
  type: yup.string(),
  client_contact: yup.object().shape({
    switch_add_contact: yup.boolean(),
    client_contact_id: yup
      .object()
      .shape({})
      .when('switch_add_contact', {
        is: false,
        then: yup.object().shape({}).required('Campo obrigatório'),
      }),
    name: yup.string().when('switch_add_contact', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    phone: yup.string().when('switch_add_contact', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    email: yup.string().when('switch_add_contact', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    role: yup.string().when('switch_add_contact', {
      is: true,
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
  }),
  motorist_exists: yup.number(),
  creator_id: yup.object().shape({}).required('Campo obrigatório'),
  seller_id: yup.object().shape({}).required('Campo obrigatório'),
  client_id: yup.object().required('Campo obrigatório'),
  received_at: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
  link_motorist_at: yup.date(),
  collect_cargo_at: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
  delivered_cargo_at: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
  origin_id: yup.object().required('Campo obrigatório'),
  destination_id: yup.object().required('Campo obrigatório'),
  vehicle_categories: yup
    .array()
    .max(4, 'Só é permitido no máximo 4 tipos de veículo')
    .of(yup.object())
    .required('Campo obrigatório'),
  vehicle_bodies: yup
    .array()
    .max(4, 'Só é permitido no máximo 4 tipos de carrocerias')
    .of(yup.object())
    .required('Campo obrigatório'),
  suggested_price_type: yup.string().when('publish_on_fretebras', {
    is: true,
    then: yup.string().required('Campo obrigatório'),
  }),
  agreed_price: yup.number().min(1, 'Valor não pode ser zero'),
  has_toll: yup.boolean().when('publish_on_fretebras', {
    is: true,
    then: yup.boolean().required('Campo obrigatório'),
  }),
  description: yup.string().required('Campo obrigatório'),
  publish_on_fretebras: yup.boolean(),
  transporter: yup.string().required('Campo obrigatório'),
  required_items: yup.string(),
  suggested_price: yup.number().when(['suggested_price_type', 'publish_on_fretebras'], {
    is: (suggested_price_type: string, publish_on_fretebras: boolean) => {
      return suggested_price_type !== 'to_match' && publish_on_fretebras
    },
    then: yup.number().min(1, 'Não pode ser zero').required('Campo obrigatório'),
  }),

  // VALIDATION CONDITIONAL BETWEEN QUOTATIONS AND FREIGHTS
  address_collect: yup.string().when(['isQuotation'], {
    is: (isQuotation: boolean) => {
      return !isQuotation
    },
    then: yup.string().required('Campo obrigatório'),
  }),
  address_delivery: yup.string().when(['isQuotation'], {
    is: (isQuotation: boolean) => {
      return !isQuotation
    },
    then: yup.string().required('Campo obrigatório'),
  }),
  cargo: yup.object().when(['isQuotation'], {
    is: (isQuotation: boolean) => {
      return isQuotation
    },
    then: yup.object().shape({
      name: yup
        .string()
        .matches(/^[a-zA-Zà-ü,À-Ü ]+$/u, 'Caracteres não permitidos: números e pontuação')
        .required('Campo obrigatório')
        .max(30, 'Só é permitido no máximo 30 caracteres'),
      cargo_category_fretebras_id: yup.string().required('Campo obrigatório'),
      width: yup.number(),
      height: yup.number(),
      depth: yup.number(),
      weight: yup.number().required('Campo obrigatório'),
      value: yup.number().required('Campo obrigatório'),
      require_tracker: yup.boolean().required('Campo obrigatório'),
      has_complement: yup.boolean().required('Campo obrigatório'),
    }),
    otherwise: yup.object().shape({
      name: yup
        .string()
        .matches(/^[a-zA-Zà-ü,À-Ü ]+$/u, 'Caracteres não permitidos: números e pontuação')
        .required('Campo obrigatório')
        .max(30, 'Só é permitido no máximo 30 caracteres'),
      cargo_category_fretebras_id: yup.string().required('Campo obrigatório'),
      width: yup.number(),
      height: yup.number(),
      depth: yup.number(),
      weight: yup.number(),
      value: yup.number().required('Campo obrigatório'),
      require_tracker: yup.boolean().required('Campo obrigatório'),
      has_complement: yup.boolean().required('Campo obrigatório'),
    }),
  }),
  service_price: yup.number().when(['isQuotation'], {
    is: (isQuotation: boolean) => {
      return isQuotation
    },
    then: yup.number().min(1, 'Valor não pode ser zero'),
    otherwise: yup.number().min(1, 'Valor não pode ser zero').required('Campo obrigatório'),
  }),
})
