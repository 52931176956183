import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightStubsType, ResponseApiType } from '../../types'
import { CTEType } from '../../types/CteType'

export type StubStatusType = 'pending' | 'approved' | 'disapproved'

type GetStubsProps = {
  freight_id: string
  status?: StubStatusType | StubStatusType[]
}

export function formatStubStatus(status: StubStatusType): string {
  const stubStatus = {
    pending: 'Pendente',
    approved: 'Aprovado',
    disapproved: 'Reprovado',
  }

  return stubStatus[status]
}

async function fetchStubs(params: GetStubsProps): Promise<FreightStubsType[]> {
  const { data: response } = await apiServer.get<ResponseApiType<FreightStubsType[]>>('stubs', {
    params: {
      ...params,
    },
  })

  const data = response.data.map(stub => {
    const ctes: CTEType[] = []

    stub.nfes?.forEach(nfe => {
      const alreadyExistsCte = ctes.find(cte => cte.id === nfe.cte?.id)
      if (nfe.cte && !alreadyExistsCte) ctes.push(nfe.cte)
    })

    return {
      ...stub,
      ctes,
      status_formatted: formatStubStatus(stub.status),
    }
  })

  return data
}

export function useGetStubs(params: GetStubsProps): UseQueryResult<FreightStubsType[]> {
  return useQuery(['stubs', params], () => fetchStubs(params), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  })
}
