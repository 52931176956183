/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQueryClient, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType, ResponseValidateMotoristType } from '../../../types'

type ResponseValidateMotoristParams = {
  licensePlate?: string
  cpf?: string
  cnpj?: string
  rntrc: string
}

async function postValidateMotorist(
  params: ResponseValidateMotoristParams,
): Promise<ResponseValidateMotoristType> {
  const { data: response } = await apiServer.post<ResponseApiType<ResponseValidateMotoristType>>(
    '/motorist/validate-motorist',
    params,
  )
  return response.data
}

export function usePostValidateMotorist(
  options?: UseMutationOptions<ResponseValidateMotoristType, Error, ResponseValidateMotoristParams, unknown>,
) {
  const queryClient = useQueryClient()

  return useMutation<ResponseValidateMotoristType, Error, ResponseValidateMotoristParams>(
    async params => {
      const localStorageKey = `motoristData_${params.cpf || ''}_${params.licensePlate || ''}_${
        params.cnpj || ''
      }_${params.rntrc}`

      const cachedData = localStorage.getItem(localStorageKey)

      if (cachedData) {
        return JSON.parse(cachedData) as ResponseValidateMotoristType
      }

      const data = await postValidateMotorist(params)
      if (data) {
        localStorage.setItem(localStorageKey, JSON.stringify(data))
      }
      return data
    },
    {
      ...options,
      onSuccess: data => {
        const localStorageKey = `motoristData_${data.cpf || ''}_${data.vehicle_license_plate || ''}_${
          data.cnpj || ''
        }_${data.rntrc}`
        localStorage.setItem(localStorageKey, JSON.stringify(data))
        queryClient.invalidateQueries('motoristData')
      },
    },
  )
}
