import { Flex, Heading, Spinner, useColorModeValue } from '@chakra-ui/react'
import { FreightMap } from '../../../../../components/map/FreightMap'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { useAppSelector } from '../../../../../store'

interface FreighMonitoringBuonnyProps {
  freight: FormattedOneFreight
}

const FreighMonitoringBuonny = ({ freight }: FreighMonitoringBuonnyProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const { isLoading } = useAppSelector(state => state.freightSlice)
  return (
    <>
      {!!freight.has_monitoring_buonny && freight.vehicle_id && (
        <Flex
          bg={bg}
          p="2"
          borderRadius="8"
          shadow="md"
          mb="8"
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Heading size="md" fontWeight="normal" mb="4">
            Monitoramento Buonny
          </Heading>
          {isLoading ? (
            <Spinner />
          ) : (
            <Flex w="100%" h="400px">
              <FreightMap freight_id={freight.id} type="buonny" />
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

export default FreighMonitoringBuonny
