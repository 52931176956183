import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  Td,
  Box,
  Flex,
  Tooltip,
  Heading,
  Spinner,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { RiStarLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import { useGlobalVariables } from '../../../contexts/GlobalVariables'
import { Layout } from '../../../layout'
import {
  useReportsFeedbacksMotorist,
  fetchReportsFeedbacksMotorist,
} from '../../../services/endpoints/motorists/reportsFeedbacksMotorist'

export function MotoristsFeedbacksReports(): JSX.Element {
  const bg = useColorModeValue('cyan', 'gray')
  const { initial_date, final_date, setInitialDate, setFinalDate } = useGlobalVariables()
  const [initialDateFeedbacksMotorist, setInitialDateFeedbacksMotorist] = useState<string>(() => {
    const today = new Date()
    const week = today.setDate(today.getDate() - 1)
    return format(week, 'yyyy-MM-dd')
  })
  const [finalDateFeedbacksMotorist, setFinalDateFeedbacksMotorist] = useState<string>(
    initialDateFeedbacksMotorist,
  )

  const { data: feedbackResponse, isLoading } = useReportsFeedbacksMotorist({
    initialDateFeedbacksMotorist,
    finalDateFeedbacksMotorist,
  })

  useEffect(() => {
    setInitialDateFeedbacksMotorist(initial_date)
    setFinalDateFeedbacksMotorist(final_date)
  }, [initial_date, final_date])

  return (
    <Layout
      initialDate={initial_date}
      finalDate={final_date}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      isFilters
      SidebarMobile
    >
      <Box>
        <Flex mb="2" gridGap="2" justifyContent="space-between" fontSize={['md', 'lg']}>
          <Heading size="lg">Relatório de feedbacks dos motoristas</Heading>
          {feedbackResponse && feedbackResponse.data.data.length > 0 && (
            <ReportToExcel
              isLoading={isLoading}
              documentTitle="Relatório de feedbacks dos motoristas"
              columnsTitle={['Número do frete', 'Motivo da nota', 'Recomendação']}
              onGenerateReport={async () => {
                const report = await fetchReportsFeedbacksMotorist({
                  initialDateFeedbacksMotorist,
                  finalDateFeedbacksMotorist,
                })
                return report.data.data.map(feedback => {
                  return [
                    feedback.freight.freight_number,
                    feedback.reason,
                    feedback.recommendation_score,
                  ] as string[]
                })
              }}
            />
          )}
        </Flex>

        <Table variant="striped" colorScheme={bg}>
          <Thead>
            <Tr>
              <Th fontSize="md" whiteSpace="nowrap">
                Número do frete
              </Th>
              <Th fontSize="md" whiteSpace="nowrap">
                Motivo da nota
              </Th>
              <Th fontSize="md" whiteSpace="nowrap">
                Recomendação
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Flex alignItems="center" justifyContent="center" my="10">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Tbody>
              {feedbackResponse &&
                feedbackResponse.data.data.length > 0 &&
                feedbackResponse.data.data.map(feedback => (
                  <Tr key={feedback.id}>
                    <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                      <Link to={`/freights/show/${feedback.freight.id}`}>
                        #{feedback.freight.freight_number}
                      </Link>
                    </Td>

                    <Td fontSize="sm" whiteSpace="nowrap" w="80%">
                      {feedback.reason && (
                        <Tooltip
                          hasArrow
                          label={feedback.reason}
                          color="gray.700"
                          bg="gray.300"
                          placement="top"
                        >
                          <Text>
                            {String(feedback.reason).substring(0, 130)}
                            {feedback.reason.length > 130 ? '...' : undefined}
                          </Text>
                        </Tooltip>
                      )}
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.recommendation_score !== 10
                          ? `\xa0\xa0${feedback.recommendation_score}`
                          : feedback.recommendation_score}
                        <Icon as={RiStarLine} fontSize={20} mx="1" />
                      </Text>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
      </Box>
    </Layout>
  )
}
