/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  index: number
}

const initialState: State = {
  index: 0,
}

const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    setIndex(state, action: PayloadAction<number>) {
      state.index = action.payload
    },
  },
})

export const { setIndex } = sideBarSlice.actions
export default sideBarSlice.reducer
