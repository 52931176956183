import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../../api'
import { ResponseApiType } from '../../../../types'
import { FreightExpenseHistoryType } from '../../../../types/FreightExpenseType'

export async function fetchFreightExpenseStatusHistory(id: string): Promise<FreightExpenseHistoryType> {
  const { data: res } = await apiServer.get<ResponseApiType<FreightExpenseHistoryType>>(
    `/find-freight-expenses-history/`,
    { params: { id } },
  )
  const { data } = res
  return {
    ...data,
    created_at: format(new Date(data.created_at), "dd/MM/yy 'às' HH:mm"),
  }
}

export function useGetOneFreightExpenseStatusHistory(id: string): UseQueryResult<FreightExpenseHistoryType> {
  return useQuery(['financial-title-status-history', id], () => fetchFreightExpenseStatusHistory(id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
