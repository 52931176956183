import { Icon as ChakraIcon } from '@chakra-ui/react'
import Leaflet from 'leaflet'
import ReactDomServer from 'react-dom/server'
import { IconType } from 'react-icons'
import { BsTruckFlatbed } from 'react-icons/bs'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaTruckFast } from 'react-icons/fa6'

type MakeLeafletIconProps = {
  Icon: IconType
  color?: string
  size?: number
  anchor?: [number, number]
}
export function makeLeafletIcon({ Icon, size = 24, color, anchor }: MakeLeafletIconProps): Leaflet.DivIcon {
  const iconAnchor = anchor ?? [Math.floor(size / 2), Math.floor(size / 2)]
  return Leaflet.divIcon({
    html: ReactDomServer.renderToString(<ChakraIcon as={Icon} fontSize={size} color={color} />),
    iconSize: [0, 0],
    iconAnchor,
  })
}

export const mapOriginIcon = makeLeafletIcon({ Icon: FaMapMarkerAlt, color: 'blue', anchor: [12, 24] })
export const mapDestinationIcon = makeLeafletIcon({
  Icon: FaMapMarkerAlt,
  color: '#FF4500',
  anchor: [12, 24],
})
export const mapCurrentPositionIcon = makeLeafletIcon({
  Icon: FaTruckFast,
  color: 'green',
})
export const mapFirstPositionIcon = makeLeafletIcon({
  Icon: BsTruckFlatbed,
  color: 'purple',
})
