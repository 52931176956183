/* eslint-disable func-names */
import * as yup from 'yup'
import { BlockedEntitiesType } from '../../../../services/types'
import OwnerType from '../../../../services/types/OwnerType'
import { validateDate } from '../../../../services/utils/dates/isValidDate'
import { validateCpf } from '../../../../services/utils/validateCpf'

type InputVerifyIfIsNewOwner = {
  type: string
  documentProvided: string
  cpfCurrent?: string
  cnpjCurrent?: string
  ownerFound?: OwnerType
}

type InputVerifyUnlockPermission = {
  blockedEntity?: BlockedEntitiesType
  role: string
}

export const verifyIfIsNewOwner = ({
  type,
  documentProvided,
  cpfCurrent,
  cnpjCurrent,
  ownerFound,
}: InputVerifyIfIsNewOwner): boolean => {
  const isNewOwnerPF = type === 'pf' && documentProvided !== cpfCurrent && !ownerFound
  const isNewOwnerPJ = type === 'pj' && documentProvided !== cnpjCurrent && !ownerFound
  return isNewOwnerPF || isNewOwnerPJ
}

export const verifyIfDataByMotorist = (ownerFound?: OwnerType): boolean => {
  const motorist = ownerFound as any
  if (motorist && Object.keys(motorist).find(key => key === 'motorist_category')) return true
  return false
}

export const verifyUnlockPermission = ({ blockedEntity, role }: InputVerifyUnlockPermission): boolean => {
  if (!blockedEntity) return false
  if (
    !['to_correct', 'disapproved', 'canceled'].includes(blockedEntity?.risk_analysis?.status) &&
    !['super', 'admin', 'supervisor', 'risk_analyst'].includes(role)
  ) {
    return true
  }
  return false
}

export const motoristFormValidation = yup.object().shape({
  cpf: yup
    .string()
    .test('validate-cpf', 'CPF inválido', value => {
      return validateCpf(value)
    })
    .required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  mother_name: yup.string().required('Campo obrigatório'),
  father_name: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Digite um email válido'),
  rg: yup.string().required('Campo obrigatório'),
  rg_uf: yup.string().required('Campo obrigatório'),
  rg_dispatch_date: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
  rg_dispatcher: yup.string().required('Campo obrigatório'),
  // birth_city: yup.mixed().required('Campo obrigatório'),
  birth: yup
    .date()
    .required('Campo obrigatório')
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate),
  birth_state: yup.string(),
  zipcode: yup
    .string()
    // .test('validate-cep', 'CEP inválido!', async value => {
    //   return validateCep(String(value));
    // })
    .required('Campo obrigatório'),
  address: yup.string().required('Campo obrigatório'),
  address_number: yup.number().required('Campo obrigatório').min(1, 'Não pode ser zero'),
  district: yup.string().required('Campo obrigatório'),
  address_city_id: yup.object().required('Campo obrigatório'),
  name_contacts_ref_person_1: yup.string().required('Campo obrigatório'),
  name_contacts_ref_person_2: yup.string().required('Campo obrigatório'),
  name_contacts_ref_professional_1: yup.string().required('Campo obrigatório'),
  name_contacts_ref_professional_2: yup.string().required('Campo obrigatório'),
  phone_ref_person_1: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  phone_ref_person_2: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  phone_ref_professional_1: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  phone_ref_professional_2: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  type_contacts_ref_person_1: yup.string().required('Campo obrigatório'),
  type_contacts_ref_person_2: yup.string().required('Campo obrigatório'),
  type_contacts_ref_professional_1: yup.string().required('Campo obrigatório'),
  type_contacts_ref_professional_2: yup.string().required('Campo obrigatório'),
  driver_license: yup.object().shape({
    register_number: yup
      .string()
      .min(11, 'Precisa conter 11 números')
      .max(11, 'Precisa conter 11 números')
      .required('Campo obrigatório'),
    category: yup.string().required('Campo obrigatório'),
    dispatcher: yup.string().required('Campo obrigatório'),
    dispatcher_uf: yup.string().required('Campo obrigatório'),
    dispatch_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Campo obrigatório'),
    first_dispatch_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Campo obrigatório'),
    validity: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Campo obrigatório'),
    protocol_number: yup.string().required('Campo obrigatório'),
    insurance_number: yup.string().required('Campo obrigatório'),
  }),
})

const currentYear = new Date().getFullYear()
export const vehicleFormValidation = yup.object().shape({
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  license_uf: yup.string().required('Campo obrigatório'),
  axes: yup.number().required('Campo obrigatório'),
  renavam: yup
    .string()
    .min(9, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .max(11, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .required('Campo obrigatório'),
  vehicle_category_id: yup.string().required('Campo obrigatório'),
  vehicle_body_id: yup.string().required('Campo obrigatório'),
  brand: yup.string(),
  model: yup.string(),
  release_year: yup
    .number()
    .max(currentYear, 'Não pode ser maior que o ano atual')
    .required('Campo obrigatório'),
  model_year: yup
    .number()
    .max(currentYear, 'Não pode ser maior que o ano atual')
    .required('Campo obrigatório'),
  chassi: yup.string(),
  antt: yup.string(),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  has_tracker: yup.boolean(),
  has_insurance: yup.boolean(),
  city_id: yup.object().shape({}),
})

export const trailerFormValidation = yup.object().shape({
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  license_uf: yup.string().required('Campo obrigatório'),
  axes: yup.number().required('Campo obrigatório'),
  renavam: yup
    .string()
    .min(9, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .max(11, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .required('Campo obrigatório'),
  vehicle_body_id: yup.string().required('Campo obrigatório'),
  brand: yup.string(),
  model: yup.string(),
  release_year: yup
    .string()
    .max(currentYear, 'Não pode ser maior que o ano atual')
    .required('Campo obrigatório'),
  model_year: yup
    .string()
    .max(currentYear, 'Não pode ser maior que o ano atual')
    .required('Campo obrigatório'),
  chassi: yup.string(),
  antt: yup.string(),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  has_tracker: yup.boolean(),
  has_insurance: yup.boolean(),
  city_id: yup.object().shape({}),
})

export const anttFormValidation = yup.object().shape({
  owner: yup.object().shape({
    type: yup.string().oneOf(['pf', 'pj'], 'Apenas pf ou pj').required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
    phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
    zipcode: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    document: yup.string().when('type', {
      is: 'pf',
      then: yup
        .string()
        .test('validate-cpf', 'CPF inválido', value => {
          return validateCpf(value)
        })
        .required('Campo obrigatório'),
      otherwise: yup.string().required('Campo obrigatório'),
    }),
    address_number: yup.number().required('Campo obrigatório').min(1, 'Não pode ser zero'),
    district: yup.string().required('Campo obrigatório'),
    mother_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    father_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_ie: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_uf: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    rg_dispatch_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    rg_dispatcher: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    birth: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    city_id: yup.object().required('Campo obrigatório'),
    email: yup.string().email('Digite um email válido'),
  }),
  pis: yup.string().when('owner.type', {
    is: 'pj',
    then: yup.string(),
    otherwise: yup.string().required('Campo obrigatório'),
  }),
  rntrc: yup.string().min(8, 'RNTRC incompleto').required('Campo obrigatório'),
  rntrc_type: yup.string().equals(['E', 'C', 'EQ', 'T']).required('Campo obrigatório'),
  bank: yup.object().required('Campo obrigatório'),
  account_type: yup.string().required('Campo obrigatório'),
  agency: yup.string().required('Campo obrigatório'),
  account: yup.string().required('Campo obrigatório'),
})

export const vehicleOwnerFormValidation = yup.object().shape({
  owner: yup.object().shape({
    type: yup.string().oneOf(['pf', 'pj'], 'Apenas pf ou pj').required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
    phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
    zipcode: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    document: yup.string().when('type', {
      is: 'pf',
      then: yup
        .string()
        .test('validate-cpf', 'CPF inválido', value => {
          return validateCpf(value)
        })
        .required('Campo obrigatório'),
      otherwise: yup.string().required('Campo obrigatório'),
    }),
    address_number: yup.number().required('Campo obrigatório').min(1, 'Não pode ser zero'),
    district: yup.string().required('Campo obrigatório'),
    mother_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    father_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_ie: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_uf: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    rg_dispatch_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    rg_dispatcher: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    birth: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    city_id: yup.object().required('Campo obrigatório'),
    email: yup.string().email('Digite um email válido'),
  }),
})

export const trailerOwnerFormValidation = yup.object().shape({
  owner: yup.object().shape({
    type: yup.string().oneOf(['pf', 'pj'], 'Apenas pf ou pj').required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
    phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
    zipcode: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    document: yup.string().when('type', {
      is: 'pf',
      then: yup
        .string()
        .test('validate-cpf', 'CPF inválido', value => {
          return validateCpf(value)
        })
        .required('Campo obrigatório'),
      otherwise: yup.string().required('Campo obrigatório'),
    }),
    address_number: yup.number().required('Campo obrigatório').min(1, 'Não pode ser zero'),
    district: yup.string().required('Campo obrigatório'),
    mother_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    father_name: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_ie: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),

    rg_uf: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    rg_dispatch_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    rg_dispatcher: yup.string().when('type', {
      is: 'pf',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string(),
    }),
    birth: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .when('type', {
        is: 'pf',
        then: yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório'),
        otherwise: yup.date(),
      }),
    city_id: yup.object().required('Campo obrigatório'),
    email: yup.string().email('Digite um email válido'),
  }),
})
