/* eslint-disable no-useless-constructor */
import { freightStatusInProgressOptions } from '../../services/endpoints/freights/getFreights'
import { FormattedFreightStatusHistory } from '../../services/endpoints/freights/getHistoryFreight'

export class FreightStatusHistoryHandler {
  constructor(
    private readonly iteratedStatus: string,
    private readonly freightStatusCurrent: string,
    private readonly listFreightStatusHistory?: FormattedFreightStatusHistory[],
  ) {}

  public getColorFreightStatusHistory(): string {
    const statusIndex = freightStatusInProgressOptions.findIndex(
      option => option.value === this.freightStatusCurrent,
    )
    if (statusIndex === -1) {
      return 'gray.400'
    }
    if (
      freightStatusInProgressOptions.findIndex(option => option.value === this.iteratedStatus) <= statusIndex
    ) {
      return 'green.500'
    }
    return 'gray.400'
  }

  public statusConcluded = (): boolean => {
    const statusConcluded = this.getColorFreightStatusHistory().includes('green')
    return statusConcluded
  }

  public getTooltipTitle = (): string => {
    const statusConcluded = this.statusConcluded()
    if (this.freightStatusCurrent === this.iteratedStatus) {
      return 'Esse é o status atual'
    }
    if (statusConcluded) {
      return 'Status concluído'
    }
    if (this.freightStatusCurrent === 'in_destination') {
      return 'Esperando por mais informações para finalizar o status da entrega'
    }
    return 'Ainda não chegou nesse status'
  }

  public getStatusDateForEachFreightPhase = (): string | undefined => {
    const collecting = this.listFreightStatusHistory?.find(history => history.status === 'collecting')
    const ready = this.listFreightStatusHistory?.find(history => history.status === 'ready')
    const onRoad = this.listFreightStatusHistory?.find(history => history.status === 'on_road')
    const inDestination = this.listFreightStatusHistory?.find(history => history.status === 'in_destination')
    const delivered = this.listFreightStatusHistory?.find(history => history.status === 'delivered')

    if (this.iteratedStatus === 'collecting') {
      return collecting?.formatted_status_date
    }
    if (this.iteratedStatus === 'ready') {
      return ready?.formatted_status_date
    }
    if (this.iteratedStatus === 'on_road') {
      return onRoad?.formatted_status_date
    }
    if (this.iteratedStatus === 'in_destination') {
      return inDestination?.formatted_status_date
    }
    if (this.iteratedStatus === 'delivered') {
      return delivered?.formatted_status_date
    }
    return undefined
  }
}
