import { Button, Grid, GridItem, HStack, Icon } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'
import { Select } from '../../../../components/form/Select'

interface CategoryTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  formState: FormState<FieldValues>
}

export function CategoryTabPanel({ setValue, tabNav, formState }: CategoryTabPanelProps): JSX.Element {
  const { errors } = formState

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 6]}>
          <Select
            name="category"
            label="Categoria"
            setValue={setValue}
            options={[
              { label: 'Carreteiro', value: '1' },
              { label: 'Agregado', value: '2' },
              { label: 'Funcionário / Motorista', value: '3' },
              { label: 'Proprietário', value: '4' },
              { label: 'Funcionário', value: '5' },
              { label: 'Ajudante', value: '6' },
              { label: 'Conferente', value: '7' },
              { label: 'Buonny RH', value: '8' },
              { label: 'Prestador de Serviços', value: '9' },
              { label: 'Vigilante de Escolta Armado', value: '10' },
            ]}
            error={errors.category}
            initialValue="1"
          />
        </GridItem>
      </Grid>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(0)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(2)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
