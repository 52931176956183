import { Box } from '@chakra-ui/react'
import { addMonths, endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import { startOfYear } from 'date-fns/esm'
import { ptBR } from 'date-fns/locale'
import React, { useCallback, useRef, useState } from 'react'
import StateHandlerComponent from '../../components/UI/StateHandlerComponent'
import { Layout } from '../../layout'
import { GetMetricsResponse, useGetMetrics } from '../../services/endpoints/metrics/getMetrics'
import Header from './header'
import List from './list'

interface Filters {
  initial_date: Date
  final_date: Date
}

const Metrics = (): JSX.Element => {
  const todayRef = new Date()
  const [filters, setFilters] = React.useState<Filters>({
    initial_date: startOfMonth(todayRef),
    final_date: endOfMonth(todayRef),
  })

  const handleResetFilters = () => {
    setFilters({ initial_date: startOfMonth(todayRef), final_date: endOfMonth(todayRef) })
  }

  const goToPreviousMonth = () => {
    setFilters(prevFilters => {
      const newInitialDate = startOfMonth(subMonths(prevFilters.initial_date, 1))
      const newFinalDate = endOfMonth(newInitialDate)
      return {
        initial_date: newInitialDate,
        final_date: newFinalDate,
      }
    })
  }

  const goToNextMonth = () => {
    setFilters(prevFilters => {
      const newInitialDate = startOfMonth(addMonths(prevFilters.initial_date, 1))
      const newFinalDate = endOfMonth(newInitialDate)
      return {
        initial_date: newInitialDate,
        final_date: newFinalDate,
      }
    })
  }

  const handleSetManualPeriod = (initialDate: Date, finalDate: Date) => {
    setFilters({ initial_date: initialDate, final_date: finalDate })
  }

  const {
    data: atualMonthData,
    isLoading: isLoadingAtualMonthData,
    isError: isErrorAtualMonthData,
    isSuccess: isSuccessAtualMonthData,
  } = useGetMetrics({
    initial_date: `${format(filters.initial_date, 'yyyy-MM-dd')}T00:00:00`,
    final_date: `${format(filters.final_date, 'yyyy-MM-dd')}T23:59:59`,
  })

  const {
    data: subMonthsData,
    isLoading: isLoadingSubMonthsData,
    isError: isErrorSubMonthsData,
    isSuccess: isSuccessSubMonthsData,
  } = useGetMetrics({
    initial_date: `${format(subMonths(filters.initial_date, 1), 'yyyy-MM-dd')}T00:00:00`,
    final_date: `${format(subMonths(filters.final_date, 1), 'yyyy-MM-dd')}T23:59:59`,
  })

  const {
    data: yearData,
    isLoading: isLoadingYearDataData,
    isError: isErrorYearDataData,
    isSuccess: isSuccessYearDataData,
  } = useGetMetrics({
    initial_date: `${format(startOfYear(todayRef), 'yyyy-MM-dd')}T00:00:00`,
    final_date: `${format(todayRef, 'yyyy-MM-dd')}T23:59:59`,
  })

  const fallbackData = {
    metrics: {
      deliveryPunctuality: '0',
      delayRate: '0',
      transportCycleTime: '0',
      costPerKilometer: '0',
      thirdPartyServiceQuality: '0',
      customerSatisfaction: '0',
      collectionPunctuality: '0',
      slaAdherence: '0',
      clientFeedbackRate: '0',
      incidentRate: '0',
    },
    total: 0,
  } as GetMetricsResponse

  const [controls, setcontrols] = useState({
    tvMode: false,
  })

  const divRef = useRef<any>(null)

  const handleFullScreen = useCallback(() => {
    if (!divRef.current) return

    if (controls.tvMode) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if ((document as any).mozCancelFullScreen) {
        // Firefox
        ;(document as any).mozCancelFullScreen()
      } else if ((document as any).webkitExitFullscreen) {
        // Chrome, Safari and Opera
        ;(document as any).webkitExitFullscreen()
      } else if ((document as any).msExitFullscreen) {
        // IE/Edge
        ;(document as any).msExitFullscreen()
      }

      setcontrols(prev => ({ ...prev, tvMode: false }))
      return
    }

    setcontrols(prev => ({ ...prev, tvMode: true }))

    if (divRef.current.requestFullscreen) {
      divRef.current.requestFullscreen()
    } else if (divRef.current.mozRequestFullScreen) {
      // Firefox
      divRef.current.mozRequestFullScreen()
    } else if (divRef.current.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      divRef.current.webkitRequestFullscreen()
    } else if (divRef.current.msRequestFullscreen) {
      // IE/Edge
      divRef.current.msRequestFullscreen()
    }
  }, [controls])

  return (
    <Layout SidebarMobile>
      <Box ref={divRef} h="100vh" padding={controls.tvMode ? '4' : '0'}>
        <Header
          nextMonth={goToNextMonth}
          onResetPeriod={handleResetFilters}
          previousMonth={goToPreviousMonth}
          period={filters}
          onFullScreen={handleFullScreen}
          onManualPeriod={handleSetManualPeriod}
          disabledNextMonth={subMonths(filters.final_date, 0) > todayRef}
          month={format(filters.initial_date, 'MMMM yyyy', { locale: ptBR })}
        />
        <StateHandlerComponent
          loading={isLoadingAtualMonthData || isLoadingYearDataData || isLoadingSubMonthsData}
          error={isErrorAtualMonthData || isErrorYearDataData || isErrorSubMonthsData}
          hasData={isSuccessAtualMonthData || isSuccessYearDataData || isSuccessSubMonthsData}
        >
          <List
            monthMetrics={atualMonthData?.metrics || fallbackData.metrics}
            yearMetrics={yearData?.metrics || fallbackData.metrics}
            subMonth={subMonthsData?.metrics || fallbackData.metrics}
            filters={filters}
          />
        </StateHandlerComponent>
      </Box>
    </Layout>
  )
}

export default Metrics
