import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import parseCurrency from '../../utils/parseCurrency'
import {
  FiltersFreightsParams,
  FormattedAllFreights,
  getStatus,
  getStatusTabs,
} from '../freights/getFreights'

export type GetFreightsTollsResponse = {
  total: number
  data: (FormattedAllFreights & {
    toll_tag?: string // pode ser 'Sim' ou 'Não', 'Sim' significa que o veículo possui uma ou mais tags de pedágio
    toll_value?: number // encontrado em route_points
    value_vpo?: number // encontrado em mdfe com o nome toll_value
  })[]
}

export async function fetchAllFreightsTolls({
  origin_id,
  destination_id,
  is_no_show,
  freight_number,
  status,
  many_status,
  sector,
  page = 1,
  per_page = 10,
  initial_date,
  final_date,
  client_id,
  client_contact_id,
  user_id,
  seller_id,
  motorist_id,
  cte_number,
  place_vehicle,
  has_monitoring_app,
  has_monitoring_buonny,
  has_monitoring_whatsapp,
  has_monitoring_tracker_devices,
  onlyWithoutCtes,
  unpaged,
}: FiltersFreightsParams): Promise<GetFreightsTollsResponse> {
  let parsedStatus: string | string[] | undefined

  if (status && typeof status === 'string') {
    parsedStatus = getStatusTabs(status)
  }

  const { data } = await apiServer.get<ResponseListApiType<FormattedAllFreights[]>>('get-freights-tolls', {
    params: {
      origin_id,
      destination_id,
      is_no_show,
      freight_number,
      status: parsedStatus,
      many_status,
      sector,
      page,
      per_page,
      initial_date,
      final_date,
      client_id,
      client_contact_id,
      user_id,
      seller_id,
      motorist_id,
      cte_number,
      place_vehicle,
      has_monitoring_app,
      has_monitoring_buonny,
      has_monitoring_whatsapp,
      has_monitoring_tracker_devices,
      onlyWithoutCtes,
      unpaged,
    },
  })

  const { total } = data.data

  const freights = data.data.data.map(freight => {
    const createdAt = new Date(freight.created_at)

    let link_motorist_at_hour
    let collect_cargo_at_hour
    let deliver_cargo_at_hour
    let formatted_link_motorist_at
    let formatted_received_at
    let formatted_finished_at
    let collect_cargo_at_pt_br
    let input_collect_cargo_at
    let formatted_delivered_cargo_at
    let formatted_denied_at

    if (freight.finished_at) {
      const finishedAt = new Date(String(freight.finished_at))
      formatted_finished_at = format(finishedAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.denied_at) {
      const disabledAt = new Date(String(freight.denied_at))
      formatted_denied_at = format(disabledAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.link_motorist_at) {
      const linkMotoristAt = new Date(freight.link_motorist_at)
      formatted_link_motorist_at = format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss")
      link_motorist_at_hour = format(linkMotoristAt, 'HH:mm:ss')
    }

    if (freight.collect_cargo_at) {
      const collectItemAt = new Date(freight.collect_cargo_at)
      input_collect_cargo_at = format(collectItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      collect_cargo_at_pt_br = format(collectItemAt, 'dd/MM/yy HH:mm')
      collect_cargo_at_hour = format(collectItemAt, 'HH:mm:ss')
    }

    if (freight.delivered_cargo_at) {
      const deliverItemAt = new Date(freight.delivered_cargo_at)
      formatted_delivered_cargo_at = format(deliverItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      deliver_cargo_at_hour = format(deliverItemAt, 'HH:mm:ss')
    }

    if (freight.received_at) {
      const receivedItemAt = new Date(freight.received_at)
      formatted_received_at = format(receivedItemAt, "yyyy-MM-dd'T'HH:mm:ss")
    }

    return {
      ...freight,
      formatted_status: getStatus(freight.status),
      origin_city: `${freight.origin.name} - ${freight.origin.state.uf}`,
      destination_city: `${freight.destination.name} - ${freight.destination.state.uf}`,
      formatted_suggested_price: parseCurrency(freight.suggested_price),
      formatted_agreed_price: parseCurrency(freight.agreed_price),
      formatted_vehicle: `${freight.vehicle?.vehicle_category?.name} / ${freight.vehicle?.vehicle_body?.name}`,
      created_at: format(createdAt, 'dd/MM'),
      formatted_denied_at,
      created_at_hour: format(createdAt, 'HH:mm'),
      link_motorist_at_hour,
      collect_cargo_at_hour,
      deliver_cargo_at_hour,
      formatted_link_motorist_at,
      input_collect_cargo_at,
      collect_cargo_at_pt_br,
      formatted_delivered_cargo_at,
      formatted_received_at,
      formatted_finished_at,
    }
  })
  return { total, data: freights }
}

export function useGetFreightsTolls(params: FiltersFreightsParams): UseQueryResult<GetFreightsTollsResponse> {
  return useQuery(['/get-freights-tolls', params], () => fetchAllFreightsTolls(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
    keepPreviousData: true,
  })
}
