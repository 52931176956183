import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import { AiOutlineMobile } from 'react-icons/ai'
import { RiCheckboxCircleFill, RiComputerLine } from 'react-icons/ri'
import { FormattedFreightStatusHistory } from '../../../services/endpoints/freights/getHistoryFreight'
import { FormattedOneFreight } from '../../../services/endpoints/freights/getOneFreight'
import { EditStatusDateModal } from './modals/EditStatusDateModal'

interface DateStatusProps {
  freight: FormattedOneFreight | null
  history?: FormattedFreightStatusHistory
}

export function DateStatus({ freight, history }: DateStatusProps): JSX.Element | null {
  return history ? (
    <Text d="flex" color="green.500" alignItems="center" fontSize="xs">
      <Icon as={RiCheckboxCircleFill} mr="2" fontSize="md" />
      {history.formatted_status_date}

      {history.origin === 'app' ? (
        <Flex gridGap={2} alignItems="center">
          <Tooltip label="Atualizado via APP" hasArrow placement="auto">
            <Flex gridGap={2} alignItems="center">
              <Icon as={AiOutlineMobile} ml="2" fontSize="md" />
            </Flex>
          </Tooltip>
          <EditStatusDateModal history={history} />
        </Flex>
      ) : (
        <Flex gridGap={2} alignItems="center">
          <Tooltip label="Atualizado via TMS" hasArrow placement="auto">
            <Flex gridGap={2} alignItems="center">
              <Icon as={RiComputerLine} ml="2" fontSize="md" />
            </Flex>
          </Tooltip>
          <EditStatusDateModal history={history} />
        </Flex>
      )}
    </Text>
  ) : (
    <Text d="flex" color="green.500" alignItems="center" fontSize="sm">
      <Icon as={RiCheckboxCircleFill} mr="2" fontSize="md" />
      {freight?.updated_at && format(new Date(freight?.updated_at), "dd/MM 'às' HH:mm")}

      {freight && (
        <Flex gridGap={2} alignItems="center">
          <Tooltip label="Atualizado via TMS" hasArrow placement="auto">
            <Flex gridGap={2} alignItems="center">
              <Icon as={RiComputerLine} ml="2" fontSize="md" />
            </Flex>
          </Tooltip>

          <EditStatusDateModal
            history={{
              freight_id: freight?.id,
              origin: 'tms',
              status: freight?.status,
              status_date: new Date(freight?.updated_at),
              created_at: format(new Date(), 'yyyy-MM-ddTHH:mm:ss'),
              updated_at: new Date(),
            }}
          />
        </Flex>
      )}
    </Text>
  )
}
