import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiStarLine } from 'react-icons/ri'
import { RateStar } from '../../../../../components/form/RateStar'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { evaluateMotorist } from '../../../../../store/slices/motoristSlice'
import { toastError } from '../../../../../config/error/toastError'

interface EvaluateMotoristModalProps {
  isOpen: boolean
  onClose: () => void
}

export interface EvaluateMotoristData {
  behavior_score: number
  ponctuality_score: number
  reliability_score: number
  cargo_state_score: number
  freight_id: string
  motorist_id: string
}

const EvaluateMotoristFormSchema = yup.object().shape({
  behavior_score: yup.number().required('Campo obrigatório'),
  ponctuality_score: yup.number().required('Campo obrigatório'),
  reliability_score: yup.number().required('Campo obrigatório'),
  cargo_state_score: yup.number().required('Campo obrigatório'),
})

export function EvaluateMotoristModal({ isOpen, onClose }: EvaluateMotoristModalProps): JSX.Element | null {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { freight } = useAppSelector(state => state.freightSlice)
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(EvaluateMotoristFormSchema),
  })
  const { errors } = formState

  const handleFinishFreight: SubmitHandler<EvaluateMotoristData> = async data => {
    if (freight) {
      const { motorist_id } = freight
      const { ...rate } = data
      if (motorist_id) {
        const error = await dispatch(
          evaluateMotorist({
            ...rate,
            freight_id: freight.id,
            motorist_id,
          }),
        )

        if (!error) {
          onClose()
          toast({
            title: 'Motorista avaliado com sucesso!',
            status: 'success',
            position: 'top-right',
          })
        } else toastError({ toast, error })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFinishFreight)}>
          <ModalHeader>Avaliar o motorista?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid minChildWidth="250px" spacing={2}>
              <VStack spacing="6">
                <RateStar
                  isRequired
                  name="behavior_score"
                  label="Como você avalia o atendimento do motorista?"
                  setValue={setValue}
                  error={errors.behavior_score}
                  initialValue={freight?.rate?.behavior_score}
                />
                <RateStar
                  isRequired
                  name="ponctuality_score"
                  label="Como você avalia a pontualidade do motorista?"
                  setValue={setValue}
                  error={errors.ponctuality_score}
                  initialValue={freight?.rate?.ponctuality_score}
                />
                <RateStar
                  isRequired
                  name="reliability_score"
                  label="Como você avalia a confiabilidade do frete?"
                  setValue={setValue}
                  error={errors.reliability_score}
                  initialValue={freight?.rate?.reliability_score}
                />
                <RateStar
                  isRequired
                  name="cargo_state_score"
                  label="Como você avalia a situação da carga na entrega do frete?"
                  setValue={setValue}
                  error={errors.cargo_state_score}
                  initialValue={freight?.rate?.cargo_state_score}
                />
              </VStack>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiStarLine} />}
              isLoading={formState.isSubmitting}
            >
              Avaliar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
