import { Flex, Spinner, Alert, AlertIcon, Text } from '@chakra-ui/react'

interface IFreightsDisabledProps {
  freightsDisabled?: number
  isLoading: boolean
  isFetching: boolean
  error: unknown
}

export function FreightsDisabledCard({
  freightsDisabled,
  isLoading,
  isFetching,
  error,
}: IFreightsDisabledProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes Desativados. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        <Flex justifyContent="space-between" pl="4" pr="4" alignItems="baseline" fontWeight="bold">
          <Flex direction="row" gridGap="2" alignItems="center">
            <Text fontSize="lg" color="purple.400">
              DESATIVADOS
              {!isLoading && isFetching && <Spinner ml={2} />}
            </Text>
          </Flex>

          <Text fontSize="x-large" color="purple.400">
            {freightsDisabled && freightsDisabled}
          </Text>
        </Flex>
      )}
    </>
  )
}
