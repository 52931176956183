/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiArrowDownSLine, RiArrowUpSLine, RiEditLine, RiSearchLine, RiUserAddLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'

import { Input } from '../../../components/form/Input'
import { InputMask } from '../../../components/form/InputMask'
import { Select } from '../../../components/form/Select'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { useGetAllOwners } from '../../../services/endpoints/owners/getAllOwners'
import { validateCpf } from '../../../services/utils/validateCpf'
import { EditOwnerModal } from '../modals/EditOwnerModal'

interface FilterOwnersFormData {
  page?: number
  per_page?: number
  name?: string
  cpf?: string
  cnpj?: string
  phone?: string
  type?: number
}

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  per_page: yup.number(),
  name: yup.string(),
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  cnpj: yup.string(),
  phone: yup.string(),
  type: yup.string(),
})

export function ListOwner(): JSX.Element {
  const key = useRef(1)
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    name?: string
    page?: string
    cpf?: string
    cnpj?: string
    phone?: string
    type?: string
  }>()
  const bg = useColorModeValue('white', 'gray.800')
  const { isOpen, onOpen, onClose } = useDisclosure()

  // filters controlers
  const [filters, setFilters] = useState<FilterOwnersFormData>({
    page: 1,
  })

  // orders table
  const [orderByName, setOrderByName] = useState<'asc' | 'desc'>('asc')
  const [legalPerson, setLegalPerson] = useState('')
  const [ownerIdToUpdate, setOwnerIdToUpdate] = useState('')

  const {
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(filterFormSchema),
  })

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilterOwners: SubmitHandler<FilterOwnersFormData> = async data => {
    const dataFilter = {
      type: data.type,
      cnpj: data.cnpj,
      cpf: data.cpf,
      name: data.name,
      phone: data.phone,
    } as FilterOwnersFormData
    setFilters(prev => ({ ...prev, ...dataFilter, page: data.page || 1 }))

    updateQueryParams({
      type: data.type?.toString() || undefined,
      cnpj: data.cnpj || undefined,
      cpf: data.cpf || undefined,
      name: data.name || undefined,
      phone: data.phone || undefined,
      page: data.page?.toString() || '1',
    })
  }

  useEffect(() => {
    const nameQuery = getParams('name')
    const phoneQuery = getParams('phone')
    const cpfQuery = getParams('cpf')
    const cnpjQuery = getParams('cnpj')
    const typeQuery = getParams('type')
    const pageQuery = getParams('page')

    if (nameQuery || phoneQuery || cpfQuery || cnpjQuery || typeQuery || pageQuery) {
      setValue('name', nameQuery || undefined)
      setValue('phone', phoneQuery || undefined)
      setValue('cpf', cpfQuery || undefined)
      setValue('cnpj', cnpjQuery || undefined)
      setValue('type', typeQuery || undefined)
      setValue('page', pageQuery || undefined)
      handleSubmit(handleFilterOwners)()
    }
  }, [])

  const {
    data: owners,
    refetch,
    isLoading,
    error,
  } = useGetAllOwners({
    ...filters,
    page: filters?.page || 1,
    orderByName,
    per_page: 10,
  })

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>
        <Divider my="4" />
        <form onSubmit={handleSubmit(handleFilterOwners)} key={key.current} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[6, 4]}>
              <Select
                name="type"
                error={errors.type}
                setValue={setValue}
                onSelectOption={option => {
                  setLegalPerson(option.value)
                  setValue('type', option.value)
                }}
                value={getValues('type') || 'todos'}
                options={[
                  { label: 'Todos', value: '' },
                  { label: 'Pessoa física', value: 'pf' },
                  { label: 'Pessoa Jurídica', value: 'pj' },
                ]}
              />
            </GridItem>
            {legalPerson === 'pj' ? (
              <GridItem colSpan={[6, 8]}>
                <InputMask
                  name="cnpj"
                  placeholder="CNPJ"
                  mask="99.999.999/9999-99"
                  maskPlaceholder=""
                  initialValue={getValues('cnpj')}
                  defaultValue={getValues('cnpj')}
                  error={errors.cnpj}
                  setValue={setValue}
                  registerOnlyNumbers
                />
              </GridItem>
            ) : legalPerson === 'pf' ? (
              <GridItem colSpan={[6, 8]}>
                <InputMask
                  name="cpf"
                  placeholder="CPF"
                  mask="999.999.999-99"
                  initialValue={getValues('cpf')}
                  defaultValue={getValues('cpf')}
                  maskPlaceholder=""
                  error={errors.cpf}
                  setValue={setValue}
                  registerOnlyNumbers
                />
              </GridItem>
            ) : (
              <>
                <GridItem colSpan={[6, 4]}>
                  <InputMask
                    name="cnpj"
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    maskPlaceholder=""
                    initialValue={getValues('cnpj')}
                    defaultValue={getValues('cnpj')}
                    error={errors.cnpj}
                    setValue={setValue}
                    registerOnlyNumbers
                  />
                </GridItem>

                <GridItem colSpan={[6, 4]}>
                  <InputMask
                    name="cpf"
                    placeholder="CPF"
                    mask="999.999.999-99"
                    maskPlaceholder=""
                    initialValue={getValues('cpf')}
                    defaultValue={getValues('cpf')}
                    error={errors.cpf}
                    setValue={setValue}
                    registerOnlyNumbers
                  />
                </GridItem>
              </>
            )}

            <GridItem colSpan={[6, 6]}>
              <Input
                name="name"
                error={errors.name}
                setValue={setValue}
                initialValue={getValues('name')}
                defaultValue={getValues('name')}
                placeholder="Nome do proprietário"
              />
            </GridItem>

            <GridItem colSpan={[6, 6]}>
              <InputMask
                mask="(99) 9 9999-9999"
                initialValue={getValues('phone')}
                registerOnlyNumbers
                maskPlaceholder=""
                name="phone"
                error={errors.phone}
                setValue={setValue}
                placeholder="Telefone"
              />
            </GridItem>
          </Grid>
          <Flex mt="4" justify="flex-end" gridGap="2">
            <Button
              type="button"
              colorScheme="orange"
              variant="outline"
              onClick={() => {
                reset()
                key.current += 1
                handleSubmit(handleFilterOwners)()
              }}
            >
              limpar filtros
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Box>

      <Box bg={bg} p="6" borderRadius="8" shadow="md">
        <Flex mb="4" justify="space-between" direction={['column', 'row']} gridGap={2} align="center">
          <Heading size="md" fontWeight="normal">
            Proprietários
          </Heading>

          <Button
            as={Link}
            to="/owners/create"
            size="sm"
            colorScheme="orange"
            leftIcon={<Icon as={RiUserAddLine} />}
          >
            Cadastrar
          </Button>
        </Flex>
        <Divider my="4" />
        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : error ? (
          <Text>Deu erro</Text>
        ) : (
          <>
            <Table size="sm" d={['block', 'block', 'table']} overflowX="auto">
              <Thead>
                <Tr>
                  <Th>
                    <Flex gridGap={2} alignItems="center" color="blue.400">
                      <Box
                        onClick={() => {
                          setOrderByName('asc')
                          if (orderByName && orderByName.includes('asc')) {
                            setOrderByName('desc')
                          }
                        }}
                        cursor="pointer"
                      >
                        Proprietário
                      </Box>
                      {orderByName && (
                        <>
                          {orderByName.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                          {orderByName.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                        </>
                      )}
                    </Flex>
                  </Th>
                  <Th>RNTRC</Th>
                  <Th>Contato</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {owners &&
                  owners.data.map(owner => (
                    <Tr key={owner.id}>
                      <Td whiteSpace="nowrap">
                        <Box>
                          <Link to={`/owners/show/${owner.id}`}>{owner.name}</Link>
                        </Box>
                      </Td>
                      <Td whiteSpace="nowrap">
                        <Box>
                          <Text>{owner.rntrc}</Text>
                        </Box>
                      </Td>
                      <Td whiteSpace="nowrap">
                        <Box>
                          <Text>{owner.phone}</Text>
                          <Text fontSize="sm" color="gray.400">
                            {owner.email}
                          </Text>
                        </Box>
                      </Td>
                      <Td whiteSpace="nowrap" align="right">
                        <Tooltip label="Editar">
                          <Button
                            size="sm"
                            colorScheme="yellow"
                            onClick={() => {
                              onOpen()
                              setOwnerIdToUpdate(owner.id)
                            }}
                          >
                            <Icon as={RiEditLine} />
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </>
        )}

        <EditOwnerModal
          owner_id={ownerIdToUpdate}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            refetch()
          }}
        />

        {owners && (
          <Pagination
            currentPage={filters.page || 1}
            totalCountOfRegisters={owners.total}
            registersPerPage={10}
            onPageChange={handleChangePage}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
