import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { toastError } from '../../../../config/error/toastError'
import { useChangeFreightLinkFreightContract } from '../../../../services/endpoints/freight-contracts/changeFreightLinkFreightContract'

interface ChangeFreightLinkFreightContractModalProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

export function ChangeFreightLinkFreightContractModal({
  isOpen,
  onClose,
  id,
}: ChangeFreightLinkFreightContractModalProps): JSX.Element {
  const { mutateAsync: handleChangeFreight, isLoading: isLoadingChangeFreight } =
    useChangeFreightLinkFreightContract()

  const [freightNumber, setFreightNumber] = useState<string>()

  const toast = useToast()

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={onClose}
      onClose={() => {
        onClose()
        setFreightNumber(undefined)
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mudar o frete do contrato de frete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            type="number"
            placeholder="Digite o número do frete"
            onChange={e => setFreightNumber(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoadingChangeFreight}
            onClick={async () => {
              if (freightNumber) {
                try {
                  await handleChangeFreight({
                    id,
                    freight_number: freightNumber,
                  })
                  toast({
                    title: 'Frete vinculado com sucesso',
                    status: 'success',
                    position: 'top-right',
                  })
                  onClose()
                } catch (error) {
                  toastError({ toast, error })
                }
              }
            }}
            isDisabled={!freightNumber}
          >
            Vincular
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
