/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'
import BlackListType from '../../types/BlackListType'

type DeleteBlackList = Pick<BlackListType, 'cnpj' | 'cpf' | 'license_plate'>

async function deleteOfTheBlackList({ cnpj, cpf, license_plate }: Partial<DeleteBlackList>) {
  return apiServer.delete(`black-list/delete`, {
    params: { cnpj, cpf, license_plate },
  })
}

export function useDeleteOfTheBlackList(
  options?: UseMutationOptions<AxiosResponse, unknown, Partial<DeleteBlackList>, unknown>,
) {
  return useMutation(async (params: Partial<DeleteBlackList>) => deleteOfTheBlackList(params), options)
}
