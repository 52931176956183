import {
  Box,
  Button as ChakraUiButton,
  Flex,
  Heading,
  Icon,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'
import { RiHome4Line } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import logoDarkImg from '../assets/logo-dark.png'
import logoLightImg from '../assets/logo-light.png'

export function NotFound(): JSX.Element {
  const logoSrc = useColorModeValue(logoLightImg, logoDarkImg)

  return (
    <Flex direction="column" w="100%" h="100vh" align="center" justify="center" p="4">
      <Image src={logoSrc} alt="SpotX Logo" w={120} mb="8" />
      <Box w="100%" maxW={360} p="8" textAlign="center">
        <Heading fontSize="x-large">Página não encontrada</Heading>
        <ChakraUiButton
          as={Link}
          to="/"
          mt="8"
          variant="outline"
          colorScheme="orange"
          leftIcon={<Icon as={RiHome4Line} />}
        >
          Voltar para o início
        </ChakraUiButton>
      </Box>
    </Flex>
  )
}
