import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import { apiServer } from '../../../api'

export interface ICreateChecklistItem {
  content_label: string
  type: string
  comments?: string
  content_value?: string | boolean | undefined
  checklist_risk_analysis_id?: string
  checkType?: string
}

async function createChecklistRiskAnalysis(data: FormData) {
  return apiServer.post('/create-checklist-risk-analysis', data)
}

export function usePostChecklistRiskAnalysis(
  options: UseMutationOptions<AxiosResponse, unknown, FormData, unknown>,
): UseMutationResult<AxiosResponse, unknown, FormData, unknown> {
  return useMutation(async data => createChecklistRiskAnalysis(data), options)
}
