import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import freightPendenciesSlice from './slices/freightPendenciesSlice'
import plankCheckInsSlice from './slices/plankCheckInsSlice'
import freightSlice from './slices/freightSlice'
import motoristSlice from './slices/motoristSlice'
import vehicleSlice from './slices/vehicleSlice'
import ownerSlice from './slices/ownerSlice'
import sideBarSlice from './slices/sideBarSlice'
import ocrCnhSlice from './slices/ocrCnhSlice'
import ocrCrlvSlice from './slices/ocrCrlvSlice'
import riskAnalysisSlice from './slices/riskAnalysisSlice'

const reducers = combineReducers({
  freightSlice,
  sideBarSlice: persistReducer({ key: 'sideBar', storage }, sideBarSlice),
  ocrCnhSlice,
  vehicleSlice,
  ownerSlice,
  motoristSlice,
  freightPendenciesSlice,
  riskAnalysisSlice,
  plankCheckInsSlice,
  ocrCrlvSlice,
})

export default reducers
