/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiRestartLine, RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'

import { DateRange } from '../../../../components/DateRange'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { ReportToExcel } from '../../../../components/reports/ReportToExcel'
import { useGlobalVariables } from '../../../../contexts/GlobalVariables'
import { searchClientsByName } from '../../../../services/endpoints/clients/searchClients'

import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import {
  FilterClientMonthlyNpsData,
  fetchAllClientMonthlyNps,
  useGetAllClientMonthlyNps,
} from '../../../../services/endpoints/clients/client-monthly-nps/getAllClientMonthlyNps'
import { AllClientMonthlyNpsTableList } from './AllClientMonthlyNpsTableList'

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  per_page: yup.number(),
  client_id: yup.string(),
  initial_date: yup.date(),
  final_date: yup.date(),
})

interface Filters extends FilterClientMonthlyNpsData {
  client: AutocompleteOption
}

export function ListAllClientMonthlyNps(): JSX.Element {
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    user_label?: string
    client_id?: string
  }>()
  const { initial_date, final_date } = useGlobalVariables()
  const bg = useColorModeValue('white', 'gray.800')

  const [filters, setFilters] = useState<FilterClientMonthlyNpsData>()

  const {
    data: allClientMonthlyNps,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetAllClientMonthlyNps({
    initial_date: `${initial_date}T00:00:00`,
    final_date: `${final_date}T23:59:59`,
    page: filters?.page || 1,
    per_page: 8,
    ...filters,
  })

  const [clientMonthlyNps, setRiskAnalysis] = useState(allClientMonthlyNps)

  useEffect(() => {
    setRiskAnalysis(allClientMonthlyNps)
  }, [allClientMonthlyNps])

  const handlePageChange = (newPage: number) => {
    setFilters(prev => ({ ...prev, page: newPage }))
    addPage(newPage)
  }

  const { setValue, handleSubmit, getValues } = useForm({
    resolver: yupResolver(filterFormSchema),
  })

  const handleFilterFreightExpense: SubmitHandler<Filters> = async data => {
    const dataFilter = {
      client_id: data.client?.value,
    } as FilterClientMonthlyNpsData
    const { client_id } = dataFilter
    setFilters({
      client_id,
    })
    updateQueryParams({
      user_label: data.client?.label,
      client_id: data.client?.value as string,
    })
  }

  useEffect(() => {
    const userLabelQuery = getParams('user_label')
    const clientIdQuery = getParams('client_id')

    if (userLabelQuery || clientIdQuery) {
      setValue('client', {
        value: clientIdQuery,
        label: userLabelQuery,
      })
      handleSubmit(handleFilterFreightExpense)()
    }
  }, [])

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>
        <Divider my="4" />
        <form onSubmit={handleSubmit(handleFilterFreightExpense)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <DateRange />

            <GridItem colSpan={[12, 6]}>
              <AutocompleteAsync
                name="client"
                label="Filtrar por cliente"
                setValue={setValue}
                initialValue={getValues('client')}
                loadOptions={searchClientsByName}
                clearState={undefined}
              />
            </GridItem>
          </Grid>
          <Stack direction="row" mt={4} justify="flex-end">
            {clientMonthlyNps && clientMonthlyNps.data.length > 0 && (
              <ReportToExcel
                isLoading={isLoading}
                documentTitle="Relatório de NPS mensal de clientes"
                columnsTitle={['Data', 'Cliente', 'Solicitante', 'Nota', 'Motivo']}
                onGenerateReport={async () => {
                  const report = await fetchAllClientMonthlyNps({
                    initial_date: `${initial_date}T00:00:00`,
                    final_date: `${final_date}T23:59:59`,
                    unpaged: true,
                  })
                  return report.data.map(feedback => {
                    return [
                      feedback.created_at,
                      feedback.client.name,
                      feedback.client_contact?.name,
                      feedback.recommendation_score,
                      feedback.reason,
                    ] as string[]
                  })
                }}
              />
            )}

            <Box>
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                leftIcon={<Icon as={RiSearchLine} />}
                isLoading={isLoading}
              >
                Filtrar
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
      <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md">
        <Flex gridGap={4}>
          <Heading size="md" fontWeight="normal">
            Relatório de NPS mensal de clientes
          </Heading>
          <Heading size="md" cursor="pointer">
            {isFetching ? (
              <Spinner size="sm" />
            ) : (
              <RiRestartLine
                onClick={() => {
                  refetch()
                }}
              />
            )}
          </Heading>
        </Flex>
        <Divider my="4" />

        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : isError ? (
          <Text>Deu erro</Text>
        ) : (
          <AllClientMonthlyNpsTableList feedbacks={clientMonthlyNps} />
        )}

        {clientMonthlyNps && (
          <Pagination
            currentPage={filters?.page || 1}
            totalCountOfRegisters={clientMonthlyNps.total}
            registersPerPage={8}
            onPageChange={handlePageChange}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
