/* eslint-disable no-useless-return */
import {
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { ChangeEvent, useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineArrowUpTray } from 'react-icons/hi2'
import { RiAttachmentLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../../../components/form/Input'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { usePostStub } from '../../../../../../services/endpoints/freights/postStub'
import { validateDate } from '../../../../../../services/utils/dates/isValidDate'

type CreateStubsModalProps = {
  isOpen: boolean
  onClose: () => void
  freight_id: string
}

type CreateStubsProps = {
  send_date: Date
}

export function CreateStubsModal({ isOpen, onClose, freight_id }: CreateStubsModalProps): JSX.Element {
  const [stubs, setStubs] = useState<File[]>([])
  const [previewImages, setPreviewImages] = useState<string[]>([])

  const toast = useToast()

  const createStubFormSchema = yup.object().shape({
    send_date: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Informe uma data'),
  })

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(createStubFormSchema),
  })
  const { errors } = formState

  const sendDateInitialValue = format(new Date(), 'yyyy-MM-dd')

  const colorBorderFileArea = useColorModeValue('#A0AEC0', 'gray.900')
  const colorBackgroundFileArea = useColorModeValue('#F5F7F9', 'gray.700')
  const colorBackgroundCreateStubs = useColorModeValue('blue', 'orange')

  const {
    mutateAsync: mutateAsyncCreate,
    isLoading: isLoadingCreate,
    error: errorCreate,
    isError: isErrorCreate,
  } = usePostStub({
    onSuccess: () => {
      queryClient.invalidateQueries('stubs')
      queryClient.invalidateQueries('stubs_formatted')
    },
  })

  useEffect(() => {
    if (isErrorCreate) {
      toastError({ toast, error: errorCreate })
    }
  }, [isErrorCreate, errorCreate, toast])

  const handleCreateStubs: SubmitHandler<CreateStubsProps> = async data => {
    if (!stubs.length) {
      toastError({ toast, error: 'Selecione pelo menos um arquivo' })
      return
    }

    if (data && freight_id) {
      await mutateAsyncCreate({
        created_at: data.send_date,
        freight_id,
        attachments: stubs,
      })

      onClose()

      toast({
        title: 'Canhotos cadastrados com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 8000,
      })

      setStubs([])
      setPreviewImages([])
    }
  }

  function handleSelectImages(event: ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files) {
      return
    }

    const selectedImages = Array.from(event.target.files)

    setStubs(selectedImages)

    const selectedImagesPreview = selectedImages.map(image => {
      return URL.createObjectURL(image)
    })

    setPreviewImages(selectedImagesPreview)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar canhotos</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(handleCreateStubs)} noValidate>
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap="3" mb={4}>
              <GridItem colSpan={[12, 4]}>
                <Input
                  name="send_date"
                  setValue={setValue}
                  label="Data de envio"
                  error={errors.send_date}
                  type="date"
                  initialValue={sendDateInitialValue}
                />
              </GridItem>
            </Grid>

            {previewImages && previewImages.length > 0 ? (
              <Flex mb={4} overflowX={previewImages.length > 5 ? 'auto' : 'hidden'}>
                {previewImages.map(image => (
                  <Image src={image} w={40} h={40} mr={1} />
                ))}
              </Flex>
            ) : null}

            <FormLabel htmlFor="stubs-preview">
              <Flex
                border={3}
                borderStyle="dashed"
                borderColor={colorBorderFileArea}
                alignItems="center"
                flexDirection="column"
                p="20"
                bg={colorBackgroundFileArea}
                cursor="pointer"
              >
                <Icon as={HiOutlineArrowUpTray} fontSize={60} mb="5" />
                <Text fontSize={20}>Faça upload dos arquivos</Text>
              </Flex>

              <Input
                id="stubs-preview"
                d="none"
                name="stubs"
                type="file"
                multiple
                setValue={setValue}
                error={errors.stubs}
                onChange={handleSelectImages}
              />
            </FormLabel>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme={colorBackgroundCreateStubs}
              leftIcon={<Icon as={RiAttachmentLine} />}
              isLoading={isLoadingCreate}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
