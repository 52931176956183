import { Grid, GridItem } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { MotoristType } from '../../services/types'
import { formatDateForInitialValue } from '../../services/utils/dates/formatDateForInitialValue'
import { validateDate } from '../../services/utils/dates/isValidDate'
import { CnpjData, searchPersonByCnpj } from '../../services/utils/searchPersonByCnpj'
import { validateCpf } from '../../services/utils/validateCpf'
import { OwnerQuickFormData } from './create/CreateQuickOwner'

interface OwnerQuickFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: OwnerQuickFormData
  handleCheckMotoristExists?: () => void
  motoristExists?: MotoristType
  motoristPhoneRef?: React.RefObject<HTMLInputElement>
}

export function OwnerQuickForm({
  setValue,
  formState,
  initialData,
  handleCheckMotoristExists,
  motoristExists,
  motoristPhoneRef,
}: OwnerQuickFormProps): JSX.Element {
  const [searchOwnerByCnpj, setSearchOwnerByCnpj] = useState<CnpjData>()
  const [legalPerson, setLegalPerson] = useState(false)

  const { errors } = formState

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="4" mb="6">
        <GridItem colSpan={[12, 3]}>
          <Select
            autoFocus
            name="type"
            label="Tipo"
            error={errors.type}
            setValue={setValue}
            initialValue={initialData?.type}
            onSelectOption={option => {
              if (option.value === 'pj') {
                setLegalPerson(true)
              } else if (option.value === 'pf') {
                setLegalPerson(false)
              }
            }}
            options={[
              { label: '', value: '' },
              { label: 'Pessoa física', value: 'pf' },
              { label: 'Pessoa Jurídica', value: 'pj' },
            ]}
          />
        </GridItem>

        {legalPerson && (
          <GridItem colSpan={[12, 3]}>
            <InputMask
              name="cnpj"
              mask="99.999.999/9999-99"
              maskPlaceholder=""
              label="CNPJ"
              error={errors.cnpj}
              setValue={setValue}
              initialValue={initialData?.cnpj || searchOwnerByCnpj?.cnpj}
              onChange={async e => {
                if (e.target.value.length === 14) {
                  const { value } = e.target
                  const owner = await searchPersonByCnpj(value)
                  if (owner) setSearchOwnerByCnpj(owner)
                }
              }}
              registerOnlyNumbers
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 3]}>
          <InputMask
            ref={motoristPhoneRef}
            onBlur={async () => {
              if (handleCheckMotoristExists) handleCheckMotoristExists()
            }}
            name="phone"
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            label="Telefone"
            error={errors.phone}
            setValue={setValue}
            initialValue={initialData?.phone || searchOwnerByCnpj?.phone || motoristExists?.phone}
          />
        </GridItem>

        {!legalPerson && (
          <GridItem colSpan={[12, 3]}>
            <InputMask
              name="cpf"
              mask="999.999.999-99"
              maskPlaceholder=""
              label="CPF"
              error={errors.cpf}
              setValue={setValue}
              initialValue={initialData?.cpf || motoristExists?.cpf}
              registerOnlyNumbers
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 2]}>
          <InputMask
            mask="99999999"
            maskPlaceholder=""
            registerOnlyNumbers
            name="rntrc"
            label="RNTRC"
            error={errors.rntrc}
            setValue={setValue}
            initialValue={initialData?.rntrc}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="rntrc_type"
            label="Tipo RNTRC"
            options={[
              { label: '', value: '' },
              { label: 'Equiparado', value: 'EQ' },
              { label: 'ETC', value: 'E' },
              { label: 'CTC', value: 'C' },
              { label: 'TAC', value: 'T' },
            ]}
            setValue={setValue}
            error={errors.rntrc_type}
            initialValue={initialData?.rntrc_type}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Input
            name="name"
            label={legalPerson ? 'Razão social' : 'Nome completo'}
            error={errors.name}
            setValue={setValue}
            initialValue={initialData?.name || searchOwnerByCnpj?.name || motoristExists?.name}
            uppercaseFirst
          />
        </GridItem>

        {legalPerson && (
          <GridItem colSpan={[12, 3]}>
            <Input
              name="ie"
              label="IE (Inscrição estadual)"
              error={errors.ie}
              setValue={setValue}
              initialValue={initialData?.ie}
            />
          </GridItem>
        )}
        {!legalPerson && (
          <>
            <GridItem colSpan={[12, 6]}>
              <Input
                isRequired
                name="father_name"
                label="Nome do pai"
                error={errors.father_name}
                setValue={setValue}
                initialValue={initialData?.father_name}
                uppercaseFirst
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <Input
                name="mother_name"
                label="Nome da mãe"
                error={errors.mother_name}
                setValue={setValue}
                initialValue={initialData?.mother_name}
                uppercaseFirst
              />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <Input
                isRequired
                name="birth"
                type="date"
                label="Data de nascimento"
                error={errors.birth}
                setValue={setValue}
                initialValue={
                  initialData?.birth
                    ? formatDateForInitialValue(initialData?.birth) ||
                      formatDateForInitialValue(motoristExists?.birth)
                    : undefined
                }
              />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <Input
                name="rg"
                label="RG"
                error={errors.rg}
                setValue={setValue}
                initialValue={initialData?.rg || motoristExists?.rg}
              />
            </GridItem>
            <GridItem colSpan={[12, 3]}>
              <Input
                name="rg_uf"
                label="RG UF"
                error={errors.rg_uf}
                setValue={setValue}
                initialValue={initialData?.rg_uf || motoristExists?.rg_uf}
              />
            </GridItem>
            <GridItem colSpan={[12, 3]}>
              <Input
                name="rg_dispatcher"
                label="Órgão expeditor"
                error={errors.rg_dispatcher}
                setValue={setValue}
                initialValue={initialData?.rg_dispatcher || motoristExists?.rg_dispatcher}
                uppercaseFirst
              />
            </GridItem>
            <GridItem colSpan={[12, 3]}>
              <Input
                name="rg_dispatch_date"
                type="date"
                label="Data de expedição"
                error={errors.rg_dispatch_date}
                setValue={setValue}
                initialValue={initialData?.rg_dispatch_date || motoristExists?.rg_dispatch_date}
              />
            </GridItem>
            <GridItem colSpan={[12, 3]}>
              <InputMask
                mask="999.99999.99.9"
                name="pis"
                label="PIS"
                error={errors.pis}
                setValue={setValue}
                initialValue={initialData?.pis || motoristExists?.pis}
                registerOnlyNumbers
              />
            </GridItem>
          </>
        )}
      </Grid>
    </>
  )
}

export const OwnerFormSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().when('type', (type: string) => {
    if (type === 'pj') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  cpf: yup
    .string()
    .test('validate-cpf', 'CPF inválido', value => {
      return validateCpf(value)
    })
    .when('type', (type: string) => {
      if (type === 'pf') {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
  name: yup.string().required('Campo obrigatório'),
  rntrc: yup.string().required('Campo obrigatório'),
  rntrc_type: yup.string().equals(['E', 'C', 'EQ', 'T']).required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  birth: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .when('type', (type: string) => {
      if (type === 'pf') {
        return yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Campo obrigatório')
      }
      return yup.date()
    }),
  ie: yup.string().when('type', (type: string) => {
    if (type === 'pj') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  father_name: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  mother_name: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_uf: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_dispatcher: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  rg_dispatch_date: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
  pis: yup.string().when('type', (type: string) => {
    if (type === 'pf') {
      return yup.string().required('Campo obrigatório')
    }
    return yup.string()
  }),
})
