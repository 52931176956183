import { SimpleGrid, WrapItem, Tag, TagLeftIcon, TagLabel, useToast, Text, Tooltip } from '@chakra-ui/react'
import { RiCaravanLine } from 'react-icons/ri'
import { TrailerType } from '../../services/types/TrailerType'
import { copyToClipboard } from '../../services/utils/copyToClipboard'

interface TrailerInfosProps {
  trailer: TrailerType
}

export function TrailerInfos({ trailer }: TrailerInfosProps): JSX.Element {
  const toast = useToast()
  return (
    <SimpleGrid minChildWidth="250px" spacing="4" mb="4">
      <WrapItem alignItems="center">
        <Text color="gray.400">Carroceria:</Text>
        <Tag size="lg" borderRadius="full" ml="2">
          <TagLeftIcon as={RiCaravanLine} />
          <TagLabel>{trailer.vehicle_body?.name}</TagLabel>
        </Tag>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Placa:</Text>
        {trailer.license_plate && (
          <Tooltip label="Clique para copiar placa" hasArrow>
            <Text
              cursor="pointer"
              onClick={() => {
                copyToClipboard(String(trailer.license_plate))
                toast({
                  title: 'Copiado com sucesso!',
                  status: 'success',
                  position: 'top-right',
                  duration: 1000,
                })
              }}
              fontWeight="medium"
              ml="1"
            >
              {trailer.license_plate}
            </Text>
          </Tooltip>
        )}
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Fabricação:</Text>
        <Text fontWeight="medium" ml="1">
          {trailer.release_year}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Marca:</Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.brand}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Eixos:</Text>
        <Text fontWeight="medium" ml="1">
          {trailer.axes}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Modelo:</Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.model}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">UF:</Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.license_uf}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Renavan: </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.renavam}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Chassi: </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.chassi}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">ANTT: </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.antt}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (m3): </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.capacity_m3}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (TARA): </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.capacity_tara}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (kg): </Text>
        <Text fontWeight="medium" ml="1">
          {trailer?.capacity_kg}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Municipio: </Text>
        <Text fontWeight="medium" ml="1">
          {trailer.city?.name}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Cor: </Text>
        <Text fontWeight="medium" ml="1">
          {trailer.color}
        </Text>
      </WrapItem>
    </SimpleGrid>
  )
}
