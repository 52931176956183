/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

async function putCostRiskAnalysis(id: string) {
  return apiServer.put(`/update-cost-risk-analysis/${id}`)
}

export function usePutCostRiskAnalysis(options: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (id: string) => putCostRiskAnalysis(id), options)
}
