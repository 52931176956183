import { Box, Flex, Heading, Link, Spinner, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import { Layout } from '../../../layout'
import {
  fetchReportsFreights,
  FreightsReport,
  useReportsFreights,
} from '../../../services/endpoints/freights/reportsFreights'

export function FreightsReports(): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })

  const [finalDate, setFinalDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })

  const [initialDateFreights, setInitialDateFreights] = useState<string>(() => {
    const today = new Date()
    const week = today.setDate(today.getDate() - 1)
    return format(week, 'yyyy-MM-dd')
  })
  const [finalDateFreights, setFinalDateFreights] = useState<string>(initialDateFreights)

  const { data: reportFreights, isLoading } = useReportsFreights({
    initialDateFreights,
    finalDateFreights,
  })

  useEffect(() => {
    setInitialDateFreights(initialDate)
    setFinalDateFreights(finalDate)
  }, [initialDate, finalDate])

  return (
    <Layout
      initialDate={initialDate}
      finalDate={finalDate}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      isFilters
      SidebarMobile
    >
      <Box>
        <Flex mb="4" gridGap="2" alignItems="center" justifyContent="center" fontSize={['md', 'lg']}>
          <Heading size="lg">Relatório de fretes</Heading>
          {reportFreights && reportFreights.freights.length > 0 && (
            <ReportToExcel
              isLoading={isLoading}
              documentTitle="Relatório de fretes"
              columnsTitle={[
                'Frete',
                'Status',
                'Aberto',
                'Finalizado',
                'Desativado',
                'CTE',
                'Transportador',
                'Motorista',
                'Nota',
                'Utilizou o app?',
                'Utilizou vale pedágio?',
                'Meio de pagamento',
                'Nota SpotX do cliente',
                'Nota SpotX do motorista',
                'Previsão de entrega',
              ]}
              onGenerateReport={async () => {
                const report = await fetchReportsFreights({
                  initialDateFreights,
                  finalDateFreights,
                })
                return report.freights.map((freights: FreightsReport) => {
                  return [
                    freights.freight_number,
                    freights.status,
                    freights.created_at,
                    freights.finished_at,
                    freights.denied_at,
                    freights.transporter,
                    freights.motorist_name,
                    freights.motorist_note,
                    freights.has_app ? 'Sim' : 'Não',
                    freights.toll_ticket,
                    freights.payment_method,
                    freights.spotx_note_by_client,
                    freights.spotx_note_by_motorist,
                    freights.delivered_cargo_at,
                  ] as string[]
                })
              }}
            />
          )}
        </Flex>

        <Table variant="striped" colorScheme="gray" size="sm" overflowX="auto" d="block">
          <Thead>
            <Tr>
              <Th fontSize="xs">Frete</Th>
              <Th fontSize="xs">Status</Th>
              <Th fontSize="xs">Aberto</Th>
              <Th fontSize="xs">Finalizado</Th>
              <Th fontSize="xs">Desativado</Th>
              <Th fontSize="xs">Transportador</Th>
              <Th fontSize="xs">Motorista</Th>
              <Th fontSize="xs">Nota</Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Utilizou o app?
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Utilizou vale pedágio?
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Meio de pagamento
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Nota SpotX do cliente
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Nota SpotX do motorista
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Previsão de entrega
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Flex alignItems="center" justifyContent="center" my="10">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Tbody justifyContent="flex-end">
              {!isLoading &&
                reportFreights &&
                reportFreights.freights?.length > 0 &&
                reportFreights.freights.map(freights => (
                  <Tr key={freights.id}>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Tooltip label="Ir para a página do frete">
                        <Box>
                          <Link href={`/freights/show/${freights.id}`}>{freights.freight_number}</Link>
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.status}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.created_at}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.finished_at}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.denied_at}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.transporter}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.motorist_name}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.motorist_note}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.has_app ? 'Sim' : 'Não'}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.toll_ticket}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.payment_method}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.spotx_note_by_client}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.spotx_note_by_motorist}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freights.delivered_cargo_at}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
      </Box>
    </Layout>
  )
}
