import { Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react'
import { ICostRiskAnalysisResponse } from '../../services/endpoints/risk-analysis/cost-risk-analysis/checkCostRiskAnalysis'

import parseCurrency from '../../services/utils/parseCurrency'

interface IAlertProps {
  risk_manager: string
  data: ICostRiskAnalysisResponse
}

export function AlertCardCostRiskAnalysis({ risk_manager, data }: IAlertProps): JSX.Element {
  return (
    <Alert variant="solid" status="warning" borderRadius="md" justifyContent="space-between" marginBottom={4}>
      <Flex alignItems="center">
        <AlertIcon />
        <AlertTitle mr={2} fontSize="lg">
          {risk_manager.toUpperCase()}: O custo de{' '}
          {parseCurrency(data.reduce((acc, elem) => Number(acc) + Number(elem.cost_risk_analysis_value), 0))}{' '}
          deve ser descontado do motorista
        </AlertTitle>
      </Flex>
    </Alert>
  )
}
