import { Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { Input } from '../../../../../components/form/Input'
import { InputMask } from '../../../../../components/form/InputMask'
import { InputNumberMask } from '../../../../../components/form/InputNumberMask'
import { Select } from '../../../../../components/form/Select'
import { SelectOption } from '../../../../../components/form/types/SelectOption'
import { FormattedQuotation } from '../../../../../services/endpoints/freights/getAllQuotations'
import { FormattedAllFreights } from '../../../../../services/endpoints/freights/getFreights'
import { trackingDeviceType } from '../../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import { IGetRoutePricesResponse } from '../../../../../services/endpoints/motorists/route-prices/getRoutePrices'

interface IFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  quotation: FormattedAllFreights | FormattedQuotation
  data?: IGetRoutePricesResponse
}

export function Form({ setValue, formState, quotation, data }: IFormProps): JSX.Element {
  const [vehicleBodies, setVehicleBodies] = useState<SelectOption[]>([])
  const [vehicleCategories, setVehicleCategories] = useState<SelectOption[]>([])

  const { errors } = formState
  useEffect(() => {
    if (quotation && quotation.vehicle_categories?.length) {
      const categories = quotation.vehicle_categories?.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      }) as SelectOption[]
      if (categories.length === 1) {
        setVehicleCategories(categories)
      } else {
        setVehicleCategories([{ label: '', value: '' }, ...categories])
      }
    }
    if (quotation && quotation.vehicle_bodies?.length) {
      const bodies = quotation.vehicle_bodies?.map(item => {
        return {
          label: item.name,
          value: item.id,
        }
      }) as SelectOption[]
      if (bodies.length === 1) {
        setVehicleBodies(bodies)
      } else {
        setVehicleBodies([{ label: '', value: '' }, ...bodies])
      }
    }
  }, [quotation])

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4">
      <GridItem colSpan={[12, 12, 8]}>
        <Input
          isRequired
          name="name"
          label="Nome"
          setValue={setValue}
          error={errors.name}
          initialValue={data?.name}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <InputNumberMask
          isRequired
          error={errors.value}
          name="value"
          label="Valor"
          isCashBRL
          setValue={setValue}
          initialValue={data?.value}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <InputNumberMask
          label="Pedágio"
          name="toll"
          isCashBRL
          setValue={setValue}
          initialValue={data?.toll}
          error={errors.toll}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <InputMask
          isRequired
          label="Telefone"
          name="phone"
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          setValue={setValue}
          initialValue={data?.phone}
          error={errors.phone}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Select
          options={trackingDeviceType}
          name="tracking_device_type"
          label="Dispositivo de rastreio"
          setValue={setValue}
          initialValue={data?.tracking_device_type}
          error={errors.tracking_device_type}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 4]}>
        <Select
          isRequired
          options={[
            { label: '', value: '' },
            { label: 'Base', value: 'data_base' },
            { label: 'Fretebras', value: 'fretebras' },
          ]}
          name="source"
          label="Fonte"
          setValue={setValue}
          initialValue={data?.source}
          error={errors.source}
        />
      </GridItem>

      {vehicleCategories.length > 0 && (
        <GridItem colSpan={[12, 6, 4]}>
          <Select
            isRequired
            label="Veículo"
            name="vehicle_category_id"
            setValue={setValue}
            options={vehicleCategories}
            initialValue={
              data?.vehicle_category?.id
                ? data?.vehicle_category?.id
                : vehicleCategories.length === 1
                ? vehicleCategories[0].value
                : undefined
            }
            error={errors.vehicle_category_id}
          />
        </GridItem>
      )}

      {vehicleBodies.length > 0 && (
        <GridItem colSpan={[12, 6, 4]}>
          <Select
            isRequired
            label="Carroceria"
            name="vehicle_body_id"
            setValue={setValue}
            options={vehicleBodies}
            initialValue={
              data?.vehicle_body?.id
                ? data?.vehicle_body?.id
                : vehicleBodies.length === 1
                ? vehicleBodies[0].value
                : undefined
            }
            error={errors.vehicle_body_id}
          />
        </GridItem>
      )}
    </Grid>
  )
}
