import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

export interface InvertRegistrationsForm {
  renavam: string
  typeInversion: string
}

export async function InvertRegistrations(
  data: InvertRegistrationsForm,
): Promise<AxiosResponse<ResponseApiType<void>>> {
  return apiServer.post('/vehicles/invert-registrations', data)
}

export function useInvertRegistrations(
  options?: UseMutationOptions<AxiosResponse, unknown, InvertRegistrationsForm, unknown>,
): UseMutationResult<AxiosResponse<ResponseApiType<void>>, unknown, InvertRegistrationsForm, unknown> {
  return useMutation(async (data: InvertRegistrationsForm) => InvertRegistrations(data), options)
}
