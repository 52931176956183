/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, UseMutationOptions } from 'react-query'

import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type GetFreightContractPdfParams = {
  cte_id: string
  freightContractId: string
}

type Output = string

async function getFreightContractPdf(params: GetFreightContractPdfParams): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output>>(
    `/freight-contracts/generate-pdf/${params.freightContractId}`,
  )
  return data.data
}

export function useGetFreightContractPdf(
  options?: UseMutationOptions<Output, unknown, GetFreightContractPdfParams, unknown>,
) {
  return useMutation<Output, unknown, GetFreightContractPdfParams>(
    (params: GetFreightContractPdfParams) => getFreightContractPdf(params),
    options,
  )
}
