import { useAbility } from '../../hooks/useAbility'
import { ActionsForModule, Modules } from '../../services/types/AuthorizationModulesTypes'

type AbilityProps<S extends Modules[0]> = {
  module: S
  action: ActionsForModule<S>
  children?: React.ReactNode
}

export const Ability = <S extends Modules[0]>({
  module,
  action,
  children,
}: AbilityProps<S>): JSX.Element | null => {
  const can = useAbility(module, action)

  return can ? <>{children}</> : null
}
