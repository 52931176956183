import { bankList } from './bankList'

export const getLabelBank = (value: string | undefined): string => {
  if (value && value.length > 3) {
    const bank = bankList.find(b => String(b.label) === String(value))
    return String(bank?.label)
  }
  const bank = bankList.find(b => String(b.value) === String(value))
  return String(bank?.label)
}
