import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'
import { ResponseListApiType, VehicleType } from '../../types'

export interface GetResponseAllVehicles {
  total: number
  data: VehicleType[]
}
export interface VehiclesFiltersParams {
  page?: number
  per_page?: number
  release_year?: number
  license_plate?: string
  vehicle_categories?: string[]
  vehicle_bodies?: string[]
}

export async function fetchAllVehicle({
  page,
  per_page,
  release_year,
  license_plate,
  vehicle_bodies,
  vehicle_categories,
}: VehiclesFiltersParams): Promise<GetResponseAllVehicles> {
  const { data: response } = await apiServer.get<ResponseListApiType<VehicleType[]>>(`/list-vehicle`, {
    params: {
      page,
      per_page,
      release_year,
      license_plate,
      vehicle_bodies,
      vehicle_categories,
    },
  })

  const { data } = response

  const { total } = data

  const vehicles = data.data

  return { total, data: vehicles }
}

export function useGetAllVehicle(params: VehiclesFiltersParams): UseQueryResult<GetResponseAllVehicles> {
  return useQuery(['vehicles', params], () => fetchAllVehicle(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
