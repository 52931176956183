import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  setValue: UseFormSetValue<FieldValues>
  initialValue?: string
  error?: FieldError
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    setValue,
    initialValue,
    error,
    onChange,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'off',
    _hover,
    type = 'password',
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const { colorMode } = useColorMode()
  const InputRightElementColor = useColorModeValue('black', 'white')
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (initialValue) {
      setShowPassword(false)
      setValue(name, initialValue)
      if (inputRef.current) {
        const inputValue = inputRef.current.querySelector('input')
        if (inputValue) {
          inputValue.value = initialValue.toString()
        }
      }
    }
  }, [initialValue, setValue, name])

  return (
    <FormControl isDisabled={isDisabled} ref={inputRef} isInvalid={!!error} isRequired={isRequired}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}
      <InputGroup size={size}>
        <ChakraInput
          ref={ref}
          id={name}
          name={name}
          type={showPassword ? 'text' : type}
          focusBorderColor={focusBorderColor}
          bgColor={bgColor || bg}
          variant={variant}
          size={size}
          _hover={_hover || { bgColor: bg }}
          isDisabled={isDisabled}
          _disabled={{
            opacity: 0.7,
            cursor: 'not-allowed',
          }}
          onChange={e => {
            const { value } = e.target
            const valueToRegister = value || undefined
            setValue(name, valueToRegister)

            if (onChange) onChange(e)
          }}
          disableAutocomplete
          disableGoogleAutocomplete
          autoComplete={autoComplete}
          {...rest}
        />
        {!isDisabled && (
          <InputRightElement
            fontWeight="bold"
            color={colorMode === 'dark' && !initialValue ? 'black' : InputRightElementColor}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
          >
            <Box
              size="sm"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
            </Box>
            <Icon
              onClick={() => {
                setValue(name, undefined)
                if (inputRef.current) {
                  const inputDOM = inputRef.current.querySelector('input')
                  if (inputDOM) {
                    inputDOM.value = ''
                  }
                }
              }}
              as={MdClear}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const InputPassword = forwardRef(InputBase)
