import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleBodyType } from '../../types'

export async function getVehicleBodies(name?: string): Promise<AutocompleteOption[]> {
  const { data: res } = await apiServer.get<ResponseApiType<VehicleBodyType[]>>('/vehicles/bodies/search', {
    params: {
      name,
    },
  })

  return res.data.map(body => {
    const label = body.name
    const value = body.id

    return { label, value }
  })
}
