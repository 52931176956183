const LucasOliveira = 'lucas.oliveira@spotx.com.br'

export const usersFreightEditors = [LucasOliveira]
export const editableFields = [
  'collect_cargo_at',
  'delivered_cargo_at',
  'service_price',
  'client_ref_type',
  'client_ref_number',
]
