import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { SelectOption } from './types/SelectOption'

interface SelectProps extends ChakraSelectProps {
  name: string
  options?: SelectOption[]
  label?: string
  setValue: UseFormSetValue<FieldValues>
  initialValue?: string
  error?: FieldError
  onSelectOption?: (option: SelectOption) => void
  loadOptions?: () => Promise<SelectOption[]>
  loadOptionsSync?: () => SelectOption[]
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    name,
    options,
    label,
    setValue,
    initialValue,
    error,
    onChange,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    _hover,
    onSelectOption,
    loadOptions,
    loadOptionsSync,
    placeholder,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const [items, setItems] = useState<SelectOption[]>([])
  const inputRef = useRef<HTMLDivElement>(null)

  // Esse useEffect garante que só o que foi selecionado seja levado em conta
  // Ele precisa vir primeiro, NÃO pode alterar a ordem
  useEffect(() => {
    if (!initialValue) {
      setValue(name, undefined)
    }
    // Desabilitado a dependência do "initialValue" para evitar de eliminar o estado no onSave
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, name])

  // Esse useEffect garante que o estado seja atualizado quando o valor do input ou do inicialValue for alterado
  // Ele precisa vir depois, NÃO pode alterar a ordem
  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
      if (inputRef.current) {
        const inputValue = inputRef.current.querySelector('select')
        if (inputValue) {
          inputValue.value = initialValue
        }
      }
    }
  }, [initialValue, setValue, name])

  return (
    <FormControl isDisabled={isDisabled} isRequired={isRequired} ref={inputRef} isInvalid={!!error}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}

      <ChakraSelect
        ref={ref}
        id={name}
        name={name}
        onFocus={async () => {
          if (loadOptions) {
            const selectOptions = await loadOptions()
            setItems([{ label: '', value: '' }, ...selectOptions])
          }
          if (loadOptionsSync) {
            const selectOptions = loadOptionsSync()
            setItems([{ label: '', value: '' }, ...selectOptions])
          }
        }}
        focusBorderColor={focusBorderColor}
        bgColor={bgColor || bg}
        variant={variant}
        size={size}
        _hover={_hover || { bgColor: bg }}
        isDisabled={isDisabled}
        _disabled={{
          opacity: 0.7,
          cursor: 'not-allowed',
        }}
        onChange={e => {
          const { value } = e.target
          const valueToRegister = value || undefined
          setValue(name, valueToRegister)
          if (onChange) {
            onChange(e)
          }
          if (onSelectOption) {
            if (options && options.length > 0) {
              const selectedItem = options.find(i => i.value === value)
              if (selectedItem) {
                onSelectOption(selectedItem)
              }
            }
            if (items && items.length > 0) {
              const selectedItem = items.find(i => i.value === value)
              if (selectedItem) {
                onSelectOption(selectedItem)
              }
            }
          }
        }}
        disableAutocomplete
        disableGoogleAutocomplete
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...rest}
      >
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        {items &&
          items.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </ChakraSelect>

      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)
