/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType, ResponseOcrCrlv } from '../../../types'

export function usePostCrlvInformations(
  options?: UseMutationOptions<ResponseOcrCrlv, Error, string, unknown>,
) {
  return useMutation<ResponseOcrCrlv, Error, string>(async (crlvImage: string) => {
    const { data: response } = await apiServer.post<ResponseApiType<ResponseOcrCrlv>>('/ocr/crlv', {
      crlv_base64_or_url: crlvImage,
    })
    return response.data
  }, options)
}
