import { Flex, Icon, Link, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { RiBriefcaseLine, RiStopFill, RiUser2Line } from 'react-icons/ri'
import { EditOrDuplicateFreightModal } from '../../../pages/freights/edit/EditOrDuplicateFreightModal'
import { EnableFreightModal } from '../../../pages/freights/edit/EnableFreightModal'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { formattedTransporter } from '../../../services/utils/formattedTransporter'
import parseCurrency from '../../../services/utils/parseCurrency'
import { Container } from './Container'
import { DefaultPropCards } from './DefaultPropCards'

interface KanbanCardNoShowProps {
  freight: FormattedAllFreights
}

export function KanbanCardNoShow({ freight }: KanbanCardNoShowProps): JSX.Element {
  const bg = useColorModeValue('gray.200', 'gray.500')

  const [actionFreightType, setActionFreightType] = useState<'edit' | 'duplicate'>('edit')

  const {
    isOpen: isEnableFreightModalOpen,
    onOpen: onEnableFreightModalOpen,
    onClose: onEnableFreightModalClose,
  } = useDisclosure()
  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  return (
    <Container
      freight={freight}
      header={
        <DefaultPropCards
          freight={freight}
          type="no-show"
          bg={bg}
          setActionFreightType={setActionFreightType}
          onFreightFormModalOpen={onFreightFormModalOpen}
          onEnableFreightModalOpen={onEnableFreightModalOpen}
        />
      }
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        borderBottomColor={bg}
        borderBottomWidth={1}
        pb="0.5"
        mb="0.5"
      >
        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiUser2Line} fontSize="sm" mr="1" /> {freight.creator?.name?.split(' ')[0]}
          </Flex>
        </Text>

        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiBriefcaseLine} fontSize="md" mr="1" />
            {freight.service_price && parseCurrency(freight.service_price)}
          </Flex>
        </Text>
      </Flex>

      {(freight.transporter || freight.calculation_ref) && (
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          fontSize="xs"
          borderBottomColor={bg}
          borderBottomWidth={1}
          pb="0.5"
          mb="0.5"
        >
          {freight.transporter && <Text>{formattedTransporter(freight.transporter)}</Text>}
          {freight.calculation_ref && (
            <Tooltip label="Ir para a calculadora">
              <Text as={Link} href={freight.calculation_ref}>
                <Icon as={AiOutlineCalculator} color="orange" fontSize="md" mr="1" />
              </Text>
            </Tooltip>
          )}
        </Flex>
      )}

      {freight.formatted_denied_at && (
        <Flex justifyContent="space-between" direction="row">
          <Text fontSize="xs">
            <Flex direction="row">
              <Icon as={RiStopFill} fontSize="md" mr="1" /> {freight.formatted_denied_at}
            </Flex>
          </Text>
        </Flex>
      )}

      <EnableFreightModal
        freight_id={freight.id}
        isOpen={isEnableFreightModalOpen}
        onClose={() => {
          onEnableFreightModalClose()
        }}
      />
      {freight && (
        <EditOrDuplicateFreightModal
          type={actionFreightType}
          freight_id={freight.id}
          isOpen={isFreightFormModalOpen}
          onClose={() => {
            onFreightFormModalClose()
          }}
        />
      )}
    </Container>
  )
}
