import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import useClickOutside from '../../../../hooks/useClickOutside'
import useSearchVehicle from '../../../../hooks/useSearchVehicle'
import { TrailerResponseFormData } from '../../../../services/endpoints/trailers/getTrailerByColumn'
import { VehicleResponseFormData } from '../../../../services/endpoints/vehicles/getVehicle'

type VehicleSearchOptionProps = {
  name: string
} & ButtonProps

const VehicleSearchOption = ({ name, ...rest }: VehicleSearchOptionProps): JSX.Element => (
  <Button
    rounded="none"
    fontWeight="normal"
    fontSize="14"
    justifyContent="start"
    size="md"
    w="100%"
    {...rest}
  >
    <Box>{name}</Box>
  </Button>
)

type VehicleSearchInputProps = {
  isLoading?: boolean
} & InputProps

const VehicleSearchInput = ({ isLoading, ...rest }: VehicleSearchInputProps): JSX.Element => (
  <InputGroup>
    <InputLeftElement pointerEvents="none">
      <FiSearch color="gray.300" />
    </InputLeftElement>
    <Input {...rest} />
    <InputRightElement>{isLoading && <Spinner size="sm" />}</InputRightElement>
  </InputGroup>
)

const VehicleSearchContainer = (props: BoxProps): JSX.Element => <Box {...props} position="relative" />

type VehicleSearchProps = {
  onVehicleFound?: (vehicle: VehicleResponseFormData) => void
  onTrailerFound?: (trailer: TrailerResponseFormData) => void
  type?: 'vehicle' | 'trailer'
}

export const VehicleSearch = ({
  onVehicleFound,
  type = 'vehicle',
  onTrailerFound,
}: VehicleSearchProps): JSX.Element => {
  const [data, setData] = useState<VehicleResponseFormData | TrailerResponseFormData | undefined>()
  const [searchQuery, setSearchQuery] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('gray.100', 'gray.800')

  const { loading, search } = useSearchVehicle({
    onSuccess: result => {
      if (result.vehicle) setData(result.vehicle as VehicleResponseFormData)
      if (result.trailer) setData(result.trailer as TrailerResponseFormData)
    },
    type,
  })

  const handleChange = (value: string) => {
    setSearchQuery(value)
    search(value)
    setIsDropdownOpen(true)
  }

  useClickOutside(containerRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
      setSearchQuery('')
      setData(undefined)
    }
  })

  return (
    <VehicleSearchContainer w="100%">
      <VehicleSearchInput
        value={searchQuery}
        onChange={e => handleChange(e.target.value)}
        isLoading={loading}
        placeholder="Pesquisar veículo por renavam..."
      />
      {isDropdownOpen && (
        <SimpleGrid
          columns={1}
          my={2}
          w="100%"
          zIndex={10}
          py={2}
          bg={bg}
          position="absolute"
          rounded="md"
          shadow="md"
          maxH="200px"
          overflowY="auto"
          gap={2}
          ref={containerRef}
        >
          {data ? (
            <VehicleSearchOption
              key={data.id}
              name={`veículo: ${data.renavam} - ${data.license_plate}`}
              onClick={() => {
                onVehicleFound?.(data as VehicleResponseFormData)
                onTrailerFound?.(data as TrailerResponseFormData)
                setIsDropdownOpen(false)
                setSearchQuery('')
                setData(undefined)
              }}
            />
          ) : (
            <Box textAlign="center" p={2}>
              {loading ? 'por favor, aguarde...' : '  Nenhum veículo encontrado'}
            </Box>
          )}
        </SimpleGrid>
      )}
    </VehicleSearchContainer>
  )
}
