import {
  Alert,
  AlertIcon,
  Badge,
  Flex,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'
import { useState } from 'react'
import { FormattedAllFreights } from '../../../../../services/endpoints/freights/getFreights'
import { calculateTripDuration } from '../../../../../services/utils/calculateTripDuration'
import { AlterTollStatusModal } from '../../modals/AlterTollStatusModal'
import { TrComponent } from './TrComponent'

type TableContainerProps = {
  freights: (FormattedAllFreights & {
    toll_tag?: string //  'conectcar' | 'semparar' // found in freight_vehicle_tags
    toll_value?: number // found in route_points
    value_vpo?: number // found in mdfe with name toll_value
  })[]
}

export function TableContainerFreightsTollStatus({ freights }: TableContainerProps): JSX.Element {
  const badgeBgColor = useColorModeValue('gray.300', 'gray.800')
  const [canEdit] = useState<boolean>(false)

  return (
    <>
      {freights && freights.length > 0 ? (
        <TableContainer backgroundColor="transparent" width="100%">
          <Table size="md" colorScheme="gray" variant="striped" borderRadius="16px">
            <Thead>
              <TrComponent canEdit={false} />
            </Thead>
            <Tbody>
              {freights.map(
                (
                  freight: FormattedAllFreights & {
                    toll_tag?: string
                    toll_value?: number
                    value_vpo?: number
                  },
                ) => {
                  let deliveryDate
                  let tripDuration
                  if (freight.distance) {
                    const date = new Date()
                    date.setHours(12)
                    tripDuration = calculateTripDuration(freight.distance, 60, date)
                    if (freight.collect_cargo_at) {
                      deliveryDate = new Date(freight.collect_cargo_at)
                      tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)

                      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
                    }
                  }

                  return (
                    <Tr key={freight.id}>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Tooltip hasArrow placement="auto" label={`Ver frete de ${freight.client?.nickname}`}>
                          <Link target="_blank" href={`/freights/show/${freight.id}`}>
                            <Flex direction="row" gridGap="1">
                              <Text fontWeight="bold">#{freight.freight_number} </Text>
                            </Flex>
                          </Link>
                        </Tooltip>
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Badge
                          colorScheme={
                            ['semparar', 'conectcar'].includes(String(freight.toll_tag)) ? 'green' : 'red'
                          }
                          rounded="full"
                        >
                          <Tooltip label={freight.toll_tag ? `${freight.toll_tag}` : ''} hasArrow>
                            {['semparar', 'conectcar'].includes(String(freight.toll_tag)) ? 'Sim' : 'Não'}
                          </Tooltip>
                        </Badge>
                      </Td>

                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Text fontWeight="bold">{freight.client?.nickname?.split(' ')[0]}</Text>
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        {freight.collect_cargo_at_pt_br}
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        {freight.delivered_cargo_at
                          ? format(new Date(freight.delivered_cargo_at), 'dd/MM/yy')
                          : tripDuration?.relativeTripDuration}
                      </Td>

                      <Td fontSize="xs" whiteSpace="nowrap">
                        {freight.origin?.name} - {freight.origin?.state?.uf}
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        {freight.destination?.name} - {freight.destination?.state?.uf}
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        {freight.formatted_status}
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Badge colorScheme={freight.toll_value ? 'green' : 'blue'} rounded="full">
                          {freight?.toll_value ? `R$ ${freight?.toll_value}` : 'N/A'}
                        </Badge>
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Badge colorScheme={freight.value_vpo ? 'green' : 'blue'} rounded="full">
                          {freight.value_vpo ? freight.value_vpo : 'Não'}
                        </Badge>
                      </Td>
                      <Td fontSize="xs" whiteSpace="nowrap">
                        <Badge
                          color={freight.is_paid_toll ? 'green.500' : 'red.500'}
                          rounded="full"
                          bgColor={badgeBgColor}
                        >
                          {freight.value_vpo ? 'Sim' : 'Não'}
                        </Badge>
                      </Td>

                      {canEdit && (
                        <Td fontSize="xs" whiteSpace="nowrap">
                          <AlterTollStatusModal freight={freight} />
                        </Td>
                      )}
                    </Tr>
                  )
                },
              )}
            </Tbody>
            <Tfoot>
              <TrComponent canEdit={canEdit} />
            </Tfoot>
          </Table>
        </TableContainer>
      ) : (
        <Alert status="info">
          <AlertIcon />
          <Heading size="md">Sem dados</Heading>
        </Alert>
      )}
    </>
  )
}
