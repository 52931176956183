import {
  Button,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BsCheck2 } from 'react-icons/bs'
import * as yup from 'yup'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { usePostClientBusinessGroup } from '../../../../services/endpoints/clients/postClientBusinessGroup'
import { validateClientBusinessGroupOptions } from '../utils/validate-client-business-group-options'
import { validateClientBusinessGroupSegmentOptions } from '../utils/validate-client-business-group-segment-options'
import { FormClientBusinessGroup } from './Form'

type CreateClientBusinessGroupModalProps = {
  isOpen: boolean
  onClose: () => void
}

type CreateClientBusinessGroupData = {
  clientBusinessGroup: {
    name: {
      label: string
      value?: string
    }
    segment?: {
      label: string
      value?: string
    }
  }
}

const createClientBusinessGroupFormSchema = yup.object().shape({
  clientBusinessGroup: yup.object().shape({
    name: yup.object().shape({
      label: yup
        .string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Somente caracteres alfanuméricos são permitidos')
        .test(
          'not-only-spaces',
          'Campo não pode conter apenas espaços em branco',
          value => value?.trim() !== '',
        )
        .required('Campo obrigatório'),
    }),
    segment: yup.object().shape({
      label: yup
        .string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Somente caracteres alfanuméricos são permitidos')
        .test(
          'not-only-spaces',
          'Campo não pode conter apenas espaços em branco',
          value => value?.trim() !== '',
        ),
    }),
  }),
})

export function CreateClientBusinessGroupModal({
  isOpen,
  onClose,
}: CreateClientBusinessGroupModalProps): JSX.Element {
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(createClientBusinessGroupFormSchema),
  })

  const toast = useToast()

  const { mutateAsync, isLoading, error, isError } = usePostClientBusinessGroup({
    onSuccess: () => {
      queryClient.invalidateQueries('clientBusinessGroup')
      onClose()
    },
  })

  const handleCreateClientBusinessGroup: SubmitHandler<CreateClientBusinessGroupData> = async data => {
    const { name } = data.clientBusinessGroup
    const { segment } = data.clientBusinessGroup

    if (name.label) {
      const alreadyExistsGroup = await validateClientBusinessGroupOptions(name.label)

      if (alreadyExistsGroup) {
        toastError({ toast, error: alreadyExistsGroup })
        return
      }
    }

    if (!segment?.value) {
      const alreadyExistsSegment = await validateClientBusinessGroupSegmentOptions(segment?.label)

      if (alreadyExistsSegment) {
        toastError({ toast, error: alreadyExistsSegment })
        return
      }
    }

    const params = {
      name: name.label,
    }

    if (segment && segment.value) {
      Object.assign(params, {
        segment_id: segment.value,
      })
    } else if (segment && segment.label && !segment.value) {
      Object.assign(params, {
        segment: {
          name: segment.label,
        },
      })
    }

    await mutateAsync(params)

    toast({
      title: 'Grupo criado com sucesso!',
      status: 'success',
      duration: 5000,
      position: 'top-right',
      isClosable: true,
    })
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Novo grupo empresarial</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(handleCreateClientBusinessGroup)}>
          <ModalBody>
            <Flex direction="column">
              <Grid templateColumns="repeat(12, 1fr)" gap="4">
                <FormClientBusinessGroup setValue={setValue} formState={formState} />
              </Grid>

              <Flex justifyContent="right" mt={5}>
                <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                  Cancelar
                </Button>

                <Button
                  isLoading={isLoading}
                  type="submit"
                  colorScheme="green"
                  leftIcon={<Icon as={BsCheck2} />}
                >
                  Cadastrar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  )
}
