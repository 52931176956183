import {
  SimpleGrid,
  WrapItem,
  Wrap,
  Divider,
  Box,
  Heading,
  Text,
  Tooltip,
  Flex,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'
import { Link } from 'react-router-dom'
import { queryClient } from '../../config/react-query'
import { UpdateContactByClientModal } from '../../pages/clients/edit/UpdateContactByClientModal'
import { FormattedQuotation } from '../../services/endpoints/freights'
import { calculateTripDuration } from '../../services/utils/calculateTripDuration'

interface QuotationInfosProps {
  quotation: FormattedQuotation
}

export function QuotationInfos({ quotation }: QuotationInfosProps): JSX.Element {
  const {
    onClose: onCloseUpdateContact,
    isOpen: isOpenUpdateContact,
    onOpen: onOpenUpdateContact,
  } = useDisclosure()

  let deliveryDate
  let tripDuration
  if (quotation.distance) {
    const date = new Date()
    date.setHours(12)
    tripDuration = calculateTripDuration(quotation.distance, 60, date)
    if (quotation.collect_cargo_at) {
      deliveryDate = new Date(quotation.collect_cargo_at)
      tripDuration = calculateTripDuration(quotation.distance, 60, deliveryDate)

      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
    }
  }

  return (
    <>
      <SimpleGrid minChildWidth="300px" spacing="4">
        <WrapItem>
          <Text color="gray.400">Cliente:</Text>
          <Tooltip label="Ir para página do cliente">
            <Box>
              <Text as={Link} to={`/clients/show/${quotation.client_id}`} fontWeight="medium" ml="1">
                {quotation.client?.name}
              </Text>
            </Box>
          </Tooltip>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Tipo:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.formatted_type}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Operador:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.creator?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Vendedor:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.seller?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Carga:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.cargo?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Valor (NF):</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.cargo_value}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Itens obrigatórios:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.required_items}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Peso:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.cargo?.weight} kg
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Exige rastreamento?:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.cargo_require_tracker}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Preço sugerido: </Text>
          <Text fontWeight="medium" color="green.400" ml="1">
            {`${quotation.formatted_suggested_price} / ${quotation.formatted_suggested_price_type}`}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Data:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.created_at}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Prev. coleta:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.collect_cargo_at && format(new Date(quotation.collect_cargo_at), 'dd/MM/yyyy')}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text color="gray.400">Prev. entrega:</Text>
          <Text fontWeight="medium" ml="1">
            {deliveryDate ? format(deliveryDate, 'dd/MM/yyyy') : tripDuration?.relativeTripDuration}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text color="gray.400">Valor do cliente:</Text>
          <Text fontWeight="medium" ml="1">
            {quotation.service_price &&
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(quotation.service_price)}
          </Text>
        </WrapItem>
      </SimpleGrid>

      <Divider my="4" />
      <SimpleGrid minChildWidth="300px" spacing="6">
        <Box>
          <Flex direction={['column', 'row']} gridGap={2} justify="space-between" align="center">
            <Heading size="md" fontWeight="normal" mb="6">
              Dados do solicitante
            </Heading>
            <Button
              colorScheme="orange"
              onClick={() => {
                onOpenUpdateContact()
              }}
              size="xs"
            >
              Editar
            </Button>
            {quotation?.client_contact_id && (
              <UpdateContactByClientModal
                onClose={() => {
                  onCloseUpdateContact()
                  queryClient.invalidateQueries('quotation')
                }}
                isOpen={isOpenUpdateContact}
                contactByClient_id={quotation.client_contact_id}
              />
            )}
          </Flex>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Nome: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation?.client_contact?.name}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Telefone: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation?.client_contact?.phone}
              </Text>
            </WrapItem>
          </Wrap>

          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Telefone 2: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation?.client_contact?.phone2}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Email: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation?.client_contact?.email}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Cargo: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation?.client_contact?.role}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>
      </SimpleGrid>

      <Divider my="4" />
      <SimpleGrid minChildWidth="300px" spacing="6">
        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço Origem
          </Heading>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Cidade: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation.origin_city}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Endereço: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation.origin_complement}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>

        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço Destino
          </Heading>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Cidade: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation.destination_city}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Endereço: </Text>
              <Text fontWeight="medium" ml="1">
                {quotation.destination_complement}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>
      </SimpleGrid>
    </>
  )
}
