import { useDisclosure } from '@chakra-ui/react'
import { ImPriceTag } from 'react-icons/im'
import { queryClient } from '../../../../../config/react-query'
import { FormattedQuotation } from '../../../../../services/endpoints/freights/getAllQuotations'
import QuotationsKanbanButtonForm from '../../../../quotation/kanban-quotations/cards/button-form-cards'
import { CreateRoutePricesModal } from '../CreatePricesMotoristsModal'

export interface ButtonProps {
  quotation: FormattedQuotation
  size?: 'sm' | 'lg' | 'md' | 'xs'
}

export default function CreateRoutePricesButton({ quotation, size }: ButtonProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      {['in_quotation', 'to_approve'].includes(quotation.status) && (
        <QuotationsKanbanButtonForm
          size={size}
          colorScheme="purple"
          leftIcon={ImPriceTag}
          onOpen={onOpen}
          label="Adicionar valor"
        />
      )}
      <CreateRoutePricesModal
        quotation={quotation}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          queryClient.invalidateQueries('freight')
          queryClient.invalidateQueries(`all-quotations`)
        }}
      />
    </>
  )
}
