import { Button, Divider, Flex, Grid, GridItem, Heading, Icon, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiRefreshLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../../../components/form/Input'
import { Select } from '../../../../../../components/form/Select'
import { SelectOption } from '../../../../../../components/form/types/SelectOption'
import { toastError } from '../../../../../../config/error/toastError'
import { apiServer } from '../../../../../../services/api'
import { listVehicleBrandsDatamex } from '../../../../../../services/endpoints/datamex/listVehicleBrandsDatamex'
import { listVehicleCategoriesDatamex } from '../../../../../../services/endpoints/datamex/listVehicleCategoriesDatamex'
import { listVehicleGroupsDatamex } from '../../../../../../services/endpoints/datamex/listVehicleGroupsDatamex'
import { FormattedOneFreight } from '../../../../../../services/endpoints/freights/getOneFreight'
import { SendDataToDatamex } from '../../../../../../services/types'
import { validateDate } from '../../../../../../services/utils/dates/isValidDate'
import { getValueFromAutoComplete } from '../../../../../../services/utils/getValueAutoComplete'

interface FormDatamexProps {
  freight: FormattedOneFreight
}

export function FormDatamex({ freight }: FormDatamexProps): JSX.Element {
  const { vehicle } = freight
  const { trailer } = freight

  const sendDataToDatamexForm = yup.object().shape({
    antt_owner_framing: yup.string().when(() => {
      if (vehicle?.antt_owner?.type === 'pj') {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
    vehicle_owner_framing: yup.string().when(() => {
      if (vehicle?.owner?.type === 'pj') {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
    trailer_owner_framing: yup.string().when(() => {
      if (trailer?.owner?.type === 'pj') {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
    cnh_insurance: yup.string().required('Campo obrigatório'),
    validity_toxicological_examination_at: yup
      .date()
      .typeError('Data inválida')
      .test('is-valid-date', 'Data inválida', validateDate)
      .required('Campo obrigatório'),
    vehicle_brand: yup.string().required('Campo obrigatório'),
    vehicle_group: yup.string().required('Campo obrigatório'),
    vehicle_category: yup.string().required('Campo obrigatório'),
    trailer_brand: yup.string().when(() => {
      if (freight.trailer_id) {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
    trailer_group: yup.string().when(() => {
      if (freight.trailer_id) {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
    trailer_category: yup.string().when(() => {
      if (freight.trailer_id) {
        return yup.string().required('Campo obrigatório')
      }
      return yup.string()
    }),
  })
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [vehicleGroup, setVehicleGroup] = useState<SelectOption[]>()
  const [vehicleBrands, setVehicleBrands] = useState<SelectOption[]>()
  const [vehicleCategories, setVehicleCategories] = useState<SelectOption[]>()
  const [datamexCategoryVehicleSelected, setDatamexCategoryVehicleSelected] = useState<string>()
  const [datamexCategoryTrailerSelected, setDatamexCategoryTrailerSelected] = useState<string>()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(sendDataToDatamexForm),
  })
  const { errors } = formState

  useEffect(() => {
    listVehicleGroupsDatamex()
      .then(response => setVehicleGroup(response))
      .catch(error => toastError({ toast, error }))
    listVehicleCategoriesDatamex()
      .then(response => setVehicleCategories(response))
      .catch(error => toastError({ toast, error }))
    listVehicleBrandsDatamex()
      .then(response => setVehicleBrands(response))
      .catch(error => toastError({ toast, error }))
  }, [toast])

  const handleSyncDatamex: SubmitHandler<SendDataToDatamex> = async data => {
    setIsLoading(true)
    try {
      await apiServer.post('/document-handler/send-register/antt-owner', {
        antt_owner_id: vehicle?.antt_owner_id,
        antt_owner_framing: data.antt_owner_framing,
      })

      await apiServer.post('/document-handler/send-register/motorist', {
        freight_id: freight.id,
        cnh_insurance: data.cnh_insurance,
        validity_toxicological_examination_at: data.validity_toxicological_examination_at,
        vehicle_brand: data.vehicle_brand,
        vehicle_group: data.vehicle_group,
        vehicle_category: data.vehicle_category,
        trailer_brand: data.trailer_brand,
        trailer_group: data.trailer_group,
        trailer_category: data.trailer_category,
      })

      await apiServer.post('/document-handler/send-register/vehicle', {
        freight_id: freight.id,
        cnh_insurance: data.cnh_insurance,
        validity_toxicological_examination_at: data.validity_toxicological_examination_at,
        vehicle_brand: data.vehicle_brand,
        vehicle_group: data.vehicle_group,
        vehicle_category: data.vehicle_category,
        vehicle_owner_framing: data.vehicle_owner_framing,
        trailer_brand: data.trailer_brand,
        trailer_group: data.trailer_group,
        trailer_category: data.trailer_category,
      })

      await apiServer.post('/document-handler/send-register/trailer', {
        freight_id: freight.id,
        cnh_insurance: data.cnh_insurance,
        validity_toxicological_examination_at: data.validity_toxicological_examination_at,
        vehicle_brand: data.vehicle_brand,
        vehicle_group: data.vehicle_group,
        vehicle_category: data.vehicle_category,
        trailer_owner_framing: data.trailer_owner_framing,
        trailer_brand: data.trailer_brand,
        trailer_group: data.trailer_group,
        trailer_category: data.trailer_category,
      })

      toast({
        status: 'success',
        position: 'top-right',
        title: 'Dados sincronizados com sucesso no Datamex!',
        isClosable: true,
      })

      setIsLoading(false)
    } catch (error) {
      toastError({ toast, error })
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSyncDatamex)} noValidate>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={12}>
          <Heading size="md" fontWeight="bold">
            Datamex
          </Heading>
        </GridItem>

        {vehicle?.antt_owner?.type === 'pj' && (
          <>
            <GridItem colSpan={[12, 4]}>
              <Select
                name="antt_owner_framing"
                label="Enquadramento dono antt"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Optante pelo Simples', value: 's' },
                  { label: 'Lucro Geral', value: 'g' },
                  { label: 'Lucro Geral - Real ', value: 'r' },
                  { label: 'Lucro Geral - Presumido ', value: 'p' },
                  { label: 'MEI - Microempreendedor', value: 'm' },
                ]}
                error={errors.antt_owner_framing}
                h="40px"
                initialValue={vehicle?.antt_owner?.framing}
              />
            </GridItem>
          </>
        )}

        {vehicle?.owner?.type === 'pj' && (
          <>
            <GridItem colSpan={[12, 4]}>
              <Select
                name="vehicle_owner_framing"
                label="Enquadramento dono tração"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Optante pelo Simples', value: 's' },
                  { label: 'Lucro Geral', value: 'g' },
                  { label: 'Lucro Geral - Real ', value: 'r' },
                  { label: 'Lucro Geral - Presumido ', value: 'p' },
                  { label: 'MEI - Microempreendedor', value: 'm' },
                ]}
                error={errors.vehicle_owner_framing}
                h="40px"
                initialValue={vehicle?.owner?.framing}
              />
            </GridItem>
          </>
        )}

        {trailer?.owner?.type === 'pj' && (
          <>
            <GridItem colSpan={[12, 4]}>
              <Select
                name="trailer_owner_framing"
                label="Enquadramento dono reboque"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Optante pelo Simples', value: 's' },
                  { label: 'Lucro Geral', value: 'g' },
                  { label: 'Lucro Geral - Real ', value: 'r' },
                  { label: 'Lucro Geral - Presumido ', value: 'p' },
                  { label: 'MEI - Microempreendedor', value: 'm' },
                ]}
                error={errors.trailer_owner_framing}
                h="40px"
                initialValue={trailer?.owner?.framing}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <Divider my="2" />
            </GridItem>
          </>
        )}

        <GridItem colSpan={12}>
          <Heading size="md" fontWeight="bold">
            CNH
          </Heading>
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="cnh_insurance"
            label="Seguro CNH"
            setValue={setValue}
            error={errors.cnh_insurance}
            h="40px"
            initialValue={freight.motorist?.driver_license?.cnh_insurance || "'Seguro exemplo'"}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            type="date"
            name="validity_toxicological_examination_at"
            label="Validade Exame Toxicológico"
            setValue={setValue}
            error={errors.validity_toxicological_examination_at}
            h="40px"
            initialValue={
              freight.motorist?.driver_license?.validity_toxicological_examination_at ||
              freight.motorist?.driver_license?.validity
            }
          />
        </GridItem>

        <GridItem colSpan={12}>
          <Divider my="2" />
        </GridItem>
        <GridItem colSpan={12}>
          <Heading size="md" fontWeight="bold">
            Veículo tração
          </Heading>
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          {vehicleGroup && (
            <Select
              name="vehicle_group"
              label="Grupo do veículo"
              setValue={setValue}
              options={[{ label: '', value: '' }, ...vehicleGroup]}
              error={errors.vehicle_group}
              h="40px"
              initialValue={vehicle?.group}
            />
          )}
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          {vehicleBrands && vehicleCategories && (
            <Select
              name="vehicle_brand"
              label="Marca do veículo"
              setValue={setValue}
              options={[{ label: '', value: '' }, ...vehicleBrands]}
              error={errors.vehicle_brand}
              onSelectOption={(option: SelectOption) => {
                if (option.label) {
                  setDatamexCategoryVehicleSelected(
                    getValueFromAutoComplete({
                      label: option.label.split(' - ')[1].trim(),
                      array: vehicleCategories,
                    }),
                  )
                } else {
                  setDatamexCategoryVehicleSelected(undefined)
                }
              }}
              h="40px"
              initialValue={
                vehicleBrands.some(i => i.value === vehicle?.brand?.toUpperCase())
                  ? vehicle?.brand
                  : undefined
              }
            />
          )}
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          {vehicleCategories && (
            <Select
              isDisabled
              name="vehicle_category"
              label="Categoria do veículo"
              setValue={setValue}
              options={[{ label: '', value: '' }, ...vehicleCategories]}
              error={errors.vehicle_category}
              h="40px"
              initialValue={datamexCategoryVehicleSelected || vehicle?.category_datamex}
            />
          )}
        </GridItem>

        <GridItem colSpan={12}>
          <Divider my="2" />
        </GridItem>
        {freight.trailer_id && (
          <>
            <GridItem colSpan={12}>
              <Heading size="md" fontWeight="bold">
                Veículo reboque
              </Heading>
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              {vehicleGroup && (
                <Select
                  name="trailer_group"
                  label="Grupo do reboque"
                  setValue={setValue}
                  options={[{ label: '', value: '' }, ...vehicleGroup]}
                  error={errors.trailer_group}
                  h="40px"
                  initialValue={trailer?.group}
                />
              )}
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              {vehicleBrands && vehicleCategories && (
                <Select
                  name="trailer_brand"
                  label="Marca do reboque"
                  setValue={setValue}
                  options={[{ label: '', value: '' }, ...vehicleBrands]}
                  error={errors.trailer_brand}
                  onSelectOption={(option: SelectOption) => {
                    if (option.label) {
                      setDatamexCategoryTrailerSelected(
                        getValueFromAutoComplete({
                          label: option.label.split(' - ')[1].trim(),
                          array: vehicleCategories,
                        }),
                      )
                    } else {
                      setDatamexCategoryTrailerSelected(undefined)
                    }
                  }}
                  h="40px"
                  initialValue={
                    vehicleBrands.some(i => i.value === trailer?.brand?.toUpperCase())
                      ? trailer?.brand
                      : undefined
                  }
                />
              )}
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              {vehicleCategories && (
                <Select
                  isDisabled
                  name="trailer_category"
                  label="Categoria do reboque"
                  setValue={setValue}
                  options={[{ label: '', value: '' }, ...vehicleCategories]}
                  error={errors.trailer_category}
                  h="40px"
                  initialValue={datamexCategoryTrailerSelected || trailer?.category_datamex}
                />
              )}
            </GridItem>
          </>
        )}
      </Grid>

      <Flex justify="flex-end" mt={4}>
        <Button
          type="submit"
          size="sm"
          leftIcon={<Icon as={RiRefreshLine} fontSize="x-large" />}
          colorScheme="green"
          isLoading={isLoading}
        >
          Sincronizar com Datamex
        </Button>
      </Flex>
    </form>
  )
}
