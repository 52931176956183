import { ElementType, ReactNode } from 'react'
import { Icon, Link as ChakraLink, Text, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType
  children: string | ReactNode
  to: string
  // eslint-disable-next-line react/require-default-props
  shouldMatchExactPath?: boolean
}

export function NavLink({
  icon,
  children,
  to,
  shouldMatchExactPath = false,
  ...rest
}: NavLinkProps): JSX.Element {
  const { pathname } = useLocation()
  let isActive = false

  if (shouldMatchExactPath && pathname === to) {
    isActive = true
  }

  if (!shouldMatchExactPath && pathname.startsWith(to)) {
    isActive = true
  }

  return (
    <ChakraLink
      as={Link}
      to={to}
      display="flex"
      align="center"
      color={isActive ? 'orange.400' : undefined}
      {...rest}
    >
      <Icon as={icon} fontSize="20" mr="4" />
      <Text fontWeight="medium">{children}</Text>
    </ChakraLink>
  )
}
