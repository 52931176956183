import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import OwnerType from '../../types/OwnerType'

// Tipos de dados
export interface ownerPayload {
  id?: string
  type: 'pf' | 'pj'
  name: string
  cpf?: string
  rg?: string
  pis?: string
  birth?: string
  rg_ie?: string
  rg_dispatch_date?: string
  rg_dispatcher?: string
  rg_uf?: string
  father_name?: string
  mother_name?: string
  cnpj?: string
  ie?: string
  phone: string
  address: string
  address_number: string
  district: string
  city_id: string
  zipcode: string
  rntrc?: string
  rntrc_type?: string
  account?: string
  account_type?: string
  agency?: string
  bank?: string
}

const createOwner = async (data: ownerPayload): Promise<ResponseApiType<OwnerType>> => {
  const { id } = data
  let response
  if (id) {
    response = await apiServer.put<ResponseApiType<OwnerType>>(`/update-owner/${id}`, data)
  } else {
    response = await apiServer.post<ResponseApiType<OwnerType>>(`/create-owner`, data)
  }

  return response.data
}

export const useCreateOwner = (
  options?: UseMutationOptions<ResponseApiType<OwnerType>, unknown, ownerPayload, unknown>,
): UseMutationResult<ResponseApiType<OwnerType>, unknown, ownerPayload, unknown> => {
  return useMutation((data: ownerPayload) => createOwner(data), options)
}
