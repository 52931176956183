import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiScissors2Fill, RiSmartphoneLine } from 'react-icons/ri'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../../services/api'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { VehicleType } from '../../../services/types'

interface InfoMotoristLinkedToVehicleExistsProps {
  vehicleExists: VehicleType
  vehicleIdToLinkMotorist?: string
  isAddMotorist?: boolean
}

export function InfoMotoristLinkedToVehicleExists({
  vehicleExists,
  vehicleIdToLinkMotorist,
  isAddMotorist,
}: InfoMotoristLinkedToVehicleExistsProps): JSX.Element {
  const toast = useToast()
  const [vehicle, setVehicle] = useState<VehicleType>(vehicleExists)
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const [vehicleIdToDisconnect, setVehicleIdToDisconnect] = useState<string>()
  const [motoristIdToDisconnect, setMotoristIdToDisconnect] = useState<string>()
  const { data: fetchedVehicle, refetch } = useGetVehicle(String(vehicleIdToLinkMotorist))

  useEffect(() => {
    if (vehicleIdToLinkMotorist && isAddMotorist) {
      const loadVehicle = async () => {
        await refetch()
        if (fetchedVehicle && fetchedVehicle.id === vehicle?.id) {
          setVehicle({ ...vehicle, motorists: fetchedVehicle.motorists })
        }
      }
      loadVehicle()
    }
  }, [isAddMotorist, vehicleIdToLinkMotorist, vehicle, fetchedVehicle, refetch])

  async function handleDisconnectMotorist(vehicle_id: string, motoristId: string) {
    try {
      await apiServer.post(`/motorist-detach-vehicle/`, {
        motorist_id: motoristId,
        vehicle_id,
      })
      if (vehicle)
        setVehicle({
          ...vehicle,
          motorists: vehicle.motorists.filter(item => item.id !== motoristId),
        })
      onCloseAlertDialog()
      toast({
        title: 'Desvinculado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      queryClient.invalidateQueries('list_motorist_in_analysis')
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <>
      {vehicle && vehicle.motorists.length > 1 ? (
        <Heading size="md" fontWeight="normal">
          Motoristas vinculados ao veículo
        </Heading>
      ) : vehicle && vehicle.motorists.length === 1 ? (
        <Heading size="md" fontWeight="normal">
          Motorista vinculado ao veículo
        </Heading>
      ) : (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle mr={2}>Nenhum motorista vinculado ao veículo!</AlertTitle>
        </Alert>
      )}
      {vehicle &&
        vehicle.motorists?.map(motorist => (
          <SimpleGrid minChildWidth="200px" spacing="3" my="8" key={motorist.id}>
            <Tooltip label="Ir para pagina do motorista" hasArrow>
              <WrapItem alignItems="center" as={Link} href={`/motorists/show/${motorist.id}`}>
                <Text fontWeight="bold" color="gray.400">
                  Nome:
                </Text>
                <Text fontWeight="medium" ml="1">
                  {motorist.name}
                </Text>
              </WrapItem>
            </Tooltip>
            <WrapItem alignItems="center">
              <Text fontWeight="bold" color="gray.400">
                Telefone:
              </Text>
              <Text fontWeight="medium" ml="1">
                {motorist.phone}
              </Text>
            </WrapItem>
            <WrapItem alignItems="center">
              {motorist.has_app && (
                <Tooltip hasArrow label="Este motorista possui o app instalado">
                  <Flex>
                    <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                      <Flex direction="row">
                        <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                      </Flex>
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </WrapItem>

            <WrapItem>
              <Tooltip label="Desvincular" hasArrow>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    onOpenAlertDialog()
                    setMotoristIdToDisconnect(motorist.id)
                    setVehicleIdToDisconnect(vehicle.id)
                  }}
                >
                  <Icon as={RiScissors2Fill} />
                </Button>
              </Tooltip>
            </WrapItem>
          </SimpleGrid>
        ))}
      {vehicleIdToDisconnect && motoristIdToDisconnect && (
        <AlertDialogConfirm
          isOpen={isOpenAlertDialog}
          title="Desvincular motorista"
          description="Tem certeza que deseja desvincular esse motorista do veículo?"
          onClose={onCloseAlertDialog}
          onConfirm={() => {
            handleDisconnectMotorist(vehicleIdToDisconnect, motoristIdToDisconnect)
          }}
        />
      )}
    </>
  )
}
