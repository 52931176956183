import { differenceInDays, differenceInHours, differenceInMinutes, format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { CheckinType, MotoristType } from '../../types'
import { ResponseListApiType } from '../../types/ResponseApiType'

export interface FetchMotoristsParams {
  orderByName?: 'asc' | 'desc'
  orderByCheckIn?: 'asc' | 'desc'
  orderByRateScore?: 'asc' | 'desc'
  page?: number
  per_page?: number
  freight_id?: string
  name?: string
  phone?: string
  cpf?: string
  license_plate?: string
  ddds?: number[]
  radius?: string
  spotx?: boolean
  type?: number
  origins?: number[]
  destinations?: number[]
  vehicle_categories?: string[]
  vehicle_bodies?: string[]
  checkins?: string[]
  general_filter?: boolean
  address_city_id?: AutocompleteOption
}
interface FormattedMotoristType extends MotoristType {
  last_checkin_city?: string
  last_checkin_time?: string
  formatted_rg_dispatch_date?: string
}

export interface GetMotoristsResponse {
  total: number
  data: FormattedMotoristType[]
}

function getCheckinTime(date: string | Date) {
  const nowDate = new Date()
  const parsedDate = new Date(date)

  const diffTimeMinutes = differenceInMinutes(nowDate, parsedDate)

  if (diffTimeMinutes < 60) return `Há ${diffTimeMinutes} min`
  if (diffTimeMinutes >= 60 && diffTimeMinutes < 120) return 'Há 1 hora'

  const diffTimeHours = differenceInHours(nowDate, parsedDate)

  if (diffTimeHours > 1 && diffTimeHours < 48) return `Há ${diffTimeHours} horas`

  const diffTimeDays = differenceInDays(nowDate, parsedDate)

  return `Há ${diffTimeDays} dias`
}

async function fetchMotorists(data?: FetchMotoristsParams): Promise<GetMotoristsResponse> {
  const params = data && { ...data }
  const { data: allMotorists } = await apiServer.get<ResponseListApiType<MotoristType[]>>('/list-motorists', {
    params,
  })

  const motorists = allMotorists.data.data.map(motorist => {
    let lastCheckin = {} as CheckinType
    if (motorist.last_checkin) {
      lastCheckin = motorist.last_checkin

      return {
        ...motorist,
        last_checkin_city: lastCheckin?.city?.name,
        last_checkin_time: getCheckinTime(lastCheckin?.checkin_at),
        formatted_rg_dispatch_date: motorist.rg_dispatch_date
          ? format(new Date(motorist.rg_dispatch_date), 'dd/MM/yyyy')
          : '',
      }
    }

    return motorist
  })

  return { data: motorists, total: allMotorists.data.total }
}

export function useGetMotorists(data?: FetchMotoristsParams): UseQueryResult<GetMotoristsResponse> {
  return useQuery(['motorists', data], () => fetchMotorists(data), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
