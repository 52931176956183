import { Button, Icon } from '@chakra-ui/react'
import { IconType } from 'react-icons'

export interface ButtonProps {
  colorScheme: string
  leftIcon?: IconType
  rightIcon?: IconType
  label: string
  onOpen: () => void
  size?: 'sm' | 'lg' | 'md' | 'xs'
}

export default function QuotationsKanbanButtonForm({
  colorScheme,
  leftIcon,
  rightIcon,
  label,
  onOpen,
  size = 'sm',
}: ButtonProps): JSX.Element {
  return (
    <Button
      zIndex={0}
      colorScheme={colorScheme}
      size={size}
      leftIcon={leftIcon ? <Icon as={leftIcon} /> : undefined}
      rightIcon={rightIcon ? <Icon as={rightIcon} /> : undefined}
      onClick={() => {
        onOpen()
      }}
    >
      {label}
    </Button>
  )
}
