/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TrailerResponseFormData } from '../../services/endpoints/trailers/getTrailerByColumn'
import { VehicleResponseFormData } from '../../services/endpoints/vehicles/getVehicle'
import { VehicleType } from '../../services/types'
import { TrailerType } from '../../services/types/TrailerType'

interface State {
  isLoading: boolean
  vehicleId: string | null
  trailerId: string | null
  vehicleCreated: VehicleType | null
  trailerCreated: TrailerType | null
  vehicleFound: VehicleResponseFormData | VehicleType | null
  trailerFound: TrailerResponseFormData | null
  hasTrailer: boolean | null
}

const initialState: State = {
  isLoading: false,
  vehicleId: null,
  trailerId: null,
  vehicleCreated: null,
  trailerCreated: null,
  vehicleFound: null,
  trailerFound: null,
  hasTrailer: null,
}

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },

    setHasTrailer(state, action: PayloadAction<boolean>) {
      state.hasTrailer = action.payload
    },

    setVehicleId(state, action: PayloadAction<string>) {
      state.vehicleId = action.payload
    },
    setTrailerId(state, action: PayloadAction<string>) {
      state.trailerId = action.payload
    },

    setVehicleCreated(state, action: PayloadAction<VehicleType | null>) {
      state.vehicleCreated = action.payload
    },
    setTrailerCreated(state, action: PayloadAction<TrailerType | null>) {
      state.trailerCreated = action.payload
    },
    setVehicleFound(state, action: PayloadAction<VehicleResponseFormData | VehicleType | null>) {
      state.vehicleFound = action.payload
    },
    setTrailerFound(state, action: PayloadAction<TrailerResponseFormData | null>) {
      state.trailerFound = action.payload
    },
  },
})

export const {
  setVehicleId,
  setTrailerId,
  setVehicleCreated,
  setTrailerCreated,
  setVehicleFound,
  setTrailerFound,
  setIsLoading,
  setHasTrailer,
} = vehicleSlice.actions
export default vehicleSlice.reducer
