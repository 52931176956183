import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type DeleteStubParams = {
  id?: string
}

type ResponseApi = {
  data: boolean
}

async function deleteStub(params: DeleteStubParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.delete<ResponseApiType<ResponseApi>>('stubs', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useDeleteStub(
  options?: UseMutationOptions<ResponseApi, unknown, DeleteStubParams, unknown>,
): UseMutationResult<ResponseApi, unknown, DeleteStubParams, unknown> {
  return useMutation(async (params: DeleteStubParams) => deleteStub(params), options)
}
