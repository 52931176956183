import { format } from 'date-fns'
/* eslint-disable consistent-return */
import { apiServer } from '../../../services/api'
import { ResponseApiType } from '../../../services/types'
import BlackListType, { CheckDocumentAndIsBlackListedExists } from '../../../services/types/BlackListType'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'

export const handleCnpjIsBlackListed = async (document: string): Promise<BlackListType | undefined> => {
  if (!document) return

  try {
    const { data: response } = await apiServer.get<ResponseApiType<BlackListType>>('/find-black-list', {
      params: { cnpj: getOnlyNumbers(document) },
    })
    const formattedData: BlackListType = response.data

    if (response.data.created_at) {
      Object.assign(formattedData, {
        created_at: format(new Date(response.data.created_at), 'dd/MM/yyyy'),
      })
    }
    return formattedData
  } catch (e) {
    return undefined
  }
}

export const handleCheckIfCnpjIsBlackListed = async (
  document?: string,
): Promise<BlackListType | undefined> => {
  if (document) {
    try {
      const { data } = await apiServer.get<ResponseApiType<CheckDocumentAndIsBlackListedExists>>(
        '/find-black-list',
        {
          params: { cnpj: getOnlyNumbers(document) },
        },
      )

      if (!data.data.isCnpjInBlacklist) {
        return undefined
      }
      if (typeof data.data.isCnpjInBlacklist !== 'boolean') {
        return data.data.isCnpjInBlacklist as BlackListType
      }
      return {
        id: data.data.owner.id,
        entity_type: data.data.owner.entity_type,
      } as BlackListType
    } catch {
      return undefined
    }
  }
}
