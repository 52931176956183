import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Input,
  Alert,
  AlertIcon,
  Link,
  Code,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
// SOMENTE PAULO VITOR PODE MEXER
import { api } from '../../../services/api'

type SetCheckedPaymentDateModalProps = {
  isOpen: boolean
  onClose: () => void
}

export function SetCheckedPaymentDateModal({
  isOpen,
  onClose,
}: SetCheckedPaymentDateModalProps): JSX.Element {
  const [file, setFile] = useState<File>()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [affectedCtes, setAffectedCtes] = useState<number>()

  useEffect(() => {
    setAffectedCtes(undefined)
  }, [isOpen])

  const handleFileUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      if (file) {
        const formData = new FormData()
        formData.append('attachment', file)
        const response = await api.post('/datamex/cte/set-payment-date', formData)
        setAffectedCtes(response.data.affectedCtes)
      }
    } catch (error) {
      toastError({ toast, error })
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleFileUpload}>
          <ModalHeader>Atualizar datas de pagamento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Deve-se subir um arquivo csv com as duas seguintes colunas preenchidas: <Code>cte_key</Code> e{' '}
              <Code>payment_date</Code>, assim como no modelo abaixo:
            </p>
            <br />
            <p>
              <Code>cte_key</Code> corresponde a chave da CTe e <Code>payment_date</Code> corresponde a data
              de pagamento que deve ser preenchida no formato americano (YYYY-MM-DD). Ex: 2023-12-31
            </p>

            <Button
              leftIcon={<Icon as={RiDownload2Line} />}
              colorScheme="green"
              my={3}
              size="sm"
              as={Link}
              href="https://spotxbr.s3.us-east-2.amazonaws.com/modelo_previsao_pagamento.csv"
            >
              Baixar modelo
            </Button>

            <Input
              mt={6}
              type="file"
              onChange={e => {
                if (e.target.files) {
                  setFile(e.target.files[0])
                }
              }}
            />

            {affectedCtes !== undefined && affectedCtes > 0 && (
              <Alert status="success" mt={4}>
                <AlertIcon />
                {`Arquivo enviado com sucesso! ${affectedCtes} CTes foram atualizadas`}
              </Alert>
            )}
            {affectedCtes !== undefined && affectedCtes === 0 && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                Nenhuma CTe foi atualizada. Verifique se o arquivo está preenchido no formato correto
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            {affectedCtes !== undefined && affectedCtes > 0 ? (
              <Button onClick={onClose} colorScheme="red">
                Fechar
              </Button>
            ) : (
              <Button
                type="submit"
                colorScheme="orange"
                mr={3}
                leftIcon={<Icon as={RiUpload2Line} />}
                isLoading={isLoading}
              >
                Enviar
              </Button>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
