import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { toastError } from '../../../config/error/toastError'
import { usePatchRiskAnalysis } from '../../../services/endpoints/risk-analysis/patchRiskAnalysis'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setClose } from '../../../store/slices/riskAnalysisSlice'

export interface IReasonStatusModalProps {
  status: 'awaiting' | 'awaiting_gr' | 'approved' | 'to_correct' | 'to_check' | 'disapproved' | 'canceled'
  title: string
  consultation_code?: string
}

export function ReasonStatusModal(): JSX.Element {
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [reason, setReason] = useState<string>()
  const { riskAnalysisId, reasonModalProps, isOpen } = useAppSelector(state => state.riskAnalysisSlice)

  const handleReasonStatus = async () => {
    try {
      if (reason && riskAnalysisId && reasonModalProps) {
        const { status, consultation_code } = reasonModalProps
        await mutateAsyncChangeStatus({
          id: riskAnalysisId,
          status,
          reason,
          consultation_code,
        })
        toast({
          title: 'Alteração realizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 8000,
        })
        dispatch(setClose())
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setReason(undefined)
  }, [isOpen])

  return (
    <>
      {reasonModalProps && (
        <Modal isOpen={isOpen} onClose={() => dispatch(setClose())} closeOnOverlayClick={false} size="md">
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>{reasonModalProps.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid minChildWidth="300px" spacing={2}>
                <Text>Informe o motivo</Text>
                <Textarea onChange={e => setReason(e.target.value)} />
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button
                rightIcon={<FaArrowRight />}
                colorScheme="green"
                isLoading={isLoadingChangeStatus}
                isDisabled={!reason}
                onClick={() => {
                  handleReasonStatus()
                }}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
