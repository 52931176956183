import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

interface IGetChecklistItemRiskAnalysisParams {
  checklist_risk_analysis_id: string
}

interface IChecklistRiskItemAnalysisResponse {
  id: string
  content_label: string
  type: string
  checklist_risk_analysis_id: string
  comments: string
  content_value: string
}

async function getChecklistItemRiskAnalysis(
  params: IGetChecklistItemRiskAnalysisParams,
): Promise<ResponseApiType<IChecklistRiskItemAnalysisResponse> | undefined> {
  if (params.checklist_risk_analysis_id) {
    const { data: checklistRiskAnalysis } = await apiServer.get(`/find-checklist-item/`, {
      params: {
        checklist_id: params.checklist_risk_analysis_id,
      },
    })
    return checklistRiskAnalysis.data
  }
  return undefined
}

export function useGetChecklistItemRiskAnalysis(
  params: IGetChecklistItemRiskAnalysisParams,
): UseQueryResult<IChecklistRiskItemAnalysisResponse[] | undefined> {
  return useQuery(['checklist-item-risk-analysis', params], () => getChecklistItemRiskAnalysis(params))
}
