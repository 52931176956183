export async function isValidateWidthImage(file: File): Promise<boolean> {
  return new Promise(resolve => {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      return resolve(img.width >= 20) // 20px, substituir para o valor desejado depois que a validação estiver ok
    }
  })
}
