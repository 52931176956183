import { AutocompleteOption } from '../../components/form'

export function encodeAutocompleteToUrl(obj?: AutocompleteOption): string {
  if (!obj || !obj.label || !obj.value) {
    return ''
  }

  const encodedLabel = btoa(obj.label)
  const encodedValue = btoa(obj.value.toString())

  return `label/${encodedLabel}#value/${encodedValue}`
}

export function decodeEncodeAutocompleteToObject(url: string): AutocompleteOption | undefined {
  if (!url) {
    return undefined
  }

  const pairs = url.split('#')
  const obj: Partial<AutocompleteOption> = {}

  pairs.forEach(pair => {
    const [key, value] = pair.split('/')
    if (key && value) {
      try {
        if (key === 'label') {
          obj.label = atob(value)
        } else if (key === 'value') {
          obj.value = atob(value)
        }
      } catch (e) {
        return undefined
      }
    }
  })

  // Verifica se tanto label quanto value estão presentes
  if (obj.label && obj.value) {
    return obj as AutocompleteOption
  }

  return undefined
}
