import { Flex, Spinner } from '@chakra-ui/react'
import ErrorFeedBack from './ErrorFeedBack'

interface StateHandlerComponent {
  loading: boolean
  error: boolean
  hasData: boolean
  children: React.ReactNode
}

const StateHandlerComponent = ({ loading, error, children, hasData }: StateHandlerComponent): JSX.Element => {
  if (loading) {
    return (
      <Flex align="center" justifyContent="center" h="600px">
        <Spinner />
      </Flex>
    )
  }

  if (error) {
    return <ErrorFeedBack message="Erro ao buscar dados. Tente novamente mais tarde." />
  }

  if (hasData) {
    return <>{children}</>
  }

  return <div>No data available</div>
}

export default StateHandlerComponent
