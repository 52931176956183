import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemeTypings,
  useDisclosure,
} from '@chakra-ui/react'

interface AlertNodalProps extends ButtonProps {
  title: string
  titleButton: string
  description?: string
  onConfirm: () => void
  confirmLabel?: string
  cancelLabel?: string
  colorScheme?: ThemeTypings['colorSchemes']
}

export function AlertModal({
  title,
  titleButton,
  description,
  onConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  colorScheme = 'red',
  ...rest
}: AlertNodalProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} colorScheme={colorScheme} {...rest}>
        {titleButton}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="md" scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{description && description}</ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{cancelLabel}</Button>
            <Button
              colorScheme={colorScheme}
              onClick={() => {
                onConfirm()
                onClose()
              }}
              ml={3}
            >
              {confirmLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
