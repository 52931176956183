import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ClientType, ResponseApiType } from '../../types'

export async function searchClientsByName(name: string, withCnpj = false): Promise<AutocompleteOption[]> {
  if (name) {
    const { data: res } = await apiServer.get<ResponseApiType<ClientType[]>>('/clients/search', {
      params: {
        name,
      },
    })

    return res.data.map(client => {
      const label = client.nickname ? client.nickname : client.name
      const value = client.id
      const cnpj = +client.cnpj
      const client_business_group_id = client.client_business_group_id ? client.client_business_group_id : ''

      if (withCnpj) return { label, value, cnpj }

      return { label, value, client_business_group_id }
    })
  }
  return []
}
