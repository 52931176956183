import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ClientContactType } from '../../../types/ClientType'
import { ResponseListApiType } from '../../../types/ResponseApiType'

export interface ClientContactsFilters {
  client_id?: string
  client_business_group_id?: string | number
  name?: string
  email?: string
  phone?: string
  phone2?: string
  role?: string
  page?: number | string
  per_page?: number
}

type ClientContactsResponse = {
  total: number
  data: ClientContactType[]
}

export async function searchClientContacts(params: ClientContactsFilters): Promise<ClientContactsResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<ClientContactType[]>>(
    'list-client-contacts',
    {
      params,
    },
  )
  return {
    total: res.data.total,
    data: res.data.data,
  }
}

export function useListClientContacts(params: ClientContactsFilters): UseQueryResult<ClientContactsResponse> {
  return useQuery(['contacts_from_client', params], () => searchClientContacts(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
