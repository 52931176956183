import { Box, Heading, Text } from '@chakra-ui/react'
import { ResponseOcrCnh } from '../../../../services/types'

type CNHinformationsProps = {
  driverLicense?: Partial<ResponseOcrCnh>
}

export const CNHinformations = ({ driverLicense }: CNHinformationsProps): JSX.Element => {
  if (!driverLicense) return <></>

  return (
    <>
      {driverLicense.nonValidFields && driverLicense.nonValidFields.length && (
        <Box p="2" borderRadius="md" bg="orange.100">
          <Text fontSize="smaller" fontWeight="medium">
            Campos que devem ser corrigidos:
          </Text>
          <Text fontSize="smaller">{driverLicense?.nonValidFields?.map(field => `${field}, `)}</Text>
        </Box>
      )}
      <Heading fontSize="sm">
        CNH:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.registration}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        CPF:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.cpf}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Nome:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.name}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Categoria:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.category}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Data de expedição:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.emissionDate}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Data Primeira CNH:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.firstLicenseDate}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Órgão expeditor:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.dispatcher}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Órgão UF:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.dispatcherUf}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Validade:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.validity}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Código de Segurança:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.insuranceNumber}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Número do protocolo:
        <Text as="span" color="gray.500" ml="2">
          {driverLicense?.mirror}
        </Text>
      </Heading>
    </>
  )
}
