import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'

type UploadAdvanceRequestAttachmentsType = {
  data: FormData
}

async function uploadAdvanceRequestAttachments(data: UploadAdvanceRequestAttachmentsType) {
  const response = await apiServer.post('/advance-requests/upload', data.data)
  queryClient.invalidateQueries('advance-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUploadAdvanceRequestAttachments(
  options?: UseMutationOptions<AxiosResponse, unknown, UploadAdvanceRequestAttachmentsType, unknown>,
) {
  return useMutation(
    async (data: UploadAdvanceRequestAttachmentsType) => uploadAdvanceRequestAttachments(data),
    options,
  )
}
