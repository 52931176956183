import { Button } from '@chakra-ui/react'
import { RiUser2Fill } from 'react-icons/ri'
import { Ability } from '../../../../components/ability'
import { useAppDispatch } from '../../../../store'
import { setOpen, setReasonStatusModalProps } from '../../../../store/slices/riskAnalysisSlice'
import { ReasonStatusModal } from '../ReasonStatusModal'

export default function ToForceApprovalButton(): JSX.Element {
  const dispatch = useAppDispatch()
  return (
    <Ability module="spotHub" action="force-approve-risk-analysis">
      <Button
        size="sm"
        onClick={() => {
          dispatch(setOpen())
          dispatch(
            setReasonStatusModalProps({
              title: 'Forçar a aprovação do motorista',
              status: 'approved',
              consultation_code: 'PERFIL ADEQUADO AO RISCO',
            }),
          )
        }}
        colorScheme="purple"
        rightIcon={<RiUser2Fill />}
      >
        Forçar Aprovação
      </Button>

      <ReasonStatusModal />
    </Ability>
  )
}
