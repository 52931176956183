import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { ButtonsActions } from '../../../../../components/datamex/list-ctes/ButtonsActions'
import { handleFormatDate } from '../../../../../services/endpoints/datamex/getCtes'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import parseCurrency from '../../../../../services/utils/parseCurrency'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'

interface ListCteProps {
  freight: FormattedOneFreight
  bg: string
}

export function ListCte({ freight, bg }: ListCteProps): JSX.Element | null {
  return freight ? (
    <Box bg={bg} p="4" borderRadius="8">
      <Flex justify="space-between" align="center">
        <Heading fontSize="md" fontWeight="medium">
          CTEs
        </Heading>
      </Flex>

      <Divider my="4" />

      {freight.formatted_ctes && freight.formatted_ctes.length > 0 ? (
        <>
          <TableContainer maxW="100%" d={displayAdaptable}>
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Th>Número</Th>
                  <Th>Status</Th>
                  <Th>Talão</Th>
                  <Th>Tomador</Th>
                  <Th>Transportadora</Th>
                  <Th>Data</Th>
                  <Th>Valor</Th>
                  <Th>Tipo</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {freight.formatted_ctes.map(c => (
                  <Tr
                    key={c.id}
                    style={{
                      backgroundColor: c.status === 'Cancelamento' ? '#ffcfcfe2' : 'transparent',
                    }}
                  >
                    <Td>{c.nct}</Td>
                    <Td>
                      <Badge
                        colorScheme={
                          c.status === 'Cancelamento' ? 'red' : c.status === 'Autorizado' ? 'green' : 'orange'
                        }
                        style={{
                          padding: '2px 8px',
                        }}
                      >
                        {c.status}
                      </Badge>
                    </Td>
                    <Td>{c.serie}</Td>
                    <Td>{c.taker}</Td>
                    <Td>{c.emitter}</Td>
                    <Td>{handleFormatDate(c.emit_date)}</Td>

                    <Td>{parseCurrency(c.installment_value_formatted)}</Td>
                    <Td>{c.type}</Td>
                    <Td>
                      <ButtonsActions cte={c} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Text textAlign="center">Nenhum documento encontrado</Text>
      )}
    </Box>
  ) : null
}
