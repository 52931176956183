import { useEffect } from 'react'
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import { Form } from './Form'
import { FormattedAllFreights } from '../../../../services/endpoints/freights/getFreights'
import { FormattedQuotation } from '../../../../services/endpoints/freights/getAllQuotations'
import { queryClient } from '../../../../config/react-query'
import { usePutRoutePrices } from '../../../../services/endpoints/motorists/route-prices/putRoutePrices'
import { ICreateRoutePrices } from '../../../../services/endpoints/motorists/route-prices/postRoutePrices'
import { useGetRoutePrices } from '../../../../services/endpoints/motorists/route-prices/getRoutePrices'
import { toastError } from '../../../../config/error/toastError'

interface IUpdateRoutePricesModalProps {
  isOpen: boolean
  onClose: () => void
  quotation: FormattedAllFreights | FormattedQuotation
  routePricesId: string
}

export function UpdateRoutePricesModal({
  isOpen,
  onClose,
  quotation,
  routePricesId,
}: IUpdateRoutePricesModalProps): JSX.Element {
  const { setValue, handleSubmit, formState } = useForm({})
  const { isSubmitting } = formState

  const updateRoutePrices = usePutRoutePrices({
    onSuccess: () => {
      queryClient.invalidateQueries('prices-motorists')
    },
  })

  const toast = useToast()

  const { data, refetch } = useGetRoutePrices(routePricesId)

  useEffect(() => {
    refetch()
  }, [isOpen, refetch])

  const handleUpdateRoutePrices: SubmitHandler<ICreateRoutePrices> = async dataToUpdate => {
    try {
      await updateRoutePrices.mutateAsync({
        id: routePricesId,
        ...dataToUpdate,
        quotation_id: quotation.id,
      })

      toast({
        title: 'Valor editado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })

      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar valor do motorista</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUpdateRoutePrices)} noValidate>
          <ModalBody>
            {data && <Form setValue={setValue} formState={formState} quotation={quotation} data={data} />}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiEditLine} />}
              isLoading={isSubmitting}
            >
              Editar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
