import {
  Button,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiSmartphoneLine, RiScissors2Fill } from 'react-icons/ri'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../../services/api'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'
import { TrailerType } from '../../../services/types/TrailerType'

interface InfoMotoristLinkedToTrailerExistsProps {
  trailerExists: TrailerType
  trailerIdToLinkMotorist?: string
  isAddMotorist?: boolean
}

export function InfoMotoristLinkedToTrailerExists({
  trailerExists,
  trailerIdToLinkMotorist,
  isAddMotorist,
}: InfoMotoristLinkedToTrailerExistsProps): JSX.Element {
  const toast = useToast()
  const [trailer, setTrailer] = useState<TrailerType>(trailerExists)
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const [trailerIdToDisconnect, setTrailerIdToDisconnect] = useState<string>()
  const [motoristIdToDisconnect, setMotoristIdToDisconnect] = useState<string>()
  const { data: fetchedTrailer, refetch } = useGetTrailer(String(trailerIdToLinkMotorist))

  useEffect(() => {
    if (trailerIdToLinkMotorist && isAddMotorist) {
      const loadTrailer = async () => {
        await refetch()
        if (fetchedTrailer && fetchedTrailer.id === trailer?.id) {
          setTrailer({ ...trailer, motorists: fetchedTrailer.motorists })
        }
      }
      loadTrailer()
    }
  }, [isAddMotorist, trailerIdToLinkMotorist, trailer, fetchedTrailer, refetch])

  async function handleDisconnectMotorist(trailer_id: string, motoristId: string) {
    try {
      await apiServer.post(`/motorist-detach-trailer/`, {
        motorist_id: motoristId,
        trailer_id,
      })
      if (trailer)
        setTrailer({
          ...trailer,
          motorists: trailer.motorists.filter(item => item.id !== motoristId),
        })
      onCloseAlertDialog()
      toast({
        title: 'Desvinculado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      queryClient.invalidateQueries('list_motorist_in_analysis')
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <>
      {trailer &&
        trailer.motorists?.map(motorist => (
          <SimpleGrid minChildWidth="200px" spacing="3" my="8" key={motorist.id}>
            <Tooltip label="Ir para pagina do motorista" hasArrow>
              <WrapItem alignItems="center" as={Link} href={`/motorists/show/${motorist.id}`}>
                <Text fontWeight="bold" color="gray.400">
                  Nome:
                </Text>
                <Text fontWeight="medium" ml="1">
                  {motorist.name}
                </Text>
              </WrapItem>
            </Tooltip>
            <WrapItem alignItems="center">
              <Text fontWeight="bold" color="gray.400">
                Telefone:
              </Text>
              <Text fontWeight="medium" ml="1">
                {motorist.phone}
              </Text>
            </WrapItem>
            <WrapItem alignItems="center">
              {motorist.has_app && (
                <Tooltip hasArrow label="Este motorista possui o app instalado">
                  <Flex>
                    <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                      <Flex direction="row">
                        <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                      </Flex>
                    </Text>
                  </Flex>
                </Tooltip>
              )}
            </WrapItem>

            <WrapItem>
              <Tooltip label="Desvincular" hasArrow>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    onOpenAlertDialog()
                    setMotoristIdToDisconnect(motorist.id)
                    setTrailerIdToDisconnect(trailer.id)
                  }}
                >
                  <Icon as={RiScissors2Fill} />
                </Button>
              </Tooltip>
            </WrapItem>
          </SimpleGrid>
        ))}
      {trailerIdToDisconnect && motoristIdToDisconnect && (
        <AlertDialogConfirm
          isOpen={isOpenAlertDialog}
          title="Desvincular motorista"
          description="Tem certeza que deseja desvincular esse motorista do veículo?"
          onClose={onCloseAlertDialog}
          onConfirm={() => {
            handleDisconnectMotorist(trailerIdToDisconnect, motoristIdToDisconnect)
          }}
        />
      )}
    </>
  )
}
