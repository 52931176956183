import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { fetchOwner } from '../../../services/endpoints/owners/getOwner'
import OwnerType from '../../../services/types/OwnerType'
import { OwnerFormData } from '../create/CreateOwner'
import { OwnerForm, OwnerFormSchema } from '../OwnerForm'

interface EditOwnerModalProps {
  owner_id: string
  isOpen: boolean
  onClose: () => void
}

export function EditOwnerModal({ owner_id, isOpen, onClose }: EditOwnerModalProps): JSX.Element {
  const toast = useToast()
  const [owner, setOwner] = useState<OwnerType>()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(OwnerFormSchema),
  })

  useEffect(() => {
    fetchOwner(owner_id).then(response => {
      if (response)
        setOwner({
          id: response.id,
          type: response.type,
          name: response.name,
          cnpj: response.cnpj,
          cpf: response.cpf,
          phone: response.phone,
          other_phone: response.other_phone,
          email: response.email,
          rntrc: response.rntrc,
          ie: response.ie,
          rg: response.rg,
          rg_uf: response.rg_uf,
          rg_dispatcher: response.rg_dispatcher,
          rg_dispatch_date: response.rg_dispatch_date,
          pis: response.pis,
          address: response.address,
          address_number: response.address_number,
          district: response.district,
          city_id: response.city_id,
          city: response.city,
          vehicles: response.vehicles,
          zipcode: response.zipcode,
          mother_name: response.mother_name,
          father_name: response.father_name,
          birth: response.birth,
          bank: response.bank,
          account_type: response.account_type,
          agency: response.agency,
          account: response.account,
        })
    })
  }, [owner_id, isOpen])

  useEffect(() => {
    setOwner(undefined)
  }, [isOpen])

  const handleEditOwner = useCallback<SubmitHandler<OwnerFormData>>(
    async data => {
      const {
        city_id,
        bank,
        cnpj,
        email,
        cpf,
        ie,
        mother_name,
        rg,
        rg_uf,
        rg_dispatcher,
        rg_dispatch_date,
        pis,
        birth,
        ...ownerFormData
      } = data
      try {
        if (owner?.type === 'pf') {
          await apiServer.put(`/update-owner/${owner_id}`, {
            cpf,
            mother_name,
            rg,
            rg_uf,
            rg_dispatcher,
            rg_dispatch_date,
            pis,
            email,
            birth,
            ...ownerFormData,
            city_id: city_id?.value,
            bank: bank?.label ? bank.label : undefined,
          })
        }
        if (owner?.type === 'pj') {
          await apiServer.put(`/update-owner/${owner_id}`, {
            cnpj,
            email,
            ie,
            ...ownerFormData,
            city_id: city_id?.value,
            bank: bank?.label ? bank.label : undefined,
          })
        }
        toast({
          title: 'Proprietário editado com sucesso!',
          status: 'success',
          position: 'top-right',
        })

        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [owner_id, owner, toast, onClose],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleEditOwner)} noValidate autoComplete="off">
          <ModalHeader>Editar Proprietário ,,</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {owner && <OwnerForm setValue={setValue} formState={formState} initialData={owner} />}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>

            <Ability module="people" action="update-owner">
              <Button
                type="submit"
                colorScheme="orange"
                leftIcon={<Icon as={RiEditLine} />}
                isLoading={formState.isSubmitting}
              >
                Editar
              </Button>
            </Ability>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
