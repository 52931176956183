import {
  Box,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react'
import { RiNotificationLine } from 'react-icons/ri'

export function NotificationsNav(): JSX.Element {
  return (
    <HStack
      spacing={['4', '6']}
      mx={['4', '6']}
      pr={['4', '6']}
      py="1"
      color="gray.300"
      borderRightWidth={1}
      borderRightColor="gray.700"
    >
      <Popover placement="auto-start">
        <Flex fontWeight="bold" cursor="pointer">
          <Tooltip hasArrow label="Notificações">
            <Flex>
              <PopoverTrigger>
                <Flex>
                  <Box mt={2}>
                    <Icon as={RiNotificationLine} fontSize={20} />
                  </Box>
                </Flex>
              </PopoverTrigger>
            </Flex>
          </Tooltip>
        </Flex>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>
            <Flex justifyContent="space-between">
              Notificações
              <PopoverCloseButton />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  )
}
