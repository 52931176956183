import { Button, Icon, useDisclosure } from '@chakra-ui/react'
import { SiAdblock } from 'react-icons/si'
import { IncludeInBlackListByDocumentModal } from '../IncludeInBlackListByDocumentModal'

export default function ButtonIncludeInBlackListByDocument(): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Button
        onClick={() => {
          onOpen()
        }}
        size="sm"
        colorScheme="red"
        leftIcon={<Icon as={SiAdblock} />}
      >
        Incluir na lista de restrição
      </Button>

      <IncludeInBlackListByDocumentModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
