import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'
import { ResponseArrayApiType } from '../../types/ResponseApiType'

interface IMonitoringOccurenceReasonsResponse {
  id: string
  reason_name: string
  monitoring_type: string
  updated_at: Date
  created_at: Date
}

async function getMonitoringOccurenceReasons(
  monitoringType: string | undefined,
): Promise<SelectOption[] | undefined> {
  if (monitoringType) {
    const { data: response } = await apiServer.get<
      ResponseArrayApiType<IMonitoringOccurenceReasonsResponse[]>
    >('/freights/monitoring-occurrences-reasons', {
      params: {
        monitoring_type: monitoringType,
      },
    })

    const reasons: SelectOption[] = response.data.map(reason => {
      return {
        label: reason.reason_name,
        value: reason.id,
      }
    })

    return reasons
  }

  return undefined
}

export function useGetMonitoringOccurenceReasons(
  monitoringType: string | undefined,
): UseQueryResult<SelectOption[]> {
  return useQuery(
    ['monitoringOccurenceReasons', monitoringType],
    () => getMonitoringOccurenceReasons(monitoringType),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
      retry: false,
    },
  )
}
