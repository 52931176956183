export const buonnyTechnologies = [
  '3 S',
  '3 T',
  '3S',
  '3T',
  'AGUIASAT',
  'ALLSAT',
  'AUTOCARGO',
  'AUTOSAT',
  'Autotrac',
  'BRINTEL',
  'C DATA',
  'CEABS',
  'CEABS (IMOBILIZADOR)',
  'Cielo',
  'CILTRONICS',
  'COBLE',
  'COMMANDERSAT',
  'CONFORCE',
  'ControlLoc',
  'ControlSat',
  'Cronos',
  'DDMX',
  'DDMX MAXTRACK',
  'DESATIVADO (ARIA SAT)',
  'EVOLUTION',
  'FORZA',
  'FOX SAT (ISCA)',
  'GETRAK',
  'GOAL',
  'GOLDEN SAT (IMOBILIZADOR)',
  'GPM TELECOM',
  'GPS RASTREAMENTO',
  'HPS',
  'INSAT',
  'IRIS SAT',
  'ISCA POINTER',
  'ITER',
  'ITURAN',
  'JABUR',
  'K-BLACK',
  'KODERLOC (ISCA)',
  'LINK',
  'LINKER',
  'LOSS CONTROL',
  'MACOR',
  'Macro',
  'MAXTRAC',
  'MEGASAT ',
  'Mensagem',
  'MOBILE',
  'Mova - MaxTrac',
  'MULTI CHIP',
  'OMNICARGA',
  'Omnilink',
  'OMNILOC',
  'OMNISATT',
  'Onixsat',
  'POINTER',
  'POLLUS SAT',
  'POSITRON',
  'PROTECT SAT',
  'RASTRO SEGURO (ISCA)',
  'RASTSAT',
  'RAVEX',
  'RETRAC',
  'Rodosis',
  'S.I.T.L.A (ISCA)',
  'Sascar',
  'SASCARGA',
  'SGBRAS',
  'SIGA+',
  'SIGASUL',
  'SIGHRA',
  'SIMTRACK',
  'SITRACK',
  'SPY (ISCA)',
  'STARCAR',
  'STI',
  'T4S',
  'TELEMONITORADOS (SEM TECNOLOGIA)',
  'TELEMONITORAMEN',
  'TOTALTRAC',
  'TRACER TAG',
  'TRACERTAG',
  'TRACKER',
  'TRACKING SOLUTIONS',
  'Trafegus',
  'X-GLOBAL (ISCA)',
]

export const buonnyTechnologiesOptions = buonnyTechnologies.map(tech => {
  return {
    label: tech,
    value: tech,
  }
})
