import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AutocompleteAsync } from '../../../../../components/form/AutocompleteAsync'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../../config/error/toastError'
import { apiServer } from '../../../../../services/api'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'

interface UpdateCheckinModalProps {
  isOpen: boolean
  onClose: () => void
  motorist_id: string
}

interface UpdateCheckinFormData {
  city: AutocompleteOption
}

const updateCheckinFormSchema = yup.object().shape({
  city: yup.object().required('Campo obrigatório'),
})

export function UpdateCheckinModal({ isOpen, onClose, motorist_id }: UpdateCheckinModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(updateCheckinFormSchema),
  })
  const { errors } = formState

  const handleUpdateCheckin: SubmitHandler<UpdateCheckinFormData> = async data => {
    try {
      await apiServer.post(`/motorist/check-in`, {
        city_id: data.city.value,
        motorist_id,
      })
      onClose()
      toast({
        title: 'Checkin atualizado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleUpdateCheckin)}>
          <ModalHeader>Atualizar checkin do motorista</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="6">
              <AutocompleteAsync
                name="city"
                label="Cidade"
                setValue={setValue}
                loadOptions={searchCitiesByName}
                error={errors.city}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiCheckLine} />}
              isLoading={formState.isSubmitting}
            >
              Atualizar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
