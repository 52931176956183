import { GridItem } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useGlobalVariables } from '../contexts/GlobalVariables'
import { Input } from './form/Input'

type DateRangeProps = {
  title?: string
}
export function DateRange({ title }: DateRangeProps): JSX.Element {
  const { setInitialDate, setFinalDate, initial_date, final_date } = useGlobalVariables()
  const { setValue } = useForm({})
  return (
    <>
      <GridItem colSpan={[12, 6, 3]} mr={2}>
        <Input
          type="date"
          label={title || 'Data inicial'}
          name="initial_date"
          setValue={setValue}
          onChange={e => setInitialDate(e.target.value)}
          initialValue={initial_date}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label={title || 'Data Final'}
          type="date"
          name="final_date"
          setValue={setValue}
          onChange={e => setFinalDate(e.target.value)}
          initialValue={final_date}
        />
      </GridItem>
    </>
  )
}
