import { parseISO } from 'date-fns'
import { FormattedAllFreights } from '../endpoints/freights/getFreights'
import RiskAnalysisType from '../types/RiskAnalysisType'

function getStatusGRDescription(status: string) {
  switch (status) {
    case 'awaiting':
      return {
        status: 'Em analise',
        color: 'orange',
      }
    case 'awaiting_gr':
      return {
        status: 'Em analise',
        color: 'orange',
      }
    case 'approved':
      return {
        status: 'Aprovado',
        color: 'green',
      }
    case 'disapproved':
      return {
        status: 'Reprovado',
        color: 'red',
      }
    case 'to_check':
      return {
        status: 'Pendente',
        color: 'orange',
      }
    default:
      return {
        status: 'Pendente',
        color: 'orange',
      }
  }
}

function getLastGRAnalisis(risk_analysis: RiskAnalysisType[]): RiskAnalysisType {
  if (!risk_analysis) return {} as RiskAnalysisType
  const lastRiskUpdated = risk_analysis.reduce((acc, risk) => {
    if (parseISO(risk.updated_at) > parseISO(acc.updated_at)) {
      return risk
    }
    return acc
  }, risk_analysis[0])
  return lastRiskUpdated
}

const getOperationStatus = (
  freight: FormattedAllFreights,
): {
  status: string
  color?: string
} => {
  const statusOperation = {
    inProspect: 'Prospecção',
    waitingGRAproval: 'Análise: ',
    wayToCollection: '⁠A caminho da coleta',
    waitingLink: 'Aguardando vínculo',
    inToCollection: 'Em coleta',
    inContract: 'Contratado',
    cteIssued: 'CTE emitido',
  }

  const { risk_analysis } = freight

  const inProspect = freight.formatted_status === 'Aberto'
  const inContract = freight.formatted_status === 'Contratado'
  const wayToCollection = freight.formatted_status === 'Saiu para coleta'
  const inToCollection = freight.formatted_status === 'Chegou para carregar'
  const hasMotorist = freight.motorist === null
  const hasRiskAnalysis = !!risk_analysis?.length
  const hasCtes = !!freight.ctes?.length

  if (hasMotorist && hasRiskAnalysis) {
    const lastRiskUpdated = getLastGRAnalisis(risk_analysis || []) as RiskAnalysisType
    const statusUtil = getStatusGRDescription(lastRiskUpdated?.status || '')
    return {
      status: `${statusOperation.waitingGRAproval} ${statusUtil.status}`,
      color: statusUtil.color,
    }
  }
  if (inProspect && !hasRiskAnalysis) {
    return { status: statusOperation.inProspect, color: 'gray.500' }
  }
  if (inContract) {
    return { status: statusOperation.inContract, color: 'green.500' }
  }
  if (wayToCollection) {
    return { status: statusOperation.wayToCollection, color: 'blue.600' }
  }
  if (inToCollection) {
    return { status: statusOperation.inToCollection, color: 'purple' }
  }

  if (hasMotorist && hasCtes) {
    return { status: statusOperation.cteIssued, color: 'red.500' }
  }

  return { status: freight.formatted_status, color: 'orange' }
}

export default getOperationStatus
