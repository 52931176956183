import { QualpCityType } from '../components/LocationManager'

type State = {
  distance?: number
  origin?: QualpCityType
  destination?: QualpCityType
  places: QualpCityType[]
  vehicleCategoryCode: string
  truckCategory?: number
  routes?: any
  stops: JSX.Element[]
  isRoundTrip: boolean
  cargoType: string
  isVehicleComposition: boolean
  isHighPerformance: boolean
  isReturnEmpty: boolean
  axesNumber: number
  isLoadingAnttValue: boolean
  anttValue?: number
  freightId?: string
  shouldUpdateUrl: boolean
  routeResponsePoints?: any[]
  showTolls: boolean
  showFreightTable: boolean
  showManeuvers: boolean
  showTruckScales: boolean
  showStaticImage: boolean
  showLinkToQualp: boolean
  showPrivatePlaces: boolean
  showPolyline: boolean
  showSimplifiedPolyline: boolean
  showUfs: boolean
  showFuelConsumption: boolean
  showLinkToQualpReport: boolean
  fuelPrice: string
  kmPerFuel: string
  maxDistance: string
  includeCategories: boolean
  includeAreas: boolean
  includeContacts: boolean
  includeProducts: boolean
  includeServices: boolean
  isOptimizedRoute: boolean
  optimizedRouteDestination: string
  calculateReturn: boolean
  alternativeRoutes: string
  avoidLocations: boolean
  avoidLocationsKey: string
  typeRoute: string
  vehicleType: string
  vehicleAxis: number | 'all'
  topSpeed: number | null
  retroactiveDate: string
  freightCategory: string | 'all'
  freightLoad: string | 'all'
  freightAxis: number | 'all'
  tollValue?: number
  isBlockedEntity: boolean
  freightType?: string
}

export const initialPlanRouteState: State = {
  distance: undefined,
  origin: undefined,
  destination: undefined,
  places: [],
  vehicleCategoryCode: 'truck',
  truckCategory: undefined,
  routes: undefined,
  stops: [],
  isRoundTrip: false,
  cargoType: '5',
  isVehicleComposition: false,
  isHighPerformance: false,
  isReturnEmpty: false,
  axesNumber: 4,
  isLoadingAnttValue: false,
  anttValue: undefined,
  freightId: undefined,
  shouldUpdateUrl: false,
  routeResponsePoints: [],
  showTolls: true,
  showFreightTable: true,
  showManeuvers: true,
  showTruckScales: true,
  showStaticImage: true,
  showLinkToQualp: true,
  showPrivatePlaces: true,
  showPolyline: true,
  showSimplifiedPolyline: true,
  showUfs: true,
  showFuelConsumption: true,
  showLinkToQualpReport: true,
  fuelPrice: '',
  kmPerFuel: '',
  maxDistance: '',
  includeCategories: false,
  includeAreas: false,
  includeContacts: false,
  includeProducts: false,
  includeServices: false,
  isOptimizedRoute: false,
  optimizedRouteDestination: 'last',
  calculateReturn: false,
  alternativeRoutes: '3',
  avoidLocations: false,
  avoidLocationsKey: '',
  typeRoute: 'efficient',
  vehicleType: 'truck',
  vehicleAxis: 'all',
  topSpeed: null,
  retroactiveDate: '',
  freightCategory: 'all',
  freightLoad: 'all',
  freightAxis: 'all',
  tollValue: undefined,
  isBlockedEntity: false,
  freightType: 'A',
}

type Action =
  | { type: 'SET_DISTANCE'; payload: number }
  | { type: 'SET_ORIGIN'; payload: QualpCityType | undefined }
  | { type: 'SET_DESTINATION'; payload: QualpCityType | undefined }
  | { type: 'SET_PLACES'; payload: QualpCityType[] }
  | { type: 'SET_VEHICLE_CATEGORY_CODE'; payload: string }
  | { type: 'SET_TRUCK_CATEGORY'; payload: number | undefined }
  | { type: 'SET_ROUTES'; payload: any }
  | { type: 'SET_STOPS'; payload: JSX.Element[] }
  | { type: 'TOGGLE_ROUND_TRIP' }
  | { type: 'SET_CARGO_TYPE'; payload: string }
  | { type: 'SET_IS_VEHICLE_COMPOSITION'; payload: boolean }
  | { type: 'SET_IS_HIGH_PERFORMANCE'; payload: boolean }
  | { type: 'SET_IS_RETURN_EMPTY'; payload: boolean }
  | { type: 'SET_AXES_NUMBER'; payload: number }
  | { type: 'SET_IS_LOADING_ANTT_VALUE'; payload: boolean }
  | { type: 'SET_ANTT_VALUE'; payload: number }
  | { type: 'SET_FREIGHT_ID'; payload: string }
  | { type: 'SET_SHOULD_UPDATE_URL'; payload: boolean }
  | { type: 'SET_ROUTE_RESPONSE_POINTS'; payload: any[] }
  | { type: 'SET_SHOW_TOLLS'; payload: boolean }
  | { type: 'SET_SHOW_FREIGHT_TABLE'; payload: boolean }
  | { type: 'SET_SHOW_MANEUVERS'; payload: boolean }
  | { type: 'SET_SHOW_TRUCK_SCALES'; payload: boolean }
  | { type: 'SET_SHOW_STATIC_IMAGE'; payload: boolean }
  | { type: 'SET_SHOW_LINK_TO_QUALP'; payload: boolean }
  | { type: 'SET_SHOW_PRIVATE_PLACES'; payload: boolean }
  | { type: 'SET_SHOW_POLYLINE'; payload: boolean }
  | { type: 'SET_SHOW_SIMPLIFIED_POLYLINE'; payload: boolean }
  | { type: 'SET_SHOW_UFS'; payload: boolean }
  | { type: 'SET_SHOW_FUEL_CONSUMPTION'; payload: boolean }
  | { type: 'SET_SHOW_LINK_TO_QUALP_REPORT'; payload: boolean }
  | { type: 'SET_FUEL_PRICE'; payload: string }
  | { type: 'SET_KM_PER_FUEL'; payload: string }
  | { type: 'SET_MAX_DISTANCE'; payload: string }
  | { type: 'SET_INCLUDE_CATEGORIES'; payload: boolean }
  | { type: 'SET_INCLUDE_AREAS'; payload: boolean }
  | { type: 'SET_INCLUDE_CONTACTS'; payload: boolean }
  | { type: 'SET_INCLUDE_PRODUCTS'; payload: boolean }
  | { type: 'SET_INCLUDE_SERVICES'; payload: boolean }
  | { type: 'SET_IS_OPTIMIZED_ROUTE'; payload: boolean }
  | { type: 'SET_OPTIMIZED_ROUTE_DESTINATION'; payload: string }
  | { type: 'SET_CALCULATE_RETURN'; payload: boolean }
  | { type: 'SET_ALTERNATIVE_ROUTES'; payload: string }
  | { type: 'SET_AVOID_LOCATIONS'; payload: boolean }
  | { type: 'SET_AVOID_LOCATIONS_KEY'; payload: string }
  | { type: 'SET_TYPE_ROUTE'; payload: string }
  | { type: 'SET_VEHICLE_TYPE'; payload: string }
  | { type: 'SET_VEHICLE_AXIS'; payload: number | 'all' }
  | { type: 'SET_TOP_SPEED'; payload: number | null }
  | { type: 'SET_RETROACTIVE_DATE'; payload: string }
  | { type: 'SET_FREIGHT_CATEGORY'; payload: string | 'all' }
  | { type: 'SET_FREIGHT_LOAD'; payload: string | 'all' }
  | { type: 'SET_FREIGHT_AXIS'; payload: number | 'all' }
  | { type: 'SET_TOLL_VALUE'; payload: number }
  | { type: 'SET_IS_BLOCKED_ENTITY'; payload: boolean }
  | { type: 'SET_FREIGHT_TYPE'; payload: string }

export const routePlannerReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DISTANCE':
      return { ...state, distance: action.payload }
    case 'SET_ORIGIN':
      return { ...state, origin: action.payload }
    case 'SET_DESTINATION':
      return { ...state, destination: action.payload }
    case 'SET_PLACES':
      return { ...state, places: action.payload }
    case 'SET_VEHICLE_CATEGORY_CODE':
      return { ...state, vehicleCategoryCode: action.payload }
    case 'SET_TRUCK_CATEGORY':
      return { ...state, truckCategory: action.payload }
    case 'SET_ROUTES':
      return { ...state, routes: action.payload }
    case 'SET_STOPS':
      return { ...state, stops: action.payload }
    case 'TOGGLE_ROUND_TRIP':
      return { ...state, isRoundTrip: !state.isRoundTrip }
    case 'SET_CARGO_TYPE':
      return { ...state, cargoType: action.payload }
    case 'SET_IS_VEHICLE_COMPOSITION':
      return { ...state, isVehicleComposition: action.payload }
    case 'SET_IS_HIGH_PERFORMANCE':
      return { ...state, isHighPerformance: action.payload }
    case 'SET_IS_RETURN_EMPTY':
      return { ...state, isReturnEmpty: action.payload }
    case 'SET_AXES_NUMBER':
      return { ...state, axesNumber: action.payload }
    case 'SET_IS_LOADING_ANTT_VALUE':
      return { ...state, isLoadingAnttValue: action.payload }
    case 'SET_ANTT_VALUE':
      return { ...state, anttValue: action.payload }
    case 'SET_FREIGHT_ID':
      return { ...state, freightId: action.payload }
    case 'SET_SHOULD_UPDATE_URL':
      return { ...state, shouldUpdateUrl: action.payload }
    case 'SET_ROUTE_RESPONSE_POINTS':
      return { ...state, routeResponsePoints: action.payload }
    case 'SET_SHOW_TOLLS':
      return { ...state, showTolls: action.payload }
    case 'SET_SHOW_FREIGHT_TABLE':
      return { ...state, showFreightTable: action.payload }
    case 'SET_SHOW_MANEUVERS':
      return { ...state, showManeuvers: action.payload }
    case 'SET_SHOW_TRUCK_SCALES':
      return { ...state, showTruckScales: action.payload }
    case 'SET_SHOW_STATIC_IMAGE':
      return { ...state, showStaticImage: action.payload }
    case 'SET_SHOW_LINK_TO_QUALP':
      return { ...state, showLinkToQualp: action.payload }
    case 'SET_SHOW_PRIVATE_PLACES':
      return { ...state, showPrivatePlaces: action.payload }
    case 'SET_SHOW_POLYLINE':
      return { ...state, showPolyline: action.payload }
    case 'SET_SHOW_SIMPLIFIED_POLYLINE':
      return { ...state, showSimplifiedPolyline: action.payload }
    case 'SET_SHOW_UFS':
      return { ...state, showUfs: action.payload }
    case 'SET_SHOW_FUEL_CONSUMPTION':
      return { ...state, showFuelConsumption: action.payload }
    case 'SET_SHOW_LINK_TO_QUALP_REPORT':
      return { ...state, showLinkToQualpReport: action.payload }
    case 'SET_FUEL_PRICE':
      return { ...state, fuelPrice: action.payload }
    case 'SET_KM_PER_FUEL':
      return { ...state, kmPerFuel: action.payload }
    case 'SET_MAX_DISTANCE':
      return { ...state, maxDistance: action.payload }
    case 'SET_INCLUDE_CATEGORIES':
      return { ...state, includeCategories: action.payload }
    case 'SET_INCLUDE_AREAS':
      return { ...state, includeAreas: action.payload }
    case 'SET_INCLUDE_CONTACTS':
      return { ...state, includeContacts: action.payload }
    case 'SET_INCLUDE_PRODUCTS':
      return { ...state, includeProducts: action.payload }
    case 'SET_INCLUDE_SERVICES':
      return { ...state, includeServices: action.payload }
    case 'SET_IS_OPTIMIZED_ROUTE':
      return { ...state, isOptimizedRoute: action.payload }
    case 'SET_OPTIMIZED_ROUTE_DESTINATION':
      return { ...state, optimizedRouteDestination: action.payload }
    case 'SET_CALCULATE_RETURN':
      return { ...state, calculateReturn: action.payload }
    case 'SET_ALTERNATIVE_ROUTES':
      return { ...state, alternativeRoutes: action.payload }
    case 'SET_AVOID_LOCATIONS':
      return { ...state, avoidLocations: action.payload }
    case 'SET_AVOID_LOCATIONS_KEY':
      return { ...state, avoidLocationsKey: action.payload }
    case 'SET_TYPE_ROUTE':
      return { ...state, typeRoute: action.payload }
    case 'SET_VEHICLE_TYPE':
      return { ...state, vehicleType: action.payload }
    case 'SET_VEHICLE_AXIS':
      return { ...state, vehicleAxis: action.payload }
    case 'SET_TOP_SPEED':
      return { ...state, topSpeed: action.payload }
    case 'SET_RETROACTIVE_DATE':
      return { ...state, retroactiveDate: action.payload }
    case 'SET_FREIGHT_CATEGORY':
      return { ...state, freightCategory: action.payload }
    case 'SET_FREIGHT_LOAD':
      return { ...state, freightLoad: action.payload }
    case 'SET_FREIGHT_AXIS':
      return { ...state, freightAxis: action.payload }
    case 'SET_TOLL_VALUE':
      return { ...state, tollValue: action.payload }
    case 'SET_IS_BLOCKED_ENTITY':
      return { ...state, isBlockedEntity: action.payload }
    case 'SET_FREIGHT_TYPE':
      return { ...state, freightType: action.payload }
    default:
      return state
  }
}
