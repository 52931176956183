import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type IsBlockEntityResponse = {
  freight_id?: string
  motorist_id?: string
  vehicle_id?: string
  trailer_id?: string
  driver_license_id?: string
  vehicle_owner_id?: string
  trailer_owner_id?: string
}

export const isBlockEntity = async (params: IsBlockEntityResponse): Promise<boolean> => {
  if (Object.values(params).every(value => value === null || value === undefined)) {
    return false
  }
  if (Object.keys(params).length === 0) {
    return false
  }

  const { data: response } = await apiServer.get<ResponseApiType<boolean>>('/blocked-entities/verify', {
    params,
  })
  return response.data
}

export function useIsBlockEntity(params: IsBlockEntityResponse): UseQueryResult<boolean> {
  return useQuery(['is-block-entity', params], () => isBlockEntity(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
