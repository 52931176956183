import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Ability } from '../../../../../../components/ability'
import { AttachmentIcon } from '../../../../../../components/AttachmentIcon'
import { ReloadButton } from '../../../../../../components/ReloadButton'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { useAttachments } from '../../../../../../services/endpoints/freights/getAttachments'
import { FormattedOneFreight } from '../../../../../../services/endpoints/freights/getOneFreight'
import { FreightAttachmentsDocumentEnum } from '../../../../../../services/types/EnumTypes'
import { dispatch } from '../../../../../../store'
import { deleteAttachmentFreight } from '../../../../../../store/slices/freightSlice'
import { AddOrEditStubModal } from '../modals/AddOrEditStubModal'

interface ListStubProps {
  freight: FormattedOneFreight
  bg: string
}

export function ListOldStubs({ freight, bg }: ListStubProps): JSX.Element | null {
  const toast = useToast()
  const [idToEdit, setIdToEdit] = useState<string>()
  const {
    isOpen: isOpenCreateOrUpdateCte,
    onClose: onCloseCreateOrUpdateCte,
    onOpen: onOpenCreateOrUpdateCte,
  } = useDisclosure()

  const {
    data: attachments,
    refetch: attachmentsRefetch,
    isLoading: isLoadAttachments,
    isFetching,
    error: errorListAttachments,
    isError: isErrorListAttachments,
  } = useAttachments({
    freight_id: freight.id,
    document: FreightAttachmentsDocumentEnum.STUB,
  })

  useEffect(() => {
    if (isErrorListAttachments) {
      toastError({ toast, error: errorListAttachments })
    }
  }, [isErrorListAttachments, errorListAttachments, toast])

  const handleDeleteAttachment = async (idAttach: string) => {
    dispatch(deleteAttachmentFreight(idAttach))
    attachmentsRefetch()
    queryClient.invalidateQueries('attachments')
  }
  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Flex justify="space-between" align="center">
        <Flex justify="space-between" align="center" gridGap={2}>
          <Heading size="lg" fontWeight="normal">
            Canhotos
          </Heading>
          <ReloadButton
            isFetching={isFetching || isLoadAttachments}
            refetch={() => {
              attachmentsRefetch()
              queryClient.invalidateQueries('attachments')
            }}
          />
        </Flex>
        <Stack direction={['column', 'row']}>
          {/* <Button
            size="sm"
            colorScheme="blue"
            onClick={onOpenCreateOrUpdateCte}
          >
            Permissões
          </Button> */}
          <Button size="sm" colorScheme="blue" onClick={onOpenCreateOrUpdateCte}>
            + Novo
          </Button>
        </Stack>
      </Flex>

      <>
        <SimpleGrid minChildWidth="300px" spacing="6" mt="2" columns={2}>
          <Box>
            <Wrap spacing="4" alignItems="center" mb="4">
              <Divider my="2" />
              <WrapItem flexDirection="column" flex="1">
                {isLoadAttachments ? (
                  <Flex>
                    <Spinner />
                  </Flex>
                ) : attachments && attachments.length > 0 ? (
                  <Table size="sm" my="1" variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Data</Th>
                        <Th>Usuário</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {attachments.map(attach => (
                        <Tr key={attach?.id}>
                          {attach && (
                            <>
                              {attach.delivery_receipt_at ? (
                                <Td>{attach.formatted_delivery_receipt_at}</Td>
                              ) : (
                                <Td />
                              )}
                              <Td>{attach.creator?.name}</Td>

                              <Td>
                                <HStack>
                                  <Ability module="operation" action="update-freight-attachment">
                                    <Button
                                      onClick={() => {
                                        setIdToEdit(attach.id)
                                        onOpenCreateOrUpdateCte()
                                      }}
                                      size="xs"
                                      colorScheme="orange"
                                    >
                                      Editar
                                    </Button>
                                  </Ability>

                                  {attach.attachment_file_url && (
                                    <AttachmentIcon
                                      attachment_file_url={attach.attachment_file_url}
                                      attach_id={attach.id}
                                      handleDeleteAttachment={handleDeleteAttachment}
                                    />
                                  )}
                                </HStack>
                              </Td>
                            </>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle mr={2}>
                      Não há nenhum canhoto anexado! Adicione um canhoto antes de finalizar o frete!
                    </AlertTitle>
                  </Alert>
                )}
              </WrapItem>
            </Wrap>
          </Box>
        </SimpleGrid>
      </>

      <AddOrEditStubModal
        isOpen={isOpenCreateOrUpdateCte}
        freight_status={freight.status}
        attachId={idToEdit}
        onClose={() => {
          setIdToEdit(undefined)
          onCloseCreateOrUpdateCte()
          attachmentsRefetch()
          queryClient.invalidateQueries('attachments')
        }}
      />
    </Box>
  )
}
