import { Alert, AlertIcon, AlertTitle, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { useCreateAttachmentToMotorist } from '../../../../services/endpoints/motorists/motorist-attachments/createAttachment'
import { useUpdateAttachmentToMotorist } from '../../../../services/endpoints/motorists/motorist-attachments/updateAttachment'
import { MotoristType, ResponseApiType } from '../../../../services/types'
import { addMoreOneParamToUrl } from '../../../../services/utils/format-url/addMoreOneParamToUrl'
import getOnlyNumbers from '../../../../services/utils/getOnlyNumbers'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setMotoristId } from '../../../../store/slices/freightPendenciesSlice'
import {
  setDriverLicenseFile,
  setDriverLicenseImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCnhSlice'
import { DrawerWrapper } from '../components/DrawerWrapper'
import { MotoristSearch } from '../components/MotoristSearch'
import {
  MotoristForm,
  MotoristFormSchema,
  MotoristFormValues,
  mototistInitialValues,
} from '../../../../components/forms/motorist'
import { createCNHDefaultValues, createMotoristDefaultValues, splitPersonsMotorist } from '../utils'
import UploadCNHModal from './UploadOwnerCnh.modal'

type MotoristFormProps = {
  isOpen: boolean
  onClose: () => void
}

const MotoristModalForm = ({ isOpen, onClose }: MotoristFormProps): JSX.Element => {
  const { motorist } = useAppSelector(state => state.freightPendenciesSlice)
  const [motoristFound, setMotoristAlreadyFound] = useState<MotoristType | null>()
  const { driverLicenseFile, isNewImage } = useAppSelector(state => state.ocrCnhSlice)
  const toast = useToast()
  const dispatch = useAppDispatch()

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }
  const showErrorToast = (message: string) => {
    toast({
      status: 'error',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const createAttachment = useCreateAttachmentToMotorist({
    onSuccess: () => {
      showSuccessToast('Anexo salvo com sucesso!')
      dispatch(setDriverLicenseFile({} as File))
      dispatch(setIsNewImage(false))
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const updateAttachment = useUpdateAttachmentToMotorist({
    onSuccess: () => {
      showSuccessToast('Anexo atualizado com sucesso!')
      dispatch(setDriverLicenseFile({} as File))
      dispatch(setIsNewImage(false))
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const methods = useForm<MotoristFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...mototistInitialValues,
    } as MotoristFormValues,
    resolver: yupResolver(MotoristFormSchema),
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = methods

  const { data: blockedEntity } = useIsBlockEntity({
    motorist_id: motoristFound?.id,
  })

  const handleAttachmentUpload = async (motorist_id: string) => {
    if (driverLicenseFile.name) {
      const attach = motoristFound?.attachments?.find(item => item.type === 'cnh')
      if (attach?.id) {
        await updateAttachment.mutateAsync({
          id: attach.id,
          attachment_file: driverLicenseFile,
        })
      } else {
        await createAttachment.mutateAsync({
          attachment_file: driverLicenseFile,
          motorist_id,
          name: 'CNH do motorista',
          type: 'cnh',
        })
      }
    }
  }

  const createMotoristPayload = (formValues: MotoristFormValues, motorist_id?: string) => ({
    cpf: getOnlyNumbers(formValues.cpf),
    name: formValues.name,
    phone: getOnlyNumbers(formValues.phone),
    mother_name: formValues.mother_name,
    father_name: formValues.father_name,
    rg: formValues.register_doc,
    rg_dispatcher: formValues.rg_dispatcher.toUpperCase(),
    rg_uf: formValues.rg_uf,
    rg_dispatch_date: formValues.rg_dispatch_date,
    birth: formValues.birth,
    email: formValues.email || undefined,
    zipcode: getOnlyNumbers(formValues.address_zipcode),
    address: formValues.address,
    address_number: formValues.address_number,
    district: formValues.address_neighborhood,
    address_city_id: formValues.address_city.value,
    birth_state: formValues.address_state_uf,
    bank: undefined,
    driver_license: {
      motorist_id,
      category: formValues.driver_license_category,
      dispatch_date: formValues.driver_license_dispatch_date,
      dispatcher_uf: formValues.driver_license_dispatcher_uf,
      dispatcher: formValues.driver_license_dispatcher.toUpperCase(),
      insurance_number: formValues.driver_license_security_code,
      first_dispatch_date: formValues.driver_license_first_dispatch_date,
      protocol_number: formValues.driver_license_protocol_number,
      register_number: formValues.driver_license,
      validity: formValues.driver_license_validity,
    },
  })

  const updateMotorist = async (
    formValues: MotoristFormValues,
    motorist_id: string,
    contactsRefPerson1: any,
    contactsRefPerson2: any,
    contactsRefProfessional1: any,
    contactsRefProfessional2: any,
  ) => {
    await apiServer.put(`/update-motorist/${motorist_id}`, {
      ...createMotoristPayload(formValues, motorist_id),
      motorist_contacts: [
        {
          ...(contactsRefPerson1?.id && { id: contactsRefPerson1.id }),
          motorist_id,
          name: formValues.name_contacts_ref_person_1,
          phone: getOnlyNumbers(formValues.phone_ref_person_1),
          type: formValues.type_contacts_ref_person_1,
        },
        {
          ...(contactsRefPerson2?.id && { id: contactsRefPerson2.id }),
          motorist_id,
          name: formValues.name_contacts_ref_person_2,
          phone: getOnlyNumbers(formValues.phone_ref_person_2),
          type: formValues.type_contacts_ref_person_2,
        },
        {
          ...(contactsRefProfessional1?.id && { id: contactsRefProfessional1.id }),
          motorist_id,
          name: formValues.name_contacts_ref_professional_1,
          phone: getOnlyNumbers(formValues.phone_ref_professional_1),
          type: 'COMERCIAL',
        },
        {
          ...(contactsRefProfessional2?.id && { id: contactsRefProfessional2.id }),
          motorist_id,
          name: formValues.name_contacts_ref_professional_2,
          phone: getOnlyNumbers(formValues.phone_ref_professional_2),
          type: 'COMERCIAL',
        },
      ],
    })
  }

  const onSubmit: SubmitHandler<MotoristFormValues> = async data => {
    if (blockedEntity) {
      showErrorToast('Edição bloqueada. Solicite correção da análise de risco.')
      return
    }
    const formValues = data

    if (motoristFound) {
      const { contactsRefPerson1, contactsRefPerson2, contactsRefProfessional1, contactsRefProfessional2 } =
        splitPersonsMotorist(motoristFound)
      try {
        await updateMotorist(
          formValues,
          motoristFound.id,
          contactsRefPerson1,
          contactsRefPerson2,
          contactsRefProfessional1,
          contactsRefProfessional2,
        )
        showSuccessToast('Motorista atualizado com sucesso!')
        addMoreOneParamToUrl('motorist_id', motoristFound.id)
        dispatch(setMotoristId(motoristFound.id))
        await handleAttachmentUpload(motoristFound.id)
        queryClient.invalidateQueries('motorist')
        onClose()
      } catch (error) {
        toastError({ toast, error })
      } finally {
        queryClient.invalidateQueries('check-pendencies')
      }
    } else {
      try {
        const { data: response } = await apiServer.post<ResponseApiType<MotoristType>>(`/create-motorist`, {
          ...createMotoristPayload(formValues),
          motorist_contacts: [
            {
              name: data.name_contacts_ref_person_1,
              phone: getOnlyNumbers(data.phone_ref_person_1),
              type: data.type_contacts_ref_person_1,
            },
            {
              name: data.name_contacts_ref_person_2,
              phone: getOnlyNumbers(data.phone_ref_person_2),
              type: data.type_contacts_ref_person_2,
            },
            {
              name: data.name_contacts_ref_professional_1,
              phone: getOnlyNumbers(data.phone_ref_professional_1),
              type: 'COMERCIAL',
            },
            {
              name: data.name_contacts_ref_professional_2,
              phone: getOnlyNumbers(data.phone_ref_professional_2),
              type: 'COMERCIAL',
            },
          ],
        })
        const { id: motorist_id } = response.data
        showSuccessToast('Motorista cadastrado com sucesso!')
        addMoreOneParamToUrl('motorist_id', motorist_id)
        dispatch(setMotoristId(motorist_id))
        await handleAttachmentUpload(motorist_id)
        onClose()
      } catch (error) {
        toastError({ toast, error })
      } finally {
        queryClient.invalidateQueries('check-pendencies')
      }
    }
  }

  const handleClose = () => {
    if (isDirty) {
      toast({
        title: 'Alterações não salvas',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (driverLicenseFile.name && isNewImage) {
      toast({
        title: 'Alterações não salvas, nova imagem de CNH',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    onClose()
  }

  const createInitialValues = useCallback(
    (data?: MotoristType) => {
      if (data) {
        const { attachments } = data
        const attach = attachments?.find(item => item.type === 'cnh')
        dispatch(setDriverLicenseImg(attach?.attachment_file_url ? attach?.attachment_file_url : ''))
        reset(createMotoristDefaultValues(data))
      }
    },
    [dispatch, reset],
  )

  useEffect(() => {
    if (motorist) {
      createInitialValues(motorist)
      setMotoristAlreadyFound(motorist)
    }
  }, [motorist, createInitialValues])

  return (
    <DrawerWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onForceClose={() => {
        onClose()
        dispatch(setIsNewImage(false))
        dispatch(setDriverLicenseFile({} as File))
      }}
      title="Cadastrar motorista"
      onSave={() => handleSubmit(onSubmit)()}
      isSubmitting={isSubmitting}
      showForceClose={isDirty}
    >
      <MotoristSearch
        onSelectFound={data => {
          if (data) {
            createInitialValues(data)
            setMotoristAlreadyFound(data)
          }
        }}
      />
      <UploadCNHModal
        onSaveInformations={CNH => {
          reset({ ...methods.getValues(), ...createCNHDefaultValues(CNH) })
        }}
        onMotoristFound={data => {
          if (data) {
            createInitialValues(data)
            setMotoristAlreadyFound(data)
          }
        }}
      />
      {blockedEntity && (
        <Alert variant="subtle" status="error" borderRadius="md">
          <AlertIcon />
          <AlertTitle mr={2} fontSize="sm">
            Edição bloqueada. Solicite correção da análise de risco
          </AlertTitle>
        </Alert>
      )}
      <FormProvider {...methods}>
        <MotoristForm
          disabled={!!blockedEntity}
          onMotoristAlreadyFound={data => {
            if (data) {
              setMotoristAlreadyFound(data)
              reset(createMotoristDefaultValues(data))
            }
          }}
        />
      </FormProvider>
    </DrawerWrapper>
  )
}

export default MotoristModalForm
