import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from '@chakra-ui/react'
import React from 'react'

type UIRadioGroupProps = {
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  options?: { label: string; value: string | number; disabled?: boolean }[]
} & Omit<RadioGroupProps, 'children'>

export const UIRadioGroup = ({
  label,
  isError,
  errorMessage,
  helperText,
  isRequired,
  options,
  ...props
}: UIRadioGroupProps): JSX.Element => {
  return (
    <FormControl isInvalid={isError} isRequired={isRequired}>
      {label && (
        <FormLabel fontSize="14" mb="1.5">
          {label}
        </FormLabel>
      )}
      <RadioGroup {...props} colorScheme="orange" focusBorderColor="orange.500">
        <Stack direction="row">
          {options?.map(option => (
            <Radio key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}
