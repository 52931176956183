import { ListVehicle } from '../../../../../components/card-infos/ListVehicle'

interface MotoristVehicleProps {
  motorist_id: string
  bg: string
}

export function MotoristVehicle({ motorist_id, bg }: MotoristVehicleProps): JSX.Element {
  return <ListVehicle bg={bg} motorist_id={motorist_id} />
}
