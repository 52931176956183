import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

interface motoristsTypeReturn {
  id: string
  name: string
  cpf: string
}

export async function searchMotoristByName(name?: string): Promise<AutocompleteOption[]> {
  if (name) {
    const { data: res } = await apiServer.get<ResponseApiType<motoristsTypeReturn[]>>('/motorist/search', {
      params: {
        name,
      },
    })

    return res.data.map(motorist => {
      const label = motorist.name
      const value = motorist.id
      const { cpf } = motorist

      return { label, value, cpf }
    })
  }
  return []
}
