import { useColorModeValue } from '@chakra-ui/react'
import 'leaflet/dist/leaflet.css'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../layout'
import { ClientInfo } from './cards/client-info'
import { HistoryClientMonthlyNps } from './cards/history-client-monthly-nps'

interface RequestParams {
  client_id: string
  contactByClient_id: string
}

export function ShowClient(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { client_id } = useParams<RequestParams>()

  return (
    <Layout>
      <ClientInfo client_id={client_id} bg={bg} />
      <HistoryClientMonthlyNps client_id={client_id} bg={bg} />
    </Layout>
  )
}
