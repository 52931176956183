export function colorStatusFreight(status: string): string {
  if (status === 'Contratado') {
    return 'gray.500'
  }
  if (status === 'Saiu para coleta') {
    return 'green.500'
  }
  if (status === 'Chegou para carregar') {
    return 'blue.600'
  }
  if (status === 'Começou a viagem') {
    return 'purple'
  }
  if (status === 'Chegou para descarregar') {
    return 'yellow.500'
  }
  if (status === 'Entrega Finalizada') {
    return 'red.500'
  }
  if (status === 'Aberto') {
    return 'blue.600'
  }
  return 'white'
}
