import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, UserType } from '../../types'

type GetUsersResponse = {
  total: number
  data: UserType[]
}
interface FilterUsersData {
  orderByName?: 'asc' | 'desc'
  orderByDate?: 'asc' | 'desc'
  name?: string
  email?: string
  phone?: string
  page?: number
  per_page?: number
}
export async function fetchUsers(params: FilterUsersData): Promise<GetUsersResponse> {
  const { data: dataUser } = await apiServer.get<ResponseApiType<GetUsersResponse>>('users', {
    params,
  })

  const {
    data: { total, data },
  } = dataUser

  const users = data.map(user => ({
    id: user.id,
    name: user.name,
    email: user.email,
    role: user.role,
    created_at: new Date(user.created_at).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }),
  }))

  return { total, data: users }
}

export function useGetUsers(params: FilterUsersData): UseQueryResult<GetUsersResponse> {
  return useQuery(['users', params], () => fetchUsers(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
