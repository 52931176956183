import * as yup from 'yup'

const currentYear = new Date().getFullYear()
export const vehicleFormValidation = yup.object().shape({
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  license_uf: yup.string().required('Campo obrigatório'),
  axes: yup.number().required('Campo obrigatório'),
  renavam: yup
    .string()
    .min(9, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .max(11, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .required('Campo obrigatório'),
  vehicle_category_id: yup.string().required('Campo obrigatório'),
  color: yup.string().required('Campo obrigatório'),
  vehicle_body_id: yup.string().required('Campo obrigatório'),
  brand: yup.string().required('Campo obrigatório'),
  model: yup.string().required('Campo obrigatório'),
  chassi: yup.string().required('Campo obrigatório'),
  release_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  model_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  antt: yup.string().required('Campo obrigatório'),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
})

export const trailerSharedForm = {
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  license_uf: yup.string().required('Campo obrigatório'),
  axes: yup.number().required('Campo obrigatório'),
  renavam: yup
    .string()
    .min(9, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .max(11, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .required('Campo obrigatório'),
  color: yup.string().required('Campo obrigatório'),
  vehicle_body_id: yup.string().required('Campo obrigatório'),
  brand: yup.string().required('Campo obrigatório'),
  model: yup.string().required('Campo obrigatório'),
  chassi: yup.string().required('Campo obrigatório'),
  release_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  model_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  antt: yup.string().required('Campo obrigatório'),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  has_tracker: yup.boolean(),
  has_insurance: yup.boolean(),
  city_id: yup.object().shape({}).required('Campo obrigatório'),
}
export const trailerFormValidation = yup.object().shape(trailerSharedForm)
