import { Button, Icon, Link, Stack, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { GrDocumentPdf } from 'react-icons/gr'
import { RiFileCopyLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useGetPdfMdfeDatamex } from '../../../services/endpoints/datamex/getPdfMdfeDatamex'
import { MdfeType } from '../../../services/types'
import { copyToClipboard } from '../../../services/utils/copyToClipboard'

interface ButtonsActionsProps {
  mdfe: MdfeType
}
export function ButtonsActions({ mdfe }: ButtonsActionsProps): JSX.Element {
  const toast = useToast()
  const {
    mutateAsync: getPdfMdfe,
    isLoading: isLoadingPdfUrl,
    error: errorMdfePdf,
    isError: isErrorMdfePdf,
  } = useGetPdfMdfeDatamex({
    onSuccess: () => {
      queryClient.invalidateQueries('list_mdfes_by_freight_id')
    },
  })

  useEffect(() => {
    if (isErrorMdfePdf) {
      toastError({ toast, error: errorMdfePdf })
    }
  }, [isErrorMdfePdf, errorMdfePdf, toast])

  return (
    <>
      {mdfe && (
        <Stack direction="row" spacing={['2', '2']}>
          <Tooltip placement="top" hasArrow label="Copiar chave">
            <Button
              size="xs"
              colorScheme="green"
              onClick={() => {
                copyToClipboard(mdfe.key)
                toast({
                  title: 'Chave copiada com sucesso!',
                  status: 'success',
                  isClosable: true,
                  position: 'top',
                })
              }}
            >
              <Icon as={RiFileCopyLine} fontSize={16} />
            </Button>
          </Tooltip>

          <Tooltip label="Baixar arquivo em pdf" hasArrow placement="top">
            {mdfe.pdf_file_url ? (
              <Button
                size="xs"
                colorScheme="orange"
                as={Link}
                href={mdfe.pdf_file_url}
                target="_blank"
                rel="noreferrer"
              >
                <Icon as={GrDocumentPdf} fontSize="18" />
              </Button>
            ) : (
              <Button
                size="xs"
                colorScheme="orange"
                isLoading={isLoadingPdfUrl}
                onClick={async () => {
                  try {
                    const { data: pdfUrl } = await getPdfMdfe(mdfe.key)
                    if (pdfUrl.data) window.open(pdfUrl.data, '_blank')
                  } catch (error) {
                    toastError({ toast, error })
                  }
                }}
              >
                <Icon as={GrDocumentPdf} fontSize="18" />
              </Button>
            )}
          </Tooltip>
        </Stack>
      )}
    </>
  )
}
