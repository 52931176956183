import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface ResponseCreatedMotoristsReport {
  total: number
  data: {
    creator_id: null | string
    name: string
    count: number
    day: string
  }[]
}

export async function fetchReportMotorists(
  initialDateMotorists: string,
  finalDateMotorists: string,
  unpaged?: boolean,
): Promise<ResponseCreatedMotoristsReport> {
  const { data: response } = await apiServer.get<ResponseApiType<ResponseCreatedMotoristsReport>>(
    '/reports/motorists/count-created-by-operator',
    {
      params: {
        initial_date: `${initialDateMotorists}T00:00:00`,
        final_date: `${finalDateMotorists}T23:59:59`,
        unpaged,
      },
    },
  )

  const { data } = response.data
  const { total } = response.data

  const reports = data.map(report => {
    return {
      ...report,
      day: format(new Date(String(report.day)), 'dd/MM/yyyy'),
    }
  })

  return { total, data: reports }
}

export function useReportMotorists(
  initialDateMotorists: string,
  finalDateMotorists: string,
): UseQueryResult<ResponseCreatedMotoristsReport> {
  return useQuery(
    ['motorists_report', initialDateMotorists, finalDateMotorists],
    () => fetchReportMotorists(initialDateMotorists, finalDateMotorists),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  )
}
