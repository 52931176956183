import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AutocompleteAsync, AutocompleteOption } from '../../../components/form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useGetClientContact } from '../../../services/endpoints/clients/client-contacts/getContactFromClient'
import { usePutContactByClient } from '../../../services/endpoints/clients/client-contacts/putContactByClient'
import { searchClientGroup } from '../../../services/endpoints/clients/getClients'
import { ContactByClientForm, ContactByClientFormSchema } from '../ContactByClientForm'

interface UpdateContactByClientModalProps {
  isOpen: boolean
  onClose: () => void
  contactByClient_id: string
}

interface ClientContactForm {
  client_contact: {
    id: string
    name?: string
    role?: string
    email?: string
    phone?: string
    phone2?: string
    client_id?: string
    last_monthly_nps_at?: string
    client_business_group_id: AutocompleteOption
  }
}

export function UpdateContactByClientModal({
  isOpen,
  onClose,
  contactByClient_id,
}: UpdateContactByClientModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(ContactByClientFormSchema),
  })
  const { isSubmitting } = formState
  const updateContactByClient = usePutContactByClient({
    onSuccess: () => {
      queryClient.invalidateQueries('contact_by_client')
    },
  })

  const { data: contactData } = useGetClientContact(contactByClient_id)

  const handleUpdateContactByClient: SubmitHandler<ClientContactForm> = async data => {
    try {
      await updateContactByClient.mutateAsync({
        ...data.client_contact,
        client_business_group_id: data.client_contact?.client_business_group_id?.value?.toString(),
        id: contactByClient_id,
      })
      onClose()
      toast({
        title: 'Cliente editado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent p="5">
        <Heading size="lg" fontWeight="normal">
          Editar Solicitante
        </Heading>

        <Divider my="6" />

        <Box as="form" onSubmit={handleSubmit(handleUpdateContactByClient)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="4">
            <ContactByClientForm formState={formState} setValue={setValue} contactData={contactData} />
            <GridItem colSpan={[12, 6]}>
              <AutocompleteAsync
                label="Grupo empresarial"
                name="client_contact.client_business_group_id"
                setValue={setValue}
                loadOptions={searchClientGroup}
                error={formState.errors.client_contact?.client_business_group_id}
                initialValue={
                  contactData?.client_business_group
                    ? {
                        label: contactData?.client_business_group?.name,
                        value: contactData?.client_business_group?.id,
                      }
                    : undefined
                }
              />
            </GridItem>
          </Grid>

          <Flex mt="8" justify="flex-end">
            <HStack spacing="4">
              <Button onClick={onClose}>Cancelar</Button>

              <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
