import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiLinksLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../../../components/form/Input'
import { InputMask } from '../../../../../../components/form/InputMask'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { apiServer } from '../../../../../../services/api'
import { RiskAnalysisOneFormatted } from '../../../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { usePatchRiskAnalysis } from '../../../../../../services/endpoints/risk-analysis/patchRiskAnalysis'
import { validateDate } from '../../../../../../services/utils/dates/isValidDate'
import { useAppDispatch, useAppSelector } from '../../../../../../store'
import { linkMotorist } from '../../../../../../store/slices/freightSlice'

interface LinkMotoristToFreightProps {
  riskAnalysis: RiskAnalysisOneFormatted
  onClose: () => void
}

interface LinkMotoristToFreightFormData {
  delivered_cargo_at: string
  operator_phone: string
  client_phone: string
  client_email: string
}

const linkMotoristToFreightFormSchema = yup.object().shape({
  delivered_cargo_at: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
  operator_phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  client_phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  client_email: yup.string().email('E-mail inválido'),
})

export function LinkMotoristToFreight({ riskAnalysis, onClose }: LinkMotoristToFreightProps): JSX.Element {
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const toast = useToast()
  const { motorist_id, vehicle_id, freight_id } = riskAnalysis
  const dispatch = useAppDispatch()
  const { freight } = useAppSelector(state => state.freightSlice)
  const [reason, setReason] = useState<string>()

  const handleCancelRiskAnalysis = async () => {
    await mutateAsyncChangeStatus({
      id: riskAnalysis.id,
      status: 'canceled',
      reason,
    })
  }

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(linkMotoristToFreightFormSchema),
  })
  const { errors } = formState

  const handleLinkMotoristToFreight: SubmitHandler<LinkMotoristToFreightFormData> = async data => {
    queryClient.invalidateQueries('freight')
    try {
      await apiServer.put(`/update-freight/${freight_id}`, {
        delivered_cargo_at: data.delivered_cargo_at,
      })
      queryClient.invalidateQueries('freight')
    } catch (error) {
      toastError({ toast, error })
    }

    const result = await dispatch(linkMotorist({ freight_id, motorist_id, vehicle_id }))
    toastError({ toast, error: result })

    try {
      await apiServer.put(`/update-client/${freight?.client_id}`, {
        name: freight?.client.name,
        cnpj: freight?.client.cnpj,
        main_activity: freight?.client.main_activity,
        phone: data.client_phone,
        email: data.client_email,
      })
    } catch (error) {
      toastError({ toast, error })
    }

    if (riskAnalysis.status === 'A Checar') {
      toast({
        title:
          'Motorista vinculado, mas com pendências de checagem e bloqueio de emissão de documentos até que a análise seja concluída!',
        status: 'warning',
        position: 'top-right',
        duration: 13000,
        isClosable: true,
      })
    } else {
      toast({
        title: 'Motorista vinculado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      toast({
        title: 'Confira se o valor da carga está correto para poder prosseguir nas próximas fases do frete!',
        status: 'warning',
        position: 'top-right',
        duration: 20000,
        isClosable: true,
      })
    }
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(handleLinkMotoristToFreight)}>
      <Heading fontWeight="normal" size="md" mb="6" color="gray.500">
        Para vincular o motorista ao frete você precisa preencher os dados abaixo
      </Heading>
      {riskAnalysis.status === 'A Checar' && (
        <Alert
          mb="2"
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon mr={0} />
          <AlertTitle mt={4} fontSize="lg">
            Atenção
          </AlertTitle>
          <AlertDescription mt="2">
            O motorista está em status de checagem. Poderá ser vinculado, mas não será possível emitir
            documentos até que a análise de risco esteja completa!
          </AlertDescription>
        </Alert>
      )}
      <Stack spacing="4">
        <SimpleGrid minChildWidth="300px" spacing="4">
          <InputMask
            isRequired
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            name="operator_phone"
            label={`Telefone do operador(a) ${freight?.creator.name.split(' ')[0]}`}
            setValue={setValue}
            error={errors.operator_phone}
            initialValue={freight?.creator.phone}
          />
          <InputMask
            isRequired
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            name="client_phone"
            label={`Telefone do cliente ${freight?.client.name.toUpperCase().split(' ')[0]}`}
            setValue={setValue}
            initialValue={freight?.client.phone}
            error={errors.client_phone}
          />
          {/* {phoneFormatted && (
            <AutocompleteMulti
              name="client_phone"
              label="Telefone do cliente"
              setValue={setValue}
              options={phoneFormatted}
              error={errors.client_phone}
              initialValue={phoneFormatted}
            />
          )} */}
          <Input
            isRequired
            type="datetime-local"
            step="60"
            name="delivered_cargo_at"
            label="Previsão para entrega da carga"
            error={errors.delivered_cargo_at}
            setValue={setValue}
            initialValue={freight?.formatted_delivered_cargo_at}
          />
          <Input
            name="client_email"
            label="Email do cliente"
            setValue={setValue}
            error={errors.client_email}
            initialValue={freight?.client.email}
          />
          {riskAnalysis.status === 'A Checar' && (
            <>
              <Text>Para cancelar informe o motivo</Text>
              <Textarea onChange={e => setReason(e.target.value)} />
            </>
          )}
        </SimpleGrid>
        {/* <Switch
          name="should_notificate"
          label="Enviar notificação"
          setValue={setValue}
          error={errors.should_notificate}
        /> */}
      </Stack>

      <HStack spacing="4" mt="4" py="2" justify="flex-end">
        {riskAnalysis.status === 'A Checar' && (
          <Button
            colorScheme="red"
            isDisabled={!reason}
            isLoading={isLoadingChangeStatus}
            onClick={() => {
              handleCancelRiskAnalysis()
            }}
          >
            Cancelar análise
          </Button>
        )}
        <Button
          type="submit"
          colorScheme="green"
          leftIcon={<Icon as={RiLinksLine} />}
          isLoading={formState.isSubmitting}
        >
          Vincular
        </Button>
      </HStack>
    </form>
  )
}
