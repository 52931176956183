import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleType } from '../../types'

type Filters = {
  columnValue: string
  columnName: string
}

export interface VehicleResponseFormData extends VehicleType {
  formatted_has_insurance: string
  formatted_has_tracker: string
}

export async function fetchVehicleByColumn({
  columnValue,
  columnName,
}: Filters): Promise<VehicleResponseFormData | undefined> {
  if (columnValue && columnValue !== 'undefined' && columnName && columnName !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<VehicleResponseFormData>>(
      `/find-vehicle`,
      {
        params: { columnName, columnValue },
      },
    )
    const { data } = response
    const vehicle = {
      ...data,
      formatted_has_insurance: data.has_insurance ? 'Sim' : 'Não',
      formatted_has_tracker: data.has_tracker ? 'Sim' : 'Não',
      vehicle_category_id: data.vehicle_category?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_category?.id,
      },
      vehicle_body_id: data.vehicle_body?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_body.id,
      },
    } as VehicleResponseFormData
    return vehicle
  }
  return undefined
}

export function useGetVehicleByColumn(input: Filters): UseQueryResult<VehicleResponseFormData> {
  return useQuery(['find-vehicle', input], () => fetchVehicleByColumn(input), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: true,
    retry: true,
  })
}
