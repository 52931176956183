import {
  Box,
  BoxProps,
  Button,
  Flex,
  SimpleGrid,
  SimpleGridProps,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { AiOutlineLink } from 'react-icons/ai'
import { BsRecordCircleFill } from 'react-icons/bs'
import { IoMdAlert, IoMdCheckmarkCircle } from 'react-icons/io'
import AnttModalForm from '../modals/Antt.modal'
import BuonnyModalForm from '../modals/Buonny.modal'
import DatamaxModalForm from '../modals/Datamax.modal'
import MotoristModalForm from '../modals/Motorist.modal'
import OwnerModalForm from '../modals/Owner.modal'
import TrailerModalForm from '../modals/Trailer.modal'
import TrailerOwnerForm from '../modals/TrailerOwner.modal'
import VehicleModalForm from '../modals/Vehicle.modal'

type PedenciesStepProps = {
  status?: 'pending' | 'success' | 'error' | 'disabled' | 'info'
  isError?: boolean
  disabled?: boolean
  title?: string
  badges?: JSX.Element
  onAction?: () => void
  onActionLabel?: string
  children?: JSX.Element
  isLoading?: boolean
  actionLabel?: string
  type?:
    | 'motorist'
    | 'vehicle'
    | 'owner'
    | 'trailer'
    | 'ownerTrailer'
    | 'antt'
    | 'datamax'
    | 'rota'
    | 'buonny'
  trailerIndex?: number
} & BoxProps

const statusConfig = {
  pending: { icon: <IoMdAlert size={22} color="orange" />, color: 'orange' },
  success: { icon: <IoMdCheckmarkCircle size={22} color="green" />, color: 'green' },
  error: { icon: <IoMdAlert size={22} color="red" />, color: 'red' },
  disabled: { icon: <IoMdAlert size={22} color="gray" />, color: 'gray' },
  info: { icon: <AiOutlineLink size={22} color="gray" />, color: 'gray' },
}

export const PedenciesStep = ({
  status = 'pending',
  title,
  children,
  badges,
  isLoading = false,
  type = 'motorist',
  actionLabel,
  trailerIndex = 0,
  isError,
  onAction,
  ...rest
}: PedenciesStepProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.700')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { icon, color } = statusConfig[isError ? 'error' : status]

  return (
    <Box
      position="relative"
      userSelect={isLoading || isError || status === 'disabled' ? 'none' : 'auto'}
      pointerEvents={isLoading || isError || status === 'disabled' ? 'none' : 'auto'}
      opacity={isLoading || isError || status === 'disabled' ? 0.5 : 1}
      {...rest}
    >
      <Box
        position="absolute"
        top="17px"
        left="-24px"
        bg="white"
        rounded="full"
        _before={{
          content: '""',
          position: 'absolute',
          top: '6px',
          left: '0',
          borderTop: '2px dashed',
          borderColor: 'gray.300',
          width: '40px',
          zIndex: -1,
        }}
      >
        <BsRecordCircleFill size={14} color={color} />
      </Box>
      <Flex gridGap="2" justify="space-between" p={2} bg={bg} rounded="xs" flexDir={['column', 'row']}>
        {isError && <Text>Erro ao carregar dados</Text>}
        {!isError && (
          <Flex gridGap="2" flexDir={['column', 'row']}>
            <Flex gridGap="2" align="center">
              {isLoading ? <Spinner size="sm" /> : icon}
              <Text fontSize="sm" fontWeight="medium">
                {title}
              </Text>
            </Flex>
            <Flex gridGap="2" align={['start', 'center']} flexDir={['column', 'row']}>
              {badges}
            </Flex>
          </Flex>
        )}
        <Flex gridGap="2">
          <Button size="sm" rounded="xs" variant="outline" colorScheme="gray" onClick={onAction ?? onOpen}>
            {actionLabel ?? 'Abrir formulário'}
          </Button>
        </Flex>
      </Flex>
      {children && (
        <Box pt="2" pl="4" ml="2" borderLeft="2px dashed" borderColor="gray.300">
          {children}
        </Box>
      )}{' '}
      {isOpen && type === 'motorist' && <MotoristModalForm isOpen={isOpen} onClose={onClose} />}
      {isOpen && type === 'vehicle' && <VehicleModalForm isOpen={isOpen} onClose={onClose} />}
      {isOpen && type === 'owner' && <OwnerModalForm isOpen={isOpen} onClose={onClose} />}
      {isOpen && type === 'trailer' && (
        <TrailerModalForm isOpen={isOpen} onClose={onClose} trailerIndex={trailerIndex} />
      )}
      {isOpen && type === 'ownerTrailer' && (
        <TrailerOwnerForm isOpen={isOpen} onClose={onClose} trailerIndex={trailerIndex} />
      )}
      {isOpen && type === 'antt' && <AnttModalForm isOpen={isOpen} onClose={onClose} />}
      {isOpen && type === 'datamax' && <DatamaxModalForm isOpen={isOpen} onClose={onClose} />}
      {isOpen && type === 'buonny' && <BuonnyModalForm isOpen={isOpen} onClose={onClose} />}
    </Box>
  )
}

export const PedenciesStepWrapper = (props: SimpleGridProps): JSX.Element => {
  return (
    <SimpleGrid
      columns={1}
      ml="2"
      pt="2"
      pl="4"
      gridGap="2"
      borderLeftWidth="2px"
      borderLeftStyle="dashed"
      borderLeftColor="gray.300"
      {...props}
    />
  )
}

type PedenciesStepStartProps = {
  title: string
  status?: 'pending' | 'success' | 'error' | 'disabled'
  isLoading?: boolean
  loadingLabel?: string
}

export const PedenciesStepStart = ({
  title,
  status = 'pending',
  isLoading,
  loadingLabel,
}: PedenciesStepStartProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.700')
  const { icon } = statusConfig[status]
  return (
    <Flex minH="48px" p={2} bg={bg} rounded="md" gridGap="2" align="center">
      {isLoading ? <Spinner size="sm" /> : icon} <Text size="xs">{isLoading ? loadingLabel : title}</Text>
    </Flex>
  )
}
