import { Switch } from 'react-router-dom'
import { useAbility } from '../hooks/useAbility'
import { BlackList } from '../pages/black-list/list/BlackList'
import { ListClientBusinessGroup } from '../pages/clients/client-business-group/list/ListClientBusinessGroup'
import { ListClientContacts } from '../pages/clients/client-contacts/list'
import { ClientMonthlyNps } from '../pages/clients/ClientMonthlyNps'
import { ClientPage } from '../pages/clients/ClientPage'
import { CreateClient } from '../pages/clients/create/CreateClient'
import { ListAllClientMonthlyNps } from '../pages/clients/list/all-client-monthly-nps'
import { ListClients } from '../pages/clients/list/ListClients'
import { ShowClient } from '../pages/clients/show/ShowClient'
import { Dashboard } from '../pages/dashboard/Dashboard'
import { ListAllPlankCheckins } from '../pages/dashboard/listAllPlankCheckins/whatsAppCheckIns/ListAllPlankCheckins'
import { ListCtes } from '../pages/datamex/list/ListCtes'
import { CteReport } from '../pages/datamex/report/CteReport'
import { ShowCte } from '../pages/datamex/show/ShowCte'
import { ListCiots } from '../pages/documents/ciot/list/ListCiots'
import PisConsultation from '../pages/documents/pis-consultation/PisConsultation'
import RntrcConsultation from '../pages/documents/rntrc-consultation/RntrcConsultation'
import { ListFreightContracts } from '../pages/freight-contracts/list/ListFreightContracts'
import FreightsInProgress from '../pages/freights-in-progress'
import { CreateFreight } from '../pages/freights/create/CreateFreight'
import FreightDetails from '../pages/freights/details'
import { ListAllFreightExpensePage } from '../pages/freights/financial/list/list-all-financial-title'
import { CreateLoadingRequest } from '../pages/freights/financial/loading-requests/create'
import { ListLoadingRequests } from '../pages/freights/financial/loading-requests/list'
import { ShowLoadingRequest } from '../pages/freights/financial/loading-requests/show'
import { FreightsTollStatus } from '../pages/freights/freights-toll-status/list/FreightsTollStatus'
import { Invoicing } from '../pages/freights/Invoicing'
import { ListFreights } from '../pages/freights/list/ListFreights'
import FreightPendenciesNEW from '../pages/freights/pendencies'
import { ClientsFeedbacksReports } from '../pages/freights/reports/ClientsFeedbacksReports'
import { FreightsReports } from '../pages/freights/reports/FreightsReports'
import { MotoristsFeedbacksReports } from '../pages/freights/reports/MotoristsFeedbacksReports'
import { ClientFeedback } from '../pages/freights/show/ClientFeedback'
import { MotoristFeedback } from '../pages/freights/show/MotoristFeedback'
import { ListStubs } from '../pages/freights/stubs'
import { ShowStub } from '../pages/freights/stubs/ShowStub'
import Metrics from '../pages/metrics'
import { IncludeMotoristToBuonny } from '../pages/motorists/buonny/IncludeMotoristToBuonny'
import { CreateMotorist } from '../pages/motorists/create/CreateMotorist'
import { ListMotorists } from '../pages/motorists/list/ListMotorists'
import { ListMotoristsSpotx } from '../pages/motorists/list/ListMotoristsSpotX'
import { MotoristsReports } from '../pages/motorists/reports/MotoristsReports'
import { ShowMotorist } from '../pages/motorists/show/ShowMotorist'
import { NotFound } from '../pages/NotFound'
import { Operation } from '../pages/operation'
import { CreateOwner } from '../pages/owners/create/CreateOwner'
import { ListOwner } from '../pages/owners/list/ListOwner'
import { ShowOwner } from '../pages/owners/show/ShowOwner'
import { EditPermissionsToManyRoles } from '../pages/permissions/edit/EditPermissionsToManyRoles'
import { EditPermissionsToOneRole } from '../pages/permissions/edit/EditPermissionsToOneRole'
import { ListRoles } from '../pages/permissions/list'
import { RouteAnalysis } from '../pages/pricing/route-analysis/RouteAnalysis'
import RoutePlanner from '../pages/pricing/route-planner/show/RoutePlanner'
import { HistoryPricesQuotations } from '../pages/pricing/route-prices/history-prices'
import { CreateQuotation } from '../pages/quotation/create/CreateQuotation'
import { CreateCalcQuotation } from '../pages/quotation/CreateCalcQuotation'
import { FreightCalculation } from '../pages/quotation/FreightCalculation'
import { FreightsQuotation } from '../pages/quotation/kanban-quotations/list/FreightsQuotation'
import { ShowQuotation } from '../pages/quotation/show/ShowQuotation'
import { ListAllRiskAnalysis } from '../pages/risk-analysis/list'
import { ShowRiskAnalysis } from '../pages/risk-analysis/show/ShowRiskAnalysis'
import { SignIn } from '../pages/SignIn'
import { AllTrailers } from '../pages/trailers/list/AllTrailers'
import { CreateUser } from '../pages/users/CreateUser'
import { ListUsers } from '../pages/users/ListUsers'
import { RecoverPassword } from '../pages/users/password/RecoverPassword'
import { SendRecoverLink } from '../pages/users/password/SendRecoverLink'
import { CreateVehicle } from '../pages/vehicles/create/CreateVehicle'
import { InvertRegistrations } from '../pages/vehicles/invert-registrations'
import { AllVehicles } from '../pages/vehicles/list/AllVehicles'
import { Route } from './Route'
import { ListAdvanceRequests } from '../pages/freights/financial/advance-requests/list'
import { ListBalanceRequests } from '../pages/freights/financial/balance-requests/list'
import { ShowAdvanceRequest } from '../pages/freights/financial/advance-requests/show'
import { ShowBalanceRequest } from '../pages/freights/financial/balance-requests/show'
import { CreateAdvanceRequest } from '../pages/freights/financial/advance-requests/create'
import { CreateBalanceRequest } from '../pages/freights/financial/balance-requests/create'
import { CreateCteIssuanceRequest } from '../pages/freights/financial/cte-issuance-requests/create'
import { ListCteIssuanceRequests } from '../pages/freights/financial/cte-issuance-requests/list'
import { ShowCteIssuanceRequest } from '../pages/freights/financial/cte-issuance-requests/show'

export function Routes(): JSX.Element {
  return (
    <Switch>
      {/* General */}
      <Route path="/" exact component={SignIn} pageTitle="Inicio" />
      {/* Password Recover */}
      <Route
        path="/recover-password"
        component={RecoverPassword}
        pageTitle="Recuperação de Senha | SpotX Admin"
      />
      <Route
        path="/send-recover-link"
        component={SendRecoverLink}
        pageTitle="Recuperação de Senha | SpotX Admin"
      />
      <Route path="/dashboard" component={Dashboard} isPrivate pageTitle="Dashboard | SpotX Admin" />
      {useAbility('operation', 'list-all-plank-checkins') && (
        <Route
          path="/all-plank-checkins"
          component={ListAllPlankCheckins}
          isPrivate
          pageTitle="Check-In carroceria prancha | SpotX Admin"
        />
      )}

      <Route
        path="/tracking/:freight_id"
        component={ClientPage}
        pageTitle="Página do cliente | SpotX Admin"
      />

      {/* Freights */}
      {useAbility('operation', 'list-freight') && (
        <Route path="/freights" exact component={ListFreights} isPrivate pageTitle="Fretes | SpotX Admin" />
      )}

      {useAbility('operation', 'list-freight-in-progress') && (
        <Route
          path="/freights/freights-in-progress"
          exact
          component={FreightsInProgress}
          isPrivate
          pageTitle="Monitoramento de fretes em andamento | SpotX Admin"
        />
      )}

      {useAbility('operation', 'create-freight') && (
        <Route
          path="/freights/create"
          component={CreateFreight}
          isPrivate
          pageTitle="Cadastro de fretes | SpotX Admin"
        />
      )}
      {useAbility('operation', 'calc-cte-value') && (
        <Route
          path="/freights/invoicing"
          component={Invoicing}
          isPrivate
          pageTitle="Faturamento de fretes | SpotX Admin"
        />
      )}

      {useAbility('operation', 'list-freight-to-operation') && (
        <Route
          path="/freights/operation"
          component={Operation}
          isPrivate
          pageTitle="Status Operação | SpotX Admin"
        />
      )}
      {/* {useAbility('operation', 'list-freight-to-operation') && ( */}
      <Route path="/freights/metrics" component={Metrics} isPrivate pageTitle="Métricas | SpotX Admin" />
      {/* )} */}
      <Route
        path="/freights/kanban-quotations"
        exact
        component={FreightsQuotation}
        isPrivate
        pageTitle="Cotações | SpotX Admin"
      />
      <Route
        path="/quotations/create"
        component={CreateQuotation}
        isPrivate
        pageTitle="Cadastro de cotações | SpotX Admin"
      />
      <Route
        path="/freights/history-prices-quotations"
        component={HistoryPricesQuotations}
        isPrivate
        pageTitle="Histórico de preços de cotação | SpotX Admin"
      />
      <Route
        path="/freights/quotation"
        component={CreateCalcQuotation}
        isPrivate
        pageTitle="Calculadora de fretes | SpotX Admin"
      />
      <Route
        path="/client-feedback/:freight_id"
        component={ClientFeedback}
        pageTitle="Feedback do cliente | SpotX Admin"
      />
      <Route
        path="/motorist-feedback/:freight_id"
        component={MotoristFeedback}
        pageTitle="Feedback do motorista | SpotX Admin"
      />
      <Route
        path="/freights/calculation/:slug"
        component={FreightCalculation}
        isPrivate
        pageTitle="Cotação de fretes | SpotX Admin"
      />
      {useAbility('operation', 'find-freight-by-id') && (
        <Route
          path="/freights/show/:freight_id"
          exact
          component={FreightDetails}
          isPrivate
          pageTitle="Frete | SpotX Admin"
        />
      )}

      {useAbility('operation', 'find-quotation-by-id') && (
        <Route
          path="/quotations/show/:quotation_id"
          exact
          component={ShowQuotation}
          isPrivate
          pageTitle="Cotação | SpotX Admin"
        />
      )}

      <Route
        path="/freights/show/:freight_id/pendencies"
        exact
        component={FreightPendenciesNEW}
        isPrivate
        pageTitle="Pendências do Frete | SpotX Admin"
      />
      <Route
        path="/freights-reports"
        exact
        component={FreightsReports}
        isPrivate
        pageTitle="Relatório de fretes | SpotX Admin"
      />
      {/* Motorists */}
      {useAbility('people', 'list-motorists') && (
        <Route
          path="/motorists"
          exact
          component={ListMotorists}
          isPrivate
          pageTitle="Motoristas | SpotX Admin"
        />
      )}

      {useAbility('people', 'count-created-by-operator') && (
        <Route
          path="/motorists-reports"
          exact
          component={MotoristsReports}
          isPrivate
          pageTitle="Relatório de motoristas | SpotX Admin"
        />
      )}

      {useAbility('people', 'list-nps-motorist') && (
        <Route
          path="/motorists-feedbacks-reports"
          exact
          component={MotoristsFeedbacksReports}
          isPrivate
          pageTitle="Relatório de feedbacks dos motoristas | SpotX Admin"
        />
      )}

      {useAbility('people', 'list-motorists') && (
        <Route
          path="/motorists/spotx"
          exact
          component={ListMotoristsSpotx}
          isPrivate
          pageTitle="Motoristas SpotX | SpotX Admin"
        />
      )}

      {useAbility('people', 'create-motorist') && (
        <Route
          path="/motorists/create"
          component={CreateMotorist}
          isPrivate
          pageTitle="Cadastro de motorista | SpotX Admin"
        />
      )}

      {useAbility('people', 'find-motorist-by-id') && (
        <Route
          path="/motorists/show/:motorist_id"
          component={ShowMotorist}
          isPrivate
          pageTitle="Página do motorista"
        />
      )}

      {useAbility('people', 'find-motorist-by-id') && (
        <Route
          path="/motorists/buonny/include/:motorist_id/:freight_id"
          component={IncludeMotoristToBuonny}
          isPrivate
          pageTitle="Checagem de motorista na Buonny | SpotX Admin"
        />
      )}
      {/* Vehicles */}
      {useAbility('operation', 'invert-vehicle-registrations') && (
        <Route
          path="/vehicles/invert-registrations"
          exact
          component={InvertRegistrations}
          isPrivate
          pageTitle="Veículos | SpotX Admin"
        />
      )}

      {useAbility('operation', 'list-vehicle') && (
        <Route path="/vehicles" exact component={AllVehicles} isPrivate pageTitle="Veículos | SpotX Admin" />
      )}

      {useAbility('operation', 'create-vehicle') && (
        <Route
          path="/vehicles/create"
          component={CreateVehicle}
          isPrivate
          pageTitle="Cadastro de veículos | SpotX Admin"
        />
      )}

      {/* Trailers */}
      {useAbility('operation', 'list-trailer') && (
        <Route path="/trailers" exact component={AllTrailers} isPrivate pageTitle="Reboques | SpotX Admin" />
      )}

      {/* Owners */}
      {useAbility('people', 'create-owner') && (
        <Route
          path="/owners/create"
          exact
          component={CreateOwner}
          isPrivate
          pageTitle="Cadastro de proprietário | SpotX Admin"
        />
      )}

      {useAbility('people', 'list-owner') && (
        <Route path="/owners" exact component={ListOwner} isPrivate pageTitle="Proprietários | SpotX Admin" />
      )}

      {useAbility('people', 'find-owner') && (
        <Route path="/owners/show/:owner_id" exact component={ShowOwner} isPrivate pageTitle="#" />
      )}
      {/* Clients */}
      <Route path="/clients" exact component={ListClients} isPrivate pageTitle="Clientes | SpotX Admin" />
      <Route
        path="/clients/create"
        exact
        component={CreateClient}
        isPrivate
        pageTitle="Cadastro de clientes | SpotX Admin"
      />
      <Route
        path="/clients/requesters"
        exact
        component={ListClientContacts}
        isPrivate
        pageTitle="Lista de solicitantes | SpotX Admin"
      />
      <Route
        path="/clients/business-group"
        exact
        component={ListClientBusinessGroup}
        pageTitle="Lista de grupos empresariais | SpotX Admin"
      />
      <Route
        path="/client-monthly-nps"
        exact
        component={ClientMonthlyNps}
        pageTitle="Feedback do cliente | SpotX Admin"
      />
      <Route
        path="/clients/client-monthly-nps"
        exact
        component={ListAllClientMonthlyNps}
        pageTitle="Lista de feedbacks do cliente | SpotX Admin"
      />
      <Route path="/clients/show/:client_id" component={ShowClient} isPrivate pageTitle="#" />
      <Route
        path="/clients-feedbacks-reports"
        exact
        component={ClientsFeedbacksReports}
        isPrivate
        pageTitle="Relatório de feedbacks dos clientes | SpotX Admin"
      />
      {/* Users */}
      {useAbility('account', 'list-user') && (
        <Route path="/users" exact component={ListUsers} isPrivate pageTitle="Usuários | SpotX Admin" />
      )}
      {useAbility('account', 'create-user') && (
        <Route
          path="/users/create"
          exact
          component={CreateUser}
          isPrivate
          pageTitle="Cadastro de usuários | SpotX Admin"
        />
      )}

      {/* Authorization */}
      {useAbility('authorization', 'update-permissions-by-roles') && (
        <Route
          path="/permissions"
          exact
          component={ListRoles}
          isPrivate
          pageTitle="Permissões | SpotX Admin"
        />
      )}

      {useAbility('authorization', 'update-permissions-by-roles') && (
        <Route
          path="/permissions/edit/to-many-roles"
          exact
          component={EditPermissionsToManyRoles}
          isPrivate
          pageTitle="Editar permissões por grupo de cargo | SpotX Admin"
        />
      )}

      {useAbility('authorization', 'update-permissions-by-roles') && (
        <Route
          path="/permissions/edit/:role"
          exact
          component={EditPermissionsToOneRole}
          isPrivate
          pageTitle="Editar permissões de um cargo | SpotX Admin"
        />
      )}

      {/* Financial */}
      {useAbility('financial', 'list-freight-expense') && (
        <Route
          path="/financial-expenses-list"
          exact
          component={ListAllFreightExpensePage}
          isPrivate
          pageTitle="Lista de despesas | SpotX Admin"
        />
      )}
      <Route
        path="/advance-requests"
        exact
        component={ListAdvanceRequests}
        isPrivate
        pageTitle="Lista de solicitação de adiantamento | SpotX Admin"
      />
      <Route
        path="/advance-requests/show/:id"
        exact
        component={ShowAdvanceRequest}
        pageTitle="Exibição solicitação de adiantamento | SpotX Admin"
      />
      <Route
        path="/advance-requests/create"
        exact
        component={CreateAdvanceRequest}
        pageTitle="Nova solicitação de adiantamento | SpotX Admin"
      />
      <Route
        path="/balance-requests"
        exact
        component={ListBalanceRequests}
        isPrivate
        pageTitle="Lista de solicitação de saldo | SpotX Admin"
      />
      <Route
        path="/balance-requests/show/:id"
        exact
        component={ShowBalanceRequest}
        pageTitle="Exibição solicitação de saldo | SpotX Admin"
      />
      <Route
        path="/balance-requests/create"
        exact
        component={CreateBalanceRequest}
        pageTitle="Nova solicitação de saldo | SpotX Admin"
      />
      <Route
        path="/loading-requests"
        exact
        component={ListLoadingRequests}
        pageTitle="Lista despesas extras (OC) | SpotX Admin"
      />

      <Route
        path="/loading-requests/show/:id"
        exact
        component={ShowLoadingRequest}
        pageTitle="Exibição solicitação de carregamento | SpotX Admin"
      />

      <Route
        path="/loading-requests/create"
        exact
        component={CreateLoadingRequest}
        pageTitle="Nova solicitação de carregamento | SpotX Admin"
      />

      <Route
        path="/cte-issuance-requests"
        exact
        component={ListCteIssuanceRequests}
        pageTitle="Lista solicitações de emissão de CTe | SpotX Admin"
      />

      <Route
        path="/cte-issuance-requests/show/:id"
        exact
        component={ShowCteIssuanceRequest}
        pageTitle="Exibição solicitação de carregamento | SpotX Admin"
      />

      <Route
        path="/cte-issuance-requests/create"
        exact
        component={CreateCteIssuanceRequest}
        pageTitle="Nova solicitação de emissão de CTe | SpotX Admin"
      />

      {/* Documents */}
      {useAbility('operation', 'calc-cte-value') && (
        <Route
          path="/documents-cte-list"
          exact
          component={ListCtes}
          isPrivate
          pageTitle="Lista de CTe | SpotX Admin"
        />
      )}
      {useAbility('financial', 'list-ciots') && (
        <Route
          path="/documents-ciot-list"
          exact
          component={ListCiots}
          isPrivate
          pageTitle="Lista de CIOT | SpotX Admin"
        />
      )}
      {useAbility('operation', 'list-formatted-stubs-by-freight') && (
        <Route
          path="/stubs"
          exact
          component={ListStubs}
          isPrivate
          pageTitle="Lista de canhotos | SpotX Admin"
        />
      )}
      <Route
        path="/stubs/:freight_id"
        exact
        component={ShowStub}
        isPrivate
        pageTitle="Lista de CTe | SpotX Admin"
      />
      {useAbility('operation', 'list-ctes') && (
        <Route
          path="/documents-cte-reports"
          exact
          component={CteReport}
          isPrivate
          pageTitle="Relatório de vendas | SpotX Admin"
        />
      )}
      {useAbility('operation', 'find-ctes') && (
        <Route
          path="/documents-cte-list/show/:cte_id"
          component={ShowCte}
          isPrivate
          pageTitle="Dados da CTE | SpotX Admin"
        />
      )}
      {useAbility('financial', 'get-freights-tolls') && (
        <Route
          path="/documents/freights-toll-status"
          exact
          component={FreightsTollStatus}
          isPrivate
          pageTitle="Status de pedágio dos fretes | SpotX Admin"
        />
      )}
      <Route
        path="/documents/freight-contracts"
        exact
        component={ListFreightContracts}
        isPrivate
        pageTitle="Lista de contratos de frete | SpotX Admin"
      />
      <Route
        path="/documents/rntrc-consultation"
        exact
        component={RntrcConsultation}
        isPrivate
        pageTitle="Consulta RNTRC | SpotX Admin"
      />
      <Route
        path="/documents/pis-consultation"
        exact
        component={PisConsultation}
        isPrivate
        pageTitle="Consulta PIS | SpotX Admin"
      />
      {/* Pricing */}
      {useAbility('operation', 'route-analysys') && (
        <Route
          path="/pricing/route-analysis"
          exact
          component={RouteAnalysis}
          pageTitle="Análise de rotas | SpotX Admin"
        />
      )}
      {useAbility('financial', 'find-route-points') && (
        <Route
          path="/pricing/route-planner"
          exact
          component={RoutePlanner}
          isPrivate
          pageTitle="Roteirizador | SpotX Admin"
        />
      )}
      {/* Security */}
      {useAbility('operation', 'list-risk-analysis') && (
        <Route
          path="/list-risk-analysis"
          exact
          component={ListAllRiskAnalysis}
          isPrivate
          pageTitle="Lista de análise de risco | SpotX Admin"
        />
      )}
      {useAbility('operation', 'find-risk-analysis-by-id') && (
        <Route
          path="/risk-analyst-infos/:risk_analysis_id"
          exact
          component={ShowRiskAnalysis}
          isPrivate
          pageTitle="Análise de risco | SpotX Admin"
        />
      )}
      {useAbility('operation', 'list-black-list') && (
        <Route
          path="/black-list"
          exact
          component={BlackList}
          isPrivate
          pageTitle="Lista de restrição | SpotX Admin"
        />
      )}
      {/* Default */}
      <Route component={NotFound} pageTitle="Página não encontrada | SpotX Admin" />
    </Switch>
  )
}
