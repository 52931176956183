import { useMutation, UseMutationOptions } from 'react-query'
import { SelectOption } from '../../../components/form'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'

type PostBalanceRequestParams = {
  total_value: string
  cte_id: string
  account_digit?: string
  account?: string
  agency?: string
  account_type?: string
  bank?: SelectOption
  pix?: string
  payment_type: string
  e_frete?: string
  freight_id: string
  observation?: string
}

async function createBalanceRequest(data: PostBalanceRequestParams) {
  const { data: response } = await apiServer.post('/balance-requests', {
    freight_id: data.freight_id,
    cte_id: data.cte_id,
    total_value: Number(data.total_value),
    pix: data.payment_type === 'pix' ? data.pix : null,
    account_type: data.payment_type === 'account' ? data.account_type : null,
    bank: data.payment_type === 'account' ? data.bank?.value : null,
    agency: data.payment_type === 'account' ? data.agency : null,
    account: data.payment_type === 'account' ? data.account : null,
    account_digit: data.payment_type === 'account' ? data.account_digit : null,
    e_frete: data.e_frete === 'yes',
    observation: data.observation,
  })
  queryClient.invalidateQueries('balance-requests')
  return response.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePostBalanceRequest(
  options?: UseMutationOptions<any, unknown, PostBalanceRequestParams, unknown>,
) {
  return useMutation(async (data: PostBalanceRequestParams) => createBalanceRequest(data), options)
}
