import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BsArrowClockwise } from 'react-icons/bs'
import { toastError } from '../../../../../../config/error/toastError'
import { queryClient } from '../../../../../../config/react-query'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { usePutAttachNfesStub } from '../../../../../../services/endpoints/freights/putAttachNfesStub'
import { FreightStubsType } from '../../../../../../services/types'

type ShowStubModalProps = {
  isOpen: boolean
  onClose: () => void
  stub: FreightStubsType | null
}

export function ShowStubModal({ isOpen, onClose, stub }: ShowStubModalProps): JSX.Element {
  const { user } = useAuth()
  const [rotateImage, setRotateImage] = useState(0)
  const {
    mutateAsync: mutateAsyncAttachNfesStub,
    isLoading: isLoadingAttachNfesStub,
    error: errorAttachNfesStub,
    isError: isErrorAttachNfesStub,
  } = usePutAttachNfesStub({
    onSuccess: () => {
      queryClient.invalidateQueries('stubs')
      queryClient.invalidateQueries('nfes')
      onClose()
    },
  })

  const toast = useToast()

  useEffect(() => {
    if (isErrorAttachNfesStub) {
      toastError({ toast, error: errorAttachNfesStub })
    }
  }, [isErrorAttachNfesStub, errorAttachNfesStub, toast])

  async function handleDetachStubNfes(): Promise<void> {
    if (stub) {
      await mutateAsyncAttachNfesStub({
        nfes_id: [],
        stub_id: stub.id,
        status: 'pending',
        occurrence: null,
      })
    }
  }

  function handleRotateImage(): void {
    if (rotateImage === 270) {
      setRotateImage(0)
    } else {
      setRotateImage(prevState => prevState + 90)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Análise de Canhoto - Frete #{stub?.freight?.freight_number}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mt={2}>
          <Text display="flex" alignItems="center" fontSize={20}>
            Análise de canhoto:
            <Text
              ml="2"
              bg={stub?.status === 'disapproved' ? 'red.400' : 'green.400'}
              p={2}
              borderRadius="10"
              fontSize={16}
            >
              {stub?.status_formatted.toLocaleUpperCase()}
            </Text>
          </Text>

          <Flex mt={4}>
            <Box w="50%">
              <Image
                src={stub?.attachment_file_url}
                w="100%"
                maxH="500px"
                mt={2}
                style={{ rotate: `${rotateImage}deg` }}
              />
              <Flex justify="flex-end" mt={2}>
                <Button size="xs" colorScheme="orange" onClick={handleRotateImage}>
                  <Icon as={BsArrowClockwise} fontSize="18" />
                </Button>
              </Flex>
            </Box>

            <Flex ml={4} flexDirection="column" w="50%">
              <Flex w="100%">
                <Text fontSize={16} color="gray.400">
                  Operador responsável:
                </Text>
                <Text fontWeight="medium" ml={2}>
                  {stub?.user?.name}
                </Text>
              </Flex>

              <Divider mt={4} />

              <Box mt={4}>
                <Text>CTes</Text>

                <Flex mt={1}>
                  {stub &&
                    stub.ctes &&
                    stub.ctes.map(cte => (
                      <Tag colorScheme="green" p="2" borderRadius="full" gridGap={2} mr={1}>
                        <Text fontSize="14" fontWeight="bold">
                          {cte.nct}
                        </Text>
                      </Tag>
                    ))}
                </Flex>
              </Box>

              <Divider mt={4} />

              <Box mt={4}>
                <Text>Nfes</Text>

                <Flex mt={1} w="100%">
                  {stub &&
                    stub.nfes &&
                    stub.nfes.map(nfe => (
                      <Tag
                        colorScheme="blue"
                        p="2"
                        borderRadius="full"
                        mr={1}
                        fontSize="14"
                        fontWeight="bold"
                      >
                        {nfe.number}
                      </Tag>
                    ))}
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>

          {[
            'antonio.salomao@spotx.com.br',
            'zenilton.filho@spotx.com.br',
            'marcelo.junior@spotx.com.br',
            'monitoramento@spotx.com.br',
            'villyane.santana@spotx.com.br',
            'vinicius.lira@spotx.com.br',
            'cleginaldo3000@gmail.com',
            'pv.lima02@gmail.com',
            'borges@gmail.com',
          ].includes(user.email) && (
            <Button
              colorScheme="red"
              mr={2}
              onClick={handleDetachStubNfes}
              isLoading={isLoadingAttachNfesStub}
            >
              Retirar atribuição
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
