import { AxiosResponse } from 'axios'
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query'
import { apiServer } from '../../../api'

export type ParamsNotifyRiskAnalysis = {
  freight_number: string
  analyst: string
  commercial?: string
  client?: string
  origin?: string
  destination?: string
  distance?: string
  material?: string
  total_value?: string
  weight?: string
  checklists?: string[]
}

async function postNotifyRiskAnalysis(data: ParamsNotifyRiskAnalysis): Promise<AxiosResponse> {
  return apiServer.post('/send-risk-analysis-notification', data)
}

export function usePostNotifyRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, ParamsNotifyRiskAnalysis, unknown>,
): UseMutationResult<AxiosResponse, unknown, ParamsNotifyRiskAnalysis, unknown> {
  return useMutation(async (data: ParamsNotifyRiskAnalysis) => postNotifyRiskAnalysis(data), options)
}
