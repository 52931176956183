import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'

type Input = {
  freight_number: string
  id: string
}

export async function changeFreightLinkFreightContract({ freight_number, id }: Input): Promise<boolean> {
  const { data, status } = await apiServer.patch(`/freight-contracts/${id}`, {
    freight_number,
  })
  if (status === 200) queryClient.invalidateQueries('list_freight_contracts')
  return data
}

export function useChangeFreightLinkFreightContract(
  options?: UseMutationOptions<boolean, unknown, Input, unknown>,
): UseMutationResult<boolean, unknown, Input, unknown> {
  return useMutation(async (params: Input) => changeFreightLinkFreightContract(params), options)
}
