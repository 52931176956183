import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { Select } from '../../../../../components/form/Select'
import { Input } from '../../../../../components/form/Input'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { addAttachment } from '../../../../../store/slices/freightSlice'
import { toastError } from '../../../../../config/error/toastError'
import { attachmentFreightOptions } from '../../../../../services/utils/attachmentsFreightOptions'
import { TextArea } from '../../../../../components/form/TextArea'

interface AddAttachmentModalProps {
  isOpen: boolean
  onClose: () => void
  freight_status: string
}

export interface AddAttachmentFormData {
  type: string
  delivery_receipt_at?: Date
  name?: string
  description?: string
  attachment: File
  freight_id: string
  freight_status?: string
}

const addAttachmentFormSchema = yup.object().shape({
  type: yup.string().required('campo obrigatório'),
  name: yup.string(),
  attachment: yup.mixed().required('campo obrigatório'),
  description: yup.string(),
})

export function AddAttachmentModal({
  isOpen,
  onClose,
  freight_status,
}: AddAttachmentModalProps): JSX.Element {
  const toast = useToast()
  const { freight, isLoading } = useAppSelector(state => state.freightSlice)

  const dispatch = useAppDispatch()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(addAttachmentFormSchema),
  })
  const { errors } = formState

  const handleAddAttachment: SubmitHandler<AddAttachmentFormData> = async data => {
    if (freight) {
      const { id: freight_id } = freight
      const error = await dispatch(
        addAttachment({
          freight_id,
          attachment: data.attachment,
          type: data.type,
          name: data.name,
          description: data.description,
          freight_status,
        }),
      )

      if (isLoading === false && !error) onClose()
      if (!error) {
        toast({
          title: 'Anexo adicionado com sucesso!',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        })
      }
      if (error) {
        toastError({ toast, error })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleAddAttachment)} noValidate>
          <ModalHeader>Adicionar anexo para o frete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="6">
              <Select
                name="type"
                label="Tipo de documento"
                setValue={setValue}
                error={errors.type}
                options={attachmentFreightOptions}
                isRequired
              />

              {/* <Input
                name="name"
                label="Nome do arquivo"
                setValue={setValue}
                error={errors.name}
              /> */}

              <Input
                h="35px"
                name="attachment"
                type="file"
                label="Arquivo"
                setValue={setValue}
                error={errors.attachment}
                isRequired
              />

              <TextArea name="description" label="Descrição" setValue={setValue} error={errors.description} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiAttachmentLine} />}
              isLoading={isLoading || false}
            >
              Adicionar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
