import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { TrailerResponseFormData } from './getTrailerByColumn'

export async function fetchTrailer(trailer_id: string): Promise<TrailerResponseFormData | undefined> {
  if (trailer_id && trailer_id !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<TrailerResponseFormData>>(
      `/find-trailer/${trailer_id}`,
    )

    const { data } = response

    const trailer = {
      ...data,
      vehicle_body_id: data.vehicle_body?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_body.id,
      },
    } as TrailerResponseFormData

    return trailer
  }
  return undefined
}

export function useGetTrailer(trailer_id: string): UseQueryResult<TrailerResponseFormData> {
  return useQuery(['trailer', trailer_id], () => fetchTrailer(trailer_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
