import {
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
} from '@chakra-ui/react'
import { colorStatusFreight } from '../../../../services/utils/colorStatusFreight'
import parseCurrency from '../../../../services/utils/parseCurrency'

interface IFreightsCurrent {
  total: number
  phases: {
    in_origin: number
    collecting: number
    ready: number
    on_road: number
    in_destination: number
    delivered: number
  }
  current_freights_service_price: number
  current_freights_expired: number
  current_freights_to_match: number
}

interface IFreightsCurrentProps {
  freightsCurrent?: IFreightsCurrent
  isLoading: boolean
  isFetching: boolean
  error: unknown
  bg: string
}

export function FreightsCurrentCard({
  freightsCurrent,
  isLoading,
  isFetching,
  error,
  bg,
}: IFreightsCurrentProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes Em Andamento. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        freightsCurrent && (
          <>
            <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md" overflow="auto" maxH="240">
              <Flex justifyContent="space-between" pl="5" pr="5" alignItems="baseline" fontWeight="bold">
                <Text fontSize="lg" color="blue.400">
                  EM ANDAMENTO
                  {!isLoading && isFetching && <Spinner ml={2} />}
                </Text>
                <Text fontSize="x-large" color="blue.400">
                  {freightsCurrent.total}
                </Text>
              </Flex>
              <Accordion allowToggle mt="4">
                <AccordionItem>
                  <AccordionButton h="30px">
                    <Box flex="1" textAlign="left">
                      Fases do frete
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel>
                    <Table size="sm">
                      <Tbody>
                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Contratado')}>
                            Contratado
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Contratado')}>
                              {freightsCurrent.phases.in_origin}
                            </Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Saiu para coleta')}>
                            Saiu para coleta
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Saiu para coleta')}>
                              {freightsCurrent.phases.collecting}
                            </Text>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Chegou para carregar')}>
                            Chegou para carregar
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Chegou para carregar')}>
                              {freightsCurrent.phases.ready}
                            </Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Começou a viagem')}>
                            Começou a viagem
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Começou a viagem')}>
                              {freightsCurrent.phases.on_road}
                            </Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Chegou para descarregar')}>
                            Chegou para descarregar
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Chegou para descarregar')}>
                              {freightsCurrent.phases.in_destination}
                            </Text>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="md" color={colorStatusFreight('Entrega Finalizada')}>
                            Entrega Finalizada
                          </Td>
                          <Td isNumeric>
                            <Text fontSize="md" color={colorStatusFreight('Entrega Finalizada')}>
                              {freightsCurrent.phases.delivered}
                            </Text>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Table size="sm" mt="4">
                <Tbody>
                  <Tr>
                    <Td fontSize="md" color="blue.400">
                      Serviço
                    </Td>
                    <Td isNumeric>
                      <Text fontSize="sm" color="blue.400">
                        {parseCurrency(freightsCurrent.current_freights_service_price)}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text
                        fontSize="md"
                        color={freightsCurrent.current_freights_expired > 0 ? 'red' : 'gray.500'}
                      >
                        Atrasados
                      </Text>
                    </Td>
                    <Td
                      fontSize="md"
                      color={freightsCurrent.current_freights_expired > 0 ? 'red' : 'gray.500'}
                      isNumeric
                    >
                      {freightsCurrent.current_freights_expired}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text
                        fontSize="md"
                        color={freightsCurrent.current_freights_to_match > 0 ? 'yellow.500' : 'gray.500'}
                      >
                        A combinar
                      </Text>
                    </Td>
                    <Td
                      fontSize="md"
                      color={freightsCurrent.current_freights_to_match > 0 ? 'yellow.500' : 'gray.500'}
                      isNumeric
                    >
                      {freightsCurrent.current_freights_to_match}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </>
        )
      )}
    </>
  )
}
