import { ChangeEventHandler, useRef, useState } from 'react'
import {
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { debounce, DebouncedFunc } from 'lodash'
import { MdClear } from 'react-icons/md'

interface SearchBoxProps extends Omit<InputGroupProps, 'onChange'> {
  onChange?: (value: string) => Promise<void>
  clearState?: (value: string | undefined) => void
  type?: string
}

export function SearchBox({
  onChange,
  variant = 'outline',
  placeholder = 'Pesquisa...',
  shadow,
  clearState,
  ...rest
}: SearchBoxProps): JSX.Element {
  const inputRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('white', 'gray.900')
  const [isLoading, setIsLoading] = useState(false)
  const delayedSearch: DebouncedFunc<(value: string) => Promise<void>> = useRef(
    debounce(async (value: string) => {
      setIsLoading(false)
      if (onChange) {
        await onChange(value)
      }
    }, 700),
  ).current

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  const handleSearch: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target
    setIsLoading(true)
    delayedSearch(value)
  }

  return (
    <InputGroup ref={inputRef} rounded="md" variant={variant} {...rest}>
      <Input
        h="45px"
        onChange={handleSearch}
        fontSize="lg"
        placeholder={placeholder}
        bg={bg}
        shadow={shadow}
        {...rest}
      />
      <InputRightElement cursor="pointer" mt={1}>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Icon
            onClick={() => {
              setInputValue('')
              if (clearState) {
                clearState(undefined)
              }
            }}
            as={MdClear}
          />
        )}
      </InputRightElement>
    </InputGroup>
  )
}
