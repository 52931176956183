import { Alert, AlertIcon, Flex } from '@chakra-ui/react'

interface EnvironmentFlagProps {
  position: 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft'
}

let environmentFlag = ''
const frontEnv = process.env.REACT_APP_ENVIRONMENT
const ApiServerProd = process.env.REACT_APP_BASE_API_SERVER?.includes('https://srv.spotx.com.br')
const ApiProd = process.env.REACT_APP_BASE_API?.includes('https://api.spotx.com.br')
const ApiServerHomolog = process.env.REACT_APP_BASE_API_SERVER?.includes('https://srv-homolog.spotx.com.br')
const ApiHomolog = process.env.REACT_APP_BASE_API?.includes('https://api-homolog.spotx.com.br')
if ((ApiHomolog || ApiServerHomolog) && frontEnv === 'local') {
  environmentFlag = 'API Homologação'
} else if ((ApiProd || ApiServerProd) && frontEnv === 'local') {
  environmentFlag = 'API Produção'
} else {
  environmentFlag = frontEnv === 'local' ? 'Local' : 'Homologação'
}
export function EnvironmentFlag({ position }: EnvironmentFlagProps): JSX.Element {
  let top: string | undefined = '30px'
  let left: string | undefined = '-50px'
  let right: string | undefined = '-50px'
  let bottom: string | undefined = '30px'

  let rotate = 45
  let color = 'purple.500'
  if (environmentFlag.includes('Homologação')) color = 'blue.500'
  else if (environmentFlag.includes('Produção')) color = 'red'
  switch (position) {
    case 'topRight':
      left = undefined
      bottom = undefined
      break
    case 'topLeft':
      right = undefined
      bottom = undefined
      rotate = -45
      break
    case 'bottomRight':
      left = undefined
      top = undefined
      rotate = -45
      break
    case 'bottomLeft':
      right = undefined
      top = undefined
      break
    default:
      break
  }
  return (
    <>
      {frontEnv !== 'production' && (
        <Alert
          bg={color}
          status={color === 'red' ? 'error' : color === 'purple.500' ? 'info' : 'warning'}
          alignContent="center"
          justifyContent="center"
          top={top}
          right={right}
          left={left}
          bottom={bottom}
          transform={`rotate(${rotate}deg)`}
          position="fixed"
          bgColor={`${color}`}
          padding="2"
          textAlign="center"
          width="200px"
          opacity={0.7}
          zIndex={99999}
          pointerEvents="none"
        >
          {color === 'red' && <AlertIcon color="black" />}
          <Flex fontSize="sm" color="black" fontWeight="bold">
            {environmentFlag}
          </Flex>
        </Alert>
      )}
    </>
  )
}

type EnvFlagProps = { children: React.ReactNode }
export function EnvFlag({ children }: EnvFlagProps): JSX.Element {
  return (
    <>
      <EnvironmentFlag position="topLeft" />
      <EnvironmentFlag position="topRight" />
      {children}
      <EnvironmentFlag position="bottomLeft" />
      <EnvironmentFlag position="bottomRight" />
    </>
  )
}
