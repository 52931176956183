import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'
import { ClientType, ResponseApiType } from '../../types'

export async function fetchClient(client_id: string): Promise<ClientType | undefined> {
  if (client_id && client_id !== 'undefined') {
    const { data: res } = await apiServer.get<ResponseApiType<ClientType>>(`find-client-by-id/${client_id}`)

    const { data } = res
    return data
  }
  return undefined
}

export function useGetClient(client_id: string): UseQueryResult<ClientType> {
  return useQuery(['client', client_id], () => fetchClient(client_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
