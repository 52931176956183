import { useState, useEffect, FormEvent } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { RiSearchLine } from 'react-icons/ri'
import { format } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Chart from 'react-apexcharts'

import { Layout } from '../../../layout'
import { AutocompleteAsync } from '../../../components/form/AutocompleteAsync'
import { Input } from '../../../components/form/Input'

import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'

import {
  FetchRouteAnalisisParams,
  useGetRouteAnalysis,
} from '../../../services/endpoints/freights/getRouteAnalysis'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'

const filterRouteAnalysis = yup.object().shape({
  origins: yup.object().required('Cidade de origem inválida'),
  destinations: yup.object().required('Cidade de destino inválida'),
  initial_date: yup.string().required('Data inicial inválida'),
  final_date: yup.string().required('Data final inválida'),
})

type FilterParamsType = {
  origins_value: string
  destinations_value: string
  initial_date: string
  final_date: string
  origins_label?: string
  destinations_label?: string
}

export function RouteAnalysis(): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-01-01')
  })
  const [finalDate, setFinalDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })
  const [filters, setFilters] = useState<FetchRouteAnalisisParams>({} as FetchRouteAnalisisParams)

  const { updateQueryParams, getParams } = useQueryParamUpdater<FilterParamsType>()

  const { data: routeAnalysis } = useGetRouteAnalysis({
    initial_date: filters.initial_date,
    final_date: filters.final_date,
    origins: filters.origins,
    destinations: filters.destinations,
  })

  const isValidGraphic = !!(routeAnalysis?.categories.length && routeAnalysis.series.length)

  const bg = useColorModeValue('white', 'gray.800')

  const { setValue, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(filterRouteAnalysis),
  })

  const { errors, isSubmitting } = formState

  function handleInitialDate(event: FormEvent<HTMLInputElement>): void {
    setInitialDate(event.currentTarget.value)
  }

  function handleFinalDate(event: FormEvent<HTMLInputElement>): void {
    setFinalDate(event.currentTarget.value)
  }

  function validateDates(): boolean {
    const initial_date_formated = new Date(initialDate)
    const final_date_formated = new Date(finalDate)

    if (initial_date_formated > final_date_formated) {
      setError('initial_date', { message: 'Data inicial inválida' })
      setError('final_date', { message: 'Data final inválida' })

      return false
    }

    return true
  }

  const handleFilter: SubmitHandler<FetchRouteAnalisisParams> = async data => {
    const isValidDates = validateDates()

    // eslint-disable-next-line no-useless-return
    if (!isValidDates) return

    updateQueryParams({
      initial_date: data.initial_date,
      final_date: data.final_date,
      origins_value: data.origins.value.toString(),
      destinations_value: data.destinations.value.toString(),
      origins_label: data.origins.label,
      destinations_label: data.destinations.label,
    })

    setFilters(data)
  }

  useEffect(() => {
    const initialDateParam = getParams('initial_date')
    const finalDateParam = getParams('final_date')
    const originsValueParam = getParams('origins_value')
    const destinationsValueParam = getParams('destinations_value')
    const originsLabelParam = getParams('origins_label')
    const destinationsLabelParam = getParams('destinations_label')

    if (initialDateParam || finalDateParam) {
      handleFilter({
        initial_date: initialDateParam,
        final_date: finalDateParam,
        origins: {
          label: originsLabelParam,
          value: originsValueParam,
        },
        destinations: {
          label: destinationsLabelParam,
          value: destinationsValueParam,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const valueChart = {
    options: {
      chart: {
        id: 'line',
      },
      xaxis: {
        categories: routeAnalysis?.categories,
      },
    },
    series: routeAnalysis?.series,
  }

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>

        <Divider my="4" />

        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Cidade de origem
              </Text>
              <AutocompleteAsync
                name="origins"
                setValue={setValue}
                error={errors.origins}
                loadOptions={searchCitiesByName}
                placeholder="Origem"
                initialValue={filters.origins}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Cidade de destino
              </Text>
              <AutocompleteAsync
                name="destinations"
                setValue={setValue}
                error={errors.destinations}
                loadOptions={searchCitiesByName}
                placeholder="Destino"
                initialValue={filters.destinations}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Data inicial
              </Text>
              <Input
                name="initial_date"
                type="date"
                initialValue={filters.initial_date || initialDate}
                error={errors.initial_date}
                setValue={setValue}
                onBlur={handleInitialDate}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Data final
              </Text>
              <Input
                name="final_date"
                type="date"
                initialValue={filters.final_date || finalDate}
                error={errors.final_date}
                setValue={setValue}
                onBlur={handleFinalDate}
              />
            </GridItem>
          </Grid>
          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Box>

      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        {isValidGraphic ? (
          <Chart options={valueChart.options} series={valueChart.series} type="line" width="100%" />
        ) : (
          <Text align="center" color="gray.400" mb={1}>
            Nenhum dado encontrado
          </Text>
        )}
      </Box>
    </Layout>
  )
}
