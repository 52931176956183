import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CiSearch } from 'react-icons/ci'
import { RiAddLine, RiArrowDownSLine, RiArrowUpSLine, RiEditLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { AutocompleteSelectOneAsync, AutocompleteOption, Input } from '../../../components/form'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { searchClientGroup, useGetClients } from '../../../services/endpoints/clients/getClients'
import { handlePrefetchClient } from '../../../services/endpoints/clients/prefetchClient'
import { UpdateClientModal } from '../edit/UpdateClientModal'

type ClientListFilter = {
  nickname?: string
  name?: string
  page?: string
  clientBusinessGroup?: AutocompleteOption
  clientBusinessGroupId?: string
}

export function ListClients(): JSX.Element {
  const {
    onClose: onCloseUpdateClient,
    isOpen: isOpenUpdateClient,
    onOpen: onOpenUpdateClient,
  } = useDisclosure()
  const toast = useToast()
  const [clientIdToUpdate, setClientIdToUpdate] = useState<string>()
  const [orderByName, setOrderByName] = useState<'asc' | 'desc'>()
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<ClientListFilter>({})
  const { updateQueryParams, getParams } = useQueryParamUpdater<ClientListFilter>()

  const { data, isLoading, isFetching, error, isError, refetch } = useGetClients({
    name: filters.name,
    nickname: filters.nickname,
    clientBusinessGroupId: filters.clientBusinessGroupId,
    page,
    orderByName,
    per_page: 10,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  const { setValue, handleSubmit } = useForm({})

  const handleFilter: SubmitHandler<ClientListFilter> = dataFilter => {
    setFilters({
      ...filters,
      name: dataFilter.name,
      nickname: dataFilter.nickname,
      clientBusinessGroupId: dataFilter.clientBusinessGroup?.value
        ? String(dataFilter.clientBusinessGroup?.value)
        : undefined,
    })
    setPage(1)
  }

  useEffect(() => {
    updateQueryParams({
      name: filters.name,
      nickname: filters.nickname,
      clientBusinessGroupId: filters.clientBusinessGroupId,
      page: page > 1 ? page.toString() : undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page])

  useEffect(() => {
    const name = getParams('name')
    const nickname = getParams('nickname')
    const pageParam = getParams('page')
    const clientBusinessGroupIdParam = getParams('clientBusinessGroupId')

    if (pageParam) {
      setPage(Number(pageParam))
    }

    if (name || nickname || clientBusinessGroupIdParam) {
      setFilters({
        name,
        nickname,
        clientBusinessGroupId: clientBusinessGroupIdParam,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout hasBackground>
      <Flex mb={['6', '8']} direction="column" gridGap={3}>
        <Flex direction="column" gridGap="4">
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="normal">
              Clientes
              {!isLoading && isFetching && <Spinner size="sm" ml="4" />}
            </Heading>

            <Button
              as={Link}
              to="/clients/create"
              size="sm"
              colorScheme="orange"
              leftIcon={<Icon as={RiAddLine} fontSize="18" />}
            >
              Novo
            </Button>
          </Flex>

          <form onSubmit={handleSubmit(handleFilter)}>
            <Grid templateColumns="repeat(12, 1fr)" gap="4">
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  placeholder="Filtrar por apelido"
                  name="nickname"
                  setValue={setValue}
                  initialValue={filters?.nickname}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <Input
                  placeholder="Filtrar por nome social"
                  name="name"
                  setValue={setValue}
                  initialValue={filters?.name}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 6]}>
                <AutocompleteSelectOneAsync
                  placeholder="Filtrar por grupo empresarial"
                  name="clientBusinessGroup"
                  setValue={setValue}
                  loadOptions={searchClientGroup}
                  initialValue={
                    filters.clientBusinessGroupId
                      ? {
                          value:
                            data?.data.find(
                              client => client?.clientBusinessGroup?.id === filters?.clientBusinessGroupId,
                            )?.id || '',
                          label:
                            data?.data.find(
                              client => client?.clientBusinessGroup?.id === filters?.clientBusinessGroupId,
                            )?.name || '',
                        }
                      : undefined
                  }
                />
              </GridItem>
              <GridItem colSpan={[12, 2]}>
                <Button type="submit" leftIcon={<CiSearch />} colorScheme="blue" mt={1}>
                  Buscar
                </Button>
              </GridItem>
            </Grid>
          </form>
        </Flex>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Text>Erro ao carregar dados</Text>
      ) : (
        <>
          <Table d={['block', 'block', 'block', 'block', 'table']} overflowX="scroll">
            <Thead>
              <Tr>
                <Th />

                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByName('asc')
                        if (orderByName && orderByName.includes('asc')) {
                          setOrderByName('desc')
                        }
                      }}
                      cursor="pointer"
                    >
                      Empresa
                    </Box>
                    {orderByName && (
                      <>
                        {orderByName.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByName.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>

                <Th>Grupo Emp.</Th>
                <Th>CNPJ</Th>
                <Th>Telefone</Th>
                <Th>E-mail</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.data.map(client => (
                  <Tr key={client.id}>
                    <Td>
                      <Stack spacing={['2', '2']}>
                        <Tooltip label="Editar">
                          <Button
                            size="xs"
                            colorScheme="yellow"
                            onClick={() => {
                              setClientIdToUpdate(client.id)
                              onOpenUpdateClient()
                            }}
                          >
                            <Icon as={RiEditLine} fontSize={16} />
                          </Button>
                        </Tooltip>
                      </Stack>
                    </Td>
                    <Td>
                      <Tooltip label={`Ver ${client.name}`} fontSize="lg">
                        <Box>
                          <Link
                            to={`/clients/show/${client.id}`}
                            color="peru"
                            onMouseEnter={() => handlePrefetchClient(client.id)}
                          >
                            <Text fontWeight="bold" cursor="pointer">
                              {client.nickname}
                            </Text>
                            <Text cursor="pointer" fontSize="sm" color="gray.400">
                              {client.name}
                            </Text>
                          </Link>
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td>{client.clientBusinessGroup?.name}</Td>
                    <Td>{client.cnpj}</Td>
                    <Td>{client.phone}</Td>
                    <Td>{client.email}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          <Pagination
            currentPage={page}
            totalCountOfRegisters={Number(data?.total)}
            registersPerPage={10}
            onPageChange={setPage}
            mt="8"
          />
          {clientIdToUpdate && (
            <UpdateClientModal
              onClose={() => {
                onCloseUpdateClient()
                refetch()
              }}
              isOpen={isOpenUpdateClient}
              client_id={clientIdToUpdate}
            />
          )}
        </>
      )}
    </Layout>
  )
}
