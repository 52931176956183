import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { RiInformationLine, RiLogoutBoxRLine, RiMoonLine, RiSunLine } from 'react-icons/ri'
import { useAuth } from '../../contexts/AuthContext'
import { UpdateUserModal } from '../../pages/users/UpdateUserModal'

export function Profile(): JSX.Element {
  const { colorMode, toggleColorMode } = useColorMode()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const { user, signOut } = useAuth()

  return (
    <Flex align="center">
      <Menu placement="bottom-end" autoSelect={false}>
        <MenuButton aria-label="Menu Options">
          <Avatar size="sm" name={user.name} src="/logo192.png" />
        </MenuButton>
        <MenuList zIndex={999}>
          <MenuItem>
            <Box mr="4" textAlign="left">
              <Text fontSize="sm">{user.name}</Text>
              <Text color="gray.400" fontSize="small">
                {user.email}
              </Text>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onOpen()
            }}
            icon={<Icon as={RiInformationLine} fontSize={20} />}
          >
            Meus dados
          </MenuItem>
          {colorMode === 'light' ? (
            <MenuItem icon={<Icon as={RiMoonLine} fontSize={20} />} onClick={() => toggleColorMode()}>
              Modo escuro
            </MenuItem>
          ) : (
            <MenuItem icon={<Icon as={RiSunLine} fontSize={20} />} onClick={() => toggleColorMode()}>
              Modo claro
            </MenuItem>
          )}
          <MenuDivider />
          <MenuItem icon={<Icon as={RiLogoutBoxRLine} fontSize={20} />} onClick={signOut}>
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
      <UpdateUserModal user_id={user.id} isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
