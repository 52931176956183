/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export interface UpdateInteractionsTimeline {
  id: string
  comment?: string
  user_id?: string
}

async function updateInteractionsTimeline({ id, ...data }: UpdateInteractionsTimeline) {
  return apiServer.put(`/update-interaction-timeline/${id}`, data)
}

export function usePutInteractionsTimeline(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateInteractionsTimeline, unknown>,
) {
  return useMutation(async (data: UpdateInteractionsTimeline) => updateInteractionsTimeline(data), options)
}
