import { Box, Button, Divider, Flex, FormLabel, Heading, Icon, Link, Text, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Input } from '../../../components/form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Layout } from '../../../layout'
import { useChangePassword } from '../../../services/endpoints/users/changePassword'

const recoverPasswordSchema = yup.object().shape({
  password: yup.string().required('Senha obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais')
    .required('Confirmação de senha obrigatória'),
  recoveryCode: yup.string().required('Código de recuperação obrigatório'),
})

type RecoverPasswordFormData = {
  password: string
  confirmPassword: string
  recoveryCode: string
}

interface TokenPayload {
  id: string
  recoveryCode: string
  exp: number
}

export function RecoverPassword(): JSX.Element {
  const toast = useToast()
  const token = useQueryParams('token')
  const history = useHistory()
  const [isLinkExpired, setIsLinkExpired] = useState(false)
  const [tokenPayload, setTokenPayload] = useState<TokenPayload | null>(null)

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(recoverPasswordSchema),
  })

  const { mutateAsync: changePassword, isLoading } = useChangePassword({
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      toast({
        title: 'A senha foi alterada com sucesso',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
      history.push('/')
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const password = watch('password')
  const confirmPassword = watch('confirmPassword')

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode<TokenPayload>(token)
        setTokenPayload(decodedToken)
        const currentTime = Date.now() / 1000
        if (decodedToken.exp < currentTime) {
          setIsLinkExpired(true)
        }
      } catch (error) {
        setIsLinkExpired(true)
      }
    }
  }, [token])

  const handleRecoverPassword: SubmitHandler<RecoverPasswordFormData> = async data => {
    if (data.recoveryCode !== tokenPayload?.recoveryCode) {
      toast({
        title: 'Código de recuperação inválido',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
      return
    }
    await changePassword({ id: tokenPayload.id, password: data.password })
  }

  return (
    <Layout hasBackground showSidebar={false} showHeader={false}>
      <Flex direction="column" align="left" justify="center" p="4">
        <Flex direction="row" align="left" justify="left">
          <Button
            as={Link}
            to="/login"
            leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}
            onClick={() => {
              history.push('/')
            }}
          >
            Voltar
          </Button>
          <Heading size="lg" fontWeight="normal" ml={4}>
            Recuperar senha
          </Heading>
        </Flex>
        <Divider my="4" />
        <Box
          as="form"
          w="100%"
          maxW="400px"
          p="8"
          borderRadius="8px"
          shadow="md"
          onSubmit={handleSubmit(handleRecoverPassword)}
          noValidate
        >
          {isLinkExpired ? (
            <Text color="red.500" fontWeight="bold" textAlign="center">
              O link de recuperação de senha expirou. Por favor, solicite um novo link.
            </Text>
          ) : (
            <>
              <Flex direction="column" align="left" justify="center" mt="4">
                <FormLabel htmlFor="recoveryCode" mt="4">
                  Código de recuperação:
                </FormLabel>
                <Input
                  id="recoveryCode"
                  name="recoveryCode"
                  type="text"
                  placeholder="Código de recuperação"
                  onChange={e => setValue('recoveryCode', e.target.value)}
                  isInvalid={!!errors.recoveryCode}
                  errorBorderColor="red.300"
                  setValue={setValue}
                />
                {errors.recoveryCode && (
                  <Text mt="4" color="red.300" fontSize="sm">
                    {errors.recoveryCode.message}
                  </Text>
                )}
              </Flex>

              <Flex direction="column" align="left" justify="center" mt="4">
                <FormLabel htmlFor="password" mt="4">
                  Digite sua nova senha:
                </FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Senha"
                  onChange={e => setValue('password', e.target.value)}
                  isInvalid={!!errors.password}
                  errorBorderColor="red.300"
                  minLength={10}
                  setValue={setValue}
                />
                {errors.password && (
                  <Text mt="4" color="red.300" fontSize="sm">
                    {errors.password.message}
                  </Text>
                )}
              </Flex>

              <Flex direction="column" align="left" justify="center" mt="4">
                <FormLabel htmlFor="confirmPassword" mt="4">
                  Confirme sua nova senha:
                </FormLabel>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirmação de senha"
                  onChange={e => setValue('confirmPassword', e.target.value)}
                  isInvalid={!!errors.confirmPassword}
                  errorBorderColor="red.300"
                  minLength={10}
                  setValue={setValue}
                />
                {errors.confirmPassword && (
                  <Text mt="4" color="red.300" fontSize="sm">
                    {errors.confirmPassword.message}
                  </Text>
                )}
              </Flex>
              <Text mt="4" color="red.300" fontSize="sm">
                {password !== confirmPassword && 'As senhas precisam ser iguais'}
              </Text>
              <Flex mt="4" justify="left">
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isLoading}
                  isDisabled={password !== confirmPassword}
                >
                  Recuperar senha
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </Layout>
  )
}
