export const vehicleColorsDatamex = [
  { label: '', value: '' },
  { label: 'Amarelo', value: 'Amarelo' },
  { label: 'Azul', value: 'Azul' },
  { label: 'Bege', value: 'Bege' },
  { label: 'Branco', value: 'Branco' },
  { label: 'Cinza', value: 'Cinza' },
  { label: 'Dourado', value: 'Dourado' },
  { label: 'Grena', value: 'Grena' },
  { label: 'Laranja', value: 'Laranja' },
  { label: 'Marrom', value: 'Marrom' },
  { label: 'Prata', value: 'Prata' },
  { label: 'Preto', value: 'Preto' },
  { label: 'Rosa', value: 'Rosa' },
  { label: 'Roxo', value: 'Roxo' },
  { label: 'Verde', value: 'Verde' },
  { label: 'Vermelho', value: 'Vermelho' },
  { label: 'Fantasia', value: 'Fantasia' },
]

export function alterColorsGenderDatamex(color: string | undefined): string {
  if (!color) return ''
  const colorsGender: Record<string, string> = {
    AMARELA: 'AMARELO',
    VERMELHA: 'VERMELHO',
    AZUL: 'AZUL', // Azul é neutro, não muda com o gênero
    VERDE: 'VERDE', // Verde é neutro, não muda com o gênero
    ROXA: 'ROXO',
    LARANJA: 'LARANJA', // Laranja é neutro, não muda com o gênero
    MARROM: 'MARROM', // Marrom é neutro, não muda com o gênero
    BRANCA: 'BRANCO',
    PRETA: 'PRETO',
    DOURADA: 'DOURADO',
  }
  return colorsGender[color] || color
}
