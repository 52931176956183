import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { publicApiServer } from '../../publicApi'
import { ResponseApiType } from '../../types'
import { FreightRelationsType } from '../../types/FreightType'
import parseCurrency from '../../utils/parseCurrency'
import { getNextStatus, getPriceType, getStatus, getType } from './getFreights'
import { FormattedOneFreight } from './getOneFreight'

export interface IFilterBuonny {
  initial_date?: string
  final_date?: string
}

type Input = {
  freight_id: string
  relations?: FreightRelationsType[]
}

export async function fetchOneFreightTrackingClient({
  freight_id,
  relations,
}: Input): Promise<FormattedOneFreight> {
  const { data: res } = await publicApiServer.get<ResponseApiType<FormattedOneFreight>>(
    `/freights/find-without-auth/${freight_id}`,
    { params: { relations } },
  )
  const { data } = res
  const createdAt = new Date(data.created_at)
  const receivedAt = data.received_at ? new Date(data.received_at) : new Date()
  const linkMotoristAt = data.link_motorist_at ? new Date(data.link_motorist_at) : undefined
  const collectItemAt = data.collect_cargo_at ? new Date(data.collect_cargo_at) : undefined
  const deliverItemAt = data.delivered_cargo_at ? new Date(data.delivered_cargo_at) : undefined

  const freight = {
    ...data,
    formatted_type: getType(data.type),
    formatted_status: getStatus(data.status),
    formatted_received_at: format(receivedAt, "yyyy-MM-dd'T'HH:mm:ss"),
    formatted_link_motorist_at: linkMotoristAt ? format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
    input_collect_cargo_at: collectItemAt ? format(collectItemAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
    formatted_delivered_cargo_at: deliverItemAt ? format(deliverItemAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
    next_status: getNextStatus(data.status),
    origin_city: data.origin ? `${data.origin?.name} - ${data.origin?.state?.uf}` : '',
    destination_city: data.destination ? `${data.destination?.name} - ${data.destination?.state?.uf}` : '',
    formatted_suggested_price: parseCurrency(data.suggested_price),
    formatted_agreed_price: parseCurrency(data.agreed_price),
    formatted_suggested_price_type: getPriceType(data.suggested_price_type),
    formatted_vehicle:
      data.vehicle?.vehicle_category && data.vehicle?.vehicle_body
        ? `${data.vehicle?.vehicle_category?.name} / ${data.vehicle?.vehicle_body?.name}`
        : '',
    formatted_has_toll: data.has_toll ? 'Sim' : 'Não',
    created_at: format(createdAt, 'dd/MM'),
    created_at_hour: format(createdAt, 'HH:mm'),
    cargo_value: data.cargo?.value ? parseCurrency(data.cargo.value) : '',
    cargo_dimensions: data.cargo ? `${data.cargo?.width} x ${data.cargo?.height} x ${data.cargo?.depth}` : '',
    cargo_weight: data.cargo?.weight ? `${data.cargo?.weight} kg` : '',
    is_cargo_complement: data.cargo?.has_complement ? 'Sim' : 'Não',
    cargo_require_tracker: data.cargo?.require_tracker ? 'Sim' : 'Não',
    formatted_origin_coords: data?.origin_coords
      ? {
          lat: String(data?.origin_coords).split(',')[0],
          lng: String(data?.origin_coords).split(',')[1],
        }
      : undefined,
    formatted_destination_coords: data?.destination_coords
      ? {
          lat: String(data?.destination_coords).split(',')[0],
          lng: String(data?.destination_coords).split(',')[1],
        }
      : undefined,
    created_at_default: createdAt,
  }

  return freight
}

export function useGetFreightTrackingClient(input: Input): UseQueryResult<FormattedOneFreight> {
  return useQuery(['freight', input], () => fetchOneFreightTrackingClient(input), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
    refetchOnWindowFocus: 'always',
  })
}
