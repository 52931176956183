/* eslint-disable no-param-reassign */
import axios from 'axios'
import https from 'https'

const { REACT_APP_BASE_TRUCKPAD_API, REACT_APP_TRUCKPAD_TOKEN, REACT_APP_TRUCKPAD_COMPANY } = process.env

const agent = new https.Agent({
  rejectUnauthorized: false,
})

const truckpadApi = axios.create({
  baseURL: REACT_APP_BASE_TRUCKPAD_API,
  headers: {
    Authorization: REACT_APP_TRUCKPAD_TOKEN,
    company: REACT_APP_TRUCKPAD_COMPANY,
  },
  httpsAgent: agent,
})

export { truckpadApi }
