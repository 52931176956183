/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiFilter2Line,
  RiSearchLine,
  RiSmartphoneLine,
  RiStarLine,
  RiUserAddLine,
  RiUserLine,
  RiUserStarLine,
  RiWhatsappLine,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import ErrorFeedBack from '../../../components/UI/ErrorFeedBack'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import { WaitQueryParams } from '../../../components/WaitQueryParams'
import { Input } from '../../../components/form'
import { AutocompleteAsync } from '../../../components/form/AutocompleteAsync'
import { AutocompleteMulti } from '../../../components/form/AutocompleteMulti'
import { AutocompleteMultiAsync } from '../../../components/form/AutocompleteMultiAsync'
import { InputMask } from '../../../components/form/InputMask'
import { Select } from '../../../components/form/Select'
import { Switch } from '../../../components/form/Switch'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import { FetchMotoristsParams, useGetMotorists } from '../../../services/endpoints/motorists/getMotorists'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../../../services/endpoints/vehicles/getVehicleCategories'
import { getAllDDDs } from '../../../services/utils/getAllDDDs'
import { validateCpf } from '../../../services/utils/validateCpf'
import ButtonShowInfosBlackList from '../../black-list/modals/buttons/ButtonShowInfosBlackList'
import { SendWhatsAppMessageModal } from '../modals/SendWhatsAppMessageModal'

interface FilterMotoristsFormData {
  page?: number
  license_plate: string
  per_page?: number
  name?: string
  phone?: string
  cpf?: string
  ddds?: AutocompleteOption[]
  radius?: number
  spotx?: boolean
  type?: number
  origins?: AutocompleteOption[]
  destinations?: AutocompleteOption[]
  vehicle_categories?: AutocompleteOption[]
  vehicle_bodies?: AutocompleteOption[]
  checkins?: AutocompleteOption[]
  address_city_id?: AutocompleteOption
  motorist_name: string
}

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  license_plate: yup.string(),
  per_page: yup.number(),
  name: yup.string(),
  phone: yup.string(),
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (value) return validateCpf(value)
    return true
  }),
  radius: yup.number(),
  spotx: yup.boolean(),
  type: yup.string(),
  origins: yup.array().of(yup.object()),
  destinations: yup.array().of(yup.object()),
  vehicle_categories: yup.array().of(yup.object()),
  vehicle_bodies: yup.array().of(yup.object()),
  checkins: yup.array().of(yup.object()),
  ddds: yup.array().of(yup.object()),
})

interface FormListMotoristsProps {
  isMotoristSpotx?: boolean
}

export function FormListMotorists({ isMotoristSpotx }: FormListMotoristsProps): JSX.Element {
  const key = useRef(1)
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    page?: string
    vehicle_categories?: string
    vehicle_bodies?: string
    checkins?: string
    license_plate?: string
    type?: string
    phone?: string
    cpf?: string
    ddds?: string
    address_city?: string
    motorist_name?: string
    is_spotx?: string
  }>()

  // filters controlers

  const [filters, setFilters] = useState<FetchMotoristsParams>({
    page: 1,
    spotx: isMotoristSpotx ? true : undefined,
  })
  const [loadingQueryParams, setLoadingQueryParams] = useState(false)

  // orders
  const [orderByName, setOrderByName] = useState<'asc' | 'desc' | undefined>()
  const [orderByCheckIn, setOrderByCheckIn] = useState<'asc' | 'desc' | undefined>()
  const [orderByRateScore, setOrderByRateScore] = useState<'asc' | 'desc' | undefined>()
  const [showCheckboxToSelectMotorists, setShowCheckboxToSelectMotorists] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const [selectedMotorists, setSelectedMotorists] = useState<string[]>([])

  // options
  const [vehicleCategories, setVehicleCategories] = useState<AutocompleteOption[]>()
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()

  const {
    isOpen: isSendWhatsAppModalOpen,
    onOpen: onSendWhatsAppModalOpen,
    onClose: onSendWhatsAppModalClose,
  } = useDisclosure()

  const { setValue, handleSubmit, formState, getValues, reset } = useForm({
    resolver: yupResolver(filterFormSchema),
  })

  const { errors, isSubmitting } = formState

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilterMotorists: SubmitHandler<FilterMotoristsFormData> = async data => {
    const dataFilter = {
      phone: data.phone,
      cpf: data.cpf,
      license_plate: data.license_plate,
      type: data.type,
      spotx: data.spotx ? true : undefined,
      checkins: data.checkins?.map(i => i.value),
      origins: data.origins?.map(i => i.value),
      destinations: data.destinations?.map(i => i.value),
      vehicle_categories: data.vehicle_categories?.map(i => i.value),
      vehicle_bodies: data.vehicle_bodies?.map(i => i.value),
      ddds: data.ddds?.map(i => i.value),
      address_city_id: data?.address_city_id?.value,
      name: data.motorist_name,
    } as FetchMotoristsParams

    setFilters(prev => ({ ...prev, ...dataFilter, page: data.page || 1 }))
    updateQueryParams({
      vehicle_categories:
        data.vehicle_categories &&
        JSON.stringify(data.vehicle_categories?.map(i => ({ label: i.label, value: i.value }))),
      vehicle_bodies:
        data.vehicle_bodies &&
        JSON.stringify(data.vehicle_bodies?.map(i => ({ label: i.label, value: i.value }))),
      checkins:
        data.checkins && JSON.stringify(data.checkins?.map(i => ({ label: i.label, value: i.value }))),
      license_plate: data.license_plate,
      type: data.type?.toString(),
      phone: data.phone,
      cpf: data.cpf,
      ddds: JSON.stringify(data.ddds?.map(i => ({ label: i.label, value: i.value }))),
      address_city: data?.address_city_id
        ? JSON.stringify({
            label: data?.address_city_id?.label,
            value: data?.address_city_id?.value,
          })
        : undefined,
      motorist_name: data.motorist_name,
      is_spotx: data.spotx ? 'true' : undefined,
      page: data.page?.toString() || '1',
    })

    setShowCheckboxToSelectMotorists(true)
  }

  const handleGetFiltersOptions = useCallback(async () => {
    const vehicleCategoriesOptions = await getVehicleCategories()
    const vehicleBodiesOptions = await getVehicleBodies()
    setVehicleCategories(vehicleCategoriesOptions)
    setVehicleBodies(vehicleBodiesOptions)
  }, [])

  useEffect(() => {
    handleGetFiltersOptions()
  }, [handleGetFiltersOptions])

  useEffect(() => {
    const vehicleCategoriesQuery = getParams('vehicle_categories')
    const vehicleBodiesQuery = getParams('vehicle_bodies')
    const checkinsQuery = getParams('checkins')
    const licensePlateQuery = getParams('license_plate')
    const typeQuery = getParams('type')
    const phoneQuery = getParams('phone')
    const cpfQuery = getParams('cpf')
    const dddsQuery = getParams('ddds')
    const originQuery = getParams('address_city')
    const motoristNameQuery = getParams('motorist_name')
    const spotxQuery = getParams('is_spotx')
    const pageQuery = getParams('page')

    const loadingQuery = async () => {
      try {
        const vehicleCategoriesParsed = (await JSON.parse(vehicleCategoriesQuery)) as AutocompleteOption[]
        const vehicleBodiesParsed = (await JSON.parse(vehicleBodiesQuery)) as AutocompleteOption[]
        const checkinsParsed = (await JSON.parse(checkinsQuery)) as AutocompleteOption[]
        const dddsParsed = (await JSON.parse(dddsQuery)) as AutocompleteOption[]
        const addressCityParsed = (await JSON.parse(originQuery)) as AutocompleteOption

        setValue('vehicle_categories', vehicleCategoriesParsed || undefined)
        setValue('vehicle_bodies', vehicleBodiesParsed || undefined)
        setValue('checkins', checkinsParsed || undefined)
        setValue('license_plate', licensePlateQuery || undefined)
        setValue('type', Number(typeQuery) || undefined)
        setValue('phone', phoneQuery || undefined)
        setValue('cpf', cpfQuery || undefined)
        setValue('ddds', dddsParsed || undefined)
        setValue('address_city_id', addressCityParsed || undefined)
        setValue('motorist_name', motoristNameQuery || undefined)
        setValue('spotx', spotxQuery === 'true' ? true : undefined)
        setValue('page', Number(pageQuery) || 1)

        handleSubmit(handleFilterMotorists)()
        setLoadingQueryParams(false)
      } catch (error) {
        toast({
          title: 'Erro ao carregar filtros',
          status: 'warning',
          description: 'Por favor, verifique os dados e tente novamente',
          isClosable: true,
          position: 'top-right',
        })
        updateQueryParams({
          vehicle_categories: undefined,
          vehicle_bodies: undefined,
          checkins: undefined,
          address_city: undefined,
          cpf: undefined,
          ddds: undefined,
          license_plate: undefined,
          type: undefined,
          phone: undefined,
          motorist_name: undefined,
          is_spotx: undefined,
        })
      } finally {
        setLoadingQueryParams(false)
      }
    }
    if (
      vehicleCategoriesQuery ||
      vehicleBodiesQuery ||
      checkinsQuery ||
      licensePlateQuery ||
      typeQuery ||
      phoneQuery ||
      cpfQuery ||
      dddsQuery ||
      originQuery ||
      motoristNameQuery ||
      spotxQuery ||
      pageQuery
    ) {
      loadingQuery()
      setLoadingQueryParams(true)
    }
  }, [])

  const {
    data: motorists,
    isLoading,
    error,
  } = useGetMotorists({
    ...filters,
    page: filters?.page || 1,
    orderByName,
    orderByCheckIn,
    orderByRateScore,
    per_page: 10,
  })

  return (
    <Layout>
      <PageHeader
        title={`Lista de motoristas ${isMotoristSpotx ? 'SpotX' : ''}`}
        icon={isMotoristSpotx ? RiUserStarLine : RiUserLine}
        rightContent={
          <>
            <Button
              as={Link}
              to="/motorists/create"
              colorScheme="orange"
              leftIcon={<Icon as={RiUserAddLine} />}
            >
              Cadastrar motorista
            </Button>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />

      <Filter showFilter={onShowFilter} key={key.current}>
        <form onSubmit={handleSubmit(handleFilterMotorists)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            {!isMotoristSpotx && (
              <GridItem mt="-1" colSpan={[6, 3]} display="flex" justifyContent="center" alignItems="center">
                <Switch
                  name="spotx"
                  error={errors.spotx}
                  defaultChecked={getValues('spotx')}
                  setValue={setValue}
                  label="Motoristas SpotX"
                />
              </GridItem>
            )}

            {/* <GridItem colSpan={[8, 3]}>
              <AutocompleteMultiAsync
                name="origins"
                error={errors.origin_id}
                setValue={setValue}
                loadOptions={searchCitiesByName}
                placeholder="Origem"
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <AutocompleteMultiAsync
                name="destinations"
                error={errors.destination_id}
                setValue={setValue}
                loadOptions={searchCitiesByName}
                placeholder="Destino"
              />
            </GridItem> */}
            {vehicleCategories &&
              vehicleBodies &&
              vehicleCategories.length > 0 &&
              vehicleBodies.length > 0 && (
                <>
                  <GridItem colSpan={[6, 3]}>
                    <AutocompleteMulti
                      name="vehicle_categories"
                      error={errors.vehicle_category}
                      initialValue={getValues('vehicle_categories')}
                      setValue={setValue}
                      options={[{ label: '', value: '' }, ...vehicleCategories]}
                      placeholder="Veículos"
                    />
                  </GridItem>

                  <GridItem colSpan={[6, 3]}>
                    <AutocompleteMulti
                      name="vehicle_bodies"
                      initialValue={getValues('vehicle_bodies')}
                      error={errors.vehicle_body}
                      setValue={setValue}
                      options={[{ label: '', value: '' }, ...vehicleBodies]}
                      placeholder="Carrocerias"
                    />
                  </GridItem>
                </>
              )}

            <GridItem colSpan={[12, 3]}>
              <WaitQueryParams condition={!loadingQueryParams}>
                <AutocompleteMultiAsync
                  name="checkins"
                  initialValue={getValues('checkins')}
                  error={errors.checkin}
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                  placeholder="Checkins"
                />
              </WaitQueryParams>
            </GridItem>

            <GridItem colSpan={[6, 3]}>
              <InputMask
                name="license_plate"
                mask="aaa9*99"
                placeholder="Placa"
                initialValue={getValues('license_plate')}
                error={errors.license_plate}
                setValue={setValue}
                uppercaseAll
              />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <Select
                name="type"
                error={errors.type}
                initialValue={getValues('type')}
                setValue={setValue}
                options={[
                  { label: 'Todos', value: '' },
                  { label: 'Pessoa física', value: '1' },
                  { label: 'Pessoa jurídica', value: '2' },
                ]}
              />
            </GridItem>

            <GridItem colSpan={[6, 3]}>
              <InputMask
                mask="(99) 9 9999-9999"
                registerOnlyNumbers
                maskPlaceholder=""
                initialValue={getValues('phone')}
                name="phone"
                error={errors.phone}
                setValue={setValue}
                placeholder="Telefone"
              />
            </GridItem>

            <GridItem colSpan={[6, 3]}>
              <InputMask
                mask="999.999.999-99"
                maskPlaceholder=""
                name="cpf"
                initialValue={getValues('cpf')}
                error={errors.cpf}
                setValue={setValue}
                placeholder="CPF"
                registerOnlyNumbers
              />
            </GridItem>

            <GridItem colSpan={[6, 3]}>
              <WaitQueryParams condition={!loadingQueryParams}>
                <AutocompleteMulti
                  name="ddds"
                  initialValue={getValues('ddds')}
                  error={errors.ddds}
                  setValue={setValue}
                  placeholder="DDDs"
                  options={getAllDDDs}
                />
              </WaitQueryParams>
            </GridItem>

            <GridItem colSpan={[6, 3]}>
              <WaitQueryParams condition={!loadingQueryParams}>
                <AutocompleteAsync
                  name="address_city_id"
                  initialValue={getValues('address_city_id') || ''}
                  error={errors.address_city_id}
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                  placeholder="Cidade"
                />
              </WaitQueryParams>
            </GridItem>

            <GridItem colSpan={[6, 6]}>
              <Input
                name="motorist_name"
                initialValue={getValues('motorist_name')}
                setValue={setValue}
                placeholder="Nome do motorista"
                h="45px"
                error={errors.motorist_name}
              />
            </GridItem>
          </Grid>
          <Flex justify="flex-end" mt="4" gridGap="2">
            <Button
              type="button"
              colorScheme="orange"
              variant="outline"
              onClick={() => {
                reset()
                key.current += 1
                handleSubmit(handleFilterMotorists)()
              }}
            >
              limpar filtros
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} px="4" py="2" borderRadius="8" shadow="md" h="667">
        <Flex mb="4" justify="end" align="center">
          {(allChecked || selectedMotorists.length > 0) && (
            <Button
              size="sm"
              colorScheme="whatsapp"
              leftIcon={<Icon as={RiWhatsappLine} fontSize={18} />}
              onClick={onSendWhatsAppModalOpen}
            >
              Enviar mensagem para o whatsapp
            </Button>
          )}
        </Flex>
        {isLoading ? (
          <Flex align="center" justifyContent="center" h="100%">
            <Spinner />
          </Flex>
        ) : error ? (
          <ErrorFeedBack message="Erro ao buscar motoristas. Tente novamente mais tarde." />
        ) : (
          <Table size="sm" d={['block', 'block', 'table']} overflowX="auto">
            <Thead>
              <Tr>
                <Th px={0} textAlign="center" pl="2" />
                {showCheckboxToSelectMotorists && (
                  <Th px={0} textAlign="center" pl="2">
                    <Checkbox
                      colorScheme="orange"
                      isChecked={allChecked}
                      isIndeterminate={selectedMotorists.length > 0}
                      onChange={e => {
                        const { checked } = e.target
                        setAllChecked(checked)
                        if (!checked) setSelectedMotorists([])
                      }}
                    />
                  </Th>
                )}
                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByRateScore('asc')
                        setOrderByCheckIn(undefined)
                        setOrderByName(undefined)
                        if (orderByRateScore && orderByRateScore.includes('asc')) {
                          setOrderByRateScore('desc')
                          setOrderByCheckIn(undefined)
                          setOrderByName(undefined)
                        }
                      }}
                      cursor="pointer"
                    >
                      Nota
                    </Box>
                    {orderByRateScore && (
                      <>
                        {orderByRateScore.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByRateScore.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>
                <Th>Fretes</Th>
                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByName('asc')
                        setOrderByRateScore(undefined)
                        setOrderByCheckIn(undefined)
                        if (orderByName && orderByName.includes('asc')) {
                          setOrderByName('desc')
                          setOrderByRateScore(undefined)
                          setOrderByCheckIn(undefined)
                        }
                      }}
                      cursor="pointer"
                    >
                      Motorista
                    </Box>
                    {orderByName && (
                      <>
                        {orderByName.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByName.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>
                <Th>
                  <Flex gridGap={2} alignItems="center" color="blue.400">
                    <Box
                      onClick={() => {
                        setOrderByCheckIn('asc')
                        setOrderByRateScore(undefined)
                        setOrderByName(undefined)
                        if (orderByCheckIn && orderByCheckIn.includes('asc')) {
                          setOrderByCheckIn('desc')
                          setOrderByRateScore(undefined)
                          setOrderByName(undefined)
                        }
                      }}
                      cursor="pointer"
                    >
                      Últ. CheckIn
                    </Box>
                    {orderByCheckIn && (
                      <>
                        {orderByCheckIn.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                        {orderByCheckIn.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                      </>
                    )}
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {motorists &&
                motorists.data.map(motorist => {
                  const motoristIsSelected = selectedMotorists.includes(motorist.id)
                  let shouldChecked = false
                  let disabledCheckBox = false

                  if (allChecked && !motoristIsSelected) shouldChecked = true
                  if (allChecked && motoristIsSelected) shouldChecked = false
                  if (!allChecked && motoristIsSelected && Number(motorist.rate_score) >= 3)
                    shouldChecked = true

                  if (!allChecked && motoristIsSelected && !motorist.rate_score) shouldChecked = true

                  if (allChecked && Number(motorist.rate_score) < 3 && Number(motorist.rate_score) > 0)
                    shouldChecked = false
                  if (Number(motorist.rate_score) < 3 && Number(motorist.rate_score) > 0)
                    disabledCheckBox = true
                  return (
                    <Tr key={motorist.id}>
                      <Td px={0} textAlign="center" pl="2">
                        {motorist.blacK_list && (
                          <ButtonShowInfosBlackList
                            black_list={{
                              ...motorist.blacK_list,
                              motorist,
                              created_at: format(new Date(motorist.blacK_list.created_at), 'dd/MM/yyyy'),
                            }}
                          />
                        )}
                      </Td>
                      {showCheckboxToSelectMotorists && (
                        <Td px={0} textAlign="center" pl="2">
                          <Checkbox
                            isDisabled={disabledCheckBox}
                            colorScheme="orange"
                            isChecked={shouldChecked}
                            onChange={() => {
                              if (motoristIsSelected) {
                                setSelectedMotorists(state => state.filter(i => i !== motorist.id))
                              } else {
                                setSelectedMotorists(state => [...state, motorist.id])
                              }
                            }}
                          />
                        </Td>
                      )}
                      <Td textAlign="center">
                        <Flex alignItems="center" gridGap="2">
                          {motorist.rate_score && (
                            <>
                              <Icon as={RiStarLine} color="yellow.500" fontSize={20} />
                              <Text fontWeight="bold" color="yellow.600">
                                {motorist.rate_score}
                              </Text>
                            </>
                          )}
                          {motorist?.has_app && (
                            <Tooltip hasArrow label="Este motorista possui o app instalado">
                              <Flex>
                                <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                                  <Flex direction="row">
                                    <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                                  </Flex>
                                </Text>
                              </Flex>
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                      <Td whiteSpace="nowrap">{motorist.total_freights}</Td>
                      <Td whiteSpace="nowrap">
                        <Box>
                          <Link to={`/motorists/show/${motorist.id}`}>
                            {motorist.name}
                            <Text mt="1" color="gray.500">
                              {motorist.phone}
                            </Text>
                          </Link>
                        </Box>
                      </Td>

                      <Td whiteSpace="nowrap">
                        <Box>
                          <Text>{motorist.last_checkin_city}</Text>
                          <Text fontSize="sm" color="gray.400">
                            {motorist.last_checkin_time}
                          </Text>
                        </Box>
                      </Td>
                    </Tr>
                  )
                })}
            </Tbody>
          </Table>
        )}

        {motorists && (
          <Pagination
            currentPage={filters.page || 1}
            totalCountOfRegisters={motorists.total}
            registersPerPage={10}
            onPageChange={handleChangePage}
            showControlButtons
            showInput
            p="6"
          />
        )}
      </Box>

      <SendWhatsAppMessageModal
        isOpen={isSendWhatsAppModalOpen}
        onClose={() => {
          onSendWhatsAppModalClose()
          setAllChecked(false)
          setSelectedMotorists([])
        }}
        filters={filters}
        allChecked={allChecked}
        selectedMotorists={selectedMotorists}
      />
    </Layout>
  )
}
