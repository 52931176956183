import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Flex,
  useToast,
  Box,
  VStack,
  Heading,
  Link,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { RiSearchLine } from 'react-icons/ri'
import { useEffect } from 'react'
import { toastError } from '../../../../config/error/toastError'
import { Input } from '../../../../components/form'
import { usePostGetPisInformations } from '../../../../services/endpoints/documents/pis-informations/post-pis-informations-infosimples'

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  motherName: yup.string(),
  cpf: yup.string().required('CPF é obrigatório'),
  birthDate: yup.mixed().required('Data de nascimento é obrigatória'),
})

interface FormData {
  name: string
  motherName?: string
  cpf: string
  birthDate: string | Date
}

interface PisFormProps {
  motherName?: string
  cpf?: string
  birthDate?: string | Date
  name?: string
}

export default function PisForm({ motherName, cpf, birthDate, name }: PisFormProps): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const toast = useToast()
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const {
    mutate: postPisInformations,
    data: dataPostPisInformations,
    isLoading: isLoadingPostPisInformations,
  } = usePostGetPisInformations({
    onSuccess: () => {
      toast({
        title: 'Consulta feita com sucesso!',
        description: 'Dados consultados com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const onSubmit = (data: FormData) => {
    postPisInformations(data)
  }

  useEffect(() => {
    if (cpf && birthDate && name && motherName) {
      postPisInformations({ name, motherName, cpf, birthDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex direction={['column', 'row']} align="stretch" justify="space-between" wrap="wrap">
      <Box flex={1} gap={4} bg={bg} p={4} rounded="md" maxW="400px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.name}>
            <FormLabel htmlFor="name">Nome</FormLabel>
            <Input setValue={setValue} name="name" initialValue={name} />

            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.motherName} mt={4}>
            <FormLabel htmlFor="motherName">Nome da mãe</FormLabel>
            <Input setValue={setValue} name="motherName" initialValue={motherName} />
            <FormErrorMessage>{errors.motherName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.cpf} mt={4}>
            <FormLabel htmlFor="cpf">CPF</FormLabel>
            <Input setValue={setValue} name="cpf" initialValue={cpf} />
            <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.birthDate} mt={4}>
            <Input
              name="birthDate"
              label="Data de nascimento"
              type="date"
              setValue={setValue}
              initialValue={birthDate}
            />
          </FormControl>

          <Button
            type="submit"
            leftIcon={<RiSearchLine />}
            colorScheme="blue"
            isLoading={isLoadingPostPisInformations}
            p={5}
            mt={4}
          >
            Consultar
          </Button>
        </form>
      </Box>

      <Spacer />

      <Box flex="1" p={4}>
        {dataPostPisInformations && (
          <VStack spacing={4} align="stretch" bg={bg} p={4} rounded="md">
            <Heading size="md">Informações do PIS</Heading>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Detalhe</Th>
                  <Th>Informação</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">NIT:</Td>
                  <Td>{dataPostPisInformations.nit}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Comprovante:</Td>
                  <Td>
                    <Link href={dataPostPisInformations.site_receipt} isExternal>
                      Ver Comprovante
                    </Link>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </VStack>
        )}
      </Box>
    </Flex>
  )
}
