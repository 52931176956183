/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAttachmentLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { ImAttachment } from 'react-icons/im'
import { useAppDispatch, useAppSelector } from '../../../../../../store'
import { Input } from '../../../../../../components/form/Input'
import { toastError } from '../../../../../../config/error/toastError'
import { addAttachment } from '../../../../../../store/slices/freightSlice'
import { AddAttachmentFormData } from '../../attachments/AddAttachmentModal'
import { apiServer } from '../../../../../../services/api'
import { queryClient } from '../../../../../../config/react-query'
import { useAttachments } from '../../../../../../services/endpoints/freights/getAttachments'
import { FreightAttachmentsDocumentEnum } from '../../../../../../services/types/EnumTypes'

interface AddAttachmentModalProps {
  isOpen: boolean
  onClose: () => void
  freight_status: string
  attachId?: string
}

export function AddOrEditStubModal({
  isOpen,
  onClose,
  freight_status,
  attachId,
}: AddAttachmentModalProps): JSX.Element {
  const addAttachmentFormSchema = yup.object().shape({
    attachment: yup.mixed().when(() => {
      if (!attachId) {
        return yup.mixed().required('É obrigatório anexar um arquivo')
      }
      return yup.mixed()
    }),
    delivery_receipt_at: yup.string().when(() => {
      if (!attachId) {
        return yup.string().required('campo obrigatório')
      }
      return yup.string()
    }),
  })
  const toast = useToast()
  const { freight, isLoading } = useAppSelector(state => state.freightSlice)

  const {
    data: attachments,
    error: errorListAttachments,
    isError: isErrorListAttachments,
  } = useAttachments({
    freight_id: String(freight?.id),
    document: FreightAttachmentsDocumentEnum.STUB,
    id: attachId,
  })

  useEffect(() => {
    if (isErrorListAttachments) {
      toastError({ toast, error: errorListAttachments })
    }
  }, [isErrorListAttachments, errorListAttachments, toast])

  const attachment = attachments?.find(attach => attach.id === attachId)

  const dispatch = useAppDispatch()
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [img, setImg] = useState<string>()
  const [fileName, setFileName] = useState<string>()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(addAttachmentFormSchema),
  })
  const { errors } = formState

  const handleAddAttachment: SubmitHandler<AddAttachmentFormData> = async data => {
    if (freight) {
      const delivery_receipt_at = new Date(`${data.delivery_receipt_at}T00:00`)
      if (attachId) {
        setIsLoadingUpdate(true)
        try {
          const formData = new FormData()
          formData.append('attachment', data.attachment)
          await apiServer.patch(`/update-freight-attachment/${attachId}`, formData, {
            params: {
              delivery_receipt_at,
            },
          })
          toast({
            title: 'Canhoto atualizado com sucesso!',
            status: 'success',
            isClosable: true,
            position: 'top-right',
          })
          onClose()

          queryClient.invalidateQueries('list-all-interactions-timeline')
        } catch (error) {
          await apiServer.delete(`/freights/attachments/${attachId}`)
          toastError({ toast, error })
        } finally {
          setIsLoadingUpdate(false)
        }
      } else {
        const { id: freight_id } = freight
        const error = await dispatch(
          addAttachment({
            freight_id,
            freight_status,
            attachment: data.attachment,
            name: data.name,
            description: data.description,
            delivery_receipt_at,
            type: 'stub',
          }),
        )

        if (isLoading === false && !error) onClose()
        if (!error) {
          queryClient.invalidateQueries('list-all-interactions-timeline')
          toast({
            title: 'Canhoto adicionado com sucesso!',
            status: 'success',
            isClosable: true,
            position: 'top-right',
          })
        }
        if (error) {
          toastError({ toast, error })
        }
      }
    }
  }

  useEffect(() => {
    setImg(undefined)
    setFileName(undefined)
    if (attachment) setFileName(attachment.attachment_file)
  }, [attachment, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleAddAttachment)} noValidate>
          <ModalHeader>{attachId ? 'Atualizar canhoto' : 'Adicionar canhoto'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid spacing="2">
              {(fileName || attachment?.attachment_file_url) && (
                <Box>
                  <Text>1 Arquivo carregado</Text>
                  {fileName && (
                    <Text mt={2}>Nome: {fileName.substring(fileName.length, fileName.length - 40)}</Text>
                  )}
                </Box>
              )}
              <Image src={img || (attachId ? attachment?.attachment_file_url : img)} w="100%" maxH="300px" />

              <FormLabel htmlFor="img-preview">
                <Flex
                  mr={-3}
                  cursor="pointer"
                  bg={attachId ? 'yellow.500' : 'blue.500'}
                  py="2"
                  px="4"
                  mt={2}
                  borderRadius="8"
                  color={errors.attachment ? 'red' : 'white'}
                  border={errors.attachment ? '2px' : '0'}
                >
                  <Icon as={ImAttachment} fontSize={24} mr="2" />
                  {attachId ? 'Substituir anexo' : 'Carregar um anexo'}{' '}
                  {!attachId && (
                    <Text ml={1} color="red.500">
                      *
                    </Text>
                  )}
                </Flex>

                <Input
                  id="img-preview"
                  d="none"
                  name="attachment"
                  type="file"
                  setValue={setValue}
                  error={errors.attachment}
                  isRequired={!attachId}
                  onChange={e => {
                    if (e.target.files) {
                      const file = e.target.files[0]
                      setFileName(file.name)
                      const fileReader = new FileReader()
                      if (
                        e.target.files &&
                        e.target.files.length > 0
                        // &&
                        // (e.target.files[0].name.includes('.png') ||
                        //   e.target.files[0].name.includes('.jpg') ||
                        //   e.target.files[0].name.includes('.jpeg'))
                      ) {
                        fileReader.onload = ev => {
                          if (ev.target && ev.target.result) {
                            const imgData = ev.target.result.toString()
                            setImg(imgData)
                          }
                        }
                        fileReader.readAsDataURL(file)

                        toast({
                          title: 'Arquivo carregado com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    }
                  }}
                />
              </FormLabel>

              <Input
                name="delivery_receipt_at"
                label="Data"
                type="date"
                setValue={setValue}
                error={errors.delivery_receipt_at}
                initialValue={
                  attachId && attachments && attachments.length > 0 && attachments[0].delivery_receipt_at
                    ? format(new Date(String(attachments[0].delivery_receipt_at)), 'yyyy-MM-dd')
                    : undefined
                }
                isRequired={!attachId}
              />
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme={attachId ? 'orange' : 'blue'}
              leftIcon={<Icon as={RiAttachmentLine} />}
              isLoading={isLoading || isLoadingUpdate}
            >
              {attachId ? 'Atualizar' : 'Adicionar'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
