import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { CityType } from '../../types'

interface IListCities {
  status: number
  data: CityType[]
}

export async function searchCitiesByName(name: string): Promise<AutocompleteOption[]> {
  if (name) {
    const { data } = await apiServer.get<IListCities>('/list-cities-by-name', {
      params: {
        name,
      },
    })

    return data.data.map(city => {
      const label = `${city.name} - ${city.state.uf}`
      const value = city.ibge_id
      const stateName = city.state.name
      const { uf } = city.state
      const lat = +city.lat
      const lng = +city.lng

      return { label, value, stateName, uf, lat, lng, cityName: city.name }
    })
  }
  return []
}
