import { Button, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteMulti, AutocompleteOption, RadioGroup } from '../../../../components/form'
import { useGetActions } from '../../../../services/endpoints/authorization/getActions'

export type FormEditPermissionsToManyRoles = {
  roles: AutocompleteOption[]
  permissionType: 'can' | 'cannot'
}

type Props = {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
}

export const editPermissionsValidateSchema = yup.object().shape({
  roles: yup.array().of(yup.object()).required('Campo obrigatório'),
  permissionType: yup.string().required('Campo obrigatório'),
})

export function FormEditPermissionsToManyRoles({ formState, setValue }: Props): JSX.Element {
  const history = useHistory()
  const { data: rolesOptions } = useGetActions({ only_roles_options: true })

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4">
      <GridItem colSpan={[12, 12, 12, 12, 7]}>
        {rolesOptions && rolesOptions.length && (
          <AutocompleteMulti
            setValue={setValue}
            name="roles"
            placeholder="Selecione os cargos a para configurar as permissões"
            options={rolesOptions as AutocompleteOption[]}
            error={formState.errors.roles}
          />
        )}
      </GridItem>
      <GridItem colSpan={[10, 10, 10, 10, 3]}>
        <RadioGroup
          mt="3"
          direction={['row']}
          name="permissionType"
          error={formState.errors.permissionType}
          setValue={setValue}
          options={[
            { label: 'Permitir', value: 'can' },
            { label: 'Não Permitir', value: 'cannot' },
          ]}
        />
      </GridItem>
      <GridItem colSpan={[3, 1]}>
        <Button colorScheme="blue" variant="outline" onClick={() => history.goBack()}>
          Cancelar
        </Button>
      </GridItem>

      <GridItem colSpan={[3, 1]}>
        <Button type="submit" colorScheme="orange" variant="outline" isLoading={formState.isSubmitting}>
          Salvar
        </Button>
      </GridItem>
    </Grid>
  )
}
