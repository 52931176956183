import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type PostNfesParams = {
  number: string
}

type ResponseApi = {
  data: any
}

async function createNfes(data: PostNfesParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.post<ResponseApiType<ResponseApi>>('/nfes', data)
  return response.data
}

export function usePostNfes(
  options?: UseMutationOptions<ResponseApi, unknown, PostNfesParams, unknown>,
): UseMutationResult<ResponseApi, unknown, PostNfesParams, unknown> {
  return useMutation(async (data: PostNfesParams) => createNfes(data), options)
}
