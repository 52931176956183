import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { CheckinType, ResponseApiType } from '../../types'

interface GetMotoristLastCheckinResponse extends CheckinType {
  checkin_city: string
  checkin_date: string
  checkin_time: string
}

async function fetchMotoristLastCheckin(
  motorist_id?: string,
): Promise<GetMotoristLastCheckinResponse | undefined> {
  if (motorist_id) {
    const { data: response } = await apiServer.get<ResponseApiType<CheckinType>>(
      `/motorist/checkins/last/${motorist_id}`,
    )

    const { data } = response

    const motoristLastCheckin = {
      ...data,
      checkin_city: `${data.city.name} - ${data.city.state.uf}`,
      checkin_date: new Date(data.checkin_at).toLocaleDateString('pt-BR'),
      checkin_time: 'Há menos de 1 minuto',
    }

    return motoristLastCheckin
  }

  return undefined
}

export function useGetMotoristLastCheckin(
  motorist_id?: string,
): UseQueryResult<GetMotoristLastCheckinResponse> {
  return useQuery(['motoristLastCheckin', motorist_id], () => fetchMotoristLastCheckin(motorist_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
