import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import useClickOutside from '../../../../hooks/useClickOutside'
import useSearchMotorist from '../../../../hooks/useSearchMotorist'
import { FormattedMotorist } from '../../../../services/endpoints/motorists/getMotorist'
import { MotoristType } from '../../../../services/types'
import OwnerType from '../../../../services/types/OwnerType'

type MotoristSearchOptionProps = {
  name: string
} & ButtonProps

const MotoristSearchOption = ({ name, ...rest }: MotoristSearchOptionProps): JSX.Element => (
  <Button
    rounded="none"
    fontWeight="normal"
    fontSize="14"
    justifyContent="start"
    size="md"
    w="100%"
    {...rest}
  >
    <Box>{name}</Box>
  </Button>
)

type MotoristSearchInputProps = {
  isLoading?: boolean
} & InputProps

const MotoristSearchInput = ({ isLoading, ...rest }: MotoristSearchInputProps): JSX.Element => (
  <InputGroup>
    <InputLeftElement pointerEvents="none">
      <FiSearch color="gray.300" />
    </InputLeftElement>
    <Input {...rest} />
    <InputRightElement>{isLoading && <Spinner size="sm" />}</InputRightElement>
  </InputGroup>
)

const MotoristSearchContainer = (props: BoxProps): JSX.Element => <Box {...props} position="relative" />

type MotoristSearchProps = {
  onSelectFound: (motorist: FormattedMotorist) => void
}

export const MotoristSearch = ({ onSelectFound }: MotoristSearchProps): JSX.Element => {
  const [motoristData, setMotoristData] = useState<FormattedMotorist | undefined>()
  const [searchQuery, setSearchQuery] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('gray.100', 'gray.800')

  const { loading, search } = useSearchMotorist({
    onSuccess: result => {
      if (result.motorist) {
        setMotoristData(result.motorist)
      }
    },
  })

  const handleChange = (value: string) => {
    setSearchQuery(value)
    search(value)
    setIsDropdownOpen(true)
  }

  useClickOutside(containerRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
      setSearchQuery('')
      setMotoristData(undefined)
    }
  })

  return (
    <MotoristSearchContainer>
      <MotoristSearchInput
        value={searchQuery}
        onChange={e => handleChange(e.target.value)}
        isLoading={loading}
        placeholder="Pesquisar motorista por CPF..."
      />
      {isDropdownOpen && (
        <SimpleGrid
          columns={1}
          my={2}
          w="100%"
          zIndex={10}
          py={2}
          bg={bg}
          position="absolute"
          rounded="md"
          shadow="md"
          maxH="200px"
          overflowY="auto"
          gap={2}
          ref={containerRef}
        >
          {motoristData ? (
            <MotoristSearchOption
              key={motoristData.id}
              name={motoristData.name}
              onClick={() => {
                onSelectFound(motoristData as Partial<FormattedMotorist & MotoristType & OwnerType>)
                setIsDropdownOpen(false)
                setSearchQuery('')
                setMotoristData(undefined)
              }}
            />
          ) : (
            <Box textAlign="center" p={2}>
              {loading ? 'Carregando...' : 'Nenhum motorista encontrado'}
            </Box>
          )}
        </SimpleGrid>
      )}
    </MotoristSearchContainer>
  )
}
