import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiStarLine } from 'react-icons/ri'
import { RateStar } from '../../../../../components/form/RateStar'
import { InputNumberMask } from '../../../../../components/form/InputNumberMask'
import { Select } from '../../../../../components/form/Select'
import { SelectOption } from '../../../../../components/form/types/SelectOption'
import { useAppDispatch } from '../../../../../store'
import { changeFrightStatus, updateFreight } from '../../../../../store/slices/freightSlice'
import { toastError } from '../../../../../config/error/toastError'
import { evaluateMotorist } from '../../../../../store/slices/motoristSlice'
import { TextArea } from '../../../../../components/form/TextArea'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { useIsAllFreightExpenseFinishedOrCanceled } from '../../../../../services/endpoints/freights/financial/isEveryFinancialTitleFinished'

interface FinishFreightModalProps {
  isOpen: boolean
  onClose: () => void
  freight: FormattedOneFreight
  motorist_id: string
}

interface FinishFreightFormData {
  behavior_score: number
  ponctuality_score: number
  reliability_score: number
  cargo_state_score: number
  description: string
  agreed_price: number
  service_price: number
  toll_ticket: SelectOption
  payment_method: SelectOption
}

const finishFreightFormSchema = yup.object().shape({
  behavior_score: yup.number().required('Campo obrigatório'),
  ponctuality_score: yup.number().required('Campo obrigatório'),
  reliability_score: yup.number().required('Campo obrigatório'),
  cargo_state_score: yup.number().required('Campo obrigatório'),
  agreed_price: yup.number().required('Campo obrigatório'),
  service_price: yup.number().required('Campo obrigatório'),
  toll_ticket: yup.string().required('Campo obrigatório'),
  payment_method: yup.string().required('Campo obrigatório'),
})

export function FinishFreightModal({
  isOpen,
  onClose,
  freight,
  motorist_id,
}: FinishFreightModalProps): JSX.Element {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { data: isAllFreightExpenseFinished } = useIsAllFreightExpenseFinishedOrCanceled(freight.id)

  const { handleSubmit, setValue, formState, setError } = useForm({
    resolver: yupResolver(finishFreightFormSchema),
  })
  const { errors } = formState

  const handleFinishFreight: SubmitHandler<FinishFreightFormData> = async data => {
    const rate_score =
      (data.behavior_score + data.ponctuality_score + data.reliability_score + data.cargo_state_score) / 4

    if (rate_score <= 3 && (!data.description || !data.description.trim())) {
      setError('description', {
        message: 'Campo obrigatório em caso de média menor ou igual 3.',
      })
      return
    }

    if (data.service_price <= data.agreed_price) {
      setError('agreed_price', {
        message: `O valor do Cliente (Serviço) não pode ser menor ou igual ao valor acordado com o motorista.
          Preencha com o valor correto!`,
      })
      return
    }

    if (isAllFreightExpenseFinished === false) {
      toast({
        title: `Não é possível finalizar o frete com títulos financeiros não finalizados!
        Finalize todos os títulos financeiros e tente novamente.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 9000,
      })
      return
    }

    const { toll_ticket, payment_method, agreed_price, service_price, ...rate } = data

    const errorEvaluateMotorist = await dispatch(
      evaluateMotorist({
        ...rate,
        freight_id: freight.id,
        motorist_id,
      }),
    )

    if (!errorEvaluateMotorist) {
      toast({
        title: 'Motorista avaliado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    }
    if (errorEvaluateMotorist) toastError({ toast, error: errorEvaluateMotorist })

    const errorUpdateFreight = await dispatch(
      updateFreight(freight.id, {
        agreed_price,
        service_price,
        toll_ticket: toll_ticket.value,
        payment_method: payment_method.value,
      }),
    )
    const errorUpdateFreightStatus = await dispatch(
      changeFrightStatus({ freight_id: freight.id, status: 'finished' }),
    )
    if (!errorUpdateFreight && !errorUpdateFreightStatus) {
      onClose()
      toast({
        title: 'Frete finalizado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    }

    if (errorUpdateFreight)
      toastError({
        toast,
        error: errorUpdateFreight || errorUpdateFreightStatus,
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFinishFreight)}>
          <ModalHeader>Finalizar frete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="md" fontWeight="normal">
              Antes de finalizar o frete você precisa avaliar o motorista
            </Heading>
            <SimpleGrid minChildWidth="250px" spacing={2}>
              <VStack spacing="6" mt="8">
                <RateStar
                  isRequired
                  name="behavior_score"
                  label="Como você avalia o atendimento do motorista?"
                  setValue={setValue}
                  error={errors.behavior_score}
                  initialValue={freight.rate?.behavior_score}
                />
                <RateStar
                  isRequired
                  name="ponctuality_score"
                  label="Como você avalia a pontualidade do motorista?"
                  setValue={setValue}
                  error={errors.ponctuality_score}
                  initialValue={freight.rate?.ponctuality_score}
                />
                <RateStar
                  isRequired
                  name="reliability_score"
                  label="Como você avalia a confiabilidade do frete?"
                  setValue={setValue}
                  error={errors.reliability_score}
                  initialValue={freight.rate?.reliability_score}
                />
                <RateStar
                  isRequired
                  name="cargo_state_score"
                  label="Como você avalia a situação da carga na entrega do frete?"
                  setValue={setValue}
                  error={errors.cargo_state_score}
                  initialValue={freight.rate?.cargo_state_score}
                />
              </VStack>
              <TextArea
                name="description"
                label="Observação"
                setValue={setValue}
                error={errors.description}
              />
              <InputNumberMask
                isRequired
                name="agreed_price"
                label="Preço acordado com o motorista"
                isCashBRL
                initialValue={!Number(freight.agreed_price) ? undefined : Number(freight.agreed_price)}
                error={errors.agreed_price}
                setValue={setValue}
              />
              <InputNumberMask
                isRequired
                name="service_price"
                label="Valor do Cliente (Serviço)"
                isCashBRL
                initialValue={!Number(freight.service_price) ? undefined : Number(freight.service_price)}
                error={errors.service_price}
                setValue={setValue}
              />

              <Select
                isRequired
                name="toll_ticket"
                label="Utilizou vale pedágio?"
                options={[
                  { label: '', value: '' },
                  { label: 'Não', value: 'not' },
                  { label: 'ConectCar', value: 'conect_car' },
                  { label: 'Sem Parar', value: 'nonstop' },
                ]}
                setValue={setValue}
                initialValue={freight.toll_ticket}
                error={errors.toll_ticket}
              />

              <Select
                isRequired
                name="payment_method"
                label="Qual o meio de pagamento?"
                options={[
                  { label: '', value: '' },
                  { label: 'Truckpad', value: 'truck_pad' },
                  { label: 'e-Frete', value: 'e_freight' },
                  { label: 'Rodobank', value: 'rodobank' },
                ]}
                setValue={setValue}
                initialValue={freight.payment_method}
                error={errors.payment_method}
              />
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiStarLine} />}
              isLoading={formState.isSubmitting}
            >
              Avaliar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
