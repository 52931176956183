import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import OwnerType from '../../types/OwnerType'

interface FilterOwnersFormData {
  orderByName?: 'asc' | 'desc' | undefined
  page?: number
  per_page?: number
  name?: string
  cpf?: string
  cnpj?: string
  phone?: string
  type?: number
}

type GetAllOwnersResponse = {
  total: number
  data: OwnerType[]
}

async function fetchOwners(params: FilterOwnersFormData): Promise<GetAllOwnersResponse> {
  const { data: response } = await apiServer.get<ResponseListApiType<OwnerType[]>>('/list-owner', {
    params,
  })
  const { data } = response
  const { total } = response.data
  const owners = data.data

  return { data: owners, total }
}

export function useGetAllOwners(params: FilterOwnersFormData): UseQueryResult<GetAllOwnersResponse> {
  return useQuery(['owners', params], () => fetchOwners(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
