import { Box, Button, Divider, Flex, Heading, Icon, Text, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { useCreateAttachmentToMotorist } from '../../../services/endpoints/motorists/motorist-attachments/createAttachment'
import { useCreateMotorist } from '../../../services/endpoints/motorists/motorist-create/createMotorist'
import { MotoristType } from '../../../services/types'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setDriverLicenseFile, setIsNewImage } from '../../../store/slices/ocrCnhSlice'
import { MotoristForm, MotoristFormSchema, MotoristFormValues } from '../../../components/forms/motorist'
import UploadCNHModal from '../../freights/pendencies/modals/UploadOwnerCnh.modal'
import { createCNHDefaultValues } from '../../freights/pendencies/utils'

export const CreateMoristCompleteForm = (): JSX.Element => {
  const history = useHistory()
  const { driverLicenseFile } = useAppSelector(state => state.ocrCnhSlice)
  const [newMotorist, setNewMotorist] = React.useState<MotoristType | null>(null)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const methods = useForm<MotoristFormValues>({
    mode: 'onChange',
    defaultValues: {} as MotoristFormValues,
    resolver: yupResolver(MotoristFormSchema),
  })

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const createAttachment = useCreateAttachmentToMotorist({
    onSuccess: () => {
      showSuccessToast('Anexo salvo com sucesso!')
      dispatch(setDriverLicenseFile({} as File))
      dispatch(setIsNewImage(false))
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const handleAttachmentUpload = async (motorist_id: string) => {
    if (!driverLicenseFile.name) return

    await createAttachment.mutateAsync({
      attachment_file: driverLicenseFile,
      motorist_id,
      name: 'CNH do motorista',
      type: 'cnh',
    })
  }

  const createMotorist = useCreateMotorist({
    onSuccess: result => {
      showSuccessToast('Motorista criado com sucesso!')
      setNewMotorist(result.data)
      handleAttachmentUpload(result.data.id)
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const createMotoristPayload = (formValues: MotoristFormValues, motorist_id?: string) => ({
    cpf: getOnlyNumbers(formValues.cpf),
    name: formValues.name,
    phone: getOnlyNumbers(formValues.phone),
    mother_name: formValues.mother_name,
    father_name: formValues.father_name,
    rg: formValues.register_doc,
    rg_dispatcher: formValues.rg_dispatcher.toUpperCase(),
    rg_uf: formValues.rg_uf,
    rg_dispatch_date: formValues.rg_dispatch_date,
    birth: formValues.birth,
    email: formValues.email || undefined,
    zipcode: getOnlyNumbers(formValues.address_zipcode),
    address: formValues.address,
    address_number: formValues.address_number,
    district: formValues.address_neighborhood,
    address_city_id: formValues.address_city.value,
    birth_state: formValues.address_state_uf,
    bank: undefined,
    driver_license: {
      motorist_id,
      category: formValues.driver_license_category,
      dispatch_date: formValues.driver_license_dispatch_date,
      dispatcher_uf: formValues.driver_license_dispatcher_uf,
      dispatcher: formValues.driver_license_dispatcher.toUpperCase(),
      insurance_number: formValues.driver_license_security_code,
      first_dispatch_date: formValues.driver_license_first_dispatch_date,
      protocol_number: formValues.driver_license_protocol_number,
      register_number: formValues.driver_license,
      validity: formValues.driver_license_validity,
    },
  })

  const onSubmit: SubmitHandler<MotoristFormValues> = async data => {
    const formValues = data
    const payload = {
      ...createMotoristPayload(formValues),
      motorist_contacts: [
        {
          name: data.name_contacts_ref_person_1,
          phone: getOnlyNumbers(data.phone_ref_person_1),
          type: data.type_contacts_ref_person_1,
        },
        {
          name: data.name_contacts_ref_person_2,
          phone: getOnlyNumbers(data.phone_ref_person_2),
          type: data.type_contacts_ref_person_2,
        },
        {
          name: data.name_contacts_ref_professional_1,
          phone: getOnlyNumbers(data.phone_ref_professional_1),
          type: 'COMERCIAL',
        },
        {
          name: data.name_contacts_ref_professional_2,
          phone: getOnlyNumbers(data.phone_ref_professional_2),
          type: 'COMERCIAL',
        },
      ],
    }

    await createMotorist.mutateAsync(payload)
  }

  return (
    <Flex flexDirection="column" gridGap="4">
      <UploadCNHModal
        onSaveInformations={CNH => {
          methods.reset(createCNHDefaultValues(CNH))
        }}
      />
      <FormProvider {...methods}>
        <MotoristForm disabled={false} />
      </FormProvider>
      {newMotorist && (
        <Box my="4" bg="green.500" p="4" borderRadius="md">
          <Heading color="white" fontSize="md" fontWeight="medium">
            Motorista criado com sucesso!
          </Heading>
          <Text color="white">Clique no botão abaixo para cadastrar um veículo com esse motorista</Text>
          <Flex mt="4">
            <Button
              size="sm"
              variant="solid"
              colorScheme="green"
              isLoading={methods.formState.isSubmitting}
              disabled={methods.formState.isSubmitting}
              onClick={() => {
                history.push(`/vehicles/create?motorist_id=${newMotorist.id}`)
              }}
            >
              Cadastrar Veículo com esse motorista
            </Button>
          </Flex>
        </Box>
      )}
      <Divider my="6" />
      <Flex justifyContent="flex-end" alignItems="center" gridGap="2">
        <Link to="/motorists">
          <Button variant="ghost" colorScheme="red">
            Cancelar
          </Button>
        </Link>
        <Ability module="people" action="create-motorist">
          <Button
            leftIcon={<Icon as={RiUserAddLine} />}
            variant="solid"
            colorScheme="orange"
            isLoading={methods.formState.isSubmitting}
            disabled={methods.formState.isSubmitting}
            onClick={methods.handleSubmit(onSubmit)}
          >
            Cadastrar motorista
          </Button>
        </Ability>
      </Flex>
    </Flex>
  )
}
