/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { apiServer } from '../../api'

interface IMotoristAttachVehicle {
  motoristId: string
  vehicleId: string
}

async function postMotoristAttachVehicle(data: IMotoristAttachVehicle) {
  return apiServer.post('/motorist-attach-vehicle', {
    motorist_id: data.motoristId,
    vehicle_id: data.vehicleId,
  })
}

export function usePostMotoristAttachVehicle(
  options?: UseMutationOptions<AxiosResponse, unknown, IMotoristAttachVehicle, unknown>,
) {
  return useMutation(async (data: IMotoristAttachVehicle) => postMotoristAttachVehicle(data), options)
}
