import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import useClickOutside from '../../hooks/useClickOutside'

type Option = {
  label: string
  value: string | number
  [key: string]: any
}

type UISelectAsyncProps = {
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  loadOptions?: (text: string) => Promise<Option[]>
  value?: Option
  onChange?: (value: Option) => void
  placeholder?: string
  name?: string
}

export const UISelectAsync = ({
  helperText,
  isRequired,
  isError,
  errorMessage,
  label,
  loadOptions,
  onChange,
  value,
  name,
  placeholder,
}: UISelectAsyncProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Option[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  useClickOutside(containerRef, () => {
    if (isOpen) {
      onClose()
    }
  })

  const [inputValue, setInputValue] = useState<string>(value?.label || '')

  const getOptions = useRef(
    debounce(
      async (search: string) => {
        setLoading(true)
        if (!loadOptions) return
        try {
          const data = await loadOptions(search)

          setOptions(data)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setOptions([])
        } finally {
          setLoading(false)
        }
      },
      500,
      {},
    ),
  ).current

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value === '') {
      setInputValue(e.target.value)
      onChange?.({
        label: '',
        value: '',
      })
      return
    }

    setInputValue(e.target.value)
    if (e.target.value) {
      getOptions(e.target.value)
    }
  }

  useEffect(() => {
    if (value) {
      setInputValue(value.label)
    }
  }, [value])

  useEffect(() => {
    return () => {
      getOptions.cancel()
    }
  }, [getOptions])

  return (
    <Box ref={containerRef}>
      <Popover
        matchWidth
        flip
        preventOverflow
        boundary="scrollParent"
        autoFocus={false}
        isOpen={isOpen}
        placement="bottom"
        isLazy
      >
        <FormControl isInvalid={isError} isRequired={isRequired}>
          {label && (
            <FormLabel fontSize="14" mb="1.5">
              {label}
            </FormLabel>
          )}
          <PopoverTrigger>
            <InputGroup>
              <Input
                name={name}
                px="2"
                onFocus={() => {
                  onOpen()
                }}
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                focusBorderColor="orange.500"
              />
              <InputRightElement pointerEvents="none" w="fit-content" px="2">
                {loading && <Spinner size="xs" />}
                <Icon ml="2" as={isOpen ? RiArrowUpSLine : RiArrowDownSLine} />
              </InputRightElement>
            </InputGroup>
          </PopoverTrigger>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </FormControl>

        <PopoverContent w="100%">
          <PopoverBody w="100%" maxH="300px" overflowY="auto" px="0">
            <SimpleGrid columns={1} spacing={1} px="0" aria-label="Opções">
              {options.length === 0 && !loading && (
                <Box as="p" px="2">
                  Nenhum resultado encontrado
                </Box>
              )}

              {options.map(option => (
                <Button
                  key={option.value}
                  value={option.value}
                  rounded="none"
                  variant="ghost"
                  aria-label={option.label}
                  onClick={() => {
                    setInputValue(option.label)
                    onChange?.(option)
                    onClose()
                  }}
                  textAlign="left"
                  justifyContent="left"
                  fontWeight="normal"
                  fontSize="14"
                  py="2"
                >
                  {option.label}
                </Button>
              ))}
            </SimpleGrid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
