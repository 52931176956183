import { Button, Divider, Flex, HStack, Icon, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { MotoristType, ResponseApiType } from '../../../services/types'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'
import {
  MotoristQuickForm,
  motoristQuickFormSchema,
  MotoristQuickFormValues,
} from '../MotoristQuickForm/MotoristQuickForm'

// export interface MotoristQuickFormData {
//   name: string
//   phone: string
//   cpf: string
//   other_phone: string
//   checkin: AutocompleteOption
//   checkin_at: Date
//   origin: AutocompleteOption
//   destination: AutocompleteOption
//   prospected_by: string
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   [key: string]: any
// }

// const motoristQuickFormSchema = yup.object().shape({
//   name: yup.string().required('Campo obrigatório'),
//   phone: yup.string().required('Campo obrigatório').min(11, 'Telefone incompleto'),
//   cpf: yup
//     .string()
//     .test('validate-cpf', 'CPF inválido', value => {
//       if (value) return validateCpf(value)
//       return true
//     })
//     .required('Campo obrigatório'),
//   other_phone: yup.string(),
//   checkin: yup.object().notRequired(),
//   checkin_at: yup.string(),
//   origin: yup.object().notRequired(),
//   destination: yup.object().notRequired(),
//   prospected_by: yup.string(),
// })

export const CreateMoristCompleteQuickForm = (): JSX.Element => {
  const history = useHistory()

  const toast = useToast()

  const methods = useForm<MotoristQuickFormValues>({
    mode: 'onChange',
    defaultValues: {
      cpf: '',
      phone: '',
      name: '',
      prospected_by: undefined,
    } as MotoristQuickFormValues,
    resolver: yupResolver(motoristQuickFormSchema),
  })

  const handleCreateMotorist: SubmitHandler<MotoristQuickFormValues> = async data => {
    try {
      const { data: response } = await apiServer.post<ResponseApiType<MotoristType>>('/create-motorist', {
        name: data.name,
        phone: getOnlyNumbers(data.phone),
        cpf: getOnlyNumbers(data.cpf),
      })
      const { data: newMotorist } = response

      toast({
        title: 'Motorista cadastrado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
      history.push(`/vehicles/create?motorist_id=${newMotorist.id}`)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleCreateMotorist)} noValidate>
        <MotoristQuickForm />
        <Divider my="4" />
        <Flex mt="4" justify="flex-end">
          <HStack spacing="4">
            <Link to="/motorists">
              <Button variant="ghost" colorScheme="red">
                Cancelar
              </Button>
            </Link>

            <Ability module="people" action="create-motorist">
              <Button
                type="submit"
                colorScheme="orange"
                isLoading={methods.formState?.isSubmitting}
                leftIcon={<Icon as={RiUserAddLine} />}
              >
                Cadastrar motorista
              </Button>
            </Ability>
          </HStack>
        </Flex>
      </form>
    </FormProvider>
  )
}
