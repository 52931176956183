import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { RiCheckLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { queryClient } from '../../../../config/react-query'
import { toastError } from '../../../../config/error/toastError'
import { formatStatusFreightExpense } from '../../../../services/endpoints/freights/financial/getAllFinancialTitles'
import { usePutFreightExpense } from '../../../../services/endpoints/freights/financial/putFinancialTitle'

interface SetNextStatusModalProps {
  isOpen: boolean
  onClose: () => void
  freight_expense_id: string
  status: string
  freight_id: string
}

export function ChangeStatusFreightExpenseModal({
  isOpen,
  onClose,
  status,
  freight_expense_id,
  freight_id,
}: SetNextStatusModalProps): JSX.Element {
  const [observation, setObservation] = useState<string>()
  const toast = useToast()

  const { mutateAsync: mutateAsyncUpdate, isLoading } = usePutFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-financial-title-history')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('financial-title')
    },
  })

  const handleSetNextStatus = async () => {
    try {
      await mutateAsyncUpdate({
        freight_id,
        status,
        id: freight_expense_id,
        observation,
      })
      toast({
        title: 'Status alterado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }
  useEffect(() => {
    setObservation(undefined)
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar status para {formatStatusFreightExpense(status)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth="300px">
            <Text fontSize="md" fontWeight="bold" my="2">
              Informe o motivo *
            </Text>
            <Textarea
              name="observation"
              size="md"
              value={observation}
              onChange={e => {
                setObservation(e.target.value)
              }}
            />
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            isDisabled={!observation}
            onClick={handleSetNextStatus}
            leftIcon={<Icon as={RiCheckLine} />}
            isLoading={isLoading}
          >
            Alterar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
