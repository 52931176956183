import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type ResponseApi = {
  data: void
}

type StringOrNumber = string | number

type AttachNfesStubParams = {
  occurrence?: string | null
  nfes_id: StringOrNumber[]
  receipt_date?: Date
  status: 'approved' | 'dessaproved' | 'pending'
  stub_id: string
}

async function attachNfesStub(data: AttachNfesStubParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.put<ResponseApiType<ResponseApi>>('/stubs/attach', {
    occurrence: data.occurrence,
    nfes_id: data.nfes_id,
    receipt_date: data.receipt_date,
    status: data.status,
    stub_id: data.stub_id,
  })

  return response.data
}

export function usePutAttachNfesStub(
  options?: UseMutationOptions<ResponseApi, unknown, AttachNfesStubParams, unknown>,
): UseMutationResult<ResponseApi, unknown, AttachNfesStubParams, unknown> {
  return useMutation((data: AttachNfesStubParams) => attachNfesStub(data), options)
}
