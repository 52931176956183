import {
  Button,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { RiCheckboxCircleLine } from 'react-icons/ri'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'

interface EnableQuotationModalProps {
  isOpen: boolean
  onClose: () => void
  quotation_id: string
}

export function EnableQuotationModal({
  isOpen,
  onClose,
  quotation_id,
}: EnableQuotationModalProps): JSX.Element {
  const toast = useToast()
  async function handleEnablequotation() {
    try {
      await apiServer.put(`quotations/${quotation_id}`, {
        status: 'pending',
      })
      onClose()
      toast({
        title: 'Cotação ativada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
      queryClient.invalidateQueries(`all-quotations`)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tem certeza que deseja reativar a cotação?</ModalHeader>
        <ModalCloseButton />

        <ModalFooter>
          <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            leftIcon={<Icon as={RiCheckboxCircleLine} />}
            onClick={handleEnablequotation}
          >
            Reativar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
