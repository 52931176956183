import {
  Box,
  Radio,
  RadioGroup as ChakraUiRadioGroup,
  RadioGroupProps as ChakraUiRadioGroupProps,
  Stack,
  StackDirection,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { RadioGroupOption } from './types/RadioGroupOption'

interface RadioGroupProps extends Omit<ChakraUiRadioGroupProps, 'children'> {
  name: string
  options: RadioGroupOption[]
  setValue: UseFormSetValue<FieldValues>
  onSelectOption?: (options: RadioGroupOption) => void
  label?: string
  initialValue?: string | number
  direction?: StackDirection
  error?: FieldError
  isDisabled?: boolean
  isRequired?: boolean
}

export function RadioGroup({
  name,
  options,
  setValue,
  onSelectOption,
  label,
  initialValue,
  direction = 'row',
  onChange,
  error,
  isDisabled,
  isRequired,
  ...rest
}: RadioGroupProps): JSX.Element {
  const [selectedValue, setSelectedValue] = useState(initialValue)

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
      setSelectedValue(initialValue)
    }
  }, [initialValue, setValue, name])

  return (
    <ChakraUiRadioGroup
      name={name}
      value={selectedValue}
      onChange={nextValue => {
        if (Number.isNaN(Number(nextValue))) {
          setValue(name, nextValue)
          setSelectedValue(nextValue)
        } else {
          const parsedValue = Number(nextValue)
          setValue(name, parsedValue)
          setSelectedValue(parsedValue)
        }
        if (onChange) onChange(nextValue)
        if (onSelectOption) {
          const selectedItem = options.find(i => i.value === nextValue)
          if (selectedItem) {
            onSelectOption(selectedItem)
          }
        }
      }}
      {...rest}
    >
      {label && (
        <Text fontSize="md" mb="2" fontWeight="medium" opacity={isDisabled ? 0.7 : undefined}>
          {label}
          {isRequired && (
            <Box as="span" color="red.500" ml="1">
              *
            </Box>
          )}
        </Text>
      )}
      <Stack
        direction={direction}
        spacing={direction === 'row' ? '6' : '3'}
        lineHeight={direction === 'row' ? 'taller' : 'normal'}
        wrap="wrap"
      >
        {options.map(option => (
          <Radio
            key={option.value}
            value={option.value}
            size="lg"
            isInvalid={!!error}
            isDisabled={isDisabled || option.isDisabled || undefined}
          >
            <Text as="span" cursor={isDisabled || option.isDisabled ? 'not-allowed' : 'pointer'}>
              {option.label}
            </Text>
          </Radio>
        ))}
      </Stack>
      {error && (
        <Text fontSize="sm" mt="2" textColor="red.500">
          {error.message}
        </Text>
      )}
    </ChakraUiRadioGroup>
  )
}
