import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'
import { invalidateCache } from './putRiskAnalysis'

export interface CreateRiskAnalysis {
  freight_id: string
  motorist_id: string
  vehicle_id: string
  trailer_id?: string
  status?: string
  consultation_code?: string
  depends_on_check?: boolean
  reason?: string
  // payload freight buonny
  buonny_cargo_type: string | number
  buonny_cargo_price: string
  buonny_contact_name: string
  buonny_contact_type: '1' | '2'
  buonny_contact_description: string
  // payload vehicles buonny
  vehicle_model_buonny?: string | number
  vehicle_brand_buonny?: string | number
  vehicle_technology?: string | number
  vehicle_color?: string
  trailer_model_buonny?: string | number
  trailer_brand_buonny?: string | number
  trailer_technology?: string | number
  trailer_color: string
  has_tracking_system?: boolean
  tracking_system?: string
  // payload motorist buonny
  motorist_category?: string
  was_stolen_in_last_24_months?: boolean
  stolen_times?: string
  had_accident?: boolean
  accident_times?: string
  how_long_carry_to_company?: string | number
  carry_times?: string
  respond_for_crime_in_last_10_years?: boolean
  crime_process_numbers?: string
}

async function createRiskAnalysis(
  data: CreateRiskAnalysis,
): Promise<AxiosResponse<ResponseApiType<RiskAnalysisType>>> {
  const riskAnalysisCreated = await apiServer.post('/create-risk-analysis', data)
  if (riskAnalysisCreated) await invalidateCache()
  return riskAnalysisCreated
}

export function usePostRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateRiskAnalysis, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, CreateRiskAnalysis, unknown> {
  return useMutation(async (data: CreateRiskAnalysis) => createRiskAnalysis(data), options)
}
