import * as yup from 'yup'
import { validateDate } from '../../../services/utils/dates/isValidDate'
import { validateCpf } from '../../../services/utils/validateCpf'

export const MotoristFormSchema = yup.object().shape({
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (!value) return false

    if (value) return validateCpf(value)
    return true
  }),
  name: yup.string().required('Nome é obrigatório'),
  birth: yup
    .string()
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Data de nascimento é obrigatória'),
  register_doc: yup.string().required('RG é obrigatório'),
  rg_uf: yup.string().required('UF do RG é obrigatório'),
  rg_dispatcher: yup.string().required('Órgão expedidor do RG é obrigatório'),
  rg_dispatch_date: yup
    .string()
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Data de expedição do RG é obrigatória'),
  phone: yup.string().required('Telefone é obrigatório'),
  email: yup.string().email('Email inválido'),
  mother_name: yup.string().required('Nome da mãe é obrigatório'),
  father_name: yup.string().required('Nome do pai é obrigatório'),

  // cnh doc
  driver_license: yup.string().required('CNH é obrigatória'),
  driver_license_category: yup.string().required('CATEGORIA da CNH é obrigatório'),
  driver_license_dispatcher: yup.string().required('ÓRGÃO EXPEDIDOR da CNH é obrigatório'),
  driver_license_dispatch_date: yup
    .string()
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('DATA DE EXPEDIÇÃO da CNH é obrigatório'),
  driver_license_first_dispatch_date: yup
    .string()
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('DATA DE PRIMEIRA EXPEDIÇÃO da CNH é obrigatório'),
  driver_license_dispatcher_uf: yup.string().required('UF do ÓRGÃO EXPEDIDOR da CNH é obrigatório'),
  driver_license_validity: yup.string().required('VALIDADE da CNH é obrigatório'),
  driver_license_security_code: yup.string().required('Código de segurança da CNH é obrigatório'),
  driver_license_protocol_number: yup.string().required('Número do protocolo é obrigatório'),

  // address
  address_zipcode: yup.string().required('CEP é obrigatório'),
  address: yup.string().required('Endereço é obrigatório'),
  address_neighborhood: yup.string().required('Bairro é obrigatório'),
  address_number: yup.string().required('Número do endereço é obrigatório'),
  address_city: yup.object().required('Cidade é obrigatório'),
  address_state_uf: yup.string().required('Estado é obrigatório'),

  // contacts
  name_contacts_ref_person_1: yup.string().required('Nome é obrigatório'),
  type_contacts_ref_person_1: yup.string().required('Tipo é obrigatório'),
  phone_ref_person_1: yup.string().required('Telefone é obrigatório'),
  name_contacts_ref_person_2: yup.string().required('Nome é obrigatório'),
  type_contacts_ref_person_2: yup.string().required('Tipo é obrigatório'),
  phone_ref_person_2: yup.string().required('Telefone é obrigatório'),

  // contacts professional
  name_contacts_ref_professional_1: yup.string().required('Nome é obrigatório'),
  // type_contacts_ref_professional_1: yup.string().required('Tipo é obrigatório'),
  phone_ref_professional_1: yup.string().required('Telefone é obrigatório'),
  name_contacts_ref_professional_2: yup.string().required('Nome é obrigatório'),
  // type_contacts_ref_professional_2: yup.string().required('Tipo é obrigatório'),
  phone_ref_professional_2: yup.string().required('Telefone é obrigatório'),
})

export type MotoristFormValues = {
  cpf: string
  name: string
  birth: string
  register_doc: string
  rg_uf: string
  rg_dispatcher: string
  rg_dispatch_date: string
  phone: string
  email: string
  mother_name: string
  father_name: string

  // cnh doc
  driver_license: string
  driver_license_category: string
  driver_license_dispatcher: string
  driver_license_dispatch_date: string
  driver_license_first_dispatch_date: string
  driver_license_dispatcher_uf: string
  driver_license_validity: string
  driver_license_security_code: string
  driver_license_protocol_number: string

  // address
  address_zipcode: string
  address: string
  address_neighborhood: string
  address_number: string
  address_city: {
    label: string
    value: string | number
  }
  address_state_uf: string

  // contacts
  name_contacts_ref_person_1: string
  type_contacts_ref_person_1: string
  phone_ref_person_1: string
  name_contacts_ref_person_2: string
  type_contacts_ref_person_2: string
  phone_ref_person_2: string

  // contacts professional
  name_contacts_ref_professional_1: string
  type_contacts_ref_professional_1: string
  phone_ref_professional_1: string
  name_contacts_ref_professional_2: string
  type_contacts_ref_professional_2: string
  phone_ref_professional_2: string
}
