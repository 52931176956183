/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'

export interface IStatusZApiInstanceResponse {
  statusCode: number
  data: boolean
}

export async function fetchStatusZApi(): Promise<IStatusZApiInstanceResponse> {
  const { data } = await apiServer.get<IStatusZApiInstanceResponse>('/z-api/get-connection-status')
  return data
}

export function getStatusZApiInstance(): UseQueryResult<IStatusZApiInstanceResponse> {
  return useQuery([], () => fetchStatusZApi(), {
    staleTime: 1000 * 60 * 2, // 2 minutes,
  })
}
