/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { CreateTripObservations } from '../../../types/TripObservationsType'

async function createTripObservations(data: CreateTripObservations) {
  return apiServer.post('/trip-observations', data)
}

export function usePostTripObservations(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateTripObservations, unknown>,
) {
  return useMutation(async (data: CreateTripObservations) => createTripObservations(data), options)
}
