import { Box, Button, Divider, Flex, Grid, GridItem, Heading, Text, Textarea } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { Ability } from '../../../components/ability'
import { Autocomplete } from '../../../components/form/Autocomplete'
import { Input } from '../../../components/form/Input'
import { InputNumberMask } from '../../../components/form/InputNumberMask'
import { Select } from '../../../components/form/Select'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { FormattedOneFreightExpenseType } from '../../../services/endpoints/freights/financial/getOneFinancialTitle'
import { eventOptions, typeOptions } from '../../../services/endpoints/freights/financial/PostFinancialTitle'
import { bankList } from '../../../services/utils/bankList'

interface FormFreightExpenseProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  data?: FormattedOneFreightExpenseType
  cte?: SelectOption[]
  setAttachment_file: (file: FileList) => void
  setObservation?: (obs: string) => void
}
export function FormCreateOrEditFreightExpense({
  formState,
  setValue,
  cte,
  data,
  setAttachment_file,
  setObservation,
}: FormFreightExpenseProps): JSX.Element {
  const { errors } = formState
  const [isAddObs, setIsAddObs] = useState(false)

  return (
    <Box>
      <Heading size="md" fontWeight="normal" mb="6">
        Informações básicas
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap="2">
        {cte && cte.length > 0 ? (
          <GridItem colSpan={[12, 4, 3]}>
            <Select
              name="cte_id"
              label="CTE"
              error={errors.cte_id}
              setValue={setValue}
              initialValue={data?.cte_id}
              options={[{ label: 'Selecione', value: '' }, ...cte]}
              isDisabled={!!data?.cte_id}
              isRequired={data?.status === 'awaiting_proof' && !data?.cte}
            />
          </GridItem>
        ) : (
          <GridItem colSpan={[12, 4, 3]}>
            <Select
              name="cte_id"
              label="CTE"
              error={errors.cte_id}
              setValue={setValue}
              initialValue={data?.cte_id}
              options={[
                { label: 'Selecione', value: '' },
                { label: 'Não há CTE no frete', value: '' },
              ]}
              isDisabled={!!data?.cte_id}
              isRequired={data?.status === 'awaiting_proof' && !data?.cte}
            />
          </GridItem>
        )}

        {data?.created_at_short && (
          <GridItem colSpan={[12, 3]}>
            <Input
              minW={200}
              type="datetime-local"
              name="date"
              label="Data"
              error={errors.date}
              setValue={() => {
                setValue('date', undefined)
              }}
              initialValue={data?.created_at_short}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 12, 12, data?.cte || cte ? 3 : 6]}>
          <Select
            name="type"
            label="Tipo de serviço"
            error={errors.type}
            setValue={setValue}
            initialValue={data?.type}
            options={typeOptions}
            isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 12, 12, data?.cte || cte ? 3 : 6]}>
          <Select
            name="event"
            label="Documento"
            error={errors.event}
            setValue={setValue}
            initialValue={data?.event}
            options={eventOptions}
            isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
            isRequired
          />
        </GridItem>
      </Grid>
      <Box>
        <Divider my={3} />
        <Heading size="md" fontWeight="normal" mb="6">
          Recebedor
        </Heading>
        <Grid templateColumns="repeat(12, 1fr)" gap="3">
          <GridItem colSpan={[12, 6]}>
            <Input
              name="name_recipient"
              label="Name"
              error={errors.name_recipient}
              setValue={setValue}
              initialValue={data?.name_recipient}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              isRequired
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <Input
              name="document_recipient"
              label="CPF / CNPJ"
              error={errors.document_recipient}
              initialValue={data?.document_recipient}
              setValue={setValue}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              isRequired
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <InputNumberMask
              isCashBRL
              name="value"
              label="Valor"
              error={errors.value}
              setValue={setValue}
              defaultValue={Number(data?.value)}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              isRequired
            />
          </GridItem>
        </Grid>
      </Box>

      <Box>
        <Divider my={3} />
        <Heading size="md" fontWeight="normal" mb="6">
          Pagamento
        </Heading>
        <Grid templateColumns="repeat(12, 1fr)" gap="3">
          <GridItem colSpan={12}>
            <Input
              name="pix"
              label="PIX"
              error={errors.pix}
              setValue={setValue}
              initialValue={data?.pix}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            {data?.bank ? (
              <Autocomplete
                name="bank"
                label="Banco"
                error={errors.bank}
                setValue={setValue}
                options={[{ label: '', value: '' }, ...bankList]}
                initialValue={
                  data?.bank
                    ? {
                        label: String(data?.bank),
                        value: String(data?.bank),
                      }
                    : undefined
                }
                isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              />
            ) : (
              <Autocomplete
                name="bank"
                label="Banco"
                error={errors.bank}
                setValue={setValue}
                options={[{ label: '', value: '' }, ...bankList]}
                isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
                isRequired={errors.bank}
              />
            )}
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <Select
              name="account_type"
              label="Tipo de conta"
              error={errors.account_type}
              setValue={setValue}
              options={[
                { label: '', value: '' },
                { label: 'Corrente', value: 'current' },
                { label: 'Poupança', value: 'savings' },
                { label: 'Outros', value: 'others' },
              ]}
              initialValue={data?.account_type}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              isRequired={errors.account_type}
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <Input
              name="agency"
              label="Agencia"
              error={errors.agency}
              setValue={setValue}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              initialValue={data?.agency}
              isRequired={errors.agency}
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <Input
              name="account"
              label="Conta"
              error={errors.account}
              setValue={setValue}
              isDisabled={data && !['awaiting_proof', 'rejected'].includes(data?.status)}
              initialValue={data?.account}
              isRequired={errors.account}
            />
          </GridItem>
        </Grid>
      </Box>

      <Box>
        <Divider my={3} />

        <Heading size="md" fontWeight="normal" mb="6">
          Comprovantes
        </Heading>
        <Grid templateColumns="repeat(12, 1fr)" gap="3">
          <GridItem colSpan={12}>
            <Flex gridGap={6} direction="column">
              <Input
                h="35px"
                name="attachment_file"
                type="file"
                label="Anexar comprovantes"
                setValue={setValue}
                onChange={e => {
                  if (e.target.files) {
                    setAttachment_file(e.target.files)
                  }
                }}
                error={!data?.attachment_file_url ? errors.attachment_file : undefined}
                multiple
              />
            </Flex>
          </GridItem>

          {data && data.observation && (
            <GridItem colSpan={12}>
              <Text fontSize="md" fontWeight="bold" my="2">
                Observação
              </Text>
              <Textarea size="md" fontWeight="bold" isDisabled defaultValue={data.observation} />
            </GridItem>
          )}

          <Ability module="spotHub" action="create-observation-financial-title">
            {isAddObs && (
              <GridItem colSpan={12}>
                <Text fontSize="md" fontWeight="bold" my="2">
                  Adicionar Observação
                </Text>
                <Textarea
                  onChange={e => {
                    if (setObservation) setObservation(e.target.value)
                  }}
                  name="observation"
                  size="md"
                />
              </GridItem>
            )}

            <GridItem colSpan={12} mt={2}>
              <Button colorScheme="blue" onClick={() => setIsAddObs(!isAddObs)}>
                + Adicionar Observação
              </Button>
            </GridItem>
          </Ability>
        </Grid>
      </Box>
    </Box>
  )
}
