import { Box, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RadioGroup, TextArea } from '../../components/form'

interface FormProps {
  card: string
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
}

export function ClientFeedbackShortForm({ card, formState, setValue }: FormProps): JSX.Element {
  const [selectedScore, setSelectedScore] = useState<number>()

  const { errors } = formState

  return (
    <>
      <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
        <Text fontSize="lg" fontWeight="bold">
          Qual o seu nível de satisfação ao realizar esse frete com a SpotX? *
        </Text>
        <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

        <RadioGroup
          mt="6"
          direction={['row']}
          name="spotx_note"
          error={errors.spotx_note}
          setValue={setValue}
          onChange={e => setSelectedScore(Number(e))}
          options={[
            { label: '0', value: 0 },
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 },
          ]}
        />
      </Box>
      {selectedScore !== undefined && selectedScore <= 6 && (
        <Box bg={card} maxW="620px" w="100%" p={8} borderRadius={8} shadow="lg">
          <Text fontSize="md" fontWeight="bold" my="2">
            Por quê você deu essa nota? (Fique à vontade para falar o que quiser)
          </Text>
          <TextArea name="because_this_note" size="md" setValue={setValue} />
        </Box>
      )}
    </>
  )
}
