import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'

type UploadBalanceRequestAttachmentsType = {
  data: FormData
}

async function uploadBalanceRequestAttachments(data: UploadBalanceRequestAttachmentsType) {
  const response = await apiServer.post('/balance-requests/upload', data.data)
  queryClient.invalidateQueries('balance-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUploadBalanceRequestAttachments(
  options?: UseMutationOptions<AxiosResponse, unknown, UploadBalanceRequestAttachmentsType, unknown>,
) {
  return useMutation(
    async (data: UploadBalanceRequestAttachmentsType) => uploadBalanceRequestAttachments(data),
    options,
  )
}
