import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

type DrawerWrapperProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title: string
  showForceClose?: boolean
  isSubmitting?: boolean
  onForceClose?: () => void
  onSave?: () => void
  disabled?: boolean
  onSaveLabel?: string
  hiddenSaveButton?: boolean
  disabaledContent?: boolean
  onCloseLabel?: string
}

export const DrawerWrapper = ({
  children,
  isOpen,
  onClose,
  title,
  showForceClose,
  onForceClose,
  isSubmitting,
  onSave,
  disabled,
  onSaveLabel = 'Salvar',
  onCloseLabel = 'Fechar',
  hiddenSaveButton = false,
  disabaledContent,
}: DrawerWrapperProps): JSX.Element => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} autoFocus={false} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader py="2" fontWeight="bold" fontSize="sm">
          {title}
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing="2" align="stretch" pointerEvents={disabaledContent ? 'none' : 'auto'}>
            {children}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          {showForceClose && (
            <Button size="sm" variant="outline" colorScheme="gray" mr="auto" onClick={onForceClose}>
              Fechar sem salvar
            </Button>
          )}

          <Button size="sm" colorScheme="gray" variant="outline" mr={3} onClick={onForceClose}>
            {onCloseLabel}
          </Button>
          {!hiddenSaveButton && (
            <Button
              size="sm"
              variant="solid"
              colorScheme="green"
              isLoading={isSubmitting}
              disabled={disabled}
              onClick={onSave}
              aria-label="save"
            >
              {onSaveLabel}
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
