import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { BalanceRequestType } from '../../types/BalanceRequestType'
import { formatBalanceRequestStatus } from '../../utils/formatBalanceRequestStatus'
import parseCurrency from '../../utils/parseCurrency'

export type BalanceRequestTypeFormatted = BalanceRequestType & {
  formatted_status: string
  balance_formatted: string
  total_value_formatted: string
}

type FindBalanceRequestResponse = BalanceRequestTypeFormatted

export type FilterBalanceRequestsType = {
  id: string
}

async function findBalanceRequest(params: FilterBalanceRequestsType) {
  const { data: response } = await apiServer.get(`/balance-requests/find`, {
    params: {
      ...params,
    },
  })

  const { data } = response

  const calcBalanceValue = data.total_value * 0.3

  return {
    ...data,
    formatted_status: formatBalanceRequestStatus(data.status),
    total_value_formatted: parseCurrency(data.total_value),
    balance_formatted: parseCurrency(calcBalanceValue),
    created_at: format(new Date(data.created_at), 'dd/MM/yy HH:mm'),
  }
}

export function useGetBalanceRequest(
  params: FilterBalanceRequestsType,
): UseQueryResult<FindBalanceRequestResponse> {
  return useQuery(['balance-requests', params], () => findBalanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
