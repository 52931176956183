/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Autocomplete } from '../../../../../components/form/Autocomplete'
import { Input } from '../../../../../components/form/Input'
import { RadioGroup } from '../../../../../components/form/RadioGroup'
import { Select } from '../../../../../components/form/Select'
import { Switch } from '../../../../../components/form/Switch'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { ReloadButton } from '../../../../../components/ReloadButton'
import { ReportToExcel } from '../../../../../components/reports/ReportToExcel'
import { useAuth } from '../../../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../../layout'
import { Pagination } from '../../../../../layout/Pagination'
import {
  fetchAllFreightExpense,
  FilterFreightExpenseParams,
  financialTitleStatusOptions,
  useGetAllFreightExpense,
} from '../../../../../services/endpoints/freights/financial/getAllFinancialTitles'
import { eventOptions } from '../../../../../services/endpoints/freights/financial/PostFinancialTitle'
import { getUserOperation } from '../../../../../services/endpoints/users/getUserOperation'
import { formatDateForInitialValue } from '../../../../../services/utils/dates/formatDateForInitialValue'
import { boxWidth } from '../../../../../services/utils/tableAdaptable'
import { TableListFreightExpense } from './TableListFinancialTitle'

const filterFormSchema = yup.object().shape({
  page: yup.number(),
  per_page: yup.number(),
  freight_id: yup.string(),
  user_id: yup.object().shape({}),
  cte_number: yup.string(),
  freight_number: yup.string(),
  name_recipient: yup.string(),
  document_recipient: yup.string(),
  paid: yup.string(),
  status: yup.string(),
  event: yup.string(),
  initial_date: yup.date(),
  final_date: yup.date(),
})

export function ListAllFreightExpensePage(): JSX.Element {
  const initialDate = () => {
    const now = new Date()
    return format(now.setDate(now.getDate() - 30), 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const bg = useColorModeValue('white', 'gray.800')
  const { getUserIdToListFreights, setUserIdToListFreights } = useAuth()
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState<AutocompleteOption[]>([])
  const [filters, setFilters] = useState<FilterFreightExpenseParams>({
    initial_date: initialDate(),
    final_date: finalDate(),
  })
  const [paid, setPaid] = useState<boolean>()
  const { updateQueryParams, getParams } = useQueryParamUpdater<FilterFreightExpenseParams>()

  const {
    data: allFreightExpense,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetAllFreightExpense({
    ...filters,
    page,
    per_page: 8,
  })

  const [FreightExpense, setFreightExpense] = useState(allFreightExpense)

  const [isFilters, setIsFilters] = useState<boolean>()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors } = formState

  useEffect(() => {
    setFreightExpense(allFreightExpense)
  }, [allFreightExpense, setUserIdToListFreights])

  const handleFilterFreightExpense: SubmitHandler<FilterFreightExpenseParams> = async data => {
    const dataFilter = {
      freight_number: data.freight_number,
      cte_number: data.cte_number,
      name_recipient: data.name_recipient,
      document_recipient: data.document_recipient,
      user_id: data.user_id?.value,
      paid,
      status: data.status,
      event: data.event || undefined,
      order_by_created_at: data.order_by_created_at,
      initial_date: formatDateForInitialValue(data.initial_date),
      final_date: formatDateForInitialValue(data.final_date),
    } as FilterFreightExpenseParams
    const {
      initial_date,
      final_date,
      order_by_created_at,
      name_recipient,
      event,
      document_recipient,
      status,
      user_id,
      cte_number,
      freight_number,
    } = dataFilter
    updateQueryParams({
      initial_date,
      final_date,
      order_by_created_at: String(order_by_created_at),
      document_recipient,
      name_recipient,
      event,
      status,
      user_id: data.user_id?.value ? String(data.user_id?.value) : undefined,
      user_label: data.user_id?.label ? String(data.user_id?.label) : undefined,
      cte_number,
      freight_number,
      page: String(page),
    })
    setFilters({
      initial_date,
      final_date,
      order_by_created_at,
      document_recipient,
      name_recipient,
      event,
      status,
      paid,
      user_id,
      user_label: data.user_id?.label,
      cte_number,
      freight_number,
    })
    setPage(1)
  }

  useEffect(() => {
    const userLabelParam = getParams('user_label')

    updateQueryParams({
      initial_date: filters.initial_date,
      final_date: filters.final_date,
      order_by_created_at: String(filters.order_by_created_at),
      document_recipient: filters.document_recipient,
      name_recipient: filters.name_recipient,
      event: filters.event,
      status: filters.status,
      user_id: filters.user_id as any,
      user_label: userLabelParam,
      cte_number: filters.cte_number,
      freight_number: filters.freight_number,
      page: String(page),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    const initialDateParam = getParams('initial_date')
    const finalDateParam = getParams('final_date')
    const orderByCreatedAtParam = getParams('order_by_created_at')
    const documentRecipientParam = getParams('document_recipient')
    const nameRecipientParam = getParams('name_recipient')
    const eventParam = getParams('event')
    const statusParam = getParams('status')
    const cteNumberParam = getParams('cte_number')
    const freightNumberParam = getParams('freight_number')
    const userValueParam = getParams('user_id')
    const userLabelParam = getParams('user_label')
    const pageParam = getParams('page')

    if (pageParam) {
      setPage(Number(pageParam))
    }

    if (
      initialDateParam ||
      finalDateParam ||
      orderByCreatedAtParam ||
      documentRecipientParam ||
      nameRecipientParam ||
      eventParam ||
      statusParam ||
      userValueParam ||
      userLabelParam ||
      cteNumberParam ||
      freightNumberParam
    ) {
      setIsFilters(true)
      handleFilterFreightExpense({
        initial_date: initialDateParam,
        final_date: finalDateParam,
        order_by_created_at: orderByCreatedAtParam === 'undefined' ? false : Boolean(orderByCreatedAtParam),
        document_recipient: documentRecipientParam,
        name_recipient: nameRecipientParam,
        event: eventParam,
        status: statusParam,
        user_id: {
          label: userLabelParam,
          value: userValueParam,
        },
        freight_number: freightNumberParam,
        cte_number: cteNumberParam,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function loadUsers() {
      const usersFiltered = await getUserOperation()
      setUsers(usersFiltered)
    }
    loadUsers()
    getUserIdToListFreights()
  }, [])

  return (
    <Layout>
      <Box bg={bg} w={boxWidth} p="6" borderRadius="8" shadow="md">
        <Flex justify="space-between" alignItems="baseline" direction={['column', 'row']}>
          <Flex gridGap={4} mb="6" align="center">
            <Heading size="lg" fontWeight="normal">
              Lista de despesas
            </Heading>

            <ReloadButton isFetching={isFetching} refetch={refetch} />
          </Flex>
          <Box>
            <Switch
              name="filters"
              label="Filtros"
              setValue={setValue}
              onChange={e => {
                setIsFilters(e.target.checked)
                setFilters(prevState => prevState)
              }}
            />
          </Box>
        </Flex>
        <Box mb={4}>
          {isFilters && <Divider mb="4" />}
          {isFilters && (
            <form onSubmit={handleSubmit(handleFilterFreightExpense)} noValidate>
              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Input
                    type="date"
                    label="Data inicial"
                    name="initial_date"
                    setValue={setValue}
                    initialValue={filters?.initial_date || initialDate()}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Input
                    label="Data Final"
                    type="date"
                    name="final_date"
                    setValue={setValue}
                    initialValue={filters?.final_date || finalDate()}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]} mt={[0, 10, 12]}>
                  <Switch
                    name="order_by_created_at"
                    label="Por data de criação"
                    setValue={setValue}
                    initialValue={filters?.order_by_created_at}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Input
                    label="Documento"
                    name="document_recipient"
                    error={errors.document_recipient}
                    setValue={setValue}
                    initialValue={filters?.document_recipient}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Input
                    name="name_recipient"
                    label="Prestador"
                    error={errors.name_recipient}
                    setValue={setValue}
                    initialValue={filters?.name_recipient}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Select
                    name="event"
                    label="Evento"
                    error={errors.event}
                    setValue={setValue}
                    options={eventOptions}
                    initialValue={filters?.event}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <RadioGroup
                    name="paid"
                    label="Pago"
                    error={errors.paid}
                    setValue={setValue}
                    onSelectOption={op => {
                      if (op.value === 'yes') {
                        setPaid(true)
                      } else if (op.value === 'not') {
                        setPaid(false)
                      } else setPaid(undefined)
                    }}
                    options={[
                      { label: 'Sim', value: 'yes' },
                      { label: 'Não', value: 'not' },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]}>
                  <Select
                    name="status"
                    label="Status"
                    setValue={setValue}
                    options={financialTitleStatusOptions}
                    error={errors.status}
                    initialValue={filters?.status}
                  />
                </GridItem>
                <GridItem colSpan={[12, 6, 4]}>
                  {users.length > 0 && (
                    <Autocomplete
                      name="user_id"
                      label="Usuários"
                      onSelectOption={userSelected => {
                        if (userSelected.value) {
                          setUserIdToListFreights(userSelected)
                          getUserIdToListFreights()
                        } else {
                          setUserIdToListFreights()
                          getUserIdToListFreights()
                        }
                      }}
                      setValue={setValue}
                      placeholder="Filtrar por usuário"
                      options={users}
                      clearState={() => {
                        setUserIdToListFreights(undefined)
                        getUserIdToListFreights()
                      }}
                      initialValue={
                        filters.user_id && filters.user_label
                          ? {
                              label: filters.user_label,
                              value: filters.user_id as any,
                            }
                          : undefined
                      }
                    />
                  )}
                </GridItem>

                <GridItem colSpan={[12, 6, 4]}>
                  <Input name="cte_number" label="CTE" error={errors.cte_number} setValue={setValue} />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]}>
                  <Input
                    name="freight_number"
                    label="Número do frete"
                    error={errors.freight_number}
                    setValue={setValue}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 12]}>
                  <Flex direction="row" mt={4} justify="flex-end">
                    {FreightExpense && FreightExpense.data.length > 0 && (
                      <ReportToExcel
                        isLoading={isLoading}
                        documentTitle="Relatório de despesas"
                        columnsTitle={[
                          'FRETE',
                          'FILIAL',
                          'CTE',
                          'FORNECEDOR',
                          'EVENTO',
                          'USUÁRIO',
                          'DATA',
                          'STATUS',
                          'PAGO',
                          'VALOR',
                          'TOTAL',
                        ]}
                        onGenerateReport={async () => {
                          const report = await fetchAllFreightExpense({
                            initial_date: `${filters?.initial_date ?? initialDate()}T00:00:00`,
                            final_date: `${filters?.final_date ?? finalDate()}T23:59:59`,
                            unpaged: true,
                          })
                          return report.data.map(financial => {
                            return [
                              `#${financial.freight.freight_number}`,
                              financial.transporter,
                              financial.cte?.nct,
                              financial.name_recipient,
                              financial.event,
                              financial.user.name,
                              financial.created_at_formatted,
                              financial.status,
                              financial.paid,
                              financial.valueFormatted,
                            ] as string[]
                          })
                        }}
                      />
                    )}

                    <Box ml={2}>
                      <Button
                        type="submit"
                        size="md"
                        colorScheme="blue"
                        leftIcon={<Icon as={RiSearchLine} />}
                        isLoading={isLoading}
                      >
                        Filtrar
                      </Button>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          )}
          {isFilters && <Divider my="4" />}
        </Box>

        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : isError ? (
          <Text>Deu erro</Text>
        ) : (
          <TableListFreightExpense FreightExpense={FreightExpense?.data} />
        )}

        {FreightExpense && (
          <Pagination
            currentPage={page}
            totalCountOfRegisters={FreightExpense.total}
            registersPerPage={8}
            onPageChange={setPage}
            p="6"
          />
        )}
      </Box>
    </Layout>
  )
}
