import { Box, Divider, Heading, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import StateHandlerComponent from '../../../../components/UI/StateHandlerComponent'
import { toastError } from '../../../../config/error/toastError'
import { Layout } from '../../../../layout'
import { useGetActions } from '../../../../services/endpoints/authorization/getActions'
import { useGetPermissionsByRoles } from '../../../../services/endpoints/authorization/getPermissionsByRoles'
import { usePutPermissions } from '../../../../services/endpoints/authorization/putPermissions'
import { UserRolesOptions } from '../../../../services/types/AuthorizationModulesTypes'
import { ModulePermissions } from '../../module'
import { editPermissionsValidateSchema, FormEditPermissionsToManyRoles } from './form'
import Modules from './Modules'

export type Permissions = {
  module: string
  action: string
}

type Input = {
  roles: UserRolesOptions[]
  can?: ModulePermissions[]
  cannot?: ModulePermissions[]
}

export function EditPermissionsToManyRoles(): JSX.Element {
  const [permissions, setPermissions] = useState<{
    [key: string]: Input
  }>({})
  const toast = useToast()
  const {
    data,
    isError: isErrorPermissions,
    isLoading: isLoadingPermissions,
  } = useGetPermissionsByRoles({ roles: ['super'] })
  const { data: actions, isLoading, isError } = useGetActions()
  const updatePermissions = usePutPermissions()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(editPermissionsValidateSchema),
  })

  const handleChangePermissions = (module: string, value: Input) => {
    setPermissions(prev => ({
      ...prev,
      [module]: {
        ...prev[module],
        can: value.can,
        cannot: value.cannot,
      },
    }))
  }

  const combinePermissions = (
    permissionsToCombine: { [key: string]: Input },
    roleToCombine: UserRolesOptions[],
    permissionType: 'can' | 'cannot',
  ) => {
    const combined = {
      can: [] as ModulePermissions[],
      cannot: [] as ModulePermissions[],
      roles: roleToCombine,
    }

    Object.keys(permissionsToCombine).forEach(key => {
      combined.can.push(...(permissionsToCombine[key].can || []))
      combined.cannot.push(...(permissionsToCombine[key].cannot || []))
    })
    combined.can = combined.can.filter(action => action.value && permissionType === 'can')
    combined.cannot = combined.cannot.filter(action => action.value && permissionType === 'cannot')
    return combined
  }

  const handleSavePermissions = useCallback<SubmitHandler<FormEditPermissionsToManyRoles>>(
    async params => {
      const roles = params.roles.map(r => r.value) as UserRolesOptions[]
      const input = combinePermissions(permissions, roles, params.permissionType)

      const actionsSelected =
        input.cannot.some(c => c.value === true) || input.can.some(c => c.value === true)
      if (!actionsSelected) {
        toast({
          title: 'Nenhum permissão selecionada!',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 8000,
        })
        return
      }

      const dataToBePut = {
        roles: input.roles,
        can: input.can,
        cannot: [...input.cannot] as ModulePermissions[],
      }

      const onlyCanPermissions = dataToBePut.can
        .filter(permission => permission.value && params.permissionType === 'can')
        .filter(p => !dataToBePut.cannot.some(c => c.module === p.module && c.action === p.action))
      const onlyCannotPermissions = dataToBePut.cannot
        .filter(permission => permission.value && params.permissionType === 'cannot')
        .filter(p => !dataToBePut.can.some(c => c.module === p.module && c.action === p.action))

      try {
        await updatePermissions.mutateAsync({
          isUpdateManyRoles: true,
          roles: input.roles,
          can: onlyCanPermissions,
          cannot: onlyCannotPermissions,
        })
        toast({
          title: 'Permissões atualizadas com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 8000,
        })
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [permissions, toast, updatePermissions],
  )

  return (
    <Layout>
      <Heading as="h1" size="lg">
        Editar permissões por grupo de cargo
      </Heading>

      <Divider my={4} />
      <form onSubmit={handleSubmit(handleSavePermissions)} noValidate>
        <FormEditPermissionsToManyRoles formState={formState} setValue={setValue} />
      </form>

      <StateHandlerComponent
        loading={isLoading || isLoadingPermissions}
        error={isError || isErrorPermissions}
        hasData={!!actions && !!data}
      >
        <Box py={4}>
          {actions &&
            actions?.map((module: any) => {
              if ('roles_options' in module) {
                return null
              }

              return (
                <Modules
                  setValue={setValue}
                  key={module.module}
                  name={module.module}
                  moduleKey={module.module}
                  actions={module.actions}
                  can={[]}
                  cannot={[]}
                  onChange={value => {
                    handleChangePermissions(value.module, {
                      can: value.can,
                      cannot: value.cannot,
                      roles: ['super'],
                    })
                  }}
                />
              )
            })}
        </Box>
      </StateHandlerComponent>
    </Layout>
  )
}
