import { UseQueryResult, useQuery } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { FormattedAllFreightsInProgress } from './getFreightsInProgress'

export interface IRemainDistance {
  distanceToRoute: number
  distance: number
  freight_id: string
}

export async function getFreightRemainDistance(
  freight: FormattedAllFreightsInProgress,
): Promise<IRemainDistance | undefined> {
  if (!freight.remain_distance && !freight.distance_to_route && freight.last_motorist_waypoint) {
    const { data: response } = await apiServer.get<ResponseApiType<IRemainDistance>>(
      `/freight-remain-distance/${freight.id}`,
    )

    return response.data
  }
  if (freight && freight.remain_distance && freight.distance_to_route) {
    return {
      distanceToRoute: freight?.distance_to_route,
      distance: freight.remain_distance,
      freight_id: freight.id,
    }
  }
}

export function useGetFreightRemainDistance(
  freight: FormattedAllFreightsInProgress,
): UseQueryResult<IRemainDistance> {
  return useQuery(['freight-remain-distance', freight], () => getFreightRemainDistance(freight), {
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  })
}
