import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export async function searchBuonnyModels(fabricator: string, name?: string): Promise<AutocompleteOption[]> {
  if (fabricator) {
    const { data } = await apiServer.get<ResponseApiType<string[]>>(`/buonny/vehicle-models`, {
      params: {
        name,
        fabricator,
      },
    })

    return data.data.map(model => {
      const label = model
      const value = model

      return { label, value }
    })
  }
  return []
}
