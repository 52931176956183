import { useEffect } from 'react'

const FixOverflow = (): void => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (body) {
      body.style.overflowX = 'hidden'
    }

    return () => {
      if (body) {
        body.style.overflowX = 'auto'
      }
    }
  }, [])
}

export default FixOverflow
