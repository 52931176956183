import { Table, Thead, Tr, Th, Tbody, Td, Flex, Box, Heading, Spinner } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import { useGlobalVariables } from '../../../contexts/GlobalVariables'
import { Layout } from '../../../layout'
import {
  useReportMotorists,
  fetchReportMotorists,
} from '../../../services/endpoints/motorists/reportMotorists'

export function MotoristsReports(): JSX.Element {
  const { initial_date, final_date, setInitialDate, setFinalDate } = useGlobalVariables()

  const [initialDateMotorists, setInitialDateMotorists] = useState<string>(() => {
    const today = new Date()
    const week = today.setDate(today.getDate() - 1)
    return format(week, 'yyyy-MM-dd')
  })
  const [finalDateMotorists, setFinalDateMotorists] = useState<string>(initialDateMotorists)

  const reportMotorists = useReportMotorists(initialDateMotorists, finalDateMotorists)

  useEffect(() => {
    setInitialDateMotorists(initial_date)
    setFinalDateMotorists(final_date)
  }, [initial_date, final_date])

  return (
    <Layout
      initialDate={initial_date}
      finalDate={final_date}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      isFilters
      SidebarMobile
    >
      <Box>
        <Flex mb="4" gridGap="2" alignItems="center" justifyContent="center" fontSize={['md', 'lg']}>
          <Heading size="lg">Relatório de motoristas</Heading>
          {reportMotorists.data && reportMotorists.data.data.length > 0 && (
            <ReportToExcel
              isLoading={reportMotorists.isLoading}
              documentTitle="Relatório de motoristas"
              columnsTitle={['Data', 'Operador', 'Cadastros']}
              onGenerateReport={async () => {
                const report = await fetchReportMotorists(initial_date, final_date)
                return report.data.map(item => {
                  return [item.day, item.name, item.count] as string[]
                })
              }}
            />
          )}
        </Flex>

        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th fontSize="sm">Data</Th>
              <Th fontSize="sm">Operador</Th>
              <Th fontSize="sm">Cadastros</Th>
            </Tr>
          </Thead>
          {reportMotorists.isLoading ? (
            <Flex alignItems="center" justifyContent="center" my="10">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Tbody justifyContent="flex-end">
              {reportMotorists.data &&
                reportMotorists.data.data.map(motorists => (
                  <Tr key={motorists.creator_id}>
                    <Td fontSize="xs">{motorists.day}</Td>
                    <Td fontSize="xs">{motorists.name}</Td>
                    <Td fontSize="xs">{motorists.count}</Td>
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
      </Box>
    </Layout>
  )
}
