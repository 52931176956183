import { Grid, GridItem, GridProps, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { FormattedOneFreight } from '../../../../services/endpoints/freights'

interface OrigenAndDestinationProps extends GridProps {
  freight: FormattedOneFreight
}

const OrigenAndDestination = ({ freight, ...props }: OrigenAndDestinationProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  return (
    <Grid p="4" borderRadius="md" bg={bg} gap="4" templateColumns="repeat(12, 1fr)" mt="4" {...props}>
      <GridItem colSpan={[12]} d="flex" justifyContent="space-between" alignItems="center">
        <Heading fontSize="md" fontWeight="medium">
          Origem e Destino
        </Heading>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Endereço de origem:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.origin_city}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Endereço de destino:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm">
          {freight?.destination_city}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Endereço de coleta:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm" whiteSpace="pre-wrap">
          {freight?.collect_complement}
        </Text>
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Heading fontSize="sm" fontWeight="medium" color="gray.500">
          Endereço de entrega:
        </Heading>
        <Text fontWeight="medium" mt="1" fontSize="sm" whiteSpace="pre-wrap">
          {freight?.delivery_complement}
        </Text>
      </GridItem>
    </Grid>
  )
}

export default OrigenAndDestination
