import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TfiTruck } from 'react-icons/tfi'
import * as yup from 'yup'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { RadioGroup } from '../../../components/form'
import { InputMask } from '../../../components/form/InputMask'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { useInvertRegistrations } from '../../../services/endpoints/vehicles/invert-registrations/InvertRegistrations'

export interface InvertRegistrationsForm {
  renavam: string
  typeInversion: string
}
export const InvertRegistrationsFormSchema = yup.object().shape({
  typeInversion: yup.string().required('Campo obrigatório'),
  renavam: yup.string().required('Campo obrigatório'),
})

export function InvertRegistrations(): JSX.Element {
  const toast = useToast()

  const [data, setData] = useState<InvertRegistrationsForm>()
  const [typeInversion, setTypeInversion] = useState<'vehicleToTrailer' | 'trailerToVehicle'>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const bg = useColorModeValue('white', 'gray.800')
  const { setValue, handleSubmit, formState, getValues, reset } = useForm({
    resolver: yupResolver(InvertRegistrationsFormSchema),
  })
  const { errors, isSubmitting } = formState
  const invert = useInvertRegistrations({
    onSuccess: () => {
      toast({
        title: 'Inversão de registro concluída com sucesso',
        position: 'top-right',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      reset()
      setData(undefined)
      setTypeInversion(undefined)
    },
  })

  const handleInvertRegistrations: SubmitHandler<InvertRegistrationsForm> = params => {
    setData(params)
    onOpen()
  }

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Inverter cadastros entre veículos e reboques
          </Heading>
        </Flex>
        <Divider my="4" />
        <form onSubmit={handleSubmit(handleInvertRegistrations)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3" alignItems="center">
            <GridItem colSpan={[12, 6, 12]}>
              <RadioGroup
                label="Escolha o tipo de inversão"
                name="typeInversion"
                error={errors.typeInversion}
                setValue={setValue}
                options={[
                  { label: 'De VEÍCULO para reboque', value: 'vehicleToTrailer' },
                  { label: 'De REBOQUE para veículo', value: 'trailerToVehicle' },
                ]}
                onSelectOption={option => {
                  setTypeInversion(option.value as 'vehicleToTrailer' | 'trailerToVehicle')
                }}
                isRequired
              />
            </GridItem>

            {typeInversion && (
              <GridItem colSpan={[12, 12, 4, 3]} fontSize={['xs', 'sm']}>
                <InputMask
                  mask="99999999999"
                  placeholder={
                    typeInversion === 'vehicleToTrailer'
                      ? 'Renavam do veículo'
                      : typeInversion === 'trailerToVehicle'
                      ? 'Renavam do reboque'
                      : 'Renavam'
                  }
                  maskPlaceholder=""
                  label={
                    typeInversion === 'vehicleToTrailer'
                      ? 'Renavam do veículo'
                      : typeInversion === 'trailerToVehicle'
                      ? 'Renavam do reboque'
                      : 'Renavam'
                  }
                  name="renavam"
                  error={errors.renavam}
                  setValue={setValue}
                  isRequired
                />
              </GridItem>
            )}
          </Grid>
          <Flex mt="4" justify="flex-end">
            <Button
              size="sm"
              type="submit"
              colorScheme="green"
              variant="outline"
              isLoading={isSubmitting}
              leftIcon={<Icon as={TfiTruck} />}
            >
              {typeInversion
                ? `Inverter de ${
                    typeInversion === 'vehicleToTrailer' ? 'VEÍCULO para reboque?' : 'REBOQUE para veículo?'
                  }`
                : 'Inverter cadastro'}
            </Button>
          </Flex>
        </form>
        <AlertDialogConfirm
          title={`Tem certeza que deseja inverter cadastro do ${
            getValues('typeInversion') === 'vehicleToTrailer'
              ? 'VEÍCULO para reboque?'
              : 'REBOQUE para veículo?'
          }`}
          onClose={onClose}
          isLoading={invert.isLoading}
          isOpen={isOpen}
          onConfirm={async () => {
            try {
              if (data) await invert.mutateAsync(data)
            } catch (error) {
              toastError({ toast, error })
            }
          }}
        />
      </Box>
    </Layout>
  )
}
