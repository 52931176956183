import { useDisclosure } from '@chakra-ui/react'
import { FinishFreightModal } from '../../pages/freights/show/cards/selected-motorist/FinishFreightModal'
import { useAppSelector } from '../../store'
import { CollectingPhase } from './freightPhases/CollectingPhase'
import { DeliveredPhase } from './freightPhases/DeliveredPhase '
import { InDestinationPhase } from './freightPhases/InDestinationPhase '
import { InOriginPhase } from './freightPhases/InOriginPhase'
import { OnRoadPhase } from './freightPhases/OnRoadPhase'
import { ReadyPhase } from './freightPhases/ReadyPhase'

// timeline

export function AccordionFreightPhases(): JSX.Element {
  const { freight } = useAppSelector(state => state.freightSlice)
  const {
    isOpen: isFinishFreightOpen,
    onClose: onFinishFreightClose,
    onOpen: onFinishFreightOpen,
  } = useDisclosure()

  return (
    <>
      {freight && (
        <>
          <InOriginPhase />

          <CollectingPhase />

          <ReadyPhase />

          <OnRoadPhase />

          <InDestinationPhase />

          <DeliveredPhase onFinishFreightOpen={onFinishFreightOpen} />

          {freight.motorist ? (
            <>
              {freight.status === 'delivered' && (
                <FinishFreightModal
                  isOpen={isFinishFreightOpen}
                  onClose={() => {
                    onFinishFreightClose()
                  }}
                  freight={freight}
                  motorist_id={freight.motorist.id}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </>
  )
}
