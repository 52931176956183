/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { IoReload } from 'react-icons/io5'
import { MdOutlineRemoveCircleOutline, MdOutlineShowChart } from 'react-icons/md'
import { RiFileCopyLine, RiUserLine, RiUserLocationLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { apiServer } from '../../../../../services/api'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { useGetAppInfos } from '../../../../../services/endpoints/mobile/app-infos/getAppInfos'
import { useGetCurrentDeviceInfo } from '../../../../../services/endpoints/mobile/device-infos/getCurrentDeviceInfo'
import { useGetMotoristLastCheckin } from '../../../../../services/endpoints/motorists/getMotoristLastCheckin'
import RateFreightType from '../../../../../services/types/RateFreightType'
import { copyToClipboard } from '../../../../../services/utils/copyToClipboard'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import {
  sendingInitialRequestMonitoringWhatsAppByZApi,
  setDisabledBtnFcm,
  setDisabledBtnWpp,
  setFcmTime,
  setInitialBtnWhatsAppByZApi,
  setWppTime,
} from '../../../../../store/slices/freightSlice'
import { UnlinkMotoristModal } from './UnlinkMotoristModal'

interface SelectedMotoristProps {
  freight: FormattedOneFreight
  bg: string
}

export function SelectedMotorist({ freight, bg }: SelectedMotoristProps): JSX.Element | null {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { btnWppDisabled, btnFcmDisabled, wppTime, fcmTime, initialBtnWppMonitoringDisabled } =
    useAppSelector(state => state.freightSlice)
  const {
    data: deviceInfos,
    refetch: refetchDeviceInfos,
    isFetching: isFetchingDeviceInfos,
    isLoading: isLoadingDeviceInfos,
    error: errorDeviceInfos,
  } = useGetCurrentDeviceInfo(freight.id, !!freight?.motorist_id)
  const {
    data: appInfos,
    refetch: refetchAppInfos,
    isFetching: isFetchingAppInfos,
    isLoading: isLoadingAppInfos,
    error: errorAppInfos,
  } = useGetAppInfos({
    motorist_id: String(freight?.motorist_id),
    freight_id: freight.id,
    has_motorist: !!freight?.motorist_id,
  })

  const { data: lastCheckin } = useGetMotoristLastCheckin(freight?.motorist_id)
  const {
    isOpen: isOpenUnlinkMotorist,
    onOpen: onOpenUnlinkMotorist,
    onClose: onCloseUnlinkMotorist,
  } = useDisclosure()

  function rateFreightAverage(data?: RateFreightType) {
    if (data) {
      const { behavior_score, cargo_state_score, ponctuality_score, reliability_score } = data
      const sum = behavior_score + cargo_state_score + ponctuality_score + reliability_score
      return sum / 4
    }

    return ''
  }

  async function handleEnabledMonitoringWhatsApp() {
    localStorage.setItem('@spotx:time_wpp', String(31))
    localStorage.setItem('@spotx:btn_disabled_wpp', String(true))
    dispatch(setWppTime(31))
    dispatch(setDisabledBtnWpp(Boolean(true)))
    if (freight?.motorist?.id) {
      apiServer
        .post(`/freights/sending-reactivation-by-whatsapp/${freight.motorist.id}`)
        .then(res => {
          const { status } = res.data.status
          if (status) {
            toast({
              status: 'success',
              title: `Mensagem enviada para o motorista ${freight.motorist?.name} com sucesso!`,
              position: 'top-right',
              isClosable: true,
            })
          }
        })
        .catch(err => {
          toast({
            status: 'error',
            title: `Ocorreu algum erro no envio da mensagem! Error: ${err.data.message}`,
            position: 'top-right',
            isClosable: true,
          })
        })
    }
  }

  useEffect(() => {
    const time_wpp_local_storage = localStorage.getItem('@spotx:time_wpp')
    const btn_wpp_disabled_local_storage = localStorage.getItem('@spotx:btn_disabled_wpp')

    if (time_wpp_local_storage === null) {
      localStorage.setItem('@spotx:time_wpp', String(31))
    }

    if (btn_wpp_disabled_local_storage === null) {
      localStorage.setItem('@spotx:btn_disabled_wpp', String(false))
    }

    if (btn_wpp_disabled_local_storage && btnWppDisabled) {
      if (
        time_wpp_local_storage &&
        Number(time_wpp_local_storage) >= 5 &&
        Number(time_wpp_local_storage) < 28
      ) {
        dispatch(setWppTime(Number(time_wpp_local_storage)))
      }

      setTimeout(() => {
        if (wppTime && btn_wpp_disabled_local_storage) {
          const time = wppTime - 1
          localStorage.setItem('@spotx:time_wpp', String(time))
          dispatch(setWppTime(time))

          if (time === 0) {
            dispatch(setDisabledBtnWpp(Boolean(false)))
            localStorage.setItem('@spotx:time_wpp', String(31))
            localStorage.setItem('@spotx:btn_disabled_wpp', String(false))
            // eslint-disable-next-line no-useless-return
            return
          }
        }
      }, 1000)
    }
    if (wppTime === 0) {
      dispatch(setDisabledBtnWpp(Boolean(false)))
      localStorage.setItem('@spotx:time_wpp', String(31))
      localStorage.setItem('@spotx:btn_disabled_wpp', String(false))
      // eslint-disable-next-line no-useless-return
      return
    }
  }, [btnWppDisabled, dispatch, wppTime])

  async function handleEnabledMonitoringFcm() {
    localStorage.setItem('@spotx:time_fcm', String(31))
    localStorage.setItem('@spotx:btn_disabled_fcm', String(true))
    dispatch(setFcmTime(31))
    dispatch(setDisabledBtnFcm(Boolean(true)))
    if (freight?.motorist?.id) {
      apiServer
        .post(`/freights/sending-reactivation-by-fcm/${freight.motorist.id}`)
        .then(res => {
          const { status } = res.data.status
          if (status) {
            toast({
              status: 'success',
              title: `Notificação silenciada foi enviada para o aplicativo do motorista com sucesso!`,
              position: 'top-right',
              isClosable: true,
            })
          }
        })
        .catch(err => {
          toast({
            status: 'error',
            title: `Ocorreu algum erro no envio da mensagem! Error: ${err?.data?.message}`,
            position: 'top-right',
            isClosable: true,
          })
        })
    }
  }

  useEffect(() => {
    const time_fcm_local_storage = localStorage.getItem('@spotx:time_fcm')
    const btn_fcm_disabled_local_storage = localStorage.getItem('@spotx:btn_disabled_fcm')

    if (time_fcm_local_storage === null) {
      localStorage.setItem('@spotx:time_fcm', String(31))
    }

    if (btn_fcm_disabled_local_storage === null) {
      localStorage.setItem('@spotx:btn_disabled_fcm', String(false))
    }

    if (btn_fcm_disabled_local_storage && btnFcmDisabled) {
      if (
        time_fcm_local_storage &&
        Number(time_fcm_local_storage) >= 5 &&
        Number(time_fcm_local_storage) < 28
      ) {
        dispatch(setFcmTime(Number(time_fcm_local_storage)))
      }

      setTimeout(() => {
        if (fcmTime && btn_fcm_disabled_local_storage) {
          const time = fcmTime - 1
          localStorage.setItem('@spotx:time_fcm', String(time))
          dispatch(setFcmTime(time))

          if (time === 0) {
            dispatch(setDisabledBtnFcm(Boolean(false)))
            localStorage.setItem('@spotx:time_fcm', String(31))
            localStorage.setItem('@spotx:btn_disabled_fcm', String(false))
            // eslint-disable-next-line no-useless-return
            return
          }
        }
      }, 1000)
    }
    if (fcmTime === 0) {
      dispatch(setDisabledBtnFcm(Boolean(false)))
      localStorage.setItem('@spotx:time_fcm', String(31))
      localStorage.setItem('@spotx:btn_disabled_fcm', String(false))
      // eslint-disable-next-line no-useless-return
      return
    }
  }, [btnFcmDisabled, dispatch, fcmTime])

  return freight.motorist ? (
    <VStack spacing={4} align="stretch">
      <UnlinkMotoristModal
        isOpen={isOpenUnlinkMotorist}
        freight_id={freight.id}
        onClose={() => {
          onCloseUnlinkMotorist()
        }}
      />

      <Grid
        p="4"
        borderRadius="md"
        gap="4"
        bg={bg}
        templateColumns="repeat(12, 1fr)"
        aria-label="Informações do motorista"
      >
        <GridItem
          colSpan={[12]}
          display="flex"
          flexDir={['column', 'row']}
          justifyContent="space-between"
          gridGap="4"
        >
          <Heading fontSize="md" fontWeight="medium">
            Informações do motorista
          </Heading>

          <ButtonGroup variant="outline" spacing="2">
            {freight?.status === 'finished' && (
              <IconButton
                onClick={() => {
                  copyToClipboard(
                    `https://admin.spotx.com.br/motorist-feedback/${freight.id}?short-feedback=true`,
                  )
                  toast({
                    title: 'Copiado com sucesso!',
                    status: 'success',
                    position: 'top-right',
                    duration: 1000,
                  })
                }}
                size="sm"
                colorScheme="blue"
                icon={<RiFileCopyLine />}
                variant="outline"
                aria-label="copy link"
              />
            )}
            {freight?.status === 'finished' && (
              <Button
                as={Link}
                to={`/motorists/${freight.motorist.id}`}
                size="sm"
                colorScheme="blue"
                variant="outline"
                leftIcon={<MdOutlineShowChart />}
              >
                NPS do motorista
              </Button>
            )}
            <Button
              as={Link}
              to={`/motorists/show/${freight.motorist.id}`}
              size="sm"
              variant="outline"
              maxW="150px"
              colorScheme="messenger"
              leftIcon={<Icon as={RiUserLine} />}
            >
              Ver motorista
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={() => {
                onOpenUnlinkMotorist()
              }}
              leftIcon={<MdOutlineRemoveCircleOutline />}
            >
              Desvincular motorista
            </Button>
          </ButtonGroup>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Nome:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight.motorist.name}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Veículo:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight.formatted_vehicle}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Placa:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight.vehicle?.license_plate}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Ano:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight.vehicle?.release_year}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Telefone:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight.motorist.phone}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Último checkin:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {lastCheckin?.checkin_city}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Nota no frete:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {rateFreightAverage(freight?.rate)}
          </Text>
        </GridItem>
      </Grid>

      <Grid
        p="4"
        borderRadius="md"
        gap="4"
        bg={bg}
        templateColumns="repeat(12, 1fr)"
        aria-label="Informações do dispositivo"
      >
        <GridItem colSpan={[12]}>
          <Heading fontSize="md" fontWeight="medium">
            Informações do dispositivo
            <Tooltip label="Atualizar dados" hasArrow placement="auto">
              <IconButton
                icon={<IoReload />}
                onClick={() => {
                  refetchDeviceInfos()
                }}
                isLoading={isFetchingDeviceInfos}
                aria-label="Recarregar"
                size="sm"
                ml="2"
                title="Recarregar"
              />
            </Tooltip>
          </Heading>
        </GridItem>
        <GridItem colSpan={[12]}>
          <Flex align="center" justify="space-between" gridGap={2}>
            {!['finished', 'disabled'].includes(freight.status) &&
              freight.has_monitoring_app &&
              freight.motorist_id && (
                <>
                  <Heading size="sm" fontWeight="normal">
                    Ativar app.:
                  </Heading>
                  <Tooltip label="Re-ativação do monitoramento por FCM(Notificação), esse meio de re-ativação é automática, basta o app está sendo executando para receber o FCM. (Obs.: caso o app não esteja sendo executado, NÃO irá iniciar)">
                    <Button
                      disabled={btnFcmDisabled}
                      size="sm"
                      colorScheme="cyan"
                      leftIcon={<Icon as={RiUserLocationLine} />}
                      onClick={handleEnabledMonitoringFcm}
                    >
                      {!btnFcmDisabled ? 'via FCM' : fcmTime}
                    </Button>
                  </Tooltip>

                  <Tooltip label="Re-ativação do monitoramento com solicitação enviada para o WhatsApp do motorista. O motorista irá receber uma mensagem automática no WhatsApp.">
                    <Button
                      disabled={btnWppDisabled}
                      size="sm"
                      colorScheme="whatsapp"
                      leftIcon={<Icon as={RiUserLocationLine} />}
                      onClick={handleEnabledMonitoringWhatsApp}
                    >
                      {!btnWppDisabled ? 'via WhatsApp' : wppTime}
                    </Button>
                  </Tooltip>
                </>
              )}
          </Flex>
          {isLoadingDeviceInfos ? (
            <Flex>
              <Spinner />
            </Flex>
          ) : deviceInfos ? (
            <>
              <TableContainer maxW={1000}>
                <Table variant="unstyled" size="sm">
                  <TableCaption>
                    <Heading size="sm" fontWeight="normal" mb="6">
                      <Divider my="4" />
                    </Heading>
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Bateria</Th>
                      <Th>GPS</Th>
                      <Th>Permissão GPS</Th>
                      <Th>Permissão câmera</Th>
                      <Th>Conexão</Th>
                      <Th>Tipo conexão</Th>
                      <Th>Offline em</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Flex gridGap={2}>
                          <Text
                            color={
                              Number(deviceInfos?.battery_level.split('%')[0]) > 25 &&
                              Number(deviceInfos?.battery_level.split('%')[0]) < 100
                                ? 'blue.400'
                                : Number(deviceInfos?.battery_level.split('%')[0]) <= 25
                                ? 'red.400'
                                : Number(deviceInfos?.battery_level.split('%')[0]) === 100 ||
                                  (['Carregando', 'Cheia'].includes(deviceInfos.battery_state) &&
                                    Number(deviceInfos?.battery_level.split('%')[0]) > 25)
                                ? 'green.400'
                                : undefined
                            }
                          >
                            {deviceInfos.battery_level}
                          </Text>
                          <Text
                            color={
                              deviceInfos.battery_state === 'Descarregando' &&
                              Number(deviceInfos?.battery_level.split('%')[0]) <= 25
                                ? 'red.400'
                                : ['Carregando', 'Cheia'].includes(deviceInfos.battery_state)
                                ? 'green.400'
                                : deviceInfos.battery_state === 'Descarregando' &&
                                  Number(deviceInfos?.battery_level.split('%')[0]) > 25
                                ? 'blue.400'
                                : undefined
                            }
                          >
                            {deviceInfos.battery_state}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        color={
                          deviceInfos.status_gps === 'Ligado'
                            ? 'green.400'
                            : deviceInfos.status_gps === 'Desligado'
                            ? 'red.400'
                            : undefined
                        }
                      >
                        {deviceInfos.status_gps}
                      </Td>
                      <Td
                        color={
                          deviceInfos.permission_location === 'Aceita'
                            ? 'green.400'
                            : deviceInfos.permission_location === 'Negada'
                            ? 'red.400'
                            : undefined
                        }
                      >
                        {deviceInfos.permission_location}
                      </Td>
                      <Td
                        color={
                          deviceInfos.permission_camera === 'Aceita'
                            ? 'green.400'
                            : deviceInfos.permission_camera === 'Negada'
                            ? 'red.400'
                            : undefined
                        }
                      >
                        {deviceInfos.permission_camera}
                      </Td>
                      <Td
                        color={
                          deviceInfos.has_connection === 'Conectado'
                            ? 'green.400'
                            : deviceInfos.has_connection === 'Offline'
                            ? 'red.400'
                            : undefined
                        }
                      >
                        {deviceInfos.has_connection}
                      </Td>
                      <Td
                        color={
                          ['Nenhuma'].includes(deviceInfos.type_connection)
                            ? 'red.400'
                            : [
                                'Desconhecida',
                                'Dados móveis',
                                'WI-FI',
                                'Bluetooth',
                                'Internet',
                                'WIMAX',
                                'VPN',
                                'Outra',
                              ].includes(deviceInfos.type_connection)
                            ? 'green.400'
                            : undefined
                        }
                      >
                        {deviceInfos.type_connection}
                      </Td>
                      <Td color={deviceInfos.date_last_offline !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.date_last_offline}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <TableContainer maxW={1000}>
                <Table variant="unstyled" size="sm">
                  <TableCaption>
                    {deviceInfos?.created_at && (
                      <Heading size="sm" fontWeight="normal" mb="6">
                        Último envio do App: {deviceInfos?.updated_at}
                      </Heading>
                    )}
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Versão O.S.</Th>
                      <Th>Nome O.S.</Th>
                      <Th>Modelo</Th>
                      <Th>Operadora</Th>
                      <Th>Marca</Th>
                      <Th>Atualizado</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td color={deviceInfos.system_version !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.system_version}
                      </Td>
                      <Td color={deviceInfos.system_name !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.system_name}
                      </Td>
                      <Td color={deviceInfos.device_model !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.device_model}
                      </Td>
                      <Td color={deviceInfos.carrier !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.carrier}
                      </Td>
                      <Td color={deviceInfos.device_brand !== 'Sem dados!' ? 'blue.400' : undefined}>
                        {deviceInfos.device_brand}
                      </Td>
                      <Td
                        color={deviceInfos.system_last_update_time !== 'Sem dados!' ? 'blue.400' : undefined}
                      >
                        {deviceInfos.system_last_update_time}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          ) : errorDeviceInfos ? (
            <Text textAlign="center">Erro ao carregar as informações do dispositivo</Text>
          ) : (
            <Text textAlign="center">Nenhuma informação sobre o dispositivo encontrada</Text>
          )}
        </GridItem>
      </Grid>

      <Grid
        p="4"
        borderRadius="md"
        gap="4"
        bg={bg}
        templateColumns="repeat(12, 1fr)"
        aria-label="Informações do aplicativo"
      >
        <GridItem colSpan={[12]}>
          <Heading fontSize="md" fontWeight="medium">
            Informações do aplicativo
            <Tooltip label="Atualizar dados" hasArrow placement="auto">
              <IconButton
                icon={<IoReload />}
                onClick={() => {
                  refetchAppInfos()
                }}
                isLoading={isFetchingAppInfos}
                aria-label="Recarregar"
                size="sm"
                ml="2"
                title="Recarregar"
              />
            </Tooltip>
          </Heading>
        </GridItem>
        <GridItem colSpan={[12]}>
          <>
            {isLoadingAppInfos ? (
              <Flex>
                <Spinner />
              </Flex>
            ) : appInfos && freight?.motorist_id ? (
              <>
                <TableContainer maxW={1000}>
                  <Table variant="unstyled" size="sm">
                    <TableCaption>
                      {appInfos?.updated_at && (
                        <Heading size="sm" fontWeight="normal" mb="6">
                          Último envio do App: {appInfos?.updated_at}
                        </Heading>
                      )}
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Versão App</Th>
                        <Th>Último acesso a página de frete</Th>
                        <Th>Última vez que abriu o app</Th>
                        <Th>Data de instalação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td color={deviceInfos?.app_version !== 'Sem dados!' ? 'blue.400' : undefined}>
                          {deviceInfos?.app_version}
                        </Td>
                        <Td
                          color={appInfos.last_open_my_freight_at !== 'Sem dados!' ? 'blue.400' : undefined}
                        >
                          {appInfos.last_open_my_freight_at}
                        </Td>
                        <Td color={appInfos.last_open_app_at !== 'Sem dados!' ? 'blue.400' : undefined}>
                          {appInfos.last_open_app_at}
                        </Td>
                        <Td color={appInfos.first_install_at !== 'Sem dados!' ? 'blue.400' : undefined}>
                          {appInfos.first_install_at}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            ) : errorAppInfos ? (
              <Text textAlign="center">Erro ao carregar as informações do aplicativo</Text>
            ) : (
              <Text textAlign="center">Nenhuma informação sobre o aplicativo encontrada</Text>
            )}
          </>
        </GridItem>
      </Grid>

      {freight.has_monitoring_whatsapp === false && freight.has_monitoring_app === true && (
        <>
          <Grid
            p="4"
            borderRadius="md"
            gap="4"
            bg={bg}
            templateColumns="repeat(12, 1fr)"
            aria-label="Ativação do monitoramento via WhatsApp"
          >
            <GridItem colSpan={[12]}>
              <Heading fontSize="md" fontWeight="medium">
                Ativação do monitoramento via WhatsApp
                <Tooltip label="Atualizar dados" hasArrow placement="auto">
                  <IconButton
                    icon={<IoReload />}
                    onClick={() => {
                      refetchAppInfos()
                    }}
                    isLoading={isFetchingAppInfos}
                    aria-label="Recarregar"
                    size="sm"
                    ml="2"
                    title="Recarregar"
                  />
                </Tooltip>
              </Heading>
            </GridItem>
            <GridItem colSpan={[12]}>
              <Alert variant="subtle" status="info" borderRadius="md" justifyContent="space-between">
                <Flex alignItems="center">
                  <AlertIcon />
                  <AlertTitle mr={2}>Monitoramento via WhatsApp!</AlertTitle>
                  <AlertDescription>
                    Caso o app tenha problema no monitoramento! E se você quiser iniciar um monitoramento via
                    WhatsApp, aperte no botão e inicie o monitoramento.
                  </AlertDescription>
                </Flex>
                <Tooltip label="O motorista irá receber uma mensagem solicitando e informando que ele precisa enviar a localização dele via WhatsApp.">
                  <Button
                    disabled={initialBtnWppMonitoringDisabled}
                    colorScheme="orange"
                    ml="4"
                    size="sm"
                    minW={150}
                    onClick={() => {
                      dispatch(setInitialBtnWhatsAppByZApi(true))
                      dispatch(sendingInitialRequestMonitoringWhatsAppByZApi(freight?.id))
                      toast({
                        title: 'Sucesso!',
                        description: 'Foi enviado com sucesso a mensagem para o motorista.',
                        status: 'success',
                        position: 'top-right',
                      })
                    }}
                  >
                    {initialBtnWppMonitoringDisabled ? 'Por favor, espere...' : 'Iniciar monitoramento'}
                  </Button>
                </Tooltip>
              </Alert>
            </GridItem>
          </Grid>
        </>
      )}
    </VStack>
  ) : null
}
