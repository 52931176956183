import { GridItem } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { AutocompleteOrTypeAsync } from '../../../../components/form/AutocompleteOrTypeAsync'
import {
  getClientBusinessGroup,
  getClientBusinessGroupSegment,
} from '../../../../services/endpoints/clients/getClientsBusinessGroupSegment'
import { ClientBusinessGroup } from '../../../../services/types'

type FormClientBusinessGroupProps = {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialValue?: ClientBusinessGroup
  colSpan?: number[]
}

export function FormClientBusinessGroup({
  setValue,
  formState,
  initialValue,
  colSpan,
}: FormClientBusinessGroupProps): JSX.Element {
  const { errors } = formState

  return (
    <>
      <GridItem colSpan={colSpan || [12, 6]}>
        <AutocompleteOrTypeAsync
          name="clientBusinessGroup.name"
          label="Nome"
          placeholder="Nome"
          setValue={setValue}
          loadOptions={getClientBusinessGroup}
          error={errors?.clientBusinessGroup?.name.label}
          initialValue={
            initialValue?.name && initialValue?.id
              ? {
                  label: initialValue.name,
                  value: initialValue.id,
                }
              : undefined
          }
          mr={1}
        />
      </GridItem>

      <GridItem colSpan={colSpan || [12, 6]}>
        <AutocompleteOrTypeAsync
          name="clientBusinessGroup.segment"
          label="Segmento"
          placeholder="Segmento"
          setValue={setValue}
          loadOptions={getClientBusinessGroupSegment}
          error={errors?.clientBusinessGroup?.segment?.label}
          ml={1}
          initialValue={
            initialValue?.segment && initialValue.segment_id
              ? {
                  label: initialValue.segment.name,
                  value: initialValue.segment_id,
                }
              : undefined
          }
        />
      </GridItem>
    </>
  )
}
