import { Flex, Icon, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import 'leaflet/dist/leaflet.css'
import { useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { GiCommercialAirplane } from 'react-icons/gi'
import {
  RiBriefcaseLine,
  RiFileSearchLine,
  RiSmartphoneLine,
  RiSteering2Line,
  RiTruckLine,
  RiUser2Line,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { DisableFreightModal } from '../../../pages/freights/edit/DisableFreightModal'
import { EditOrDuplicateFreightModal } from '../../../pages/freights/edit/EditOrDuplicateFreightModal'
import { EvaluateMotoristModal } from '../../../pages/freights/show/cards/selected-motorist/EvaluateMotoristModal'
import { UnlinkMotoristModal } from '../../../pages/freights/show/cards/selected-motorist/UnlinkMotoristModal'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { formattedTransporter } from '../../../services/utils/formattedTransporter'
import parseCurrency from '../../../services/utils/parseCurrency'
import { Container } from './Container'
import { DefaultPropCards } from './DefaultPropCards'
import FreightIcons from './FreightIcons'
import FreightStatus from './FreightStatus'

interface KanbanCardCurrentProps {
  freight: FormattedAllFreights
}

export function KanbanCardCurrent({ freight }: KanbanCardCurrentProps): JSX.Element {
  const bg = useColorModeValue('gray.200', 'gray.500')

  const [actionFreightType, setActionFreightType] = useState<'edit' | 'duplicate'>('edit')
  const {
    isOpen: isOpenEvaluateMotorist,
    onClose: onCloseEvaluateMotorist,
    onOpen: onOpenEvaluateMotorist,
  } = useDisclosure()

  const {
    isOpen: isDisableFreightModalOpen,
    onOpen: onDisableFreightModalOpen,
    onClose: onDisableFreightModalClose,
  } = useDisclosure()

  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenUnlinkMotorist,
    onOpen: onOpenUnlinkMotorist,
    onClose: onCloseUnlinkMotorist,
  } = useDisclosure()

  return (
    <Container
      freight={freight}
      header={
        <DefaultPropCards
          freight={freight}
          type="current"
          bg={bg}
          setActionFreightType={setActionFreightType}
          onFreightFormModalOpen={onFreightFormModalOpen}
          onDisableFreightModalOpen={onDisableFreightModalOpen}
          onOpenUnlinkMotorist={onOpenUnlinkMotorist}
        />
      }
    >
      <Flex justifyContent="space-between" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
        <Text fontSize="xs" as={Link} to={`/motorists/show/${freight?.motorist?.id}`}>
          <Flex direction="row">
            <Icon as={RiSteering2Line} fontSize="md" mr="1" />
            {freight?.motorist?.has_app && (
              <Tooltip hasArrow label="Motorista possui o app instalado">
                <Flex>
                  <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                    <Flex direction="row">
                      <Icon as={RiSmartphoneLine} fontSize="md" mr="1" />
                    </Flex>
                  </Text>
                </Flex>
              </Tooltip>
            )}
            <Tooltip hasArrow label={freight?.motorist?.name}>
              {String(freight?.motorist?.name).split(' ')[0]}
            </Tooltip>
          </Flex>
        </Text>

        <FreightIcons
          motoristPhone={freight?.motorist?.phone.toString()}
          hasMonitoringApp={freight.has_monitoring_app}
          hasMonitoringBuonny={freight.has_monitoring_buonny}
          hasMonitoringTrackerDevices={freight.has_monitoring_tracker_devices}
          hasMonitoringWhatsApp={freight.has_monitoring_whatsapp}
        />

        {freight.agreed_price ? (
          <>
            <Text fontSize="xs">
              <Flex direction="row">
                <Icon as={RiTruckLine} fontSize="md" mr="1" />
                {freight.agreed_price &&
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(freight.agreed_price)
                    .split(',')[0]}
              </Flex>
            </Text>
          </>
        ) : (
          <Text fontSize="xs">
            <Flex direction="row">
              <Icon as={RiFileSearchLine} fontSize="md" mr="1" />
              {Number(freight.suggested_price) > 0
                ? `${
                    freight.suggested_price &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(freight.suggested_price)
                      .split(',')[0]
                  }`
                : 'A combinar'}
            </Flex>
          </Text>
        )}
      </Flex>

      <Flex justifyContent="space-between" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiUser2Line} fontSize="sm" mr="1" /> {freight.creator?.name?.split(' ')[0]}
          </Flex>
        </Text>

        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiBriefcaseLine} fontSize="md" mr="1" />
            {freight.service_price && parseCurrency(freight.service_price)}
          </Flex>
        </Text>
      </Flex>
      {freight.type === 'aerial' && (
        <Flex fontSize="xs" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
          <Text mr={2} fontWeight="bold">
            Frete Aéreo
          </Text>
          <Flex mt="0.5">
            <GiCommercialAirplane />
          </Flex>
        </Flex>
      )}
      {(freight.transporter || freight.calculation_ref) && (
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          fontSize="xs"
          borderBottomColor={bg}
          borderBottomWidth={1}
          pb="0.5"
          mb="0.5"
        >
          {freight.transporter && <Text>{formattedTransporter(freight.transporter)}</Text>}
          {freight.calculation_ref && (
            <Tooltip label="Ir para a calculadora">
              <Text as={Link} to={freight.calculation_ref}>
                <Icon as={AiOutlineCalculator} color="orange" fontSize="md" mr="1" />
              </Text>
            </Tooltip>
          )}
        </Flex>
      )}

      <FreightStatus freight={freight} />

      <UnlinkMotoristModal
        isOpen={isOpenUnlinkMotorist}
        freight_id={freight.id}
        onClose={() => {
          onCloseUnlinkMotorist()
        }}
      />

      {freight && (
        <>
          <EditOrDuplicateFreightModal
            type={actionFreightType}
            freight_id={freight.id}
            isOpen={isFreightFormModalOpen}
            onClose={() => {
              onFreightFormModalClose()
            }}
          />

          <DisableFreightModal
            freight_ids={[String(freight?.id)]}
            isOpen={isDisableFreightModalOpen}
            onClose={() => {
              onDisableFreightModalClose()
              if (freight.motorist_id && !freight.rate) {
                onOpenEvaluateMotorist()
              }
            }}
          />

          <EvaluateMotoristModal isOpen={isOpenEvaluateMotorist} onClose={onCloseEvaluateMotorist} />
        </>
      )}
    </Container>
  )
}
