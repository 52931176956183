import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FiPaperclip } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { Input } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { useUploadLoadingRequestAttachments } from '../../../../../services/endpoints/freights/loading-request/UploadLoadingRequestAttachments'
import {
  LoadingRequestAttachmentTypeEnum,
  LoadingRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'

type UploadLoadingRequestCfModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

type UpdateLoadingRequestFormData = {
  cf_number: string
  attachment: File
}

const UpdateLoadingRequestFormSchema = yup.object().shape({
  cf_number: yup.string().required('Campo obrigatório'),
})

export function UploadLoadingRequestCfModal({
  isOpen,
  onClose,
}: UploadLoadingRequestCfModalProps): JSX.Element {
  const toast = useToast()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateLoadingRequestFormSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateLoadingRequestStatus, isLoading } = usePutLoadingRequest({
    onSuccess: () => {
      toast({
        title: 'Número CF anexado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadLoadingRequestAttachments,
    isLoading: isLoadingUploadLoadingRequestAttachments,
  } = useUploadLoadingRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateLoadingRequest: SubmitHandler<UpdateLoadingRequestFormData> = async data => {
    const uploadFormData = {
      cf_number: data.cf_number,
      status: LoadingRequestStatusEnum.AWAITING_PAYMENT,
    }

    await updateLoadingRequestStatus({
      id,
      data: uploadFormData,
    })

    const formData = new FormData()

    formData.append('loading_request_id', id)
    formData.append('type', LoadingRequestAttachmentTypeEnum.CF)
    formData.append('attachment', data.attachment)

    await uploadLoadingRequestAttachments({ data: formData })

    onClose()
  }
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateLoadingRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              Deseja realmente anexar o número da CF?
            </Text>

            <Text color="gray.400" mt={2} mb={2}>
              Ao anexar o número da CF a solicitação de carregamento será finalizada
            </Text>

            <Input
              label="Número CF"
              name="cf_number"
              error={errors.cf_number}
              setValue={setValue}
              isRequired
              type="number"
              mb={4}
            />

            <DropzoneImageUpload
              name="attachment"
              setValue={setValue}
              displayMode="list"
              label="Arquivo CF"
            />

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={isLoading || isLoadingUploadLoadingRequestAttachments}
              >
                <Icon fontSize={16} as={FiPaperclip} mr={1} /> Anexar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
