/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import reducers from './reducers'

const store = configureStore({
  reducer: reducers,
})

const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const useAppDispatch = () => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const { dispatch } = store

export { store, persistor, dispatch, useAppSelector, useAppDispatch }
