import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { handleCheckIfCpfIsBlackListed } from '../pages/black-list/check-document-exists/handleCpfIsBlackListed'
import { findMotoristByCpf } from '../services/endpoints/motorists/findMotoristByCpf'
import { MotoristType } from '../services/types'
import { CheckDocumentAndIsBlackListedExists } from '../services/types/BlackListType'
import getOnlyNumbers from '../services/utils/getOnlyNumbers'

export type useSearchMotoristResult = {
  motorist?: MotoristType
}

export type useBlackListReturn = CheckDocumentAndIsBlackListedExists['isCPFInBlacklist']

type useSearchMotoristProps = {
  onSuccess?: (data: useSearchMotoristResult) => void
  onBlackList?: (data: useBlackListReturn) => void
  onError?: (error: any) => void
}

const useSearchMotorist = ({
  onSuccess,
  onBlackList,
  onError,
}: useSearchMotoristProps): {
  loading: boolean
  search: (search: string) => void
} => {
  const [loading, setLoading] = useState(false)

  const handleSearch = useRef(
    debounce(
      async (search: string) => {
        const onlyNumbers = getOnlyNumbers(search)
        if (onlyNumbers.length === 11) {
          setLoading(true)
          try {
            const isBlackListed = await handleCheckIfCpfIsBlackListed(onlyNumbers)

            if (isBlackListed) {
              onBlackList?.(isBlackListed)
              return
            }
            const data = await findMotoristByCpf(onlyNumbers)

            if (data) {
              onSuccess?.({
                motorist: data as MotoristType,
              })
              return
            }
          } catch (error) {
            onError?.(error)
          } finally {
            setLoading(false)
          }
        }
      },
      500,
      {},
    ),
  ).current

  useEffect(() => {
    return () => {
      handleSearch.cancel()
    }
  }, [handleSearch])

  return { loading, search: handleSearch }
}

export default useSearchMotorist
