import { CteIssuanceRequestStatusEnum } from '../types/EnumTypes'

export function getNextStatusLabelCteIssuanceRequest(status: CteIssuanceRequestStatusEnum): string | null {
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ANALYSIS) return 'Aguardando emissão do CTe'
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ISSUANCE_CTE) return 'CTe emitido'
  if (status === CteIssuanceRequestStatusEnum.CTE_ISSUED) return 'CTe retornado ao motorista'
  if (status === CteIssuanceRequestStatusEnum.CTE_RETURN_TO_MOTORIST)
    return 'Aguardando solic. de adiantamento'
  if (status === CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_ONGOING) return 'Finalizado'
  return null
}
