import { useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { ChecklistRiskAnalysis } from '../../../components/checklist-risk-analysis'
import { CostRiskAnalysis } from '../../../components/CostRiskAnalysis'
import { MonitoringOccurrences } from '../../../components/MonitoringOccurrences'

import { Layout } from '../../../layout'
import { useGetOneFreight } from '../../../services/endpoints/freights'
import { useAppSelector } from '../../../store'
import { BuonnyHistoryFreight } from './cards/buonny-history'
import { RiskAnalysisInfos } from './cards/risk-analysis-infos/RiskAnalysisInfos'
import { ListRiskAnalysisHistory } from './cards/risk-analysis-status-history/ListRiskAnalysisHistory'

interface RouteParams {
  risk_analysis_id: string
}

export function ShowRiskAnalysis(): JSX.Element {
  const { riskAnalysis } = useAppSelector(s => s.riskAnalysisSlice)
  const { risk_analysis_id } = useParams<RouteParams>()
  const { data: freight } = useGetOneFreight({
    freight_id: String(riskAnalysis?.freight_id),
    relations: ['monitoring_occurrences', 'monitoring_occurrences.reason', 'monitoring_occurrences.user'],
  })
  const bg = useColorModeValue('white', 'gray.800')

  return (
    <Layout>
      <RiskAnalysisInfos bg={bg} risk_analysis_id={risk_analysis_id} />

      {freight && <MonitoringOccurrences bg={bg} freight={freight} />}

      {risk_analysis_id && (
        <CostRiskAnalysis
          bg={bg}
          risk_analysis_id={risk_analysis_id}
          freight_id={riskAnalysis?.freight_id}
          motorist_id={riskAnalysis?.motorist_id}
        />
      )}

      {risk_analysis_id && riskAnalysis && (
        <ChecklistRiskAnalysis
          bg={bg}
          risk_analysis_id={risk_analysis_id}
          motorist_id={riskAnalysis.motorist_id}
          vehicle_id={riskAnalysis.vehicle_id}
          trailer_id={riskAnalysis.trailer_id}
          user_id={riskAnalysis.user_id}
        />
      )}

      {riskAnalysis && <BuonnyHistoryFreight bg={bg} freight_id={riskAnalysis.freight_id} />}

      <ListRiskAnalysisHistory bg={bg} risk_analysis_id={risk_analysis_id} />
    </Layout>
  )
}
