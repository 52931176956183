import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { publicApiServer } from '../../publicApi'

export interface ISendRecoverLinkFormData {
  phone: string
}

async function sendRecoverLink(data: ISendRecoverLinkFormData): Promise<AxiosResponse> {
  return publicApiServer.post('/users/send-recover-link', data)
}

export function useSendRecoverLink(
  options?: UseMutationOptions<AxiosResponse, unknown, ISendRecoverLinkFormData, unknown>,
): UseMutationResult<AxiosResponse, unknown, ISendRecoverLinkFormData, unknown> {
  return useMutation<AxiosResponse, unknown, ISendRecoverLinkFormData, unknown>(
    async (data: ISendRecoverLinkFormData) => sendRecoverLink(data),
    options,
  )
}
