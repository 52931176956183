/* eslint-disable prettier/prettier */
import { Grid, GridItem } from '@chakra-ui/react';
import { useState } from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import { InputNumberMask } from '../form/InputNumberMask';
import { Select } from '../form/Select';

interface IFormProps {
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}

type riskManager = 'buonny';

export function Form({ setValue, errors }: IFormProps): JSX.Element {
  const [riskManagerValue, setRiskManagerValue] = useState<number>();

  function setValueByRiskManager(risk_manager: riskManager): void {
    const listRiskManager = {
      'buonny': 35,
    }

    if (risk_manager) {
      setValue('value', listRiskManager[risk_manager]);
      setRiskManagerValue(listRiskManager[risk_manager]);
    }
  }

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4">
      <GridItem colSpan={[12, 6]}>
        <Select
          isRequired
          options={[
            { label: '', value: '' },
            { label: 'Buonny', value: 'buonny' },
          ]}
          name="risk_manager"
          label="GR"
          error={errors.risk_manager}
          setValue={setValue}
          onSelectOption={option => {
            const risk_manager = option.value;

            setValueByRiskManager(risk_manager as riskManager);
          }}
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        {riskManagerValue && (
          <InputNumberMask
          isRequired
          name="value"
          label="Valor"
          error={errors.value}
          setValue={setValue}
          initialValue={riskManagerValue}
          isCashBRL
        />
        )}
      </GridItem>
    </Grid>
  );
}
