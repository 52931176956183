/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid, GridItem, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import * as yup from 'yup'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { Switch } from '../../components/form/Switch'
import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../components/form/types/SelectOption'
import { useApiCarsRequestContext } from '../../contexts/ApiCarsRequestContext'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { VehicleResponseFormData } from '../../services/endpoints/vehicles/getVehicle'
import { getVehicleBodies } from '../../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../../services/endpoints/vehicles/getVehicleCategories'
import { capitalizeWord } from '../../services/utils/capitalizeWord'
import { ufOptions } from '../../services/utils/ufOptions'
import { alterColorsGenderDatamex, vehicleColorsDatamex } from '../../services/utils/vehicleColorsDatamex'
import { TrailerForm } from './TrailerForm'

export interface VehicleCompleteFormData {
  type?: 'vehicle' | 'trailer'
  license_plate?: string
  license_uf?: string
  brand?: string
  model?: string
  release_year?: number
  model_year?: number
  renavam?: string
  chassi?: string
  antt?: string
  capacity_m3?: number
  capacity_tara?: number
  capacity_kg?: number
  has_tracker?: boolean
  has_insurance?: boolean
  vehicle_category: string
  vehicle_body: string
  city_id?: AutocompleteOption
  color?: string
  axes?: number

  trailer?: {
    license_plate?: string
    license_uf?: string
    brand?: string
    model?: string
    release_year?: number
    model_year?: number
    renavam?: string
    chassi?: string
    antt?: string
    capacity_m3?: number
    capacity_tara?: number
    capacity_kg?: number
    vehicle_body: string
    city_id?: AutocompleteOption
    color?: string
    axes?: number
  }
}

interface VehicleCompleteFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: VehicleResponseFormData
  licensePlate?: string
  handleCheckVehicleExists?: () => void
  licensePlateRef?: React.RefObject<HTMLInputElement>
  isEditVehicle?: boolean
}

export function VehicleCompleteForm({
  setValue,
  formState,
  initialData,
  handleCheckVehicleExists,
  licensePlate,
  licensePlateRef,
  isEditVehicle,
}: VehicleCompleteFormProps): JSX.Element {
  const { isLoading, setLicensePlateVehicle, carsApi } = useApiCarsRequestContext()
  const [typeInversion, settypeInversion] = useState('vehicle')
  const [license_plate_vehicle, setLicense_plate_vehicle] = useState('')
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()
  const [vehicleCategories, setVehicleCategories] = useState<AutocompleteOption[]>()
  useEffect(() => {
    getVehicleCategories().then(categories => setVehicleCategories(categories))
    getVehicleBodies().then(bodies => setVehicleBodies(bodies))
  }, [])

  useEffect(() => {
    if (licensePlate && handleCheckVehicleExists) {
      handleCheckVehicleExists()
    }
  }, [licensePlate, initialData?.id])

  const { errors } = formState
  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="4">
        {!isEditVehicle && (
          <GridItem colSpan={12}>
            <Heading size="lg" fontWeight="normal" mb="2">
              Dados da tração
            </Heading>
          </GridItem>
        )}
        <GridItem colSpan={[12, 3, 2]}>
          <InputMask
            isRequired
            ref={licensePlateRef}
            name="license_plate"
            mask="aaa9*99"
            maskPlaceholder=""
            label="Placa"
            onChange={e => {
              if (e.target.value.length === 7) {
                setLicense_plate_vehicle(e.target.value)
                if (handleCheckVehicleExists) handleCheckVehicleExists()
              }
            }}
            error={errors.license_plate}
            setValue={setValue}
            initialValue={initialData?.license_plate || licensePlate}
            uppercaseAll
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3, 2, 2]}>
          <Button
            mt={9}
            colorScheme="green"
            isLoading={isLoading}
            onClick={() => {
              setLicensePlateVehicle(initialData?.license_plate || String(license_plate_vehicle))
            }}
          >
            Buscar Dados
          </Button>
        </GridItem>

        {vehicleCategories && vehicleBodies && (
          <>
            <GridItem colSpan={[12, 6, 3]}>
              <Select
                isRequired
                name="vehicle_category"
                label="Categoria"
                error={errors.vehicle_category}
                setValue={setValue}
                initialValue={initialData?.vehicle_category?.id}
                onSelectOption={option => {
                  if (option.has_trailer) {
                    settypeInversion('trailer')
                  } else {
                    settypeInversion('vehicle')
                  }
                }}
                options={[{ label: '', value: '' }, ...(vehicleCategories as SelectOption[])]}
                isDisabled={!!initialData}
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 3]}>
              <Select
                isRequired
                name="vehicle_body"
                label={typeInversion === 'trailer' ? 'Reboque' : 'Carroceria'}
                error={errors.vehicle_body}
                setValue={setValue}
                initialValue={initialData?.vehicle_body?.id}
                options={[{ label: '', value: '' }, ...(vehicleBodies as SelectOption[])]}
              />
            </GridItem>
          </>
        )}
        <GridItem colSpan={[12, 3, 2]}>
          <InputMask
            isRequired
            mask="9999"
            name="release_year"
            label="Ano fábrica"
            maskPlaceholder=""
            error={errors.release_year}
            setValue={setValue}
            initialValue={carsApi?.release_year || String(initialData?.release_year)}
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 2]}>
          <InputMask
            isRequired
            mask="9999"
            name="model_year"
            label="Ano modelo"
            maskPlaceholder=""
            error={errors.model_year}
            setValue={setValue}
            initialValue={carsApi?.model_year || String(initialData?.model_year)}
          />
        </GridItem>

        <GridItem colSpan={[12, 3, 1]}>
          <Input
            name="axes"
            label="Eixos"
            error={errors.axes}
            setValue={setValue}
            initialValue={Number(carsApi?.axes) || Number(initialData?.axes)}
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 2]}>
          <Select
            setValue={setValue}
            options={ufOptions}
            name="license_uf"
            label="UF"
            error={errors.license_uf}
            initialValue={carsApi?.license_uf || initialData?.license_uf}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Input
            name="brand"
            label="Marca"
            error={errors.brand}
            setValue={setValue}
            initialValue={carsApi?.brand || initialData?.brand}
            uppercaseFirst
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Input
            name="model"
            label="Modelo"
            error={errors.model}
            setValue={setValue}
            initialValue={carsApi?.model || initialData?.model}
            uppercaseFirst
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]}>
          <Input
            type="number"
            name="capacity_m3"
            label="Capacidade (m3)"
            error={errors.capacity_m3}
            setValue={setValue}
            initialValue={initialData?.capacity_m3}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Input
            name="renavam"
            label="Renavam"
            error={errors.renavam}
            setValue={setValue}
            initialValue={carsApi?.renavam || initialData?.renavam}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Input
            name="chassi"
            label="Chassi"
            error={errors.chassi}
            setValue={setValue}
            initialValue={initialData?.chassi || carsApi?.chassi}
            uppercaseAll
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]}>
          <Input
            type="number"
            name="capacity_tara"
            label="Capacidade (TARA)"
            error={errors.capacity_tara}
            setValue={setValue}
            initialValue={initialData?.capacity_tara}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Input
            type="number"
            name="capacity_kg"
            label="Capacidade (kg)"
            error={errors.capacity_kg}
            setValue={setValue}
            initialValue={initialData?.capacity_kg}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 2]}>
          <InputMask
            name="antt"
            mask="aaa9*99"
            maskPlaceholder=""
            label="Placa ANTT"
            error={errors.antt}
            setValue={setValue}
            initialValue={carsApi?.license_plate || initialData?.antt}
            uppercaseAll
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          {carsApi?.cityComplete ? (
            <AutocompleteAsync
              name="city_id"
              label="Municipio"
              error={errors.city_id}
              setValue={setValue}
              initialValue={carsApi?.cityComplete}
              loadOptions={searchCitiesByName}
            />
          ) : (
            <AutocompleteAsync
              name="city_id"
              label="Municipio"
              error={errors.city_id}
              setValue={setValue}
              initialValue={
                initialData?.city && {
                  label: String(initialData?.city?.name),
                  value: String(initialData?.city?.ibge_id),
                }
              }
              loadOptions={searchCitiesByName}
            />
          )}
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          {carsApi?.color ? (
            <Input
              name="color"
              label="Cor"
              error={errors.color}
              setValue={setValue}
              initialValue={capitalizeWord(alterColorsGenderDatamex(carsApi?.color))}
            />
          ) : (
            <Select
              name="color"
              label="Cor"
              options={vehicleColorsDatamex}
              error={errors.color}
              setValue={setValue}
              initialValue={capitalizeWord(alterColorsGenderDatamex(initialData?.color))}
            />
          )}
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <Switch
            name="has_insurance"
            label="Tem seguro?"
            error={errors.has_insurance}
            setValue={setValue}
            initialValue={initialData?.has_insurance}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <Switch
            name="has_tracker"
            label="Tem rastreador?"
            error={errors.has_tracker}
            setValue={setValue}
            initialValue={initialData?.has_tracker}
          />
        </GridItem>
      </Grid>
      {typeInversion === 'trailer' && !isEditVehicle && (
        <>
          <Divider my={8} />
          <GridItem colSpan={12}>
            <Heading size="lg" fontWeight="normal" mb="4">
              Dados do reboque
            </Heading>
          </GridItem>
          <TrailerForm setValue={setValue} formState={formState} initialData={initialData} />
        </>
      )}
    </>
  )
}

const currentYear = new Date().getFullYear()

export const vehicleCompleteFormSchema = yup.object().shape({
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  license_uf: yup.string(),
  brand: yup.string(),
  model: yup.string(),
  release_year: yup
    .number()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  model_year: yup
    .number()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  renavam: yup.string(),
  chassi: yup.string(),
  antt: yup.string(),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero'),
  has_tracker: yup.boolean(),
  has_insurance: yup.boolean(),
  vehicle_category: yup.string().required('Campo obrigatório'),
  vehicle_body: yup.string().required('Campo obrigatório'),
  axes: yup.number(),
  city_id: yup.object().shape({}),

  trailer: yup.object().shape({
    vehicle_body: yup.string(),
    license_plate: yup.string().min(7, 'Placa incompleta'),
    license_uf: yup.string(),
    brand: yup.string(),
    model: yup.string(),
    release_year: yup.number().max(currentYear, 'Não pode ser maior que o ano atual'),
    renavam: yup.string(),
    chassi: yup.string(),
    antt: yup.string(),
    capacity_m3: yup
      .number()
      .max(2139999999, 'O valor excede limite de 2.139.999.999')
      .min(1, 'O valor não pode ser zero'),
    capacity_tara: yup
      .number()
      .max(2139999999, 'O valor excede limite de 2.139.999.999')
      .min(1, 'O valor não pode ser zero'),
    capacity_kg: yup
      .number()
      .max(2139999999, 'O valor excede limite de 2.139.999.999')
      .min(1, 'O valor não pode ser zero'),
    axes: yup.number(),
    city_id: yup.object().shape({}),
  }),
})
