import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { RiWhatsappLine } from 'react-icons/ri'

interface IconHasMonitoringWhatsAppProps {
  has_monitoring_whatsapp?: boolean
  fontSize?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'large'
    | 'medium'
    | 'small'
    | 'x-large'
    | 'x-small'
    | 'xx-large'
    | 'xx-small'
    | 'xxx-large'
}

export function IconHasMonitoringWhatsApp({
  has_monitoring_whatsapp,
  fontSize = 'md',
}: IconHasMonitoringWhatsAppProps): JSX.Element {
  return (
    <>
      {has_monitoring_whatsapp && (
        <Tooltip hasArrow label="Frete monitorado pelo WhatsApp">
          <Flex>
            <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
              <Flex direction="row">
                <Icon as={RiWhatsappLine} fontSize={fontSize} mr="1" />
              </Flex>
            </Text>
          </Flex>
        </Tooltip>
      )}
    </>
  )
}
