import { useQuery, UseQueryResult } from 'react-query'
import { localStorageKeys } from '../../../contexts/AuthContext'
import { apiServer } from '../../api'
import { Authorization, ResponseApiType } from '../../types'
import { UserRolesOptions } from '../../types/AuthorizationModulesTypes'

type Input = { roles: UserRolesOptions[] }
type Output = Authorization

export async function updatePermissionsOnLocalStorage(params: Input): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output[]>>('/authorization/permissions', { params })
  if (data.statusCode === 200 && data.data[0].role) {
    localStorage.setItem(localStorageKeys.permissions, JSON.stringify(data.data[0]))
  }
  return data.data[0]
}

export function useUpdatePermissionsOnLocalStorage(params: Input): UseQueryResult<Output, unknown> {
  return useQuery(
    ['updatePermissionsOnLocalStorage', params],
    () => updatePermissionsOnLocalStorage(params),
    {
      enabled: !!params.roles[0],
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60 * 2, // 2 hours
    },
  )
}
