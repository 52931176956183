import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEdit2Fill } from 'react-icons/ri'
import * as yup from 'yup'
import { Switch } from '../../../../components/form'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { FormattedAllFreights } from '../../../../services/endpoints/freights/getFreights'
import { usePutFreight } from '../../../../services/endpoints/freights/putFreight'

interface ITollStatus {
  id: string
  motorist_id?: string
  has_toll: boolean
  is_paid_toll: boolean
  last_observation?: string
  freight_number?: string | number
}
interface AlterTollStatusModalProps {
  freight: FormattedAllFreights
}
export function AlterTollStatusModal({ freight }: AlterTollStatusModalProps): JSX.Element {
  const filterFormSchema = yup.object().shape({
    has_toll: yup.string().required('Campo obrigatório'),
    is_paid_toll: yup.string().required('Campo obrigatório'),
  })
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors } = formState
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: putFreight, isLoading: isLoadingFreight } = usePutFreight({
    onSuccess: () => {
      queryClient.invalidateQueries('freights')
      queryClient.invalidateQueries('freight')
      toast({
        title: 'Atualização salva com sucesso!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      })
      onClose()
    },
  })

  const handleCreateNewObservation: SubmitHandler<ITollStatus> = async (data: ITollStatus) => {
    try {
      await putFreight({
        freight_id: freight.id,
        has_toll: data.has_toll,
        is_paid_toll: data.is_paid_toll,
        disable_entities_block: true,
      })
    } catch (error) {
      toastError({ error, toast })
    }
  }

  return (
    <>
      <Button colorScheme="orange" size="xs" leftIcon={<Icon as={RiEdit2Fill} />} onClick={onOpen}>
        Editar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg" scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(handleCreateNewObservation)} noValidate>
              <Heading size="md" my="4">
                Alterar informações de pedágio do frete #{freight.freight_number}
              </Heading>
              <Stack direction={['column', 'row']} my="8">
                <Switch
                  label="Exige pedágio?"
                  name="has_toll"
                  error={errors.has_toll}
                  initialValue={freight.has_toll}
                  setValue={setValue}
                />
                <Switch
                  label="Pedágio pago?"
                  name="is_paid_toll"
                  error={errors.is_paid_toll}
                  initialValue={freight.is_paid_toll}
                  setValue={setValue}
                />
              </Stack>

              <Flex justifyContent="flex-end" gridGap="2" direction={['column', 'row']}>
                <Button colorScheme="orange" type="submit" isLoading={isLoadingFreight}>
                  Enviar
                </Button>
                <Button colorScheme="red" onClick={onClose}>
                  Fechar
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
