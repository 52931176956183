import pt from './pt'

// Function to capitalize the first letter of a string
function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const FormattedMessage = ({ id }: { id: string }): JSX.Element => {
  if (pt[id]) {
    return <>{capitalize(pt[id])}</>
  }

  return <>{id}</>
}

export { FormattedMessage }
