import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Heading,
  Spinner,
  Tooltip,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Pagination } from '../../../../../layout/Pagination'
import { useGetCheckBuonnyHistory } from '../../../../../services/endpoints/risk-management/list-buonny-motorist-check'
import { boxWidth, displayAdaptable } from '../../../../../services/utils/tableAdaptable'

interface BuonnyHistoryProps {
  freight_id: string
  bg: string
}

export function BuonnyHistoryFreight({ freight_id, bg }: BuonnyHistoryProps): JSX.Element {
  const [page, setPage] = useState(1)
  const {
    data: buonnyHistoryResponse,
    isLoading,
    isError,
  } = useGetCheckBuonnyHistory({
    freight_id,
    page,
    per_page: 10,
  })

  return (
    <Box bg={bg} p="6" w={boxWidth} borderRadius="8" shadow="md" my="8">
      <Flex mb={['6', '8']} justify="space-between" align="center" direction={['column', 'row']} gridGap={2}>
        <Heading size="lg" fontWeight="normal">
          Consultas do frete na Buonny
        </Heading>
      </Flex>

      {buonnyHistoryResponse && buonnyHistoryResponse.data.length > 0 ? (
        <Table d={displayAdaptable} overflowX="auto">
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Consulta</Th>
              <Th>Motorista</Th>
              <Th>CPF</Th>
              <Th>Placa (Cavalo)</Th>
              <Th>Placa (Carreta)</Th>
              <Th>Data da consulta</Th>
              <Th>Mensagem</Th>
              <Th>Origem</Th>
              <Th>Destino</Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Flex>
              <Spinner size="lg" />
            </Flex>
          ) : buonnyHistoryResponse ? (
            <Tbody>
              {buonnyHistoryResponse &&
                buonnyHistoryResponse.data.length > 0 &&
                buonnyHistoryResponse.data.map(history => (
                  <Tr fontSize="xs" key={history.id}>
                    <Td>{history.status}</Td>
                    <Td>{history.buonny_consult_number}</Td>
                    <Td>{history.motorist?.name}</Td>
                    <Td>{history.document}</Td>
                    <Td>{history.vehicle_license_plate}</Td>
                    <Td>{history.trailer_license_plate}</Td>
                    <Td>{history.formatted_created_at}</Td>
                    <Td>
                      <Tooltip label={history.message} hasArrow placement="auto">
                        <Box cursor="pointer">{history.message.substring(0, 8)}...</Box>
                      </Tooltip>
                    </Td>
                    <Td>{history.origin?.name}</Td>
                    <Td>{history.destination?.name}</Td>
                  </Tr>
                ))}
            </Tbody>
          ) : (
            isError && (
              <Alert variant="solid" status="error" borderRadius="md">
                <AlertIcon />
                <AlertTitle mr={2} fontSize="lg">
                  Erro ao carregar dados!
                </AlertTitle>
              </Alert>
            )
          )}
        </Table>
      ) : (
        <Flex justifyContent="center">
          <Text>Nenhuma consulta realizada</Text>
        </Flex>
      )}

      {buonnyHistoryResponse && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={buonnyHistoryResponse.total}
          registersPerPage={10}
          onPageChange={setPage}
          p="6"
        />
      )}
    </Box>
  )
}
