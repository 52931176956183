import { Center, Spinner, Text } from '@chakra-ui/react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SelectOption } from '../components/form'
import { AutocompleteOption } from '../components/form/types/AutocompleteOption'
import { api, apiServer } from '../services/api'
import { useUpdatePermissionsOnLocalStorage } from '../services/endpoints/authorization/updatePermissionsOnLocalStorage'
import { UserType } from '../services/types'
import { UserRolesOptions } from '../services/types/AuthorizationModulesTypes'

interface SignInRequest {
  email: string
  password: string
}

interface SignInResponse {
  user: UserType
  token: string
}

interface AuthContextData {
  user: UserType
  signIn: (data: SignInRequest) => Promise<void>
  signOut: () => void
  getUserIdToListFreights: () => void
  getSellerIdToListFreights: () => void
  setStorageUser?: React.Dispatch<React.SetStateAction<SelectOption | undefined>>
  updateUser: (user: UserType) => void
  setUserIdToListFreights: (userSelected?: SelectOption | AutocompleteOption) => void
  setSellerIdToListFreights: (userSelected?: SelectOption | AutocompleteOption) => void
  onFilterByCreatorLogged: () => string | undefined
  onFilterBySellerLogged: () => SelectOption | undefined
  storageUser?: SelectOption
  storageSeller?: SelectOption
}

export const localStorageKeys = {
  token: '@spotx:token',
  user: '@spotx:user',
  userSelected: '@spotx:user_id',
  sellerSelected: '@spotx:seller_id',
  permissions: '@spotx:permissions',
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [storageUser, setStorageUser] = useState<SelectOption>()
  const [storageSeller, setStorageSeller] = useState<SelectOption>()
  const [data, setData] = useState<SignInResponse>(() => {
    const token = localStorage.getItem(localStorageKeys.token)
    const user = localStorage.getItem(localStorageKeys.user)

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`
      apiServer.defaults.headers.authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) }
    }

    return {} as SignInResponse
  })

  async function signIn({ email, password }: SignInRequest) {
    const response = await apiServer.post('login', { email, password })

    const { token, user } = response.data.data

    localStorage.setItem(localStorageKeys.token, token)
    localStorage.setItem(localStorageKeys.user, JSON.stringify(user))

    apiServer.defaults.headers.authorization = `Bearer ${token}`
    apiServer.defaults.headers.authorization = `Bearer ${token}`

    setData({ token, user })
    const queryParams = new URLSearchParams(window.location.search)
    const e2e = queryParams.get('e2e')
    if (!e2e) {
      history.goBack()
    }
  }

  const onFilterByCreatorLogged = (): string | undefined => {
    if (data.user.role === 'logistics_analyst') return data.user.id
    if (storageUser) return String(storageUser.value)
    return undefined
  }
  const onFilterBySellerLogged = (): SelectOption | undefined => {
    if (data.user.role === 'seller' && !'commercial_analyst')
      return { label: data.user.name, value: data.user.id }
    if (storageSeller) return storageSeller
    return undefined
  }

  function signOut() {
    localStorage.removeItem(localStorageKeys.token)
    localStorage.removeItem(localStorageKeys.user)
    localStorage.removeItem(localStorageKeys.userSelected)
    localStorage.removeItem(localStorageKeys.sellerSelected)
    localStorage.removeItem(localStorageKeys.permissions)

    setData({} as SignInResponse)
    history.push('/')
  }

  function updateUser(user: UserType) {
    localStorage.setItem(localStorageKeys.user, JSON.stringify(user))

    setData({ user, token: data.token })
  }

  function getUserIdToListFreights() {
    const userSelected = localStorage.getItem(localStorageKeys.userSelected)
    if (userSelected) {
      const userFormatted = JSON.parse(userSelected)
      setStorageUser(userFormatted)
    } else {
      setStorageUser(undefined)
    }
  }

  function getSellerIdToListFreights() {
    const userSelected = localStorage.getItem(localStorageKeys.sellerSelected)
    if (userSelected) {
      const userFormatted = JSON.parse(userSelected)
      setStorageSeller(userFormatted)
    } else {
      setStorageSeller(undefined)
    }
  }

  function setUserIdToListFreights(userSelected?: SelectOption | AutocompleteOption) {
    getUserIdToListFreights()
    if (userSelected) {
      localStorage.setItem(localStorageKeys.userSelected, JSON.stringify(userSelected))
    } else if (!userSelected) localStorage.removeItem(localStorageKeys.userSelected)
  }

  function setSellerIdToListFreights(userSelected?: SelectOption | AutocompleteOption) {
    getSellerIdToListFreights()
    if (userSelected) {
      localStorage.setItem(localStorageKeys.sellerSelected, JSON.stringify(userSelected))
    } else if (!userSelected) localStorage.removeItem(localStorageKeys.sellerSelected)
  }

  const { isLoading, isFetching } = useUpdatePermissionsOnLocalStorage({
    roles: [data?.user?.role] as UserRolesOptions[],
  })

  useEffect(() => {
    getUserIdToListFreights()
  }, [])

  if (isLoading || isFetching)
    return (
      <Center justifyContent="center" height="100vh" gridGap="4">
        <Spinner size="lg" />
        <Text>Aplicando permissões...</Text>
      </Center>
    )

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        onFilterByCreatorLogged,
        onFilterBySellerLogged,
        updateUser,
        setUserIdToListFreights,
        setSellerIdToListFreights,
        getUserIdToListFreights,
        getSellerIdToListFreights,
        storageUser,
        storageSeller,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) throw new Error('useAuth() must be used within AuthProvider')
  return context
}
