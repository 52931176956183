import React from 'react'
import { RiSearchLine } from 'react-icons/ri'
import { PageHeader } from '../../../components/UI/PageHeader'
import { Layout } from '../../../layout'
import RntrcForm from './Forms/RntrcForm'

export default function RntrcConsultation(): JSX.Element {
  return (
    <Layout>
      <PageHeader title="Consulta RNTRC" icon={RiSearchLine} />
      <RntrcForm />
    </Layout>
  )
}
