/* eslint-disable no-param-reassign */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AiOutlineStop } from 'react-icons/ai'
import {
  RiAddLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiSearchLine,
  RiSmartphoneLine,
  RiStarLine,
  RiWhatsappLine,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteAsync } from '../../../../../components/form/AutocompleteAsync'
import { AutocompleteMulti } from '../../../../../components/form/AutocompleteMulti'
import { Input } from '../../../../../components/form/Input'
import { InputMask } from '../../../../../components/form/InputMask'
import { Select as SelectComponent } from '../../../../../components/form/Select'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../../config/error/toastError'
import { Pagination } from '../../../../../layout/Pagination'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import {
  FetchCompatibleMotoristsParams,
  useCompatibleMotorists,
} from '../../../../../services/endpoints/freights/getCompatibleMotorists'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { FetchMotoristsParams } from '../../../../../services/endpoints/motorists/getMotorists'
import { useGetAllMotoristInAnalysis } from '../../../../../services/endpoints/risk-analysis/getAllMotoristInAnalysis'
import { getAllDDDs } from '../../../../../services/utils/getAllDDDs'
import ButtonShowInfosBlackList from '../../../../black-list/modals/buttons/ButtonShowInfosBlackList'
import { SendWhatsAppMessageModal } from '../../../../motorists/modals/SendWhatsAppMessageModal'
import { ListMotoristsInAnalysis } from './list-motorists-in-analysis/ListMotoristsInAnalysis'
import { CheckRiskMotoristModal } from './modals/check-risk-motorist-modal'
import { ConfirmFreightVpoModal } from './modals/ConfirmFreightVpoModal'

interface CompatibleMotoristsProps {
  freight: FormattedOneFreight
  bg: string
}

interface FilterMotoristsFormData {
  orderByName?: 'asc' | 'desc'
  orderByCheckIn?: 'asc' | 'desc'
  orderByRateScore?: 'asc' | 'desc'
  page?: number
  per_page?: number
  freight_id?: string
  phone?: string
  license_plate?: string
  type?: number
  ddds?: AutocompleteOption[]
  name?: string
  cpf?: string
  origins?: number[]
  destinations?: number[]
  address_city_id?: AutocompleteOption
}

const filterFormSchema = yup.object().shape({
  phone: yup.string(),
  type: yup.string(),
  license_plate: yup.string(),
  ddds: yup.array().of(yup.object()),
})

export function CompatibleMotorists({ freight, bg }: CompatibleMotoristsProps): JSX.Element | null {
  const toast = useToast()
  const {
    isOpen: isSendWhatsAppModalOpen,
    onOpen: onSendWhatsAppModalOpen,
    onClose: onSendWhatsAppModalClose,
  } = useDisclosure()
  const [allChecked, setAllChecked] = useState(false)
  const [filters, setFilters] = useState<FetchMotoristsParams>()
  const [selectedMotorists, setSelectedMotorists] = useState<string[]>([])
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors, isSubmitting } = formState

  const { data: allRiskAnalysis, isFetching: isFetchingRiskAnalysis } = useGetAllMotoristInAnalysis(
    {
      freight_id: freight.id,
      per_page: 30,
    },
    !!freight?.motorist_id,
  )

  const [riskAnalysis, setRiskAnalysis] = useState(allRiskAnalysis)

  useEffect(() => {
    setRiskAnalysis(allRiskAnalysis)
  }, [allRiskAnalysis])

  const {
    data: compatibleMotorists,
    isLoading,
    error: errorListCompatibleMotorists,
    isError: isErrorListCompatibleMotorists,
  } = useCompatibleMotorists({
    ...filters,
    freight_id: freight.id,
    page: filters?.page || 1,
    per_page: 8,
    has_motorist: !!freight?.motorist_id,
  })

  useEffect(() => {
    if (isErrorListCompatibleMotorists) {
      toastError({ toast, error: errorListCompatibleMotorists })
    }
  }, [isErrorListCompatibleMotorists, errorListCompatibleMotorists, toast])

  const handleFilterMotorists: SubmitHandler<FilterMotoristsFormData> = async dataSubmit => {
    const dataSubmitFilter = {
      phone: dataSubmit.phone,
      type: dataSubmit.type,
      license_plate: dataSubmit.license_plate,
      ddds: dataSubmit.ddds?.map(i => String(i.value)),
      name: dataSubmit.name,
      address_city_id: dataSubmit?.address_city_id?.value,
    } as FetchCompatibleMotoristsParams
    const { phone, type, ddds, name, license_plate, address_city_id } = dataSubmitFilter
    setFilters({
      phone,
      type,
      ddds,
      name,
      license_plate,
      address_city_id,
    })
  }

  return freight && !freight.motorist_id ? (
    <Box noValidate bg={bg} p="4" borderRadius="8">
      <Flex
        mb={['6', '8']}
        justifyContent="space-between"
        align="center"
        direction={['column', 'column', 'row']}
        gridGap={4}
      >
        <Heading fontSize="md" fontWeight="medium">
          Motoristas compatíveis {isLoading && <Spinner size="sm" ml="4" />}
        </Heading>

        <Stack direction={['column', 'row']} align="center" spacing={2}>
          <CheckRiskMotoristModal freight={freight} />

          <ConfirmFreightVpoModal
            titleButton="Cadastro do zero"
            leftIcon={<Icon as={RiAddLine} />}
            size="sm"
            variant="outline"
            colorScheme="orange"
            onConfirm={() =>
              window.location.replace(`/freights/show/${freight.id}/pendencies?integration=buonny`)
            }
          />
        </Stack>
      </Flex>

      <Box>
        <form onSubmit={handleSubmit(handleFilterMotorists)}>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 3, 3]}>
              <Input height="44px" name="name" error={errors.name} setValue={setValue} placeholder="Nome" />
            </GridItem>
            <GridItem colSpan={[6, 3]}>
              <AutocompleteAsync
                name="address_city_id"
                error={errors.address_city_id}
                setValue={setValue}
                loadOptions={searchCitiesByName}
                placeholder="Cidade"
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 3]}>
              <InputMask
                height="44px"
                mask="(99) 9 9999-9999"
                registerOnlyNumbers
                maskPlaceholder=""
                name="phone"
                error={errors.phone}
                setValue={setValue}
                placeholder="Telefone"
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 3]}>
              <SelectComponent
                name="type"
                height="44px"
                error={errors.type}
                setValue={setValue}
                options={[
                  { label: 'Todos', value: '' },
                  { label: 'Pessoa física', value: '1' },
                  { label: 'Pessoa jurídica', value: '2' },
                ]}
              />
            </GridItem>
            <GridItem colSpan={[12, 6, 4]}>
              <InputMask
                name="license_plate"
                mask="aaa9*99"
                placeholder="Placa"
                error={errors.license_plate}
                setValue={setValue}
                uppercaseAll
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 5]}>
              <AutocompleteMulti
                name="ddds"
                height="26px"
                error={errors.ddds}
                setValue={setValue}
                placeholder="DDDs"
                options={getAllDDDs}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Button
                mt={1}
                type="submit"
                colorScheme="orange"
                leftIcon={<Icon as={RiSearchLine} />}
                isLoading={isSubmitting}
                width="full"
              >
                Filtrar
              </Button>
            </GridItem>
          </Grid>
          <Flex align="center" justifyContent="space-between">
            <Stack spacing="2" direction={['column', 'row']}>
              <Box>
                {(allChecked || selectedMotorists.length > 0) && (
                  <Button
                    my={['1', '4']}
                    size="sm"
                    colorScheme="whatsapp"
                    leftIcon={<Icon as={RiWhatsappLine} fontSize={18} />}
                    onClick={onSendWhatsAppModalOpen}
                  >
                    WhatsApp
                  </Button>
                )}
              </Box>
            </Stack>
          </Flex>

          <ListMotoristsInAnalysis
            isFetchingRiskAnalysis={isFetchingRiskAnalysis}
            riskAnalysis={riskAnalysis}
          />
        </form>

        <Flex alignItems="center" justifyContent="space-between" flex={1} my={6}>
          <Heading fontSize="md" fontWeight="medium">
            Motoristas para seleção
            {isLoading && <Spinner size="sm" ml="4" />}
          </Heading>
        </Flex>
        {compatibleMotorists && !isLoading ? (
          <>
            <Table size="sm" overflowX="auto" d={['block', 'block', 'table']}>
              <Thead>
                <Tr>
                  <Th px={0} textAlign="center" pl="2">
                    <Checkbox
                      isChecked={allChecked}
                      colorScheme="orange"
                      isIndeterminate={selectedMotorists.length > 0}
                      onChange={e => {
                        const { checked: clickedCheckbox } = e.target
                        setAllChecked(clickedCheckbox)
                        if (!clickedCheckbox) setSelectedMotorists([])
                      }}
                    />
                  </Th>
                  <Th>
                    <Flex gridGap={2} alignItems="center" color="blue.400">
                      <Box
                        onClick={() => {
                          setFilters({
                            ...filters,
                            orderByRateScore: 'asc',
                            orderByName: undefined,
                            orderByCheckIn: undefined,
                          })

                          if (filters?.orderByRateScore && filters?.orderByRateScore.includes('asc')) {
                            setFilters({
                              ...filters,
                              orderByRateScore: 'desc',
                              orderByName: undefined,
                              orderByCheckIn: undefined,
                            })
                          }
                        }}
                        cursor="pointer"
                      >
                        Nota
                      </Box>
                      {filters?.orderByRateScore && (
                        <>
                          {filters?.orderByRateScore.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                          {filters?.orderByRateScore.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                        </>
                      )}
                    </Flex>
                  </Th>
                  <Th>Fretes</Th>
                  <Th>
                    <Flex gridGap={2} alignItems="center" color="blue.400">
                      <Box
                        onClick={() => {
                          setFilters({
                            ...filters,
                            orderByName: 'asc',
                            orderByRateScore: undefined,
                            orderByCheckIn: undefined,
                          })
                          if (filters?.orderByName && filters?.orderByName.includes('asc')) {
                            setFilters({
                              ...filters,
                              orderByName: 'desc',
                              orderByRateScore: undefined,
                              orderByCheckIn: undefined,
                            })
                          }
                        }}
                        cursor="pointer"
                      >
                        Motorista
                      </Box>
                      {filters?.orderByName && (
                        <>
                          {filters?.orderByName.includes('asc') && <RiArrowUpSLine fontSize={20} />}
                          {filters?.orderByName.includes('desc') && <RiArrowDownSLine fontSize={20} />}
                        </>
                      )}
                    </Flex>
                  </Th>
                  <Th>Veículo</Th>
                  <Th>Placa</Th>
                  <Th>Ação</Th>
                </Tr>
              </Thead>
              <Tbody>
                {compatibleMotorists &&
                  compatibleMotorists.data?.map(motorist => {
                    const motoristIsSelected = selectedMotorists.includes(motorist.id)
                    let shouldChecked = false
                    let disabledCheckBox = false

                    if (allChecked && !motoristIsSelected) shouldChecked = true
                    if (allChecked && motoristIsSelected) shouldChecked = false
                    if (!allChecked && motoristIsSelected && Number(motorist.rate_score) >= 3)
                      shouldChecked = true

                    if (!allChecked && motoristIsSelected && !motorist.rate_score) shouldChecked = true

                    if (allChecked && Number(motorist.rate_score) < 3 && Number(motorist.rate_score) > 0)
                      shouldChecked = false
                    if (Number(motorist.rate_score) < 3 && Number(motorist.rate_score) > 0)
                      disabledCheckBox = true

                    return motorist.vehicles.map(motoristVehicle => (
                      <Tr key={motoristVehicle.id}>
                        <Td px={0} textAlign="center" pl="2">
                          <Checkbox
                            type="submit"
                            isDisabled={disabledCheckBox}
                            isChecked={shouldChecked}
                            colorScheme="orange"
                            onChange={() => {
                              if (motoristIsSelected) {
                                setSelectedMotorists(state => state.filter(i => i !== motorist.id))
                              } else {
                                setSelectedMotorists(state => [...state, motorist.id])
                              }
                            }}
                          />
                        </Td>

                        <Td textAlign="center">
                          <Flex alignItems="center" gridGap="2">
                            {motorist.rate_score && (
                              <>
                                <Icon as={RiStarLine} color="yellow.500" fontSize={20} />
                                <Text fontWeight="bold" color="yellow.600">
                                  {motorist.rate_score}
                                </Text>
                              </>
                            )}
                            {motorist?.has_app && (
                              <Tooltip hasArrow label="Este motorista possui o app instalado">
                                <Flex>
                                  <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                                    <Flex direction="row">
                                      <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                                    </Flex>
                                  </Text>
                                </Flex>
                              </Tooltip>
                            )}
                          </Flex>
                        </Td>
                        <Td whiteSpace="nowrap">{motorist.total_freights}</Td>
                        <Td whiteSpace="nowrap">
                          <Box>
                            <Tooltip hasArrow label={`Ver mais informações do motorista ${motorist.name}`}>
                              <Text as={Link} to={`/motorists/show/${motorist.id}`}>
                                {motorist.name.split(' ')[0]} {motorist.name.split(' ')[1]}{' '}
                                {motorist.name.split(' ')[2]} {motorist.name.split(' ')[3]}
                              </Text>
                            </Tooltip>
                            <Text mt="1" color="gray.500">
                              {motorist.phone}
                            </Text>
                          </Box>
                        </Td>

                        <Td whiteSpace="nowrap">
                          <Box>
                            <Text>{motoristVehicle.vehicle_category?.name}</Text>
                            <Text fontSize="sm" color="gray.400">
                              {motoristVehicle.vehicle_body?.name}
                            </Text>
                          </Box>
                        </Td>
                        <Td whiteSpace="nowrap">{motoristVehicle.license_plate}</Td>

                        <Td>
                          <HStack spacing="2" justify="center">
                            {motorist &&
                              motorist.total_freights >= 0 &&
                              (!motorist.rate_score || motorist.rate_score >= 4) && (
                                <CheckRiskMotoristModal
                                  freight={freight}
                                  motorist={motorist}
                                  vehicle={motoristVehicle}
                                />
                              )}
                            {motorist.rate_score && motorist.rate_score <= 3 ? (
                              <Tooltip hasArrow label="Inapto para o frete devido a baixa nota">
                                <Box>
                                  <Icon as={AiOutlineStop} color="red" fontSize={20} />
                                </Box>
                              </Tooltip>
                            ) : motorist.blacK_list ? (
                              <ButtonShowInfosBlackList
                                black_list={{
                                  ...motorist.blacK_list,
                                  motorist,
                                  created_at: format(new Date(motorist.blacK_list.created_at), 'dd/MM/yyyy'),
                                }}
                              />
                            ) : riskAnalysis && riskAnalysis.data.some(i => i.motorist_id === motorist.id) ? (
                              <Tag px={2} colorScheme="orange" borderRadius="full">
                                <Text fontSize="sm" fontWeight="bold">
                                  Em análise
                                </Text>
                              </Tag>
                            ) : motorist &&
                              motorist.total_freights >= 0 &&
                              (!motorist.rate_score || motorist.rate_score >= 4) ? (
                              <ConfirmFreightVpoModal
                                titleButton="Selecionar"
                                colorScheme="blue"
                                onConfirm={() => {
                                  window.location.href = `/freights/show/${freight.id}/pendencies?motorist_id=${motorist?.id}&vehicle_id=${motoristVehicle.id}&integration=buonny`
                                }}
                              />
                            ) : (
                              <Tooltip label="Inapto para o frete devido a baixa nota" hasArrow>
                                <Box>
                                  <Icon as={AiOutlineStop} color="red" fontSize={20} />
                                </Box>
                              </Tooltip>
                            )}
                          </HStack>
                        </Td>
                      </Tr>
                    ))
                  })}
              </Tbody>
            </Table>

            <Pagination
              currentPage={filters?.page}
              totalCountOfRegisters={compatibleMotorists?.total}
              registersPerPage={8}
              onPageChange={p => {
                setFilters({ ...filters, page: p })
              }}
              p="6"
            />

            <SendWhatsAppMessageModal
              isOpen={isSendWhatsAppModalOpen}
              onClose={() => {
                onSendWhatsAppModalClose()
                setAllChecked(false)
                setSelectedMotorists([])
              }}
              filters={{
                ...filters,
                vehicle_bodies: freight?.vehicle_bodies?.map(i => i.id),
                vehicle_categories: freight?.vehicle_categories?.map(i => i.id),
              }}
              allChecked={allChecked}
              selectedMotorists={selectedMotorists}
            />
          </>
        ) : isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erro ao carregar os motoristas compatíveis!</AlertTitle>
            <AlertDescription>
              Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o
              administrador do sistema.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Box>
  ) : null
}
