import {
  Box,
  Button,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiArrowLeftLine, RiCheckboxCircleFill, RiTruckLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../../../components/UI/PageHeader'
import { queryClient } from '../../../config/react-query'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Layout } from '../../../layout'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setHasTrailer } from '../../../store/slices/vehicleSlice'
import FormTrailerProvider from '../form-vehicles/trailer/FormTrailerProvider'
import FormVechileProvider from '../form-vehicles/vehicle/FormVechileProvider'
import { InfoVehicleExists } from '../info-vehicle-exists/InfoVehicleExists'

export function CreateVehicle(): JSX.Element {
  const {
    vehicleCreated: newVehicleCreated,
    trailerCreated,
    vehicleFound,
    hasTrailer,
    vehicleId: idVehicle,
  } = useAppSelector(state => state.vehicleSlice)
  const freight_id = useQueryParams('freight_id')
  const motorist_id = useQueryParams('motorist_id')
  const vehicle_id = useQueryParams('vehicle_id')
  const owner_id = useQueryParams('owner_id')
  const [tabIndex, setTabIndex] = useState(0)
  const bg = useColorModeValue('white', 'gray.800')

  const history = useHistory()
  let vehicleId = vehicle_id
  if (idVehicle) vehicleId = idVehicle

  const dispatch = useAppDispatch()

  const { data: retrievedVehicle } = useGetVehicle(vehicleId)

  useEffect(() => {
    if (freight_id && motorist_id && retrievedVehicle?.id) {
      const url = `/freights/show/${freight_id}/pendencies?motorist_id=${motorist_id}&vehicle_id=${retrievedVehicle.id}&integration=buonny`
      window.location.href = url
    }
  }, [freight_id, motorist_id, retrievedVehicle])

  useEffect(() => {
    if (retrievedVehicle) {
      const { vehicle_category } = retrievedVehicle
      const hasTrailerForm = vehicle_category?.has_trailer || false

      dispatch(setHasTrailer(hasTrailerForm))
    }
  }, [retrievedVehicle, dispatch])

  return (
    <Layout>
      <PageHeader title="Cadastro de veículos" icon={RiTruckLine} />

      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <Flex alignContent="center" gridGap={4} direction={['column', 'column', 'row']}>
          {motorist_id && (
            <Button
              colorScheme="blue"
              leftIcon={<Icon as={RiArrowLeftLine} fontSize="x-large" />}
              onClick={() => {
                history.push(`/motorists/show/${motorist_id}`)
              }}
            >
              Voltar para página do motorista
            </Button>
          )}

          {owner_id && (
            <Button
              colorScheme="blue"
              leftIcon={<Icon as={RiArrowLeftLine} fontSize="x-large" />}
              onClick={() => {
                history.push(`/owners/show/${owner_id}`)
              }}
            >
              Voltar para página do propriétário
            </Button>
          )}
        </Flex>

        {vehicleFound && <InfoVehicleExists motorist_id={motorist_id} vehicle_id={vehicleFound.id} />}

        {!vehicleFound && (
          <Tabs
            index={tabIndex}
            isFitted
            variant="enclosed-colored"
            colorScheme="orange"
            onChange={setTabIndex}
            mt="4"
          >
            <TabList d={['table', 'table', 'flex']} overflowX="auto" overflowY="hidden">
              {hasTrailer && (
                <>
                  <Tab
                    color={newVehicleCreated || retrievedVehicle ? 'green' : 'gray'}
                    cursor="pointer"
                    _disabled={{ opacity: 0.7 }}
                  >
                    {(newVehicleCreated || retrievedVehicle) && <Icon mr="1" as={RiCheckboxCircleFill} />}
                    Veículo
                  </Tab>
                  <Tab
                    color={trailerCreated || retrievedVehicle?.trailers?.[0]?.id ? 'green' : 'gray'}
                    cursor={retrievedVehicle ? 'pointer' : 'not-allowed'}
                    isDisabled={!retrievedVehicle}
                    _disabled={{ opacity: 0.7 }}
                  >
                    {(trailerCreated || retrievedVehicle?.trailers?.[0]?.id) && (
                      <Icon mr="1" as={RiCheckboxCircleFill} />
                    )}
                    Reboque
                  </Tab>
                </>
              )}
            </TabList>
            <TabPanels>
              <TabPanel p="0" pt="6">
                <FormVechileProvider
                  initialValues={retrievedVehicle}
                  onForward={() => {
                    if (hasTrailer) {
                      setTabIndex(state => state + 1)
                    }
                  }}
                  invalidateCache={() => {
                    queryClient.invalidateQueries('motorist')
                    queryClient.invalidateQueries('vehicle')
                    queryClient.invalidateQueries('trailer')
                  }}
                />
              </TabPanel>
              {hasTrailer && retrievedVehicle && (
                <TabPanel p="0" pt="6">
                  <FormTrailerProvider
                    onBack={() => setTabIndex(state => state - 1)}
                    initialValues={retrievedVehicle.trailers[0]}
                    vehicle={retrievedVehicle}
                    invalidateCache={() => {
                      queryClient.invalidateQueries('motorist')
                      queryClient.invalidateQueries('vehicle')
                      queryClient.invalidateQueries('trailer')
                    }}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </Layout>
  )
}
