import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { SelectOption } from '../../../components/form'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { CteIssuanceRequestType } from '../../types/CteIssuanceRequestType'

type CreateCteIssuanceRequestType = {
  freight_id: string
  has_toll: string
  toll_value?: number
  deduct_motorist_total: string
  motorist: SelectOption
  motorist_vehicle_id: string
  observation?: string
}

async function createCteIssuanceRequest(data: CreateCteIssuanceRequestType): Promise<CteIssuanceRequestType> {
  const { data: response } = await apiServer.post('/cte-issuance-requests', {
    freight_id: data.freight_id,
    has_toll: data.has_toll === 'yes',
    toll_value: data.has_toll === 'yes' ? Number(data.toll_value) : 0,
    deduct_motorist_total: data.has_toll === 'yes' ? data.deduct_motorist_total === 'yes' : false,
    motorist_id: data.motorist.value,
    motorist_vehicle_id: data.motorist_vehicle_id,
    observation: data.observation,
  })
  queryClient.invalidateQueries('cte-issuance-requests')
  return response.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePostCteIssuanceRequest(
  options?: UseMutationOptions<CteIssuanceRequestType, unknown, CreateCteIssuanceRequestType, unknown>,
): UseMutationResult<CteIssuanceRequestType, unknown, CreateCteIssuanceRequestType, unknown> {
  return useMutation(async (data: CreateCteIssuanceRequestType) => createCteIssuanceRequest(data), options)
}
