/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddFill } from 'react-icons/ri'
import * as yup from 'yup'
import { Ability } from '../../../../components/ability'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { useCteSelectOption } from '../../../../services/endpoints/datamex/cteSelectOption'
import { useUploadAttachmentsToFreightExpense } from '../../../../services/endpoints/freights/financial/attachments/uploadAttachment'
import {
  CreateFreightExpense,
  usePostFreightExpense,
} from '../../../../services/endpoints/freights/financial/PostFinancialTitle'
import { FreightExpensesAttachmentsTypeEnum } from '../../../../services/types/EnumTypes'
import { FormCreateOrEditFreightExpense } from '../FormCreateOrEditFinancialTitle'

interface CreateFreightExpenseModalProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
  transporter?: string
}

interface CreateOrEditFreightExpenseFormData extends Omit<CreateFreightExpense, 'bank'> {
  bank?: AutocompleteOption
  attachment_file: FileList
}

const createFreightExpenseFormSchema = yup.object().shape({
  cte_id: yup.string(),
  number: yup.string(),
  document_recipient: yup.string().required('Campo obrigatório'),
  name_recipient: yup.string().required('Campo obrigatório'),
  observation: yup.string(),
  pix: yup.string(),
  bank: yup
    .object()
    .shape({})
    .when('pix', {
      is: undefined,
      then: yup.object().shape({}).required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.object().shape({}),
    }),
  account_type: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  agency: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  account: yup.string().when('pix', {
    is: undefined,
    then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
    otherwise: yup.string(),
  }),
  value: yup.string().required('Campo obrigatório'),
  event: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  attachment_file: yup.string(),
})

export function CreateFreightExpenseModal({
  isOpen,
  onClose,
  freight_id,
  transporter,
}: CreateFreightExpenseModalProps): JSX.Element {
  const [attachment_file, setAttachment_file] = useState<FileList>()
  const [observation, setObservation] = useState<string>()
  const toast = useToast()
  const { data: cte, refetch } = useCteSelectOption(freight_id)
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(createFreightExpenseFormSchema),
  })

  const { mutateAsync: mutateAsyncCreate } = usePostFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('list-all-financial-title-history')
    },
  })
  const { mutateAsync: mutateAsyncUpload, error: uploadError } = useUploadAttachmentsToFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('list-all-financial-title-history')
    },
  })

  const handleCreateFreightExpense: SubmitHandler<CreateOrEditFreightExpenseFormData> = async data => {
    try {
      const { data: res } = await mutateAsyncCreate({
        ...data,
        freight_id,
        observation,
        transporter,
        bank: data.bank?.label !== '' ? data.bank?.label : undefined,
        attachment_file: undefined,
      })
      const newFinancial = res.data
      if (newFinancial && attachment_file && attachment_file.length > 0) {
        for (let i = 0; i < attachment_file.length; i++) {
          try {
            await mutateAsyncUpload({
              freight_expense_id: newFinancial.id,
              type: FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
              file: attachment_file[i],
            })
          } catch (errorUpload: any) {
            toastError({ toast, error: errorUpload || uploadError })
          }
        }
      }

      toast({
        title: 'Despesa adicionada com sucesso!',
        status: 'success',
        position: 'top-right',
      })

      setAttachment_file(undefined)

      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setObservation(undefined)
    setAttachment_file(undefined)
    refetch()
  }, [isOpen, refetch])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleCreateFreightExpense)}>
          <ModalHeader>Adicionar despesa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormCreateOrEditFreightExpense
              setAttachment_file={setAttachment_file}
              setObservation={setObservation}
              cte={cte}
              formState={formState}
              setValue={setValue}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>

            <Ability module="financial" action="create-freight-expense">
              <Button
                type="submit"
                colorScheme="blue"
                leftIcon={<Icon as={RiAddFill} />}
                isLoading={formState.isSubmitting}
              >
                Cadastrar
              </Button>
            </Ability>
          </ModalFooter>
        </form>
      </ModalContent>

      {/* <NotifyExpenseWhatsappModal
        isOpen={isOpenConfirmNotifyWhatsapp}
        onClose={onCloseConfirmNotifyWhatsapp}
        message={`🚀 Cadastro de Despesa Esporádica 🚀
    - Número do Frete: 9531
    - Analista: Leandro Almeida
    - Comercial: Larissa Nery
    - Cliente: CASSOL PRE-FABRICADOS LTDA
    - Origem: Monte Mor - SP
    - Destino: São Paulo - SP
    - Distância: 115.49 km
    - Material: não informado
    - Valor Total: não informado
    - Peso: 24000 kg
    - Checklists: não informado
    - Observações: não informado
  `}
      /> */}
    </Modal>
  )
}
