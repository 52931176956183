/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputProps,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEventHandler, forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { IconType } from 'react-icons'
import { MdClear } from 'react-icons/md'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import useClickOutside from '../../hooks/useClickOutside'
import { AutocompleteOption } from './types/AutocompleteOption'

interface AutocompleteMultiProps extends InputProps {
  name: string
  options: AutocompleteOption[]
  setValue: UseFormSetValue<FieldValues>
  initialValue?: AutocompleteOption[]
  label?: string
  error?: FieldError
  OptionIcon?: IconType
  onSelectOptions?: (option: AutocompleteOption[]) => void
  clearState?: (value: any | undefined) => void
  isCloseOptionsOnSelect?: boolean
}

const AutocompleteMultiBase: ForwardRefRenderFunction<HTMLInputElement, AutocompleteMultiProps> = (
  {
    name,
    options,
    label,
    error,
    setValue,
    initialValue,
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'off',
    onChange,
    onBlur,
    onFocus,
    OptionIcon,
    onSelectOptions,
    clearState,
    isCloseOptionsOnSelect = false,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)
  const [items, setItems] = useState(options)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedItems, setSelectedItems] = useState<AutocompleteOption[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  useClickOutside(containerRef, () => {
    if (showOptions) {
      setShowOptions(false)
    }
  })

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  // Esse useEffect garante que só o que foi selecionado seja levado em conta
  // Ele precisa vir primeiro, NÃO pode alterar a ordem
  useEffect(() => {
    if (!selectedItems || !initialValue) {
      setValue(name, undefined)
    }
    // Desabilitado a dependência do "initialValue" para evitar de eliminar o estado no onSave
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, name])

  // Esse useEffect garante que o estado seja atualizado quando o valor do selectedItems for alterado
  // Ele precisa vir depois, NÃO pode alterar a ordem
  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      setValue(name, selectedItems)
    }
  }, [name, selectedItems, setValue])
  // Esse useEffect garante que o estado seja atualizado quando o valor do inicialValue for alterado
  // Ele precisa vir depois, NÃO pode alterar a ordem
  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      setValue(name, initialValue)
      setSelectedItems(initialValue)
    }
  }, [initialValue, name, setValue])

  useEffect(() => {
    if (selectedItems) {
      if (onSelectOptions) onSelectOptions(selectedItems)
    }
  }, [onSelectOptions, selectedItems])

  const filterOptions = useRef(
    debounce(
      async (value: string) => {
        const filteredOptions = options.filter(option =>
          option.label.toLowerCase().includes(value.toLowerCase()),
        )
        setItems(filteredOptions)
      },
      500,
      {},
    ),
  ).current

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target

    if (value) {
      await filterOptions(value)
    }
  }

  function handleFilterRemoveItem(item: AutocompleteOption) {
    const filteredOptions = selectedItems.filter(i => i.value !== item.value)
    setSelectedItems(filteredOptions)

    if (filteredOptions.length > 0) setValue(name, filteredOptions)
    else setValue(name, undefined)
  }

  function handleSelectItem(item: AutocompleteOption, isSelected: boolean) {
    if (isSelected) {
      handleFilterRemoveItem(item)
    } else {
      setValue(name, [...selectedItems, item])
      setSelectedItems(state => [...state, item])
      if (isCloseOptionsOnSelect) setShowOptions(false)
      setInputValue('')
    }
  }

  return (
    <Box position="relative" cursor={isDisabled ? 'not-allowed' : undefined} opacity={isDisabled ? 0.7 : 1}>
      {label && (
        <FormLabel htmlFor={name}>
          {label}
          {isRequired && (
            <Box as="span" color="red.500" ml="1">
              *
            </Box>
          )}
        </FormLabel>
      )}

      <Flex
        ref={inputRef}
        alignItems="center"
        borderWidth={!error ? 1 : 2}
        borderColor={error ? 'red.500' : undefined}
        rounded="md"
        px="3"
        pt="2"
        bg={bg}
      >
        <Flex flex={1} flexWrap="wrap">
          {selectedItems.map(item => (
            <Tag key={item.value} size={size} borderRadius="full" mr="2" mb="2">
              <TagLabel fontSize="sm">{item.label}</TagLabel>
              {!isDisabled && <TagCloseButton fontSize="sm" onClick={() => handleFilterRemoveItem(item)} />}
            </Tag>
          ))}
          <Input
            flex={1}
            mb="2"
            ref={ref}
            id={name}
            variant="unstyled"
            size={size}
            onChange={e => {
              setValue(name, undefined) // Limpar o estado ao digitar para não submeter o form sem nada selecionado.
              handleInputChange(e)
              if (onChange) onChange(e)
            }}
            onBlur={e => {
              if (onBlur) onBlur(e)
            }}
            onFocus={e => {
              setShowOptions(true)
              if (onFocus) onFocus(e)
            }}
            isDisabled={isDisabled}
            _disabled={{
              opacity: 0.7,
              cursor: 'not-allowed',
            }}
            cursor={isDisabled ? 'not-allowed' : undefined}
            disableAutocomplete
            disableGoogleAutocomplete
            autoComplete={autoComplete}
            {...rest}
          />
        </Flex>

        {!isDisabled && (
          <>
            <Icon
              mb="2"
              as={showOptions ? RiArrowUpSLine : RiArrowDownSLine}
              fontSize={20}
              onClick={() => {
                if (!isDisabled) setShowOptions(state => !state)
              }}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
            />
            <Icon
              mb="2"
              fontSize={20}
              onClick={() => {
                setSelectedItems([])
                setInputValue('')
                setShowOptions(false)
                setValue(name, undefined)
                if (inputRef.current) {
                  const inputDOM = inputRef.current.querySelector('input')
                  if (inputDOM) {
                    inputDOM.value = ''
                  }
                }
                if (clearState) clearState(undefined)
              }}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
              as={MdClear}
            />
          </>
        )}
      </Flex>

      {error && (
        <Text fontSize="sm" mt="2" textColor="red.500">
          {error.message}
        </Text>
      )}

      {showOptions && (
        <Stack
          direction="column"
          mt="2"
          py="2"
          bg={bg}
          rounded="md"
          shadow="md"
          borderWidth="1px"
          zIndex={10}
          position="absolute"
          w="100%"
          ref={containerRef}
        >
          {items.length > 0 ? (
            items.map(option => {
              const isSelected = selectedItems.find(i => i.value === option.value)
              const selectedButtonBg = bg === 'white' ? 'gray.100' : 'gray.700'

              return (
                <Button
                  key={option.value}
                  leftIcon={OptionIcon && <Icon as={OptionIcon} fontSize={18} />}
                  bg={isSelected ? selectedButtonBg : bg}
                  justifyContent="flex-start"
                  fontWeight="normal"
                  fontSize="md"
                  onClick={() => handleSelectItem(option, !!isSelected)}
                >
                  {option.label}
                </Button>
              )
            })
          ) : (
            <Text p="2" px="4" textColor="gray.400">
              Nenhuma opção
            </Text>
          )}
        </Stack>
      )}
    </Box>
  )
}

export const AutocompleteMulti = forwardRef(AutocompleteMultiBase)
