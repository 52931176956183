import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import {
  RiAddFill,
  RiBriefcaseLine,
  RiCheckLine,
  RiCloseCircleLine,
  RiEditLine,
  RiFileCopyLine,
  RiMore2Fill,
  RiStopFill,
  RiTimeLine,
  RiUser2Line,
} from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { FormattedQuotation } from '../../../services/endpoints/freights'
import { formattedTransporter } from '../../../services/utils/formattedTransporter'
import { EditOrDuplicateFreightModal } from '../../freights/edit/EditOrDuplicateFreightModal'
import CreateRoutePricesButton from '../../pricing/route-prices/modals/buttons-actions/CreatePriceMotoristButton'
import ShowRoutePricesButton from '../../pricing/route-prices/modals/buttons-actions/ShowPriceMotoristButton'
import { ChangeStatusQuotationModal } from '../modals/ChangeStatusQuotationModal'
import { EditOrDuplicateQuotationModal } from '../modals/edit/EditOrDuplicateQuotationModal'
import { EnableQuotationModal } from '../modals/edit/EnableQuotationModal'

interface CardProps {
  quotation: FormattedQuotation
}

export function Card({ quotation }: CardProps): JSX.Element {
  const bg = useColorModeValue('gray.200', 'gray.500')
  const box = useColorModeValue('gray.100', 'gray.600')
  const [changeStatus, setChangeStatus] = useState<SelectOption>()
  const [actionFreightType, setActionFreightType] = useState<'edit' | 'duplicate'>('edit')

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure()

  const {
    isOpen: isOpenFreightFormModal,
    onOpen: onOpenFreightFormModal,
    onClose: onCloseFreightFormModal,
  } = useDisclosure()

  const {
    isOpen: isEnableQuotationModalOpen,
    onOpen: onEnableQuotationModalOpen,
    onClose: onEnableQuotationModalClose,
  } = useDisclosure()

  const {
    isOpen: isOpenQuotationFormModal,
    onOpen: onOpenQuotationFormModal,
    onClose: onCloseQuotationFormModal,
  } = useDisclosure()

  return (
    <Flex bg={box} p="2" borderRadius={8} direction="column" mb="2">
      {!quotation ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : (
        quotation && (
          <>
            <Flex
              justifyContent="space-between"
              borderBottomColor={bg}
              borderBottomWidth={1}
              pb="0.5"
              mb="0.5"
            >
              <Flex>
                <Popover isLazy>
                  <Tooltip hasArrow label="Opções">
                    <Flex fontSize="xs" fontWeight="bold" cursor="pointer" alignItems="center">
                      <PopoverTrigger>
                        <Flex>
                          <Icon as={RiMore2Fill} />
                        </Flex>
                      </PopoverTrigger>
                    </Flex>
                  </Tooltip>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>
                      Opções
                      <Flex gridGap="2" mt={1} justifyContent="center">
                        {quotation.status &&
                          ['pending', 'in_quotation', 'to_approve'].includes(quotation.status) && (
                            <Ability module="spotHub" action="show-change-status-quotation">
                              <Button
                                size="sm"
                                colorScheme="red"
                                leftIcon={<Icon as={RiCloseCircleLine} />}
                                onClick={() => {
                                  onOpenChangeStatus()
                                  setChangeStatus({
                                    label: 'Desativada',
                                    value: 'disabled',
                                  })
                                }}
                              >
                                Desativar
                              </Button>
                            </Ability>
                          )}
                        <Ability module="spotHub" action="show-force-disable-quotation">
                          {quotation.status === 'finished' && (
                            <Button
                              size="sm"
                              colorScheme="red"
                              leftIcon={<Icon as={RiCloseCircleLine} />}
                              onClick={() => {
                                onOpenChangeStatus()
                                setChangeStatus({
                                  label: 'Desativada',
                                  value: 'disabled',
                                })
                              }}
                            >
                              Desativar
                            </Button>
                          )}
                        </Ability>

                        <Ability module="operation" action="create-quotation">
                          <Button
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<Icon as={RiFileCopyLine} />}
                            onClick={() => {
                              onOpenQuotationFormModal()
                              setActionFreightType('duplicate')
                            }}
                          >
                            Duplicar
                          </Button>
                        </Ability>

                        <Ability module="operation" action="update-quotation">
                          <Button
                            size="sm"
                            colorScheme="yellow"
                            leftIcon={<Icon as={RiEditLine} />}
                            onClick={() => {
                              onOpenQuotationFormModal()
                              setActionFreightType('edit')
                            }}
                          >
                            Editar
                          </Button>
                        </Ability>

                        {quotation?.status === 'disabled' && (
                          <Button
                            size="sm"
                            colorScheme="green"
                            leftIcon={<Icon as={RiCheckLine} />}
                            onClick={onEnableQuotationModalOpen}
                          >
                            Reativar
                          </Button>
                        )}
                      </Flex>
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Stack
                        direction="row"
                        mb={1}
                        justify={
                          quotation.previous_status_quotation && quotation.previous_status_quotation.label
                            ? 'space-between'
                            : 'flex-end'
                        }
                      >
                        {quotation.previous_status_quotation && quotation.previous_status_quotation.label && (
                          <Ability module="spotHub" action="show-back-status-quotation">
                            <Button
                              size="sm"
                              colorScheme="facebook"
                              leftIcon={<FaArrowLeft />}
                              onClick={() => {
                                onOpenChangeStatus()
                                setChangeStatus(quotation.previous_status_quotation)
                              }}
                            >
                              Voltar
                            </Button>
                          </Ability>
                        )}

                        <Ability module="operation" action="create-freight">
                          <Button
                            size="sm"
                            colorScheme="green"
                            leftIcon={<Icon as={RiAddFill} />}
                            onClick={() => {
                              onOpenFreightFormModal()
                              setActionFreightType('duplicate')
                            }}
                          >
                            Criar frete
                          </Button>
                        </Ability>

                        {quotation.next_status_quotation && quotation.next_status_quotation.label && (
                          <Ability module="spotHub" action="show-next-status-quotation">
                            <Button
                              size="sm"
                              colorScheme="facebook"
                              rightIcon={<FaArrowRight />}
                              onClick={() => {
                                onOpenChangeStatus()
                                setChangeStatus(quotation.next_status_quotation)
                              }}
                            >
                              Avançar
                            </Button>
                          </Ability>
                        )}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>

                <Tooltip hasArrow label={`Ver cotação de ${quotation?.client?.name}`}>
                  <Text
                    ml="1"
                    fontSize="xs"
                    fontWeight="bold"
                    cursor="pointer"
                    as={Link}
                    to={`/quotations/show/${quotation?.id}`}
                    target="_blank"
                  >
                    #{quotation?.quotation_number} {String(quotation?.client.nickname).substring(0, 18)}
                  </Text>
                </Tooltip>
              </Flex>
              <Text fontSize="xs">
                <Flex direction="row">
                  <Icon as={RiTimeLine} fontSize="sm" mr="1" /> {quotation?.created_at}{' '}
                  {quotation?.created_at_hour}
                </Flex>
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              direction="row"
              borderBottomColor={bg}
              borderBottomWidth={1}
              pb="0.5"
              mb="0.5"
            >
              <Text fontWeight="bold" fontSize="xs">
                {String(quotation?.origin_city).length > 18
                  ? String(quotation?.origin_city).substring(0, 18).split('-')
                  : String(quotation?.origin_city)}{' '}
                {String(quotation?.origin_city).length > 18 && ` - ${quotation?.origin.state.uf}`}
              </Text>
              <Text fontWeight="bold" fontSize="xs">
                {String(quotation?.destination_city).length > 18
                  ? String(quotation?.destination_city).substring(0, 18).split('-')
                  : String(quotation?.destination_city)}{' '}
                {String(quotation?.destination_city).length > 18 && ` - ${quotation?.destination.state.uf}`}
              </Text>
            </Flex>

            <Flex
              justifyContent="space-between"
              borderBottomColor={bg}
              borderBottomWidth={1}
              pb="0.5"
              mb="0.5"
            >
              <Text fontSize="xs">
                <Flex direction="row">
                  <Icon as={RiUser2Line} fontSize="sm" mr="1" /> {quotation?.creator?.name.split(' ')[0]}
                </Flex>
              </Text>

              <Text fontSize="xs">
                <Flex direction="row">
                  <Icon as={RiBriefcaseLine} fontSize="md" mr="1" />
                  {quotation?.service_price &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(quotation?.service_price)
                      .split(',')[0]}
                </Flex>
              </Text>
            </Flex>

            {(quotation?.transporter || quotation?.calculation_ref) && (
              <Flex
                justifyContent="space-between"
                alignItems="flex-end"
                fontSize="xs"
                borderBottomColor={bg}
                borderBottomWidth={1}
                pb="0.5"
                mb="0.5"
              >
                <Text>{formattedTransporter(quotation?.transporter)}</Text>
                {quotation?.calculation_ref && (
                  <Tooltip label="Ir para a calculadora">
                    <Text as={Link} to={quotation?.calculation_ref}>
                      <Icon as={AiOutlineCalculator} color="orange" fontSize="md" mr="1" />
                    </Text>
                  </Tooltip>
                )}
              </Flex>
            )}

            <Flex justifyContent="space-between" alignItems="flex-end" fontSize="xs" pb="0.5">
              <Stack zIndex="0" direction="row" mt={1} justify="space-between">
                {['in_quotation', 'to_approve', 'finished', 'disabled'].includes(quotation.status) && (
                  <>
                    <CreateRoutePricesButton quotation={quotation} size="xs" />
                    <ShowRoutePricesButton quotation={quotation} size="xs" />
                  </>
                )}
              </Stack>

              {quotation.status === 'finished' && quotation.finished_at && (
                <Text fontSize="xs">
                  <Flex direction="row">
                    <Icon as={RiStopFill} fontSize="md" mr="1" />{' '}
                    {new Intl.DateTimeFormat('pt-BR', {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    }).format(new Date(quotation.finished_at))}
                  </Flex>
                </Text>
              )}

              {quotation.status === 'disabled' && quotation.denied_at && (
                <Text fontSize="xs">
                  <Flex direction="row">
                    <Icon color="red" as={TiDeleteOutline} fontSize="md" mr="1" />{' '}
                    {new Intl.DateTimeFormat('pt-BR', {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    }).format(new Date(quotation.denied_at))}
                  </Flex>
                </Text>
              )}
            </Flex>

            <EditOrDuplicateQuotationModal
              type={actionFreightType}
              quotation={quotation}
              isOpen={isOpenQuotationFormModal}
              onClose={() => {
                onCloseQuotationFormModal()
              }}
            />

            <EnableQuotationModal
              quotation_id={quotation.id}
              isOpen={isEnableQuotationModalOpen}
              onClose={() => {
                onEnableQuotationModalClose()
              }}
            />

            <EditOrDuplicateFreightModal
              type={actionFreightType}
              freight_id={quotation.id}
              isOpen={isOpenFreightFormModal}
              onClose={() => {
                onCloseFreightFormModal()
              }}
            />

            {changeStatus && (
              <ChangeStatusQuotationModal
                quotation={quotation}
                change_status={changeStatus}
                isOpen={isOpenChangeStatus}
                onClose={() => {
                  setChangeStatus(undefined)
                  onCloseChangeStatus()
                }}
              />
            )}
          </>
        )
      )}
    </Flex>
  )
}
