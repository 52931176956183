import {
  Flex,
  FormControl,
  FormLabel,
  Switch as ChakraUiSwitch,
  SwitchProps as ChakraUiSwitchProps,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'

interface SwitchProps extends Omit<ChakraUiSwitchProps, 'children'> {
  name: string
  setValue: UseFormSetValue<FieldValues>
  label?: string
  initialValue?: boolean
  error?: FieldError
  isDisabled?: boolean
  isRequired?: boolean
}

export function Switch({
  name,
  setValue,
  label,
  initialValue,
  onChange,
  error,
  isDisabled,
  isRequired,
  defaultChecked,
  ...rest
}: SwitchProps): JSX.Element {
  const [cheCked, setChecked] = useState(defaultChecked)

  useEffect(() => {
    if (initialValue === undefined) {
      setValue(name, undefined)
    } else {
      setValue(name, initialValue)
    }
    if (defaultChecked) setValue(name, true)
    if (initialValue || defaultChecked) {
      setChecked(true)
    } else {
      setChecked(false)
      setValue(name, false)
    }
  }, [initialValue, setValue, name, defaultChecked])

  return (
    <FormControl isRequired={isRequired} isFocusable={!!error}>
      <Flex gridGap={1}>
        <ChakraUiSwitch
          id={name}
          isDisabled={isDisabled}
          isChecked={cheCked}
          onChange={nextValue => {
            if (nextValue.target.checked) {
              setChecked(true)
              setValue(name, true)
            } else {
              setChecked(false)
              setValue(name, false)
            }
            if (onChange) onChange(nextValue)
          }}
          {...rest}
        />
        {label && (
          <FormLabel htmlFor={name} mb="0" ml={1} cursor="pointer" _disabled={{ opacity: 0.7 }}>
            {label}
          </FormLabel>
        )}
      </Flex>
      {error && (
        <Text fontSize="sm" textColor="red.700">
          {error.message}
        </Text>
      )}
    </FormControl>
  )
}
