import { LoadingRequestStatusEnum } from '../types/EnumTypes'

export function getLoadingRequestColorByStatus(status: LoadingRequestStatusEnum): string {
  const loadingRequestStatusWithColor = {
    awaiting_analysis: 'gray',
    rejected: 'red',
    awaiting_cf: 'blue',
    awaiting_payment: 'yellow',
    paid: 'blue',
    finished: 'green',
  }

  return loadingRequestStatusWithColor[status]
}
