import { Box, Divider, Flex, Heading, Icon, ModalCloseButton, Text, Tooltip } from '@chakra-ui/react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RiEditFill } from 'react-icons/ri'
import { format } from 'date-fns-tz'
import { usePostTripObservations } from '../../../services/endpoints/freights/trip-observations/postTripObservations'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { FormattedAllFreightsInProgress } from '../../../services/endpoints/freights/getFreightsInProgress'
import { TextArea } from '../../../components/form'

interface IObservation {
  id: string
  motorist_id?: string
  comment: string
  last_observation?: string
  freight_number?: string | number
}
interface AddInteractionModalProps {
  freight: FormattedAllFreightsInProgress
}
export function AddTripObservationsModal({ freight }: AddInteractionModalProps): JSX.Element {
  const filterFormSchema = yup.object().shape({
    comment: yup.string().required('Campo obrigatório'),
  })
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })
  const { errors } = formState
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: postTripObservations, isLoading: isLoadingTripObservations } = usePostTripObservations(
    {
      onSuccess: () => {
        queryClient.invalidateQueries('list-all-TripObservations-timeline')
        queryClient.invalidateQueries('show-TripObservations-timeline')
        queryClient.invalidateQueries('list-freight-in-progress')
        toast({
          title: 'Comentário salvo com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        })
        onClose()
      },
    },
  )

  const handleCreateNewObservation: SubmitHandler<IObservation> = async (data: IObservation) => {
    try {
      await postTripObservations({
        comment: data.comment,
        freight_id: freight.id,
        motorist_id: freight.motorist_id,
      })
    } catch (error) {
      toastError({ error, toast })
    }
  }

  return (
    <>
      {freight.trip_observations ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          fontSize="xs"
          direction="row"
          gridGap="2"
        >
          <Tooltip
            hasArrow
            placement="top"
            label={`${
              freight.trip_observations
                ? freight.trip_observations[freight.trip_observations.length - 1]?.comment
                : ''
            }`}
          >
            <Text
              textAlign="center"
              fontWeight="bold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              maxW="80px"
            >
              {/* {handleFormatDate(freight.trip_observations[freight.trip_observations.length - 1]?.created_at)}
               */}
              {freight.trip_observations[freight.trip_observations.length - 1]?.comment}
            </Text>
          </Tooltip>
          <Tooltip hasArrow placement="top" label="Ver ou cadastrar observações da viagem">
            <Flex
              direction="row"
              onClick={onOpen}
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
            >
              <Box>
                <Icon as={RiEditFill} />
              </Box>
            </Flex>
          </Tooltip>
        </Flex>
      ) : (
        <Tooltip hasArrow placement="top" label="Cadastrar uma observação da viagem">
          <Flex direction="row" onClick={onOpen} cursor="pointer" justifyContent="center" alignItems="center">
            <Box>
              <Icon as={RiEditFill} />
            </Box>
          </Flex>
        </Tooltip>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="2xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(handleCreateNewObservation)} noValidate>
              <Heading size="md" my="4">
                Cadastre uma nova observação da viagem para o frete #{freight.freight_number}
              </Heading>
              <TextArea
                name="comment"
                error={errors.comment}
                setValue={setValue}
                placeholder="Digite uma observação da viagem..."
                style={{ width: '100%' }}
              />

              <Flex justifyContent="flex-end" gridGap="2" mt="2">
                <Button colorScheme="orange" type="submit" isLoading={isLoadingTripObservations}>
                  Enviar
                </Button>
                <Button colorScheme="red" onClick={onClose}>
                  Fechar
                </Button>
              </Flex>
              <Divider my="4" />

              <Text>
                Esse campo deve ser utilizado para registrar informações esporádicas sobre a viagem, como
                paradas, atrasos, problemas, etc. Essa informação será visível somente nessa tela.
              </Text>
              <Divider my="4" />

              {freight.trip_observations && (
                <>
                  <Heading size="md" mb="4">
                    Última Observação da Viagem - #{freight.freight_number}
                  </Heading>

                  <Flex justifyContent="flex-start" direction="column" fontSize="lg">
                    <Text whiteSpace="pre-wrap" mb="2">
                      <b>Mensagem:</b>{' '}
                      {freight.trip_observations[freight.trip_observations.length - 1]?.comment}
                    </Text>
                    <Text whiteSpace="pre-wrap">
                      {freight &&
                        freight.trip_observations &&
                        freight.trip_observations[freight.trip_observations.length - 1] && (
                          <>
                            <b>Data:</b>{' '}
                            {format(
                              new Date(
                                freight.trip_observations[freight.trip_observations.length - 1].created_at,
                              ),
                              'dd/MM/yy HH:mm',
                            )}
                          </>
                        )}
                    </Text>
                  </Flex>
                </>
              )}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
