import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AutocompleteAsync, AutocompleteOption } from '../../../components/form'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { usePostContactFromClient } from '../../../services/endpoints/clients/client-contacts/postContactFromClient'
import { searchClientGroup } from '../../../services/endpoints/clients/getClients'
import { ContactByClientForm, ContactByClientFormSchema } from '../ContactByClientForm'

interface CreateContactByClientModalProps {
  isOpen: boolean
  onClose: () => void
}

export type ClientContactFormType = {
  client_contact: {
    id: string
    name?: string
    role?: string
    email?: string
    phone?: string
    phone2?: string
    client_id?: string
    last_monthly_nps_at?: string
    client_business_group_id: AutocompleteOption
  }
}

export function CreateContactByClientModal({
  isOpen,
  onClose,
}: CreateContactByClientModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(ContactByClientFormSchema),
  })
  const { isSubmitting } = formState
  const createContactByClient = usePostContactFromClient({
    onSuccess: () => {
      queryClient.invalidateQueries('contacts_from_client')
    },
  })

  const handleCreateContact: SubmitHandler<ClientContactFormType> = async data => {
    try {
      await createContactByClient.mutateAsync({
        ...data.client_contact,
        client_business_group_id: data.client_contact?.client_business_group_id?.value?.toString(),
      })
      toast({
        title: 'Contato cadastrado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent p="5">
        <Box as="form" onSubmit={handleSubmit(handleCreateContact)} noValidate>
          <Heading size="lg" fontWeight="normal" my="6">
            Cadastrar contato
          </Heading>

          <Grid templateColumns="repeat(12, 1fr)" gap="4">
            <ContactByClientForm formState={formState} setValue={setValue} />
            <GridItem colSpan={[12, 6]}>
              <AutocompleteAsync
                label="Grupo empresarial"
                name="client_contact.client_business_group_id"
                setValue={setValue}
                loadOptions={searchClientGroup}
                error={formState.errors.client_contact?.client_business_group_id}
              />
            </GridItem>
          </Grid>

          <Flex mt="8" justify="flex-end">
            <HStack spacing="4">
              <Button onClick={onClose}>Cancelar</Button>

              <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
