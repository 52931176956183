import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { CteIssuanceRequestType } from '../../types/CteIssuanceRequestType'
import { formatCteIssuanceRequestStatus } from '../../utils/formatCteIssuanceRequestStatus'
import parseCurrency from '../../utils/parseCurrency'

export type CteIssuanceRequestTypeFormatted = CteIssuanceRequestType & {
  formatted_status: string
  formatted_toll_value: string
  formatted_agreed_price: string
  formatted_service_price: string
  total_value: string
}

type FindCteIssuanceRequestResponse = CteIssuanceRequestTypeFormatted

export type FilterCteIssuanceRequestsType = {
  id: string
}

async function findCteIssuanceRequest(params: FilterCteIssuanceRequestsType) {
  const { data: response } = await apiServer.get(`/cte-issuance-requests/find`, {
    params,
  })

  const { data } = response

  const total_value = data.deduct_motorist_total
    ? Number(data.freight.agreed_price) - Number(data.toll_value)
    : Number(data.freight.agreed_price)

  return {
    ...data,
    formatted_status: formatCteIssuanceRequestStatus(data.status),
    formatted_toll_value: parseCurrency(data.toll_value),
    formatted_agreed_price: parseCurrency(data.freight.agreed_price),
    formatted_service_price: parseCurrency(data.freight.service_price),
    total_value: parseCurrency(total_value),
    created_at: format(new Date(data.created_at), 'dd/MM/yy HH:mm'),
  }
}

export function useGetCteIssuanceRequest(
  params: FilterCteIssuanceRequestsType,
): UseQueryResult<FindCteIssuanceRequestResponse> {
  return useQuery(['cte-issuance-requests', params], () => findCteIssuanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
