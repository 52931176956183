import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'

import { IMonitoringOccurence } from '../../types/MonitoringOccurenceType'
import { ResponseApiType } from '../../types'

interface IGetMonitoringOccurenceParams {
  freight_id: string
  monitoring_occurrence_id: string
}

type IMonitoringOccurencesResponse = IMonitoringOccurence

async function getMonitoringOccurence(params: IGetMonitoringOccurenceParams) {
  const { data: monitoringOccurence } = await apiServer.get<ResponseApiType<IMonitoringOccurencesResponse>>(
    `/freights/monitoring-occurrences/${params.freight_id}/${params.monitoring_occurrence_id}`,
  )

  return monitoringOccurence.data
}

export function useGetMonitoringOccurence(
  params: IGetMonitoringOccurenceParams,
): UseQueryResult<IMonitoringOccurencesResponse> {
  return useQuery(['monitoring-occurence', params], () => getMonitoringOccurence(params))
}
