import { UseQueryResult, useQuery } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type Input = {
  originCity: { lat: number; lng: number }
  destinationCity: { lat: number; lng: number }
}

type Output = number | undefined

export async function getFreightDistance(params: Input): Promise<Output> {
  if (params.originCity && params.destinationCity) {
    const { data: response } = await apiServer.get<ResponseApiType<Output>>('/freight-distance', {
      params: {
        origin_lat: params.originCity.lat,
        origin_lng: params.originCity.lng,
        destination_lat: params.destinationCity.lat,
        destination_lng: params.destinationCity.lng,
      },
    })

    return response.data
  }
}

export function useGetFreightDistance(params: Input): UseQueryResult<Output> {
  return useQuery(['freight-distance', params], () => getFreightDistance(params), {
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  })
}
