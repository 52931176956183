import { useColorModeValue } from '@chakra-ui/react'
import 'leaflet/dist/leaflet.css'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../layout'
import { MotoristAttachments } from './cards/attachments'
import { BuonnyHistory } from './cards/buonny-history'
import { MotoristFreightHistory } from './cards/freight-history'
import { MotoristInfo } from './cards/motorist-info'
import { MotoristVehicle } from './cards/vehicle'

interface RequestParams {
  motorist_id: string
}

export function ShowMotorist(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { motorist_id } = useParams<RequestParams>()

  return (
    <Layout>
      <MotoristInfo motorist_id={motorist_id} bg={bg} />

      <MotoristVehicle motorist_id={motorist_id} bg={bg} />

      {/* <FavoriteRoutes motorist_id={motorist_id} bg={bg} /> */}

      <MotoristAttachments motorist_id={motorist_id} bg={bg} />

      <BuonnyHistory motorist_id={motorist_id} bg={bg} />

      <MotoristFreightHistory motorist_id={motorist_id} bg={bg} />
    </Layout>
  )
}
