import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { MdFindInPage } from 'react-icons/md'
import { FormattedOneFreight } from '../../../../../../../services/endpoints/freights'
import { FormattedMotorist } from '../../../../../../../services/endpoints/motorists/getMotorist'
import { VehicleType } from '../../../../../../../services/types'
import { useAppDispatch } from '../../../../../../../store'
import { setMotorist } from '../../../../../../../store/slices/motoristSlice'
import { setVehicleFound } from '../../../../../../../store/slices/vehicleSlice'
import { ConfirmFreightVpoModal } from '../ConfirmFreightVpoModal'
import { CheckRiskMotorist } from './CheckRisk'

interface CheckRiskModalProps {
  onOpenModal?: () => void
  freight: FormattedOneFreight
  motorist?: FormattedMotorist
  vehicle?: VehicleType
}

export function CheckRiskMotoristModal({
  onOpenModal,
  freight,
  motorist,
  vehicle,
}: CheckRiskModalProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (isOpen && onOpenModal) onOpenModal()

  return (
    <>
      <Tooltip hasArrow label="Consulta rápida de analise de risco">
        <Box>
          <ConfirmFreightVpoModal
            titleButton="Consultar"
            leftIcon={<Icon as={MdFindInPage} />}
            size="sm"
            colorScheme="orange"
            onConfirm={onOpen}
            variant="outline"
          />
        </Box>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          dispatch(setMotorist(null))
          dispatch(setVehicleFound(null))
        }}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verificação rápida de risco na Buonny</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <CheckRiskMotorist freight={freight} motorist={motorist} vehicle={vehicle} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
