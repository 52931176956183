import { Button, ButtonGroup, Flex, IconButton, Input, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaExpand } from 'react-icons/fa'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { RiArrowRightDoubleFill } from 'react-icons/ri'

type HeaderProps = {
  nextMonth: () => void
  previousMonth: () => void
  period: { initial_date: Date; final_date: Date }
  month: string
  onManualPeriod: (initialDate: Date, finalDate: Date) => void
  onResetPeriod: () => void
  onFullScreen: () => void
  disabledNextMonth: boolean
}

const Header = ({
  nextMonth,
  previousMonth,
  month,
  onManualPeriod,
  period,
  onResetPeriod,
  onFullScreen,
  disabledNextMonth,
}: HeaderProps): JSX.Element => {
  const [manualPeriod, setmanualPeriod] = useState({
    initial_date: period.initial_date,
    final_date: period.final_date,
  })
  return (
    <Flex justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
      <Flex alignItems="center" gridGap="2" flexDirection={{ base: 'column', md: 'row' }}>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <IconButton
            onClick={previousMonth}
            variant="outline"
            aria-label="tv mode"
            icon={<MdArrowBackIos />}
          />
          <Text textAlign="center" mx="10" minW="100px">
            {month}
          </Text>
          <IconButton
            variant="outline"
            onClick={nextMonth}
            aria-label="tv mode"
            disabled={disabledNextMonth}
            icon={<MdArrowForwardIos />}
          />
        </Flex>
        <Flex
          align="center"
          gridGap="2"
          flexDirection={{ base: 'column', md: 'row' }}
          w={{ base: '100%', md: 'auto' }}
        >
          <Flex align="center" w="100%" justifyContent="space-between">
            <Input
              flex={1}
              fontSize="sm"
              size="md"
              type="date"
              w={{ base: '100%', md: '100px' }}
              name="initial_date"
              onChange={e => setmanualPeriod({ ...manualPeriod, initial_date: new Date(e.target.value) })}
            />
            <Text fontSize="sm" color="gray.500" fontWeight="bold">
              <RiArrowRightDoubleFill fontSize="20px" />
            </Text>
            <Input
              flex={1}
              fontSize="sm"
              size="md"
              type="date"
              w={{ base: '100%', md: '100px' }}
              name="initial_date"
              onChange={e => setmanualPeriod({ ...manualPeriod, final_date: new Date(e.target.value) })}
            />
          </Flex>
          <Flex gridGap="2" w="100%">
            <Button
              variant="solid"
              colorScheme="orange"
              size="md"
              onClick={() => onManualPeriod(manualPeriod.initial_date, manualPeriod.final_date)}
            >
              Aplicar
            </Button>
            <Button variant="outline" colorScheme="orange" size="md" onClick={onResetPeriod}>
              Hoje
            </Button>
            <ButtonGroup size="md" isAttached variant="outline">
              <IconButton aria-label="tv mode" icon={<FaExpand />} onClick={onFullScreen} />
            </ButtonGroup>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
