import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { GrLinkNext } from 'react-icons/gr'
import { TextArea } from '../../../../../components/form'
import { CteIssuanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { toastError } from '../../../../../config/error/toastError'
import { usePutCteIssuanceRequest } from '../../../../../services/endpoints/freights/PutCteIssuanceRequest'

type HandleCteIssuanceRequestStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  analysisStatus:
    | 'awaiting_issuance_cte'
    | 'rejected'
    | 'cte_issued'
    | 'cte_return_to_motorist'
    | 'awaiting_advance_request'
    | 'finished'
}

type RequestParams = {
  id: string
}

type UpdateCteIssuanceRequestFormData = {
  status: string
  reject_reason?: string
}

const UpdateCteIssuanceRequestFormSchema = yup.object().shape({
  status: yup.string(),
  reject_reason: yup.string().when('status', {
    is: CteIssuanceRequestStatusEnum.REJECTED,
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
})

const modalMessagens = {
  awaiting_issuance_cte: {
    title: 'Deseja realmente aprovar a solicitação de emissão de CTe?',
    body: 'Se você aprovar, um outro operador responsável irá dar seguemento e emitir a CTe.',
    message: 'Solicitação de emissão de CTe aprovada!',
  },
  rejected: {
    title: 'Deseja realmente reprovar a solicitação de emissão de CTe?',
    body: 'Se você reprovar, o operador responsável pela solicitação irá reavaliar os dados.',
    message: 'Solicitação de emissão de CTe reprovada!',
  },
  cte_issued: {
    title: 'Deseja realmente avançar para o próximo status?',
    body: `Se o status for avançado, a solicitação de emissão obterá o status de 'CTe Emitida'.`,
    message: `Status avançado para 'CTe emitida'!`,
  },
  cte_return_to_motorist: {
    title: 'Deseja realmente avançar para o próximo status?',
    body: `Se o status for avançado, a solicitação de emissão obterá o status de 'CTe Retornado ao Motorista'.`,
    message: `Status avançado para 'CTe Retornado ao Motorista'!`,
  },
  awaiting_advance_request: {
    title: 'Deseja realmente avançar para o próximo status?',
    body: 'Se o status for avançado, a solicitação de emissão ira aguardar um usuário criar a solicitação de adiantamento.',
    message: `Status avançado para 'Aguardando Solicitação de adiantamento'!`,
  },
  finished: {
    title: 'Deseja realmente finalizar a solicitação de emissão de CTe?',
    body: 'Se a solicitação for finalizada, o mesmo não podera mais ser alterado.',
    message: 'Solicitação de emissão de CTe finalizada!',
  },
}

export function HandleCteIssuanceRequestStatusModal({
  isOpen,
  onClose,
  analysisStatus,
}: HandleCteIssuanceRequestStatusModalProps): JSX.Element {
  const toast = useToast()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateCteIssuanceRequestFormSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateCteIssuanceRequestStatus, isLoading } = usePutCteIssuanceRequest({
    onSuccess: () => {
      toast({
        title: modalMessagens[analysisStatus].message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateCteIssuanceRequest: SubmitHandler<UpdateCteIssuanceRequestFormData> = async data => {
    const uploadFormData = {
      status: CteIssuanceRequestStatusEnum.REJECTED,
      reject_reason:
        data.status === 'awaiting_issuance_cte' || data.status === 'finished' ? null : data.reject_reason,
    }

    if (data.status === 'awaiting_issuance_cte') {
      Object.assign(uploadFormData, {
        status: CteIssuanceRequestStatusEnum.AWAITING_ISSUANCE_CTE,
      })
    }

    if (data.status === 'finished') {
      Object.assign(uploadFormData, {
        status: CteIssuanceRequestStatusEnum.FINISHED,
      })
    }

    if (data.status === 'cte_issued') {
      Object.assign(uploadFormData, {
        status: CteIssuanceRequestStatusEnum.CTE_ISSUED,
      })
    }

    if (data.status === 'cte_return_to_motorist') {
      Object.assign(uploadFormData, {
        status: CteIssuanceRequestStatusEnum.CTE_RETURN_TO_MOTORIST,
      })
    }

    if (data.status === 'awaiting_advance_request') {
      Object.assign(uploadFormData, {
        status: CteIssuanceRequestStatusEnum.AWAITING_ADVANCE_REQUEST,
      })
    }

    await updateCteIssuanceRequestStatus({
      id,
      data: uploadFormData,
    })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateCteIssuanceRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              {modalMessagens[analysisStatus].title}
            </Text>
            <Text color="gray.400" mt={2} mb={2}>
              {modalMessagens[analysisStatus].body}
            </Text>

            {analysisStatus === 'rejected' && (
              <TextArea
                label="Motivo da reavalição"
                name="reject_reason"
                error={errors.reject_reason}
                height="120px"
                setValue={setValue}
                isRequired
              />
            )}

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme={analysisStatus !== 'rejected' ? 'green' : 'red'}
                onClick={() => {
                  setValue('status', analysisStatus)
                }}
                isLoading={isLoading}
              >
                {analysisStatus === 'awaiting_issuance_cte' ? (
                  <>
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                  </>
                ) : analysisStatus === 'finished' ? (
                  <>
                    <Icon fontSize={20} as={IoCheckmark} mr={1} /> Finalizar
                  </>
                ) : analysisStatus === 'rejected' ? (
                  <>
                    <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                  </>
                ) : (
                  <>
                    Avançar status <Icon fontSize={12} as={GrLinkNext} ml={1} />
                  </>
                )}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
