import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiArrowRightLine, RiCheckLine, RiDirectionLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../../components/form/Input'
import { toastError } from '../../../../../config/error/toastError'
import { queryClient } from '../../../../../config/react-query'
import { validateDate } from '../../../../../services/utils/dates/isValidDate'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { changeFrightStatus, setNextStatus } from '../../../../../store/slices/freightSlice'

interface SetNextStatusFormData {
  status_date: Date
}

const setNextStatusFormSchema = yup.object().shape({
  status_date: yup
    .date()
    .typeError('Data inválida')
    .test('is-valid-date', 'Data inválida', validateDate)
    .required('Campo obrigatório'),
})

export function SetNextStatusModal(): JSX.Element {
  const {
    isOpen: isSetNextStatusOpen,
    onClose: onSetNextStatusClose,
    onOpen: onSetNextStatusOpen,
  } = useDisclosure()
  const dispatch = useAppDispatch()
  const { freight } = useAppSelector(state => state.freightSlice)
  const isLoading = useAppSelector(state => state.freightSlice.isLoading)
  const toast = useToast()
  const { handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(setNextStatusFormSchema),
  })
  const [statusDate, setStatusDate] = useState('')

  const handleSetNextStatus: SubmitHandler<SetNextStatusFormData> = async () => {
    if (freight && freight?.next_status) {
      const error = await dispatch(
        changeFrightStatus({
          freight_id: freight.id,
          status: freight.next_status.value,
          status_date: statusDate,
        }),
      )

      if (!error) {
        onSetNextStatusClose()
        toast({
          title: 'Status alterado com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        dispatch(setNextStatus(null))
        queryClient.invalidateQueries('freight-status-history')
        queryClient.invalidateQueries('freight')
      } else {
        dispatch(setNextStatus(null))
        toastError({ toast, error })
      }
    }
  }

  return (
    <>
      <Button
        size="xs"
        h="6"
        colorScheme="blue"
        rightIcon={<Icon as={RiArrowRightLine} />}
        onClick={onSetNextStatusOpen}
      >
        {freight?.next_status?.label}
      </Button>
      <Modal
        isOpen={isSetNextStatusOpen}
        onClose={onSetNextStatusClose}
        isCentered
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleSetNextStatus)} noValidate>
            <ModalHeader>Alterar status do frete</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex align="center">
                O status do frete será alterado para
                <Tag size="lg" borderRadius="full" colorScheme="green" ml="4">
                  <TagLeftIcon as={RiDirectionLine} />
                  <TagLabel>{freight?.next_status?.label}</TagLabel>
                </Tag>
              </Flex>
              <Stack spacing="6" mt="6">
                <Input
                  isRequired
                  type="datetime-local"
                  setValue={setValue}
                  onChange={e => setStatusDate(e.target.value)}
                  name="status_date"
                  label="Data do Status"
                  error={formState.errors.status_date}
                />
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onSetNextStatusClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="orange"
                leftIcon={<Icon as={RiCheckLine} />}
                isLoading={isLoading || formState.isSubmitting}
              >
                Alterar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
