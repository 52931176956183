import {
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine, RiAttachmentLine, RiErrorWarningLine } from 'react-icons/ri'
import { DateStatus } from '.'
import { toastError } from '../../../config/error/toastError'
import { AddAttachmentModal } from '../../../pages/freights/show/cards/attachments/AddAttachmentModal'
import { SetNextStatusModal } from '../../../pages/freights/show/cards/selected-motorist/SetNextStatusModal'
import { useAttachments } from '../../../services/endpoints/freights/getAttachments'
import { FreightAttachmentsTypeEnum } from '../../../services/types/EnumTypes'
import { useAppSelector } from '../../../store'
import { CheckFreightIsExpired } from '../../CheckFreightIsExpired'
import { AccordionTables } from './accordionTables/AccordionTables'

const phaseStatus = 'collecting'

export function CollectingPhase(): JSX.Element {
  const toast = useToast()
  const { freight, statusHistory } = useAppSelector(state => state.freightSlice)
  const { isOpen: showMoreInfo, onToggle: toggleMoreInfo } = useDisclosure()
  const {
    data: attachments,
    refetch: attachmentsRefetch,
    isLoading: isLoadAttachments,
    error: errorListAttachments,
    isError: isErrorListAttachments,
  } = useAttachments({
    freight_id: String(freight?.id),
  })

  useEffect(() => {
    if (isErrorListAttachments) {
      toastError({ toast, error: errorListAttachments })
    }
  }, [isErrorListAttachments, errorListAttachments, toast])

  const {
    isOpen: isOpenAddAttachments,
    onOpen: onOpenAddAttachments,
    onClose: onCloseAddAttachments,
  } = useDisclosure()
  const filteredAttachments = attachments?.filter(
    attach => attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.DEFAULT,
  )
  const filteredOccurences = attachments?.filter(
    attach =>
      attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.OCCURRENCE,
  )

  const history = statusHistory?.find(statusOfPhase => statusOfPhase.status === 'ready')
  return (
    <Flex justifyContent="space-between" direction="column" mb="4">
      <Flex direction="row" gridGap="2">
        <Text
          onClick={toggleMoreInfo}
          cursor="pointer"
          size="sm"
          color={
            ['ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(String(freight?.status))
              ? 'green.500'
              : 'gray.400'
          }
          fontWeight="bold"
          fontSize="sm"
        >
          <Button
            color="white"
            backgroundColor={
              ['ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
                String(freight?.status),
              )
                ? 'green.500'
                : 'gray.400'
            }
            borderRadius="100%"
            size="xs"
            mr="4"
          >
            2
          </Button>
          Chegou para carregar{' '}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          direction={['column', 'row']}
          gridGap="1"
          mb={['-5', '0']}
        >
          {isLoadAttachments
            ? freight?.status === 'collecting' && <Spinner size="md" color="orange" />
            : filteredAttachments &&
              filteredAttachments.length > 0 && (
                <Tag size="sm" backgroundColor="orange" borderRadius="full">
                  <TagLeftIcon as={RiAttachmentLine} />
                  <TagLabel>{filteredAttachments.length}</TagLabel>
                </Tag>
              )}
          {isLoadAttachments
            ? freight?.status === 'collecting' && <Spinner size="md" color="red.400" />
            : filteredOccurences &&
              filteredOccurences.length > 0 && (
                <Tag size="sm" mx="1" borderRadius="full" backgroundColor="red.400">
                  <TagLeftIcon as={RiErrorWarningLine} />
                  <TagLabel>{filteredOccurences.length}</TagLabel>
                </Tag>
              )}
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb="-3" mt="-2">
        <Flex direction={['column', 'row']} ml="10" my="2" gridGap={2}>
          {['collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
            String(freight?.status),
          ) && (
            <>
              {['ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
                String(freight?.status),
              ) ? (
                <>
                  {isLoadAttachments || isLoadAttachments ? (
                    <Spinner size="md" />
                  ) : (
                    <DateStatus freight={freight} history={history} />
                  )}
                </>
              ) : (
                freight && <CheckFreightIsExpired freight={freight} />
              )}
            </>
          )}
          <Flex direction={['column', 'row']} alignItems="center" gridGap="2" justifyContent="center">
            {freight?.status === 'collecting' && (
              <>
                <Button colorScheme="gray" size="xs" h="6" onClick={onOpenAddAttachments}>
                  <Icon as={RiAttachmentLine} mr="1" /> Anexar ordem de coleta
                </Button>

                <SetNextStatusModal />
              </>
            )}
          </Flex>
        </Flex>

        <Flex mt="-7">
          <IconButton
            onClick={toggleMoreInfo}
            variant="ghost"
            size="sm"
            aria-label="More info"
            icon={!showMoreInfo ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
          />
        </Flex>
      </Flex>

      <Collapse in={showMoreInfo} animateOpacity>
        <AccordionTables showMoreInfo={showMoreInfo} toggleMoreInfo={toggleMoreInfo} status={phaseStatus} />
      </Collapse>

      <AddAttachmentModal
        freight_status={phaseStatus}
        isOpen={isOpenAddAttachments}
        onClose={() => {
          onCloseAddAttachments()
          attachmentsRefetch()
        }}
      />
    </Flex>
  )
}
