import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input as ChakraInput,
} from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowRightLine, RiDeleteBinLine } from 'react-icons/ri'
import { Input } from '../../../../components/form/Input'
import { Select } from '../../../../components/form/Select'

interface ClientTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  formState: FormState<FieldValues>
}

export function ClientTabPanel({ setValue, tabNav, formState }: ClientTabPanelProps): JSX.Element {
  const { errors } = formState

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={2}>
          <FormControl isDisabled>
            <FormLabel>Código</FormLabel>
            <ChakraInput value="60752" />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isDisabled>
            <FormLabel>CNPJ</FormLabel>
            <ChakraInput value="08958058000154" />
          </FormControl>
        </GridItem>
        <GridItem colSpan={7}>
          <FormControl isDisabled>
            <FormLabel>Razão social</FormLabel>
            <ChakraInput value="PRO ATIVA - SERVICOS DE TRANSPORTES LTDA" />
          </FormControl>
        </GridItem>
      </Grid>

      <Heading size="md" fontWeight="normal" mt="8" mb="4">
        Dados do retorno
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={1}>
          <Button colorScheme="red">
            <Icon as={RiDeleteBinLine} />
          </Button>
        </GridItem>

        <GridItem colSpan={4}>
          <Input
            name="buonny_contact_name"
            label="Nome contato"
            setValue={setValue}
            initialValue="MARCELO"
            error={errors.buonny_contact_name}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Select
            name="buonny_contact_type"
            label="Tipo"
            setValue={setValue}
            options={[
              { label: 'E-mail', value: '2' },
              { label: 'Telefone', value: '1' },
            ]}
            error={errors.buonny_contact_type}
            initialValue="2"
          />
        </GridItem>

        <GridItem colSpan={5}>
          <Input
            name="buonny_contact_description"
            label="Valor"
            setValue={setValue}
            initialValue="MARCELO.JUNIOR@PROATIVALOGISTICA.COM.BR"
            error={errors.buonny_contact_description}
          />
        </GridItem>
      </Grid>

      <Flex justifyContent="center" mt="6" pt="6" borderTopWidth="1px" borderTopColor="gray.200">
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(1)}>
          Avançar
        </Button>
      </Flex>
    </>
  )
}
