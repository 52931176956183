import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaWhatsapp } from 'react-icons/fa'
import * as yup from 'yup'
import { Input, Switch, TextArea } from '../../../../components/form'
import { toastError } from '../../../../config/error/toastError'
import { IChecklistRiskAnalysisResponse } from '../../../../services/endpoints/risk-analysis/checklist-risk-analysis/getChecklistRiskAnalysis'
import { usePostNotifyRiskAnalysis } from '../../../../services/endpoints/risk-analysis/checklist-risk-analysis/usePostNotifyRiskAnalysis'
import { RiskAnalysisOneFormatted } from '../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'

interface NotifyRiskAnalysisModalProps {
  riskAnalysis: RiskAnalysisOneFormatted
  checklistRiskAnalysis?: IChecklistRiskAnalysisResponse[]
}

const notifyRiskAnalysisFormSchema = yup.object().shape({
  freight_number: yup.string().required('Número do frete é obrigatório'),
  analyst: yup.string(),
  commercial: yup.string(),
  client: yup.string(),
  origin: yup.string(),
  destination: yup.string(),
  distance: yup.string(),
  material: yup.string(),
  total_value: yup.string(),
  weight: yup.string(),
  checklists: yup.mixed(),
  requester: yup.string(),
  recurrent: yup.string(),
  antt_active: yup.string(),
  tracker: yup.string(),
  locator: yup.string(),
  reason: yup.string(),
  observations: yup.string(),
  vitimology: yup.string(),
  crvl: yup.string(),
  cpf_motorist: yup.string(),
  inspection: yup.string(),
})

export function NotifyRiskAnalysisModal({
  riskAnalysis,
  checklistRiskAnalysis,
}: NotifyRiskAnalysisModalProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(notifyRiskAnalysisFormSchema),
  })
  const toast = useToast()
  const [wannaAddObservations, setWannaAddObservations] = useState(false)
  const { mutateAsync: postNotifyRiskAnalysis, isLoading: isLoadingPostNotifyRiskAnalysis } =
    usePostNotifyRiskAnalysis()
  const handlePostNotification = async (data: any) => {
    try {
      await postNotifyRiskAnalysis({ ...data, checklists: checklistRiskAnalysis })

      toast({
        title: 'Notificação enviada com sucesso!',
        description: 'Notificação enviada com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    } catch (error) {
      toastError({
        error,
        toast,
      })
    }
  }
  return (
    <>
      <Box>
        <Tooltip label="Aprovação de Risco Esporádico" hasArrow placement="top">
          <Button
            setValue={setValue}
            label="Notificar Zenilton"
            name="notify_zenilton"
            leftIcon={<FaWhatsapp />}
            onClick={onOpen}
          >
            Notificar Zenilton
          </Button>
        </Tooltip>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(data => handlePostNotification(data))}>
            <ModalHeader>Notificar Zenilton</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl
                  onSubmit={() =>
                    handleSubmit(async data => {
                      handlePostNotification(data)
                    })
                  }
                >
                  <FormLabel>FRETE</FormLabel>
                  <Input
                    placeholder="Número do frete"
                    initialValue={riskAnalysis.freight.freight_number}
                    setValue={setValue}
                    name="freight_number"
                    isDisabled={!!riskAnalysis.freight.freight_number}
                    isInvalid={errors.freight_number}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Analista</FormLabel>
                  <Input
                    placeholder="Nome do analista"
                    initialValue={riskAnalysis.user?.name}
                    setValue={setValue}
                    name="analyst"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Solicitante</FormLabel>
                  <Input
                    placeholder="Nome do comercial"
                    setValue={setValue}
                    name="commercial"
                    initialValue={riskAnalysis.freight.seller.name}
                    // isDisabled={freight ? !!riskAnalysis.freight.seller.name : false}
                    isInvalid={errors.commercial}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>CLIENTE</FormLabel>
                  <Input
                    placeholder="Nome do cliente"
                    setValue={setValue}
                    name="client"
                    initialValue={riskAnalysis.freight.client.name}
                    isInvalid={errors.client}
                  />
                </FormControl>
                <SimpleGrid columns={2} spacing={10}>
                  <FormControl>
                    <FormLabel>ORIGEM</FormLabel>
                    <Input
                      placeholder="Cidade de origem"
                      setValue={setValue}
                      name="origin"
                      initialValue={riskAnalysis.freight.origin_city}
                      // isDisabled={freight ? !!riskAnalysis.freight.origin_city : false}
                      isInvalid={errors.origin}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>DESTINO</FormLabel>
                    <Input
                      placeholder="Cidade de destino"
                      setValue={setValue}
                      name="destination"
                      initialValue={riskAnalysis.freight.destination_city}
                      // isDisabled={freight ? !!riskAnalysis.freight.destination_city : false}
                      isInvalid={errors.destination}
                    />
                  </FormControl>
                </SimpleGrid>
                <FormControl>
                  <FormLabel>DISTÂNCIA</FormLabel>
                  <Input
                    placeholder="Distância em KM"
                    setValue={setValue}
                    name="distance"
                    initialValue={riskAnalysis.freight.distance}
                    isInvalid={errors.distance}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>MATERIAL</FormLabel>
                  <Input
                    placeholder="Tipo de material"
                    setValue={setValue}
                    name="material"
                    initialValue={riskAnalysis.freight.cargo?.name}
                    isInvalid={errors.material}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>VALOR TOTAL</FormLabel>
                  <Input
                    placeholder="Valor total"
                    // initialValue={parseCurrency(riskAnalysis.freight.cargo?.value)}
                    setValue={setValue}
                    name="total_value"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>PESO</FormLabel>
                  <Input
                    placeholder="Peso em KG"
                    setValue={setValue}
                    name="weight"
                    initialValue={riskAnalysis.freight.cargo?.weight}
                    isInvalid={errors.weight}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>RECORRENTE</FormLabel>
                  <Input
                    placeholder="É Recorrente?"
                    setValue={setValue}
                    name="recurrent"
                    initialValue={
                      (riskAnalysis.motorist && riskAnalysis.motorist.total_freights) ??
                      riskAnalysis.motorist.total_freights > 1
                        ? `O motorista já realizou ${riskAnalysis.motorist.total_freights} fretes`
                        : riskAnalysis.motorist.total_freights === 1
                        ? 'O motorista já realizou 1 frete'
                        : 'O motorista não realizou nenhum frete'
                    }
                  />
                </FormControl>
                <FormControl>
                  <Flex direction="row">
                    <FormLabel>
                      RASTREADOR
                      <Switch
                        setValue={setValue}
                        defaultChecked={riskAnalysis.vehicle.has_tracker}
                        name="tracker"
                      />
                    </FormLabel>

                    <FormLabel>
                      LOCALIZADOR
                      <Switch setValue={setValue} name="locator" />
                    </FormLabel>
                  </Flex>
                </FormControl>
                <FormControl gridColumn="1 / span 2">
                  <FormLabel>ANTT Ativa</FormLabel>
                  <Input placeholder="ANTT Ativa?" setValue={setValue} name="antt_active" />
                </FormControl>
                <FormControl gridColumn="1 / span 2">
                  <FormLabel>VITIMOLOGIA</FormLabel>
                  <Input placeholder="Vitimologia" setValue={setValue} name="vitimology" />
                </FormControl>
                <FormControl gridColumn="1 / span 2">
                  <FormLabel>Inspeção </FormLabel>
                  <Input placeholder="Inspeção" setValue={setValue} name="inspection" />
                </FormControl>
                <FormControl gridColumn="1 / span 2">
                  <FormLabel>CRLV</FormLabel>
                  <Input
                    placeholder="Crvl"
                    setValue={setValue}
                    name="crvl"
                    initialValue={
                      riskAnalysis.motorist && riskAnalysis.motorist.attachments
                        ? riskAnalysis.motorist.attachments.find(attachment => attachment.type === 'CRLV')
                          ? `Possui CRLV anexado: ${
                              riskAnalysis.motorist.attachments.find(attachment => attachment.type === 'CRLV')
                                ?.attachment_file_url
                            }`
                          : 'Não possui CRLV anexado'
                        : ''
                    }
                  />
                </FormControl>
                <FormControl gridColumn="1 / span 2">
                  <FormLabel>CPF motorista</FormLabel>
                  <Input
                    placeholder="CPF motorista"
                    setValue={setValue}
                    name="cpf_motorist"
                    initialValue={riskAnalysis.motorist.cpf}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Checklists de Segurança</FormLabel>
                  <Accordion>
                    {checklistRiskAnalysis && checklistRiskAnalysis.length ? (
                      checklistRiskAnalysis?.map(checklist => (
                        <AccordionItem key={checklist?.id}>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                Tipo de checagem: {checklist?.checkType}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <Divider />
                          <AccordionPanel pb={4}>
                            <VStack spacing={4} align="start">
                              <Text>Status: {checklist?.status}</Text>
                              {checklist.comments && (
                                <Text>Comentários Adicionais: {checklist.comments}</Text>
                              )}
                            </VStack>
                          </AccordionPanel>
                        </AccordionItem>
                      ))
                    ) : (
                      <>Nenhum Checklist Encontrado no SpotHub.</>
                    )}
                  </Accordion>
                </FormControl>
                <FormControl>
                  <FormLabel>Motivo da solicitação de liberação:</FormLabel>
                  <TextArea setValue={setValue} name="reason" />
                </FormControl>
                <FormControl>
                  <FormLabel>Adicionar Observações Adicionais</FormLabel>
                  <Switch
                    setValue={setValue}
                    name="addObservations"
                    onChange={() => setWannaAddObservations(!wannaAddObservations)}
                  />
                </FormControl>

                {wannaAddObservations && (
                  <FormControl>
                    <FormLabel>Observações</FormLabel>
                    <TextArea setValue={setValue} name="observations" />
                  </FormControl>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="green" type="submit" isLoading={isLoadingPostNotifyRiskAnalysis}>
                Notificar Zenilton
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
