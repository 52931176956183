import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useDeleteFreightTrackerDevices } from '../../../services/endpoints/tracker-devices/freight-tracker-devices/deleteFreightTrackerDevices'
import { useCreateFreightTrackerDevices } from '../../../services/endpoints/tracker-devices/freight-tracker-devices/postFreightTrackerDevices'
import { ReturnFormattedTrackerDevices } from '../../../services/endpoints/tracker-devices/getTrackerDevices'

type ChangeStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  freight_id: string
  tracker: ReturnFormattedTrackerDevices
}

export function ChangeStatusModal({
  freight_id,
  tracker,
  isOpen,
  onClose,
}: ChangeStatusModalProps): JSX.Element {
  const toast = useToast()
  const createFreightTrackerDevices = useCreateFreightTrackerDevices({
    onSuccess: () => {
      queryClient.invalidateQueries('list-tracker-devices')
    },
  })
  const deleteFreightTrackerDevices = useDeleteFreightTrackerDevices({
    onSuccess: () => {
      queryClient.invalidateQueries('list-tracker-devices')
    },
  })

  const handleRelation = useCallback(async () => {
    if (tracker.is_tracker_verified) {
      try {
        await deleteFreightTrackerDevices.mutateAsync({
          freight_id,
          tracker_id: tracker.id,
        })
        toast({
          title: 'Alteração realizada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    } else {
      try {
        await createFreightTrackerDevices.mutateAsync({
          freight_id,
          tracker_id: tracker.id,
        })
        toast({
          title: 'Dispositivo verificado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    }
  }, [
    createFreightTrackerDevices,
    deleteFreightTrackerDevices,
    freight_id,
    onClose,
    toast,
    tracker.id,
    tracker.is_tracker_verified,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>Tem certeza que deseja alterar o status deste dispositivo?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={2} align="stretch" direction={['row']} justifyContent="flex-end">
            <Button colorScheme="orange" type="submit" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              onClick={handleRelation}
              isLoading={deleteFreightTrackerDevices.isLoading || createFreightTrackerDevices.isLoading}
            >
              Sim
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
