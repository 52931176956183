/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

async function deleteCostRiskAnalysis(id: string) {
  return apiServer.delete(`/delete-cost-risk-analysis/${id}`)
}

export function useDeleteCostRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>,
) {
  return useMutation(async (id: string) => deleteCostRiskAnalysis(id), options)
}
