import { Box, Heading, Spinner, Tooltip } from '@chakra-ui/react'
import { IoReload } from 'react-icons/io5'

interface ReloadButtonProps {
  isFetching: boolean
  refetch: () => void
}

export function ReloadButton({ isFetching, refetch }: ReloadButtonProps): JSX.Element {
  return (
    <Tooltip label="Atualizar dados" hasArrow placement="auto">
      <Box>
        <Heading size="md" cursor="pointer">
          {isFetching ? <Spinner size="sm" /> : <IoReload onClick={refetch} />}
        </Heading>
      </Box>
    </Tooltip>
  )
}
