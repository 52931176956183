import {
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { RiAddLine } from 'react-icons/ri'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { CreateQuickOwner } from '../create/CreateQuickOwner'
import { FindOwnerToLinkToVehicleOrTrailer } from '../list/FindOwnerToLinkToVehicleOrTrailer'

interface AttachOwnerToVehicleOrTrailerModalProps {
  vehicleOrTrailerId?: string
  motorist_id?: string
  isOpen: boolean
  onClose: () => void
  refetchVehicles?: () => void
}

export function AttachOwnerToVehicleOrTrailerModal({
  vehicleOrTrailerId,
  motorist_id,
  isOpen,
  onClose,
  refetchVehicles,
}: AttachOwnerToVehicleOrTrailerModalProps): JSX.Element {
  const [showFindForOwner, setShowFindForOwner] = useState<boolean>(true)
  const [showFormOwner, setShowFormOwner] = useState<boolean>()
  const integration = useQueryParams('integration')

  useEffect(() => {
    setShowFindForOwner(true)
    setShowFormOwner(undefined)
  }, [refetchVehicles, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="4xl"
      scrollBehavior="outside"
    >
      <ModalOverlay />

      <ModalContent p="8">
        <ModalBody>
          <Flex justifyContent="center" alignItems="center" direction="column">
            <>
              <ModalCloseButton />
              {showFindForOwner && (
                <>
                  {vehicleOrTrailerId && (
                    <FindOwnerToLinkToVehicleOrTrailer
                      refetchVehicles={refetchVehicles}
                      motorist_id={motorist_id}
                      onClose={() => {
                        onClose()
                      }}
                      vehicleOrTrailerId={vehicleOrTrailerId}
                    />
                  )}
                  <Divider my="2" />
                  {!integration && (
                    <Flex mt="6" alignItems="flex-end" justifyContent="flex-end">
                      <Button
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<Icon as={RiAddLine} />}
                        onClick={() => {
                          setShowFormOwner(true)
                          setShowFindForOwner(false)
                        }}
                      >
                        Novo proprietário
                      </Button>
                    </Flex>
                  )}
                </>
              )}
            </>

            {showFormOwner && (
              <>
                <ModalCloseButton />

                <CreateQuickOwner
                  refetchVehicles={refetchVehicles}
                  motorist_id={motorist_id}
                  vehicleOrTrailerId={vehicleOrTrailerId}
                  onClose={() => {
                    onClose()
                  }}
                />
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
