import { v4 as uuidv4 } from 'uuid'
import { PlaceType } from '../RoutePlannerMap'

export interface ReduceState {
  origin?: PlaceType | null
  steps: PlaceType[]
  destination?: PlaceType | null
}

export interface defaultAction {
  type: 'ADD_STEP' | 'SET_ORIGIN' | 'SET_DESTINATION'
  payload: PlaceType
}
export interface removeStepAction {
  type: 'EXCLUDE_STEP'
  payload: string
}
export interface createEmptyStep {
  type: 'CREATE_EMPTY_STEP'
}
export interface updateStepAction {
  type: 'UPDATE_STEP'
  payload: {
    id: string
    position: {
      lat: number
      lng: number
      label: string
    }
    uf: string
    cityName: string
    country: string
  }
}

// Tipo de ação
export type Action = defaultAction | removeStepAction | createEmptyStep | updateStepAction

export const stepsReducer = (state: ReduceState, action: Action): ReduceState => {
  switch (action.type) {
    case 'SET_ORIGIN':
      return {
        ...state,
        origin: action.payload,
      }
    case 'ADD_STEP': {
      const alreadyExists = state.steps.find(step => step.id === action.payload.cityName)

      if (alreadyExists) {
        return state
      }
      return {
        ...state,
        steps: [
          ...state.steps,
          {
            id: action.payload.cityName,
            ...action.payload,
          },
        ],
      }
    }
    case 'CREATE_EMPTY_STEP': {
      return {
        ...state,
        steps: [
          ...state.steps,
          {
            id: `empty-${uuidv4()}`,
            position: {
              lat: 0,
              lng: 0,
              label: '',
            },
          },
        ],
      }
    }

    case 'UPDATE_STEP':
      return {
        ...state,
        steps: state.steps.map(step => {
          if (step.id === action.payload.id) {
            return {
              ...step,
              ...action.payload,
            }
          }
          return step
        }),
      }
    case 'EXCLUDE_STEP':
      return {
        ...state,
        steps: state.steps.filter(step => step.id !== action.payload),
      }

    case 'SET_DESTINATION':
      return {
        ...state,
        destination: action.payload,
      }

    default:
      return state
  }
}
