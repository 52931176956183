import { Box, Heading } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

interface CargoTypeSelectProps {
  cargoType: string
  setCargoType: (value: string) => void
  errorsAntt: any
  setValue: any
}

export const CargoTypeSelect = ({
  cargoType,
  setCargoType,
  errorsAntt,
  setValue,
}: CargoTypeSelectProps): JSX.Element => {
  return (
    <Box py="4">
      <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
        Tipo de Carga
      </Heading>
      <Select
        isRequired
        placeholder="Selecione o tipo de carga"
        value={cargoType}
        onChange={e => setCargoType(e.target.value)}
        error={errorsAntt?.cargoType}
        name="cargoType"
        setValue={setValue}
        options={[
          { value: 'all', label: 'Todas' },
          { value: 'granel_solido', label: 'Granel Sólido' },
          { value: 'granel_liquido', label: 'Granel Líquido' },
          { value: 'frigorificada', label: 'Frigorificada' },
          { value: 'conteinerizada', label: 'Conteinerizada' },
          { value: 'geral', label: 'Geral' },
          { value: 'neogranel', label: 'Neogranel' },
          { value: 'perigosa_granel_solido', label: 'Perigosa Granel Sólido' },
          { value: 'perigosa_granel_liquido', label: 'Perigosa Granel Líquido' },
          { value: 'perigosa_frigorificada', label: 'Perigosa Frigorificada' },
          { value: 'perigosa_conteinerizada', label: 'Perigosa Conteinerizada' },
          { value: 'perigosa_geral', label: 'Perigosa Geral' },
          { value: 'granel_pressurizada', label: 'Granel Pressurizada' },
        ]}
      />
    </Box>
  )
}
