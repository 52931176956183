/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import TrailerAttachmentType from '../../../types/TrailerAttachmentType'

export interface CreateAttachToTrailer {
  trailer_id: string
  type: string
  name: string
  attachment_file: File
}

async function createAttachmentToTrailer({ trailer_id, type, name, attachment_file }: CreateAttachToTrailer) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  formData.append('trailer_id', trailer_id)
  formData.append('type', type)
  formData.append('name', name)
  return apiServer.post<ResponseApiType<TrailerAttachmentType>>('/trailers/attachments', formData)
}

export function useCreateAttachmentToTrailer(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateAttachToTrailer, unknown>,
) {
  return useMutation(async (data: CreateAttachToTrailer) => createAttachmentToTrailer(data), options)
}
