import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import parseCurrency from '../../../../../services/utils/parseCurrency'
import { calculateTotalToll } from '../../services/calculate-total-toll'

interface RouteTitleType {
  id: number
  distance: string
  duration: string
  tolls_count: number
  total: number
  provider: string
  freight_distance?: string
  antt_value?: string
}

function formatRouteDurationToReadable(duration: string): string {
  const [hours, minutes] = duration.split(':')
  return `Duração: ${hours} horas e ${minutes} min.`
}

export const RouteTitle = ({
  distance,
  freight_distance,
  duration,
  provider,
  total,
  tolls_count,
  id,
  antt_value,
}: RouteTitleType): JSX.Element => {
  return (
    <Box>
      <Heading as="h3" size="sm" flexDirection="row">
        <Text fontWeight="bold" fontSize="lg" color="orange.500" textTransform="uppercase">
          Rota {id}
        </Text>
        {distance && ` ${distance}`}
        {freight_distance && `, ${freight_distance} km`}
        {duration && `, ${formatRouteDurationToReadable(duration)}`}
        {tolls_count > 0 && ` - ${tolls_count} Pedágio${tolls_count > 1 ? 's' : ''}`}
      </Heading>

      <Text color="green.500" fontWeight="bold" pt={1}>
        Valor total do pedágio: {total ? parseCurrency(total) : undefined}
      </Text>
      <Text color="gray.500" fontWeight="bold" fontSize="sm">
        {provider !== 'Unknown' && provider}
      </Text>
      <Text color="green.500" fontWeight="bold" pt={1}>
        Valor mínimo da ANTT: {antt_value ? parseCurrency(antt_value) : undefined}
      </Text>
      <Text color="gray.500" fontWeight="bold" fontSize="sm">
        {provider !== 'Unknown' && provider}
      </Text>
    </Box>
  )
}

interface Route {
  distance: string
  duration: string
  tolls: Array<{
    nome: string
    tarifa: Record<number, number>
    concessionaria: string
    km: string
    rodovia: string
  }>
  tolls_count: number
  tolls_gross_amount: number
  route_provider: string
}

interface RouteCardProps {
  id: number
  onMouseEnter: () => void
  route: Route
  handleRouteSelection: () => void
  isSelected?: boolean
  isPageFreight?: boolean
  freightTollValue?: number
  freight_distance?: string
  truckCategory?: number
  antt_value?: any
  freight_category?: string
  cargo_type?: string
}

export const RouteCard = ({
  id,
  onMouseEnter,
  route,
  handleRouteSelection,
  isSelected,
  isPageFreight = false,
  freightTollValue = undefined,
  freight_distance,
  truckCategory,
  antt_value,
  freight_category,
  cargo_type,
}: RouteCardProps): JSX.Element => {
  const hasTolls = route?.tolls?.length > 0
  const totalToll = calculateTotalToll(route.tolls, truckCategory)

  return (
    <Box
      key={id}
      rounded="md"
      onMouseEnter={onMouseEnter}
      border={isSelected ? '1px' : '0px'}
      borderColor={isSelected ? 'green.500' : 'transparent'}
      _hover={{
        cursor: 'pointer',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      onClick={() => {
        handleRouteSelection()
      }}
    >
      <Box mb={2} borderRadius="md" p={2} rounded="md">
        <Flex justifyContent="space-between">
          <RouteTitle
            provider={route.route_provider}
            id={id}
            tolls_count={route.tolls_count}
            distance={route.distance}
            duration={route.duration}
            total={freightTollValue || totalToll}
            freight_distance={freight_distance}
            antt_value={
              antt_value &&
              antt_value[freight_category as string] &&
              antt_value[freight_category as string][truckCategory as number] &&
              antt_value[freight_category as string][truckCategory as number][cargo_type as string]
            }
          />
        </Flex>
        {!isPageFreight && (
          <Accordion
            allowToggle
            pt={2}
            px={0}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <AccordionItem border="0px" p={0}>
              <AccordionButton px={0}>
                <Box flex="1" textAlign="left" fontWeight="bold" color="gray.400">
                  {!hasTolls
                    ? 'Sem Pedágios'
                    : route.tolls.length === 1
                    ? 'Ver Pedágio'
                    : 'Ver Praças de Pedágios'}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              {hasTolls && (
                <AccordionPanel pb={4} px={0}>
                  <Accordion allowToggle>
                    {route.tolls.map(toll => (
                      <AccordionItem border="0px" key={toll.nome}>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Pedágio: {toll.nome}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          <p>Valor: R$ {toll.tarifa[2]?.toFixed(2)}</p>
                          <p>Concessionária: {toll.concessionaria}</p>
                          <p>
                            Rodovia: {toll.rodovia} - KM {toll.km}
                          </p>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        )}
      </Box>
    </Box>
  )
}
