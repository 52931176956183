import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { addMinutes, format, formatDistance } from 'date-fns'
import localeBR from 'date-fns/locale/pt-BR'
import { RiCaravanLine, RiTruckLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { queryClient } from '../../config/react-query'
import { UpdateContactByClientModal } from '../../pages/clients/edit/UpdateContactByClientModal'
import { useGetFreightHistory } from '../../services/endpoints/freights/getHistoryFreight'
import { FormattedOneFreight } from '../../services/endpoints/freights/getOneFreight'
import { calculateTripDuration } from '../../services/utils/calculateTripDuration'
import { buonnyItemTypesOptions } from '../../services/utils/getBuonnyItemTypes'
import parseCurrency from '../../services/utils/parseCurrency'

interface FreightInfosProps {
  freight: FormattedOneFreight
}

export function FreightInfos({ freight }: FreightInfosProps): JSX.Element {
  const {
    onClose: onCloseUpdateContact,
    isOpen: isOpenUpdateContact,
    onOpen: onOpenUpdateContact,
  } = useDisclosure()

  const buonny_cargo_type = buonnyItemTypesOptions.find(i => i.value === freight.buonny_cargo_type)

  let calcSlaCollection = ''
  let calcSlaDelivery = ''
  const { data: history } = useGetFreightHistory(freight.id)
  const statusCollection = history?.find(h => h.status === 'collecting')
  if (statusCollection && statusCollection.created_at_original && freight?.estimated_collection_date) {
    calcSlaCollection = formatDistance(
      new Date(String(statusCollection?.created_at_original)),
      new Date(String(freight?.estimated_collection_date)),
      { locale: localeBR },
    )
  }

  const statusDelivery = history?.find(h => h.status === 'delivered')
  if (statusDelivery && statusDelivery.created_at_original && freight?.delivered_cargo_at) {
    calcSlaDelivery = formatDistance(
      new Date(String(statusDelivery?.created_at_original)),
      new Date(String(freight?.delivered_cargo_at)),
      { locale: localeBR },
    )
  }

  let deliveryDate
  let tripDuration
  if (freight.distance) {
    const date = new Date()
    date.setHours(12)
    tripDuration = calculateTripDuration(freight.distance, 60, date)
    if (freight.collect_cargo_at) {
      deliveryDate = new Date(freight.collect_cargo_at)
      tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)

      deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
    }
  }

  return (
    <>
      <SimpleGrid minChildWidth="300px" spacing="4">
        <WrapItem>
          <Text color="gray.400">Cliente:</Text>
          <Tooltip label="Ir para página do cliente">
            <Box>
              <Text as={Link} to={`/clients/show/${freight.client_id}`} fontWeight="medium" ml="1">
                {freight.client.name}
              </Text>
            </Box>
          </Tooltip>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Nº ref cliente:</Text>
          {freight.client_ref_type && freight.client_ref_number && (
            <Text fontWeight="medium" ml="1">
              {`${freight.client_ref_type?.toUpperCase()}-${freight.client_ref_number}`}
            </Text>
          )}
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Tipo:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.formatted_type}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Operador:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.creator?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Vendedor:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.seller?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Carga:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.cargo?.name}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Tipo de carga:</Text>
          <Text fontWeight="medium" ml="1">
            {buonny_cargo_type?.label}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Valor (NF):</Text>
          <Text fontWeight="medium" ml="1">
            {freight.cargo_value}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Itens obrigatórios:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.required_items}
          </Text>
        </WrapItem>

        {/* <WrapItem>
          <Text color="gray.400">Categoria:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.cargo?.category?.name}
          </Text>
        </WrapItem> */}

        <WrapItem>
          <Text color="gray.400">Peso:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.cargo?.weight} kg
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Exige rastreamento?:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.cargo_require_tracker}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Preço sugerido: </Text>
          <Text fontWeight="medium" color="green.400" ml="1">
            {`${freight.formatted_suggested_price} / ${freight.formatted_suggested_price_type}`}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Preço acordado: </Text>
          <Text fontWeight="medium" color="orange.400" ml="1">
            {freight.formatted_agreed_price &&
              `${freight.formatted_agreed_price} / ${freight.formatted_suggested_price_type}`}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Data:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.created_at}
          </Text>
        </WrapItem>

        <WrapItem>
          <Text color="gray.400">Prev. coleta:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.collect_cargo_at && format(new Date(freight.collect_cargo_at), 'dd/MM/yyyy  HH:mm')}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text color="gray.400">Previsão de entrega:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.delivered_cargo_at && format(new Date(freight.delivered_cargo_at), 'dd/MM/yyyy HH:mm')}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text color="gray.400">Prazo de entrega (algoritmo):</Text>
          <Text fontWeight="medium" ml="1">
            {tripDuration?.relativeTripDuration}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text color="gray.400">Valor do cliente:</Text>
          <Text fontWeight="medium" ml="1">
            {freight.service_price && parseCurrency(freight.service_price)}
          </Text>
        </WrapItem>
        <Wrap ml="1">
          {freight.vehicle_categories?.map(category => (
            <Tag key={category.id} size="lg" borderRadius="full">
              <TagLeftIcon as={RiTruckLine} />
              <TagLabel>{category.name}</TagLabel>
            </Tag>
          ))}
        </Wrap>
        <Wrap ml="1">
          {freight.vehicle_bodies?.map(body => (
            <Tag key={body.id} size="lg" borderRadius="full">
              <TagLeftIcon as={RiCaravanLine} />
              <TagLabel>{body.name}</TagLabel>
            </Tag>
          ))}
        </Wrap>
      </SimpleGrid>
      {freight?.estimated_collection_date && freight?.estimated_delivery_date && (
        <>
          <Divider my="4" />
          <SimpleGrid minChildWidth="300px" spacing="100">
            <Box>
              <Heading size="md" fontWeight="normal" mb="6">
                Estimativas
              </Heading>

              <Flex justifyContent="space-between" direction={['column', 'row']} gridGap={6}>
                <Box>
                  <WrapItem>
                    <Text color="gray.400">Estimativa de coleta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {format(new Date(String(freight.estimated_collection_date)), 'dd/MM/yyyy - HH:mm')}
                    </Text>
                  </WrapItem>

                  <WrapItem>
                    <Text color="gray.400">SLA de coleta:</Text>
                    <Text fontWeight="medium" ml="1">
                      {calcSlaCollection}
                    </Text>
                  </WrapItem>
                </Box>

                <Box>
                  <WrapItem>
                    <Text color="gray.400">Estimativa de entrega:</Text>
                    <Text fontWeight="medium" ml="1">
                      {format(new Date(String(freight.estimated_delivery_date)), 'dd/MM/yyyy - HH:mm')}
                    </Text>
                  </WrapItem>

                  <WrapItem>
                    <Text color="gray.400">SLA de entrega:</Text>
                    <Text fontWeight="medium" ml="1">
                      {calcSlaDelivery}
                    </Text>
                  </WrapItem>
                </Box>
              </Flex>
            </Box>
          </SimpleGrid>
        </>
      )}

      <Divider my="4" />
      <SimpleGrid minChildWidth="300px" spacing="6">
        <Box>
          <Flex direction={['column', 'row']} gridGap={2} justify="space-between" align="center">
            <Heading size="md" fontWeight="normal" mb="6">
              Dados do solicitante
            </Heading>
            <Button
              colorScheme="orange"
              onClick={() => {
                onOpenUpdateContact()
              }}
              size="xs"
            >
              Editar
            </Button>
            {freight?.client_contact_id && (
              <UpdateContactByClientModal
                onClose={() => {
                  onCloseUpdateContact()
                  queryClient.invalidateQueries('freight')
                }}
                isOpen={isOpenUpdateContact}
                contactByClient_id={freight.client_contact_id}
              />
            )}
          </Flex>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Nome: </Text>
              <Text fontWeight="medium" ml="1">
                {freight?.client_contact?.name}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Telefone: </Text>
              <Text fontWeight="medium" ml="1">
                {freight?.client_contact?.phone}
              </Text>
            </WrapItem>
          </Wrap>

          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Telefone 2: </Text>
              <Text fontWeight="medium" ml="1">
                {freight?.client_contact?.phone2}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Email: </Text>
              <Text fontWeight="medium" ml="1">
                {freight?.client_contact?.email}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Cargo: </Text>
              <Text fontWeight="medium" ml="1">
                {freight?.client_contact?.role}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>
      </SimpleGrid>

      <Divider my="4" />
      <SimpleGrid minChildWidth="300px" spacing="6">
        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço Origem
          </Heading>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Cidade: </Text>
              <Text fontWeight="medium" ml="1">
                {freight.origin_city}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Endereço: </Text>
              <Text fontWeight="medium" ml="1">
                {freight.origin_complement}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>

        <Box>
          <Heading size="md" fontWeight="normal" mb="6">
            Endereço Destino
          </Heading>
          <Wrap spacing="4">
            <WrapItem>
              <Text color="gray.400">Cidade: </Text>
              <Text fontWeight="medium" ml="1">
                {freight.destination_city}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Endereço: </Text>
              <Text fontWeight="medium" ml="1">
                {freight.destination_complement}
              </Text>
            </WrapItem>
          </Wrap>
        </Box>
      </SimpleGrid>

      {freight.collect_complement && freight.delivery_complement && (
        <>
          <Divider my="4" />
          <SimpleGrid minChildWidth="300px" spacing="6">
            <Box>
              <Heading size="md" fontWeight="normal" mb="4">
                Endereço de coleta
              </Heading>
              <Box>
                <Text color="gray.400">Endereço: </Text>
                <Text fontWeight="medium" whiteSpace="pre-wrap">
                  {freight.collect_complement}
                </Text>
              </Box>
            </Box>

            <Box>
              <Heading size="md" fontWeight="normal" mb="4">
                Endereço de entrega
              </Heading>
              <Box>
                <Text color="gray.400">Endereço: </Text>
                <Text fontWeight="medium" whiteSpace="pre-wrap">
                  {freight.delivery_complement}
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
        </>
      )}
    </>
  )
}
