import { Flex, Text } from '@chakra-ui/react'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { colorStatusFreight } from '../../../services/utils/colorStatusFreight'
import { statusFreightNumber } from '../../../services/utils/statusFreightNumber'
import { CheckFreightIsExpired } from '../../CheckFreightIsExpired'

type FreightStatusProps = {
  freight: FormattedAllFreights
}

const FreightStatus = ({ freight }: FreightStatusProps): JSX.Element => {
  return (
    <Flex justifyContent="space-between" alignItems="center" pt="1">
      <Text fontSize="xs" fontWeight="bold" color={colorStatusFreight(freight.formatted_status)}>
        {statusFreightNumber(freight.formatted_status)} {freight.formatted_status}
      </Text>

      <CheckFreightIsExpired freight={freight} />
    </Flex>
  )
}

export default FreightStatus
