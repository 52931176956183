import axios from 'axios'

const { REACT_APP_BASE_API, REACT_APP_BASE_API_SERVER } = process.env

const publicApi = axios.create({
  baseURL: REACT_APP_BASE_API,
})

const publicApiServer = axios.create({
  baseURL: REACT_APP_BASE_API_SERVER,
})

export { publicApi, publicApiServer }
