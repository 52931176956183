import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'
import { invalidateCache } from './putRiskAnalysis'

export interface ForceStatusRiskAnalysis {
  id: string
  status: string
  reason?: string
  depends_on_check?: boolean
  consultation_code?: string
}

export async function forceStatusRiskAnalysis({
  id,
  ...data
}: ForceStatusRiskAnalysis): Promise<AxiosResponse<ResponseApiType<RiskAnalysisType>>> {
  const riskAnalysisUpdated = await apiServer.patch(`/force-status-risk-analysis/${id}`, data)
  if (riskAnalysisUpdated) await invalidateCache()
  return riskAnalysisUpdated
}

export function useForceStatusRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, ForceStatusRiskAnalysis, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, ForceStatusRiskAnalysis, unknown> {
  return useMutation(async (data: ForceStatusRiskAnalysis) => forceStatusRiskAnalysis(data), options)
}
