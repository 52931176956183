import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'

export interface UpdateAttachToTrailer {
  id: string
  attachment_file: File
  type?: string
  name?: string
  description?: string
  trailer_id?: string
}

async function updateAttachmentToTrailer({ id, attachment_file, trailer_id }: UpdateAttachToTrailer) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  if (trailer_id) formData.append('trailer_id', trailer_id)
  formData.append('type', 'crlv')
  formData.append('name', 'CRLV do reboque')
  return apiServer.patch(`/trailers/attachments/${id}`, formData)
}

export function useUpdateAttachmentToTrailer(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateAttachToTrailer, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, UpdateAttachToTrailer, unknown> {
  return useMutation(async (data: UpdateAttachToTrailer) => updateAttachmentToTrailer(data), options)
}
