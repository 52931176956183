import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { VehicleType } from '../../types'

export async function detachTrailerFromVehicle(
  vehicle_id: string,
  trailer_id: string,
): Promise<AxiosResponse<VehicleType>> {
  return apiServer.patch('/detach-trailer', {
    vehicle_id,
    trailer_id,
  })
}

interface DetachTrailerParams {
  vehicle_id: string
  trailer_id: string
}

export function useDetachTrailer(
  options?: UseMutationOptions<AxiosResponse, unknown, DetachTrailerParams, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, DetachTrailerParams, unknown> {
  return useMutation(
    async (data: DetachTrailerParams) => detachTrailerFromVehicle(data.vehicle_id, data.trailer_id),
    options,
  )
}
