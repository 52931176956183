import { LoadingRequestStatusEnum } from '../types/EnumTypes'

export function formatLoadingRequestStatus(status: LoadingRequestStatusEnum): string {
  const loadingRequestStatus = {
    awaiting_analysis: 'Aguardando análise',
    rejected: 'CO Reprovado',
    awaiting_cf: 'Aguardando CF',
    awaiting_payment: 'Aguardando Pagamento',
    paid: 'Pagamento Realizado',
    finished: 'Finalizado',
  }

  return loadingRequestStatus[status]
}
