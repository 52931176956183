/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { GrAttachment } from 'react-icons/gr'
import * as yup from 'yup'
import { Ability } from '../../../../components/ability'
import { AttachmentIcon } from '../../../../components/AttachmentIcon'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { useCan } from '../../../../hooks/useCan'
import { useCteSelectOption } from '../../../../services/endpoints/datamex/cteSelectOption'
import { useDeleteAttachmentFromFinancial } from '../../../../services/endpoints/freights/financial/attachments/deleteAttachment'
import { useUploadAttachmentsToFreightExpense } from '../../../../services/endpoints/freights/financial/attachments/uploadAttachment'
import { useGetOneFreightExpense } from '../../../../services/endpoints/freights/financial/getOneFinancialTitle'
import { CreateFreightExpense } from '../../../../services/endpoints/freights/financial/PostFinancialTitle'
import { usePutFreightExpense } from '../../../../services/endpoints/freights/financial/putFinancialTitle'
import { FreightExpensesAttachmentsTypeEnum } from '../../../../services/types/EnumTypes'
import { FormCreateOrEditFreightExpense } from '../FormCreateOrEditFinancialTitle'
import { TableListFinancialHistory } from '../list/financial-history/TableListFinancialHistory'
import ActionButtonFreightExpense from './button-actions'
import { ForceStatusFinancialModal } from './ForceStatusFinancialModal'

interface EditFreightExpenseModalProps {
  isOpen: boolean
  onClose: () => void
  freightId: string
  freight_expense_id: string
}

interface CreateOrEditFreightExpenseFormData extends Omit<CreateFreightExpense, 'bank'> {
  bank?: AutocompleteOption
  attachment_file: FileList
}

export function EditFreightExpenseModal({
  isOpen,
  onClose,
  freight_expense_id,
  freightId,
}: EditFreightExpenseModalProps): JSX.Element {
  const financialRole = useCan({ roles: ['financial_accounts_payable', 'admin'] })
  const [status, setStatus] = useState<string>()
  const [observation, setObservation] = useState<string>()
  const [attachment_file, setAttachment_file] = useState<FileList>()
  const [attachmentNoRequired, setAttachmentNoRequired] = useState(true)
  const toast = useToast()
  const { data: freightExpense, refetch } = useGetOneFreightExpense(freight_expense_id)
  const [freight_id, setFreight_id] = useState<string>(freightId)
  useEffect(() => {
    if (freightExpense?.freight_id) setFreight_id(freightExpense.freight_id)
    if (freightId) setFreight_id(freightId)
  }, [freightExpense, freightId])

  const { data: cte, isLoading } = useCteSelectOption(freight_id)
  const editFreightExpenseFormSchema = yup.object().shape({
    cte_id: yup
      .string()
      .required(
        'Campo CTE é obrigatório! Caso não haja CTE cadastrada no frete, cadastre antes de continuar.',
      ),
    number: yup.string(),
    document_recipient: yup.string().required('Campo obrigatório'),
    name_recipient: yup.string().required('Campo obrigatório'),
    observation: yup.string(),
    pix: yup.string(),
    bank: yup
      .object()
      .shape({})
      .when('pix', {
        is: undefined,
        then: yup.object().shape({}).required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
        otherwise: yup.object().shape({}),
      }),
    account_type: yup.string().when('pix', {
      is: undefined,
      then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.string(),
    }),
    agency: yup.string().when('pix', {
      is: undefined,
      then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.string(),
    }),
    account: yup.string().when('pix', {
      is: undefined,
      then: yup.string().required("Campo obrigatório caso o campo 'PIX' não for preenchido!"),
      otherwise: yup.string(),
    }),
    value: yup.string().required('Campo obrigatório!'),
    event: yup.string().required('Campo obrigatório!'),
    attachment_file: yup.string().when(() => {
      if (
        freightExpense?.attachments_operational &&
        freightExpense?.attachments_operational?.length > 0 &&
        attachmentNoRequired
      ) {
        return yup.string()
      }
      return yup.string().required('Nenhum arquivo foi selecionado! Selecione um ou mais arquivos')
    }),
  })
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(editFreightExpenseFormSchema),
  })

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = usePutFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-financial-title-attachments')
      queryClient.invalidateQueries('list-all-financial-title-history')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('financial-title')
    },
  })

  const {
    mutateAsync: mutateAsyncUpload,
    error: uploadError,
    isLoading: isLoadingUpload,
  } = useUploadAttachmentsToFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-financial-title-attachments')
      queryClient.invalidateQueries('list-all-financial-title-history')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('financial-title')
    },
  })

  const { mutateAsync: mutateAsyncDelete } = useDeleteAttachmentFromFinancial({
    onSuccess: () => {
      queryClient.invalidateQueries('list-all-financial-title-attachments')
      queryClient.invalidateQueries('list-all-financial-title-history')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('financial-title')
    },
  })

  const handleDeleteAttachment = async (idAttach: string) => {
    await mutateAsyncDelete(idAttach)
  }

  const handleEditFreightExpense: SubmitHandler<CreateOrEditFreightExpenseFormData> = async data => {
    const { bank } = data
    try {
      await mutateAsyncUpdate({
        ...data,
        bank: bank?.label || undefined,
        id: freight_expense_id,
        freight_id,
        observation,
        status,
        attachment_file: undefined,
      })

      if (attachment_file && attachment_file.length > 0) {
        try {
          for (let i = 0; i < attachment_file.length; i++) {
            await mutateAsyncUpload({
              freight_expense_id,
              type: financialRole
                ? FreightExpensesAttachmentsTypeEnum.FINANCIAL
                : FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
              file: attachment_file[i],
            })
          }
        } catch (error) {
          toastError({ toast, error: error || uploadError })
        }
      }

      toast({
        title: 'Status alterado com sucesso!',
        status: 'success',
        position: 'top-right',
      })

      setAttachment_file(undefined)

      refetch()

      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  const handlePaidFreightExpense = async (paid: boolean) => {
    if (
      !freightExpense?.cte ||
      (freightExpense?.attachments_operational && freightExpense?.attachments_operational.length <= 0)
    ) {
      try {
        await mutateAsyncUpdate({
          id: freight_expense_id,
          freight_id,
          status: 'awaiting_proof',
          observation:
            !freightExpense?.cte &&
            freightExpense?.attachments_operational &&
            freightExpense?.attachments_operational.length > 0
              ? 'Preencha o campo CTE para finalizar'
              : freightExpense?.attachments_operational &&
                freightExpense?.attachments_operational.length <= 0 &&
                freightExpense?.cte
              ? 'Preencha o campo Anexo para finalizar'
              : 'Preencha os campos CTE e Anexo para finalizar',
          paid,
          payment_at: new Date(),
        })

        if (attachment_file && attachment_file.length > 0) {
          try {
            for (let i = 0; i < attachment_file.length; i++) {
              await mutateAsyncUpload({
                freight_expense_id,
                type: financialRole
                  ? FreightExpensesAttachmentsTypeEnum.FINANCIAL
                  : FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
                file: attachment_file[i],
              })
            }
          } catch (error) {
            toastError({ toast, error: error || uploadError })
          }
        }

        toast({
          title: 'Título pago com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
        toast({
          status: 'warning',
          title: 'Existem pendências e elas serão sinalizadas ao operador',
          position: 'top-right',
          isClosable: true,
          duration: 7000,
        })

        refetch()
        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    } else {
      try {
        await mutateAsyncUpdate({
          id: freight_expense_id,
          freight_id,
          paid,
          status: 'finished',
          payment_at: new Date(),
        })
        if (attachment_file && attachment_file.length > 0) {
          try {
            for (let i = 0; i < attachment_file.length; i++) {
              await mutateAsyncUpload({
                freight_expense_id,
                type: financialRole
                  ? FreightExpensesAttachmentsTypeEnum.FINANCIAL
                  : FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
                file: attachment_file[i],
              })
            }
          } catch (error) {
            toastError({ toast, error: error || uploadError })
          }
        }
        toast({
          title: 'Título pago com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        refetch()
        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    }
  }

  const handleFinishFreightExpense = async () => {
    if (
      !freightExpense?.cte_id ||
      (freightExpense?.attachments_operational && freightExpense?.attachments_operational.length <= 0)
    ) {
      toast({
        status: 'error',
        title: 'Não foi possível finalizar com pendencias, verifique os campos CTE e anexo e tente novamente',
        position: 'top-right',
        isClosable: true,
      })
      return
    }

    try {
      await mutateAsyncUpdate({
        id: freight_expense_id,
        freight_id,
        status: 'finished',
      })
      if (attachment_file && attachment_file.length > 0) {
        try {
          for (let i = 0; i < attachment_file.length; i++) {
            await mutateAsyncUpload({
              freight_expense_id,
              type: financialRole
                ? FreightExpensesAttachmentsTypeEnum.FINANCIAL
                : FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
              file: attachment_file[i],
            })
          }
        } catch (error) {
          toastError({ toast, error: error || uploadError })
        }
      }
      toast({
        title: 'Título finalizado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      refetch()
      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    refetch()
    setAttachment_file(undefined)
  }, [isOpen, refetch])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dados da despesa</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleEditFreightExpense)}>
          <ModalBody>
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              freightExpense && (
                <FormCreateOrEditFreightExpense
                  setAttachment_file={setAttachment_file}
                  cte={cte}
                  setObservation={setObservation}
                  data={freightExpense}
                  formState={formState}
                  setValue={setValue}
                />
              )
            )}
            <Grid templateColumns="repeat(12, 1fr)" gap="3">
              <GridItem colSpan={12}>
                <Divider my={3} />
              </GridItem>

              <GridItem colSpan={[12, 6]}>
                <Heading size="md" fontWeight="normal" mb="2">
                  Comprovantes do financeiro
                </Heading>
                <Flex>
                  {freightExpense?.attachments_financial &&
                    freightExpense?.attachments_financial.length > 0 &&
                    freightExpense?.attachments_financial.map(
                      attach =>
                        attach.attachment_file_url && (
                          <AttachmentIcon
                            attachment_file_url={attach.attachment_file_url}
                            attach_id={attach.id}
                            attach={attach}
                            handleDeleteAttachment={handleDeleteAttachment}
                          />
                        ),
                    )}
                </Flex>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <Heading size="md" fontWeight="normal" mb="2">
                  Comprovantes do operacional
                </Heading>

                <Flex>
                  {freightExpense?.attachments_operational &&
                    freightExpense?.attachments_operational.length > 0 &&
                    freightExpense?.attachments_operational.map(
                      attach =>
                        attach.attachment_file_url && (
                          <AttachmentIcon
                            attachment_file_url={attach.attachment_file_url}
                            attach_id={attach.id}
                            attach={attach}
                            handleDeleteAttachment={handleDeleteAttachment}
                          />
                        ),
                    )}
                </Flex>
              </GridItem>
            </Grid>
          </ModalBody>

          <ModalFooter flexDirection="column">
            <Flex justify="space-between" direction="column">
              <Divider my={4} />

              <Flex gridGap={4}>
                {freightExpense && (
                  <ForceStatusFinancialModal onCloseModal={onClose} freight_expense_id={freightExpense.id} />
                )}
                <Ability module="spotHub" action="show-cancel-freight-expense">
                  {freightExpense?.status &&
                    ['pending', 'rejected', 'awaiting_proof'].includes(freightExpense?.status) &&
                    !freightExpense.paid && (
                      <ActionButtonFreightExpense
                        title="Cancelar"
                        freight_id={freight_id}
                        color="red"
                        onCloseModal={onClose}
                        freight_expense_id={freightExpense.id}
                        status="canceled"
                      />
                    )}
                </Ability>

                <Ability module="spotHub" action="show-pending-freight-expense">
                  {freightExpense?.status &&
                    ['rejected', 'awaiting_proof'].includes(freightExpense.status) && (
                      <Button
                        type="submit"
                        isLoading={isLoadingUpdate || isLoadingUpload}
                        colorScheme="orange"
                        onClick={() => {
                          setStatus('pending')
                        }}
                      >
                        Solicitar revisão
                      </Button>
                    )}
                </Ability>

                <Ability module="spotHub" action="show-save-attachments-freight-expense">
                  {freightExpense?.status === 'pending' && (
                    <Button
                      colorScheme="green"
                      type="submit"
                      isLoading={isLoadingUpdate || isLoadingUpload}
                      isDisabled={!attachment_file}
                      onClick={() => {
                        setAttachmentNoRequired(false)
                        setStatus(undefined)
                      }}
                      leftIcon={<GrAttachment />}
                    >
                      Salvar anexos
                    </Button>
                  )}
                </Ability>

                <Ability module="spotHub" action="show-finish-freight-expense">
                  {freightExpense?.status &&
                    ['pending', 'awaiting_proof'].includes(freightExpense.status) &&
                    freightExpense.paid && (
                      <Button
                        colorScheme="blue"
                        isLoading={isLoadingUpdate || isLoadingUpload}
                        onClick={() => {
                          handleFinishFreightExpense()
                        }}
                      >
                        Finalizar
                      </Button>
                    )}
                </Ability>

                <Ability module="spotHub" action="show-handle-paid-freight-expense">
                  {freightExpense?.status &&
                    ['pending'].includes(freightExpense.status) &&
                    !freightExpense.paid && (
                      <Button
                        colorScheme="green"
                        isLoading={isLoadingUpdate || isLoadingUpload}
                        onClick={() => {
                          handlePaidFreightExpense(true)
                        }}
                      >
                        Pagar
                      </Button>
                    )}
                </Ability>

                <Ability module="spotHub" action="show-request-proof-freight-expense">
                  {freightExpense?.status === 'pending' && freightExpense.id && (
                    <ActionButtonFreightExpense
                      title="Solicitar comprovação"
                      freight_id={freight_id}
                      color="orange"
                      onCloseModal={() => {
                        onClose()
                        refetch()
                      }}
                      freight_expense_id={freightExpense.id}
                      status="awaiting_proof"
                    />
                  )}
                </Ability>

                <Ability module="spotHub" action="show-reject-freight-expense">
                  {freightExpense?.status === 'pending' && !freightExpense.paid && (
                    <ActionButtonFreightExpense
                      title="Rejeitar"
                      freight_id={freight_id}
                      color="purple"
                      onCloseModal={onClose}
                      freight_expense_id={freightExpense.id}
                      status="rejected"
                    />
                  )}
                </Ability>

                {freightExpense?.status === 'finished' && freightExpense.paid && (
                  <Button
                    colorScheme="green"
                    type="submit"
                    isLoading={isLoadingUpdate || isLoadingUpload}
                    isDisabled={!attachment_file}
                    onClick={() => {
                      setAttachmentNoRequired(false)
                      setStatus(undefined)
                    }}
                    leftIcon={<GrAttachment />}
                  >
                    Salvar anexos
                  </Button>
                )}
              </Flex>
            </Flex>

            <Grid templateColumns="repeat(12, 1fr)" gap="3">
              {freightExpense && (
                <>
                  <GridItem colSpan={12}>
                    <Divider my={3} />
                  </GridItem>

                  <GridItem colSpan={12}>
                    <Heading size="md" fontWeight="normal" mb="6">
                      Histórico
                    </Heading>
                  </GridItem>

                  <GridItem colSpan={12}>
                    <TableListFinancialHistory freight_expense_id={freight_expense_id} />
                  </GridItem>
                </>
              )}
            </Grid>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
