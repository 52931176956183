import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import * as yup from 'yup'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { FormattedQuotation } from '../../../../services/endpoints/freights/getAllQuotations'
import {
  usePostRoutePrices,
  ICreateRoutePrices,
} from '../../../../services/endpoints/motorists/route-prices/postRoutePrices'
import { Form } from './Form'

interface changeStatusProps {
  isOpen: boolean
  onClose: () => void
  quotation: FormattedQuotation
}

const FormSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  phone: yup.string().min(11, 'Telefone incompleto').required('Campo obrigatório'),
  value: yup.number().required('Campo obrigatório'),
  vehicle_category_id: yup.string().required('Campo obrigatório'),
  vehicle_body_id: yup.string().required('Campo obrigatório'),
  source: yup.string().required('Campo obrigatório'),
  toll: yup.number(),
  motorist_id: yup.string(),
})

export function CreateRoutePricesModal({ isOpen, onClose, quotation }: changeStatusProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(FormSchema),
  })
  const { isSubmitting } = formState
  const createPrice = usePostRoutePrices({
    onSuccess: () => {
      queryClient.invalidateQueries('prices-motorists')
    },
  })

  const handleCreateRoutePrices: SubmitHandler<ICreateRoutePrices> = async data => {
    try {
      await createPrice.mutateAsync({ ...data, quotation_id: quotation.id })

      toast({
        title: 'Valor cadastrado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })

      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit(handleCreateRoutePrices)} noValidate>
        <ModalContent>
          <ModalHeader>Cadastrar valor do motorista</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form setValue={setValue} formState={formState} quotation={quotation} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiCheckLine} />}
              isLoading={isSubmitting}
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
