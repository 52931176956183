import { Flex, Icon, Text, Tooltip, useToast } from '@chakra-ui/react'
import React from 'react'
import { RiPhoneFill } from 'react-icons/ri'
import { copyToClipboard } from '../../../services/utils/copyToClipboard'
import { IconHasMonitoringApp } from '../../IconHasMonitoringApp'
import { IconHasMonitoringBuonny } from '../../IconHasMonitoringBuonny'
import { IconHasMonitoringTrackerDevices } from '../../IconHasMonitoringTrackerDevices'
import { IconHasMonitoringWhatsApp } from '../../IconHasMonitoringWhatsApp'

type FreightIconsProps = {
  motoristPhone?: string
  hasMonitoringApp?: boolean
  hasMonitoringBuonny?: boolean
  hasMonitoringTrackerDevices?: boolean
  hasMonitoringWhatsApp?: boolean
}

function FreightIcons({
  motoristPhone,
  hasMonitoringApp,
  hasMonitoringBuonny,
  hasMonitoringTrackerDevices,
  hasMonitoringWhatsApp,
}: FreightIconsProps): JSX.Element {
  const toast = useToast()
  return (
    <Flex justifyContent="flex-end" gridGap="2">
      <Tooltip hasArrow label="Copiar telefone do motorista">
        <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
          <Flex
            direction="row"
            cursor="pointer"
            onClick={() => {
              copyToClipboard(String(motoristPhone))
              toast({
                title: 'Telefone copiado com sucesso!',
                status: 'success',
                position: 'top-right',
                duration: 1000,
              })
            }}
          >
            <Icon as={RiPhoneFill} fontSize="md" mr="1" />
          </Flex>
        </Text>
      </Tooltip>
      <IconHasMonitoringApp has_monitoring_app={hasMonitoringApp} />

      <IconHasMonitoringWhatsApp has_monitoring_whatsapp={hasMonitoringWhatsApp} />

      <IconHasMonitoringBuonny has_monitoring_buonny={hasMonitoringBuonny} />

      <IconHasMonitoringTrackerDevices has_monitoring_tracker_devices={hasMonitoringTrackerDevices} />
    </Flex>
  )
}

export default FreightIcons
