import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'

interface SelectOptionGroup {
  id: string
  group: string
}

export async function listVehicleGroupsDatamex(): Promise<SelectOption[]> {
  const { data } = await apiServer.get<{ data: SelectOptionGroup[] }>(`/document-handler/vehicles/groups`)

  return data.data.map(group => {
    const label = group.group
    const value = group.id

    return { label, value }
  })
}
