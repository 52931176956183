/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import InteractionsTimelineType from '../../../types/InteractionsTimelineType'

async function fetchInteractionsTimeline(id: string): Promise<InteractionsTimelineType> {
  const { data: res } = await apiServer.get<ResponseApiType<InteractionsTimelineType>>(
    `/find-interaction-timeline/${id}`,
  )
  const { data } = res
  return data
}

export function useGetInteractionsTimeline(id: string): UseQueryResult<InteractionsTimelineType> {
  return useQuery(['show-interactions-timeline', id], () => fetchInteractionsTimeline(id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
