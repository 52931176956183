import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { AdvanceRequestType } from '../../types/AdvanceRequestType'
import { formatAdvanceRequestStatus } from '../../utils/formatAdvanceRequestStatus'

export type AdvanceRequestTypeFormatted = AdvanceRequestType & {
  formatted_status: string
}

type FindAdvanceRequestResponse = AdvanceRequestTypeFormatted

export type FilterAdvanceRequestsType = {
  id: string
}

async function findAdvanceRequest(params: FilterAdvanceRequestsType) {
  const { data: response } = await apiServer.get(`/advance-requests/find`, {
    params: {
      ...params,
    },
  })

  const { data } = response

  return {
    ...data,
    formatted_status: formatAdvanceRequestStatus(data.status),
    created_at: format(new Date(data.created_at), 'dd/MM/yy HH:mm'),
  }
}

export function useGetAdvanceRequest(
  params: FilterAdvanceRequestsType,
): UseQueryResult<FindAdvanceRequestResponse> {
  return useQuery(['advance-requests', params], () => findAdvanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
