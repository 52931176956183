import {
  Button,
  Center,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { GrDocumentPdf } from 'react-icons/gr'
import {
  RiArrowDownSLine,
  RiCurrencyLine,
  RiFileCopyLine,
  RiMoneyDollarCircleLine,
  RiTruckLine,
} from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { ChangeCteRelatedFreightModal } from '../../../pages/datamex/list/modals/ChangeCteRelatedFreightModal'
import { SetCteToRebillingModal } from '../../../pages/datamex/list/modals/SetCteToRebillingModal'
import { CteResponseFormatted } from '../../../services/endpoints/datamex/getCtes'
import { getPdfCteDatamex } from '../../../services/endpoints/datamex/getPdfCteDatamex'
import { useCreateReceivableBill } from '../../../services/endpoints/omie/createReceivableBill'
import { copyToClipboard } from '../../../services/utils/copyToClipboard'
import { Ability } from '../../ability'

interface ButtonsActionsProps {
  cte: CteResponseFormatted
}
export function ButtonsActions({ cte }: ButtonsActionsProps): JSX.Element {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] = useState(false)
  const toast = useToast()
  const cteIsAlreadyInOmie = (cteToVerify: CteResponseFormatted) => {
    if (cteToVerify.type === 'Anulação' || cteToVerify.status === 'Cancelamento') return true
    const cteEmissionDate = new Date(cteToVerify.emit_date).getTime()
    const initialIntegrationDate = new Date('2023-06-06').getTime()
    if (cteEmissionDate < initialIntegrationDate) return true
    return !!cteToVerify.omie_code
  }

  const { mutateAsync: createReceivableBill, isLoading: isLoadingCreateReceivableBill } =
    useCreateReceivableBill({
      onSuccess: () => {
        toast({
          title: 'Conta a receber lançada com sucesso!',
          status: 'success',
          position: 'top-right',
        })
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const generatePdfCte = async () => {
    setIsLoadingGeneratePdf(true)
    try {
      const pdfUrl = await getPdfCteDatamex(cte.key)
      if (pdfUrl) window.open(pdfUrl, '_blank')
      queryClient.invalidateQueries('list_ctes')
      queryClient.invalidateQueries('show_cte_datamex')
    } catch (error) {
      toastError({ toast, error })
    } finally {
      setIsLoadingGeneratePdf(false)
    }
  }

  const {
    isOpen: isOpenChangeRelatedFreightModal,
    onClose: onCloseChangeRelatedFreightModal,
    onOpen: onOpenChangeRelatedFreightModal,
  } = useDisclosure()

  const {
    isOpen: isOpenSetCteStatusToRebillingModal,
    onClose: onCloseSetCteStatusToRebillingModal,
    onOpen: onOpenSetCteStatusToRebillingModal,
  } = useDisclosure()

  return (
    <>
      {cte && (
        <>
          {isLoadingGeneratePdf ? (
            <Center justifyContent="center" alignItems="center">
              <Spinner size="sm" />
            </Center>
          ) : (
            <HStack spacing="2">
              <Menu>
                <MenuButton
                  as={Button}
                  size="xs"
                  variant="outline"
                  colorScheme="orange"
                  rightIcon={<Icon as={RiArrowDownSLine} />}
                >
                  Ações
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<RiFileCopyLine size="18" color="green" />}
                    onClick={() => {
                      copyToClipboard(cte.key)
                      toast({
                        title: 'Chave copiada com sucesso!',
                        status: 'success',
                        isClosable: true,
                        position: 'top',
                      })
                    }}
                  >
                    Copiar chave
                  </MenuItem>
                  <MenuItem
                    icon={<AiOutlineDownload size="18" color="red" />}
                    as={Link}
                    href={cte.xml_file_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Baixar XML
                  </MenuItem>

                  <Ability module="operation" action="generate-pdf-document-handler-cte">
                    {cte.pdf_file_url ? (
                      <MenuItem
                        icon={<GrDocumentPdf size="18" />}
                        as={Link}
                        href={cte.pdf_file_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Baixar PDF
                      </MenuItem>
                    ) : (
                      <MenuItem icon={<GrDocumentPdf size="18" />} onClick={async () => generatePdfCte()}>
                        Baixar PDF
                      </MenuItem>
                    )}
                  </Ability>

                  <Ability module="spotHub" action="show-create-receivable-bill">
                    <MenuItem
                      icon={<RiMoneyDollarCircleLine size="18" color="orange" />}
                      onClick={() => createReceivableBill(cte.key)}
                      isDisabled={isLoadingCreateReceivableBill}
                    >
                      Lançar no Omie
                    </MenuItem>
                  </Ability>

                  <Ability module="operation" action="chenge-freight-related-to-cte">
                    <MenuItem
                      icon={<RiTruckLine size="18" color="blue" />}
                      onClick={onOpenChangeRelatedFreightModal}
                    >
                      Mudar frete vinculado
                    </MenuItem>
                  </Ability>

                  <Ability module="operation" action="change-cte-status-to-rebilling">
                    <MenuItem
                      icon={<RiCurrencyLine size="18" color="purple" />}
                      onClick={onOpenSetCteStatusToRebillingModal}
                    >
                      Marcar como CTE de refaturamento
                    </MenuItem>
                  </Ability>
                </MenuList>
              </Menu>
              {!cteIsAlreadyInOmie(cte) && (
                <Ability module="spotHub" action="show-create-receivable-bill">
                  <Tooltip placement="top" hasArrow label="Lançar conta a receber no Omie">
                    <Button
                      size="xs"
                      colorScheme="yellow"
                      onClick={() => createReceivableBill(cte.key)}
                      isLoading={isLoadingCreateReceivableBill}
                    >
                      <Icon as={RiMoneyDollarCircleLine} fontSize={16} />
                    </Button>
                  </Tooltip>
                </Ability>
              )}
            </HStack>
          )}
        </>
      )}

      <ChangeCteRelatedFreightModal
        isOpen={isOpenChangeRelatedFreightModal}
        onClose={() => {
          onCloseChangeRelatedFreightModal()
        }}
        cte_id={cte.id}
      />

      <SetCteToRebillingModal
        isOpen={isOpenSetCteStatusToRebillingModal}
        onClose={() => {
          onCloseSetCteStatusToRebillingModal()
        }}
        cte_id={cte.id}
      />
    </>
  )
}
