import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toastError } from '../../config/error/toastError'
import { queryClient } from '../../config/react-query'
import { useGetUser } from '../../services/endpoints/users/getUser'
import { UpdateUserFormData, usePutUser } from '../../services/endpoints/users/updateUser'
import { UserForm, userFormSchema } from './show/UserForm'

interface UpdateUserModalProps {
  isOpen: boolean
  onClose: () => void
  user_id: string
}

export function UpdateUserModal({ isOpen, onClose, user_id }: UpdateUserModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(userFormSchema),
  })
  const { isSubmitting } = formState
  const updateUser = usePutUser({
    onSuccess: () => {
      queryClient.invalidateQueries('user')
    },
  })

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = async data => {
    try {
      await updateUser.mutateAsync({ ...data, id: user_id })
      onClose()
      toast({
        title: 'Usuário editado com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  const { data } = useGetUser(String(user_id))

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent p="5">
        <Heading size="lg" fontWeight="normal">
          Editar usuário
        </Heading>

        <Divider my="6" />

        <Box as="form" onSubmit={handleSubmit(handleUpdateUser)} noValidate>
          <UserForm formState={formState} setValue={setValue} userData={data} />
          <Flex mt="8" justify="flex-end">
            <HStack spacing="4">
              <Button onClick={onClose}>Cancelar</Button>

              <Button type="submit" colorScheme="orange" isLoading={isSubmitting}>
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
