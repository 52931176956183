import { FormattedQuotation } from '../../../../services/endpoints/freights'
import { Card } from '../CardDefaultProps'

interface KanbanCardProps {
  quotation: FormattedQuotation
}
export function KanbanCardOpen({ quotation }: KanbanCardProps): JSX.Element {
  return <Card quotation={quotation} />
}

export function KanbanCardInQuotation({ quotation }: KanbanCardProps): JSX.Element {
  return <Card quotation={quotation} />
}

export function KanbanCardPending({ quotation }: KanbanCardProps): JSX.Element {
  return <Card quotation={quotation} />
}

export function KanbanCardFinished({ quotation }: KanbanCardProps): JSX.Element {
  return <Card quotation={quotation} />
}

export function KanbanCardDisabled({ quotation }: KanbanCardProps): JSX.Element {
  return <Card quotation={quotation} />
}
