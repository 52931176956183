/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddLine, RiSearchLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { WaitQueryParams } from '../../../components/WaitQueryParams'
import { AutocompleteMultiAsync } from '../../../components/form/AutocompleteMultiAsync'
import { InputMask } from '../../../components/form/InputMask'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { VehiclesFiltersParams } from '../../../services/endpoints/vehicles/getAllVehicle'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../../../services/endpoints/vehicles/getVehicleCategories'
import { ListVehicleAccordion } from './ListVehicleAccordion'

export interface FilterVehiclesFormData {
  page?: number
  per_page?: number
  release_year?: number
  license_plate?: string
  vehicle_categories?: AutocompleteOption[]
  vehicle_bodies?: AutocompleteOption[]
}
export const filterVehiclesFormSchema = yup.object().shape({
  page: yup.number(),
  license_plate: yup.string(),
  per_page: yup.number(),
  vehicle_categories: yup.array().of(yup.object()),
  vehicle_bodies: yup.array().of(yup.object()),
})

export function AllVehicles(): JSX.Element {
  const toast = useToast()
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    license_plate?: string
    page?: string
    vehicle_categories?: string
    vehicle_bodies?: string
    release_year?: string
  }>()
  const history = useHistory()
  const bg = useColorModeValue('white', 'gray.800')
  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(filterVehiclesFormSchema),
  })
  const { errors, isSubmitting } = formState
  const [loadingQueryParams, setLoadingQueryParams] = useState(false)
  const [filters, setFilters] = useState<VehiclesFiltersParams>({
    page: 1,
  })

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilterVehicles: SubmitHandler<FilterVehiclesFormData> = async data => {
    const dataFilters = {
      page: data.page,
      per_page: data.per_page,
      release_year: data.release_year,
      license_plate: data.license_plate,
      vehicle_categories: data.vehicle_categories?.map(i => i.value),
      vehicle_bodies: data.vehicle_bodies?.map(i => i.value),
    }
    setFilters(prev => ({ ...prev, ...dataFilters, page: data.page || 1 } as VehiclesFiltersParams))
    updateQueryParams({
      license_plate: data.license_plate,
      page: data.page?.toString() || '1',
      vehicle_categories: data.vehicle_categories && JSON.stringify(data.vehicle_categories),
      vehicle_bodies: data.vehicle_bodies && JSON.stringify(data.vehicle_bodies),
      release_year: data.release_year?.toString() || undefined,
    })
  }

  useEffect(() => {
    const licensePlateQuery = getParams('license_plate')
    const vehicleCategoriesQuery = getParams('vehicle_categories')
    const vehicleBodiesQuery = getParams('vehicle_bodies')
    const releaseYearQuery = getParams('release_year')
    const pageQuery = getParams('page')

    const loadingQuery = async () => {
      try {
        const parsedVehicleCategories = await JSON.parse(vehicleCategoriesQuery || '[]')
        const parsedVehicleBodies = await JSON.parse(vehicleBodiesQuery || '[]')
        setValue('license_plate', licensePlateQuery || undefined)
        setValue('vehicle_categories', parsedVehicleCategories || undefined)
        setValue('vehicle_bodies', parsedVehicleBodies || undefined)
        setValue('release_year', releaseYearQuery || undefined)
        setValue('page', pageQuery || 1)
        handleSubmit(handleFilterVehicles)()
      } catch (error) {
        toast({
          title: 'Erro ao carregar filtros',
          status: 'warning',
          description: 'Por favor, verifique os dados e tente novamente',
          isClosable: true,
          position: 'top-right',
        })
        updateQueryParams({
          license_plate: undefined,
          vehicle_categories: undefined,
          vehicle_bodies: undefined,
          release_year: undefined,
          page: undefined,
        })
      } finally {
        setLoadingQueryParams(false)
      }
    }

    if (pageQuery || licensePlateQuery || vehicleCategoriesQuery || vehicleBodiesQuery || releaseYearQuery) {
      setLoadingQueryParams(true)
      loadingQuery()
    }
  }, [])

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>
        <Divider my="4" />
        <WaitQueryParams condition={!loadingQueryParams}>
          <form onSubmit={handleSubmit(handleFilterVehicles)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="3" alignItems="center">
              <GridItem colSpan={[3, 3]}>
                <InputMask
                  name="license_plate"
                  mask="aaa9*99"
                  placeholder="Placa"
                  autoFocus
                  maskPlaceholder=""
                  initialValue={getValues('license_plate')}
                  error={errors.license_plate}
                  setValue={setValue}
                  uppercaseAll
                />
              </GridItem>
              <GridItem colSpan={[3, 3]}>
                <AutocompleteMultiAsync
                  name="vehicle_categories"
                  error={errors.vehicle_category}
                  setValue={setValue}
                  initialValue={getValues('vehicle_categories')}
                  loadOptions={getVehicleCategories}
                  placeholder="Categoria"
                />
              </GridItem>
              <GridItem colSpan={[3, 3]}>
                <AutocompleteMultiAsync
                  name="vehicle_bodies"
                  error={errors.vehicle_body}
                  initialValue={getValues('vehicle_bodies')}
                  setValue={setValue}
                  loadOptions={getVehicleBodies}
                  placeholder="Carrocerias"
                />
              </GridItem>
              <GridItem colSpan={[3, 3]}>
                <InputMask
                  mask="9999"
                  placeholder="Ano"
                  maskPlaceholder=""
                  name="release_year"
                  initialValue={getValues('release_year')}
                  error={errors.release_year}
                  setValue={setValue}
                />
              </GridItem>
            </Grid>
            <Flex mt="4" justify="flex-end">
              <Button size="sm" type="submit" colorScheme="blue" isLoading={isSubmitting}>
                <Icon as={RiSearchLine} mr="2" />
                Filtrar
              </Button>
            </Flex>
          </form>
        </WaitQueryParams>
      </Box>

      <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8" overflowX="auto">
        <Flex mb="6" justify="space-between" align="center">
          <Heading size="md" fontWeight="normal">
            Lista de veículos
          </Heading>

          <Button
            size="sm"
            colorScheme="orange"
            leftIcon={<Icon as={RiAddLine} />}
            onClick={() => {
              history.push(`/vehicles/create`)
            }}
          >
            Novo veículo
          </Button>
        </Flex>
        <Divider my="4" />
        <ListVehicleAccordion filters={filters} onChangePage={handleChangePage} />
      </Box>
    </Layout>
  )
}
