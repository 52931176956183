/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

import { apiServer } from '../../../api'

interface ICreateCostRiskAnalysis {
  risk_manager: string
  value: number
  risk_analysis_id: string | undefined
  freight_id: string | undefined
  motorist_id: string | undefined
}

async function createCostRiskAnalysis(data: ICreateCostRiskAnalysis) {
  return apiServer.post('/create-cost-risk-analysis', data)
}

export function usePostCostRiskAnalysis(
  options: UseMutationOptions<AxiosResponse, unknown, ICreateCostRiskAnalysis, unknown>,
) {
  return useMutation(async (data: ICreateCostRiskAnalysis) => createCostRiskAnalysis(data), options)
}
