import { useEffect } from 'react'
import { Redirect, Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useGlobalVariables } from '../contexts/GlobalVariables'

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType
  isPrivate?: boolean
  pageTitle: string
}

export function Route({
  component: Component,
  isPrivate = false,
  pageTitle,
  ...rest
}: RouteProps): JSX.Element {
  const { setPageTitle } = useGlobalVariables()
  const { user } = useAuth()

  useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle, setPageTitle])

  return (
    <ReactDOMRoute
      render={({ location }) => {
        if (isPrivate) {
          if (user) {
            return <Component />
          }
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
        if (user && location.pathname === '/') {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          )
        }

        return <Component />
      }}
      {...rest}
    />
  )
}
