/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

type Input = {
  id: string
  name?: string
  role?: string
  email?: string
  phone?: string
  phone2?: string
  client_id?: string
  last_monthly_nps_at?: string
  client_business_group_id: string
}

async function updateContactByClient({ id, ...data }: Input) {
  return apiServer.put(`/update-client-contacts/${id}`, data)
}

export function usePutContactByClient(options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>) {
  return useMutation(async (data: Input) => updateContactByClient(data), options)
}
