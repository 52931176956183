import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiFileFind } from 'react-icons/bi'
import { RiDeleteBin6Line, RiMoneyDollarCircleLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Ability } from '../../../../../components/ability'
import { AlertDialogConfirm } from '../../../../../components/AlertDialogConfirm'
import { toastError } from '../../../../../config/error/toastError'
import { queryClient } from '../../../../../config/react-query'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { useDeleteFreightExpense } from '../../../../../services/endpoints/freights/financial/deleteFinancialTitle'
import { FormattedFreightExpenseType } from '../../../../../services/endpoints/freights/financial/getAllFinancialTitles'
import { removeMainParam } from '../../../../../services/utils/format-url/removeMainParam'
import { setParamToUrl } from '../../../../../services/utils/format-url/setParamToUrl'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'
import { EditFreightExpenseModal } from '../../modals/EditFinancialTitleModal'

interface TableListProps {
  FreightExpense?: FormattedFreightExpenseType[]
}

export function TableListFreightExpense({ FreightExpense }: TableListProps): JSX.Element {
  const freight_expense_id = useQueryParams('freight_expense_id')
  const [freight_id, setFreight_id] = useState('')
  const toast = useToast()
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()
  const [idToDeleteFreightExpense, setIdToDeleteFreightExpense] = useState<string>()
  const [idToUpdateFreightExpense, setIdToUpdateFreightExpense] = useState<string>()
  const {
    isOpen: isOpenChangeStatus,
    onClose: onCloseChangeStatus,
    onOpen: onOpenChangeStatus,
  } = useDisclosure()

  const { mutateAsync: mutateAsyncDelete } = useDeleteFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('list-all-financial-title')
      queryClient.invalidateQueries('list-all-financial-title-history')
    },
  })

  useEffect(() => {
    if (freight_expense_id) {
      onOpenChangeStatus()
      setIdToUpdateFreightExpense(freight_expense_id)
    }
  }, [freight_expense_id, onOpenChangeStatus])

  const handleRemoveFreightExpense = async (financialTitleId: string) => {
    try {
      await mutateAsyncDelete(financialTitleId)
      toast({
        title: 'Título financeiro removido  com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <>
      {FreightExpense && FreightExpense.length > 0 ? (
        <Table mt={4} size="sm" d={displayAdaptable} overflowX="auto">
          <Thead>
            <Tr>
              <Th />
              <Th>Frete</Th>
              <Th>Filial</Th>
              <Th>CTE</Th>
              <Th>Fornecedor</Th>
              <Th>Evento</Th>
              <Th>Operador</Th>
              <Th>Data</Th>
              <Th>Status</Th>
              <Th>Pago</Th>
              <Th>Valor</Th>
              <Th>Ação</Th>
            </Tr>
          </Thead>
          <Tbody>
            {FreightExpense &&
              FreightExpense.map(item => {
                return (
                  <Tr key={item.id}>
                    <Td whiteSpace="nowrap">
                      <Flex gridGap={2} direction="column" alignItems="center">
                        <Ability module="financial" action="delete-freight-expense">
                          <Tooltip hasArrow label="Remover remover despesa">
                            <Box
                              cursor="pointer"
                              color="red"
                              onClick={() => {
                                onOpenConfirm()
                                setIdToDeleteFreightExpense(item.id)
                              }}
                            >
                              <Icon fontSize="2xl" as={RiDeleteBin6Line} />
                            </Box>
                          </Tooltip>
                        </Ability>

                        <Ability module="financial" action="find-freight-expense">
                          <Tooltip hasArrow label="Ver despesa">
                            <Box
                              cursor="pointer"
                              color="orange"
                              onClick={() => {
                                setFreight_id(item.freight_id)
                                onOpenChangeStatus()
                                setIdToUpdateFreightExpense(item.id)
                                setParamToUrl('freight_expense_id', item.id)
                              }}
                            >
                              <Icon fontSize="2xl" as={BiFileFind} />
                            </Box>
                          </Tooltip>
                        </Ability>
                      </Flex>
                    </Td>

                    <Td whiteSpace="nowrap">
                      <Link to={`/freights/show/${item.freight_id}`}>
                        <Text fontSize="xs">#{item?.freight?.freight_number}</Text>
                      </Link>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs">{item?.transporter}</Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs">{item?.cte?.nct}</Text>
                    </Td>

                    <Td whiteSpace="nowrap">
                      <Text fontSize="sm" fontWeight="bold">
                        {item?.name_recipient.split(' ')[0]}
                      </Text>
                      <Text fontSize="sm" fontWeight="bold">
                        {item?.name_recipient.split(' ')[1]}
                      </Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs">{item?.event.substring(0, 10)}</Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs">{item?.user?.name.split(' ')[0]}</Text>
                      <Text fontSize="xs">{item?.user?.name.split(' ')[1]}</Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs" textAlign="center">
                        {item?.created_at_formatted.split('-')[0]}
                      </Text>
                      <Text fontSize="xs" textAlign="center">
                        {item?.created_at_formatted.split('-')[1]}
                      </Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="md">
                        <Tag colorScheme={item.color_status} p="2" borderRadius="8" gridGap={2}>
                          <Text fontSize="xs" fontWeight="bold">
                            {item?.status}
                          </Text>
                        </Tag>
                      </Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Flex gridGap={2} direction="column" alignItems="center">
                        <Tag colorScheme={item?.paid === 'Sim' ? 'green' : 'red'} p="2" borderRadius="8">
                          <Text fontSize="xs" fontWeight="bold">
                            {item?.paid}
                          </Text>
                        </Tag>
                      </Flex>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Text fontSize="xs">{item?.valueFormatted}</Text>
                    </Td>
                    <Td whiteSpace="nowrap">
                      <Tooltip placement="top" hasArrow label="Lançar no Omie">
                        <Button size="xs" colorScheme="yellow">
                          <Icon as={RiMoneyDollarCircleLine} fontSize={16} />
                        </Button>
                      </Tooltip>
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      ) : (
        <Text textAlign="center">Nenhum registro encontrado</Text>
      )}
      {idToUpdateFreightExpense && (
        <EditFreightExpenseModal
          freightId={freight_id}
          isOpen={isOpenChangeStatus}
          onClose={() => {
            onCloseChangeStatus()
            removeMainParam('freight_expense_id')
          }}
          freight_expense_id={idToUpdateFreightExpense}
        />
      )}
      {idToDeleteFreightExpense && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title="Remover despesa"
          description="Tem certeza que deseja remover este despesa da lista?"
          onClose={onCloseConfirm}
          onConfirm={() => handleRemoveFreightExpense(idToDeleteFreightExpense)}
        />
      )}
    </>
  )
}
