import type { LatLngTuple } from 'leaflet'
import { useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

const MAX_WAYPOINTS = 25
interface City {
  lat: string | number
  lng: string | number
}

type Waypoint = { coordinates: number[] }
export interface RouteResult {
  coords: LatLngTuple[]
  metrics: {
    distanceFormatted: string
    durationFormatted: string
    distance: number
    duration: number
  }
}

const getDirections = async (waypoints: Waypoint[]): Promise<RouteResult> => {
  const { data: res } = await apiServer.post<ResponseApiType<RouteResult>>('/geolocation/directions-map', {
    waypoints,
  })
  const { coords, metrics } = res.data
  return { coords, metrics }
}

export async function fetchRoute(origin?: City, destination?: City): Promise<RouteResult> {
  if (origin && destination) {
    const directions = [origin, destination]
    const waypoints = directions.map(d => ({
      coordinates: [Number(d.lng), Number(d.lat)],
    }))
    return getDirections(waypoints)
  }
  return {
    coords: [],
    metrics: { distance: 0, duration: 0, distanceFormatted: '', durationFormatted: '' },
  }
}

export async function fetchRouteWithStops(stops: City[]): Promise<RouteResult> {
  if (stops.length < 2) {
    throw new Error('São necessárias pelo menos duas paradas para calcular uma rota.')
  }
  const waypoints = stops.map(stop => ({
    coordinates: [Number(stop.lng), Number(stop.lat)],
  }))

  try {
    return getDirections(waypoints)
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Erro ao buscar a rota com paradas: ${error.message}`)
    } else {
      throw new Error('Erro desconhecido ao buscar a rota com paradas')
    }
  }
}

export const fetchRoutesInSegments = async (places: City[]): Promise<RouteResult> => {
  if (places.length < 2) {
    throw new Error('São necessárias pelo menos duas paradas para calcular uma rota.')
  }

  const segmentCount = Math.ceil((places.length - 1) / (MAX_WAYPOINTS - 1))

  const segments = Array.from({ length: segmentCount }, (_, index) => {
    const start = index * (MAX_WAYPOINTS - 1)
    const end = start + MAX_WAYPOINTS
    return places.slice(start, Math.min(end, places.length))
  })

  const allResults = await Promise.all(segments.map(segment => fetchRouteWithStops(segment)))

  const coords = allResults.flatMap(result => result.coords)
  const distance = allResults.reduce((acc, result) => acc + Number(result.metrics.distance), 0)
  const duration = allResults.reduce((acc, result) => acc + Number(result.metrics.duration), 0)

  return {
    coords,
    metrics: {
      distanceFormatted: `${distance} Km`,
      durationFormatted: `${duration} minutos`,
      distance,
      duration,
    },
  }
}

export function useGetMapDirections(origin?: City, destination?: City): UseQueryResult<RouteResult> {
  return useQuery(['mapbox', origin, destination], () => fetchRoute(origin, destination), {
    staleTime: 1000 * 60 * 60, // 1 hour,
    retry: false,
  })
}

export function useFetchRoutesInSegments(places: City[]): UseQueryResult<RouteResult, Error> {
  const placesKey = useMemo(() => {
    return places.map(({ lat, lng }) => `${lat},${lng}`).join('|')
  }, [places])

  return useQuery(['fetchRoutesInSegments', placesKey], () => fetchRoutesInSegments(places), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 24,
    retry: false,
    enabled: places.length > 1,
  })
}
