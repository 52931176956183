import { Flex, Icon, Text, Tooltip, useColorMode } from '@chakra-ui/react'
import { RiBaseStationFill } from 'react-icons/ri'

interface IconHasMonitoringTrackerDevicesProps {
  has_monitoring_tracker_devices?: boolean
  color?: string
  fontSize?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'large'
    | 'medium'
    | 'small'
    | 'x-large'
    | 'x-small'
    | 'xx-large'
    | 'xx-small'
    | 'xxx-large'
}

export function IconHasMonitoringTrackerDevices({
  has_monitoring_tracker_devices,
  fontSize = 'md',
  color = 'linkedin.500',
}: IconHasMonitoringTrackerDevicesProps): JSX.Element {
  const { colorMode } = useColorMode()
  return (
    <>
      {has_monitoring_tracker_devices && (
        <Tooltip hasArrow label="Frete monitorado por dispositivos de rastreamento">
          <Flex>
            <Text fontSize="xs" color={colorMode === 'dark' ? 'white' : color} fontWeight="bold">
              <Flex direction="row">
                <Icon as={RiBaseStationFill} fontSize={fontSize} mr="1" />
              </Flex>
            </Text>
          </Flex>
        </Tooltip>
      )}
    </>
  )
}
