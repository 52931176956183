import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'

interface ITextAreaProps extends ChakraTextareaProps {
  name: string
  label?: string
  uppercaseFirst?: boolean
  uppercaseAll?: boolean
  setValue: UseFormSetValue<FieldValues>
  initialValue?: string | number | Date
  error?: FieldError
}

const TextAreaBase: ForwardRefRenderFunction<HTMLTextAreaElement, ITextAreaProps> = (
  {
    name,
    label,
    setValue,
    initialValue,
    isDisabled,
    error,
    isRequired,
    size = 'lg',
    uppercaseFirst,
    uppercaseAll,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    _hover,
    autoComplete = 'nope',
    onChange,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
      if (inputRef.current) {
        const inputValue = inputRef.current.querySelector('textarea')
        if (inputValue) {
          inputValue.value = initialValue.toString()
        }
      }
    }
  }, [setValue, name, initialValue])

  return (
    <FormControl isDisabled={isDisabled} ref={inputRef} isInvalid={!!error} isRequired={isRequired}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}

      <ChakraTextarea
        ref={ref}
        id={name}
        name={name}
        focusBorderColor={focusBorderColor}
        bgColor={bgColor || bg}
        variant={variant}
        size={size}
        _hover={_hover || { bgColor: bg }}
        _disabled={{
          opacity: 0.7,
          cursor: 'not-allowed',
        }}
        onFocus={e => {
          let { value } = e.target
          if (uppercaseFirst) {
            value = value.toLowerCase().replace(/(?:^|\s)\S/g, a => {
              return a.toUpperCase()
            })
            setValue(name, value)
            if (inputRef.current) {
              const inputDOM = inputRef.current.querySelector('textarea')
              if (inputDOM) {
                inputDOM.value = value
              }
            }
          }
          if (uppercaseAll) {
            value = value.toUpperCase()
            setValue(name, value)
            if (inputRef.current) {
              const inputDOM = inputRef.current.querySelector('textarea')
              if (inputDOM) {
                inputDOM.value = value
              }
            }
          }
        }}
        onChange={e => {
          let { value } = e.target
          if (uppercaseFirst) {
            value = value.toLowerCase().replace(/(?:^|\s)\S/g, a => {
              return a.toUpperCase()
            })
            setValue(name, value)
            if (inputRef.current) {
              const inputDOM = inputRef.current.querySelector('textarea')
              if (inputDOM) {
                inputDOM.value = value
              }
            }
          }
          if (uppercaseAll) {
            value = value.toUpperCase()
            setValue(name, value)
            if (inputRef.current) {
              const inputDOM = inputRef.current.querySelector('textarea')
              if (inputDOM) {
                inputDOM.value = value
              }
            }
          }
          setValue(name, value)
          if (onChange) onChange(e)
        }}
        isDisabled={isDisabled}
        disableAutocomplete
        disableGoogleAutocomplete
        autoComplete={autoComplete}
        {...rest}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
export const TextArea = forwardRef(TextAreaBase)
