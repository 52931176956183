import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'

export function formatStatusRiskAnalysis(status: string): string {
  if (status === 'awaiting') return 'Aguardando'
  if (status === 'awaiting_gr') return 'Aguardando GR'
  if (status === 'awaiting_approval') return 'A Aprovar'
  if (status === 'approved') return 'Aprovado'
  if (status === 'to_correct') return 'A Corrigir'
  if (status === 'to_check') return 'A Checar'
  if (status === 'disapproved') return 'Reprovado'
  if (status === 'canceled') return 'Cancelado'
  return ''
}

export function colorStatusRiskAnalysis(status: string): string {
  if (status === 'awaiting') return 'gray'
  if (status === 'awaiting_gr') return 'purple'
  if (status === 'awaiting_approval') return 'blue'
  if (status === 'to_correct') return 'orange'
  if (status === 'to_check') return 'yellow'
  if (status === 'approved') return 'green'
  if (status === 'disapproved') return 'red'
  if (status === 'canceled') return 'pink'
  return ''
}

export interface RiskAnalysisFormatted extends RiskAnalysisType {
  color_status: string
  depends_check: string
  costRiskAnalysisPaidOut: string
}

export type GetRiskAnalysisResponse = {
  total: number
  data: RiskAnalysisFormatted[]
}

export interface FiltersAllRiskAnalysisParams {
  user_id?: AutocompleteOption
  freight_id?: string
  motorist_id?: AutocompleteOption
  cpf?: string
  vehicle_id?: string
  freight_number?: string
  depends_on_check?: boolean
  status?: string
  trailer_id?: string
  vehicle_categories?: string[]
  vehicle_bodies?: string[]
  initial_date?: string
  final_date?: string
  page?: number
  per_page?: number
  unpaged?: boolean
  has_motorist?: boolean
  user_label?: string
  motorist_label?: string
}

function changeConsultationCode(consultationCode: string): string {
  if (consultationCode === 'PERFIL ADEQUADO AO RISCO') return 'ADEQUADO'
  if (consultationCode === 'PERFIL COM INSUFICIÊNCIA DE DADOS') return 'INSUF. DADOS'
  if (consultationCode === 'PERFIL EXPIRADO') return 'PERFIL EXP.'

  return consultationCode
}

export async function fetchAllRiskAnalysis(
  params: FiltersAllRiskAnalysisParams,
): Promise<GetRiskAnalysisResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<RiskAnalysisFormatted[]>>(
    '/list-risk-analysis',
    {
      params: {
        ...params,
        initial_date: params.initial_date,
        final_date: params.final_date,
      },
    },
  )

  const { total } = res.data

  const riskAnalysis = res.data.data.map((cargo: RiskAnalysisFormatted) => {
    return {
      ...cargo,
      created_at: format(new Date(cargo.created_at), 'dd/MM/yyyy - HH:mm'),
      color_status: colorStatusRiskAnalysis(cargo.status),
      status: formatStatusRiskAnalysis(cargo.status),
      depends_check: cargo.depends_on_check === true ? 'Sim' : cargo.depends_on_check === false ? 'Não' : '',
      consultation_code: changeConsultationCode(cargo.consultation_code),
    }
  })

  return { total, data: riskAnalysis }
}

export function useGetAllRiskAnalysis(
  params: FiltersAllRiskAnalysisParams,
): UseQueryResult<GetRiskAnalysisResponse> {
  return useQuery(['list-risk-analysis', params], () => fetchAllRiskAnalysis(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
