import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { GiVibratingSmartphone } from 'react-icons/gi'

interface IconHasMonitoringAppProps {
  has_monitoring_app?: boolean
  fontSize?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'large'
    | 'medium'
    | 'small'
    | 'x-large'
    | 'x-small'
    | 'xx-large'
    | 'xx-small'
    | 'xxx-large'
}

export function IconHasMonitoringApp({
  has_monitoring_app,
  fontSize = 'md',
}: IconHasMonitoringAppProps): JSX.Element {
  return (
    <>
      {has_monitoring_app && (
        <Tooltip hasArrow label="Frete monitorado pelo app">
          <Flex>
            <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
              <Flex direction="row">
                <Icon as={GiVibratingSmartphone} fontSize={fontSize} mr="1" />
              </Flex>
            </Text>
          </Flex>
        </Tooltip>
      )}
    </>
  )
}
