import { apiServer } from '../../api'
import { ClientBusinessGroup, ResponseApiType } from '../../types'
import ClientBusinessGroupSegment from '../../types/ClientBusinessGroupSegment'

type ClientBusinessGroupResponse = {
  label: string
  value: string
  group: {
    id: string
    name: string
    ddr?: boolean
    pocket_insurance?: string
    payment_deadline?: string
    effective_insurance?: string
  }
}

type ClientBusinessGroupSegmentResponse = {
  label: string
  value: string
}

export async function getClientBusinessGroupSegment(
  name?: string,
): Promise<ClientBusinessGroupSegmentResponse[]> {
  const { data } = await apiServer.get<ResponseApiType<ClientBusinessGroupSegment[]>>(
    '/clients/client-business-group/segments',
    {
      params: {
        name,
      },
    },
  )

  return data.data.map(segment => {
    return {
      label: segment.name,
      value: segment.id,
    }
  })
}

export async function getClientBusinessGroup(name?: string): Promise<ClientBusinessGroupResponse[]> {
  const { data } = await apiServer.get<ResponseApiType<{ data: ClientBusinessGroup[] }>>(
    '/clients/client-business-group',
    {
      params: {
        name,
        unpaged: true,
      },
    },
  )

  return data.data.data.map(group => {
    return {
      label: group.name,
      value: group.id,
      group: {
        id: group.id,
        name: group.name,
        ddr: group.ddr,
        pocket_insurance: group.pocket_insurance,
        payment_deadline: group.payment_deadline,
        effective_insurance: group.effective_insurance,
      },
    }
  })
}
