import { apiServer } from '../../api'
import ResponseApiType from '../../types/ResponseApiType'

interface AnttValueParams {
  cargoType: string
  axesNumber: string
  distance: string
  isVehicleComposition: string
  isHighPerformance: string
  isReturnEmpty: string
}

interface AnttValueReturn {
  value: number
}

export async function getAnttValue(params: AnttValueParams): Promise<AnttValueReturn> {
  const { data: response } = await apiServer.get<ResponseApiType<AnttValueReturn>>(`/get-antt-value/`, {
    params,
  })

  return response.data
}
