/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from '../../api'

// NÃO MEXER, SOMENTE PAULO VITOR PODE
async function createReceivableBill(key: string) {
  return api.post(`/financial/receivable-bills/create-by-cte/${key}`)
}

export function useCreateReceivableBill(
  options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>,
) {
  return useMutation(async (key: string) => createReceivableBill(key), options)
}
