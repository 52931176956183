export const buonnyItemTypes = [
  'TIPO DE CARGA 1',
  'TIPO DE CARGA 2',
  'DIVERSOS',
  'ALUMÍNIO',
  'BEBIDAS',
  'ELETRO/ELETRÔNICOS',
  'COBRE',
  'ARROZ',
  'SOJA',
  'PAPEL',
  'BOBINAS DE AÇO',
  'CIGARROS',
  'MEDICAMENTOS',
  'CAFE',
  'PRODUTOS ALIMENTÍCIOS',
  'PRODUTOS FRIGORÍFICOS',
  'PRODUTOS QUÍMICOS',
  'AÇÚCAR',
  'BOBINAS',
  'ALGODÃO EM PLUMA',
  'LEITE',
  'CHAPAS DE AÇO',
  'PRODUTOS SIDERÚRGICOS',
  'ÓLEO DE SOJA',
  'SEMENTES',
  'POLIETILENO',
  'VERGALHÃO',
  'MAQUINAS EM GERAL',
  'BICARBONATO DE SÓDIO',
  'TRIGO',
  'PRODUTOS AGRÍCOLAS',
  'AÇO',
  'FERRO',
  'PRODUTOS DE HIGIENE E LIMPEZA',
  'TECIDOS',
  'CHAPAS DE MDF',
  'LAMINADOS',
  'CIMENTO',
  'TINTAS',
  'CARGA FRACIONADA',
  'OUTROS',
  'COURO',
  'ALGODÃO',
]

export const buonnyItemTypesOptions = buonnyItemTypes.map((type, index) => {
  return {
    label: type,
    value: String(index + 1),
  }
})
