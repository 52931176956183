import { getClientBusinessGroupSegment } from '../../../../services/endpoints/clients/getClientsBusinessGroupSegment'

export async function validateClientBusinessGroupSegmentOptions(name?: string): Promise<string | null> {
  const segments = await getClientBusinessGroupSegment(name)
  const alreadyExistsName = segments.find(segment => segment.label === name)

  if (alreadyExistsName) {
    return 'Já existe um segmento com este nome'
  }

  return null
}
