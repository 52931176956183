import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import * as yup from 'yup'

import { queryClient } from '../../config/react-query'

import { usePutMonitoringOccurence } from '../../services/endpoints/freights/putMonitoringOccurences'

import { IMonitoringOccurences } from '../../services/types/MonitoringOccurenceType'

import { Form } from './Form'

interface IUpdateMonitoringOccurencesProps {
  isOpen: boolean
  onClose: () => void
  monitoringOccurence: IMonitoringOccurences
}

const UpdateFormSchema = yup.object().shape({
  monitoring_type: yup.string().required('Campo obrigatório'),
  observation: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório'),
})

interface IUpdateMonitoringOccurence {
  monitoring_type: string
  observation: string
  reason: string
}

export function UpdateMonitoringOccurences({
  isOpen,
  onClose,
  monitoringOccurence,
}: IUpdateMonitoringOccurencesProps): JSX.Element {
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(UpdateFormSchema),
  })

  const { errors } = formState

  const toast = useToast()

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = usePutMonitoringOccurence({
    onSuccess: () => {
      queryClient.invalidateQueries('monitoring-occurrences')
    },
  })

  const handleUpdateMonitoringOccurence: SubmitHandler<IUpdateMonitoringOccurence> = async data => {
    try {
      await mutateAsyncUpdate({
        monitoring_occurence_id: monitoringOccurence.id,
        monitoring_type: data.monitoring_type,
        observation: data.observation,
        reason_id: data.reason,
      })

      onClose()
    } catch (err) {
      const error = err as AxiosError

      toast({
        status: 'error',
        title:
          error.response?.data.message ||
          'Não foi possível editar a ocorrência de monitoramento. Tente novamente mais tarde.',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit(handleUpdateMonitoringOccurence)} noValidate>
        <ModalContent>
          <ModalHeader>Editar ocorrência de monitoramento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form setValue={setValue} errors={errors} monitoringOccurence={monitoringOccurence} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiEditLine} />}
              isLoading={isLoadingUpdate}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
