import { AutocompleteOption } from '../../../components/form'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleCategoryType } from '../../types'

export async function getVehicleCategories(name?: string): Promise<AutocompleteOption[]> {
  const { data: res } = await apiServer.get<ResponseApiType<VehicleCategoryType[]>>(
    '/vehicles/categories/search',
    {
      params: {
        name,
      },
    },
  )

  return res.data.map(category => {
    const label = category.name
    const value = category.id
    const { has_trailer } = category
    const { axes } = category
    const { model } = category

    return { label, value, has_trailer, axes, model }
  })
}
