import { Flex, Box, Heading, IconButton, Icon, useBreakpointValue } from '@chakra-ui/react'
import { RiMenuLine } from 'react-icons/ri'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import { Logo } from './Logo'
import { Profile } from './Profile'

interface HeaderProps {
  title?: string
}

export function SimpleHeader({ title }: HeaderProps): JSX.Element {
  const { onOpen } = useSidebarDrawer()
  const isMediumVersion = useBreakpointValue({
    lg: true,
    md: true,
  })
  return (
    <Flex px="4" mt="3" alignItems="center" justifyContent="space-between">
      <Flex>
        {title && (
          <IconButton
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="link"
            mr="2"
            mt="2"
            onClick={onOpen}
          />
        )}

        <Logo />
      </Flex>
      {title && isMediumVersion && (
        <Box mr="150">
          <Heading as="h1">{title}</Heading>
        </Box>
      )}

      <Profile />
    </Flex>
  )
}
