import { Badge, Button, Icon, Text, Tooltip, useToast } from '@chakra-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { GrDocumentPdf } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { ContentBox } from '../../../../components/ContentBox/ContentBox'
import Table from '../../../../components/Table/Table'
import StateHandlerComponent from '../../../../components/UI/StateHandlerComponent'
import { toastError } from '../../../../config/error/toastError'
import { CiotFormatted, useGetCiots } from '../../../../services/endpoints/documents/ciot/list-ciots'
import formatCPFAndCNPJ from '../../../../services/utils/formatCPFAndCNPJ'
import { getCiotPdfByCiotNumber } from '../../../../services/utils/getCiotPdfByCiotNumber'
import { shortenName } from '../../../../services/utils/shortenName'
import { tableWidthQuery } from '../../../../services/utils/tableAdaptable'
import { QueryParams } from './ListCiots'

type RenderListCiotsProps = {
  filters: QueryParams
  onChangePage?: (page: number) => void
}

export const CiotTable = ({ filters, onChangePage }: RenderListCiotsProps): JSX.Element => {
  const toast = useToast()

  const redirectToNewPageWithPdf = useCallback(
    async (ciot: CiotFormatted): Promise<void> => {
      try {
        toast({
          title: 'carregando PDF!',
          status: 'info',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        })

        const url = await getCiotPdfByCiotNumber({
          ciot_number: ciot.ciot_number,
          contractor_id: ciot.contractor_id,
        })

        if (url) {
          window.open(url, '_blank')
        } else {
          toast({
            title: 'Este arquivo não está mais disponível!',
            status: 'warning',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          })
        }
      } catch (error: unknown) {
        toastError({ toast, error })
      }
    },
    [toast],
  )

  const getDoc = useCallback(
    (ciot: CiotFormatted) => {
      redirectToNewPageWithPdf(ciot)
    },
    [redirectToNewPageWithPdf],
  )

  const {
    data: ciots,
    isLoading,
    isError,
  } = useGetCiots(
    {
      page: filters?.page,
      freight_number: filters?.freight_number,
      ciot_number: filters?.ciot_number,
      owner_name: filters?.owner_name,
    },
    {
      keepPreviousData: true,
      onError: error => {
        toastError({ toast, error })
      },
    },
  )

  const data: CiotFormatted[] = useMemo(() => (ciots ? ciots.data : []), [ciots])

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'ciot_number',
          header: 'Número',
        },
        {
          accessorKey: 'ciot_status',
          header: 'Status',
          cell: ({ cell }) => {
            const status = cell.getValue<CiotFormatted['ciot_status']>()
            return <Badge colorScheme="gray">{status?.status || 'ABERTO'}</Badge>
          },
        },
        {
          accessorKey: 'freight',
          header: 'Frete',
          cell: ({ row }) => {
            const { cte } = row.original

            if (!cte?.freight?.freight_number) {
              return '--'
            }

            return (
              <Tooltip
                label={`Clique para ser direcionado ao frete #${cte?.freight.freight_number}`}
                hasArrow
                placement="top"
              >
                <Link to={`freights/show/${cte?.freight_id}`}>
                  <Text>#{cte?.freight.freight_number}</Text>
                </Link>
              </Tooltip>
            )
          },
        },
        {
          accessorKey: 'cte_number',
          header: 'cte',
        },
        {
          accessorKey: 'collect_order_number',
          header: 'Ord. de coleta',
        },
        {
          accessorKey: 'date_emission',
          header: 'Data Emissão',
        },
        {
          accessorKey: 'contractor_number',
          header: 'Spotx',
        },
        {
          accessorKey: 'hired',
          header: 'Proprietário',
          cell: ({ cell }) => {
            const hired = cell.getValue<CiotFormatted['hired']>()
            const displayName = !hired ? '--' : shortenName(hired.name)
            return (
              <Tooltip label={hired?.name} hasArrow placement="top">
                <Badge colorScheme="gray">{displayName}</Badge>
              </Tooltip>
            )
          },
        },
        {
          accessorKey: 'hired_doc.number',
          header: 'CPF/CNPJ',
          cell: ({ cell }) => {
            const number = cell.getValue<CiotFormatted['hired_doc']['number']>()
            return formatCPFAndCNPJ(number)
          },
        },
        {
          accessorKey: 'gross_ciot_value',
          header: 'Valor',
        },
        {
          accessorKey: 'actions',
          header: 'Ações',
          cell: ({ row }) => {
            return (
              <Tooltip label="Baixar CIOT em pdf" hasArrow placement="top">
                <Button
                  size="sm"
                  color="white"
                  colorScheme="orange"
                  rel="noreferrer"
                  onClick={() => getDoc(row.original)}
                >
                  <Icon as={GrDocumentPdf} fontSize="16" color="white" fill="white" />
                </Button>
              </Tooltip>
            )
          },
        },
      ] as ColumnDef<CiotFormatted, any>[],

    [getDoc],
  )

  return (
    <ContentBox w={tableWidthQuery} mt={4} borderRadius="8" shadow="md" minH="60vh">
      <StateHandlerComponent loading={isLoading} error={isError} hasData={!!ciots}>
        <Table
          data={data}
          columns={columns}
          onPageChange={newPage => {
            onChangePage?.(newPage)
          }}
          totalCountOfRegisters={ciots?.total || 0}
          currentPage={filters?.page}
        />
      </StateHandlerComponent>
    </ContentBox>
  )
}
