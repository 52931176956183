import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { MdPersonSearch } from 'react-icons/md'
import { CheckBuonny } from './CheckBuonny'

export function CheckMotoristBuonnyModal(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button size="sm" rightIcon={<MdPersonSearch size={20} />} colorScheme="green" onClick={() => onOpen()}>
        Consultar na buonny
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verificar risco na Buonny</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <CheckBuonny />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
