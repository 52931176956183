/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export interface ICreateRoutePrices {
  name: string
  phone?: string
  value: number
  source?: string
  toll?: number
  quotation_id: string
  motorist_id?: string
  vehicle_category_id: string
  vehicle_body_id: string
}

async function postRoutePrices(data: ICreateRoutePrices) {
  return apiServer.post('/create-route-prices', data)
}

export function usePostRoutePrices(
  options?: UseMutationOptions<AxiosResponse, unknown, ICreateRoutePrices, unknown>,
) {
  return useMutation(async (data: ICreateRoutePrices) => postRoutePrices(data), options)
}
