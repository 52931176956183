import { Box, Button, Divider, Flex, Heading, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { usePostLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PostLoadingRequest'
import { useUploadLoadingRequestAttachments } from '../../../../../services/endpoints/freights/loading-request/UploadLoadingRequestAttachments'
import { LoadingRequestAttachmentTypeEnum } from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateLoadingRequestForm } from '../forms/CreateOrUpdateLoadingRequestForm'

type CreateLoadingRequestType = {
  freight_id: string
  motorist: SelectOption
  motorist_vehicle_id: string
  cte_id: string
  discount_buonny: string
  reason: string
  type: string
  total_value: string
  total_gross: string
  origin?: string
  destination?: string
  beneficiary: SelectOption
  e_frete?: string
  payment_type: string
  pix?: string
  account_type?: string
  bank?: SelectOption
  agency?: string
  account?: string
  account_digit?: string
  attachment_file?: File[]
  observation?: string
}

const CreateLoadingRequestSchema = yup.object().shape({
  freight_id: yup.string().required('Campo obrigatório'),
  motorist: yup.object().shape({}).required('Campo obrigatório'),
  motorist_vehicle_id: yup.string().required('Campo obrigatório'),
  cte_id: yup.string(),
  discount_buonny: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  total_value: yup.string().required('Campo obrigatório'),
  total_gross: yup.string().required('Campo obrigatório'),
  origin: yup.string(),
  destination: yup.string(),
  beneficiary: yup.object().shape({}).required('Campo obrigatório'),
  e_frete: yup.string(),
  payment_type: yup.string().required('Campo obrigatório'),
  pix: yup.string().when('payment_type', {
    is: 'pix',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account_type: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  bank: yup
    .object()
    .shape({})
    .when('payment_type', {
      is: 'account',
      then: yup.object().shape({}).required('Campo obrigatório'),
      otherwise: yup.object().shape({}),
    }),
  agency: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account_digit: yup.string(),
  observation: yup.string(),
})

export function CreateLoadingRequest(): JSX.Element {
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const history = useHistory()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(CreateLoadingRequestSchema),
  })

  const { errors } = formState

  const { mutateAsync: createLoadingRequest, isLoading: isLoadingCreateLoadingRequest } =
    usePostLoadingRequest({
      onSuccess: () => {
        toast({
          title: 'Nova solicitação de carregamento criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const {
    mutateAsync: uploadLoadingRequestAttachments,
    isLoading: isLoadingUploadLoadingRequestAttachments,
  } = useUploadLoadingRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleCreateLoadingRequest: SubmitHandler<CreateLoadingRequestType> = async data => {
    try {
      if (data.freight_id) {
        const newLoadingRequest = await createLoadingRequest(data)

        if (newLoadingRequest && data.attachment_file && data.attachment_file.length > 0) {
          forEach(data.attachment_file, async file => {
            const formData = new FormData()

            formData.append('loading_request_id', newLoadingRequest.id)
            formData.append('type', LoadingRequestAttachmentTypeEnum.GENERIC)
            formData.append('attachment', file)
            await uploadLoadingRequestAttachments({ data: formData })
          })
        }

        history.push('/loading-requests')
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <Heading size="lg" fontWeight="normal" mb={4}>
          Nova despesa extra (OC)
        </Heading>

        <Divider my="4" />

        <form onSubmit={handleSubmit(handleCreateLoadingRequest)} noValidate>
          <CreateOrUpdateLoadingRequestForm setValue={setValue} errors={errors} />

          <Flex mt={4} justifyContent="flex-end">
            <Link to="/loading-requests">
              <Button mr={3}>Cancelar</Button>
            </Link>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingCreateLoadingRequest || isLoadingUploadLoadingRequestAttachments}
              isDisabled={isLoadingCreateLoadingRequest || isLoadingUploadLoadingRequestAttachments}
            >
              Cadastrar
            </Button>
          </Flex>
        </form>
      </Box>
    </Layout>
  )
}
