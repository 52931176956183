import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'

type ResponseApi = {
  data: number
}

type closureExpectationParams = {
  seller?: string
}

export async function fetchDailyClosureExpectation(params?: closureExpectationParams): Promise<number> {
  const { data } = await apiServer.get<ResponseApi>(`/daily-closure-expectation`, {
    params: {
      date: new Date(),
      seller: params?.seller ? params.seller : 'default',
    },
  })

  return data.data
}

export function useDailyClosureExpectation(data?: closureExpectationParams): UseQueryResult<number> {
  return useQuery(['get_daily_closure_expectation'], () => fetchDailyClosureExpectation(data))
}
