import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { Input } from '../../../../components/form'
import { toastError } from '../../../../config/error/toastError'
import { usePostValidateMotorist } from '../../../../services/endpoints/documents/validate-motorist/post-validate-motorist'

const schema = yup.object().shape({
  documentType: yup.string(),
  document: yup.string(),
  rntrc: yup.string().required('O RNTRC é obrigatório'),
  licensePlate: yup.string().required('A placa do veículo é obrigatória'),
})

interface IRntrcFormProps {
  cpf?: string
  cnpj?: string
  rntrc?: string
  licensePlate?: string
}

export default function RntrcForm({ cpf, cnpj, rntrc, licensePlate }: IRntrcFormProps): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const toast = useToast()
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [documentType, setDocumentType] = useState('CPF')

  const {
    mutate: postValidateMotorist,
    data: dataPostValidateMotorist,
    isLoading: isLoadingPostValidateMotorist,
  } = usePostValidateMotorist({
    onSuccess: () => {
      toast({
        title: 'Consulta feita com sucesso!',
        description: 'Dados consultados com sucesso.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const onSubmit = (data: any) => {
    postValidateMotorist(data)
  }

  useEffect(() => {
    if ((cpf || cnpj) && rntrc && licensePlate) {
      postValidateMotorist({ cpf, cnpj, rntrc, licensePlate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getColor = (message: string) => {
    if (message.includes('não está apto')) {
      return 'red'
    }
    if (message.includes('está apto')) {
      return 'green'
    }
    return 'black'
  }

  return (
    <Flex direction={['column', 'row']} gridGap={4} align="stretch" justify="space-between" wrap="wrap">
      <Box flex={1} gap={4} bg={bg} p={4} rounded="md" maxW="400px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.documentType}>
            <FormLabel htmlFor="documentType">Tipo de documento</FormLabel>
            <Select
              name="documentType"
              placeholder="Selecione"
              value={documentType}
              onChange={e => {
                setDocumentType(e.target.value)
                setValue('documentType', e.target.value)
              }}
              setValue={setValue}
            >
              <option value="CPF">CPF</option>
              <option value="CNPJ">CNPJ</option>
            </Select>
            <FormErrorMessage>{errors.documentType?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.document} mt={4}>
            <FormLabel htmlFor="document">
              <Flex>
                <Text mr={2}>{documentType}</Text>
              </Flex>
            </FormLabel>
            <Input
              setValue={setValue}
              name="document"
              initialValue={documentType === 'CPF' ? cpf : cnpj}
              placeholder={`Digite o ${documentType}`}
            />

            <FormErrorMessage>{errors.document?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.rntrc} mt={4}>
            <FormLabel htmlFor="rntrc">
              <Flex>
                <Text mr={2}>RNTRC</Text>
                <span style={{ color: 'red' }}>*</span>
              </Flex>
            </FormLabel>
            <Input setValue={setValue} name="rntrc" initialValue={rntrc} placeholder="Digite o RNTRC" />

            <FormErrorMessage>{errors.rntrc?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.licensePlate} mt={4}>
            <FormLabel htmlFor="licensePlate">
              <Flex>
                <Text mr={2}>Placa do veículo</Text>
                <span style={{ color: 'red' }}>*</span>
              </Flex>
            </FormLabel>
            <Input
              setValue={setValue}
              name="licensePlate"
              initialValue={licensePlate}
              placeholder="Digite a placa do veículo"
            />

            <FormErrorMessage>{errors.licensePlate?.message}</FormErrorMessage>
          </FormControl>

          <Button
            colorScheme="orange"
            size="lg"
            type="submit"
            leftIcon={isLoadingPostValidateMotorist ? <Spinner size="xs" /> : undefined}
            isLoading={isLoadingPostValidateMotorist}
            mt={4}
            w="100%"
          >
            <Text mr={2}> Enviar dados para consulta</Text>
            <RiSearchLine />
          </Button>
        </form>
      </Box>

      <Box
        flex={1}
        paddingLeft={[0, 4]}
        bg={bg}
        p={4}
        rounded="md"
        display={dataPostValidateMotorist ? 'block' : 'none'}
      >
        {dataPostValidateMotorist && (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Detalhes do Transportador
            </Text>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Detalhe</Th>
                  <Th>Informação</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <strong>Nome:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.transporter}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Status:</strong>
                  </Td>
                  <Td
                    style={{
                      color:
                        dataPostValidateMotorist.status === 'Ativo' ||
                        dataPostValidateMotorist.status === 'ATIVO'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {dataPostValidateMotorist.status}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>CNPJ/CPF:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.cnpj || dataPostValidateMotorist.cpf}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>RNTRC:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.rntrc}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Placa do Veículo:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.vehicle_license_plate}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Tipo de Veículo:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.vehicle_type}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Data de Abertura:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.opening_date}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Cidade:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.address_city}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Estado:</strong>
                  </Td>
                  <Td>{dataPostValidateMotorist.address_state}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <strong>Mensagem:</strong>
                  </Td>
                  <Td style={{ color: getColor(dataPostValidateMotorist.message) }}>
                    {dataPostValidateMotorist.message}
                  </Td>
                </Tr>
                {dataPostValidateMotorist.site_receipt && (
                  <Tr>
                    <Td>
                      <strong>Url do Recibo:</strong>
                    </Td>
                    <Td>
                      <Link href={dataPostValidateMotorist.site_receipt} isExternal>
                        Ver Recibo
                      </Link>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </VStack>
        )}
      </Box>
    </Flex>
  )
}
