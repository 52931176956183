import { format } from 'date-fns'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface GlobalVariablesProviderProps {
  children: ReactNode
}

interface GlobalVariablesData {
  pageTitle: string
  initial_date: string
  final_date: string
  setPageTitle: React.Dispatch<React.SetStateAction<string>>
  setInitialDate: React.Dispatch<React.SetStateAction<string>>
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
}

const GlobalVariables = createContext({} as GlobalVariablesData)

export function GlobalVariablesProvider({ children }: GlobalVariablesProviderProps): JSX.Element {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [initial_date, setInitialDate] = useState<string>(() => {
    const now = new Date()
    return format(now.setDate(now.getDate() - 30), 'yyyy-MM-dd')
  })
  const [final_date, setFinalDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })

  useEffect(() => {
    document.title = pageTitle
    setInitialDate(() => {
      const now = new Date()
      return format(now.setDate(now.getDate() - 30), 'yyyy-MM-dd')
    })
    setFinalDate(() => {
      const now = new Date()
      return format(now, 'yyyy-MM-dd')
    })
  }, [pageTitle])

  return (
    <GlobalVariables.Provider
      value={{
        pageTitle,
        initial_date,
        final_date,
        setPageTitle,
        setInitialDate,
        setFinalDate,
      }}
    >
      {children}
    </GlobalVariables.Provider>
  )
}

export function useGlobalVariables(): GlobalVariablesData {
  const context = useContext(GlobalVariables)
  if (!context) throw new Error('useGlobalVariables() must be used within GlobalVariablesProvider!')
  return context
}
