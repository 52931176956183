/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

export interface IncludeBlackList {
  entity_id?: string
  entity_type: 'motorist' | 'owner' | 'vehicle'
  reason: string
  cpf?: string
  cnpj?: string
  license_plate?: string
}

async function includeInBlackList(data: IncludeBlackList) {
  return apiServer.post('black-list', data)
}

export function usePostBlackList(
  options?: UseMutationOptions<AxiosResponse, unknown, IncludeBlackList, unknown>,
) {
  return useMutation(async (data: IncludeBlackList) => includeInBlackList(data), options)
}
