import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { RiCustomerService2Fill } from 'react-icons/ri'
import { searchClientsByName } from '../../services/endpoints/clients/searchClients'
import { getUserOperation } from '../../services/endpoints/users/getUserOperation'
import { Autocomplete, AutocompleteAsync, AutocompleteOption, SelectOption } from '../form'

interface OperatorAndClientSelector {
  handleSetCurrentUser: (userSelected: AutocompleteOption | undefined) => void
  handleSetCurrentClient: (userSelected: AutocompleteOption | undefined) => void
  userSelected?: AutocompleteOption
  clientSelected?: AutocompleteOption
  isOperator?: boolean
  setValue: (name: string, value: string) => void
}

const OperatorAndClientSelector = ({
  handleSetCurrentClient,
  handleSetCurrentUser,
  userSelected,
  clientSelected,
  isOperator,
  setValue,
}: OperatorAndClientSelector): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box>
      <Box display={{ base: 'block', md: 'none' }}>
        <Button variant="outline" colorScheme="gray" size="md" onClick={onOpen} borderColor="gray.400">
          <RiCustomerService2Fill />
        </Button>
      </Box>
      <Drawer isOpen={isOpen} placement="right" size="sm" onClose={onClose} trapFocus={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody pt="8" display="flex" flexDirection="column" gridGap="2">
            <Autocomplete
              defaultValue={userSelected?.label || ''}
              name="user_id"
              size="md"
              placeholder="Selecione o operador"
              onSelectOption={user => {
                handleSetCurrentUser(user)
              }}
              clearState={() => {
                handleSetCurrentUser(undefined)
              }}
              loadOptions={getUserOperation}
              setValue={setValue}
            />
            <AutocompleteAsync
              defaultValue={clientSelected?.label || ''}
              name="client_id"
              size="md"
              placeholder="Selecione o cliente"
              onSelectOption={client => {
                handleSetCurrentClient(client as SelectOption)
              }}
              clearState={() => {
                handleSetCurrentClient(undefined)
              }}
              loadOptions={searchClientsByName}
              setValue={setValue}
            />
          </DrawerBody>
          <DrawerFooter display="flex" justifyContent="flex-start" gridGap="2">
            <Button variant="outline" size="md" mr="2" onClick={onClose}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Flex gridGap="2" maxW="430px" display={{ base: 'none', md: 'flex' }}>
        <Box opacity={isOperator ? 0.5 : 1} pointerEvents={isOperator ? 'none' : 'auto'} w="100%">
          <Autocomplete
            defaultValue={userSelected?.label || ''}
            name="user_id"
            size="md"
            placeholder="Selecione o operador"
            onSelectOption={user => {
              handleSetCurrentUser(user)
            }}
            clearState={() => {
              handleSetCurrentUser(undefined)
            }}
            loadOptions={getUserOperation}
            setValue={setValue}
          />
        </Box>
        <AutocompleteAsync
          defaultValue={clientSelected?.label || ''}
          name="client_id"
          size="md"
          placeholder="Selecione o cliente"
          onSelectOption={client => {
            handleSetCurrentClient(client)
          }}
          clearState={() => {
            handleSetCurrentClient(undefined)
          }}
          loadOptions={searchClientsByName}
          setValue={setValue}
        />
      </Flex>
    </Box>
  )
}

export default OperatorAndClientSelector
