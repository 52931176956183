/* eslint-disable no-param-reassign */
import {
  Button,
  Center,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine, RiFileCopyLine } from 'react-icons/ri'
import { toastError } from '../../../config/error/toastError'
import { useAuth } from '../../../contexts/AuthContext'
import { apiServer } from '../../../services/api'
import { useIsBlockEntity } from '../../../services/endpoints/blocked-entities/isBlockEntity'
import { useGetOneFreight } from '../../../services/endpoints/freights'
import { putFreight, usePutFreight } from '../../../services/endpoints/freights/putFreight'
import { CargoType, CityType, VehicleBodyType, VehicleCategoryType } from '../../../services/types'
import { ClientContactType } from '../../../services/types/ClientType'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setFreightForm, setIsQuotation } from '../../../store/slices/freightSlice'
import { FreightAndQuotationForm, FreightFormData } from '../../operations/forms/FreightAndQuotationForm'
import { freightAndQuotationValidateSchema } from '../../operations/forms/FreightAndQuotationValidation'
import { usersFreightEditors } from '../utils/permissions'

export interface DateCompare {
  date?: Date | string
  name: string
}
export interface FreightProp {
  id?: string
  status?: string
  type: string | undefined
  transporter?: string
  is_round_trip?: boolean
  suggested_price?: number
  suggested_price_type?: string
  agreed_price?: number
  description?: string
  service_price?: number
  has_toll?: boolean
  formatted_received_at?: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  collect_cargo_at_pt_br?: string
  formatted_delivered_cargo_at?: string
  is_fretebras_sync?: boolean
  origin_complement?: string
  destination_complement?: string
  collect_complement?: string
  delivery_complement?: string
  client_contact_id?: string
  client: {
    id: string
    name: string
    nickname?: string
    client_business_group_id?: string
  }
  creator?: {
    id: string
    name: string
  }
  client_contact?: ClientContactType
  seller?: {
    id: string
    name: string
  }
  origin: CityType
  destination: CityType
  vehicle_categories?: Omit<VehicleCategoryType, 'fretebras_vehicle_type_id'>[]
  vehicle_bodies?: Omit<VehicleBodyType, 'fretebras_vehicle_body_id'>[]
  cargo?: Partial<CargoType>
  is_quotation?: boolean
  required_items?: string
  calculation_ref?: string
  quotation_id?: string
  sector?: string
  client_ref_type?: string
  client_ref_number?: string
}

interface EditOrDuplicateFreightModalProps {
  type: 'edit' | 'duplicate' | undefined
  freight_id: string
  isOpen: boolean
  onClose: () => void
}

const RenderModal = ({
  type,
  freight_id,
  isOpen,
  onClose,
}: EditOrDuplicateFreightModalProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { data: freight, isFetching } = useGetOneFreight({
    freight_id,
    relations: [
      'cargo',
      'client',
      'client_contact',
      'creator',
      'destination',
      'origin',
      'seller',
      'vehicle_categories',
      'vehicle_bodies',
    ],
  })
  const updateFreight = usePutFreight()
  const { user } = useAuth()

  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id: type === 'duplicate' ? undefined : freight_id,
  })

  const { freightForm, client_business_group_id } = useAppSelector(state => state.freightSlice)
  const toast = useToast()
  const { handleSubmit, setValue, formState, setError } = useForm({
    resolver: yupResolver(freightAndQuotationValidateSchema),
  })
  const dateNow = format(new Date(), "yyyy-MM-dd'T'HH:mm")
  function descriptionNoNumber(description: string): string | undefined {
    const text = String(description).split(' ')
    text.pop()
    return text.join(' ')
  }
  function freightNumber(description: string): string | undefined {
    const text = String(description).split(' ').pop()
    return text
  }

  useEffect(() => {
    if (freight) {
      dispatch(
        setFreightForm({
          id: freight.id,
          type: freight.type,
          transporter: freight.transporter,
          status: freight.status,
          service_price: freight.service_price,
          client_id: {
            label: freight.client?.nickname ? freight.client?.nickname : freight.client?.name,
            value: freight.client?.id,
            client_business_group_id: freight.client?.client_business_group_id,
          },
          client_contact_id: {
            label: freight.client_contact?.name || '',
            value: freight.client_contact?.id || '',
          },
          creator_id: {
            label: String(freight.creator?.name),
            value: String(freight.creator?.id),
          },
          seller_id: {
            label: String(freight.seller?.name),
            value: String(freight.seller?.id),
          },
          received_at: type === 'edit' ? freight.formatted_received_at : dateNow,
          link_motorist_at: freight.formatted_link_motorist_at,
          collect_cargo_at: type === 'edit' ? freight.input_collect_cargo_at : undefined,
          delivered_cargo_at: type === 'edit' ? freight.formatted_delivered_cargo_at : undefined,
          origin_id: {
            label: freight.origin.name,
            value: freight.origin.ibge_id,
          },
          destination_id: {
            label: freight.destination.name,
            value: freight.destination.ibge_id,
          },
          vehicle_categories: freight.vehicle_categories?.map(category => ({
            label: category.name,
            value: category.id,
          })),
          vehicle_bodies: freight.vehicle_bodies?.map(body => ({
            label: body.name,
            value: body.id,
          })),
          suggested_price: freight.suggested_price,
          agreed_price: freight.agreed_price,
          suggested_price_type: freight.suggested_price_type,
          has_toll: freight.has_toll,
          description: descriptionNoNumber(String(freight.description)),
          publish_on_fretebras: type === 'edit' ? freight.is_fretebras_sync : false,
          origin_complement: freight?.origin_complement,
          destination_complement: freight.destination_complement,
          collect_complement: freight.collect_complement,
          delivery_complement: freight.delivery_complement,
          required_items: freight.required_items,
          sector: freight.sector,
          client_ref_type: freight.client_ref_type,
          client_ref_number: freight.client_ref_number,
          client_contact: freight.client_contact && {
            client_id: freight.client_contact.client_id,
            name: freight.client_contact.name,
            email: freight.client_contact.email,
            id: freight.client_contact.id,
            phone: freight.client_contact.phone,
            role: freight.client_contact.role,
            client_business_group_id: freight.client_contact.client_business_group_id,
          },
          cargo: freight.cargo && {
            id: freight.cargo.id,
            cargo_category_fretebras_id: freight.cargo.cargo_category_fretebras_id,
            has_complement: freight.cargo.has_complement,
            name: freight.cargo.name,
            require_tracker: freight.cargo.require_tracker,
            depth: freight.cargo.depth ? Number(freight.cargo.depth) : undefined,
            value: freight.cargo.value ? Number(freight.cargo.value) : undefined,
            height: freight.cargo.height ? Number(freight.cargo.height) : undefined,
            weight: freight.cargo.weight ? Number(freight.cargo.weight) : undefined,
            width: freight.cargo.width ? Number(freight.cargo.width) : undefined,
          },
        }),
      )
    }
  }, [freight, freight_id, type, isOpen, dateNow, dispatch, client_business_group_id])

  const handleEditOrDuplicateFreight = useCallback<SubmitHandler<FreightFormData>>(
    async data => {
      if (data.received_at && data.collect_cargo_at) {
        if (data.received_at >= data.collect_cargo_at) {
          setError('collect_cargo_at', {
            message: 'A previsão de coleta não pode ser menor ou igual a data de solicitação!',
          })
          return
        }
        if (data.delivered_cargo_at && data.collect_cargo_at >= data.delivered_cargo_at) {
          setError('delivered_cargo_at', {
            message: 'A previsão de entrega não pode ser menor ou igual a previsão de coleta!',
          })
          return
        }
      }

      const freightFormData = {
        type: data.type,
        transporter: data.transporter,
        client_id: data.client_id?.value,
        client_contact_id:
          !data.client_contact?.switch_add_contact && data.client_contact?.client_contact_id?.value,
        received_at: data.received_at,
        delivered_cargo_at: data.delivered_cargo_at,
        collect_cargo_at: data.collect_cargo_at,
        creator_id: data.creator_id?.value,
        seller_id: data.seller_id?.value,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        suggested_price: data.suggested_price,
        suggested_price_type: data.suggested_price_type,
        agreed_price: data.agreed_price,
        service_price: data.service_price,
        has_toll: Boolean(data.has_toll),
        description: data.description,
        collect_complement: data.address_collect,
        delivery_complement: data.address_delivery,
        required_items: data.required_items,
        publish_on_fretebras: data.publish_on_fretebras,
        vehicle_categories_id: data.vehicle_categories?.map(i => i.value),
        vehicle_bodies_id: data.vehicle_bodies?.map(i => i.value),
        freight_vehicle_categories: freight?.id
          ? data.vehicle_categories?.map(i => ({
              vehicle_category_id: i.value,
              freight_id: freight?.id,
            }))
          : [],
        freight_vehicle_bodies: freight?.id
          ? data.vehicle_bodies?.map(i => ({
              vehicle_body_id: i.value,
              freight_id: freight?.id,
            }))
          : [],
        calculation_ref: freight?.calculation_ref || undefined,
        sector: data.sector,
        client_ref_type: data.client_ref_type,
        client_ref_number: data.client_ref_number,
        client_contact: data.client_contact?.switch_add_contact && {
          id: data.client_contact?.switch_add_contact ? undefined : data.client_contact_id?.value,
          client_contact_id: data.client_contact?.switch_add_contact
            ? undefined
            : data?.client_contact?.client_contact_id?.value,
          client_id: data.client_id?.value,
          name: data.client_contact?.name,
          email: data.client_contact?.email,
          phone: data.client_contact?.phone,
          phone2: data.client_contact?.phone2,
          role: data.client_contact?.role,
          client_business_group_id:
            freight?.client_contact?.client_business_group_id || client_business_group_id,
        },
        cargo: data.cargo && {
          id: type === 'edit' ? freight?.cargo?.id : undefined,
          name: data.cargo.name,
          cargo_category_fretebras_id: data.cargo.cargo_category_fretebras_id,
          width: data.cargo.width,
          height: data.cargo.height,
          depth: data.cargo.depth,
          weight: data.cargo.weight,
          value: data.cargo.value,
          require_tracker: Boolean(data.cargo.require_tracker),
          has_complement: Boolean(data.cargo.has_complement),
        },
      } as FreightFormData

      if (type === 'edit') {
        try {
          if (freight?.id) {
            await updateFreight?.mutateAsync({
              freight_id: freight?.id,
              ...freightFormData,
              creator_id: data.creator_id?.value,
              seller_id: data.seller_id?.value,
              description: `${String(data.description)} ${freightNumber(String(freight?.description))}`,
              should_alert_gr: usersFreightEditors.includes(user?.email) && isBlockedEntity,
              disable_entities_block: usersFreightEditors.includes(user?.email),
            } as putFreight)

            toast({
              title: 'Frete editado com sucesso!',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            })
            onClose()
          }
        } catch (error) {
          toastError({ toast, error })
        }
      } else {
        try {
          const link_quotation = freight?.quotation_id || undefined

          freightFormData.quotation_id = link_quotation

          const { data: res } = await apiServer.post(`/create-freight`, freightFormData)
          const newFreight = res.data

          toast({
            title: 'Frete criado com sucesso!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
          window.location.href = `/freights/show/${newFreight?.id}`
          onClose()
        } catch (error) {
          toastError({ toast, error })
        }
      }
    },
    [
      freight?.id,
      freight?.calculation_ref,
      freight?.client_contact?.client_business_group_id,
      freight?.cargo?.id,
      freight?.description,
      freight?.quotation_id,
      client_business_group_id,
      type,
      setError,
      updateFreight,
      user?.email,
      isBlockedEntity,
      toast,
      onClose,
    ],
  )

  useEffect(() => {
    dispatch(setIsQuotation(false))
  }, [dispatch, isOpen])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          type = undefined
          onClose()
        }}
        closeOnOverlayClick={false}
        size="5xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />

        <ModalContent>
          {isFetching ? (
            <Center justifyContent="center" h="100%">
              <Spinner />
            </Center>
          ) : (
            freight &&
            freightForm && (
              <form onSubmit={handleSubmit(handleEditOrDuplicateFreight)} noValidate>
                <ModalHeader fontSize="3xl">
                  {type === 'edit'
                    ? `Editar frete #${freight.freight_number}`
                    : `Duplicar a partir do frete #${freight.freight_number}`}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FreightAndQuotationForm
                    setValue={setValue}
                    formState={formState}
                    initialData={freightForm}
                    isEdit={type === 'edit'}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    colorScheme={type === 'edit' ? 'orange' : 'blue'}
                    leftIcon={<Icon as={type === 'edit' ? RiEditLine : RiFileCopyLine} />}
                    isLoading={formState.isSubmitting}
                  >
                    {type === 'edit' ? 'Editar' : 'Duplicar'}
                  </Button>
                </ModalFooter>
              </form>
            )
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export function EditOrDuplicateFreightModal({
  type,
  freight_id,
  isOpen,
  onClose,
}: EditOrDuplicateFreightModalProps): JSX.Element | null {
  return (
    <>
      {freight_id && isOpen && (
        <RenderModal freight_id={freight_id} type={type} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  )
}
