import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

type Params = {
  related_cte_key: string
  cte_id: string
}

export async function setCteToRebillingStatus({ related_cte_key, cte_id }: Params): Promise<boolean> {
  const { data } = await apiServer.patch(`/ctes/related-freight/${cte_id}`, {
    related_cte_key,
  })
  return data.data
}

export function useSetCteToRebillingStatus(
  options?: UseMutationOptions<boolean, unknown, Params, unknown>,
): UseMutationResult<boolean, unknown, Params, unknown> {
  return useMutation(async (params: Params) => setCteToRebillingStatus(params), options)
}
