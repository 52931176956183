import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ClientContactType } from '../../../types/ClientType'
import { ResponseListApiType } from '../../../types/ResponseApiType'
import { searchClientContacts } from './getAllContactsFromClient'

export interface ContactsFiltered {
  label: string
  value: string
  role: string
  id: string
  name: string
  email: string
  phone: string
  phone2?: string
  created_at: string
}

export const formatClientContacts = (contacts: ClientContactType[]): ContactsFiltered[] => {
  if (contacts && contacts.length > 0) {
    const formattedContacts = contacts.map(item => {
      const email = item.email ? `- ${item.email}` : ''
      return {
        label: `${item.name} - ${item.phone} ${email}`,
        value: item.id,
        role: item.role,
        id: item.id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        phone2: item.phone2,
        created_at: new Date(item.created_at).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),
      }
    })

    return formattedContacts
  }
  return []
}

export async function getContactsAutocomplete(name?: string): Promise<ContactsFiltered[]> {
  const contactsResponse = await searchClientContacts({ name })
  return formatClientContacts(contactsResponse.data)
}

export async function listClientContactsByBusinessGroupAutocomplete(
  client_business_group_id?: string,
): Promise<ContactsFiltered[]> {
  if (
    !client_business_group_id ||
    client_business_group_id === '' ||
    client_business_group_id === 'null' ||
    client_business_group_id === 'undefined'
  ) {
    return []
  }
  const { data: res } = await apiServer.get<ResponseListApiType<ClientContactType[]>>(
    'list-client-contacts',
    { params: { client_business_group_id, unpaged: true } },
  )
  return formatClientContacts(res.data.data)
}

export function useListClientContactsByBusinessGroup(
  client_business_group_id?: string,
): UseQueryResult<ContactsFiltered[]> {
  return useQuery(
    ['useListClientContactsByBusinessGroup', client_business_group_id],
    () => listClientContactsByBusinessGroupAutocomplete(client_business_group_id),
    {
      cacheTime: 0,
      enabled: !!client_business_group_id,
      refetchOnWindowFocus: false,
    },
  )
}
