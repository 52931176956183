import { SimpleGrid, WrapItem, Tag, TagLeftIcon, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react'
import { RiCaravanLine } from 'react-icons/ri'
import { VehicleResponseFormData } from '../../services/endpoints/vehicles/getVehicle'
import { VehicleType } from '../../services/types'
import { copyToClipboard } from '../../services/utils/copyToClipboard'

interface VehicleInfosProps {
  vehicle: VehicleType | VehicleResponseFormData
}
export function VehicleInfos({ vehicle }: VehicleInfosProps): JSX.Element {
  const toast = useToast()
  return (
    <SimpleGrid minChildWidth="250px" spacing="4">
      {/* {vehicle.type === 'trailer' ? (
        <>
          <WrapItem alignItems="center">
            <Text color="gray.400">Tipo:</Text>
            <Tag size="lg" borderRadius="full" ml="2">
              <TagLeftIcon as={RiCaravanLine} />
              <TagLabel>Reboque</TagLabel>
            </Tag>
          </WrapItem>
        </>
      ) : (
        <WrapItem alignItems="center">
          <Text color="gray.400">Categoria:</Text>
          <Tag size="lg" borderRadius="full" ml="2">
            <TagLeftIcon as={RiTruckLine} />
            <TagLabel>{vehicle.vehicle_category?.name}</TagLabel>
          </Tag>
        </WrapItem>
      )} */}

      <WrapItem alignItems="center">
        <Text color="gray.400">Carroceria:</Text>
        <Tag size="lg" borderRadius="full" ml="2">
          <TagLeftIcon as={RiCaravanLine} />
          <TagLabel>{vehicle.vehicle_body?.name}</TagLabel>
        </Tag>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Eixos:</Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.axes}
        </Text>
      </WrapItem>

      {vehicle?.license_plate && (
        <WrapItem>
          <Text color="gray.400">Placa tração:</Text>

          {vehicle.license_plate && (
            <Tooltip label="Clique para copiar placa" hasArrow>
              <Text
                cursor="pointer"
                onClick={() => {
                  copyToClipboard(String(vehicle.license_plate))
                  toast({
                    title: 'Copiado com sucesso!',
                    status: 'success',
                    position: 'top-right',
                    duration: 1000,
                  })
                }}
                fontWeight="medium"
                ml="1"
              >
                {vehicle.license_plate}
              </Text>
            </Tooltip>
          )}
        </WrapItem>
      )}

      <WrapItem>
        <Text color="gray.400">Tem rastreador?: </Text>
        {vehicle?.has_tracker ? (
          <Text fontWeight="medium" ml="1">
            Sim
          </Text>
        ) : (
          <Text fontWeight="medium" ml="1">
            Não
          </Text>
        )}
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Tem seguro?: </Text>
        {vehicle?.has_insurance ? (
          <Text fontWeight="medium" ml="1">
            Sim
          </Text>
        ) : (
          <Text fontWeight="medium" ml="1">
            Não
          </Text>
        )}
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Marca:</Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.brand}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Fabricação:</Text>
        <Text fontWeight="medium" ml="1">
          {vehicle.release_year}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Modelo:</Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.model}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">UF:</Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.license_uf}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Renavan: </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.renavam}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Chassi: </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.chassi}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">ANTT: </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.antt}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (m3): </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.capacity_m3}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (TARA): </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.capacity_tara}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Capacidade (kg): </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.capacity_kg}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Municipio: </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.city?.name}
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Cor: </Text>
        <Text fontWeight="medium" ml="1">
          {vehicle?.color}
        </Text>
      </WrapItem>
    </SimpleGrid>
  )
}
