import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import OwnerType from '../../types/OwnerType'

export async function fetchOwner(owner_id: string): Promise<OwnerType | undefined> {
  if (owner_id && owner_id !== 'undefined' && owner_id !== 'null') {
    const { data: response } = await apiServer.get<ResponseApiType<OwnerType>>(`find-owner`, {
      params: { id: owner_id },
    })
    const data = response.data as OwnerType

    return data
  }
  return undefined
}

export function useGetOwner(owner_id: string): UseQueryResult<OwnerType> {
  return useQuery(['owner', owner_id], () => fetchOwner(owner_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnMount: 'always',
  })
}
