/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input as ChakraInput,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiSearch2Line, RiSendPlane2Line, RiUploadCloudLine } from 'react-icons/ri'
import * as yup from 'yup'
import CrlvPlaceholderImg from '../../assets/crlv-placeholder.png'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputMask } from '../../components/form/InputMask'
import { Select } from '../../components/form/Select'
import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../components/form/types/SelectOption'
import { toastError } from '../../config/error/toastError'
import { queryClient } from '../../config/react-query'
import { useApiCarsRequestContext } from '../../contexts/ApiCarsRequestContext'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { usePostCrlvInformations } from '../../services/endpoints/documents/ocr/post-crl-informations'
import { usePostConvertPdfToImage } from '../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import { fetchTrailerByColumn } from '../../services/endpoints/trailers/getTrailerByColumn'
import { getVehicleBodies } from '../../services/endpoints/vehicles/getVehicleBodies'
import { TrailerType } from '../../services/types/TrailerType'
import { parseBase64ToImage } from '../../services/utils/parseImageToBase64'
import { ufOptions } from '../../services/utils/ufOptions'
import { useAppDispatch, useAppSelector } from '../../store'
import { setTrailerId, setTrailerFound } from '../../store/slices/vehicleSlice'
import { trailerSharedForm } from './validations'
import { setCrlvTrailerFile, setCrlvTrailerImg } from '../../store/slices/ocrCrlvSlice'
import { crlvStateType } from './form-vehicles/Form'
import { alterColorsGenderDatamex, vehicleColorsDatamex } from '../../services/utils/vehicleColorsDatamex'
import { capitalizeWord } from '../../services/utils/capitalizeWord'

export interface TrailerFormData {
  trailer?: {
    license_plate?: string
    license_uf?: string
    brand?: string
    model?: string
    release_year?: number
    model_year?: number
    renavam?: string
    chassi?: string
    antt?: string
    capacity_m3?: number
    capacity_tara?: number
    capacity_kg?: number
    vehicle_body_id: string
    city_id?: AutocompleteOption
    color?: string
    axes?: number
  }
}

interface TrailerFormProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  initialData?: TrailerType | null
  isEditTrailer?: boolean
  isLinkTrailer?: boolean
  renavamRef?: React.RefObject<HTMLInputElement>
  handleCheckTrailerExists?: () => Promise<void>
}

export function TrailerForm({
  setValue,
  formState,
  initialData,
  isEditTrailer,
  isLinkTrailer,
  renavamRef,
  handleCheckTrailerExists,
}: TrailerFormProps): JSX.Element {
  const toast = useToast()
  const convertImgToPdf = usePostConvertPdfToImage()
  const { isLoading, setLicensePlateTrailer, trailerApi } = useApiCarsRequestContext()
  const [license_plate_trailer, setLicense_plate_trailer] = useState('')
  const [vehicleBodies, setVehicleBodies] = useState<AutocompleteOption[]>()
  const [isOpenImageModal, setIsOpenImageModal] = useState(false)
  const dispatch = useAppDispatch()
  const { crlvTrailerImg } = useAppSelector(state => state.ocrCrlvSlice)

  const [crlvInfo, setCrlvInfo] = useState<crlvStateType>({
    releaseYear: undefined,
    axes: undefined,
    brandModelVersion: undefined,
    licensePlate: undefined,
    predominantColor: undefined,
    modelYear: undefined,
    renavam: undefined,
    brandName: undefined,
    chassis: undefined,
    vehicleCategory: undefined,
    capacity: undefined,
  })

  useEffect(() => {
    getVehicleBodies().then(bodies => setVehicleBodies(bodies))
  }, [])
  const { errors } = formState

  const onOpenImageModal = () => {
    setIsOpenImageModal(true)
  }

  const onCloseImageModal = () => {
    setIsOpenImageModal(false)
  }

  const {
    mutate: postCrlvInformations,
    data: crlvInformations,
    isLoading: isCrlvInformationsLoading,
  } = usePostCrlvInformations({
    onSuccess: () => {
      toast({
        title: 'Dados de OCR carregados com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleSubmit = async () => {
    try {
      postCrlvInformations(crlvTrailerImg)
    } catch (errorSubmit) {
      toast({
        title: 'Erro ao enviar informações',
        description: errorSubmit.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  useEffect(() => {
    if (crlvInformations) {
      setCrlvInfo({
        releaseYear: crlvInformations.manufactureYear,
        axes: crlvInformations.axes,
        brandModelVersion: crlvInformations.brandModelVersion,
        licensePlate: crlvInformations.licensePlate,
        predominantColor: crlvInformations.predominantColor,
        modelYear: crlvInformations.modelYear,
        renavam: crlvInformations.renavam,
        brandName: crlvInformations.brandName,
        chassis: crlvInformations.chassis,
        vehicleCategory: crlvInformations.vehicleCategory,
        capacity: crlvInformations.capacity,
      })
    }
  }, [crlvInformations])

  useEffect(() => {
    const asyncCrlvInfo = async () => {
      if (crlvInfo?.renavam) {
        if (handleCheckTrailerExists) await handleCheckTrailerExists()
        try {
          const findTrailer = await fetchTrailerByColumn({
            columnName: 'renavam',
            columnValue: crlvInfo.renavam,
          })
          if (findTrailer) {
            dispatch(setTrailerId(findTrailer.id))
            dispatch(setTrailerFound(findTrailer))
          }
        } catch (error) {
          dispatch(setTrailerId(''))
          dispatch(setTrailerFound(null))
        } finally {
          queryClient.invalidateQueries('vehicle')
        }
      }
    }
    asyncCrlvInfo()
  }, [crlvInfo?.renavam])

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap="4">
        <GridItem colSpan={[12, 12, 3]} rowSpan={[1, 1, 3]} direction="column" gap={6} w="full" mb={4}>
          <Flex direction="row" gap={6} w="full" wrap="wrap" height="272px" justify="center">
            <FormLabel htmlFor="img-preview-crlv-trailer2" cursor="pointer">
              {convertImgToPdf.isLoading ? (
                <Flex justify="center" align="center" h="24vh" w="100%">
                  <Heading size="md">Convertendo PDF...</Heading>
                </Flex>
              ) : (
                <Image
                  src={crlvTrailerImg || CrlvPlaceholderImg}
                  alt="CRLV"
                  maxH="24vh"
                  maxW="100%"
                  objectFit="cover"
                  borderRadius="md"
                  p={2}
                  onClick={() => onOpenImageModal()}
                  cursor="pointer"
                />
              )}
            </FormLabel>
            <ChakraInput
              id="img-preview-crlv-trailer2"
              name="img-preview-crlv-trailer2"
              type="file"
              display="none"
              onChange={async e => {
                const { files } = e.target
                if (files && files.length > 0) {
                  const file = files[0]
                  const isValidExtension = /\.(png|jpg|jpeg)$/i.test(file.name)
                  if (isValidExtension) {
                    dispatch(setCrlvTrailerFile(file))
                    const fileReader = new FileReader()

                    fileReader.onload = ev => {
                      const { result } = ev.target as FileReader
                      if (result) {
                        const imgData = result.toString()
                        dispatch(setCrlvTrailerImg(imgData))
                        toast({
                          title: 'Imagem carregada com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      } else {
                        toast({
                          title: 'Erro ao ler o arquivo!',
                          status: 'error',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    }
                    fileReader.readAsDataURL(file)
                  } else {
                    const isPdf = /\.pdf$/i.test(file.name)
                    if (isPdf) {
                      const pdfConverted = await convertImgToPdf.mutateAsync({ file, slicePdf: false })
                      const imageConvertedToFile = await parseBase64ToImage(pdfConverted.imageConverted)

                      if (pdfConverted) {
                        const imgData = pdfConverted.imageConverted
                        dispatch(setCrlvTrailerFile(imageConvertedToFile))
                        dispatch(setCrlvTrailerImg(imgData))
                        toast({
                          title: 'PDF do reboque convertido com sucesso!',
                          status: 'success',
                          position: 'top-right',
                          isClosable: true,
                        })
                      } else {
                        toast({
                          title: 'Erro ao converter PDF!',
                          status: 'error',
                          position: 'top-right',
                          isClosable: true,
                        })
                      }
                    } else {
                      toast({
                        title: 'Formato inválido! Envie um arquivo em PNG, PDF ou JPEG!',
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                      })
                    }
                  }
                }
              }}
            />

            <Flex gap={4} direction="column" flex="1" justifyContent="flex-end">
              <Button
                bg="orange.500"
                color="white"
                onClick={() => document.getElementById('img-preview-crlv-trailer2')?.click()}
                leftIcon={<Icon as={RiUploadCloudLine} />}
              >
                Carregar arquivo
              </Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                mt={2}
                leftIcon={<Icon as={RiSendPlane2Line} />}
                isLoading={isCrlvInformationsLoading}
              >
                Buscar dados via OCR
              </Button>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem colSpan={[12, 4, 3]}>
          <Input
            autoFocus
            ref={renavamRef}
            onChange={async e => {
              if (e.target.value.length > 9) {
                try {
                  const findTrailer = await fetchTrailerByColumn({
                    columnName: 'renavam',
                    columnValue: e.target.value,
                  })
                  if (findTrailer) {
                    dispatch(setTrailerId(findTrailer.id))
                    dispatch(setTrailerFound(findTrailer))
                  }
                } catch (error) {
                  dispatch(setTrailerId(''))
                } finally {
                  queryClient.invalidateQueries('vehicle')
                }
              }
              if (handleCheckTrailerExists) await handleCheckTrailerExists()
            }}
            name="trailer.renavam"
            label="Renavam"
            error={errors.trailer?.renavam}
            initialValue={crlvInfo?.renavam || trailerApi?.renavam || initialData?.renavam}
            setValue={setValue}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]} display="flex">
          <InputMask
            name="trailer.license_plate"
            mask="aaa9*99"
            maskPlaceholder=""
            label="Placa"
            onChange={async e => {
              if (e.target.value.length === 7) {
                try {
                  const findTrailer = await fetchTrailerByColumn({
                    columnName: 'licensePlate',
                    columnValue: e.target.value,
                  })
                  if (findTrailer) {
                    dispatch(setTrailerId(findTrailer.id))
                    dispatch(setTrailerFound(findTrailer))
                  }
                } catch (error) {
                  dispatch(setTrailerId(''))
                } finally {
                  queryClient.invalidateQueries('vehicle')
                }
              }
              setLicense_plate_trailer(e.target.value)
            }}
            error={errors.trailer?.license_plate}
            initialValue={initialData?.license_plate || crlvInfo.licensePlate}
            setValue={setValue}
            uppercaseAll
          />
          <Button
            mt={8}
            p={2}
            size="lg"
            colorScheme="green"
            isLoading={isLoading}
            onClick={() => {
              setLicensePlateTrailer(initialData?.license_plate || String(license_plate_trailer))
            }}
          >
            <Icon as={RiSearch2Line} />
          </Button>
        </GridItem>

        {vehicleBodies && (isEditTrailer || isLinkTrailer) && (
          <GridItem colSpan={[12, 6, 3]}>
            <Select
              isRequired
              name="trailer.vehicle_body_id"
              label="Reboque"
              error={errors.trailer?.vehicle_body_id}
              setValue={setValue}
              initialValue={initialData?.vehicle_body?.id}
              options={[{ label: '', value: '' }, ...(vehicleBodies as SelectOption[])]}
            />
          </GridItem>
        )}

        <GridItem colSpan={[12, 3, 2]}>
          <InputMask
            isRequired
            mask="9999"
            name="trailer.release_year"
            label="Ano fábrica"
            maskPlaceholder=""
            error={errors.trailer?.release_year}
            setValue={setValue}
            initialValue={
              crlvInfo.releaseYear || trailerApi?.release_year || initialData?.release_year?.toString()
            }
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 2]}>
          <InputMask
            isRequired
            mask="9999"
            name="trailer.model_year"
            label="Ano modelo"
            maskPlaceholder=""
            error={errors.trailer?.model_year}
            setValue={setValue}
            initialValue={crlvInfo.modelYear || trailerApi?.model_year || initialData?.model_year?.toString()}
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 1]}>
          <Input
            name="trailer.axes"
            label="Eixos"
            error={errors.trailer?.axes}
            initialValue={Number(crlvInfo.axes) || Number(trailerApi?.axes) || Number(initialData?.axes)}
            setValue={setValue}
          />
        </GridItem>
        <GridItem colSpan={[12, 3, 2]}>
          <Select
            name="trailer.license_uf"
            label="UF"
            error={errors.trailer?.license_uf}
            setValue={setValue}
            initialValue={trailerApi?.license_uf || initialData?.license_uf}
            options={ufOptions}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 4]}>
          <Input
            name="trailer.brand"
            label="Marca"
            error={errors.trailer?.brand}
            setValue={setValue}
            initialValue={crlvInfo.brandName || trailerApi?.brand || initialData?.brand}
            uppercaseFirst
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Input
            name="trailer.model"
            label="Modelo"
            error={errors.trailer?.model}
            setValue={setValue}
            initialValue={crlvInfo.brandModelVersion || trailerApi?.model || initialData?.model}
            uppercaseFirst
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]}>
          <Input
            name="trailer.capacity_m3"
            label="Capacidade (m3)"
            error={errors.trailer?.capacity_m3}
            initialValue={initialData?.capacity_m3}
            setValue={setValue}
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 3]}>
          <Input
            name="trailer.chassi"
            label="Chassi"
            error={errors.trailer?.chassi}
            initialValue={crlvInfo.chassis || initialData?.chassi || trailerApi?.chassi}
            setValue={setValue}
            uppercaseAll
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]}>
          <Input
            name="trailer.capacity_tara"
            label="Capacidade (TARA)"
            error={errors.trailer?.capacity_tara}
            initialValue={initialData?.capacity_tara}
            setValue={setValue}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <Input
            name="trailer.capacity_kg"
            label="Capacidade (kg)"
            error={errors.trailer?.capacity_kg}
            initialValue={
              !Number.isNaN(Number(crlvInfo.capacity))
                ? (Number(crlvInfo.capacity) * 1000)?.toString()
                : initialData?.capacity_kg?.toString()
            }
            setValue={setValue}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <InputMask
            name="trailer.antt"
            mask="aaa9*99"
            maskPlaceholder=""
            label="Placa ANTT"
            error={errors.trailer?.antt}
            initialValue={crlvInfo.licensePlate || trailerApi?.license_plate || initialData?.antt}
            setValue={setValue}
            uppercaseAll
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          <AutocompleteAsync
            name="trailer.city_id"
            label="Município"
            error={errors.trailer?.city_id}
            setValue={setValue}
            initialValue={
              trailerApi?.cityComplete
                ? trailerApi?.cityComplete
                : initialData?.city
                ? {
                    label: initialData?.city?.name,
                    value: initialData?.city?.ibge_id,
                  }
                : undefined
            }
            loadOptions={searchCitiesByName}
          />
        </GridItem>
        <GridItem colSpan={[12, 6, 3]}>
          {trailerApi?.color ? (
            <Input
              name="trailer.color"
              label="Cor"
              error={errors.trailer?.color}
              setValue={setValue}
              initialValue={capitalizeWord(alterColorsGenderDatamex(trailerApi.color))}
            />
          ) : (
            <Select
              name="trailer.color"
              label="Cor"
              options={vehicleColorsDatamex}
              initialValue={capitalizeWord(alterColorsGenderDatamex(trailerApi?.color || initialData?.color))}
              error={errors.trailer?.color}
              setValue={setValue}
            />
          )}
        </GridItem>
      </Grid>
      <Modal isOpen={isOpenImageModal} onClose={onCloseImageModal} size="full">
        <ModalOverlay />
        <Flex align="center" justify="center" height="100vh">
          <ModalContent margin="auto">
            <ModalHeader>CRLV Ampliado</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" alignItems="center" justifyContent="center" height="100%">
              <Image
                src={crlvTrailerImg}
                alt="Amplified Image"
                maxW="100%"
                maxH="100vh"
                objectFit="contain"
              />
            </ModalBody>
          </ModalContent>
        </Flex>
      </Modal>
    </>
  )
}

export const TrailerFormSchema = yup.object().shape({
  trailer: yup.object().shape(trailerSharedForm),
})
