/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { ApexOptions } from 'apexcharts'
import { differenceInMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { getWaypoints } from '../../../../../services/endpoints/motorists/getWaypoints'

interface TimelineProps {
  freight: FormattedOneFreight
  isOpen: boolean
  onClose: () => void
}

const chartOptions: ApexOptions = {
  chart: {
    height: 150,
    type: 'rangeBar',
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '80%',
    },
  },
  xaxis: {
    type: 'datetime',
  },
  stroke: {
    width: 1,
  },
  fill: {
    type: 'solid',
    opacity: 0.6,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
}

interface ChartData {
  x: string
  y: [number, number]
}

export function Timeline({ freight, isOpen, onClose }: TimelineProps): JSX.Element {
  const [items, setItems] = useState<ChartData[]>([])
  const [startTimeFixed, setStartTime] = useState<Date>()
  const [endTimeFixed, setEndTime] = useState<Date>()

  useEffect(() => {
    const loadWaypoints = async () => {
      const res = await getWaypoints({ freight, type: 'app' })
      const waypoints = res.allWaypoints
      if (waypoints && waypoints.length > 0) {
        setStartTime(new Date(waypoints[0].waypoint_date))
        let startTime: Date = new Date(waypoints[0].waypoint_date)
        const itemsArray: ChartData[] = []
        for (let i = 0; i < waypoints.length; i++) {
          const waypoint = waypoints[i]
          const waypointOld = i > 0 ? waypoints[i - 1] : waypoint
          const compareTime = new Date(waypoint.waypoint_date)
          const compareOldTime = new Date(waypointOld.waypoint_date)
          const diff = differenceInMinutes(compareTime, compareOldTime)
          if (diff < 10) continue
          itemsArray.push({
            x: 'Monitoramento',
            y: [startTime.getTime(), compareOldTime.getTime()],
          })
          startTime = new Date(waypoint.waypoint_date)
        }
        const endTimeFixed2 = new Date(waypoints[waypoints.length - 1].waypoint_date)
        if (itemsArray.length === 0) {
          itemsArray.push({
            x: 'Monitoramento',
            y: [startTime.getTime(), endTimeFixed2.getTime()],
          })
        }
        setEndTime(endTimeFixed2)
        setItems(itemsArray)
      }
    }
    loadWaypoints()
  }, [freight])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Timeline de monitoramento</ModalHeader>
        <ModalCloseButton />
        <ModalBody height="500px">
          {startTimeFixed && endTimeFixed && (
            <div id="chart">
              <ReactApexChart
                options={chartOptions}
                series={[{ data: items }]}
                type="rangeBar"
                height={150}
              />
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
