import { useQuery, UseQueryResult } from 'react-query'
import { ResponseListApiType } from '../../types/ResponseApiType'
import { apiServer } from '../../api'
import { MotoristType } from '../../types'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'

export interface FetchCompatibleMotoristsParams {
  orderByName?: 'asc' | 'desc'
  orderByCheckIn?: 'asc' | 'desc'
  orderByRateScore?: 'asc' | 'desc'
  freight_id?: string
  page?: number
  per_page?: number
  name?: string
  phone?: string
  license_plate?: string
  type?: number
  ddds?: number[]
  radius?: string
  has_motorist: boolean
  address_city_id?: AutocompleteOption
}

export interface GetCompatibleMotoristsResponse {
  total: number
  data: MotoristType[]
}

export async function fetchCompatibleMotorists({
  orderByName,
  orderByCheckIn,
  orderByRateScore,
  freight_id,
  page,
  per_page,
  name,
  phone,
  license_plate,
  type,
  ddds,
  radius,
  has_motorist,
  address_city_id,
}: FetchCompatibleMotoristsParams): Promise<GetCompatibleMotoristsResponse | undefined> {
  if (!has_motorist) {
    const { data: response } = await apiServer.get<ResponseListApiType<MotoristType[]>>(
      'list-compatible-motorist',
      {
        params: {
          orderByName,
          orderByCheckIn,
          orderByRateScore,
          freight_id,
          page,
          per_page,
          name,
          phone,
          license_plate,
          type,
          ddds,
          radius,
          address_city_id,
        },
      },
    )
    const { data } = response.data

    return { data, total: response.data.total }
  }
  return undefined
}

export function useCompatibleMotorists(
  data: FetchCompatibleMotoristsParams,
): UseQueryResult<GetCompatibleMotoristsResponse> {
  return useQuery(['compatible_motorists', data], () => fetchCompatibleMotorists(data), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
