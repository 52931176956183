import { Grid, GridItem } from '@chakra-ui/react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { FilterFreightContract } from '../../services/endpoints/freight-contracts/getAllFreightContracts'
import { searchMotoristByName } from '../../services/endpoints/motorists/searchMotoristByName'

interface FiltersCtesProps {
  setValue: UseFormSetValue<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  filters?: FilterFreightContract
}

export function FiltersFreightContract({ setValue, errors, filters }: FiltersCtesProps): JSX.Element {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="3">
      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Número do contrato"
          type="number"
          name="number"
          setValue={setValue}
          initialValue={filters?.number}
          error={errors.number}
          placeholder="Número do contrato"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Número do frete"
          type="number"
          name="freight_number"
          setValue={setValue}
          initialValue={filters?.freight_number}
          error={errors.freight_number}
          placeholder="Número do frete"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Data emissão inicial"
          name="emit_date_ini"
          type="date"
          error={errors.emit_date_ini}
          setValue={setValue}
          initialValue={filters?.emit_date_ini}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Data emissão final"
          name="emit_date_final"
          type="date"
          setValue={setValue}
          error={errors.emit_date_final}
          initialValue={filters?.emit_date_final}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 6]}>
        <AutocompleteAsync
          name="motorist_name"
          label="Motorista"
          setValue={setValue}
          loadOptions={searchMotoristByName}
          initialValue={filters?.motorist_name as any}
          error={errors.motorist_name}
        />
      </GridItem>
    </Grid>
  )
}
