import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input as ChakraInput,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine, RiCheckboxCircleLine, RiUploadCloudLine } from 'react-icons/ri'
import driverLicensePlaceholderImg from '../../../../assets/driver-license-placeholder.png'
import { Input } from '../../../../components/form/Input'

interface DriverLicenseTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  formState: FormState<FieldValues>
}

export function DriverLicenseTabPanel({
  setValue,
  tabNav,
  formState,
}: DriverLicenseTabPanelProps): JSX.Element {
  const { errors } = formState

  const [imgSource, setImgSource] = useState<string>(driverLicensePlaceholderImg)

  const imgEncoded = useMemo(() => {
    const splits = imgSource.split(',')
    return splits[1]
  }, [imgSource])

  return (
    <>
      <Flex>
        <Flex direction="column" mr="8" w="400px" alignItems="center">
          <Image src={imgSource} w="300px" />
          <FormLabel htmlFor="img-preview">
            <Flex mt="4" cursor="pointer" bg="orange.500" py="2" px="4" borderRadius="8" color="white">
              <Icon as={RiUploadCloudLine} fontSize={24} mr="2" />
              Carregar arquivo
            </Flex>

            <ChakraInput
              id="img-preview"
              name="img-preview"
              type="file"
              d="none"
              onChange={e => {
                if (e.target.files) {
                  const file = e.target.files[0]

                  const fileReader = new FileReader()
                  fileReader.onload = ev => {
                    if (ev.target && ev.target.result) {
                      const imgData = ev.target.result.toString()
                      setImgSource(imgData)
                    }
                  }
                  fileReader.readAsDataURL(file)
                }
              }}
            />
          </FormLabel>

          <Input name="professional_motorist_img" setValue={setValue} initialValue={imgEncoded} d="none" />

          {errors.professional_motorist_img && (
            <Heading color="red.500" size="sm">
              {errors.professional_motorist_img}
            </Heading>
          )}
        </Flex>

        <Box>
          <Heading size="md">PARA A CATEGORIA CARRETEIRO, O ENVIO DA CNH É OBRIGATÓRIO.</Heading>
          <Heading size="md" fontWeight="normal" mt="2">
            Confira abaixo os cuidados para o envio correto do documento.
          </Heading>

          <List spacing={3} mt="6" p="4" border="1px solid #ccc" borderRadius="8">
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Digitalize o documento em alta resolução ou faça uma foto com alta qualidade.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Em ambos os casos mantenha o documento na vertical com a foto na parte superior.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Enquadre o documento aproveitando os espaços possíveis, ressaltando suas informações.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Mantenha o foco da imagem, realize a foto sobre uma superficie plana, com o documento reto, sem
              dobras ou distorções.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              Utilize um fundo claro para manter o contraste do documento com o fundo.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />O arquivo deve ter no
              máximo 5mb.
            </ListItem>
            <ListItem>
              <ListIcon as={RiCheckboxCircleLine} color="green.500" fontSize={20} />
              NÃO SERÃO ACEITAS CÓPIAS DO DOCUMENTO EM PRETO E BRANCO.
            </ListItem>
          </List>
        </Box>
      </Flex>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(2)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(4)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
