import {
  Alert,
  AlertIcon,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCaravanLine } from 'react-icons/ri'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useApiCarsRequestContext } from '../../../contexts/ApiCarsRequestContext'
import { apiServer } from '../../../services/api'
import { useCreateAttachmentToTrailer } from '../../../services/endpoints/trailers/trailer-attachments/createTrailerAttachment'
import { useUpdateAttachmentToTrailer } from '../../../services/endpoints/trailers/trailer-attachments/updateTrailerAttachment'
import { attachTrailerToVehicle } from '../../../services/endpoints/vehicles/attachTrailerToVehicle'
import {
  fetchTrailerByColumn,
  TrailerResponseFormData,
} from '../../../services/endpoints/trailers/getTrailerByColumn'
import { fetchVehicleByColumn } from '../../../services/endpoints/vehicles/getVehicleByColumn'
import { ResponseApiType, VehicleType } from '../../../services/types'
import TrailerAttachmentType from '../../../services/types/TrailerAttachmentType'
import { useAppDispatch, useAppSelector } from '../../../store'
import { setCrlvTrailerFile } from '../../../store/slices/ocrCrlvSlice'
import { setTrailerFound } from '../../../store/slices/vehicleSlice'
import { TrailerForm, TrailerFormSchema } from '../TrailerForm'
import { TrailerType } from '../../../services/types/TrailerType'

interface TrailerFormData {
  trailer: {
    license_plate?: string
    license_uf?: string
    brand?: string
    model?: string
    release_year?: number
    model_year?: number
    renavam?: string
    chassi?: string
    antt?: string
    capacity_m3?: number
    capacity_tara?: number
    capacity_kg?: number
    vehicle_body_id: string
    city_id?: AutocompleteOption
    color?: string
    axes?: number
    has_tracker?: boolean
    has_insurance?: boolean
  }
}

interface EditTrailerModalProps extends ButtonProps {
  buttonTitle: string
  vehicle_id: string
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>
}

export function AttachTrailerToVehicleModal({
  vehicle_id,
  buttonTitle,
  setTabIndex,
  ...rest
}: EditTrailerModalProps): JSX.Element {
  const { trailerFound } = useAppSelector(state => state.vehicleSlice)
  const { crlvTrailerFile } = useAppSelector(state => state.ocrCrlvSlice)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { setTrailerApi } = useApiCarsRequestContext()
  const [trailerExists, setTrailerExists] = useState<TrailerResponseFormData | undefined | null>(trailerFound)
  useEffect(() => {
    if (trailerFound) {
      setTrailerExists(trailerFound)
    }
  }, [trailerFound])
  const [vehicleExists, setVehicleExists] = useState<VehicleType>()
  const renavamRef = useRef<HTMLInputElement>(null)

  const { mutateAsync: createAttachmentTrailer } = useCreateAttachmentToTrailer({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  const { mutateAsync: updateAttachmentTrailer } = useUpdateAttachmentToTrailer({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
    },
  })
  async function handleCheckTrailerExists() {
    if (renavamRef.current) {
      const { value } = renavamRef.current
      try {
        const byRenavam = await fetchTrailerByColumn({ columnName: 'renavam', columnValue: value })
        if (byRenavam) {
          setTrailerExists(byRenavam)
        } else {
          const byLicensePlate = await fetchTrailerByColumn({
            columnName: 'licensePlate',
            columnValue: value,
          })
          if (byLicensePlate) setTrailerExists(byLicensePlate)
          else setTrailerExists(undefined)
        }
      } catch {
        setTrailerExists(undefined)
      }

      try {
        const vehicle = await fetchVehicleByColumn({ columnName: 'renavam', columnValue: value })
        if (vehicle) {
          setVehicleExists(vehicle)
        } else {
          setVehicleExists(undefined)
        }
      } catch {
        setVehicleExists(undefined)
      }
    }
  }

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(TrailerFormSchema),
  })

  const handleAttachTrailer = useCallback<SubmitHandler<TrailerFormData>>(
    async data => {
      let attachment: TrailerAttachmentType | undefined
      const trailerFormData = {
        license_plate: data.trailer?.license_plate?.toLocaleUpperCase(),
        license_uf: data.trailer?.license_uf,
        brand: data.trailer?.brand,
        model: data.trailer?.model,
        release_year: data.trailer?.release_year,
        model_year: data.trailer?.model_year,
        renavam: data.trailer?.renavam,
        chassi: data.trailer?.chassi,
        antt: data.trailer?.antt,
        capacity_m3: data.trailer?.capacity_m3,
        capacity_tara: data.trailer?.capacity_tara,
        capacity_kg: data.trailer?.capacity_kg,
        vehicle_body_id: data.trailer.vehicle_body_id,
        city_id: data.trailer?.city_id?.value,
        color: data.trailer?.color,
        axes: data.trailer?.axes,
        disable_entities_block: true,
      }

      try {
        if (trailerExists?.id) {
          attachment = trailerExists?.attachments?.find(item => item.type === 'crlv')
          await apiServer.put(`/update-trailer/${trailerExists.id}`, trailerFormData)
          await attachTrailerToVehicle(vehicle_id, trailerExists.id)
          if (crlvTrailerFile) {
            if (attachment?.id) {
              await updateAttachmentTrailer({
                id: attachment.id,
                attachment_file: crlvTrailerFile,
                trailer_id: trailerExists.id,
              })
            }
          }
        } else {
          const { data: response } = await apiServer.post<ResponseApiType<TrailerType>>(
            `/create-trailer`,
            trailerFormData,
          )
          const newTrailer = response.data
          await attachTrailerToVehicle(vehicle_id, newTrailer.id)
          if (crlvTrailerFile) {
            if (attachment?.id) {
              await updateAttachmentTrailer({
                id: attachment.id,
                attachment_file: crlvTrailerFile,
                trailer_id: newTrailer.id,
              })
            } else {
              await createAttachmentTrailer({
                attachment_file: crlvTrailerFile,
                trailer_id: newTrailer.id,
                name: 'Crlv do veículo',
                type: 'crlv',
              })
            }
          }
        }
        toast({
          title: 'Reboque vinculado a tração com sucesso!',
          status: 'success',
          position: 'top-right',
        })

        if (setTabIndex) setTabIndex(state => state + 1)

        onClose()
        setTrailerApi(undefined)
        setTrailerExists(undefined)
        queryClient.invalidateQueries('vehicle')
        queryClient.invalidateQueries('motorist')
        queryClient.invalidateQueries('owner')
        queryClient.invalidateQueries('check-pendencies')
      } catch (error) {
        toastError({ toast, error })
      } finally {
        setTrailerExists(undefined)
        dispatch(setCrlvTrailerFile(null))
      }
    },
    [
      trailerExists,
      toast,
      onClose,
      setTrailerApi,
      setTabIndex,
      crlvTrailerFile,
      vehicle_id,
      updateAttachmentTrailer,
      createAttachmentTrailer,
      dispatch,
    ],
  )

  useEffect(() => {
    setTrailerApi(undefined)
    setTrailerExists(undefined)
    dispatch(setTrailerFound(null))
  }, [dispatch, isOpen, setTrailerApi])

  return (
    <>
      <Button
        {...rest}
        minW={150}
        leftIcon={<RiCaravanLine />}
        colorScheme="green"
        onClick={() => {
          onOpen()
        }}
      >
        {buttonTitle}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="4xl"
        scrollBehavior="outside"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader fontSize="2xl">Vincular reboque</ModalHeader>

          {vehicleExists && (
            <Flex mx={6}>
              <Alert status="error" my="4" borderRadius="4">
                <AlertIcon />

                <Flex>O renavam digitado pertence a um veiculo de tração!</Flex>
              </Alert>
            </Flex>
          )}
          <form onSubmit={handleSubmit(handleAttachTrailer)} noValidate>
            <ModalCloseButton />
            <ModalBody>
              <>
                <TrailerForm
                  setValue={setValue}
                  formState={formState}
                  initialData={trailerFound || trailerExists}
                  renavamRef={renavamRef}
                  handleCheckTrailerExists={handleCheckTrailerExists}
                  isLinkTrailer
                />
                <Divider my="2" />
              </>
            </ModalBody>

            <ModalFooter>
              <>
                <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  name="link_trailer_submit"
                  colorScheme="green"
                  leftIcon={<Icon as={RiCaravanLine} />}
                  isLoading={formState.isSubmitting}
                  isDisabled={!!vehicleExists}
                  onClick={() => {
                    // eslint-disable-next-line no-console
                    console.log(formState.errors)
                  }}
                >
                  Vincular
                </Button>
              </>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
