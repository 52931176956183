import { Box, Grid, GridItem, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import useSearchMotorist from '../../../hooks/useSearchMotorist'
import useSearchOwner from '../../../hooks/useSearchOwner'
import OwnerType from '../../../services/types/OwnerType'
import { validateCpf } from '../../../services/utils/validateCpf'
import { UIInput } from '../../../components/UI/UIInput'
import { UIRadioGroup } from '../../../components/UI/UIRadioGroup'
import { CreateMotoristCompleteFormModal } from '../create/CreateMotoristCompleteFormModal'

export type MotoristQuickFormValues = {
  cpf: string
  phone: string
  name: string
  prospected_by?: number
}

export const motoristQuickFormSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório').min(11, 'Telefone incompleto'),
  cpf: yup.string().test('validate-cpf', 'CPF inválido', value => {
    if (!value) return false

    if (value) return validateCpf(value)
    return true
  }),
  prospected_by: yup.number(),
})

export function MotoristQuickForm(): JSX.Element {
  const history = useHistory()
  const toast = useToast()
  const {
    isOpen: isOpenCreateMotoristModal,
    onOpen: onOpenCreateMotoristModal,
    onClose: onCloseCreateMotoristModal,
  } = useDisclosure()

  const [owner, setOwner] = useState<OwnerType>()

  const { control } = useFormContext<MotoristQuickFormValues>()

  useEffect(() => {
    if (owner) onOpenCreateMotoristModal()
  }, [onOpenCreateMotoristModal, owner])

  const { loading: loadingMotorist, search: searchMotorist } = useSearchMotorist({
    onSuccess: data => {
      if (data.motorist) {
        toast({
          title: 'Motorista encontrado',
          description: 'Motorista encontrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        if (data.motorist?.id) {
          history.push(`/motorists/show/${data.motorist.id}`)
        }
      }
    },
  })

  const { loading: loadingOwner, search: searchOwner } = useSearchOwner({
    onSuccess: data => {
      if (data.owner) {
        setOwner(data.owner)
      }
    },
  })

  return (
    <>
      <Box>
        <Grid templateColumns="repeat(12, 1fr)" gap="4">
          <GridItem colSpan={[12, 6, 3]}>
            <Controller
              control={control}
              name="cpf"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  mask="999.999.999-99"
                  label="CPF"
                  isRequired
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                  isSearchable
                  isLoading={loadingMotorist || loadingOwner}
                  onSearch={() => searchMotorist(value)}
                  onBlur={() => {
                    if (value) {
                      searchMotorist(value)
                      searchOwner(value)
                    }
                  }}
                />
              )}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 3]}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  mask="(99) 99999-9999"
                  label="Telefone Principal"
                  isRequired
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
          <GridItem colSpan={[12, 6, 6]}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UIInput
                  label="Nome Completo"
                  isRequired
                  isError={!!error?.message}
                  errorMessage={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </GridItem>
          <GridItem colSpan={[12]}>
            <Controller
              control={control}
              name="prospected_by"
              render={({ field: { onChange, value } }) => (
                <UIRadioGroup
                  name="prospected_by"
                  label="Prospectado"
                  size="lg"
                  onChange={v => onChange(Number(v))}
                  value={value}
                  options={[
                    { label: 'Fretebras', value: 0 },
                    { label: 'Agenciador', value: 1 },
                    { label: 'Base', value: 2 },
                  ]}
                />
              )}
            />
          </GridItem>
        </Grid>
      </Box>

      <CreateMotoristCompleteFormModal
        isOpen={isOpenCreateMotoristModal}
        onClose={() => {
          onCloseCreateMotoristModal()
        }}
        ownerExistsData={owner}
      />
    </>
  )
}
