import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightType } from '../../types'
import { ResponseListApiType } from '../../types/ResponseApiType'

export interface IClientFeedback {
  total: number
  data: [
    {
      id: string
      freight_id: string
      because_this_note?: string
      why_this_recommendation_score?: string
      spotx_note: number
      punctuality_note: number
      collect_and_delivery_note: number
      seller_note: number
      payment_method_note: number
      informations_note: number
      recommendation_score: number
      created_at: string
      updated_at: string
      freight: FreightType
    },
  ]
}

export type GetClientFeedbackResponse = {
  total: number
  data: IClientFeedbackData[]
}

interface ReportParams {
  initialDateFeedbacksClient: string
  finalDateFeedbacksClient: string
}

export interface IClientFeedbackData {
  id: string
  freight_id: string
  because_this_note?: string
  why_this_recommendation_score?: string
  spotx_note: number
  punctuality_note: number
  collect_and_delivery_note: number
  seller_note: number
  payment_method_note: number
  informations_note: number
  recommendation_note: number
  created_at: string
  updated_at: string
  freight: FreightType
}
export async function fetchReportsFeedbacksClient({
  initialDateFeedbacksClient,
  finalDateFeedbacksClient,
}: ReportParams): Promise<GetClientFeedbackResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<IClientFeedbackData[]>>(
    `/list-clients-feedback`,
    {
      params: {
        initial_date: `${initialDateFeedbacksClient}T00:00:00`,
        final_date: `${finalDateFeedbacksClient}T23:59:59`,
      },
    },
  )

  const { total } = res.data

  return { total, data: res.data.data }
}

export function useReportsFeedbacksClient(params: ReportParams): UseQueryResult<GetClientFeedbackResponse> {
  return useQuery(['reports-feedbacks-client', params], () => fetchReportsFeedbacksClient(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
