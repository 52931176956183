import { format } from 'date-fns-tz'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { CiotType } from '../../../types'
import { CTEType } from '../../../types/CteType'
import { formattedTransporter } from '../../../utils/formattedTransporter'
import parseCurrency from '../../../utils/parseCurrency'

type HiredDoc = {
  type: 'cpf' | 'cnpj'
  number: string
}

export interface CiotFormatted extends CiotType {
  hired_doc: HiredDoc
  cte?: CTEType
}

type FetchCiotParams = {
  page?: number
  per_page?: number
  unpaged?: boolean
  freight_id?: string
  ciot_number?: number
  freight_number?: number
  emission_date?: string
  owner_name?: string
}

type fetchCiotsResponse = {
  total: number
  data: CiotFormatted[]
}

export async function fetchCiots(data: FetchCiotParams): Promise<fetchCiotsResponse> {
  const params = data && { ...data }

  const { data: response } = await apiServer.get('ciots', {
    params: {
      ...params,
    },
  })

  return {
    ...response.data,
    data: response.data.data.map((ciot: CiotFormatted) => ({
      ...ciot,
      date_emission: format(new Date(ciot.date_emission), 'dd/MM/yy HH:mm'),
      gross_ciot_value: parseCurrency(ciot.gross_ciot_value),
      hired_doc: ciot.hired?.docs?.find(doc => doc.type === 'cpf' || doc.type === 'cnpj'),
      contractor_number: formattedTransporter(ciot.contractor_number),
      cte: ciot.mdfe?.ctes?.find(
        cte =>
          // eslint-disable-next-line eqeqeq
          cte.nct == ciot.cte_number && ciot.ciot_status?.status !== 'cancelado',
      ),
    })),
  }
}

export function useGetCiots(
  data: FetchCiotParams,
  options?: UseQueryOptions<fetchCiotsResponse>, // Tipando as opções como argumento opcional
): UseQueryResult<fetchCiotsResponse> {
  return useQuery(['list_ciots', data], () => fetchCiots(data), {
    staleTime: 1000 * 60 * 10,
    retry: false,
    ...options, // Mesclando com as opções fornecidas
  })
}
