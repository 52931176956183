import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'

interface SelectOptionGroup {
  id: string
  category: string
  brand: string
}

export async function listVehicleBrandsDatamex(): Promise<SelectOption[]> {
  const { data } = await apiServer.get<{ data: SelectOptionGroup[] }>(`/document-handler/vehicles/brands`)

  return data.data.map(brand => {
    const label = `${brand.brand} - ${brand.category}`
    const value = brand.id

    return { label, value }
  })
}
