import { Box, Button, Divider, Flex, Heading, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { forEach } from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { Layout } from '../../../../../layout'
import { usePostCteIssuanceRequest } from '../../../../../services/endpoints/freights/PostCteIssuanceRequest'
import { useUploadCteIssuanceRequestAttachments } from '../../../../../services/endpoints/freights/UploadCteIssuanceRequestAttachments'
import { CteIssuanceRequestAttachmentTypeEnum } from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateCteIssuanceRequestForm } from '../forms/CreateOrUpdateCteIssuanceRequestForm'

type CreateCteIssuanceRequestType = {
  freight_id: string
  motorist: SelectOption
  motorist_vehicle_id: string
  has_toll: string
  toll_value?: number
  deduct_motorist_total: string
  attachment_file?: File[]
  observation?: string
}

const CreateCteissuanceRequestSchema = yup.object().shape({
  freight_id: yup.string().required('Campo obrigatório'),
  motorist: yup.object().shape({}).required('Campo obrigatório'),
  motorist_vehicle_id: yup.string().required('Campo obrigatório'),
  has_toll: yup.string(),
  toll_value: yup.string(),
  deduct_motorist_total: yup.string(),
  observation: yup.string(),
})

export function CreateCteIssuanceRequest(): JSX.Element {
  const toast = useToast()
  const bg = useColorModeValue('white', 'gray.800')
  const history = useHistory()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(CreateCteissuanceRequestSchema),
  })

  const { errors } = formState

  const { mutateAsync: createCteIssuanceRequest, isLoading: isLoadingCreateCteIssuanceRequest } =
    usePostCteIssuanceRequest({
      onSuccess: () => {
        toast({
          title: 'Nova solicitação de emissão de CTe criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const {
    mutateAsync: uploadCteIssuanceRequestAttachments,
    isLoading: isLoadingUploadCteIssuanceRequestAttachments,
  } = useUploadCteIssuanceRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleCreateCteIssuanceRequest: SubmitHandler<CreateCteIssuanceRequestType> = async data => {
    if (!data.attachment_file || data.attachment_file.length === 0) {
      toastError({ toast, error: 'Você deve fazer o anexo de pelo menos uma nota fiscal' })
      return
    }

    try {
      if (data.freight_id) {
        const newCteIssuanceRequest = await createCteIssuanceRequest(data)

        if (newCteIssuanceRequest && data.attachment_file && data.attachment_file.length > 0) {
          forEach(data.attachment_file, async file => {
            const formData = new FormData()

            formData.append('cte_issuance_request_id', newCteIssuanceRequest.id)
            formData.append('type', CteIssuanceRequestAttachmentTypeEnum.NF)
            formData.append('attachment', file)

            await uploadCteIssuanceRequestAttachments({ data: formData })
          })
        }

        history.push('/cte-issuance-requests')
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <Heading size="lg" fontWeight="normal" mb={4}>
          Nova solicitação de emissão de CTe
        </Heading>

        <Divider my="4" />

        <form onSubmit={handleSubmit(handleCreateCteIssuanceRequest)} noValidate>
          <CreateOrUpdateCteIssuanceRequestForm setValue={setValue} errors={errors} />

          <Flex mt={4} justifyContent="flex-end">
            <Link to="/cte-issuance-requests">
              <Button mr={3}>Cancelar</Button>
            </Link>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingCreateCteIssuanceRequest || isLoadingUploadCteIssuanceRequestAttachments}
              isDisabled={isLoadingCreateCteIssuanceRequest || isLoadingUploadCteIssuanceRequestAttachments}
            >
              Cadastrar
            </Button>
          </Flex>
        </form>
      </Box>
    </Layout>
  )
}
