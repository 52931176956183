import { Box, Flex } from '@chakra-ui/react'
import { flexRender, Row } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import useTableColors from './useTableColors'

interface TableRowProps {
  row: Row<any>
  rowSelection: boolean
  handleRowToggle: (id: string) => void
  index: number
  rowStyles?: {
    background?: string
    border?: string
    boxShadow?: string
  }
}

const TableRow = ({ row, rowSelection, handleRowToggle, index, rowStyles }: TableRowProps): JSX.Element => {
  const {
    rowBackgroundColor,
    alternateRowBackgroundColor,
    rowHoverBackgroundColor,
    selectedRowBackgroundColor,
    borderColor,
  } = useTableColors()

  const rowMemo = useMemo(() => row, [row])
  return (
    <>
      <Flex
        key={rowMemo.id}
        {...(rowStyles && rowStyles.background !== '' && !rowSelection
          ? {
              style: {
                backgroundColor: rowStyles?.background,
                boxShadow: rowStyles?.boxShadow,
              },
            }
          : {
              bg: rowSelection
                ? selectedRowBackgroundColor
                : index % 2 === 0
                ? rowBackgroundColor
                : alternateRowBackgroundColor,
              _hover: {
                bg: rowSelection ? selectedRowBackgroundColor : rowHoverBackgroundColor,
              },
            })}
        width="fit-content"
        cursor="pointer"
        onClick={() => handleRowToggle(rowMemo.id)}
      >
        {rowMemo.getVisibleCells().map(cell => (
          <Box
            display="inline-flex"
            borderColor={borderColor}
            alignItems="center"
            borderBottomWidth={1}
            borderRightWidth={1}
            _last={{ borderRightWidth: 0 }}
            {...{
              key: cell.id,
              style: {
                width: cell.column.getSize(),
              },
            }}
            minWidth={20}
          >
            <Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Box>
          </Box>
        ))}
      </Flex>
    </>
  )
}

export default React.memo(TableRow)
