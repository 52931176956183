import { apiServer } from '../../api'
import { UserType } from '../../types'
import { ResponseArrayApiType } from '../../types/ResponseApiType'

interface UsersFiltered {
  label: string
  value: string
  role: string
}

export async function getUserOperation(): Promise<UsersFiltered[]> {
  const { data: usersResponse } = await apiServer.get<ResponseArrayApiType<{ data: UserType[] }>>('/users', {
    params: { role: ['supervisor', 'logistics_analyst'], unpaged: true },
  })

  return usersResponse.data.data.map(user => {
    return {
      label: user.name,
      role: user.role,
      value: user.id,
    }
  })
}
