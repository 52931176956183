import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

type Contact = {
  name: string
  contact_type: number
  return_type: number
  description: string
}

type City = {
  ibge_id?: number
  name: string
  state: {
    uf: string
    name?: string
  }
}

type Address = {
  state: string
  city: string
  zipcode: string
  district: string
  address: string
  number: number
  complement?: string
}

type Vehicle = {
  license_plate: string
  chassi: string
  renavam: string
  city_licensed: City
  vehicle_color: string
  release_year: number
  model?: string
  model_year: number
  fabricator?: string
  technology?: string
  owner: {
    document: string
    company_name: string
    rg_ie: string
    address: Address
    contacts: Contact[]
  }
  antt_owner: { rntrc: string }
}

type Cnh = {
  cnh: string
  category: string
  validity: string
  state: string
  first_cnh_date: string
  security_code: string
  mopp_initial_date?: string
}

type Professional = {
  image_professional: string
  category: string
  document: string
  name: string
  father_name: string
  mother_name: string
  rg: string
  rg_state: string
  rg_issue_date: string
  birthday: string
  birth_city: City
  cnh: Cnh
  address: Address
  contacts: Contact[]
}

export interface InputInsertProfessionalToBuonny {
  product: number
  cnpj_shipper?: string
  cnpj_transporter: string
  note?: string
  buonny_cargo_type?: string
  buonny_cargo_price?: string
  origin: City
  destination: City
  return_contact: Contact
  professional: Professional
  vehicles: Vehicle[]
  additional_data: {
    was_stolen_in_last_24_months: boolean
    stolen_times?: number
    had_accident: boolean
    accident_times?: number
    how_long_carry_to_company: string
    carry_times: number
    has_tracking_system: boolean
    tracking_system?: string
    respond_for_crime_in_last_10_years: boolean
    crime_process_numbers?: boolean
  }
}

async function insertProfessionalToBuonny(data: Partial<InputInsertProfessionalToBuonny>) {
  return apiServer.post('/buonny/insert-professional', data)
}

export function useInsertProfessionalToBuonny(
  options?: UseMutationOptions<AxiosResponse, unknown, InputInsertProfessionalToBuonny, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, InputInsertProfessionalToBuonny, unknown> {
  return useMutation(
    async (data: InputInsertProfessionalToBuonny) => insertProfessionalToBuonny(data),
    options,
  )
}
