import { useDisclosure } from '@chakra-ui/react'
import { FaDollarSign } from 'react-icons/fa'
import { queryClient } from '../../../../../config/react-query'
import { FormattedQuotation } from '../../../../../services/endpoints/freights/getAllQuotations'
import { useGetAllRoutePrices } from '../../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import QuotationsKanbanButtonForm from '../../../../quotation/kanban-quotations/cards/button-form-cards'
import { ShowListRoutePricesModal } from '../ShowListPricesMotoristsModal'

export interface ButtonProps {
  quotation: FormattedQuotation
  size?: 'sm' | 'lg' | 'md' | 'xs'
}

export default function ShowRoutePricesButton({ quotation, size }: ButtonProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { data: prices } = useGetAllRoutePrices({
    quotation_id: quotation.id,
    per_page: 10,
  })

  return (
    <>
      {['in_quotation', 'to_approve', 'finished', 'disabled'].includes(quotation.status) &&
        prices &&
        prices.data.length > 0 && (
          <QuotationsKanbanButtonForm
            size={size}
            colorScheme="green"
            leftIcon={FaDollarSign}
            onOpen={onOpen}
            label="Mostrar valores"
          />
        )}
      <ShowListRoutePricesModal
        prices={prices?.data}
        quotation={quotation}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          queryClient.invalidateQueries('all-quotations')
        }}
      />
    </>
  )
}
