import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import { FreightExpensesAttachmentsTypeEnum } from '../../../types/EnumTypes'
import FreightExpenseType, { FreightExpenseAttachmentsType } from '../../../types/FreightExpenseType'
import { formattedTransporter } from '../../../utils/formattedTransporter'

export interface FormattedOneFreightExpenseType extends FreightExpenseType {
  created_at_short: string
  created_at_formatted: string
  cte_name: string
  attachments_operational: FreightExpenseAttachmentsType[]
  attachments_financial: FreightExpenseAttachmentsType[]
}

export async function fetchFreightExpense(id: string): Promise<FormattedOneFreightExpenseType> {
  const { data: res } = await apiServer.get<ResponseApiType<FormattedOneFreightExpenseType>>(
    `/find-freight-expense/${id}`,
  )
  const { data } = res
  return {
    ...data,
    attachments_operational: data.freight_expense_attachments.filter(
      attach => attach.type === FreightExpensesAttachmentsTypeEnum.OPERATIONAL,
    ),
    attachments_financial: data.freight_expense_attachments.filter(
      attach => attach.type === FreightExpensesAttachmentsTypeEnum.FINANCIAL,
    ),
    cte_name: data.cte ? `${formattedTransporter(data.transporter)} - ${data.cte.nct}` : '',
    transporter: formattedTransporter(data.transporter),
    created_at_formatted: format(new Date(data.created_at), "dd/MM/yy 'às' HH:mm"),
    created_at_short: format(new Date(data.created_at), "yyyy-MM-dd'T'HH:mm"),
  }
}

export function useGetOneFreightExpense(id: string): UseQueryResult<FormattedOneFreightExpenseType> {
  return useQuery(['financial-title', id], () => fetchFreightExpense(id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
