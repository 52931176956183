import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Flex,
  Tooltip,
  Heading,
  Spinner,
  Text,
  useColorModeValue,
  Icon,
  useToast,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { RiStarLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import { toastError } from '../../../config/error/toastError'
import { useGlobalVariables } from '../../../contexts/GlobalVariables'
import { Layout } from '../../../layout'
import {
  useReportsFeedbacksClient,
  fetchReportsFeedbacksClient,
  IClientFeedbackData,
} from '../../../services/endpoints/clients/reportsFeedbacksClient'

export function ClientsFeedbacksReports(): JSX.Element {
  const toast = useToast()
  const { initial_date, final_date, setInitialDate, setFinalDate } = useGlobalVariables()
  const [initialDateFeedbacksClient, setInitialDateFeedbacksClient] = useState<string>(() => {
    const today = new Date()
    const week = today.setDate(today.getDate() - 1)
    return format(week, 'yyyy-MM-dd')
  })
  const [finalDateFeedbacksClient, setFinalDateFeedbacksClient] = useState<string>(initialDateFeedbacksClient)

  const {
    data: feedbackResponse,
    isLoading,
    isError,
    error,
  } = useReportsFeedbacksClient({
    initialDateFeedbacksClient,
    finalDateFeedbacksClient,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  const bg = useColorModeValue('cyan', 'gray')

  useEffect(() => {
    setInitialDateFeedbacksClient(initial_date)
    setFinalDateFeedbacksClient(final_date)
  }, [initial_date, final_date])

  return (
    <Layout
      initialDate={initial_date}
      finalDate={final_date}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      isFilters
      SidebarMobile
    >
      <Box>
        <Flex mb="2" gridGap="2" justifyContent="space-between" fontSize={['md', 'lg']}>
          <Heading size="lg">Relatório de feedbacks dos clientes</Heading>
          {feedbackResponse &&
            feedbackResponse.data &&
            feedbackResponse.data &&
            feedbackResponse.data.length > 0 && (
              <ReportToExcel
                isLoading={isLoading}
                documentTitle="Relatório de feedbacks dos clientes"
                columnsTitle={[
                  'Número do frete',
                  'SpotX',
                  'Motivo da nota',
                  'Pontualidade',
                  'Coleta e entrega',
                  'Vendedor',
                  'Formas de pagamento',
                  'Informações do frete',
                ]}
                onGenerateReport={async () => {
                  const report = await fetchReportsFeedbacksClient({
                    initialDateFeedbacksClient,
                    finalDateFeedbacksClient,
                  })

                  return report?.data.map((feedback: IClientFeedbackData) => {
                    return [
                      feedback.freight.freight_number,
                      feedback.spotx_note,
                      feedback.because_this_note,
                      feedback.punctuality_note,
                      feedback.collect_and_delivery_note,
                      feedback.seller_note,
                      feedback.payment_method_note,
                      feedback.informations_note,
                    ] as string[]
                  })
                }}
              />
            )}
        </Flex>

        <Table variant="striped" colorScheme={bg}>
          <Thead>
            <Tr>
              <Th fontSize="xs" whiteSpace="nowrap">
                Número do frete
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                SpotX
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Motivo da nota
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                pontualidade
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                coleta e entrega
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                vendedor
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                formas de pagamento
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                informação do frete
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <Flex alignItems="center" justifyContent="center" my="10">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Tbody>
              {feedbackResponse?.data &&
                feedbackResponse?.data.length > 0 &&
                feedbackResponse.data.map((feedback: IClientFeedbackData) => (
                  <Tr key={feedback.id}>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Link to={`/freights/show/${feedback.freight.id}`}>
                        #{feedback.freight.freight_number}
                      </Link>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.spotx_note && (
                          <>
                            {feedback.spotx_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      {feedback.because_this_note && (
                        <Tooltip
                          hasArrow
                          label={feedback.because_this_note}
                          color="gray.700"
                          bg="gray.300"
                          placement="top"
                        >
                          <Text>
                            {String(feedback.because_this_note).substring(0, 40)}
                            {feedback.because_this_note.length > 40 ? '...' : undefined}
                          </Text>
                        </Tooltip>
                      )}
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.punctuality_note && (
                          <>
                            {feedback.punctuality_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.collect_and_delivery_note && (
                          <>
                            {feedback.collect_and_delivery_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.seller_note && (
                          <>
                            {feedback.seller_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.payment_method_note && (
                          <>
                            {feedback.payment_method_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                    <Td fontSize="sm" whiteSpace="nowrap">
                      <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                        {feedback.informations_note && (
                          <>
                            {feedback.informations_note}
                            <Icon as={RiStarLine} fontSize={20} mx="1" />
                          </>
                        )}
                      </Text>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          )}
        </Table>
      </Box>
    </Layout>
  )
}
