import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'

export interface FiltersMetricsParams {
  initial_date: string
  final_date: string
}

export interface GetMetricsResponse {
  metrics: {
    deliveryPunctuality: string
    delayRate: string
    transportCycleTime: string
    costPerKilometer: string
    thirdPartyServiceQuality: string
    customerSatisfaction: string
    collectionPunctuality: string
    slaAdherence: string
    clientFeedbackRate: string
    incidentRate: string
    tracked_by_satellite: string
    tracked_by_web: string
  }
  total: number
}

interface GetMetricsDTO {
  data: GetMetricsResponse
  statusCode: number
}

// list-freight-metrics
export async function fetchMetrics({
  initial_date,
  final_date,
}: FiltersMetricsParams): Promise<GetMetricsResponse> {
  const { data } = await apiServer.get<GetMetricsDTO>('list-freight-metrics', {
    params: {
      initial_date,
      final_date,
    },
  })

  return data.data
}

export function useGetMetrics(params: FiltersMetricsParams): UseQueryResult<GetMetricsResponse> {
  return useQuery(['metrics', params], () => fetchMetrics(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
