import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  WrapItem,
} from '@chakra-ui/react'
import { formatStatusFreightExpense } from '../../../../../../services/endpoints/freights/financial/getAllFinancialTitles'
import { useGetOneFreightExpenseStatusHistory } from '../../../../../../services/endpoints/freights/financial/history-by-status/getOneFinancialTitleStatusHistory'

interface ShowFinancialHistoryModalProps {
  isOpen: boolean
  onClose: () => void
  freight_expense_id: string
}

export function ShowFinancialHistoryModal({
  isOpen,
  onClose,
  freight_expense_id,
}: ShowFinancialHistoryModalProps): JSX.Element {
  const { data: FreightExpense } = useGetOneFreightExpenseStatusHistory(freight_expense_id)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dados</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth="300px" spacing={10}>
            {FreightExpense && FreightExpense.status && (
              <WrapItem>
                <Text color="gray.400">Status:</Text>
                <Text fontWeight="medium" ml="1">
                  {formatStatusFreightExpense(FreightExpense?.status)}
                </Text>
              </WrapItem>
            )}
            <WrapItem>
              <Text color="gray.400">Operador:</Text>
              <Text fontWeight="medium" ml="1">
                {FreightExpense?.user?.name}
              </Text>
            </WrapItem>
            <WrapItem>
              <Text color="gray.400">Data:</Text>
              <Text fontWeight="medium" ml="1">
                {FreightExpense?.created_at}
              </Text>
            </WrapItem>

            <WrapItem>
              <Text color="gray.400">Motivo:</Text>
              <Text fontWeight="medium" ml="1">
                {FreightExpense?.observation}
              </Text>
            </WrapItem>
            {/* <WrapItem>
              <Text color="gray.400">Anexo:</Text>
              {FreightExpense?.attachment_file &&
              !['png', 'jpg', 'jpeg'].includes(
                FreightExpense?.attachment_file,
              ) ? (
                <Link to={FreightExpense?.attachment_file_url} target="_blank">
                  <Icon fontSize="5xl" as={GrAttachment} />
                </Link>
              ) : (
                FreightExpense?.attachment_file_url && (
                  <Image src={FreightExpense?.attachment_file_url} />
                )
              )}
            </WrapItem> */}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
