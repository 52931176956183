import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseListApiType } from '../../../types'
import { FreightTrackerDevicesType } from '../../../types/TrackerDevicesType'

export interface FiltersFreightTrackerDevices {
  tracker_id?: string
  freight_id?: string
  per_page?: number
  page?: number
  unpaged?: boolean
}
export interface ReturnFormattedFreightTrackerDevices extends FreightTrackerDevicesType {
  created_at_formatted: string
}

type GetFreightTrackerDevicesResponse = {
  total: number
  data: ReturnFormattedFreightTrackerDevices[]
}

export async function getFreightTrackerDevices(
  params: FiltersFreightTrackerDevices,
): Promise<GetFreightTrackerDevicesResponse> {
  const { data: FreightTrackerDevices } = await apiServer.get<
    ResponseListApiType<FreightTrackerDevicesType[]>
  >(`/freight-tracker-devices`, {
    params,
  })
  const { total } = FreightTrackerDevices.data
  const FreightTrackerDevicesFormatted = FreightTrackerDevices.data.data.map(
    (relation: FreightTrackerDevicesType) => {
      return {
        ...relation,
        created_at_formatted: format(new Date(relation.created_at), 'dd/MM/yy'),
      } as ReturnFormattedFreightTrackerDevices
    },
  )
  return { data: FreightTrackerDevicesFormatted, total }
}

export function useGetFreightTrackerDevices(
  params: FiltersFreightTrackerDevices,
): UseQueryResult<GetFreightTrackerDevicesResponse> {
  return useQuery(['list-freight-tracker-devices', params], () => getFreightTrackerDevices(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes
  })
}
