import axios, { AxiosError } from 'axios'

const { REACT_APP_BASE_API, REACT_APP_BASE_API_SERVER } = process.env

const api = axios.create({
  baseURL: REACT_APP_BASE_API,
})

api.interceptors.response.use(
  response => {
    return response
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

const apiServer = axios.create({
  baseURL: REACT_APP_BASE_API_SERVER,
})

api.interceptors.response.use(
  response => {
    return response
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export { api, apiServer }
