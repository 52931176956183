import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from '../store'
import { ApiCarsRequestContextProvider } from './ApiCarsRequestContext'
import { AuthProvider } from './AuthContext'
import { GlobalVariablesProvider } from './GlobalVariables'
import { PendenciesOCRContextProvider } from './PendenciesOCRContext'
import { SidebarDrawerProvider } from './SidebarDrawerContext'

export const AppProvider: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalVariablesProvider>
          <PendenciesOCRContextProvider>
            <ApiCarsRequestContextProvider>
              <AuthProvider>
                <SidebarDrawerProvider>{children}</SidebarDrawerProvider>
              </AuthProvider>
            </ApiCarsRequestContextProvider>
          </PendenciesOCRContextProvider>
        </GlobalVariablesProvider>
      </PersistGate>
    </Provider>
  )
}
