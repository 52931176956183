import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { AdvanceRequestStatusEnum } from '../../types/EnumTypes'

type UpdateAdvanceRequestType = {
  id: string
  data: {
    reason?: string
    account_digit?: string | null
    account?: string | null
    agency?: string | null
    account_type?: string | null
    bank?: string | null
    pix?: string | null
    payment_type?: string | null
    e_frete?: boolean
    status?: AdvanceRequestStatusEnum
    reject_reason?: string | null
    observation?: string | null
    payment_observation?: string | null
  }
}

async function updateAdvanceRequest(data: UpdateAdvanceRequestType) {
  const response = await apiServer.put(`/advance-requests/${data.id}`, data.data)
  queryClient.invalidateQueries('advance-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePutAdvanceRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateAdvanceRequestType, unknown>,
) {
  return useMutation(async (data: UpdateAdvanceRequestType) => updateAdvanceRequest(data), options)
}
