/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'
import { MotoristAttachmentType, ResponseApiType } from '../../../types'

export interface CreateAttachToMotorist {
  motorist_id: string
  type: string
  name: string
  attachment_file: File
}

async function createAttachmentToMotorist({
  motorist_id,
  type,
  name,
  attachment_file,
}: CreateAttachToMotorist) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  formData.append('motorist_id', motorist_id)
  formData.append('type', type)
  formData.append('name', name)
  return apiServer.post<ResponseApiType<MotoristAttachmentType>>('/motorists/attachments', formData)
}

export function useCreateAttachmentToMotorist(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateAttachToMotorist, unknown>,
) {
  return useMutation(async (data: CreateAttachToMotorist) => createAttachmentToMotorist(data), options)
}
