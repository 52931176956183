export const attachmentFreightOptions = [
  { label: '', value: '' },
  { label: 'CT-e', value: 'ct-e' },
  { label: 'CIOT', value: 'ciot' },
  {
    label: 'Comprovante pag. Adiantamento',
    value: 'proof_paid_advance',
  },
  {
    label: 'Comprovante pag. Diária',
    value: 'proof_paid_daily',
  },
  {
    label: 'Comprovante pag. Entrega',
    value: 'proof_paid_delivery',
  },
  { label: 'Comprovante pag. GNRE', value: 'proof_paid_GNRE' },
  {
    label: 'Comprovante pag. Saldo',
    value: 'proof_paid_balance',
  },
  {
    label: 'Comprovante solicitação. Pagamento',
    value: 'proof_request_payment',
  },
  { label: 'Contrato de Frete', value: 'freight_contract' },
  {
    label: 'Fotos do caminhão lacrado',
    value: 'pictures_sealed_truck',
  },
  { label: 'Guia GNRE', value: 'GNRE_guide' },
  {
    label: 'MDF-e',
    value: 'mdf-e',
  },
  { label: 'NF-e', value: 'nf-e' },
  { label: 'Ordem de coleta', value: 'collecting_order' },
  { label: 'Recibo de descarga', value: 'unloading_receipt' },
  { label: 'Ticket de portaria', value: 'concierge_ticket' },
  { label: 'Outro', value: 'other' },
]

const addStub = [...attachmentFreightOptions, { label: 'Canhoto do frete', value: 'stub' }]

export const getLabelAttachmentFreight = (value: string): string => {
  const option = addStub.find(opt => opt.value === value)
  return option?.label || ''
}
