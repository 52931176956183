import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react'
import StateHandlerComponent from '../../../components/UI/StateHandlerComponent'
import { useGetPermissionsByRoles } from '../../../services/endpoints/authorization/getPermissionsByRoles'
import { UserRolesOptions } from '../../../services/types/AuthorizationModulesTypes'

interface DrawerRole {
  isOpen: boolean
  onClose: () => void
  role: UserRolesOptions
}

const DrawerRole = ({ isOpen, onClose, role }: DrawerRole): JSX.Element => {
  const { data, isError, isLoading } = useGetPermissionsByRoles({
    roles: [role],
  })

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{role}</DrawerHeader>

          <DrawerBody>
            <StateHandlerComponent loading={isLoading} error={isError} hasData={!!data}>
              <Text size="sm" color="gray.300" fontWeight="bold">
                Esse cargo pode fazer:
              </Text>
              <Box>
                {data?.can?.map(can => (
                  <Text key={can.action}>{`${can.module}: ${can.action}`}</Text>
                ))}
              </Box>
              <Text mt={4} size="sm" color="gray.300" fontWeight="bold">
                Esse cargo não pode fazer:
              </Text>
              <Box>
                {data?.cannot?.map(can => (
                  <Text key={can.action}>{`${can.module}: ${can.action}`}</Text>
                ))}
              </Box>
            </StateHandlerComponent>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default DrawerRole
