import { queryClient } from '../../../config/react-query'
import { fetchClient } from './getClient'

// PREFETCH DOS DADOS (Carregar com antecedencia e guardar em estado global por 10 minutos)
export async function handlePrefetchClient(client_id: string): Promise<void> {
  await queryClient.prefetchQuery(
    ['client', client_id],
    async () => {
      const res = await fetchClient(client_id)
      return res
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  )
}
