import {
  Button,
  Collapse,
  Flex,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { RiArrowDownSLine, RiArrowUpSLine, RiAttachmentLine, RiErrorWarningLine } from 'react-icons/ri'
import { DateStatus } from '.'
import { SetNextStatusModal } from '../../../pages/freights/show/cards/selected-motorist/SetNextStatusModal'
import { FreightAttachmentsTypeEnum } from '../../../services/types/EnumTypes'
import { useAppSelector } from '../../../store'
import { CheckFreightIsExpired } from '../../CheckFreightIsExpired'
import { AccordionTables } from './accordionTables/AccordionTables'

const phaseStatus = 'on_road'

export function OnRoadPhase(): JSX.Element {
  const { isOpen: showMoreInfo, onToggle: toggleMoreInfo } = useDisclosure()
  const { freight, statusHistory, isLoading } = useAppSelector(state => state.freightSlice)
  const filteredAttachments = freight?.attachments?.filter(
    attach => attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.DEFAULT,
  )
  const filteredOccurences = freight?.attachments?.filter(
    attach =>
      attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.OCCURRENCE,
  )
  const history = statusHistory?.find(statusOfPhase => statusOfPhase.status === 'in_destination')
  return (
    <Flex justifyContent="space-between" direction="column" mb="4">
      <Flex direction="row" gridGap="2">
        <Text
          onClick={toggleMoreInfo}
          cursor="pointer"
          size="sm"
          color={
            ['in_destination', 'delivered', 'finished'].includes(String(freight?.status))
              ? 'green.500'
              : 'gray.400'
          }
          fontWeight="bold"
          fontSize="sm"
        >
          <Button
            color="white"
            backgroundColor={
              ['in_destination', 'delivered', 'finished'].includes(String(freight?.status))
                ? 'green.500'
                : 'gray.400'
            }
            borderRadius="100%"
            size="xs"
            mr="4"
          >
            4
          </Button>
          Chegou para descarregar
        </Text>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          direction={['column', 'row']}
          gridGap="1"
          mb={['-5', '0']}
        >
          {isLoading
            ? freight?.status === 'on_road' && <Spinner size="md" color="orange" />
            : filteredAttachments &&
              filteredAttachments.length > 0 && (
                <Tag size="sm" backgroundColor="orange" borderRadius="full">
                  <TagLeftIcon as={RiAttachmentLine} />
                  <TagLabel>{filteredAttachments.length}</TagLabel>
                </Tag>
              )}
          {isLoading ? (
            <Spinner size="md" color="red.400" />
          ) : (
            filteredOccurences &&
            filteredOccurences.length > 0 && (
              <Tag size="sm" mx="1" borderRadius="full" backgroundColor="red.400">
                <TagLeftIcon as={RiErrorWarningLine} />
                <TagLabel>{filteredOccurences.length}</TagLabel>
              </Tag>
            )
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="-3" mt="-2">
        <Flex ml="10" my="2" direction={['column', 'row']} gridGap="2" alignItems="center">
          {['on_road', 'in_destination', 'delivered', 'finished'].includes(String(freight?.status)) && (
            <>
              {['in_destination', 'delivered', 'finished'].includes(String(freight?.status)) ? (
                <>
                  {isLoading || isLoading ? (
                    freight?.status === 'on_road' && <Spinner size="md" />
                  ) : (
                    <DateStatus freight={freight} history={history} />
                  )}
                </>
              ) : (
                freight && <CheckFreightIsExpired freight={freight} />
              )}
            </>
          )}
          <Flex>{freight?.status === 'on_road' && <SetNextStatusModal />}</Flex>
        </Flex>

        <Flex mt="-7">
          <IconButton
            onClick={toggleMoreInfo}
            variant="ghost"
            size="sm"
            aria-label="More info"
            icon={!showMoreInfo ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
          />
        </Flex>
      </Flex>

      <Collapse in={showMoreInfo} animateOpacity>
        <AccordionTables showMoreInfo={showMoreInfo} toggleMoreInfo={toggleMoreInfo} status={phaseStatus} />
      </Collapse>
    </Flex>
  )
}
