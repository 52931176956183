import { Flex, Icon, Link, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { GiCommercialAirplane } from 'react-icons/gi'
import {
  RiBriefcaseLine,
  RiSmartphoneLine,
  RiSteering2Line,
  RiStopFill,
  RiTruckLine,
  RiUser2Line,
} from 'react-icons/ri'
import { EditOrDuplicateFreightModal } from '../../../pages/freights/edit/EditOrDuplicateFreightModal'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { formattedTransporter } from '../../../services/utils/formattedTransporter'
import parseCurrency from '../../../services/utils/parseCurrency'
import { Container } from './Container'
import { DefaultPropCards } from './DefaultPropCards'
import FreightIcons from './FreightIcons'

interface KanbanCardFinishedProps {
  freight: FormattedAllFreights
}

export function KanbanCardFinished({ freight }: KanbanCardFinishedProps): JSX.Element {
  const bg = useColorModeValue('gray.200', 'gray.500')

  const [actionFreightType, setActionFreightType] = useState<'edit' | 'duplicate'>('edit')

  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  return (
    <Container
      freight={freight}
      header={
        <DefaultPropCards
          freight={freight}
          type="finished"
          bg={bg}
          setActionFreightType={setActionFreightType}
          onFreightFormModalOpen={onFreightFormModalOpen}
        />
      }
    >
      <Flex justifyContent="space-between" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
        <Text fontSize="xs" as={Link} href={`/motorists/show/${freight?.motorist?.id}`} target="_blank">
          <Flex direction="row">
            <Icon as={RiSteering2Line} fontSize="md" mr="1" />
            {freight?.motorist?.has_app && (
              <Tooltip hasArrow label="Motorista possui o app instalado">
                <Flex>
                  <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                    <Flex direction="row">
                      <Icon as={RiSmartphoneLine} fontSize="md" mr="1" />
                    </Flex>
                  </Text>
                </Flex>
              </Tooltip>
            )}
            <Tooltip hasArrow label={freight?.motorist?.name}>
              {String(freight?.motorist?.name).split(' ')[0]}
            </Tooltip>
          </Flex>
        </Text>

        <FreightIcons
          motoristPhone={freight?.motorist?.phone.toString()}
          hasMonitoringApp={freight.has_monitoring_app}
          hasMonitoringBuonny={freight.has_monitoring_buonny}
          hasMonitoringTrackerDevices={freight.has_monitoring_tracker_devices}
          hasMonitoringWhatsApp={freight.has_monitoring_whatsapp}
        />

        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiTruckLine} fontSize="md" mr="1" />
            {freight.agreed_price &&
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
                .format(freight.agreed_price)
                .split(',')[0]}
          </Flex>
        </Text>
      </Flex>

      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        borderBottomColor={bg}
        borderBottomWidth={1}
        pb="0.5"
        mb="0.5"
      >
        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiUser2Line} fontSize="sm" mr="1" /> {freight.creator?.name?.split(' ')[0]}
          </Flex>
        </Text>

        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiBriefcaseLine} fontSize="md" mr="1" />
            {freight.service_price && parseCurrency(freight.service_price)}
          </Flex>
        </Text>
      </Flex>
      {freight.type === 'aerial' && (
        <Flex fontSize="xs" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
          <Text mr={2} fontWeight="bold">
            Frete Aéreo
          </Text>
          <Flex mt="0.5">
            <GiCommercialAirplane />
          </Flex>
        </Flex>
      )}
      {(freight.transporter || freight.calculation_ref) && (
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          fontSize="xs"
          borderBottomColor={bg}
          borderBottomWidth={1}
          pb="0.5"
          mb="0.5"
        >
          {freight.transporter && <Text>{formattedTransporter(freight.transporter)}</Text>}
          {freight.calculation_ref && (
            <Tooltip label="Ir para a calculadora">
              <Text as={Link} href={freight.calculation_ref}>
                <Icon as={AiOutlineCalculator} color="orange" fontSize="md" mr="1" />
              </Text>
            </Tooltip>
          )}
        </Flex>
      )}

      {freight.finished_at && (
        <Flex justifyContent="space-between" direction="row">
          <Text fontSize="xs">
            <Flex direction="row">
              <Icon as={RiStopFill} fontSize="md" mr="1" /> {freight.formatted_finished_at}
            </Flex>
          </Text>
          {/* <Text fontSize="xs">
            <Flex direction="row">
              <Icon as={RiBriefcaseLine} fontSize="md" mr="1" /> 20%
            </Flex>
          </Text> */}
        </Flex>
      )}
      {freight && (
        <EditOrDuplicateFreightModal
          type={actionFreightType}
          freight_id={freight.id}
          isOpen={isFreightFormModalOpen}
          onClose={() => {
            onFreightFormModalClose()
          }}
        />
      )}
    </Container>
  )
}
