export const displayAdaptable = [
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'block',
  'table',
]

export const boxWidth = ['auto', 'auto', 'auto', 'auto', 1070, 1100, 'auto']

export const tableWidthQuery = {
  base: '100%',
  sm: '100%',
  md: '100%',
  lg: '100%',
  xl: '1084px',
  '2xl': '1084px',
}
