/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../../api'
import { FreightExpensesAttachmentsTypeEnum } from '../../../../types/EnumTypes'
import { FreightExpenseAttachmentsType } from '../../../../types/FreightExpenseType'

export interface UploadAttachToFreightExpense {
  freight_expense_id: string
  type: FreightExpensesAttachmentsTypeEnum
  file: File
}

async function uploadAttachmentsToFreightExpense({
  freight_expense_id,
  type,
  file,
}: UploadAttachToFreightExpense) {
  const formData = new FormData()
  formData.append('attachment', file)
  formData.append('freight_expense_id', freight_expense_id)
  formData.append('type', type)
  return apiServer.post<FreightExpenseAttachmentsType>('/create-freight-expense-attachment', formData)
}

export function useUploadAttachmentsToFreightExpense(
  options?: UseMutationOptions<AxiosResponse, unknown, UploadAttachToFreightExpense, unknown>,
) {
  return useMutation(
    async (data: UploadAttachToFreightExpense) => uploadAttachmentsToFreightExpense(data),
    options,
  )
}
