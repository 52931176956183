/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

type DeleteClientBusinessGroupParams = {
  id: string
}

async function deleteClientBusinessGroup(params: DeleteClientBusinessGroupParams) {
  return apiServer.delete(`/clients/client-business-group/${params.id}`)
}

export function useDeleteClientBusinessGroup(
  params: UseMutationOptions<AxiosResponse, unknown, DeleteClientBusinessGroupParams, unknown>,
) {
  return useMutation(async (data: DeleteClientBusinessGroupParams) => deleteClientBusinessGroup(data), params)
}
