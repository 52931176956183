import { useQuery, UseQueryResult } from 'react-query'
import { truckpadApi } from '../../truckpadApi'

interface CheckBalanceType {
  Code: string
  Status: string
  Message: string
  Value: string
}

export async function fetchCheckBalance(): Promise<CheckBalanceType> {
  const { data } = await truckpadApi.get<CheckBalanceType>(`/finances/balance`)

  return data
}

export function useGetCheckBalance(): UseQueryResult<CheckBalanceType> {
  return useQuery(['check_balance'], () => fetchCheckBalance(), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
    refetchOnWindowFocus: 'always',
  })
}
