import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { publicApiServer } from '../../publicApi'
import { FreightStatusHistoryType, ResponseApiType } from '../../types'

export function getFormattedReason(reason: string): string {
  if (reason === 'no_show') return 'No Show'
  if (reason === 'sinister') return 'Sinistro'
  if (reason === 'canceled_by_client') return 'Cancelado pelo cliente'
  return 'Outros'
}

export interface Coords {
  lat: string
  lng: string
}

export interface FormattedFreightStatusHistory extends FreightStatusHistoryType {
  created_at: string
  disabled_reason: string
  formatted_get_coords_freight_status?: Coords
  created_at_original: string
  formatted_status_date: string
}

export async function fetchOneFreightStatusHistory(
  freight_id: string,
  status?: string,
): Promise<FormattedFreightStatusHistory[]> {
  const { data: res } = await publicApiServer.get<ResponseApiType<FreightStatusHistoryType[]>>(
    `/list-freight-status-history`,
    { params: { status, freight_id } },
  )

  const statusHistory = res.data.map<FormattedFreightStatusHistory>(history => {
    return {
      ...history,
      created_at: history.created_at && format(new Date(history.created_at), "dd/MM 'às' HH:mm"),
      formatted_status_date: history.status_date
        ? format(new Date(history.status_date), "dd/MM 'às' HH:mm")
        : format(new Date(history.created_at), "dd/MM 'às' HH:mm"),
      created_at_original: history.created_at,
      disabled_reason: getFormattedReason(String(history.disabled_reason)),
      formatted_get_coords_freight_status: history?.get_coords_freight_status
        ? {
            lat: String(history?.get_coords_freight_status).split(',')[0],
            lng: String(history?.get_coords_freight_status).split(',')[1],
          }
        : undefined,
    }
  })

  return statusHistory
}

export function useGetFreightHistory(
  freight_id: string,
  status?: string,
): UseQueryResult<FormattedFreightStatusHistory[]> {
  return useQuery(
    ['freight-status-history', freight_id],
    () => fetchOneFreightStatusHistory(freight_id, status),
    {
      staleTime: 1000 * 60 * 5, // 10 minutes
      retry: false,
    },
  )
}
