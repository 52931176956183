import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { FormattedAllFreights } from './getFreights'

export async function getFreightByNumber(
  freight_number: string | number,
): Promise<FormattedAllFreights | undefined> {
  if (!freight_number || freight_number === 'undefined') return
  const { data: res } = await apiServer.get<ResponseApiType<FormattedAllFreights>>(`/find-freight`, {
    params: {
      freight_number,
    },
  })

  const { data } = res

  const createdAt = new Date(res.data.created_at)

  let link_motorist_at_hour
  let collect_item_at_hour
  let deliver_item_at_hour
  let formatted_link_motorist_at
  let input_collect_cargo_at
  let formatted_delivered_cargo_at
  let formatted_denied_at

  if (data.denied_at) {
    const disabledAt = new Date(String(data.denied_at))
    formatted_denied_at = format(disabledAt, 'dd/MM/yyyy HH:mm')
  }

  if (data.link_motorist_at) {
    const linkMotoristAt = new Date(data.link_motorist_at)
    formatted_link_motorist_at = format(linkMotoristAt, 'dd/MM')
    link_motorist_at_hour = format(linkMotoristAt, 'HH:mm')
  }

  if (data.collect_cargo_at) {
    const collectItemAt = new Date(data.collect_cargo_at)
    input_collect_cargo_at = format(collectItemAt, 'dd/MM')
    collect_item_at_hour = format(collectItemAt, 'HH:mm')
  }

  if (data.delivered_cargo_at) {
    const deliverItemAt = new Date(data.delivered_cargo_at)
    formatted_delivered_cargo_at = format(deliverItemAt, 'dd/MM')
    deliver_item_at_hour = format(deliverItemAt, 'HH:mm')
  }
  const freight = {
    ...data,
    status: data.status,
    origin_city:
      data.origin && data.origin.state ? `${data.origin.name} - ${data.origin.state.uf}` : 'Unknown',
    destination_city:
      data.destination && data.destination.state
        ? `${data.destination.name} - ${data.destination.state.uf}`
        : 'Unknown',
    formatted_suggested_price: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(data.suggested_price)),
    formatted_agreed_price: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(data.agreed_price)),
    formatted_vehicle: `${data.vehicle?.vehicle_category?.name} / ${data.vehicle?.vehicle_body?.name}`,
    created_at: format(createdAt, 'dd/MM/yyyy'),
    formatted_denied_at,
    created_at_hour: format(createdAt, 'HH:mm'),
    link_motorist_at_hour,
    collect_item_at_hour,
    deliver_item_at_hour,
    formatted_link_motorist_at,
    input_collect_cargo_at,
    formatted_delivered_cargo_at,
  }

  return freight
}

export function useGetFreightByNumber(freight_number: string | number): UseQueryResult<FormattedAllFreights> {
  return useQuery(['freight-by-number', freight_number], () => getFreightByNumber(freight_number), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retryDelay: 5000,
  })
}
