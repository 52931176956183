import { Heading, Text } from '@chakra-ui/react'
import { ResponseOcrCrlv } from '../../../../services/types'

type CNHinformationsProps = {
  CRLV?: Partial<ResponseOcrCrlv> | null
}

export const CRLVinformations = ({ CRLV }: CNHinformationsProps): JSX.Element => {
  if (!CRLV) return <></>

  //   renavam: Renavam,
  //   licensePlate: Placa, Placa ANTT,
  //   releaseYear: Ano fábrica,
  //   modelYear: Ano Modelo,
  //   axes: Eixos,
  //   brandName: Marca,
  //   brandModelVersion: Modelo,
  //   chassis: Chassi,
  //   capacity: capacity_m3,
  //   predominantColor: Cor,
  //   vehicleCategory: Categoria,

  return (
    <>
      <Heading fontSize="sm">
        Placa/ Placa ANTT:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.licensePlate}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Ano fábrica:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.manufactureYear}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Categoria:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.vehicleCategory}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Ano Modelo:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.modelYear}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Eixos:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.axes}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Marca
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.brandName}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Modelo:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.brandModelVersion}
        </Text>
      </Heading>

      <Heading fontSize="sm">
        Chassi:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.chassis}
        </Text>
      </Heading>

      <Heading fontSize="sm">
        Capacidade (kg):
        <Text as="span" color="gray.500" ml="2">
          {(Number(CRLV.capacity) * 1000)?.toString()}
        </Text>
      </Heading>
      <Heading fontSize="sm">
        Cor:
        <Text as="span" color="gray.500" ml="2">
          {CRLV?.predominantColor}
        </Text>
      </Heading>
    </>
  )
}
