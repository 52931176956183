import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { FormDatamex } from './datamex/FormDatamex'

type DatamexModalProps = {
  freight: FormattedOneFreight
  isOpen: boolean
  onClose: () => void
}
export const DatamexModal = ({ isOpen, onClose, freight }: DatamexModalProps): JSX.Element => {
  if (!isOpen) return <></>
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sicronizar com Datamex</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormDatamex freight={freight} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
