import { SelectOption } from '../../../components/form/types/SelectOption'
import { apiServer } from '../../api'

interface SelectOptionGroup {
  id: string
  category: string
}

export async function listVehicleCategoriesDatamex(): Promise<SelectOption[]> {
  const { data } = await apiServer.get<{ data: SelectOptionGroup[] }>(`/document-handler/vehicles/categories`)

  return data.data.map(category => {
    const label = category.category
    const value = category.id

    return { label, value }
  })
}
