import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import { ClientContactType } from '../../../types/ClientType'

export async function fetchClientContact(client_contact_id: string): Promise<ClientContactType> {
  const { data } = await apiServer.get<ResponseApiType<ClientContactType>>(
    `find-client-contacts-by-id/${client_contact_id}`,
  )

  return data.data
}

export function useGetClientContact(client_contact_id: string): UseQueryResult<ClientContactType> {
  return useQuery(['contact_by_client', client_contact_id], () => fetchClientContact(client_contact_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
