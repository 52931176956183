import { localStorageKeys } from '../contexts/AuthContext'
import { Authorization } from '../services/types'
import { ActionsForModule, Modules } from '../services/types/AuthorizationModulesTypes'

function can<S extends Modules[0]>(module: S, action: ActionsForModule<S>): boolean {
  const permissions: Authorization = JSON.parse(localStorage.getItem(localStorageKeys.permissions) || '{}')
  if (permissions) {
    if (Object.keys(permissions).length === 0) return false
    let result = false
    if (permissions.cannot) {
      const ruleExists = permissions.cannot.find(c => {
        if (c.action === action || c.action === '*') {
          if (c.module === module || c.module === '*') {
            return true
          }
        }
        return false
      })
      if (ruleExists) return false
    }
    if (permissions.can) {
      result = permissions.can.some(c => {
        if (c.action === action || c.action === '*') {
          if (c.module === module || c.module === '*') {
            return true
          }
        }
        return false
      })
    }
    return result
  }
  return true
}

export function useAbility<S extends Modules[0]>(module: S, action: ActionsForModule<S>): boolean {
  return can(module, action)
}
