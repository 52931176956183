/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatch } from '..'
import { EvaluateMotoristData } from '../../pages/freights/show/cards/selected-motorist/EvaluateMotoristModal'
import { apiServer } from '../../services/api'
import {
  fetchCompatibleMotorists,
  FetchCompatibleMotoristsParams,
  GetCompatibleMotoristsResponse,
} from '../../services/endpoints/freights/getCompatibleMotorists'
import { fetchMotorist, FormattedMotorist } from '../../services/endpoints/motorists/getMotorist'
import { GetMotoristsResponse } from '../../services/endpoints/motorists/getMotorists'
import { MotoristType } from '../../services/types'

interface State {
  isLoading: boolean
  motoristId: string | null
  motorist: FormattedMotorist | null
  motorists: GetMotoristsResponse | null
  compatibleMotorists: GetCompatibleMotoristsResponse | null
}

const initialState: State = {
  isLoading: false,
  motoristId: null,
  motorist: null,
  motorists: null,
  compatibleMotorists: null,
}

const motoristSlice = createSlice({
  name: 'motorists',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },

    setCompatibleMotorists(state, action: PayloadAction<GetCompatibleMotoristsResponse | null>) {
      state.compatibleMotorists = action.payload
    },

    setMotoristId(state, action: PayloadAction<string>) {
      state.motoristId = action.payload
    },

    setMotorist(state, action: PayloadAction<FormattedMotorist | null>) {
      state.motorist = action.payload
    },

    setMotoristCompatible(state, action: PayloadAction<MotoristType[]>) {
      state.compatibleMotorists = {
        ...(state.compatibleMotorists as GetCompatibleMotoristsResponse),
        data: action.payload,
        total: 1,
      }
    },
  },
})

export const { setMotoristCompatible, setCompatibleMotorists, setMotorist, setMotoristId } =
  motoristSlice.actions
export default motoristSlice.reducer

function loading(isLoading: boolean) {
  dispatch(motoristSlice.actions.setIsLoading(isLoading))
}

export function getMotorist(motorist_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const motorist = await fetchMotorist(motorist_id)
      if (motorist) dispatch(motoristSlice.actions.setMotorist(motorist))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function evaluateMotorist(data: EvaluateMotoristData): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.post('/create-rate-freight', data)
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function getCompatibleMotorists(filters: FetchCompatibleMotoristsParams): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const motorists = await fetchCompatibleMotorists(filters)
      if (motorists) dispatch(setCompatibleMotorists(motorists))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}
