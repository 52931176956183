import {
  useToast,
  useColorModeValue,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState, useEffect, useRef } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AutocompleteMultiAsync, AutocompleteOption, InputMask } from '../../../components/form'
import { WaitQueryParams } from '../../../components/WaitQueryParams'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { TrailersFiltersParams } from '../../../services/endpoints/trailers/getAllTrailer'
import { getVehicleBodies } from '../../../services/endpoints/vehicles/getVehicleBodies'
import { ListTrailerAccordion } from './ListTrailerAccordion'

export interface FilterTrailersFormData {
  page?: number
  per_page?: number
  release_year?: number
  license_plate?: string
  vehicle_bodies?: AutocompleteOption[]
}

export const filterTrailersFormSchema = yup.object().shape({
  page: yup.number(),
  license_plate: yup.string(),
  per_page: yup.number(),
  vehicle_bodies: yup.array().of(yup.object()),
})

export function AllTrailers(): JSX.Element {
  const toast = useToast()
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    license_plate?: string
    page?: string
    vehicle_bodies?: string
    release_year?: string
  }>()

  const bg = useColorModeValue('white', 'gray.800')
  const { setValue, handleSubmit, formState, getValues, reset } = useForm({
    resolver: yupResolver(filterTrailersFormSchema),
  })
  const { errors, isSubmitting } = formState
  const [loadingQueryParams, setLoadingQueryParams] = useState(false)
  const [filters, setFilters] = useState<TrailersFiltersParams>({
    page: 1,
  })

  const initialLoadRef = useRef(true)

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilterTrailers: SubmitHandler<FilterTrailersFormData> = async data => {
    const dataFilters = {
      page: data.page,
      per_page: data.per_page,
      release_year: data.release_year,
      license_plate: data.license_plate,
      vehicle_bodies: data.vehicle_bodies?.map(i => i.value),
    }
    setFilters(prev => ({ ...prev, ...dataFilters, page: data.page || 1 } as TrailersFiltersParams))

    const queryParams = {
      license_plate: data.license_plate || undefined,
      page: data.page?.toString() || '1',
      vehicle_bodies: data.vehicle_bodies?.length ? JSON.stringify(data.vehicle_bodies) : undefined,
      release_year: data.release_year?.toString() || undefined,
    }

    updateQueryParams(queryParams)
  }

  useEffect(() => {
    if (initialLoadRef.current) {
      const licensePlateQuery = getParams('license_plate')
      const trailerBodiesQuery = getParams('vehicle_bodies')
      const releaseYearQuery = getParams('release_year')
      const pageQuery = getParams('page')

      const loadingQuery = async () => {
        try {
          const parsedTrailerBodies = trailerBodiesQuery ? JSON.parse(trailerBodiesQuery) : []
          const defaultValues = {
            license_plate: licensePlateQuery || '',
            vehicle_bodies: parsedTrailerBodies,
            release_year: releaseYearQuery ? Number(releaseYearQuery) : undefined,
            page: pageQuery ? Number(pageQuery) : 1,
          }
          reset(defaultValues)
        } catch (error) {
          toast({
            title: 'Erro ao carregar filtros',
            status: 'warning',
            description: 'Por favor, verifique os dados e tente novamente',
            isClosable: true,
            position: 'top-right',
          })
          updateQueryParams({
            license_plate: undefined,
            vehicle_bodies: undefined,
            release_year: undefined,
            page: undefined,
          })
        } finally {
          setLoadingQueryParams(false)
        }
      }

      if (licensePlateQuery || trailerBodiesQuery || releaseYearQuery || pageQuery) {
        setLoadingQueryParams(true)
        loadingQuery()
      }

      initialLoadRef.current = false
    }
  }, [getParams, reset, toast, updateQueryParams])

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" fontWeight="normal">
            Filtros
          </Heading>
        </Flex>
        <Divider my="4" />
        <WaitQueryParams condition={!loadingQueryParams}>
          <form onSubmit={handleSubmit(handleFilterTrailers)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="3" alignItems="center">
              <GridItem colSpan={[3, 3]}>
                <InputMask
                  name="license_plate"
                  mask="aaa9*99"
                  placeholder="Placa"
                  autoFocus
                  maskPlaceholder=""
                  initialValue={getValues('license_plate')}
                  error={errors.license_plate}
                  setValue={setValue}
                  uppercaseAll
                />
              </GridItem>
              <GridItem colSpan={[3, 3]}>
                <AutocompleteMultiAsync
                  name="vehicle_bodies"
                  error={errors.vehicle_bodies}
                  initialValue={getValues('vehicle_bodies')}
                  setValue={setValue}
                  loadOptions={getVehicleBodies}
                  placeholder="Carrocerias"
                />
              </GridItem>
              <GridItem colSpan={[3, 3]}>
                <InputMask
                  mask="9999"
                  placeholder="Ano"
                  maskPlaceholder=""
                  name="release_year"
                  initialValue={getValues('release_year')}
                  error={errors.release_year}
                  setValue={setValue}
                />
              </GridItem>
            </Grid>
            <Flex mt="4" justify="flex-end">
              <Button size="sm" type="submit" colorScheme="blue" isLoading={isSubmitting}>
                <Icon as={RiSearchLine} mr="2" />
                Filtrar
              </Button>
            </Flex>
          </form>
        </WaitQueryParams>
      </Box>

      <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8" overflowX="auto">
        <Flex mb="6" justify="space-between" align="center">
          <Heading size="md" fontWeight="normal">
            Lista de reboques
          </Heading>
        </Flex>
        <ListTrailerAccordion filters={filters} onChangePage={handleChangePage} />
      </Box>
    </Layout>
  )
}
