import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  ListItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiFileCopyLine, RiSearchLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Autocomplete } from '../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../components/form/AutocompleteAsync'
import { Input } from '../../components/form/Input'
import { InputNumberMask } from '../../components/form/InputNumberMask'
import { RadioGroup } from '../../components/form/RadioGroup'
import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { SelectOption } from '../../components/form/types/SelectOption'
import { useAuth } from '../../contexts/AuthContext'
import { Layout } from '../../layout'
import { apiServer } from '../../services/api'
import { searchCitiesByName } from '../../services/endpoints/cities/searchCities'
import { searchClientsByName } from '../../services/endpoints/clients/searchClients'
import { getAllUsers } from '../../services/endpoints/users/getAllUsers'
import { UserType } from '../../services/types'

interface Item {
  name: string
  slug: string
}

interface AllowedItems {
  content: {
    TIPO_TIPOS: {
      mercadorias: Item[]
    }
  }
}
interface ForbiddenItems {
  data: Array<string>
}

interface FormOption {
  label: string
  value: string | number
}

export interface CreateQuotationResponse {
  status: 'success' | 'error'
  data: {
    timestamp: string
    empresa_nome: SelectOption
    custo_nf: string
    mercadoria_outros_tipos: string
    carga_proibida: string
    operador_info: UserType
    insurance_ask: '1'
    slug: string
    info_frete: {
      locations: {
        origem: {
          ibge_id: number
          name: string
          lat: string
          lng: string
          is_capital: boolean
          state_ibge_id: number
          state: {
            ibge_id: number
            uf: string
            name: string
            lat: string
            lng: string
          }
        }
        destino: {
          ibge_id: number
          name: string
          lat: string
          lng: string
          is_capital: boolean
          state_ibge_id: number
          state: {
            ibge_id: number
            uf: string
            name: string
            lat: string
            lng: string
          }
        }
      }
      parametros_identifica_seguro: {
        tipo_mercadoria: string
        custo_nf: string
        origem_uf: string
        destino_uf: string
      }
      seguro: {
        mercadoria: {
          name: string
          grupo: string
        }
        frete: {
          regiao: string
          descricao: string
          estados: string
          cuidados: string[]
        }
        regras: {
          faixa: string
          regras: Array<string[]>
        }
      }
      rota: {
        params: {
          waypoints: string[]
          locations: {
            origem: {
              name: string
              ibge_id: number
            }
            destino: {
              name: string
              ibge_id: number
            }
          }
          typeOfLoad: string
          vehicleType: string
          axis: string
        }
        result: {
          totalTollPrice: string
          totalMinimumFreight: string
          distance: string
          duration: string
          success: boolean
          full_price: string
          locations: {
            origem: {
              name: string
              ibge_id: number
            }
            destino: {
              name: string
              ibge_id: number
            }
          }
          waypoints: string[]
        }
      }
      calculo: {
        params: {
          origem: string
          destino: string
          custo_nf: string
          valor_base: string
          tipo_valor_base: string
          proposta: string
          margem_contribuicao: string
        }
        result: {
          PF: {
            taxa_imposto_fixo: number
            background_check: string
            taxa_imposto_sobre_folha: number
            taxa_contador_motorista: string
            ICMS_taxa: number
            ICMS: string
            taxa_seguro: string
            aliquota_seguro: number
            valor_seguro: string
            valor_final: string
            custo_motorista: string
            valor_imposto_sobre_folha: string
            receita_liquida: string
            imposto_valor: number
            margem_contribuicao_valor: string
            valor_a_ser_cobrado: number
            valor_a_ser_cobrado__BRL: string
            ICMS_valor: number
            ICMS_valor__BRL: string
            valor_a_ser_cobrado_sem_icms: number
            valor_a_ser_cobrado_sem_icms__BRL: string
            valor_mercadoria: string
            margem_contribuicao: string
          }
          PJ: {
            taxa_imposto_fixo: number
            background_check: string
            taxa_imposto_sobre_folha: number
            taxa_contador_motorista: string
            ICMS_taxa: number
            ICMS: string
            taxa_seguro: string
            aliquota_seguro: number
            valor_seguro: string
            valor_final: string
            custo_motorista: string
            valor_imposto_sobre_folha: string
            receita_liquida: string
            imposto_valor: number
            margem_contribuicao_valor: string
            valor_a_ser_cobrado: number
            valor_a_ser_cobrado__BRL: string
            ICMS_valor: number
            ICMS_valor__BRL: string
            valor_a_ser_cobrado_sem_icms: number
            valor_a_ser_cobrado_sem_icms__BRL: string
            valor_mercadoria: string
            margem_contribuicao: string
          }
        }
      }
    }
  }
}

export interface CreateQuotationData {
  client: FormOption
  invoice: number
  origin: FormOption
  destination: FormOption
  buonny_cargo_type: FormOption
  item_description: string
  is_forbidden_item: number
}

export interface DataListQuotations {
  carga_proibida: boolean
  timestamp: number
  empresa_nome: {
    label: string
    value: string
  }
  origem: number
  destino: number
  custo_nf: number
  mercadoria_outros_tipos: string
  insurance_ask: number
  operador_info: UserType
  slug: string
  titulo: string
  info_frete: {
    locations: {
      origem: {
        ibge_id: number
        name: string
        lat: string
        lng: string
        is_capital: true
        state_ibge_id: 35
        state: {
          ibge_id: 35
          uf: string
          name: string
          lat: string
          lng: string
        }
      }
      destino: {
        ibge_id: number
        name: string
        lat: string
        lng: string
        is_capital: boolean
        state_ibge_id: number
        state: {
          ibge_id: number
          uf: string
          name: string
          lat: string
          lng: string
        }
      }
    }
    parametros_identifica_seguro: {
      tipo_mercadoria: string
      custo_nf: number
      origem_uf: string
      destino_uf: string
    }
    seguro: {
      mercadoria: {
        name: string
        grupo: string
      }
      frete: {
        regiao: string
        descricao: string
        estados: string
        cuidados: [string]
      }
      regras: {
        faixa: string
        regras: [[string, string], [string, string], [string, string]]
      }
    }
    calculo: {
      params: {
        origem: string
        destino: string
        custo_nf: string
        valor_base: string
        tipo_valor_base: string
        proposta: number
        margem_contribuicao: string
      }
      result: {
        PF: {
          taxa_imposto_fixo: number
          background_check: string
          taxa_imposto_sobre_folha: number
          taxa_contador_motorista: string
          ICMS_taxa: number
          ICMS: string
          taxa_seguro: string
          aliquota_seguro: number
          valor_seguro: string
          custo_motorista: string
          valor_imposto_sobre_folha: string
          receita_liquida: string
          imposto_valor: number
          margem_contribuicao_valor: string
          valor_a_ser_cobrado: number
          valor_a_ser_cobrado__BRL: string
          ICMS_valor: number
          ICMS_valor__BRL: string
          valor_a_ser_cobrado_sem_icms: number
          valor_a_ser_cobrado_sem_icms__BRL: string
          valor_mercadoria: string
          margem_contribuicao: string
        }
        PJ: {
          taxa_imposto_fixo: number
          background_check: string
          taxa_imposto_sobre_folha: number
          taxa_contador_motorista: string
          ICMS_taxa: number
          ICMS: string
          taxa_seguro: string
          aliquota_seguro: number
          valor_seguro: string
          custo_motorista: string
          valor_imposto_sobre_folha: string
          receita_liquida: string
          imposto_valor: number
          margem_contribuicao_valor: string
          valor_a_ser_cobrado: number
          valor_a_ser_cobrado__BRL: string
          ICMS_valor: number
          ICMS_valor__BRL: string
          valor_a_ser_cobrado_sem_icms: number
          valor_a_ser_cobrado_sem_icms__BRL: string
          valor_mercadoria: string
          margem_contribuicao: string
        }
      }
    }
    rota: {
      params: {
        waypoints: string[]
        locations: {
          origem: {
            name: string
            ibge_id: number
          }
          destino: {
            name: string
            ibge_id: number
          }
        }
        typeOfLoad: string
        vehicleType: string
        axis: 5
        vehicle: {
          label: string
          value: string
          axes: number
          model: string
        }
      }
      result: {
        totalTollPrice: string
        totalMinimumFreight: string
        totalGas: number
        distance: string
        duration: string
        tolls: [
          {
            name: string
            road: string
            km: number
            price: number
          },
          {
            name: number
            road: string
            km: number
            price: number
          },
          {
            name: string
            road: string
            km: 180
            price: number
          },
          {
            name: string
            road: string
            km: 204
            price: number
          },
          {
            name: string
            road: string
            km: number
            price: number
          },
        ]
        success: boolean
        full_price: string
        locations: {
          origem: {
            name: string
            ibge_id: number
          }
          destino: {
            name: string
            ibge_id: number
          }
        }
        waypoints: string[]
      }
    }
  }
  operador: string
}
export interface ListQuotations {
  status: 'success' | 'error'
  content: DataListQuotations[]
}

type FilterQuotationHistoryType = {
  origins: AutocompleteOption
  destinations: AutocompleteOption
  user: AutocompleteOption
  client: AutocompleteOption
}

const createQuotationFormSchema = yup.object().shape({
  client: yup.object().required('Campo obrigatorio'),
  invoice: yup.number().required('Campo obrigatorio'),
  origin: yup.object().required('Campo obrigatorio'),
  destination: yup.object().required('Campo obrigatorio'),
  buonny_cargo_type: yup.object().shape({}),
  item_description: yup.string(),
  is_forbidden_item: yup.boolean().required('Campo obrigatorio'),
})

export function CreateCalcQuotation(): JSX.Element {
  const { user } = useAuth()
  const bg = useColorModeValue('white', 'gray.800')
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(createQuotationFormSchema),
  })
  const toast = useToast()
  const [isChecked, setIsChecked] = useState(false)
  const [selectItems, setSelectItems] = useState<AutocompleteOption[]>([])
  const [listQuotations, setListQuotations] = useState<ListQuotations>()
  const [listQuotationsFilters, setListQuotationsFilters] = useState({})
  const [isLoadingListQuotations, setIsLoadingListQuotations] = useState<boolean>(false)
  const [listForbidden, setListForbidden] = useState<string[]>([])
  const [isClicked, setIsClicked] = useState(false)
  const [isForbiddenItem, setIsForbiddenItem] = useState(false)
  const history = useHistory()

  const { errors } = formState

  const {
    setValue: setValueQuotationHistory,
    handleSubmit: handleSubmitQuotationHistory,
    formState: formStateQuotationHistory,
  } = useForm({})

  const { errors: errosQuotationHistory } = formStateQuotationHistory

  const handleFilterQuotationHistory: SubmitHandler<FilterQuotationHistoryType> = async data => {
    setListQuotationsFilters({
      origin: data.origins?.value,
      destination: data.destinations?.value,
      client_id: data.client?.value,
      operator: data.user?.value,
    })

    setIsLoadingListQuotations(true)
  }

  useEffect(() => {
    const loadItems = async () => {
      const { data: allowedItems } = await apiServer.get<AllowedItems>(
        'https://sofix.com.br/api/spotx_calculo/?api=seguro&mercadoria=1',
      )
      const formattedAllowedItems = allowedItems.content.TIPO_TIPOS.mercadorias.map(item => {
        return {
          label: item.name,
          value: item.slug,
        }
      })
      setSelectItems(formattedAllowedItems)
      const { data: forbiddenItems } = await apiServer.get<ForbiddenItems>(
        'https://sofix.com.br/api/spotx_calculo/?api=seguro&mercadorias_proibidas=1',
      )
      setListForbidden(forbiddenItems.data)
      const { data: listQuotation } = await apiServer.get<ListQuotations>(
        'https://sofix.com.br/api/spotx_calculo/?api=calculo-historico',
        {
          params: {
            ...listQuotationsFilters,
          },
        },
      )
      setListQuotations(listQuotation)
      setIsLoadingListQuotations(false)
    }

    loadItems()
  }, [listQuotationsFilters])

  async function handleCreateQuotation(data: CreateQuotationData) {
    if (data.buonny_cargo_type === undefined && !isChecked) {
      toast({
        title: 'Selecione o tipo de mercadoria',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }
    if (data.item_description === undefined && isChecked) {
      toast({
        title: 'Descreva o tipo de mercadoria',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
      return
    }

    if (data.is_forbidden_item) {
      setIsForbiddenItem(Boolean(data.is_forbidden_item))
      return
    }

    const timestamp = new Date().getTime()

    const operador_info = {
      id: user.id,
      name: user.name,
    }

    const postRequest = {
      frete: {
        carga_proibida: data.is_forbidden_item,
        timestamp,
        empresa_nome: data.client,
        origem: data.origin.value,
        destino: data.destination.value,
        custo_nf: data.invoice,
        mercadoria_outros_tipos: !isChecked ? data.buonny_cargo_type?.value : data.item_description,
        insurance_ask: 1,
        operador_info,
      },
      id_operador: user.email,
    }

    const { data: responseSlug } = await apiServer.post<CreateQuotationResponse>(
      'https://sofix.com.br/api/spotx_calculo/?page=gera-proposta&mode=api',
      postRequest,
    )
    if (
      responseSlug.status === 'success' &&
      responseSlug?.data.info_frete.seguro.regras.regras
        .map(i => {
          return {
            role: i[0],
            aplication: i[1],
          }
        })
        .find(i => i.role.includes('Motorista Carreteiro / Autônomo') && i.aplication.includes('PROIBÍDO'))
    ) {
      toast({
        title: 'Restrição na apólice: ',
        description: `Não pode ser cotado devido a impedimento da apólice. Motivo: MOTORISTA AUTÔNOMO É PROIBÍDO.
        Contate seu coordenador!`,
        isClosable: true,
        position: 'top',
        status: 'error',
        duration: 7000,
      })
      return
    }

    if (responseSlug.status === 'success') {
      const { slug } = responseSlug.data
      history.push(`/freights/calculation/${slug}`)
    }
    if (
      responseSlug.status === 'success' &&
      responseSlug.data.info_frete.seguro.regras.regras.map(i => i)[1][1].includes('OPCIONAL')
    ) {
      toast({
        title: 'Proposta criada com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    }
    if (responseSlug.status === 'error' && data.invoice > 800000) {
      toast({
        title: `O valor (NF) de ${new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(data.invoice)} não foi previsto na tabela de seguros. Favor contatar seu coordenador!`,
        isClosable: true,
        position: 'top-right',
        status: 'error',
        duration: 9000,
      })
    }
  }

  async function replicateQuotation(data: DataListQuotations) {
    const timestamp = new Date().getTime()

    const operador_info = {
      id: user.id,
      name: user.name,
    }

    const postRequest = {
      frete: {
        carga_proibida: data.carga_proibida,
        timestamp,
        empresa_nome: {
          label: data.empresa_nome.label,
          value: data.empresa_nome.value,
        },
        origem: data.origem,
        destino: data.destino,
        custo_nf: data.custo_nf,
        mercadoria_outros_tipos: data.info_frete.seguro.mercadoria.name,
        insurance_ask: 1,
        operador_info,
      },
      id_operador: user.email,
    }

    const { data: responseSlug } = await apiServer.post<CreateQuotationResponse>(
      'https://sofix.com.br/api/spotx_calculo/?page=gera-proposta&mode=api',
      postRequest,
    )

    if (responseSlug.status === 'success') {
      const { slug } = responseSlug.data

      history.push(`/freights/calculation/${slug}`)
      toast({
        title: 'Cotação replicada com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    }

    if (responseSlug.status === 'error') {
      toast({
        title: 'Não foi possível replicar cotação! Tente novamente.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      })
    }
  }

  return (
    <Layout>
      <Box p="6" bg={bg} borderRadius="8">
        <Heading fontWeight="normal" size="lg">
          Calculadora de frete
        </Heading>
        <Divider my="4" />
        {!isForbiddenItem ? (
          <form onSubmit={handleSubmit(handleCreateQuotation)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="4" mt="6">
              <GridItem colSpan={[12, 12]}>
                <Heading fontWeight="normal" size="md">
                  Informações básicas
                </Heading>
              </GridItem>
              <GridItem colSpan={[12, 12, 3]}>
                <AutocompleteAsync
                  isRequired
                  name="client"
                  label="Cliente"
                  setValue={setValue}
                  error={errors.client}
                  loadOptions={searchClientsByName}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 3]}>
                <InputNumberMask
                  isRequired
                  isCashBRL
                  error={errors.invoice}
                  name="invoice"
                  label="Valor (NF)"
                  setValue={setValue}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 3]}>
                <AutocompleteAsync
                  isRequired
                  error={errors.origin}
                  name="origin"
                  label="Origem"
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>
              <GridItem colSpan={[12, 12, 3]}>
                <AutocompleteAsync
                  isRequired
                  error={errors.destination}
                  name="destination"
                  label="Destino"
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>
              <GridItem colSpan={[12, 12]}>
                <Heading fontWeight="normal" size="md">
                  Seguro
                </Heading>
              </GridItem>
              <GridItem colSpan={[12, 12]}>
                <Checkbox
                  isChecked={isChecked}
                  onChange={e => {
                    setIsChecked(e.target.checked)
                  }}
                >
                  Não encontrei o tipo de mercadoria condizente com o frete
                </Checkbox>
              </GridItem>
              {selectItems.length > 0 && (
                <GridItem colSpan={[12, 12]}>
                  {!isChecked && (
                    <Autocomplete
                      isRequired
                      placeholder="Selecione o tipo de mercadoria"
                      name="buonny_cargo_type"
                      isDisabled={isChecked}
                      label="Tipo de Mercadoria"
                      setValue={setValue}
                      options={selectItems}
                      error={errors.buonny_cargo_type}
                    />
                  )}
                </GridItem>
              )}

              {isChecked && (
                <GridItem colSpan={[12, 12]}>
                  <Input
                    isRequired
                    placeholder="Descreva o tipo de mercadoria"
                    error={errors.item_description}
                    name="item_description"
                    label="Descrição da Mercadoria"
                    setValue={setValue}
                  />
                </GridItem>
              )}
              <GridItem colSpan={12}>
                {isClicked && (
                  <Box mt="6">
                    <Text mb={4} fontSize={20}>
                      Atenção: a carga se enquadra em alguma dessas categorias?
                    </Text>
                    <UnorderedList>
                      {listForbidden.map(item => {
                        return <ListItem key={item}> {item} </ListItem>
                      })}
                    </UnorderedList>
                    <Flex direction="column">
                      <RadioGroup
                        isRequired
                        mt="6"
                        label="Atenção: a carga se enquadra em alguma dessas categorias citadas acima?"
                        direction="column"
                        name="is_forbidden_item"
                        error={errors.is_forbidden_item}
                        setValue={setValue}
                        options={[
                          { label: 'Sim, ela se enquadra', value: 1 },
                          { label: 'Não, ela NÃO se enquadra', value: 0 },
                        ]}
                      />
                    </Flex>

                    <Flex justifyContent="center" mt="6">
                      <Button type="submit" colorScheme="orange" isLoading={formState.isSubmitting}>
                        Avançar
                      </Button>
                    </Flex>
                  </Box>
                )}
              </GridItem>
            </Grid>
            <Flex justifyContent="center" mt="6">
              {!isClicked && (
                <Button onClick={() => setIsClicked(true)} colorScheme="orange">
                  Avançar
                </Button>
              )}
            </Flex>
          </form>
        ) : (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Não é possível prosseguir!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              A mercadoria a ser transportada é proibida pelo seguro. Consulte seu supervisor
            </AlertDescription>
          </Alert>
        )}
        <Divider my="6" />
        <Heading mb="5" fontWeight="normal" size="lg">
          Histórico de cotações
        </Heading>

        <form onSubmit={handleSubmitQuotationHistory(handleFilterQuotationHistory)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3" mb="4">
            <GridItem colSpan={[8, 2]}>
              <AutocompleteAsync
                name="origins"
                label="Cidade de origem"
                setValue={setValueQuotationHistory}
                error={errosQuotationHistory.origins}
                loadOptions={searchCitiesByName}
                placeholder="Origem"
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <AutocompleteAsync
                name="destinations"
                label="Cidade de destino"
                setValue={setValueQuotationHistory}
                error={errosQuotationHistory.destinations}
                loadOptions={searchCitiesByName}
                placeholder="Destino"
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <AutocompleteAsync
                name="client"
                label="Cliente"
                setValue={setValueQuotationHistory}
                error={errosQuotationHistory.client}
                loadOptions={searchClientsByName}
                placeholder="Cliente"
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <AutocompleteAsync
                name="user"
                label="Usuário"
                setValue={setValueQuotationHistory}
                error={errosQuotationHistory.user}
                loadOptions={getAllUsers}
                placeholder="Usuário"
              />
            </GridItem>
            <GridItem colSpan={[8, 1]} display="flex" alignItems="end">
              <Button type="submit" size="md" colorScheme="blue" leftIcon={<Icon as={RiSearchLine} />} mb="1">
                Filtrar
              </Button>
            </GridItem>
          </Grid>
        </form>

        <Table alignItems="center" size="sm" colorScheme="gray" variant="striped" d="block" overflowX="auto">
          <Thead>
            <Tr>
              <Th>Cliente</Th>
              <Th>Data e usuário</Th>
              <Th>Origem</Th>
              <Th>Destino</Th>
              <Th>Mercadoria</Th>
              <Th>Veiculo</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {!isLoadingListQuotations && listQuotations ? (
              listQuotations?.content?.map(quotation => (
                <Tr key={quotation.timestamp}>
                  <Td whiteSpace="nowrap">
                    <Tooltip label="Ver cotação" hasArrow>
                      <Link href={`/freights/calculation/${quotation.slug}`}>
                        {quotation.empresa_nome.label
                          ? quotation.empresa_nome.label.substring(0, 30)
                          : 'Ver cotação'}
                      </Link>
                    </Tooltip>
                  </Td>
                  <Td fontSize="xs" whiteSpace="nowrap">
                    <Box gridGap={4}>
                      <Text mb="3">
                        {format(new Date(Number(quotation.timestamp)), "dd/MM/yyyy 'às' HH:mm")}
                      </Text>
                      <Text>{quotation?.operador_info?.name}</Text>
                    </Box>
                  </Td>
                  <Td whiteSpace="nowrap">{quotation.info_frete.locations.origem.name}</Td>
                  <Td whiteSpace="nowrap">{quotation.info_frete.locations.destino.name}</Td>
                  <Td whiteSpace="nowrap">
                    <Tooltip
                      label={
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: quotation.info_frete.seguro.mercadoria.name,
                          }}
                        />
                      }
                      hasArrow
                    >
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: quotation.info_frete.seguro.mercadoria.name.substring(0, 13),
                        }}
                      />
                    </Tooltip>
                  </Td>
                  <Td fontSize="xs" whiteSpace="nowrap">
                    <VStack gridGap={1}>
                      <Text>{quotation.info_frete.rota?.params.vehicle.label}</Text>
                      <Text>{quotation.info_frete.rota?.params.vehicle.model}</Text>
                    </VStack>
                  </Td>
                  <Td>
                    <Tooltip label="Replicar" hasArrow>
                      <Button
                        fontSize="lg"
                        colorScheme="blue"
                        onClick={() => {
                          replicateQuotation(quotation)
                        }}
                      >
                        <Icon as={RiFileCopyLine} />
                      </Button>
                    </Tooltip>
                  </Td>
                </Tr>
              ))
            ) : (
              <Flex mt="8" mb="8" w="100%">
                <Spinner size="md" mr={4} /> Carregando dados
              </Flex>
            )}
          </Tbody>
        </Table>
      </Box>
    </Layout>
  )
}
