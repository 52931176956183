import { apiServer } from '../../api'
import { UserType } from '../../types'
import { ResponseArrayApiType } from '../../types/ResponseApiType'

export interface UsersTypeSeller {
  label: string
  value: string
  role: string
}

export async function getUserSales(): Promise<UsersTypeSeller[]> {
  const { data: usersResponse } = await apiServer.get<ResponseArrayApiType<{ data: UserType[] }>>('/users', {
    params: { role: ['seller'] },
  })
  return usersResponse.data.data.map(user => {
    return {
      label: user.name,
      role: user.role,
      value: user.id,
    }
  })
}
