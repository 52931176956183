import { useEffect, useState } from 'react'
import { Grid, GridItem, useToast } from '@chakra-ui/react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'

import { useGetMonitoringOccurenceReasons } from '../../services/endpoints/freights/getMonitoringOccurenceReasons'

import { Select } from '../form/Select'
import { TextArea } from '../form/TextArea'

import { SelectOption } from '../form/types/SelectOption'

import { IMonitoringOccurences } from '../../services/types/MonitoringOccurenceType'
import { toastError } from '../../config/error/toastError'

interface IFormProps {
  setValue: UseFormSetValue<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
  monitoringOccurence?: IMonitoringOccurences
}

export function Form({ setValue, errors, monitoringOccurence }: IFormProps): JSX.Element {
  const [monitoringType, setMonitoringType] = useState<SelectOption>()
  const toast = useToast()

  const { data, isError, error } = useGetMonitoringOccurenceReasons(monitoringType?.value)

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  useEffect(() => {
    if (monitoringOccurence) {
      setMonitoringType({
        label: monitoringOccurence.reason.monitoring_type,
        value: monitoringOccurence.reason.monitoring_type,
      })
    }
  }, [monitoringOccurence])

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4">
      <GridItem colSpan={[12, 6]}>
        <Select
          isRequired
          options={[
            { label: '', value: '' },
            { label: 'App', value: 'app' },
            { label: 'Buonny', value: 'buonny' },
            { label: 'WhatsApp', value: 'whatsapp' },
          ]}
          name="monitoring_type"
          error={errors.monitoring_type}
          label="Tipo de monitoramento"
          setValue={setValue}
          onSelectOption={option => {
            if (option) {
              setMonitoringType(option)
            }
          }}
          initialValue={monitoringOccurence?.reason.monitoring_type}
        />
      </GridItem>

      {data?.length && (
        <GridItem colSpan={[12, 6]}>
          <Select
            isRequired
            options={data ? [{ label: '', value: '' }, ...data] : [{ label: '', value: '' }]}
            name="reason"
            label="Motivo"
            error={errors.reason}
            setValue={setValue}
            initialValue={monitoringOccurence?.reason.id}
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 12]}>
        <TextArea
          isRequired
          name="observation"
          label="Observação"
          error={errors.observation}
          setValue={setValue}
          height="150px"
          initialValue={monitoringOccurence?.observation}
        />
      </GridItem>
    </Grid>
  )
}
