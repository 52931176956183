export const formatDateToEUA = (date: string | undefined): string => {
  if (date) {
    const dateFormatted = String(date).split('/').reverse().join('-')

    return dateFormatted
  }
  return ''
}

export const formatDateToBR = (date?: string | Date): string => {
  if (date) {
    const dateFormatted = String(date).split('-').reverse().join('/')

    return dateFormatted
  }
  return ''
}
