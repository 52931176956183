/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

type UpdateClientBusinessGroupParams = {
  id?: string
  name: string
  segment_id?: string
  segment?: {
    name: string
  }
}

async function updateClientBusinessGroup(data: UpdateClientBusinessGroupParams) {
  const { id, ...rest } = data
  return apiServer.put(`/clients/client-business-group/${id}`, {
    ...rest,
  })
}

export function usePutClientBusinessGroup(
  params: UseMutationOptions<AxiosResponse, unknown, UpdateClientBusinessGroupParams, unknown>,
) {
  return useMutation(async (data: UpdateClientBusinessGroupParams) => updateClientBusinessGroup(data), params)
}
