import { ChangeStatusForm } from '.'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { FormattedQuotation } from '../../../services/endpoints/freights'

interface SetNextStatusModalProps {
  isOpen: boolean
  onClose: () => void
  quotation: FormattedQuotation
  change_status?: SelectOption
}

export function ChangeStatusQuotationModal({
  isOpen,
  onClose,
  quotation,
  change_status,
}: SetNextStatusModalProps): JSX.Element {
  return (
    <ChangeStatusForm quotation={quotation} isOpen={isOpen} onClose={onClose} change_status={change_status} />
  )
}
