export type SpotHubActionsType = (typeof spotHubModule.actions)[number]

export const spotHubModule = {
  module: 'spotHub',
  actions: [
    'analyze-stub',
    'force-approve-risk-analysis',
    'show-filter-seller',
    'show-generate-report-seller',
    'show-generate-complete-report-ctes',
    'show-reprove-check-list-risk-analysis',
    'show-aprove-check-list-risk-analysis',
    'show-generate-report-ctes',
    'show-cancel-freight-expense',
    'show-pending-freight-expense',
    'show-save-attachments-freight-expense',
    'show-finish-freight-expense',
    'show-handle-paid-freight-expense',
    'show-request-proof-freight-expense',
    'show-reject-freight-expense',
    'show-force-status',
    'show-change-status-quotation',
    'show-force-disable-quotation',
    'show-back-status-quotation',
    'show-next-status-quotation',
    'show-delete-tracker-device',
    'show-delete-attachment',
    'show-create-receivable-bill',
    'create-observation-financial-title',
  ] as const,
}
