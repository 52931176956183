import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { publicApiServer } from '../../publicApi'
import { ResponseApiType, UserType } from '../../types'

export interface ChangePasswordFormData {
  id: string
  password: string
}

async function ChangePassword({ id, ...data }: ChangePasswordFormData) {
  const output = await publicApiServer.patch<ResponseApiType<UserType>>(`/users/change-password/${id}`, data)
  return output
}

export function useChangePassword(
  options?: UseMutationOptions<AxiosResponse, unknown, ChangePasswordFormData, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, ChangePasswordFormData, unknown> {
  return useMutation(async (data: ChangePasswordFormData) => ChangePassword(data), options)
}
