import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
  useDisclosure,
  Tag,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { queryClient } from '../../../../../config/react-query'
import { useGetAllFreightExpenseStatusHistory } from '../../../../../services/endpoints/freights/financial/history-by-status/getAllFinancialTitlesStatusHistory'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'
import { ShowFinancialHistoryModal } from './modals/ShowFinancialHistoryModal'

interface TableListHistoryProps {
  freight_expense_id: string
}

export function TableListFinancialHistory({ freight_expense_id }: TableListHistoryProps): JSX.Element {
  const { data: financialHistory } = useGetAllFreightExpenseStatusHistory({
    freight_expense_id,
  })
  const [idToUpdateFreightExpense, setIdToShowFinancialHistory] = useState<string>()
  const {
    isOpen: isOpenShowFinancialHistory,
    onClose: onCloseShowFinancialHistory,
    onOpen: onOpenShowFinancialHistory,
  } = useDisclosure()

  useEffect(() => {
    queryClient.invalidateQueries('list-all-financial-title-history')
  }, [])

  return (
    <>
      <Table size="sm" variant="striped" d={displayAdaptable} overflowX="auto" maxW={1100}>
        <Thead>
          <Tr>
            <Th />
            <Th>Status</Th>
            <Th>Operador</Th>
            <Th>Data</Th>
            <Th>Observação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {financialHistory &&
            financialHistory.data.map(item => {
              return (
                <Tr key={item.id}>
                  <Td whiteSpace="nowrap">
                    <Flex gridGap={2} direction="column" alignItems="center">
                      <Tooltip hasArrow label="Mais detalhes">
                        <Box
                          cursor="pointer"
                          color="orange"
                          onClick={() => {
                            onOpenShowFinancialHistory()
                            setIdToShowFinancialHistory(item.id)
                          }}
                        >
                          <Icon fontSize="2xl" as={AiOutlineEye} />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Td>

                  <Td whiteSpace="nowrap">
                    <Text fontSize="md">
                      <Tag colorScheme={item.color_status} p="2" borderRadius="full" gridGap={2}>
                        <Text fontSize="md" fontWeight="bold">
                          {item?.status}
                        </Text>
                      </Tag>
                    </Text>
                  </Td>

                  <Td whiteSpace="nowrap">
                    <Text fontSize="md">{item?.user?.name}</Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="md">{item?.created_at}</Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Flex gridGap={2} direction="column" alignItems="center">
                      {item?.observation &&
                      item.observation !== 'Sem pendencias' &&
                      item?.observation.length > 30 ? (
                        <Text fontSize="md" fontWeight="bold">
                          {item.observation.substring(0, 30)}...
                        </Text>
                      ) : (
                        item.observation !== 'Sem pendencias' && (
                          <Text fontSize="md" fontWeight="bold">
                            {item.observation}
                          </Text>
                        )
                      )}
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
      {idToUpdateFreightExpense && (
        <ShowFinancialHistoryModal
          isOpen={isOpenShowFinancialHistory}
          onClose={onCloseShowFinancialHistory}
          freight_expense_id={idToUpdateFreightExpense}
        />
      )}
    </>
  )
}
