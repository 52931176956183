import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { RiskAnalysisHistoryType } from '../../../types/RiskAnalysisType'
import { colorStatusRiskAnalysis, formatStatusRiskAnalysis } from '../getAllRiskAnalysis'
import { ResponseArrayApiType } from '../../../types/ResponseApiType'

export interface RiskAnalysisHistoryFormatted extends RiskAnalysisHistoryType {
  color_status: string
}

export interface FiltersAllRiskAnalysisHistoryParams {
  risk_analysis_id?: string
}

export async function fetchAllRiskAnalysisHistory(
  params?: FiltersAllRiskAnalysisHistoryParams,
): Promise<RiskAnalysisHistoryFormatted[]> {
  const { data: res } = await apiServer.get<ResponseArrayApiType<RiskAnalysisHistoryFormatted[]>>(
    `/risk-analysis/history/${params?.risk_analysis_id}`,
  )

  const riskAnalysis = res.data.map((item: RiskAnalysisHistoryFormatted) => {
    return {
      ...item,
      created_at: format(new Date(item.created_at), 'dd/MM/yyyy - HH:mm'),
      color_status: colorStatusRiskAnalysis(item.status),
      status: formatStatusRiskAnalysis(item.status),
    }
  })

  return riskAnalysis
}

export function useGetAllRiskAnalysisHistory(
  params: FiltersAllRiskAnalysisHistoryParams,
): UseQueryResult<RiskAnalysisHistoryFormatted[]> {
  return useQuery(['list-risk-analysis-history', params], () => fetchAllRiskAnalysisHistory(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
