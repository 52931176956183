/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  RiArrowLeftLine,
  RiCheckLine,
  RiCloseCircleLine,
  RiEditLine,
  RiFileCopyLine,
  RiMore2Fill,
  RiTimeLine,
} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { Ability } from '../../ability'

interface DefaultPropCardsProps {
  freight: FormattedAllFreights
  type: 'active' | 'current' | 'finished' | 'no-show' | 'disabled'
  bg?: 'gray.200' | 'gray.500'
  onDisableFreightModalOpen?: () => void
  onEnableFreightModalOpen?: () => void
  onFreightFormModalOpen: () => void
  setActionFreightType: React.Dispatch<React.SetStateAction<'edit' | 'duplicate'>>
  onOpenUnlinkMotorist?: () => void
}

export function DefaultPropCards({
  freight,
  type,
  bg,
  onDisableFreightModalOpen,
  onEnableFreightModalOpen,
  onFreightFormModalOpen,
  setActionFreightType,
  onOpenUnlinkMotorist,
}: DefaultPropCardsProps): JSX.Element {
  return (
    <>
      <Flex justifyContent="space-between" bg={bg}>
        <Flex>
          <Popover>
            <Tooltip hasArrow label="Opções">
              <Flex fontSize="xs" ml="-1" fontWeight="bold" cursor="pointer" alignItems="center">
                <PopoverTrigger>
                  <Icon as={RiMore2Fill} />
                </PopoverTrigger>
              </Flex>
            </Tooltip>
            <PopoverContent>
              <PopoverArrow />

              <PopoverContent>
                <PopoverHeader>Opções</PopoverHeader>
                <Flex w="100%" justifyContent="space-between" p={3}>
                  {['active', 'current'].includes(type) && !!onDisableFreightModalOpen && (
                    <Button
                      w="31%"
                      size="sm"
                      colorScheme="red"
                      leftIcon={<Icon as={RiCloseCircleLine} />}
                      onClick={() => {
                        onDisableFreightModalOpen()
                      }}
                    >
                      Desativar
                    </Button>
                  )}

                  {['no-show', 'disabled'].includes(type) && !!onEnableFreightModalOpen && (
                    <Button
                      size="sm"
                      colorScheme="green"
                      leftIcon={<Icon as={RiCheckLine} />}
                      onClick={() => {
                        onEnableFreightModalOpen()
                      }}
                    >
                      Reativar
                    </Button>
                  )}

                  <Ability module="operation" action="create-freight">
                    <Button
                      w={type === 'finished' ? '48%' : '31%'}
                      size="sm"
                      colorScheme="blue"
                      leftIcon={<Icon as={RiFileCopyLine} />}
                      onClick={() => {
                        onFreightFormModalOpen()
                        setActionFreightType('duplicate')
                      }}
                    >
                      Duplicar
                    </Button>
                  </Ability>

                  <Ability module="operation" action="update-freight">
                    <Button
                      w={type === 'finished' ? '48%' : '31%'}
                      size="sm"
                      colorScheme="yellow"
                      leftIcon={<Icon as={RiEditLine} />}
                      onClick={() => {
                        onFreightFormModalOpen()
                        setActionFreightType('edit')
                      }}
                    >
                      Editar
                    </Button>
                  </Ability>
                </Flex>
                {type === 'current' && (
                  <Flex justifyContent="space-between" p={3}>
                    {!!onOpenUnlinkMotorist && (
                      <>
                        Motorista:
                        <Button
                          colorScheme="red"
                          leftIcon={<Icon as={RiArrowLeftLine} />}
                          onClick={() => {
                            onOpenUnlinkMotorist()
                          }}
                          size="xs"
                        >
                          Desvincular
                        </Button>
                      </>
                    )}
                  </Flex>
                )}
              </PopoverContent>
            </PopoverContent>
          </Popover>

          <Tooltip hasArrow label={`Ver o frete de ${freight.client.name}`}>
            <Text
              ml="1"
              fontSize="xs"
              fontWeight="bold"
              cursor="pointer"
              as={Link}
              to={`/freights/show/${freight.id}`}
            >
              #{freight.freight_number} {String(freight.client.nickname).substring(0, 18)}
            </Text>
          </Tooltip>
        </Flex>
        <Text fontSize="xs">
          <Flex direction="row" alignItems="center">
            <Icon as={RiTimeLine} fontSize="xs" mr="1" /> {freight.created_at} {freight.created_at_hour}
          </Flex>
        </Text>
      </Flex>

      <Flex justifyContent="space-between" direction="row" pt="0" fontSize="xs">
        <Text fontWeight="bold">
          {String(freight.origin_city).length > 18
            ? String(freight.origin_city).substring(0, 18).split('-')
            : String(freight.origin_city)}{' '}
          {String(freight.origin_city).length > 18 && ` - ${freight.origin.state.uf}`}{' '}
        </Text>
        <Text fontWeight="bold">
          {String(freight.destination_city).length > 18
            ? String(freight.destination_city).substring(0, 18).split('-')
            : String(freight.destination_city)}{' '}
          {String(freight.destination_city).length > 18 && ` - ${freight.destination.state.uf}`}
        </Text>
      </Flex>
    </>
  )
}
