/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SelectOption } from '../../components/form/types/SelectOption'
import { IReasonStatusModalProps } from '../../pages/risk-analysis/modals/ReasonStatusModal'
import { RiskAnalysisOneFormatted } from '../../services/endpoints/risk-analysis/getOneRiskAnalysis'

interface State {
  isOpen: boolean
  nextStatus: SelectOption | null
  riskAnalysisId: string | null
  reasonModalProps: IReasonStatusModalProps | null
  riskAnalysis: RiskAnalysisOneFormatted | null
}

const initialState: State = {
  isOpen: false,
  nextStatus: null,
  riskAnalysisId: null,
  reasonModalProps: null,
  riskAnalysis: null,
}

const riskAnalysisSlice = createSlice({
  name: 'risk_analysis',
  initialState,
  reducers: {
    setRiskAnalysisId(state, action: PayloadAction<string | null>) {
      state.riskAnalysisId = action.payload
    },

    setOpen(state) {
      state.isOpen = true
    },

    setClose(state) {
      state.isOpen = false
    },

    setReasonStatusModalProps(state, action: PayloadAction<IReasonStatusModalProps | null>) {
      state.reasonModalProps = action.payload
    },

    setNextStatus(state, action: PayloadAction<SelectOption | null>) {
      state.nextStatus = action.payload
    },

    setStatus(state, action: PayloadAction<string>) {
      state.riskAnalysis = {
        ...(state.riskAnalysis as RiskAnalysisOneFormatted),
        status: action.payload,
      }
    },

    setRiskAnalysis(state, action: PayloadAction<RiskAnalysisOneFormatted>) {
      state.riskAnalysis = action.payload
    },
  },
})

export const {
  setStatus,
  setNextStatus,
  setClose,
  setOpen,
  setRiskAnalysisId,
  setReasonStatusModalProps,
  setRiskAnalysis,
} = riskAnalysisSlice.actions
export default riskAnalysisSlice.reducer
