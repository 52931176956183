import { ApexOptions } from 'apexcharts'
import { theme } from '../../../../config/theme'

export const apexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    foreColor: theme.colors.gray[500],
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    formatter(val) {
      return `${Number(val).toFixed(0)}%`
    },
    textAnchor: 'middle',
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: undefined,
    },
    background: {
      enabled: true,
      foreColor: theme.colors.gray[700],
      padding: 4,
      borderRadius: 10,
      borderWidth: 0,
      borderColor: '#fff',
      opacity: 0.0,
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45,
    },
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    type: 'numeric',
    axisBorder: {
      color: theme.colors.gray[600],
    },
    axisTicks: {
      color: theme.colors.gray[600],
    },
  },
  colors: ['#4682B4', '#A9A9A9', '#FFA500'],
  labels: ['Rastreados', 'Não rastreados', 'Aéreos'],
  fill: {
    opacity: 1,
    type: 'gradient',
    gradient: {
      shade: 'light',
      opacityFrom: 3,
      opacityTo: 4,
    },
  },
} as ApexOptions
