import { Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip } from '@chakra-ui/react'
import { RiStarLine } from 'react-icons/ri'
import { GetClientMonthlyNpsResponse } from '../../../../services/endpoints/clients/client-monthly-nps/getAllClientMonthlyNps'

interface TableListProps {
  feedbacks?: GetClientMonthlyNpsResponse
}

export function AllClientMonthlyNpsTableList({ feedbacks }: TableListProps): JSX.Element {
  const bg = useColorModeValue('cyan', 'gray')

  return (
    <>
      {feedbacks?.data.length ? (
        <Table variant="striped" colorScheme={bg}>
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th>Cliente</Th>
              <Th>Solicitante</Th>
              <Th>Nota</Th>
              <Th>Motivo</Th>
            </Tr>
          </Thead>
          <Tbody>
            {feedbacks?.data?.map(feedback => (
              <Tr key={feedback.id}>
                <Td>{feedback.created_at}</Td>
                <Td>{feedback.client.name}</Td>
                <Td>{feedback.client_contact?.name}</Td>
                <Td>
                  <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                    {feedback.recommendation_score}
                    <Icon as={RiStarLine} fontSize={20} mx="1" />
                  </Text>
                </Td>
                <Td>
                  {feedback.reason && (
                    <Tooltip hasArrow label={feedback.reason} color="gray.700" bg="gray.300" placement="top">
                      <Text>
                        {String(feedback.reason).substring(0, 30)}
                        {feedback.reason.length > 30 ? '...' : undefined}
                      </Text>
                    </Tooltip>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text align="center">Nenhum dado encontrado</Text>
      )}
    </>
  )
}
