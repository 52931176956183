import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export async function updateAuthorizationFiles(): Promise<void> {
  await apiServer.get<ResponseApiType<void>>('/authorization/update-files')
}

export function useUpdateAuthorizationFiles(): UseQueryResult<void, unknown> {
  return useQuery(['updateAuthorizationFiles'], () => updateAuthorizationFiles(), {
    staleTime: undefined,
    retryOnMount: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: false,
    enabled: false,
  })
}
