import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  ThemeTypings,
} from '@chakra-ui/react'
import { useRef } from 'react'

interface AlertDialogConfirmProps extends Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'> {
  title: string
  description?: string
  onConfirm: () => void
  confirmLabel?: string
  cancelLabel?: string
  colorScheme?: ThemeTypings['colorSchemes']
  isLoading?: boolean
}

export function AlertDialogConfirm({
  title,
  description,
  onConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  colorScheme = 'red',
  onClose,
  isLoading,
  ...rest
}: AlertDialogConfirmProps): JSX.Element {
  const leastDestructiveRef = useRef(null)

  return (
    <AlertDialog leastDestructiveRef={leastDestructiveRef} onClose={onClose} {...rest}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          {description && <AlertDialogBody>{description}</AlertDialogBody>}

          <AlertDialogFooter>
            <Button onClick={onClose}>{cancelLabel}</Button>
            <Button
              colorScheme={colorScheme}
              onClick={() => {
                onConfirm()
                onClose()
              }}
              ml={3}
              isLoading={isLoading}
            >
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
