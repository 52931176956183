import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { ColumnResizeMode, flexRender, HeaderGroup, Table } from '@tanstack/react-table'
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa'
import { IoMdEyeOff } from 'react-icons/io'
import TableFilter from './TableFilter'
import useTableColors from './useTableColors'

interface TableHeaderProps {
  headerGroup: HeaderGroup<any>
  table: Table<any>
  columnResizeMode: ColumnResizeMode
}

const TableHeader = ({ headerGroup, table, columnResizeMode }: TableHeaderProps): JSX.Element => {
  const { headerBackgroundColor, borderColor } = useTableColors()
  return (
    <Box display="inline-flex" key={headerGroup.id} position="sticky" top={0} zIndex={1}>
      {headerGroup.headers.map(header => (
        <Flex
          display="inline-flex"
          p={2}
          px={4}
          position="relative"
          borderColor={borderColor}
          borderRightWidth={1}
          borderBottomWidth={1}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          flexDirection="column"
          bg={headerBackgroundColor}
          justifyContent="space-between"
          _last={{ borderRightWidth: 0 }}
          key={header.id}
          {...{
            style: {
              width: header.getSize(),
            },
          }}
          minWidth={20}
        >
          <Flex justifyContent="space-between" alignItems="center" gridGap={2}>
            <Text
              cursor="pointer"
              flex={1}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              size="sm"
              fontWeight="bold"
              onClick={() => header.column.toggleSorting()}
            >
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </Text>
            {{
              asc: <FaSortAmountDown size={10} />,
              desc: <FaSortAmountUp size={10} />,
            }[header.column.getIsSorted() as string] ?? null}
            {header.column.getCanHide() ? (
              <IconButton
                size="sm"
                backgroundColor="transparent"
                aria-label="columns"
                borderColor={borderColor}
                onClick={() => header.column.toggleVisibility()}
                icon={<IoMdEyeOff />}
                __css={{}}
              />
            ) : null}
          </Flex>

          {header.column.getCanFilter() ? <TableFilter column={header.column} /> : null}
          <Box
            {...{
              onDoubleClick: () => header.column.resetSize(),
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),

              position: 'absolute',
              right: 0,
              top: 0,
              height: '500px',
              width: 2,
              background: 'transparent',
              cursor: 'col-resize',
              zIndex: 1,
              userSelect: 'none',
              touchAction: 'none',
              _hover: {
                background: 'blackAlpha.300',
              },
              style: {
                zIndex: header.column.getIsResizing() ? 999 : 0,
                transform:
                  columnResizeMode === 'onEnd' && header.column.getIsResizing()
                    ? `translateX(${
                        (table.options.columnResizeDirection === 'rtl' ? -1 : 1) *
                        (table.getState().columnSizingInfo.deltaOffset ?? 0)
                      }px)`
                    : '',
              },
            }}
          />
        </Flex>
      ))}
    </Box>
  )
}

export default TableHeader
