import { Alert, AlertIcon, AlertTitle, Badge, Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { useVehicleCategoriesCombox } from '../../../hooks/useVehicleCategoriesCombox'
import { Layout } from '../../../layout'
import { useCheckAllPendencies, useGetOneFreight } from '../../../services/endpoints/freights'
import { useGetMotorist } from '../../../services/endpoints/motorists/getMotorist'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { TrailerType } from '../../../services/types/TrailerType'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  getPendencies,
  setFreight,
  setMotorist,
  setTrailer,
  setVehicle,
  setVehicleOwner,
} from '../../../store/slices/freightPendenciesSlice'
import FreightPendenciesAlert from './components/FreightPendenciesAlert'
import { Header } from './components/Header'
import { PedenciesStep, PedenciesStepStart, PedenciesStepWrapper } from './components/PedenciesStep'

export interface Params {
  freight_id: string
}

const PendenciesError = () => {
  return (
    <Alert status="error">
      <Box>
        <Flex>
          <AlertIcon />
          <AlertTitle mr={2}>Algo deu errado!</AlertTitle>
        </Flex>
        <Box>
          <Text>
            Não foi possível encontrar as dependências do frete. Por favor, tente novamente. Se o problema
            persistir, entre em contato com o suporte.
          </Text>
        </Box>
      </Box>
    </Alert>
  )
}

const FreightPendenciesNEW = (): JSX.Element => {
  const { freight_id } = useParams<Params>()

  // STATES
  const [motoristId, setMotoristId] = useState<string | ''>(useQueryParams('motorist_id'))
  const [vehicleId, setVehicleId] = useState<string | ''>(useQueryParams('vehicle_id'))

  let integration = useQueryParams('integration')

  if (!integration) {
    integration = 'buonny'
  }

  // HOOKS
  useVehicleCategoriesCombox()
  const dispatch = useAppDispatch()
  const {
    motorist_id,
    vehicle_id,
    routePointsHasPendencies,
    anttOwnerHasPendencies,
    has_pendencies,
    ...store
  } = useAppSelector(state => state.freightPendenciesSlice)

  const { data: freight } = useGetOneFreight({
    freight_id,
    relations: [
      'origin',
      'destination',
      'client',
      'vehicle',
      'trailer',
      'vehicle.owner',
      'vehicle.antt_owner',
      'trailer.owner',
      'motorist.driver_license',
    ],
  })

  // QUERYS

  const {
    data: motorist,
    isLoading: isLoadingMotorist,
    isFetching: isFetchingMotorist,
    isError: isErrorMotorist,
  } = useGetMotorist(motoristId, {
    onSuccess: data => {
      if (!data) return
      // if (data?.vehicles[0]?.id && data.vehicles.length === 1 && !vehicleId) {
      //   setVehicleId(data.vehicles[0].id)
      // }
      dispatch(setMotorist(data))
    },
  })

  const {
    isLoading: isLoadingVehicle,
    isError: isErrorVehicle,
    isFetching: isFetchingVehicle,
  } = useGetVehicle(vehicleId, {
    onSuccess: data => {
      if (!data) return
      dispatch(setVehicle(data))
      const { owner, trailers, vehicle_category } = data
      const { has_trailer } = vehicle_category
      if (owner) dispatch(setVehicleOwner(owner))
      if (has_trailer) {
        if (trailers && trailers.length > 0) {
          dispatch(setTrailer(trailers))
        } else {
          dispatch(setTrailer([{ id: 'placeholder' } as TrailerType]))
        }
      } else {
        dispatch(setTrailer([]))
      }
    },
  })

  const {
    data: checkPendencies,
    isLoading: isLoadingPendencies,
    isFetching: isFetchingPendencies,
    isError: isErrorPendencies,
    refetch: refetchPendencies,
  } = useCheckAllPendencies({
    freight_id,
    motorist_id: motoristId,
    vehicle_id: vehicleId,
  })

  // HELPERS
  const shortName = (name: string) => {
    if (name) {
      const nameSplited = name.split(' ')

      if (nameSplited.length < 2) return name
      return `${nameSplited[0]} ${nameSplited[1]}`
    }
  }

  // SIDE EFFECTS

  useEffect(() => {
    refetchPendencies()
  }, [refetchPendencies])

  useEffect(() => {
    if (motorist) {
      dispatch(getPendencies(checkPendencies, freight, motorist))
    }
  }, [checkPendencies, dispatch, freight, motorist, isFetchingPendencies, isLoadingPendencies])

  useEffect(() => {
    if (motorist_id) setMotoristId(motorist_id)
  }, [motorist_id])

  useEffect(() => {
    if (vehicle_id) setVehicleId(vehicle_id)
  }, [vehicle_id])

  useEffect(() => {
    if (freight) dispatch(setFreight(freight))
  }, [freight, dispatch])

  const encodeLocation = (location: any) => {
    const locationParams = {
      lat: location.lat,
      lng: location.lng,
      uf: location.state.uf,
      label: location.name,
      country: 'BRA',
      cityName: location.name,
    }
    return encodeURIComponent(JSON.stringify(locationParams))
  }

  const buildRoutePlannerUrl = () => {
    const originParams = encodeLocation(freight?.origin)
    const destinationParams = encodeLocation(freight?.destination)
    localStorage.removeItem('currentUrl')
    localStorage.setItem('currentUrl', window.location.href)
    return (
      `/pricing/route-planner?freight_id=${freight?.id}` +
      `&freight_number=${freight?.freight_number}` +
      `&origin=${originParams}` +
      `&destination=${destinationParams}` +
      `&freight_status=${freight?.status}` +
      `&vehicle_plate=${store.vehicle?.license_plate || ''}` +
      `&isPendenciesPage=${true}`
    )
  }
  // TODO: MOVE O COMPONENTE QUE CHECA AS PENDENCIAS PARA OUTRO COMPONENTE EVITANDO RENDERS

  return (
    <Layout>
      <Header
        freightId={freight?.id}
        freightNumber={freight?.freight_number.toString()}
        motoristId={motorist?.id}
        vehicleId={store.vehicle?.id}
      />
      <Box my="4">
        <Skeleton isLoaded={!isLoadingPendencies}>
          {isErrorPendencies ? (
            <Alert variant="subtle" status="error" borderRadius="md">
              <AlertIcon />
              <AlertTitle mr={2} fontSize="lg">
                Erro ao carregar pendências!
              </AlertTitle>
            </Alert>
          ) : (
            <>{has_pendencies && <FreightPendenciesAlert pendencies={checkPendencies} />}</>
          )}
        </Skeleton>
      </Box>

      {isErrorPendencies ? (
        <PendenciesError />
      ) : (
        <Box mt="6">
          <PedenciesStepStart
            status={!has_pendencies ? 'success' : 'pending'}
            title={
              !has_pendencies ? 'Todos as pendências foram resolvidos' : 'Existe pendências para esse frete'
            }
            isLoading={isLoadingPendencies || isFetchingPendencies}
            loadingLabel="checando pendências nos cadastros..."
          />
          <PedenciesStepWrapper>
            <PedenciesStep
              isError={isErrorMotorist}
              title="Motorista"
              type="motorist"
              status={store.motorist ? 'success' : 'pending'}
              isLoading={isLoadingMotorist || isFetchingMotorist}
              actionLabel={store.motorist ? 'Editar Motorista' : 'Cadastrar Motorista'}
              badges={
                <>
                  {store.motorist && <Badge>CPF: {store.motorist?.cpf}</Badge>}
                  {store.motorist && <Badge>{shortName(store.motorist?.name)}</Badge>}
                </>
              }
            />
            <PedenciesStep
              title="Veículo"
              type="vehicle"
              isError={isErrorVehicle}
              status={store.motorist ? (store.vehicle ? 'success' : 'pending') : 'disabled'}
              isLoading={isLoadingVehicle || isFetchingVehicle}
              actionLabel={store.vehicle ? 'Editar Veículo' : 'Cadastrar Veículo'}
              badges={<>{store.vehicle && <Badge>Placa: {store.vehicle?.license_plate}</Badge>}</>}
            >
              <PedenciesStep
                title="Proprietário do veículo"
                type="owner"
                isError={isErrorVehicle}
                isLoading={isLoadingVehicle}
                status={store.owner ? 'success' : store.vehicle ? 'pending' : 'disabled'}
                actionLabel={store.owner ? 'Editar Proprietário' : 'Cadastrar Proprietário'}
                badges={
                  <>
                    {store.owner && <Badge>nome: {shortName(store.owner?.name)}</Badge>}
                    {store.owner && <Badge>Doc: {store.owner?.cnpj || store.owner?.cpf}</Badge>}
                  </>
                }
              />
            </PedenciesStep>
            {store.trailers &&
              store.trailers.length > 0 &&
              store.trailers.map(trailer => (
                <PedenciesStep
                  type="trailer"
                  title="Reboque"
                  isError={isErrorVehicle}
                  status={trailer.renavam && store.vehicle && store.owner ? 'success' : 'pending'}
                  isLoading={isLoadingVehicle}
                  actionLabel={trailer.renavam ? 'Editar Reboque' : 'Cadastrar Reboque'}
                  badges={<>{trailer.renavam && <Badge>Placa: {trailer?.license_plate}</Badge>}</>}
                >
                  <PedenciesStep
                    title="Proprietário do reboque"
                    type="ownerTrailer"
                    actionLabel={
                      trailer.owner_id
                        ? 'Editar Proprietário do Reboque'
                        : 'Cadastrar Proprietário do Reboque'
                    }
                    status={trailer.owner_id ? 'success' : trailer.renavam ? 'pending' : 'disabled'}
                    badges={
                      <>
                        {trailer.owner && <Badge>Nome: {shortName(trailer.owner.name)}</Badge>}
                        {trailer.owner && <Badge>Doc: {trailer.owner?.cnpj || trailer.owner?.cpf}</Badge>}
                      </>
                    }
                  />
                </PedenciesStep>
              ))}
            <PedenciesStep
              title="ANTT"
              type="antt"
              isError={isErrorVehicle}
              status={
                store.motorist?.id && store.vehicle?.id && store.owner?.id
                  ? !anttOwnerHasPendencies
                    ? 'success'
                    : 'pending'
                  : 'disabled'
              }
              badges={
                <>
                  {store.vehicle?.antt_owner_id && <Badge>Nome: {store.vehicle?.antt_owner?.name}</Badge>}
                  {store.vehicle?.antt_owner_id && (
                    <Badge>Doc: {store.vehicle?.antt_owner?.cnpj || store.vehicle?.antt_owner?.cpf}</Badge>
                  )}
                </>
              }
              actionLabel={store.vehicle?.antt_owner_id ? 'Editar ANTT' : 'Cadastrar ANTT'}
            />
            <PedenciesStep
              title="Roterizador"
              status={
                store.vehicle?.antt_owner_id
                  ? !routePointsHasPendencies
                    ? 'success'
                    : 'pending'
                  : 'disabled'
              }
              actionLabel="Cadastrar rota"
              onAction={() => {
                window.location.href = buildRoutePlannerUrl() ?? ''
              }}
            />
            <Text mt="2" fontSize="sm" fontWeight="medium" color="gray.500">
              Integrações:
            </Text>

            <PedenciesStep
              title="Buonny"
              type="buonny"
              status={!has_pendencies ? 'info' : 'disabled'}
              actionLabel="Sincronizar Buonny"
            />

            <PedenciesStep
              title="Datamax"
              type="datamax"
              status={freight?.motorist_id && !has_pendencies ? 'info' : 'disabled'}
              actionLabel="Sincronizar Datamax"
            />
          </PedenciesStepWrapper>
        </Box>
      )}
    </Layout>
  )
}

export default FreightPendenciesNEW
