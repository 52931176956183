import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsPersonCheckFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { toastError } from '../../../config/error/toastError'
import { usePatchRiskAnalysis } from '../../../services/endpoints/risk-analysis/patchRiskAnalysis'

interface ApproveMotoristModalProps {
  risk_analysis_id: string
  isOpen: boolean
  onClose: () => void
}

export function ApproveMotoristModal({
  isOpen,
  onClose,
  risk_analysis_id,
}: ApproveMotoristModalProps): JSX.Element {
  const { setValue } = useForm({})
  const toast = useToast()
  const [aptMotorist, setAptMotorist] = useState<boolean>()
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()

  const handleDependsCheck = async () => {
    try {
      if (aptMotorist) {
        await mutateAsyncChangeStatus({
          id: risk_analysis_id,
          status: 'approved',
          consultation_code: 'PERFIL ADEQUADO AO RISCO',
        })

        onClose()
      } else {
        toast({
          title: 'O motorista só poderá ser aprovado caso esteja apto',
          status: 'error',
          position: 'top-right',
          isClosable: true,
          duration: 8000,
        })
        onClose()
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setAptMotorist(undefined)
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="md"
    >
      <ModalOverlay />
      error
      <ModalContent>
        <ModalHeader>Aprovar motorista</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth="300px" spacing={2}>
            <RadioGroup
              name="aptMotorist"
              label="O motorista está apto para o frete?"
              setValue={setValue}
              options={[
                { label: 'Sim', value: 'yes' },
                { label: 'Não', value: 'not' },
              ]}
              onSelectOption={op => {
                if (op.value === 'yes') {
                  setAptMotorist(true)
                } else if (op.value === 'not') {
                  setAptMotorist(false)
                } else {
                  setAptMotorist(undefined)
                }
              }}
            />
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          {aptMotorist ? (
            <Button
              colorScheme="green"
              isLoading={isLoadingChangeStatus}
              onClick={() => {
                handleDependsCheck()
              }}
              rightIcon={<BsPersonCheckFill />}
            >
              Aprovar
            </Button>
          ) : (
            <Button
              rightIcon={<FaArrowRight />}
              colorScheme="green"
              isLoading={isLoadingChangeStatus}
              isDisabled={aptMotorist === undefined}
              onClick={() => {
                handleDependsCheck()
              }}
            >
              Confirmar
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
