import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../../api'

import { CostRiskAnalysisType } from '../../../types'

interface IGetCostRiskAnalysisParams {
  risk_analysis_id: string | undefined
  freight_id?: string
  page: number
  per_page: number
  paid_out?: boolean
}

interface ICostRiskAnalysisResponse {
  data: CostRiskAnalysisType[]
  total: number
}

async function getCostRiskAnalysis(
  params: IGetCostRiskAnalysisParams,
): Promise<ICostRiskAnalysisResponse | undefined> {
  if (params.risk_analysis_id && params.risk_analysis_id !== 'undefined') {
    const { data: costRiskAnalysis } = await apiServer.get(
      `/list-cost-risk-analysis/${params.risk_analysis_id}`,
      {
        params: {
          page: params.page,
          per_page: params.per_page,
          paid_out: params.paid_out,
          freight_id: params.freight_id,
        },
      },
    )
    return costRiskAnalysis
  }
  return undefined
}

export function useGetCostRiskAnalysis(
  params: IGetCostRiskAnalysisParams,
): UseQueryResult<ICostRiskAnalysisResponse | undefined> {
  return useQuery(['cost-risk-analysis', params], () => getCostRiskAnalysis(params))
}
