import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import BlackListType from '../../types/BlackListType'

export function getFormatEntityType(type: string): string {
  if (type === 'motorist') return 'Motorista'
  if (type === 'owner') return 'Proprietário'
  return 'Veículo'
}

type GetBlackListResponse = {
  total: number
  data: BlackListType[]
}

export interface FiltersAllBlackListParams {
  initial_date?: Date
  final_date?: Date
  entity_type?: 'motorist' | 'owner' | 'vehicle'
  cpf?: string
  cnpj?: string
  motorist_id?: string
  owner_id?: string
  vehicle_id?: string
  creator_id?: string
  license_plate?: string
  page?: number
  per_page?: number
}

export async function fetchAllBlackList(params: FiltersAllBlackListParams): Promise<GetBlackListResponse> {
  const {
    data: { data },
  } = await apiServer.get<ResponseListApiType<BlackListType[]>>('/list-black-list', {
    params,
  })

  const { total } = data

  const blackList = data.data.map(cargo => {
    return {
      ...cargo,
      created_at: format(new Date(cargo.created_at), 'dd/MM/yyyy'),
    }
  })

  return { total, data: blackList }
}

export function useGetAllBlackList(params: FiltersAllBlackListParams): UseQueryResult<GetBlackListResponse> {
  return useQuery(['black-list', params], () => fetchAllBlackList(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: 'always',
  })
}
