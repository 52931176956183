import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../../components/form'
import { apiServer } from '../../../api'
import { LoadingRequestStatusEnum } from '../../../types/EnumTypes'
import { LoadingRequestType } from '../../../types/LoadingRequestType'
import { formatLoadingRequestStatus } from '../../../utils/formatLoadingRequestStatus'
import parseCurrency from '../../../utils/parseCurrency'

type LoadingRequestTypeFormatted = LoadingRequestType & {
  formatted_status: string
  total_value_formatted: string
  total_gross_formatted: string
}

type ListLoadingRequestResponse = {
  total: number
  data: LoadingRequestTypeFormatted[]
}

export type FilterLoadingRequestsType = {
  freight_number?: string
  status?: LoadingRequestStatusEnum
  beneficiary_id?: SelectOption
  creator_id?: SelectOption
  initial_date?: string
  final_date?: string
  motorist_id?: SelectOption
  cf_number?: string
  page?: number
}

async function listLoadingRequest(params: FilterLoadingRequestsType) {
  Object.assign(params, {
    ...params,
    motorist_id: params.motorist_id?.value,
    beneficiary_id: params.beneficiary_id?.value,
    creator_id: params.creator_id?.value,
  })

  const { data: response } = await apiServer.get(`/loading-requests`, {
    params,
  })

  const { data } = response
  const { total } = data

  const formattedLoadingRequests = data.data.map((loadingRequest: LoadingRequestType) => {
    return {
      ...loadingRequest,
      total_value_formatted: parseCurrency(loadingRequest.total_value),
      total_gross_formatted: parseCurrency(loadingRequest.total_gross),
      formatted_status: formatLoadingRequestStatus(loadingRequest.status),
      created_at: format(new Date(loadingRequest.created_at), 'dd/MM/yy HH:mm'),
    }
  })

  return { total, data: formattedLoadingRequests }
}

export function useGetAllLoadingRequest(
  params: FilterLoadingRequestsType,
): UseQueryResult<ListLoadingRequestResponse> {
  return useQuery(['loading-requests', params], () => listLoadingRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
