import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import {
  colorStatusRiskAnalysis,
  FiltersAllRiskAnalysisParams,
  formatStatusRiskAnalysis,
  GetRiskAnalysisResponse,
  RiskAnalysisFormatted,
} from './getAllRiskAnalysis'

export async function getAllMotoristInAnalysis(
  params: FiltersAllRiskAnalysisParams,
  has_motorist: boolean,
): Promise<GetRiskAnalysisResponse | null> {
  if (!has_motorist) {
    const { data } = await apiServer.get<ResponseListApiType<RiskAnalysisFormatted[]>>(
      '/list-risk-analysis',
      {
        params,
      },
    )

    const { total } = data.data

    const riskAnalysis = data.data.data.map((cargo: RiskAnalysisFormatted) => {
      return {
        ...cargo,
        created_at: format(new Date(cargo.created_at), 'dd/MM/yyyy - HH:mm'),
        color_status: colorStatusRiskAnalysis(cargo.status),
        status: formatStatusRiskAnalysis(cargo.status),
        depends_check:
          cargo.depends_on_check === true ? 'Sim' : cargo.depends_on_check === false ? 'Não' : '',
      }
    })

    return { total, data: riskAnalysis }
  }
  return null
}

export function useGetAllMotoristInAnalysis(
  params: FiltersAllRiskAnalysisParams,
  has_motorist: boolean,
): UseQueryResult<GetRiskAnalysisResponse> {
  return useQuery(
    ['list_motorist_in_analysis', params],
    () => getAllMotoristInAnalysis(params, has_motorist),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
      refetchOnWindowFocus: 'always',
      refetchIntervalInBackground: true,
    },
  )
}
