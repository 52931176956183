import { useToast } from '@chakra-ui/react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { toastError } from '../config/error/toastError'
import { searchVehicleByLicensePlate } from '../services/endpoints/vehicles/searchVehicleByLicensePlate'
import { GetResponseCarsApi } from '../services/types/GetResponseCarsApi'

interface PendenciesOCRContextProviderProps {
  children: ReactNode
}
interface ApiCarsRequestContextData {
  setLicensePlateVehicle: (type: string) => void
  setLicensePlateTrailer: (type: string) => void
  setCarsApi: (type: GetResponseCarsApi | undefined) => void
  setTrailerApi: (type: GetResponseCarsApi | undefined) => void
  isLoading: boolean
  carsApi?: GetResponseCarsApi
  trailerApi?: GetResponseCarsApi
}

const ApiCarsRequestContext = createContext({} as ApiCarsRequestContextData)

export function ApiCarsRequestContextProvider({ children }: PendenciesOCRContextProviderProps): JSX.Element {
  const toast = useToast()
  const [licensePlateVehicle, setLicensePlateVehicle] = useState<string>()
  const [licensePlateTrailer, setLicensePlateTrailer] = useState<string>()
  const [carsApi, setCarsApi] = useState<GetResponseCarsApi>()
  const [trailerApi, setTrailerApi] = useState<GetResponseCarsApi>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (licensePlateVehicle) {
      const load = async () => {
        try {
          setIsLoading(true)
          const dataCar = await searchVehicleByLicensePlate(licensePlateVehicle)
          setCarsApi(dataCar)
          toast({
            title: 'Dados buscados com sucesso!',
            isClosable: true,
            position: 'top-right',
            status: 'success',
            duration: 5000,
          })
          setIsLoading(false)
        } catch (error) {
          toastError({
            toast,
            error,
          })
          setIsLoading(false)
        }
      }
      load()
    }
  }, [licensePlateVehicle, toast])

  useEffect(() => {
    if (licensePlateTrailer) {
      const load = async () => {
        try {
          setIsLoading(true)
          const dataCar = await searchVehicleByLicensePlate(licensePlateTrailer)
          setTrailerApi(dataCar)
          toast({
            title: 'Dados buscados com sucesso!',
            isClosable: true,
            position: 'top-right',
            status: 'success',
            duration: 5000,
          })
          setIsLoading(false)
        } catch (error) {
          toastError({
            toast,
            error,
          })
          setIsLoading(false)
        }
      }
      load()
    }
  }, [licensePlateTrailer, toast])

  return (
    <ApiCarsRequestContext.Provider
      value={{
        setLicensePlateVehicle,
        setLicensePlateTrailer,
        isLoading,
        setCarsApi,
        setTrailerApi,
        carsApi,
        trailerApi,
      }}
    >
      {children}
    </ApiCarsRequestContext.Provider>
  )
}

export function useApiCarsRequestContext(): ApiCarsRequestContextData {
  const context = useContext(ApiCarsRequestContext)
  if (!context) throw new Error('usePendenciesOCRContext() must be used within PendenciesOCRContextProvider!')
  return context
}
