import { useHistory, useLocation } from 'react-router-dom'

interface useQueryParamUpdaterProps<T> {
  updateQueryParams: (params: { [key: string]: string | undefined }) => void
  upsertQueryParams: (params: { [key: string]: string | undefined }) => void
  getQueryParams: () => URLSearchParams
  getParams: (key: keyof T) => string
  addPage: (page: number) => void
  removeQueryParam: (key: string) => void
}

function removeEmptyProperties(obj: { [key: string]: any }) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null))
}
function useQueryParamUpdater<T>(): useQueryParamUpdaterProps<T> {
  const history = useHistory()
  const location = useLocation()

  const updateQueryParams = (params: { [key: string]: string | undefined }) => {
    const removedEmptyParams = removeEmptyProperties(params)

    const searchParams = new URLSearchParams(removedEmptyParams)
    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    })
  }

  const getQueryParams = () => {
    return new URLSearchParams(location.search)
  }

  const addPage = (page: number) => {
    const draft = getQueryParams()
    draft.set('page', String(page))
    history.push({
      pathname: location.pathname,
      search: `?${draft}`,
    })
  }

  const getAllQueryParams = (): Record<string, string> => {
    return Object.fromEntries(new URLSearchParams(location.search).entries())
  }

  const upsertQueryParams = (params: { [key: string]: string | undefined }) => {
    const allQueryParams = getAllQueryParams()

    // Remover propriedades vazias diretamente ao criar `newParams`
    const newParams = Object.entries({ ...allQueryParams, ...params })
      .filter(([, value]) => value !== undefined && value !== '')
      .reduce((acc, [key, value]) => {
        acc[key] = value as string
        return acc
      }, {} as Record<string, string>)

    const searchParams = new URLSearchParams(newParams)

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    })
  }

  const getParams = (key: keyof T) => {
    return getQueryParams().get(key as string) as string
  }

  const removeQueryParam = (key: string) => {
    const draft = getQueryParams()
    draft.delete(key as string)
    history.push({
      pathname: location.pathname,
      search: `?${draft}`,
    })
  }

  return { updateQueryParams, getQueryParams, getParams, addPage, upsertQueryParams, removeQueryParam }
}

export default useQueryParamUpdater
