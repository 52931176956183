import {
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

interface TextWithLimitProps {
  text: string
  limit: number
}

function TextWithLimit({ text, limit }: TextWithLimitProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isOverLimit = text.length > limit
  const displayText = isOverLimit ? `${text.slice(0, limit)}...` : text

  return (
    <Box>
      <Text>{displayText}</Text>
      {isOverLimit && (
        <Button
          variant="link"
          color="blue.500"
          size="sm"
          onClick={() => {
            onOpen()
          }}
          mt={1}
        >
          Ver mais
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Texto completo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{!!isOverLimit && text}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TextWithLimit
