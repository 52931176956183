/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

export interface UpdateClientFormData {
  id: string
  cnpj: string
  name: string
  nickname: string
  main_activity: string
  email?: string
  phone?: string
  zipcode?: string
  address?: string
  address_number?: number
  district?: string
  city?: string
  state?: string
  payment_method: string
  clientBusinessGroup?: {
    name: {
      label: string
      value?: string
    }
    segment?: {
      label: string
      value?: string
    }
    ddr?: boolean
    pocket_insurance?: string
    payment_deadline?: string
    effective_insurance?: string
  }
}

async function updateClient({ id, ...data }: UpdateClientFormData) {
  return apiServer.put(`/update-client/${id}`, data)
}

export function usePutClient(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateClientFormData, unknown>,
) {
  return useMutation(async (data: UpdateClientFormData) => updateClient(data), options)
}
