import { Icon } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import Leaflet from 'leaflet'
import ReactDomServer from 'react-dom/server'
import { SiGooglemaps } from 'react-icons/si'
import { QualpCityType } from './components/LocationManager'
import { decodePolyline } from '../services/decode-polyline-service'

export interface IRoutePlannerMapComponentQualpProps {
  origin: QualpCityType
  destination: QualpCityType
  polyline?: string
  places?: { lat: number; lng: number }[]
  zoom?: number
  showIcons?: boolean
  stopPlaces?: QualpCityType[]
}

const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function RoutePlannerMapComponentQualp({
  origin,
  destination,
  polyline,
  places,
  stopPlaces,
  zoom = 5,
  showIcons = true,
}: IRoutePlannerMapComponentQualpProps): JSX.Element {
  const [decodedPolyline, setDecodedPolyline] = useState<Array<{ lat: number; lng: number }>>([])

  useEffect(() => {
    if (polyline) {
      setDecodedPolyline(decodePolyline(polyline))
    } else if (places && places.length > 0) {
      setDecodedPolyline(places)
    }
  }, [places, polyline])

  const mapIcon = Leaflet.divIcon({
    html: ReactDomServer.renderToString(
      <div
        style={{
          backgroundColor: 'transparent',
          display: 'inline-block',
          transform: 'translate(-50%, -100%)',
        }}
      >
        <Icon as={SiGooglemaps} color="blue" style={{ fontSize: '24px' }} />
      </div>,
    ),
    iconSize: [24, 24],
    iconAnchor: [1, 1],
    popupAnchor: [1, -34],
    className: 'custom-map-icon',
  })

  return (
    <div style={{ ...containerStyle, backgroundColor: 'white' }}>
      <MapContainer
        center={{
          lat: origin ? (origin.lat ? +origin.lat : -15.793889) : -15.793889,
          lng: origin ? (origin.lng ? +origin.lng : -47.925833) : -47.925833,
        }}
        zoom={zoom}
        style={containerStyle}
      >
        {showIcons && origin && origin.lat && origin.lng && (
          <Marker icon={mapIcon} position={[+origin.lat, +origin.lng]}>
            <Popup>{`Origem: Latitude: ${origin.lat}, Longitude: ${origin.lng}`}</Popup>
          </Marker>
        )}

        {showIcons && destination && destination.lat && destination.lng && (
          <Marker icon={mapIcon} position={[+destination.lat, +destination.lng]}>
            <Popup>{`Destino: Latitude: ${destination.lat}, Longitude: ${destination.lng}`}</Popup>
          </Marker>
        )}

        {stopPlaces &&
          stopPlaces.map((place, index) => (
            <Marker icon={mapIcon} position={[+place.lat, +place.lng]}>
              <Popup>{`Parada ${index + 1}: Latitude: ${place.lat}, Longitude: ${place.lng}`}</Popup>
            </Marker>
          ))}

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {decodedPolyline.length > 0 && (
          <Polyline
            pathOptions={{
              color: '#3366cc',
              opacity: 0.6,
            }}
            positions={decodedPolyline.map(coord => [coord.lat, coord.lng])}
            weight={10}
            opacity={1}
          />
        )}
      </MapContainer>
    </div>
  )
}
