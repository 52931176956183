import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import DeviceInfosType from '../../../types/DeviceInfosType'
import { formatDateInTime } from '../../../utils/dates/formatDateInTime'

export function formattedBatteryState(name: string): string {
  if (name === 'charging') return 'Carregando'
  if (name === 'unplugged') return 'Descarregando'
  if (name === 'full') return 'Cheia'
  return '-'
}

export function formattedTypeConnection(name: string): string {
  if (name === 'none') return 'Nenhuma'
  if (name === 'unknown') return 'Desconhecida'
  if (name === 'cellular') return 'Dados móveis'
  if (name === 'wifi') return 'WI-FI'
  if (name === 'bluetooth') return 'Bluetooth'
  if (name === 'ethernet') return 'Internet'
  if (name === 'wimax') return 'WIMAX'
  if (name === 'vpn') return 'VPN'
  return 'Outra'
}

export interface CurrentDeviceInfoFormatted {
  id: string
  motorist_id: string
  freight_id: string
  status_gps: string
  battery_level: string
  battery_state: string
  has_connection: string
  date_last_offline: string
  type_connection: string
  permission_location: string
  permission_camera: string
  system_version: string
  app_version: string
  system_name: string
  device_model: string
  carrier: string
  system_api_level: string
  device_brand: string
  system_device_id: string
  system_last_update_time: string
  created_at: string
  updated_at: string
}

export interface GetDeviceInfosResponse {
  total: number
  data: DeviceInfosType[]
}

async function fetchCurrentDeviceInfo(
  freight_id: string,
  has_motorist: boolean,
): Promise<CurrentDeviceInfoFormatted | null> {
  if (has_motorist) {
    const {
      data: { data },
    } = await apiServer.get<{ data: GetDeviceInfosResponse }>(`/motorists/device-infos`, {
      params: { freight_id, per_page: 1 },
    })

    const deviceInfosFormatted = data.data.map<CurrentDeviceInfoFormatted>(device => {
      return {
        id: device.id || '',
        motorist_id: device.motorist_id,
        freight_id: device.freight_id,
        status_gps: device.status_gps ? 'Ligado' : device.status_gps === false ? 'Desligado' : 'Sem dados!',
        battery_level: device.battery_level ? `${device.battery_level}%` : 'Sem dados!',
        battery_state: device.battery_state ? formattedBatteryState(device.battery_state) : 'Sem dados!',
        type_connection: device.type_connection
          ? formattedTypeConnection(device.type_connection)
          : 'Sem dados!',
        has_connection: device.has_connection
          ? 'Conectado'
          : device.has_connection === false
          ? 'Offline'
          : 'Sem dados!',
        date_last_offline: device.date_last_offline
          ? format(new Date(String(device.date_last_offline)), "dd/MM/yyy 'às' HH:mm")
          : 'Sem dados!',
        permission_location: device.permission_location
          ? 'Aceita'
          : device.permission_location === false
          ? 'Negada'
          : 'Sem dados!',
        permission_camera: device.permission_camera
          ? 'Aceita'
          : device.permission_camera === false
          ? 'Negada'
          : 'Sem dados!',
        system_version: device.system_version || 'Sem dados!',
        app_version: device.app_version || 'Sem dados!',
        system_name: device.system_name || 'Sem dados!',
        device_model: device.device_model || 'Sem dados!',
        carrier: device.carrier || 'Sem dados!',
        system_api_level: String(device.system_api_level) || 'Sem dados!',
        device_brand: device.device_brand || 'Sem dados!',
        system_device_id: device.system_device_id || 'Sem dados!',
        system_last_update_time: device.system_last_update_time
          ? formatDateInTime(device.system_last_update_time)
          : 'Sem dados!',
        created_at: formatDateInTime(device.created_at),
        updated_at: formatDateInTime(device.updated_at) || '',
      }
    })

    return deviceInfosFormatted[0]
  }
  return null
}

export function useGetCurrentDeviceInfo(
  freight_id: string,
  has_motorist: boolean,
): UseQueryResult<CurrentDeviceInfoFormatted> {
  return useQuery(['device-infos', freight_id], () => fetchCurrentDeviceInfo(freight_id, has_motorist), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
