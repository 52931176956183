import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiAddFill, RiDoorLockBoxLine, RiEdit2Fill, RiLock2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Input } from '../../../components/form'
import { FormattedMessage } from '../../../components/intl'
import { PageHeader } from '../../../components/UI/PageHeader'
import StateHandlerComponent from '../../../components/UI/StateHandlerComponent'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { useCreateRole } from '../../../services/endpoints/authorization/createRole'
import { useGetAllPermissions } from '../../../services/endpoints/authorization/getAllPermissions'
import { useUpdateAuthorizationFiles } from '../../../services/endpoints/authorization/updateAuthorizationFiles'
import { UserRolesOptions } from '../../../services/types/AuthorizationModulesTypes'
import DrawerRole from '../DrawerRole'

type RoleForm = {
  label: string
  value: string
}

const RoleFormSchema = yup.object().shape({
  label: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
})

export function ListRoles(): JSX.Element {
  const {
    refetch: refetchUpdateFiles,
    isLoading: isLoadingUpdateFiles,
    isFetching: isFetchingUpdateFiles,
  } = useUpdateAuthorizationFiles()
  const toast = useToast()
  const [roleToView, setRoleToView] = useState<UserRolesOptions>('' as UserRolesOptions)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const createRole = useCreateRole()
  const moduleDrawer = useDisclosure()
  const bg = useColorModeValue('white', 'gray.800')
  const { data: allPermissions, isLoading, isError } = useGetAllPermissions()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(RoleFormSchema),
  })
  const handleCreateRole: SubmitHandler<RoleForm> = async data => {
    try {
      await createRole.mutateAsync(data)
      toast({
        title: 'Cargo criado com sucesso',
        status: 'success',
        position: 'top-right',
        duration: 1000 * 5,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <PageHeader
        title="Cargos"
        icon={RiDoorLockBoxLine}
        direction={['column', 'column', 'column', 'row']}
        gridGap={4}
        rightContent={
          <>
            <Button
              leftIcon={<Icon as={RiLock2Fill} />}
              colorScheme="red"
              onClick={() => refetchUpdateFiles()}
              isLoading={isFetchingUpdateFiles || isLoadingUpdateFiles}
            >
              Atualizar aquivos
            </Button>
            <Button
              as={Link}
              to="/permissions/edit/to-many-roles"
              leftIcon={<Icon as={RiEdit2Fill} />}
              colorScheme="purple"
              onClick={onOpen}
            >
              Editar permissões por grupo de cargo
            </Button>
            <Button leftIcon={<Icon as={RiAddFill} />} colorScheme="orange" onClick={onOpen}>
              Criar novo cargo
            </Button>
          </>
        }
      />

      <Box bg={bg} px="4" py="2" borderRadius="8" shadow="md">
        <StateHandlerComponent
          loading={isLoading}
          error={isError}
          hasData={!!allPermissions && allPermissions?.length > 0}
        >
          <Table d={['block', 'block', 'table']} overflowX="auto">
            <Thead>
              <Tr>
                <Th>Cargo</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allPermissions?.map(authorization => {
                return (
                  <Tr key={authorization.role}>
                    <Td w="100%">
                      <FormattedMessage id={authorization.role} />
                    </Td>
                    <Td>
                      <Flex gridGap={2}>
                        <Button
                          as={Link}
                          to={`/permissions/edit/${authorization.role}`}
                          colorScheme="orange"
                          variant="outline"
                          size="sm"
                        >
                          Editar
                        </Button>
                        <Button
                          colorScheme="blue"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            moduleDrawer.onOpen()
                            setRoleToView(authorization.role)
                          }}
                        >
                          Visualizar permissões
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </StateHandlerComponent>
      </Box>
      <DrawerRole isOpen={moduleDrawer.isOpen} onClose={moduleDrawer.onClose} role={roleToView} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(handleCreateRole)} noValidate>
          <ModalContent>
            <ModalHeader>Criar novo cargo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid minChildWidth="240px" w="100%" gap={['4', '6']}>
                <Input
                  name="label"
                  label="Nome do cargo"
                  setValue={setValue}
                  error={formState.errors.label}
                />

                <Input
                  name="value"
                  label="Nome do cargo em inglês"
                  setValue={setValue}
                  error={formState.errors.value}
                />
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" variant="outline" mr={3} onClick={onClose}>
                fechar
              </Button>
              <Button
                type="submit"
                isLoading={createRole.isLoading || formState.isSubmitting}
                colorScheme="orange"
                variant="outline"
              >
                Criar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Layout>
  )
}
