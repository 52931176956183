import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  GetRoutePricesListResponse,
  trackingDeviceType,
} from '../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import { getLabelFromAutoComplete } from '../../../../services/utils/getLabelAutoComplete'
import { displayAdaptable } from '../../../../services/utils/tableAdaptable'

interface TableListProps {
  prices: GetRoutePricesListResponse
}

export function TableRoutePricesListPage({ prices }: TableListProps): JSX.Element {
  return (
    <>
      <Table size="sm" my={4} d={displayAdaptable} overflowX="auto" maxW={1100}>
        <Thead>
          <Tr>
            <Th whiteSpace="nowrap">Data</Th>
            <Th whiteSpace="nowrap">Motorista</Th>
            <Th whiteSpace="nowrap">Telefone</Th>
            <Th whiteSpace="nowrap">Valor</Th>
            <Th whiteSpace="nowrap">Dispositivo</Th>
            <Th whiteSpace="nowrap">Origem</Th>
            <Th whiteSpace="nowrap">Destino</Th>
            <Th whiteSpace="nowrap">Veículo</Th>
            <Th whiteSpace="nowrap">Carroceria</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prices?.data?.map(price => (
            <Tr key={price.id}>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.created_at_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.name}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.phone}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.value_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {getLabelFromAutoComplete({
                  value: price.tracking_device_type,
                  array: trackingDeviceType,
                })}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.quotation?.origin?.name}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.quotation?.destination?.name}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.vehicle_category?.name}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.vehicle_body?.name}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}
