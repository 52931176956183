import { Flex, Input, Select } from '@chakra-ui/react'
import { Column } from '@tanstack/react-table'
import { MdOutlineSearch } from 'react-icons/md'
import { FormattedAllFreightsInProgress } from '../../services/endpoints/freights/getFreightsInProgress'

const TableFilter = ({
  column,
}: {
  column: Column<FormattedAllFreightsInProgress, unknown>
}): JSX.Element => {
  const filterValue = column.getFilterValue() as string
  const meta = column.columnDef.meta as {
    filterVariant: 'select' | 'input' | 'date' | 'datetime-local'
    type?: 'string' | 'number' | 'boolean'
    options?: { value: string; label: string }[]
  }

  if (meta?.filterVariant === 'date' || meta?.filterVariant === 'datetime-local') {
    return (
      <>
        <Flex mt={1} flex={1} w="100%" gridGap={2} align="center" bg="whiteAlpha.300" rounded="md">
          <Input
            flex={1}
            height="26px"
            bg="transparent"
            size="xs"
            value={column.getFilterValue() as string}
            onChange={e => {
              column.setFilterValue(e.target.value)
            }}
            type={meta?.filterVariant}
          />
        </Flex>
      </>
    )
  }

  if (meta?.filterVariant === 'select') {
    return (
      <Flex
        mt={1}
        flex={1}
        w="100%"
        gridGap={2}
        align="center"
        bg="whiteAlpha.300"
        rounded="md"
        fontSize="smaller"
      >
        <Select
          size="sm"
          height="26px"
          fontSize="sm"
          onChange={e => column.setFilterValue(e.target.value)}
          value={filterValue}
          borderWidth={0}
        >
          <option value="">Todos</option>
          {meta?.options?.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Flex>
    )
  }

  return (
    <Flex mt={1} flex={1} w="100%" gridGap={2} align="center" bg="whiteAlpha.300" rounded="md" px={2}>
      <MdOutlineSearch size={16} />
      <Input
        flex={1}
        height="26px"
        bg="transparent"
        value={column.getFilterValue() as string}
        onChange={e => {
          column.setFilterValue(e.target.value)
        }}
        _active={{ outline: 'none', border: 'none' }}
        _focus={{ outline: 'none', border: 'none' }}
        px={0}
        placeholder="filtrar"
        size="sm"
        fontSize="sm"
        borderWidth={0}
      />
    </Flex>
  )
}

export default TableFilter
