import { differenceInYears, format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { MotoristType, ResponseApiType } from '../../types'

export interface FormattedMotorist extends MotoristType {
  age?: number
  formatted_gender?: string
  formatted_marital_status?: string
  formatted_rg_dispatch_date?: string
}

export function getGender(type?: string): string {
  if (type === 'M') return 'Masculino'
  if (type === 'F') return 'Feminino'
  return '--'
}

export function getMaritalStatus(status?: string): string {
  if (status === 'single') return 'Solteiro'
  if (status === 'married') return 'Casado'
  if (status === 'divorced') return 'Divorciado'
  if (status === 'widow') return 'Viúvo'
  return '--'
}

export async function searchMotoristsByName(name: string): Promise<AutocompleteOption[]> {
  const { data: response } = await apiServer.get<ResponseApiType<MotoristType[]>>('/motorist/search', {
    params: {
      name,
    },
  })
  const { data } = response

  return data.map(motorist => {
    const label = motorist.name
    const value = motorist.id

    return { label, value }
  })
}

export async function fetchMotorist(motorist_id: string): Promise<FormattedMotorist | undefined> {
  if (motorist_id && motorist_id !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<MotoristType>>(
      `/find-motorist/${motorist_id}`,
    )
    const { data } = response

    const motorist = {
      ...data,
      age: data.birth && differenceInYears(new Date(), data.birth),
      formatted_gender: getGender(data.gender),
      formatted_marital_status: getMaritalStatus(data?.marital_status),
      formatted_rg_dispatch_date: data.rg_dispatch_date
        ? format(new Date(data.rg_dispatch_date), 'dd/MM/yyyy')
        : '',
    }

    return motorist
  }
  return undefined
}

type Options = {
  onSuccess?: (data: FormattedMotorist) => void
}
export function useGetMotorist(motorist_id: string, options?: Options): UseQueryResult<FormattedMotorist> {
  return useQuery(['motorist', motorist_id], () => fetchMotorist(motorist_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    onSuccess: data => {
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })
}
