interface CalcAverageProps {
  values: number[]
  total: number
}

export function CalcAverage({ values, total }: CalcAverageProps): JSX.Element {
  const totalValues = values.reduce((acc, elem) => Number(acc) + Number(elem), 0)
  const average = totalValues / total

  return (
    <>
      {values &&
        values.length > 0 &&
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(Number(average))}
    </>
  )
}
