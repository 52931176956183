type ArrayAutoComplete = {
  label: string
  value: string | number
}

type getValueFromAutoCompleteProps = {
  label?: string | number
  array: ArrayAutoComplete[]
}

export const getValueFromAutoComplete = ({ label, array }: getValueFromAutoCompleteProps): string => {
  if (!label) return ''
  const object = array.find(
    obj => String(obj.label).includes(String(label)) || String(obj.value) === String(label),
  )
  return String(object?.value)
}
