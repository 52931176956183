import { Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react'
import React from 'react'

export function ChecklistRiskAnalysisOutOfRangeAlert(): JSX.Element {
  return (
    <Alert variant="solid" status="error" borderRadius="md" justifyContent="space-between" marginBottom={4}>
      <Flex alignItems="center">
        <AlertIcon />
        <AlertTitle mr={2} fontSize="lg">
          Motorista não realizou nenhum frete, faça o checklist de análise de risco
        </AlertTitle>
      </Flex>
    </Alert>
  )
}
