export function statusFreightNumber(status: string): string {
  if (status === 'Contratado') {
    return ''
  }
  if (status === 'Saiu para coleta') {
    return '1.'
  }
  if (status === 'Chegou para carregar') {
    return '2.'
  }
  if (status === 'Começou a viagem') {
    return '3.'
  }
  if (status === 'Chegou para descarregar') {
    return '4.'
  }
  if (status === 'Entrega Finalizada') {
    return '5.'
  }
  if (status === 'Aberto') {
    return '6.'
  }
  return ''
}
