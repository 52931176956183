import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { TrailerType } from '../../types/TrailerType'

type Filters = {
  columnValue: string
  columnName: string
}

export interface TrailerResponseFormData extends TrailerType {
  formatted_has_insurance: string
  formatted_has_tracker: string
}

export async function fetchTrailerByColumn({
  columnValue,
  columnName,
}: Filters): Promise<TrailerResponseFormData | undefined> {
  if (columnValue && columnValue !== 'undefined' && columnName && columnName !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<TrailerResponseFormData>>(
      `/find-trailer`,
      {
        params: { columnName, columnValue },
      },
    )

    const { data } = response

    const trailer = {
      ...data,
      vehicle_body_id: data.vehicle_body?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_body.id,
      },
    } as TrailerResponseFormData

    return trailer
  }
  return undefined
}

export function useGetVehicleByColumn(input: Filters): UseQueryResult<TrailerResponseFormData> {
  return useQuery(['find-trailer', input], () => fetchTrailerByColumn(input), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: true,
    retry: true,
  })
}
