import { conveyers } from './conveyers'
import getOnlyNumbers from './getOnlyNumbers'

export function formattedTransporter(cnpj: string): string {
  if (cnpj) {
    const result = conveyers.find(x => getOnlyNumbers(x.value) === getOnlyNumbers(cnpj))
    if (result) {
      return result.label
    }
  }
  return '-'
}
