import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },

  styles: {
    global: props => ({
      body: {
        bg: mode('#F0F1F5', 'gray.900')(props),
        color: mode('gray.600', 'gray.50')(props),
      },
    }),
  },
  colors: {
    spotxbg: {
      light: '#F0F1F5',
      dark: '#121214',
    },
  },
})
