import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { MdfeType } from '../../types'

export async function fetchMdfesByFreightId(freight_id: string): Promise<MdfeType[]> {
  const { data } = await apiServer.get<{ data: MdfeType[] }>(`/mdfes`, {
    params: {
      freight_id,
    },
  })

  return data.data
}

export function useListMdfesByFreightId(freight_id: string): UseQueryResult<MdfeType[]> {
  return useQuery(['list_mdfes_by_freight_id', freight_id], () => fetchMdfesByFreightId(freight_id), {
    staleTime: 1000 * 60 * 10,
  })
}
