import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { RiEditLine } from 'react-icons/ri'
import { VscTrash } from 'react-icons/vsc'
import { queryClient } from '../../../../config/react-query'
import { Pagination } from '../../../../layout/Pagination'
import { FormattedQuotation } from '../../../../services/endpoints/freights'
import { useDeleteRoutePrices } from '../../../../services/endpoints/motorists/route-prices/deleteRoutePrices'
import {
  trackingDeviceType,
  useGetAllRoutePrices,
} from '../../../../services/endpoints/motorists/route-prices/getAllRoutePrices'
import { AlertDialogConfirm } from '../../../../components/AlertDialogConfirm'
import { UpdateRoutePricesModal } from '../modals/UpdatePriceMotoristModal'
import { getLabelFromAutoComplete } from '../../../../services/utils/getLabelAutoComplete'

export interface ContactByClientProps {
  quotation: FormattedQuotation
}

export function ListRoutePrices({ quotation }: ContactByClientProps): JSX.Element {
  const [page, setPage] = useState(1)
  const [routePricesId, setRoutePricesId] = useState<string>()

  const {
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
    isOpen: isOpenAlertDialog,
  } = useDisclosure()
  const {
    onOpen: onOpenUpdateRoutePrices,
    onClose: onCloseUpdateRoutePrices,
    isOpen: isOpenUpdateRoutePrices,
  } = useDisclosure()

  const { data: prices } = useGetAllRoutePrices({
    quotation_id: quotation.id,
    page,
    per_page: 3,
  })

  const { mutateAsync: mutateAsyncDeleteRoutePrices } = useDeleteRoutePrices({
    onSuccess: () => {
      queryClient.invalidateQueries('prices-motorists')
    },
  })

  async function handleDeleteRoutePrices(id: string): Promise<void> {
    await mutateAsyncDeleteRoutePrices(id)
  }

  return (
    <Box borderRadius="8" shadow="md" mt="8">
      <Table d={['block', 'block', 'block', 'block', 'table']} variant="unstyled" overflowX="auto">
        <Thead>
          <Tr>
            <Th whiteSpace="nowrap">Data</Th>
            <Th whiteSpace="nowrap">Motorista</Th>
            <Th whiteSpace="nowrap">Valor</Th>
            <Th whiteSpace="nowrap">Dispositivo</Th>
            <Th whiteSpace="nowrap">Pedágio</Th>
            <Th whiteSpace="nowrap">Telefone</Th>
            <Th whiteSpace="nowrap">Origem</Th>
            <Th whiteSpace="nowrap" textAlign="center">
              Editar/Deletar
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {prices?.data?.map(price => (
            <Tr key={price.id}>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.created_at_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.name}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.value_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {getLabelFromAutoComplete({
                  value: price.tracking_device_type,
                  array: trackingDeviceType,
                })}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.toll_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.phone ? price.phone : 'Não informado'}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                {price.source_formatted}
              </Td>
              <Td whiteSpace="nowrap" fontSize="xs">
                <Flex justifyContent="center">
                  <Tooltip label="Editar registro" hasArrow placement="bottom">
                    <Button
                      size="sm"
                      colorScheme="yellow"
                      marginRight={2}
                      onClick={() => {
                        setRoutePricesId(price.id)
                        onOpenUpdateRoutePrices()
                      }}
                    >
                      <Icon as={RiEditLine} />
                    </Button>
                  </Tooltip>

                  <Tooltip label="Deletar registro" hasArrow placement="bottom">
                    <Button
                      size="sm"
                      colorScheme="red"
                      marginLeft={2}
                      onClick={() => {
                        setRoutePricesId(price.id)
                        onOpenAlertDialog()
                      }}
                    >
                      <Icon as={VscTrash} />
                    </Button>
                  </Tooltip>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {prices && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={prices.total}
          registersPerPage={3}
          onPageChange={setPage}
          p="6"
        />
      )}

      {routePricesId && (
        <>
          <UpdateRoutePricesModal
            isOpen={isOpenUpdateRoutePrices}
            onClose={onCloseUpdateRoutePrices}
            quotation={quotation}
            routePricesId={routePricesId}
          />
          <AlertDialogConfirm
            isOpen={isOpenAlertDialog}
            title="Desvincular veículo"
            description="Tem certeza que deseja apagar esse registro?"
            onClose={onCloseAlertDialog}
            onConfirm={() => {
              handleDeleteRoutePrices(routePricesId)
            }}
          />
        </>
      )}
    </Box>
  )
}
