import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Textarea,
  Icon,
  ModalHeader,
  Input,
  useToast,
} from '@chakra-ui/react'
import { RiAttachmentLine } from 'react-icons/ri'
import { useState } from 'react'
import { apiServer } from '../../../../../services/api'
import { toastError } from '../../../../../config/error/toastError'

interface OccurrencesModalProps {
  isOpen: boolean
  onClose: () => void
  freight_id: string
  status?: string
}

export function OccurrencesModal({
  isOpen,
  onClose,
  freight_id,
  status,
}: OccurrencesModalProps): JSX.Element {
  const toast = useToast()
  const [textOccurrence, setTextOccurrence] = useState('')
  const [fileOccurrence, setFileOccurrence] = useState<File>()

  const handleOccurrences = async () => {
    if (textOccurrence.trim().length === 0 || !textOccurrence) {
      toast({
        title: 'Descrição obrigatória!',
        status: 'error',
        position: 'top',
      })
      return
    }

    let attachId = ''

    try {
      if (fileOccurrence) {
        const formData = new FormData()

        formData.append('freight_id', freight_id)
        formData.append('attachment', fileOccurrence)
        formData.append('type', 'occurrence')
        formData.append('description', textOccurrence)
        if (status) formData.append('freight_status', status)

        const { data: newOccurrence } = await apiServer.post(`/freights/attachments`, formData)

        attachId = newOccurrence.id
      }
      toast({
        title: 'Ocorrência adicionada com sucesso!',
        status: 'success',
        position: 'top-right',
      })
      onClose()
      setTextOccurrence('')
      setFileOccurrence(undefined)
    } catch (error) {
      await apiServer.delete(`/freights/attachments/${attachId}`)
      toastError({ toast, error })
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="x-large">Ocorrência</ModalHeader>

          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Descreva a ocorrência</FormLabel>
              <Textarea value={textOccurrence} autoFocus onChange={e => setTextOccurrence(e.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <Icon as={RiAttachmentLine} mr="1" /> Anexar comprovante
              <Input
                type="file"
                h="32px"
                p="-1"
                onChange={e => {
                  if (e.target.files) {
                    setFileOccurrence(e.target.files[0])
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              color="white"
              size="sm"
              h="7"
              mr="3"
              onClick={() => {
                onClose()
                setTextOccurrence('')
                setFileOccurrence(undefined)
              }}
            >
              Cancelar
            </Button>
            <Button colorScheme="green" color="white" size="sm" h="7" mr="3" onClick={handleOccurrences}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
