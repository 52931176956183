export const vehicleColorsBuonny = [
  { label: '', value: '' },
  { label: 'ALUMINIO', value: 'ALUMINIO' },
  { label: 'AMARELO', value: 'AMARELO' },
  { label: 'AZUL', value: 'AZUL' },
  { label: 'BEGE', value: 'BEGE' },
  { label: 'BORDÔ', value: 'BORDÔ' },
  { label: 'BRANCO', value: 'BRANCO' },
  { label: 'CHUMBO', value: 'CHUMBO' },
  { label: 'CIANO', value: 'CIANO' },
  { label: 'CINZA', value: 'CINZA' },
  { label: 'CREME', value: 'CREME' },
  { label: 'DOURADA', value: 'DOURADA' },
  { label: 'FANTASIA', value: 'FANTASIA' },
  { label: 'GELO', value: 'GELO' },
  { label: 'GRENA', value: 'GRENA' },
  { label: 'LARANJA', value: 'LARANJA' },
  { label: 'LILÁS', value: 'LILÁS' },
  { label: 'MAGENTA', value: 'MAGENTA' },
  { label: 'MARROM', value: 'MARROM' },
  { label: 'OLIVA', value: 'OLIVA' },
  { label: 'OUTROS', value: 'OUTROS' },
  { label: 'PRATA', value: 'PRATA' },
  { label: 'PRETO', value: 'PRETO' },
  { label: 'ROSA', value: 'ROSA' },
  { label: 'ROXO', value: 'ROXO' },
  { label: 'SALMÃO', value: 'SALMÃO' },
  { label: 'TURQUESA', value: 'TURQUESA' },
  { label: 'VERDE', value: 'VERDE' },
  { label: 'VERMELHO', value: 'VERMELHO' },
  { label: 'VINHO', value: 'VINHO' },
  { label: 'VIOLETA', value: 'VIOLETA' },
  { label: 'BRANCA', value: 'BRANCA' },
]

export function alterColorsGenderBuonny(color: string | undefined): string {
  if (!color) return ''
  const colorsGender: Record<string, string> = {
    AMARELO: 'AMARELA',
    VERMELHO: 'VERMELHA',
    AZUL: 'AZUL', // Azul é neutro, não muda com o gênero
    VERDE: 'VERDE', // Verde é neutro, não muda com o gênero
    ROXO: 'ROXA',
    LARANJA: 'LARANJA', // Laranja é neutro, não muda com o gênero
    MARROM: 'MARROM', // Marrom é neutro, não muda com o gênero
    BRANCO: 'BRANCA',
    PRETO: 'PRETA',
    DOURADO: 'DOURADA',
  }
  return colorsGender[color] || color
}
