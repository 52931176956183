import { Box, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { RiDoorLockBoxLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { AutocompleteOption } from '../../../../components/form'
import { PageHeader } from '../../../../components/UI/PageHeader'
import StateHandlerComponent from '../../../../components/UI/StateHandlerComponent'
import { toastError } from '../../../../config/error/toastError'
import { Layout } from '../../../../layout'
import { useGetActions } from '../../../../services/endpoints/authorization/getActions'
import { useGetPermissionsByRoles } from '../../../../services/endpoints/authorization/getPermissionsByRoles'
import { usePutPermissions } from '../../../../services/endpoints/authorization/putPermissions'
import { UserRolesOptions } from '../../../../services/types/AuthorizationModulesTypes'
import { getLabelFromAutoComplete } from '../../../../services/utils/getLabelAutoComplete'
import Module, { ModulePermissions } from '../../module'
import { FormEditPermissionsToOneRole } from './form'

interface RequestParams {
  role: UserRolesOptions
}

export type Permissions = {
  module: string
  action: string
}

type Input = {
  roles: UserRolesOptions[]
  can?: ModulePermissions[]
  cannot?: ModulePermissions[]
}

export function EditPermissionsToOneRole(): JSX.Element {
  const [permissions, setPermissions] = useState<{
    [key: string]: Input
  }>({})
  const toast = useToast()
  const { role } = useParams<RequestParams>()
  const {
    data,
    isError: isErrorPermissions,
    isLoading: isLoadingPermissions,
  } = useGetPermissionsByRoles({ roles: [role] })
  const { data: actions, isLoading, isError } = useGetActions()
  const rolesOptions = actions?.filter(f => f.roles_options)[0].roles_options
  const mutation = usePutPermissions()

  const handleChangePermissions = (module: string, value: Input) => {
    setPermissions(prev => ({
      ...prev,
      [module]: {
        ...prev[module],
        can: value.can,
        cannot: value.cannot,
      },
    }))
  }

  const combinePermissions = (
    permissionsToCombine: { [key: string]: Input },
    roleToCombine: UserRolesOptions[],
  ) => {
    const combined = {
      can: [] as ModulePermissions[],
      cannot: [] as ModulePermissions[],
      roles: roleToCombine,
    }

    Object.keys(permissionsToCombine).forEach(key => {
      combined.can.push(...(permissionsToCombine[key].can || []))
      combined.cannot.push(...(permissionsToCombine[key].cannot || []))
    })
    combined.can = combined.can.filter(permissionsCan => permissionsCan.value)
    combined.cannot = combined.cannot.filter(permissionCannot => !permissionCannot.value)

    return combined
  }

  const handleSavePermissions = async () => {
    const input = combinePermissions(permissions, [role])

    const dataToBePut = {
      roles: input.roles,
      can: input.can,
      cannot: [...input.cannot, ...(data?.cannot || [])] as ModulePermissions[],
    }

    const onlyCannotPermissions = dataToBePut.cannot
      .filter(permission => !permission.value)
      .filter(p => !dataToBePut.can.some(c => c.module === p.module && c.action === p.action))

    try {
      await mutation.mutateAsync({
        roles: input.roles,
        can: [{ module: '*', action: '*' }],
        cannot: onlyCannotPermissions,
      })

      toast({
        title: 'Permissões atualizadas com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 8000,
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Layout>
      <PageHeader
        title={`Permissões:  ${
          isLoading
            ? 'Carregando...'
            : getLabelFromAutoComplete({
                value: role,
                array: (rolesOptions || []) as AutocompleteOption[],
              })
        }`}
        icon={RiDoorLockBoxLine}
        rightContent={
          <FormEditPermissionsToOneRole
            handleSavePermissions={handleSavePermissions}
            isLoading={mutation.isLoading}
          />
        }
      />

      <StateHandlerComponent
        loading={isLoading || isLoadingPermissions}
        error={isError || isErrorPermissions}
        hasData={!!actions && !!data}
      >
        <Box py={4}>
          {actions &&
            actions?.map((module: any) => {
              if ('roles_options' in module) {
                return null
              }

              return (
                <Module
                  key={module.module}
                  name={module.module}
                  moduleKey={module.module}
                  actions={module.actions}
                  can={data?.can || []}
                  cannot={data?.cannot || []}
                  onChange={value => {
                    handleChangePermissions(value.module, {
                      can: value.can,
                      cannot: value.cannot,
                      roles: [role],
                    })
                  }}
                />
              )
            })}
        </Box>
      </StateHandlerComponent>
      <FormEditPermissionsToOneRole
        handleSavePermissions={handleSavePermissions}
        isLoading={mutation.isLoading}
      />
    </Layout>
  )
}
