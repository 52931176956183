import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState, useEffect } from 'react'
import { FormattedAllFreights } from '../services/endpoints/freights/getFreights'
import { FormattedAllFreightsInProgress } from '../services/endpoints/freights/getFreightsInProgress'
import { FormattedOneFreight } from '../services/endpoints/freights/getOneFreight'
import { FreightType } from '../services/types'
import { Timer } from './Timer'

export type Option = {
  value: string
  label: string
}
export interface FormattedFreightCurrent extends FreightType {
  has_monitoring_whatsapp?: boolean
  origin_city: string
  formatted_status: string
  destination_city: string
  formatted_suggested_price: string
  formatted_agreed_price: string
  formatted_vehicle: string
  next_status: Option | null
  created_at_hour: string
  formatted_received_at?: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  formatted_delivered_cargo_at?: string
  formatted_denied_at?: string
  link_motorist_at_hour?: string
  collect_item_at_hour?: string
  deliver_item_at_hour?: string
}
interface CheckFreightIsExpiredProps {
  freight: FormattedAllFreights | FormattedAllFreightsInProgress | FormattedOneFreight
}

export function CheckFreightIsExpired({ freight }: CheckFreightIsExpiredProps): JSX.Element {
  const now = new Date()
  const timerDate = new Date(
    String(
      ['ready', 'on_road'].includes(freight.status) ? freight.delivered_cargo_at : freight.collect_cargo_at,
    ),
  )

  const expired = now.getTime() > timerDate.getTime()

  const title = ['ready', 'on_road'].includes(freight.status) ? `entrega` : `coleta`

  const texts = [<Timer date={timerDate} />, 'Atenção, perto de atrasar']

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(indexCurrent => (indexCurrent + 1) % texts.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [texts.length])

  const differenceInHours = (timerDate.getTime() - now.getTime()) / (1000 * 60 * 60)

  return (
    <>
      {!['in_destination', 'delivered', 'finished', 'disabled'].includes(freight.status) && (
        <Text fontSize="xs" color={expired ? 'red' : undefined} d="flex" alignItems="center">
          {expired ? (
            <Tooltip
              label={`Atrasado! A data prevista para ${title} era dia ${format(
                timerDate,
                "dd/MM/yyyy 'às' HH:mm",
              )}`}
              hasArrow
              placement="auto"
            >
              <Flex direction="column" align="center" justifyContent="center">
                <Text as="span" ml="1" cursor="pointer">
                  {title.toUpperCase()} ATRASADA
                </Text>
              </Flex>
            </Tooltip>
          ) : (
            <>
              {differenceInHours <= 5 ? (
                <Text color="orange">{texts[index]}</Text>
              ) : (
                <Timer date={timerDate} />
              )}
            </>
          )}
        </Text>
      )}
    </>
  )
}
