import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type Output = SelectOption[]
export async function getRolesOptions(): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output>>('/authorization/actions', {
    params: { only_roles_options: true },
  })
  return [{ label: 'Escolha um cargo', value: '' }, ...data.data]
}

export function useGetRoleOptions(): UseQueryResult<Output, unknown> {
  return useQuery(['getRolesOptions'], () => getRolesOptions(), {
    refetchOnWindowFocus: false,
  })
}
