import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { fetchCtes } from './getCtes'

export async function cteSelectOption(freight_id: string): Promise<SelectOption[]> {
  const data = await fetchCtes({ freight_id })

  return data.data.map(cte => {
    const label = `${cte.nct} / ${cte.serie} - SPOTX ${
      cte.emitter.includes('/') ? cte.emitter.split('/')[1] : cte.emitter.split('-')[1]
    }`
    const value = cte.id

    return { label, value }
  })
}

export function useCteSelectOption(freight_id: string): UseQueryResult<SelectOption[]> {
  return useQuery(['list-all-cte-autocomplete', freight_id], () => cteSelectOption(freight_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
