/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

async function deleteRoutePrices(id: string) {
  return apiServer.delete(`/delete-route-prices/${id}`)
}

export function useDeleteRoutePrices(options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (id: string) => deleteRoutePrices(id), options)
}
