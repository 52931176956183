import axios from 'axios'

type Params = {
  ciot_number: number
  contractor_id: string
}

type ResponseApiTruckPad = {
  data: {
    file_url: string
  }[]
}

const { REACT_APP_TRUCKPAD_TOKEN_CIOT, REACT_APP_TRUCKPAD_CIOT_PDF_URL_PRINT } = process.env

export async function getCiotPdfByCiotNumber(params: Params): Promise<string | undefined> {
  const axiosInstance = axios.create({
    baseURL: REACT_APP_TRUCKPAD_CIOT_PDF_URL_PRINT,
    headers: {
      Authorization: REACT_APP_TRUCKPAD_TOKEN_CIOT,
      company: params.contractor_id,
    },
  })

  const { data } = await axiosInstance.get<ResponseApiTruckPad>(`/${params.ciot_number}`)

  return data.data[0].file_url
}
