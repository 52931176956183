import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toastError } from '../../../../config/error/toastError'
import { useSetCteToRebillingStatus } from '../../../../services/endpoints/datamex/setCteToRebillingStatus'

interface SetCteToRebillingModalProps {
  isOpen: boolean
  onClose: () => void
  cte_id: string
}

export function SetCteToRebillingModal({
  isOpen,
  onClose,
  cte_id,
}: SetCteToRebillingModalProps): JSX.Element {
  const toast = useToast()
  const {
    mutateAsync: handleSetCteToRebillingStatus,
    isLoading: isLoadingSetCteToRebilling,
    isError: isErrorSetCteToRebillingStatus,
    error: errorSetCteToRebillingStatus,
  } = useSetCteToRebillingStatus()

  useEffect(() => {
    if (isErrorSetCteToRebillingStatus) {
      toastError({ toast, error: errorSetCteToRebillingStatus })
    }
  }, [isErrorSetCteToRebillingStatus, errorSetCteToRebillingStatus, toast])

  const [relatedCteKey, setRelatedCteKey] = useState<string>()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setRelatedCteKey(undefined)
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Marcar CTe como refaturamento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            type="number"
            placeholder="Chave da CTe vinculada"
            onChange={e => setRelatedCteKey(e.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoadingSetCteToRebilling}
            onClick={async () => {
              if (relatedCteKey) {
                try {
                  await handleSetCteToRebillingStatus({
                    cte_id,
                    related_cte_key: relatedCteKey,
                  })
                  toast({
                    title: 'CTe marcado como refaturamento!',
                    status: 'success',
                    position: 'top-right',
                  })
                  onClose()
                } catch (error) {
                  toastError({ toast, error })
                }
              }
            }}
            isDisabled={!relatedCteKey}
          >
            Marcar como CTe de refaturamento
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
