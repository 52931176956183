import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import {
  fetchTrailerByColumn,
  TrailerResponseFormData,
} from '../services/endpoints/trailers/getTrailerByColumn'
import {
  fetchVehicleByColumn,
  VehicleResponseFormData,
} from '../services/endpoints/vehicles/getVehicleByColumn'
import { CheckDocumentAndIsBlackListedExists } from '../services/types/BlackListType'
import getOnlyNumbers from '../services/utils/getOnlyNumbers'

export type useSearchVehicleResult = {
  vehicle?: VehicleResponseFormData
  trailer?: TrailerResponseFormData
}

export type useBlackListReturn = CheckDocumentAndIsBlackListedExists['isCPFInBlacklist']

type useSearchVehicleProps = {
  onSuccess?: (data: useSearchVehicleResult) => void
  onError?: (error: any) => void
  type?: 'vehicle' | 'trailer'
}

const useSearchVehicle = ({
  onSuccess,
  onError,
  type = 'vehicle',
}: useSearchVehicleProps): {
  loading: boolean
  search: (search: string) => void
} => {
  const [loading, setLoading] = useState(false)

  const handleSearch = useRef(
    debounce(
      async (search: string) => {
        const onlyNumbers = getOnlyNumbers(search)
        if (onlyNumbers.length > 5) {
          setLoading(true)
          try {
            if (type === 'vehicle') {
              const findVehicle = await fetchVehicleByColumn({
                columnName: 'renavam',
                columnValue: search,
              })

              if (findVehicle) {
                onSuccess?.({
                  vehicle: findVehicle as VehicleResponseFormData,
                })
              }
            } else {
              const findTrailer = await fetchTrailerByColumn({
                columnName: 'renavam',
                columnValue: search,
              })

              if (findTrailer) {
                onSuccess?.({
                  trailer: findTrailer as TrailerResponseFormData,
                })
              }
            }
          } catch (error) {
            onError?.(error)
          } finally {
            setLoading(false)
          }
        }
      },
      500,
      {},
    ),
  ).current

  useEffect(() => {
    return () => {
      handleSearch.cancel()
    }
  }, [handleSearch])

  return { loading, search: handleSearch }
}

export default useSearchVehicle
