/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

type CreateClientBusinessGroupParams = {
  name: string
  segment_id?: string
  segment?: {
    name: string
  }
}

async function createClientBusinessGroup(data: CreateClientBusinessGroupParams) {
  return apiServer.post('/clients/client-business-group', data)
}

export function usePostClientBusinessGroup(
  params: UseMutationOptions<AxiosResponse, unknown, CreateClientBusinessGroupParams, unknown>,
) {
  return useMutation(async (data: CreateClientBusinessGroupParams) => createClientBusinessGroup(data), params)
}
