import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { getNextStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/NextStatusQuotationButton'
import { getPreviousStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/PreviousStatusQuotationButton'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import parseCurrency from '../../utils/parseCurrency'
import { FormattedQuotation } from './getAllQuotations'
import { getNextStatus, getPriceType, getStatus, getType } from './getFreights'

export async function fetchOneQuotation(Quotation_id: string): Promise<FormattedQuotation | undefined> {
  if (Quotation_id && Quotation_id !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<FormattedQuotation>>(
      `/find-quotation/${Quotation_id}`,
    )

    const { data } = response

    const createdAt = new Date(data.created_at)
    const updatedAt = new Date(data.updated_at)

    const receivedAt = data.received_at ? new Date(data.received_at) : new Date()

    const collectCargoAt = data.collect_cargo_at ? new Date(data.collect_cargo_at) : undefined

    const deliveredCargoAt = data.delivered_cargo_at ? new Date(data.delivered_cargo_at) : undefined

    const Quotation = {
      ...data,
      formatted_type: getType(data.type),
      formatted_status: getStatus(data.status),
      formatted_received_at: format(receivedAt, "yyyy-MM-dd'T'HH:mm:ss"),
      input_collect_cargo_at: collectCargoAt ? format(collectCargoAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
      formatted_delivered_cargo_at: deliveredCargoAt
        ? format(deliveredCargoAt, "yyyy-MM-dd'T'HH:mm:ss")
        : undefined,
      next_status: getNextStatus(data.status),
      next_status_quotation: getNextStatusQuotation(data.status),
      previous_status_quotation: getPreviousStatusQuotation(data.status),
      origin_city: `${data.origin.name} - ${data.origin.state.uf}`,
      destination_city: `${data.destination.name} - ${data.destination.state.uf}`,
      formatted_suggested_price: parseCurrency(data.suggested_price),
      formatted_suggested_price_type: getPriceType(data.suggested_price_type),
      formatted_has_toll: data.has_toll ? 'Sim' : 'Não',
      created_at: format(createdAt, 'dd/MM'),
      created_at_hour: format(createdAt, 'HH:mm'),
      cargo_value: parseCurrency(data.cargo?.value),
      cargo_dimensions: `${data.cargo?.width} x ${data.cargo?.height} x ${data.cargo?.depth}`,
      cargo_weight: `${data.cargo?.weight} kg`,
      is_cargo_complement: data.cargo?.has_complement ? 'Sim' : 'Não',
      cargo_require_tracker: data.cargo?.require_tracker ? 'Sim' : 'Não',
      formatted_origin_coords: {
        lat: String(data?.origin_coords).split(',')[0],
        lng: String(data?.origin_coords).split(',')[1],
      },
      formatted_destination_coords: {
        lat: String(data?.destination_coords).split(',')[0],
        lng: String(data?.destination_coords).split(',')[1],
      },
      created_at_default: data.created_at,
      formatted_updated_at: format(updatedAt, "dd/MM 'às' HH:mm"),
    }
    return Quotation
  }
  return undefined
}

export function useGetOneQuotation(Quotation_id: string): UseQueryResult<FormattedQuotation> {
  return useQuery(['one-quotation', Quotation_id], () => fetchOneQuotation(Quotation_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
