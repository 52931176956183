import { Flex, FormLabel, Grid, GridItem, Icon, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FieldValues, UseFormSetValue } from 'react-hook-form'
import { FaRegFilePdf } from 'react-icons/fa'
import { HiOutlineArrowUpTray } from 'react-icons/hi2'
import { IoCloseSharp, IoImageOutline } from 'react-icons/io5'
import { TbArrowUpRight } from 'react-icons/tb'
import { toastError } from '../../config/error/toastError'
import { extractFileType } from '../../services/utils/extractFileType'
import { formatFileSize } from '../../services/utils/formatFileSize'

type DropzoneImageUploadProps = {
  /**
   * Tamanho máximo do arquivo em bytes.
   * Por exemplo, 1048576 para 1 MB.
   */
  maxSize?: number
  displayMode?: 'list' | 'grid'
  maxFiles?: number
  setValue: UseFormSetValue<FieldValues>
  name: string
  multiple?: boolean
  initialValue?: string
  label?: string
}

type PreviewFileProps = {
  url: string
  name: string
  size?: string
  type: string | null
}

export function DropzoneImageUpload({
  maxSize,
  displayMode = 'list',
  maxFiles,
  setValue,
  name,
  multiple = false,
  initialValue,
  label,
}: DropzoneImageUploadProps): JSX.Element {
  const toast = useToast()
  const [previewFiles, setPreviewFiles] = useState<PreviewFileProps[]>([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': [],
    },
    maxSize,
    multiple,
    onDropRejected: rejects => {
      const fileWithErrosMessagesTranslated = rejects.map(rejection => {
        const fileName = rejection.file.name
        const translatedErrorMessages = rejection.errors
          .map(error => {
            if (error.code === 'file-too-large' && maxSize && maxSize > 0) {
              return `O arquivo excede o tamanho máximo de ${formatFileSize(maxSize)}.`
            }

            return undefined
          })
          .filter(messageError => messageError !== undefined)

        return {
          fileName,
          translatedErrorMessages,
        }
      })

      // eslint-disable-next-line no-restricted-syntax
      for (const fileMessageError of fileWithErrosMessagesTranslated) {
        toast({
          position: 'top',
          duration: 60000,
          status: 'error',
          isClosable: true,
          title: 'Falha ao carregar o arquivo',
          description: (
            <>
              <Text>O arquivo {fileMessageError.fileName} falhou a ser carregado.</Text>
              <ul>
                {fileMessageError.translatedErrorMessages.map(error => (
                  <li>{error}</li>
                ))}
              </ul>
            </>
          ),
        })
      }
    },
    onDrop: (files: File[]) => {
      if (maxFiles && files.length > maxFiles) {
        toastError({ toast, error: `O limite de ${maxFiles} arquivos foi atingido.` })
        return
      }

      const convertFilesInArray = Array.from(files)
      if (multiple) {
        setValue(name, convertFilesInArray)
      } else {
        setValue(name, convertFilesInArray[0])
      }
      const formattedFiles: PreviewFileProps[] = convertFilesInArray.map(file => {
        const imagePreview = URL.createObjectURL(file)
        const typeFile = extractFileType(file.type)

        return {
          url: imagePreview,
          name: file.name,
          size: formatFileSize(file.size),
          type: typeFile,
        }
      })

      setPreviewFiles(prevState => [...prevState, ...formattedFiles])
    },
  })
  const dropZoneBorderColor = useColorModeValue('#A0AEC0', 'gray.900')
  const dropZoneBackgroundColor = useColorModeValue('#F5F7F9', 'gray.700')
  const fileBorderBorderColor = useColorModeValue('#A0AEC0', 'gray.900')
  const fileIconBorderColor = useColorModeValue('gray.300', 'gray.900')

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
    }
  }, [initialValue, setValue, name])

  useEffect(() => {
    return () => previewFiles.forEach(file => URL.revokeObjectURL(file.url))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!multiple && initialValue) {
      const fileName = initialValue.split('/').pop() || ''
      const fileType = fileName.split('.').pop() || ''

      setPreviewFiles([
        {
          url: initialValue,
          name: fileName,
          type: fileType,
        },
      ])
    }
  }, [initialValue, multiple])

  function handleDeleteFile(fileName: string): void {
    setPreviewFiles(prevState => prevState.filter(file => file.name !== fileName))
  }

  return (
    <>
      <Flex className="container" flexDirection="column">
        {label && (
          <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
            {label}
          </FormLabel>
        )}
        {previewFiles.length === 0 && (
          <Flex
            {...getRootProps({ className: 'dropzone' })}
            border={2}
            borderColor={dropZoneBorderColor}
            backgroundColor={dropZoneBackgroundColor}
            borderStyle="dashed"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            p={30}
            cursor="pointer"
            mb={4}
          >
            <input {...getInputProps({ id: name, name })} />
            <Icon as={HiOutlineArrowUpTray} fontSize={60} mb="5" />
            <Text display="flex" fontSize="18">
              <Text mr={1} fontWeight="bold">
                Clique aqui
              </Text>
              para fazer o upload ou arraste e solte
            </Text>
            {maxSize && (
              <Text display="flex" mt={2}>
                Tamanho máximo permitido :{' '}
                <Text fontWeight="bold" ml={1}>
                  {formatFileSize(maxSize)}
                </Text>
              </Text>
            )}
          </Flex>
        )}
        <Grid templateColumns="repeat(12, 1fr)" gap="2">
          {previewFiles.map(previewFile => (
            <GridItem colSpan={[12, displayMode === 'list' ? 12 : 6]} key={previewFile.url}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                border={2}
                borderStyle="solid"
                borderColor={fileBorderBorderColor}
                borderRadius={5}
                p={3}
                width="100%"
                key={previewFile.url}
              >
                <Flex alignItems="center">
                  <Icon
                    p={1}
                    fontSize={30}
                    as={previewFile.type === 'pdf' ? FaRegFilePdf : IoImageOutline}
                    border={1}
                    borderColor={fileIconBorderColor}
                    borderStyle="solid"
                    borderRadius={5}
                  />
                  <Text ml={3}>{previewFile.name}</Text>
                  {previewFile.size && (
                    <Text ml={3} color="gray.400">
                      {previewFile.size}
                    </Text>
                  )}
                </Flex>

                <Flex>
                  <Icon
                    cursor="pointer"
                    fontSize={24}
                    as={TbArrowUpRight}
                    mr={3}
                    onClick={() => {
                      window.open(previewFile.url, '_blank')?.focus()
                    }}
                  />

                  <Icon
                    cursor="pointer"
                    fontSize={24}
                    as={IoCloseSharp}
                    onClick={() => {
                      handleDeleteFile(previewFile.name)
                    }}
                  />
                </Flex>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </>
  )
}
