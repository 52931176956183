import { AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'

export interface CreateFreightTrackerDevicesRequest {
  tracker_id: string
  freight_id: string
}

async function postFreightTrackerDevices(data: CreateFreightTrackerDevicesRequest) {
  return apiServer.post(`/freight-tracker-devices`, data)
}
export function useCreateFreightTrackerDevices(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateFreightTrackerDevicesRequest, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, CreateFreightTrackerDevicesRequest, unknown> {
  return useMutation(
    async (data: CreateFreightTrackerDevicesRequest) => postFreightTrackerDevices(data),
    options,
  )
}
