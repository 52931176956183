import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiCaravanLine, RiEditLine, RiTruckLine, RiUserAddLine } from 'react-icons/ri'
import { Pagination } from '../../../layout/Pagination'
import { useGetAllVehicle, VehiclesFiltersParams } from '../../../services/endpoints/vehicles/getAllVehicle'
import { InfoMotoristLinkedToVehicleExists } from '../info-vehicle-exists/InfoMotoristLinkedToVehicleExists'
import { InfoOwnerLinkedToVehicleExists } from '../info-vehicle-exists/InfoOwnerLinkedToVehicleExists'
import { EditTrailerModal } from '../modals/EditTrailerModal'
import { EditVehicleModal } from '../modals/EditVehicleModal'
import { LinkVehicleToMotoristModal } from '../modals/LinkVehicleToMotoristModal'

interface ListVehicleAccordionProps {
  filters?: VehiclesFiltersParams
  onChangePage: (page: number) => void
}

export function ListVehicleAccordion({ filters, onChangePage }: ListVehicleAccordionProps): JSX.Element {
  const toast = useToast()
  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }

  const {
    data: vehicles,
    refetch,
    isLoading,
    error: errorList,
  } = useGetAllVehicle({
    ...filters,
    per_page: 5,
  })

  const [isAddMotorist, setIsAddMotorist] = useState(false)

  const {
    isOpen: isVehicleFormModalOpen,
    onOpen: onVehicleFormModalOpen,
    onClose: onVehicleFormModalClose,
  } = useDisclosure()
  const {
    onClose: onCloseEditTrailerModal,
    isOpen: isOpenEditTrailerModal,
    // onOpen: onOpenEditTrailerModal,
  } = useDisclosure()
  const {
    isOpen: isOpenLinkVehicleToMotorist,
    onOpen: onOpenLinkVehicleToMotorist,
    onClose: onCloseLinkVehicleToMotorist,
  } = useDisclosure()
  const [vehicleIdToLinkMotorist, setVehicleIdToLinkMotorist] = useState<string>()
  const [ownerIdToLinkMotorist, setOwnerIdToLinkMotorist] = useState<string>()
  const [vehicleIdToUpdate, setVehicleIdToUpdate] = useState<string>()

  useEffect(() => {
    refetch()
  }, [ownerIdToLinkMotorist, refetch])

  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner size="lg" />
        </Flex>
      ) : errorList ? (
        <Text>
          Não foi possível carregar dados dos veículos! Por favor tente mais tarde ou contacte a equipe de
          suporte!
        </Text>
      ) : (
        <>
          <Accordion allowToggle overflowX="auto">
            {vehicles &&
              vehicles.data.map(vehicle => (
                <AccordionItem key={vehicle.id}>
                  <Flex direction={['column', 'column', 'column', 'row']}>
                    <AccordionButton w="100%" gridGap="4">
                      <AccordionIcon fontSize="xx-large" />

                      {vehicle.vehicle_category && (
                        <WrapItem alignItems="center">
                          <Text color="gray.400">Categoria:</Text>
                          <Tag size="lg" borderRadius="full" ml="2">
                            <TagLeftIcon as={RiTruckLine} />
                            <TagLabel>{vehicle.vehicle_category?.name}</TagLabel>
                          </Tag>
                        </WrapItem>
                      )}

                      <WrapItem alignItems="center">
                        <Text color="gray.400">Carroceria:</Text>
                        <Tag size="lg" borderRadius="full" ml="2">
                          <TagLeftIcon as={RiCaravanLine} />
                          <TagLabel>{vehicle.vehicle_body?.name}</TagLabel>
                        </Tag>
                      </WrapItem>
                      <WrapItem>
                        <Text color="gray.400">Placa:</Text>
                        {vehicle.license_plate && (
                          <Tooltip label="Clique para copiar placa" hasArrow>
                            <Text
                              onClick={() => {
                                copyToClipboard(String(vehicle.license_plate))
                                toast({
                                  title: 'Copiado com sucesso!',
                                  status: 'success',
                                  position: 'top-right',
                                  duration: 1000,
                                })
                              }}
                              fontWeight="medium"
                              ml="1"
                            >
                              {vehicle.license_plate}
                            </Text>
                          </Tooltip>
                        )}
                      </WrapItem>
                    </AccordionButton>
                    <Flex justifyContent="flex-end" alignItems="flex-start" my="2" gridGap="2">
                      <Tooltip label="Vincular motorista" hasArrow>
                        <Button
                          size="sm"
                          colorScheme="green"
                          onClick={() => {
                            onOpenLinkVehicleToMotorist()
                            setVehicleIdToLinkMotorist(vehicle.id)
                            setOwnerIdToLinkMotorist(vehicle.owner_id)
                            setIsAddMotorist(false)
                          }}
                        >
                          <Icon as={RiUserAddLine} />
                        </Button>
                      </Tooltip>
                      <Tooltip label="Editar" hasArrow>
                        <Button
                          size="sm"
                          colorScheme="yellow"
                          onClick={() => {
                            onVehicleFormModalOpen()

                            // onOpenEditTrailerModal();

                            setVehicleIdToUpdate(vehicle.id)
                          }}
                        >
                          <Icon as={RiEditLine} />
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Divider />
                  <AccordionPanel pb={4}>
                    <SimpleGrid minChildWidth="250px" spacing="4">
                      <WrapItem>
                        <Text color="gray.400">Fabricação:</Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.release_year}
                        </Text>
                      </WrapItem>
                      <WrapItem>
                        <Text color="gray.400">Marca:</Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.brand}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Modelo:</Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.model}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">UF:</Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.license_uf}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Renavan: </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.renavam}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Chassi: </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.chassi}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">ANTT: </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.antt}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (m3): </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.capacity_m3}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (TARA): </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.capacity_tara}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (kg): </Text>
                        <Text fontWeight="medium" ml="1">
                          {vehicle.capacity_kg}
                        </Text>
                      </WrapItem>

                      {vehicle.has_tracker && (
                        <WrapItem>
                          <Text color="gray.400">Tem rastreador?: </Text>
                          <Text fontWeight="medium" ml="1">
                            Sim
                          </Text>
                        </WrapItem>
                      )}

                      {vehicle.has_insurance && (
                        <WrapItem>
                          <Text color="gray.400">Tem seguro?: </Text>
                          <Text fontWeight="medium" ml="1">
                            Sim
                          </Text>
                        </WrapItem>
                      )}
                    </SimpleGrid>
                    <Divider my="6" />
                    <InfoOwnerLinkedToVehicleExists
                      ownerIdToLinkMotorist={vehicle.owner_id}
                      vehicleId={vehicle.id}
                    />
                    <Divider my="6" />
                    <InfoMotoristLinkedToVehicleExists
                      isAddMotorist={isAddMotorist}
                      vehicleIdToLinkMotorist={String(vehicleIdToLinkMotorist)}
                      vehicleExists={vehicle}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
          <Pagination
            currentPage={filters?.page || 1}
            totalCountOfRegisters={Number(vehicles?.total)}
            registersPerPage={20}
            onPageChange={onChangePage}
            mt="8"
          />
        </>
      )}
      {vehicleIdToUpdate && (
        <>
          <EditVehicleModal
            vehicle_id={vehicleIdToUpdate}
            isOpen={isVehicleFormModalOpen}
            onClose={() => {
              onVehicleFormModalClose()
              refetch()
            }}
          />

          <EditTrailerModal
            isOpen={isOpenEditTrailerModal}
            onClose={() => {
              onCloseEditTrailerModal()
              refetch()
            }}
            trailer_id={vehicleIdToUpdate}
          />
        </>
      )}

      {vehicleIdToLinkMotorist && (
        <LinkVehicleToMotoristModal
          vehicle_id={vehicleIdToLinkMotorist}
          onClose={() => {
            onCloseLinkVehicleToMotorist()
            setIsAddMotorist(true)
          }}
          isOpen={isOpenLinkVehicleToMotorist}
        />
      )}
    </>
  )
}
