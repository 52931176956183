import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiAddLine, RiCaravanLine, RiScissors2Fill } from 'react-icons/ri'
import { toastError } from '../../config/error/toastError'
import { InfoVehicleExists } from '../../pages/vehicles/info-vehicle-exists/InfoVehicleExists'
import { apiServer } from '../../services/api'
import { FormattedMotorist, useGetMotorist } from '../../services/endpoints/motorists/getMotorist'
import { useGetOwner } from '../../services/endpoints/owners/getOwner'
import OwnerType from '../../services/types/OwnerType'
import { AlertDialogConfirm } from '../AlertDialogConfirm'
import { ReloadButton } from '../ReloadButton'

interface VehicleProps {
  motorist_id?: string
  owner_id?: string
  bg: string
}

export function ListVehicle({ motorist_id, owner_id, bg }: VehicleProps): JSX.Element {
  const [motoristOrOwner, setMotoristOrOwner] = useState<FormattedMotorist | OwnerType>()
  const {
    data: owner,
    isLoading: isLoadingOwner,
    isFetching: isFetchingOwner,
    refetch: refetchOwner,
  } = useGetOwner(String(owner_id))

  const { data: motorist, isLoading, isFetching, refetch } = useGetMotorist(String(motorist_id))

  useEffect(() => {
    if (motorist) setMotoristOrOwner(motorist)
    if (owner) setMotoristOrOwner(owner)
  }, [motorist, owner])

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }
  const toast = useToast()

  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const [vehicleIdToDisconnect, setVehicleIdToDisconnect] = useState<string>()

  async function handleDisconnectVehicle(vehicle_id: string, motoristId?: string, ownerId?: string) {
    try {
      if (motoristId) {
        await apiServer.post(`/motorist-detach-vehicle`, {
          motorist_id: motoristId,
          vehicle_id,
        })
      } else {
        await apiServer.patch(`/owner-detach-vehicle`, {
          owner_id: ownerId,
          vehicle_id,
        })
      }
      onCloseAlertDialog()
      toast({
        title: 'Desvinculado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
      refetch()
      refetchOwner()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    refetch()
    refetchOwner()
  }, [refetch, refetchOwner])

  return motoristOrOwner ? (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8" overflowX="auto">
      <Flex mb="6" justify="space-between" align="center" direction={['column', 'row']} gridGap={2}>
        <Flex gridGap={4} align="center">
          <Heading size="lg" fontWeight="normal">
            Veículos vinculados
          </Heading>
          <ReloadButton
            isFetching={isFetching || isFetchingOwner}
            refetch={() => {
              refetch()
              refetchOwner()
            }}
          />
        </Flex>
        {motorist_id ? (
          <Button
            size="sm"
            colorScheme="green"
            as={Link}
            href={`/vehicles/create?motorist_id=${motorist_id}`}
            leftIcon={<Icon as={RiAddLine} />}
          >
            Adicionar veículo
          </Button>
        ) : (
          <Button
            size="sm"
            colorScheme="green"
            as={Link}
            href={`/vehicles/create?owner_id=${owner_id}`}
            leftIcon={<Icon as={RiAddLine} />}
          >
            Adicionar veículo
          </Button>
        )}
      </Flex>
      <>
        <Accordion allowToggle overflowX="auto">
          {motoristOrOwner.vehicles.map(vehicle => (
            <AccordionItem key={vehicle.id}>
              <Flex direction={['column', 'column', 'column', 'row']}>
                <AccordionButton w="100%" gridGap="4">
                  <AccordionIcon fontSize="xx-large" />
                  {/* {vehicle.type === 'trailer' ? (
                    <>
                      <WrapItem alignItems="center">
                        <Text color="gray.400">Tipo:</Text>
                        <Tag size="lg" borderRadius="full" ml="2">
                          <TagLeftIcon as={RiCaravanLine} />
                          <TagLabel>Reboque</TagLabel>
                        </Tag>
                      </WrapItem>
                    </>
                  ) : (
                    <WrapItem alignItems="center">
                      <Text color="gray.400">Categoria:</Text>
                      <Tag size="lg" borderRadius="full" ml="2">
                        <TagLeftIcon as={RiTruckLine} />
                        <TagLabel>{vehicle.vehicle_category?.name}</TagLabel>
                      </Tag>
                    </WrapItem>
                  )} */}

                  <WrapItem alignItems="center">
                    <Text color="gray.400">Carroceria:</Text>
                    <Tag size="lg" borderRadius="full" ml="2">
                      <TagLeftIcon as={RiCaravanLine} />
                      <TagLabel>{vehicle.vehicle_body?.name}</TagLabel>
                    </Tag>
                  </WrapItem>
                  <WrapItem>
                    <Text color="gray.400">Placa:</Text>
                    {vehicle.license_plate && (
                      <Tooltip label="Clique para copiar placa" hasArrow>
                        <Text
                          onClick={() => {
                            copyToClipboard(String(vehicle.license_plate))
                            toast({
                              title: 'Copiado com sucesso!',
                              status: 'success',
                              position: 'top-right',
                              duration: 1000,
                            })
                          }}
                          fontWeight="medium"
                          ml="1"
                        >
                          {vehicle.license_plate}
                        </Text>
                      </Tooltip>
                    )}
                  </WrapItem>
                  {vehicle &&
                  vehicle.vehicle_category &&
                  vehicle.trailers &&
                  vehicle.trailers.length === 0 &&
                  [
                    '1130817b-b5ad-4073-b0f5-44c942ec2528',
                    'ad6e4c8b-15c3-4cab-87c3-437de9ee5651',
                    '46b8d8a5-adeb-44fd-b0fe-ae092984d0bb',
                    'fe1c8218-a10d-4984-9b24-44ca68c727c2',
                    '98fea3e9-dfa4-4de8-9d29-0255d6a5b7de',
                  ].includes(vehicle.vehicle_category.id) ? (
                    <Alert status="error" borderRadius="8" h={['auto', 'auto', 35]}>
                      <AlertIcon />

                      <Flex>Vincule um reboque!</Flex>
                    </Alert>
                  ) : (
                    <WrapItem>
                      <Text color="gray.400">Fabricação:</Text>
                      <Text fontWeight="medium" ml="1">
                        {vehicle.release_year}
                      </Text>
                    </WrapItem>
                  )}
                </AccordionButton>
                <Flex justifyContent="flex-end" alignItems="flex-start" my="2" gridGap="2">
                  <Tooltip hasArrow label="Desvincular">
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        onOpenAlertDialog()
                        setVehicleIdToDisconnect(vehicle.id)
                      }}
                    >
                      <Icon as={RiScissors2Fill} />
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>

              <AccordionPanel pb={4}>
                <InfoVehicleExists vehicle_id={vehicle.id} motorist_id={motorist_id} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        {vehicleIdToDisconnect && owner_id && (
          <AlertDialogConfirm
            isOpen={isOpenAlertDialog}
            title="Desvincular veículo"
            description="Tem certeza que deseja desvincular esse veículo?"
            onClose={onCloseAlertDialog}
            onConfirm={() => {
              handleDisconnectVehicle(vehicleIdToDisconnect, undefined, owner_id)
            }}
          />
        )}
        {vehicleIdToDisconnect && motorist_id && (
          <AlertDialogConfirm
            isOpen={isOpenAlertDialog}
            title="Desvincular veículo"
            description="Tem certeza que deseja desvincular esse veículo?"
            onClose={onCloseAlertDialog}
            onConfirm={() => {
              handleDisconnectVehicle(vehicleIdToDisconnect, motorist_id, undefined)
            }}
          />
        )}
      </>
    </Box>
  ) : isLoading || isLoadingOwner ? (
    <Flex justify="center">
      <Spinner />
    </Flex>
  ) : (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Erro ao carregar os dados do veículo!</AlertTitle>
      <AlertDescription>
        Verifique sua conexão com a internet. Se o problema persistir, entre em contato com o administrador do
        sistema.
      </AlertDescription>
    </Alert>
  )
}
