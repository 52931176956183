import {
  Text,
  WrapItem,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  useDisclosure,
  useToast,
  Flex,
  VStack,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { RiUser2Fill } from 'react-icons/ri'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { useGetOwner } from '../../../services/endpoints/owners/getOwner'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'
import OwnerType from '../../../services/types/OwnerType'

interface InfoOwnerLinkedToTrailerExistsProps {
  ownerIdToLinkMotorist?: string
  trailerId?: string
}

export function InfoOwnerLinkedToTrailerExists({
  ownerIdToLinkMotorist,
  trailerId,
}: InfoOwnerLinkedToTrailerExistsProps): JSX.Element {
  const [owner, setOwner] = useState<OwnerType>()
  const [ownerId] = useState<string | undefined>(ownerIdToLinkMotorist)
  const [trailer_id] = useState<string | undefined>(trailerId)
  const toast = useToast()
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()

  const [showButtonAddOwner, setShowButtonAddOwner] = useState<boolean>(true)
  const { refetch: refetchTrailer } = useGetTrailer(String(trailer_id))

  const { data: fetchedOwner, refetch } = useGetOwner(String(ownerId))

  useEffect(() => {
    if (ownerIdToLinkMotorist !== undefined) {
      const loadOwner = async () => {
        await refetch()
        if (fetchedOwner) {
          setOwner(fetchedOwner)
        }
      }
      loadOwner()
    }
  }, [ownerIdToLinkMotorist, ownerId, showButtonAddOwner, fetchedOwner, refetch])

  async function handleDisconnectOwner(id: string) {
    try {
      await apiServer.patch(`/owner-detach-trailer`, {
        owner_id: id,
        trailer_id,
      })
      refetchTrailer()
      onCloseAlertDialog()
      toast({
        title: 'Desvinculado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setShowButtonAddOwner(true)
    refetchTrailer()
  }, [refetchTrailer])

  return (
    <>
      {owner ? (
        <>
          <Heading size="md" fontWeight="normal">
            Proprietário vinculado ao veículo
          </Heading>

          <VStack spacing="3" my="8" key={owner?.id} alignItems="start">
            <Flex>
              <Text fontWeight="bold" color="gray.400" mr="2">
                Nome:
              </Text>
              <Text fontWeight="medium">{owner?.name}</Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" color="gray.400" mr="2">
                Telefone:
              </Text>
              <Text fontWeight="medium">{owner?.phone}</Text>
            </Flex>
          </VStack>

          <WrapItem>
            <Button
              size="sm"
              colorScheme="red"
              leftIcon={<RiUser2Fill />}
              onClick={() => {
                onOpenAlertDialog()
                setShowButtonAddOwner(true)
              }}
            >
              Desvincular proprietário
            </Button>
          </WrapItem>
        </>
      ) : (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle mr={2}>Nenhum proprietário vinculado ao veículo!</AlertTitle>
        </Alert>
      )}

      {owner && (
        <AlertDialogConfirm
          isOpen={isOpenAlertDialog}
          title="Desvincular proprietário"
          description={`Tem certeza que deseja desvincular ${owner.name}?`}
          onClose={onCloseAlertDialog}
          onConfirm={() => {
            handleDisconnectOwner(owner.id)
          }}
        />
      )}
    </>
  )
}
