import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface SidebarDrawerProviderProps {
  children: ReactNode
}

type SidebarDrawerContextData = UseDisclosureReturn

const SidebarDrawerContext = createContext({} as SidebarDrawerContextData)

export function SidebarDrawerProvider({ children }: SidebarDrawerProviderProps): JSX.Element {
  const { onClose, ...rest } = useDisclosure()
  const { pathname } = useLocation()

  useEffect(() => {
    onClose()
  }, [pathname, onClose])

  return (
    <SidebarDrawerContext.Provider value={{ onClose, ...rest }}>{children}</SidebarDrawerContext.Provider>
  )
}

export function useSidebarDrawer(): SidebarDrawerContextData {
  const context = useContext(SidebarDrawerContext)
  if (!context) throw new Error('useSidebarDrawer() must be used within SidebarDrawerProvider!')
  return context
}
