import { AdvanceRequestStatusEnum } from '../types/EnumTypes'

export function formatAdvanceRequestStatus(status: AdvanceRequestStatusEnum): string {
  const advanceRequestStatus = {
    awaiting_analysis: 'Aguardando Análise',
    rejected: 'Reprovado',
    awaiting_payment: 'Aguardando Pagamento',
    paid: 'Pagamento Realizado',
    finished: 'Finalizado',
  }

  return advanceRequestStatus[status]
}
