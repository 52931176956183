import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { forEach } from 'lodash'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { TextArea } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { usePutAdvanceRequest } from '../../../../../services/endpoints/freights/putAdvanceRequest'
import { useUploadAdvanceRequestAttachments } from '../../../../../services/endpoints/freights/UploadAdvanceRequestAttachments'
import {
  AdvanceRequestAttachmentTypeEnum,
  AdvanceRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'

type UploadAdvanceRequestReceiptsModalProps = {
  isOpen: boolean
  onClose: () => void
  e_frete: boolean
}

type RequestParams = {
  id: string
}

type UploadReceiptsAdvanceRequest = {
  attachments: File[]
  payment_observation?: string
}

const UpdateAdvanceRequestFormSchema = yup.object().shape({
  payment_observation: yup.string().when('e_frete', {
    is: true,
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
})

export function UploadAdvanceRequestReceiptsModal({
  isOpen,
  onClose,
  e_frete,
}: UploadAdvanceRequestReceiptsModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateAdvanceRequestFormSchema),
  })

  const { id } = useParams<RequestParams>()

  const { errors } = formState

  const { mutateAsync: updateAdvanceRequestStatus, isLoading } = usePutAdvanceRequest({
    onSuccess: () => {
      toast({
        title: 'Solicitação de adiantamento atualizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadAdvanceRequestAttachments,
    isLoading: isLoadingUploadAdvanceRequestAttachments,
  } = useUploadAdvanceRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUploadReceiptsAdvanceRequest: SubmitHandler<UploadReceiptsAdvanceRequest> = async data => {
    const uploadFormData = {
      status: AdvanceRequestStatusEnum.PAID,
      payment_observation: data.payment_observation,
    }

    await updateAdvanceRequestStatus({ id, data: uploadFormData })

    forEach(data.attachments, async attachment => {
      if (attachment) {
        const formData = new FormData()

        formData.append('advance_request_id', id)
        formData.append('type', AdvanceRequestAttachmentTypeEnum.RECEIPT)
        formData.append('attachment', attachment)

        await uploadAdvanceRequestAttachments({ data: formData })
      }
    })

    onClose()
  }

  useEffect(() => {
    setValue('e_frete', e_frete)
  }, [setValue, e_frete])

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Anexar comprovantes de pagamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUploadReceiptsAdvanceRequest)} noValidate>
          <ModalBody>
            {e_frete && (
              <TextArea
                name="payment_observation"
                h="150"
                error={errors.payment_observation}
                label="Observação de pagamento"
                setValue={setValue}
              />
            )}

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="attachments"
              label="Anexo de recibos de pagamento"
              multiple
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoading || isLoadingUploadAdvanceRequestAttachments}
              isDisabled={isLoading || isLoadingUploadAdvanceRequestAttachments}
            >
              Avançar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
