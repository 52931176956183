import { BalanceRequestStatusEnum } from '../types/EnumTypes'

export function formatBalanceRequestStatus(status: BalanceRequestStatusEnum): string {
  const balanceRequestStatus = {
    awaiting_analysis: 'Aguardando Análise',
    rejected: 'Reprovado',
    awaiting_contract: 'Aguardando Contrato',
    awaiting_payment: 'Aguardando Pagamento',
    paid: 'Pagamento Realizado',
    finished: 'Finalizado',
  }

  return balanceRequestStatus[status]
}
