import { Flex, Spinner, Alert, AlertIcon, Table, Tbody, Tr, Td, Text } from '@chakra-ui/react'
import parseCurrency from '../../../../services/utils/parseCurrency'

interface IFreightsFinished {
  total: number
  finished_freights_service_price: number
}

interface IFreightsFinishedProps {
  freightsFinished?: IFreightsFinished
  isLoading: boolean
  isFetching: boolean
  error: unknown
}

export function FreightsFinishedCard({
  freightsFinished,
  isLoading,
  isFetching,
  error,
}: IFreightsFinishedProps): JSX.Element {
  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes Finalizados. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        <>
          <Flex justifyContent="space-between" pl="4" pr="4" alignItems="baseline" fontWeight="bold">
            <Flex direction="row" gridGap="2" alignItems="center">
              <Text fontSize="lg" color="orange.400">
                FINALIZADOS
                {!isLoading && isFetching && <Spinner ml={2} />}
              </Text>
            </Flex>
            <Text fontSize="x-large" color="orange.400">
              {freightsFinished && freightsFinished.total}
            </Text>
          </Flex>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td fontSize="md" color="orange.400">
                  Serviço
                </Td>
                <Td isNumeric>
                  <Text fontSize="sm" color="orange.400">
                    {parseCurrency(freightsFinished && freightsFinished.finished_freights_service_price)}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
    </>
  )
}
