import { apiServer } from '../../api'
import { UserType } from '../../types'

interface UsersFiltered {
  label: string
  value: string
  role: string
}

export async function usersAutoComplete(): Promise<UsersFiltered[]> {
  const { data: usersResponse } = await apiServer.get('/users', {
    params: { unpaged: true },
  })

  return usersResponse.data.data.map((user: UserType) => {
    return {
      label: user.name,
      value: user.id,
      role: user.role,
    }
  })
}
