import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { AiOutlineStop } from 'react-icons/ai'
import { IoReload } from 'react-icons/io5'
import { RiSmartphoneLine, RiStarLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { queryClient } from '../../../../../../config/react-query'
import { GetRiskAnalysisResponse } from '../../../../../../services/endpoints/risk-analysis/getAllRiskAnalysis'
import ButtonOptions from './ButtonOptions'

interface ListMotoristsInAnalysisProps {
  riskAnalysis?: GetRiskAnalysisResponse
  isFetchingRiskAnalysis: boolean
}

export function ListMotoristsInAnalysis({
  riskAnalysis,
  isFetchingRiskAnalysis,
}: ListMotoristsInAnalysisProps): JSX.Element {
  return (
    <>
      <Flex my={6} align="center">
        <Heading fontSize="md" fontWeight="medium">
          Motoristas em análise
        </Heading>

        <IconButton
          icon={<IoReload />}
          onClick={() => queryClient.invalidateQueries('list_motorist_in_analysis')}
          isLoading={isFetchingRiskAnalysis}
          aria-label="Recarregar"
          size="sm"
          ml="2"
          title="Recarregar"
        />
      </Flex>
      <Table size="sm" overflowX="auto" d={['block', 'block', 'table']}>
        <Thead>
          <Tr>
            <Th>Nota</Th>
            <Th>Fretes</Th>
            <Th>Motorista</Th>
            <Th>Telefone</Th>
            <Th>Veículo</Th>
            <Th>Placa</Th>
            <Th>Ação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {riskAnalysis &&
            riskAnalysis.data.map(risk => (
              <Tr key={risk.id}>
                <Td textAlign="center">
                  <Flex alignItems="center" gridGap="2">
                    {risk?.motorist?.rate_score && (
                      <>
                        <Icon as={RiStarLine} color="yellow.500" fontSize={20} />
                        <Text fontWeight="bold" color="yellow.600">
                          {risk?.motorist?.rate_score}
                        </Text>
                      </>
                    )}
                    {risk.motorist?.has_app && (
                      <Tooltip hasArrow label="Este motorista possui o app instalado">
                        <Flex>
                          <Text fontSize="xs" color="linkedin.500" fontWeight="bold">
                            <Flex direction="row">
                              <Icon as={RiSmartphoneLine} fontSize="xx-large" mr="1" />
                            </Flex>
                          </Text>
                        </Flex>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
                <Td whiteSpace="nowrap">{risk?.motorist?.total_freights}</Td>
                <Td whiteSpace="nowrap">
                  <Tooltip hasArrow label={`Ver mais informações do motorista ${risk?.motorist.name}`}>
                    <Box>
                      <Text as={Link} to={`/motorists/show/${risk?.motorist.id}`}>
                        {risk?.motorist.name.split(' ')[0]} {risk?.motorist.name.split(' ')[1]}
                      </Text>
                      <Text mt="1" color="gray.500">
                        {risk?.motorist.phone}
                      </Text>
                    </Box>
                  </Tooltip>
                </Td>
                <Td whiteSpace="nowrap">
                  <Text mt="1" color="gray.500">
                    {risk?.motorist?.phone}
                  </Text>
                </Td>
                <Td whiteSpace="nowrap">
                  <Flex gridGap={3}>
                    <Text>{risk?.vehicle?.vehicle_category?.name}</Text>
                    <Text>{risk?.vehicle?.vehicle_body?.name}</Text>
                  </Flex>
                </Td>
                <Td whiteSpace="nowrap">{risk?.vehicle?.license_plate}</Td>

                <Td>
                  {risk &&
                  risk.motorist?.total_freights >= 0 &&
                  (!risk.motorist.rate_score || risk.motorist.rate_score >= 4) ? (
                    <ButtonOptions riskAnalysis={risk} />
                  ) : (
                    <Tooltip label="Inapto para o frete devido a baixa nota" hasArrow>
                      <Box>
                        <Icon as={AiOutlineStop} color="red" fontSize={20} />
                      </Box>
                    </Tooltip>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  )
}
