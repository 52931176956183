/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../api'

export interface CreateUserFormData {
  name: string
  email: string
  password: string
  passwordConfirmation: string
  phone?: string
}

async function createUser(data: CreateUserFormData) {
  return apiServer.post('/users', data)
}

export function usePostUser(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateUserFormData, unknown>,
) {
  return useMutation(async (data: CreateUserFormData) => createUser(data), options)
}
