import 'leaflet/dist/leaflet.css'
import ReactDomServer from 'react-dom/server'

import { Flex, Icon } from '@chakra-ui/react'
import { RiTruckFill } from 'react-icons/ri'

import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import Leaflet from 'leaflet'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useAppSelector } from '../../../../store'
import { IMotoristCheckIn } from '../../../../store/slices/plankCheckInsSlice'

export default function MapCheckIns({ theme }: { theme: string }): JSX.Element {
  const { plankCheckIns } = useAppSelector(state => state.plankCheckInsSlice)

  const [plankCheckInsState, setPlankCheckInsState] = useState([] as IMotoristCheckIn[])
  const [themeMap, setThemeMap] = useState('')
  const [themeUrl, setThemeUrl] = useState('')
  const latitudeInicial = -14.5831167
  const longitudeInicial = -53.7202396
  const zoomInicial = 4

  function iconColorCheck(date: Date): string {
    const currentDateTime: Date = new Date()
    const checkDateTime: Date = new Date(date)
    const difference: number = currentDateTime.getTime() - checkDateTime.getTime()
    const hourDifference: number = difference / (1000 * 60 * 60) // Calcular a diferença em horas
    const dayDifference: number = difference / (1000 * 60 * 60 * 24) // Calcular a diferença em dias
    let result: string

    if (hourDifference < 3) {
      result = '#00ff1a'
    } else if (hourDifference >= 3 && dayDifference < 1) {
      result = '#ff8c00'
    } else {
      result = '#7700ff'
    }
    return result
  }

  useEffect(() => {
    setThemeMap(theme)
  }, [theme])

  useEffect(() => {
    setThemeUrl(
      themeMap === 'light'
        ? 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
        : 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
    )
  }, [themeMap])

  useEffect(() => {
    setPlankCheckInsState(plankCheckIns)
  }, [plankCheckIns])

  return (
    <Flex
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '90vh',
      }}
    >
      <MapContainer
        scrollWheelZoom
        center={[latitudeInicial, longitudeInicial]}
        zoom={zoomInicial}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer attribution="&copy; SpotX Logística" url={themeUrl} key={themeUrl} />
        {plankCheckInsState.map(plankCheckIn => (
          <Marker
            key={plankCheckIn.checkin_id}
            position={[Number(plankCheckIn.checkin_lat), Number(plankCheckIn.checkin_lng)]}
            icon={Leaflet.divIcon({
              html: ReactDomServer.renderToString(
                <Icon
                  as={RiTruckFill}
                  color={iconColorCheck(new Date(plankCheckIn.checkin_created_at))}
                  fontSize={18}
                />,
              ),
              iconSize: [0, 0],
              iconAnchor: [8, 12],
            })}
          >
            <Popup>
              <b>Motorista: </b> {plankCheckIn.motorist_name || 'Não informado'}
              .<br />
              <b>Fretes feitos: </b> {plankCheckIn.count_freights || '0'}.<br />
              <b>Nota: </b> {plankCheckIn.rate_score || '0'} <b>/5</b>.
              <br />
              <b>Último check-in: </b>
              {format(new Date(plankCheckIn.checkin_at), 'dd/MM/yyyy - HH:mm') || 'Não informado'}
              .
              <br />
              <b>Latitude: </b> {plankCheckIn.checkin_lat || 'Não informado'}.
              <br />
              <b>Longitude: </b> {plankCheckIn.checkin_lng || 'Não informado'}.
              <br />
              <b>Equipamento: </b> Celular/Smartphone.
              <br />
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </Flex>
  )
}
