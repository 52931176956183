import { Button, Divider, Grid, GridItem, HStack, Icon } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'
import { AutocompleteAsync } from '../../../../components/form/AutocompleteAsync'
import { Input } from '../../../../components/form/Input'
import { InputMask } from '../../../../components/form/InputMask'
import { RadioGroup } from '../../../../components/form/RadioGroup'
import { Select } from '../../../../components/form/Select'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { MotoristType } from '../../../../services/types'
import { formatDateForInitialValue } from '../../../../services/utils/dates/formatDateForInitialValue'

interface ProfessionalTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  motorist: MotoristType
  formState: FormState<FieldValues>
}

const ufOptions = [
  { label: '', value: '' },
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AP', value: 'AP' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MG', value: 'MG' },
  { label: 'MS', value: 'MS' },
  { label: 'MT', value: 'MT' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'PR', value: 'PR' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'RS', value: 'RS' },
  { label: 'SC', value: 'SC' },
  { label: 'SE', value: 'SE' },
  { label: 'SP', value: 'SP' },
  { label: 'TO', value: 'TO' },
]

export function ProfessionalTabPanel({
  setValue,
  tabNav,
  motorist,
  formState,
}: ProfessionalTabPanelProps): JSX.Element {
  const { errors } = formState
  const [showMoppDate, setShowMoppDate] = useState(false)

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 4]}>
          <InputMask
            name="professional_cpf"
            label="CPF"
            mask="999.999.999-99"
            maskPlaceholder=""
            setValue={setValue}
            initialValue={motorist.cpf}
            error={errors.professional_cpf}
            registerOnlyNumbers
          />
        </GridItem>

        <GridItem colSpan={[12, 8]}>
          <Input
            name="professional_name"
            label="Nome Completo"
            setValue={setValue}
            initialValue={motorist.name}
            error={errors.professional_name}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Input
            name="professional_father_name"
            label="Nome do Pai"
            setValue={setValue}
            error={errors.professional_father_name}
          />
        </GridItem>

        <GridItem colSpan={[12, 6]}>
          <Input
            name="professional_mother_name"
            label="Nome da Mãe"
            setValue={setValue}
            error={errors.professional_mother_name}
          />
        </GridItem>

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_rg"
            type="number"
            label="RG"
            setValue={setValue}
            initialValue={motorist.rg}
            error={errors.professional_rg}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="professional_uf_rg"
            label="RG UF"
            setValue={setValue}
            options={ufOptions}
            error={errors.professional_uf_rg}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <AutocompleteAsync
            name="professional_birth_city"
            label="Naturalidade"
            setValue={setValue}
            loadOptions={searchCitiesByName}
            error={errors.professional_birth_city}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_rg_dispatch_date"
            type="date"
            label="Data de emissão"
            setValue={setValue}
            initialValue={motorist.rg_dispatch_date}
            error={errors.professional_rg_dispatch_date}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_birthday"
            type="date"
            label="Data de Nascimento"
            setValue={setValue}
            initialValue={formatDateForInitialValue(motorist.birth)}
            error={errors.professional_birthday}
          />
        </GridItem>

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_cnh"
            label="Número CNH"
            setValue={setValue}
            initialValue={motorist.driver_license?.register_number}
            error={errors.professional_cnh}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="professional_cnh_category"
            label="Categoria"
            setValue={setValue}
            options={[
              { label: '', value: '' },
              { label: 'A', value: 'A' },
              { label: 'AB', value: 'AB' },
              { label: 'AC', value: 'AC' },
              { label: 'AD', value: 'AD' },
              { label: 'AE', value: 'AE' },
              { label: 'B', value: 'B' },
              { label: 'C', value: 'C' },
              { label: 'D', value: 'D' },
              { label: 'E', value: 'E' },
            ]}
            error={errors.professional_cnh_category}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Select
            name="professional_cnh_uf"
            label="CNH UF"
            setValue={setValue}
            options={ufOptions}
            error={errors.professional_cnh_uf}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_cnh_validity"
            type="date"
            label="Validade CNH"
            setValue={setValue}
            initialValue={motorist.driver_license?.validity}
            error={errors.professional_cnh_validity}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_cnh_first_date"
            type="date"
            label="Data Primeira CNH"
            setValue={setValue}
            initialValue={
              motorist.driver_license?.first_dispatch_date &&
              format(new Date(motorist.driver_license?.first_dispatch_date), 'yyyy-MM-dd')
            }
            error={errors.professional_cnh_first_date}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <Input
            name="professional_cnh_security_code"
            label="CNH Código de Segurança"
            setValue={setValue}
            initialValue={motorist.driver_license?.insurance_number}
            error={errors.professional_cnh_security_code}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <RadioGroup
            name="has_mopp"
            label="Possui Mopp?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.has_mopp}
            onChange={e => {
              if (e === 'S') setShowMoppDate(true)
              else setShowMoppDate(false)
            }}
          />
        </GridItem>

        {showMoppDate && (
          <GridItem colSpan={[12, 3]}>
            <Input
              name="professional_mopp_date"
              type="date"
              label="Data Início MOPP"
              setValue={setValue}
              error={errors.professional_mopp_date}
            />
          </GridItem>
        )}

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InputMask
            name="professional_address_cep"
            label="CEP"
            mask="99.999-999"
            registerOnlyNumbers
            maskPlaceholder=""
            setValue={setValue}
            initialValue={motorist.zipcode}
            error={errors.professional_address_cep}
          />
        </GridItem>

        <GridItem colSpan={[12, 7]}>
          <Input
            name="professional_address"
            label="Endereço"
            setValue={setValue}
            initialValue={motorist.address}
            error={errors.professional_address}
          />
        </GridItem>

        <GridItem colSpan={[12, 2]}>
          <Input
            name="professional_address_number"
            label="Número"
            setValue={setValue}
            initialValue={motorist.address_number}
            error={errors.professional_address_number}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="professional_address_district"
            label="Bairro"
            setValue={setValue}
            initialValue={motorist.district}
            error={errors.professional_address_district}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Input
            name="professional_address_complement"
            label="Complemento"
            setValue={setValue}
            error={errors.professional_address_complement}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <AutocompleteAsync
            name="address_city_id"
            label="Cidade"
            error={errors.address_city_id}
            setValue={setValue}
            initialValue={{
              label: motorist?.address_city ? String(motorist?.address_city?.name) : '',
              value: motorist?.address_city ? Number(motorist?.address_city?.ibge_id) : '',
            }}
            loadOptions={searchCitiesByName}
          />
        </GridItem>

        <GridItem colSpan={12} my="6">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Select
            name="professional_contact_type"
            label="Tipo de contato"
            setValue={setValue}
            options={[
              { label: '', value: '' },
              { label: 'Residencial', value: '1' },
              { label: 'Comercial', value: '2' },
              { label: 'Referência', value: '7' },
            ]}
            error={errors.professional_contact_type}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <Select
            name="professional_contact_return_type"
            label="Tipo de retorno"
            setValue={setValue}
            options={[
              { label: '', value: '' },
              { label: 'Telefone', value: '1' },
            ]}
            error={errors.professional_contact_return_type}
          />
        </GridItem>

        <GridItem colSpan={[12, 4]}>
          <InputMask
            mask="(99) 9 9999-9999"
            registerOnlyNumbers
            maskPlaceholder=""
            name="professional_contact_value"
            label="Contato"
            setValue={setValue}
            initialValue={motorist.phone}
            error={errors.professional_contact_value}
          />
        </GridItem>
      </Grid>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(1)}>
          Voltar
        </Button>
        <Button rightIcon={<Icon as={RiArrowRightLine} />} colorScheme="green" onClick={() => tabNav(3)}>
          Avançar
        </Button>
      </HStack>
    </>
  )
}
