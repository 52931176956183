import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { fetchMotorist } from '../../../services/endpoints/motorists/getMotorist'
import { CityType, DriverLicenseType } from '../../../services/types'
import { MotoristContactsType } from '../../../services/types/MotoristType'
import {
  MotoristCompleteForm,
  MotoristCompleteFormData,
  motoristCompleteFormSchema,
} from '../MotoristCompleteForm'

export interface MotoristEditFormData {
  id?: string
  is_completed?: boolean
  name?: string
  phone?: string
  other_phone?: string
  email?: string
  gender?: string
  rntrc?: string
  rntrc_type?: 'E' | 'C' | 'EQ' | 'T'
  birth?: Date
  birth_city?: string
  birth_state?: string
  cpf?: string
  marital_status?: string
  school_degree?: string
  rg?: string
  rg_uf?: string
  rg_dispatcher?: string
  rg_dispatch_date?: Date
  pis?: string
  address?: string
  address_number?: number
  district?: string
  address_city_id?: number
  address_city?: CityType
  zipcode?: string
  collects_annual_iss?: boolean
  mother_name?: string
  father_name?: string
  bank?: string
  account_type?: string
  agency?: string
  account?: string
  driver_license?: DriverLicenseType
  motorist_contacts?: MotoristContactsType[]
}
interface EditMotoristModalProps {
  motorist_id: string
  isOpen: boolean
  onClose: () => void
}
const isEdit = true
export function EditMotoristModal({ motorist_id, isOpen, onClose }: EditMotoristModalProps): JSX.Element {
  const toast = useToast()
  const [motorist, setMotorist] = useState<MotoristEditFormData>()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(motoristCompleteFormSchema),
  })

  useEffect(() => {
    fetchMotorist(motorist_id).then(response => {
      setMotorist({
        is_completed: response?.is_completed,
        name: response?.name,
        phone: response?.phone,
        other_phone: response?.other_phone,
        email: response?.email,
        gender: response?.gender,
        birth: response?.birth,
        birth_city: response?.birth_city,
        birth_state: response?.birth_state,
        cpf: response?.cpf,
        marital_status: response?.marital_status,
        school_degree: response?.school_degree,
        rg: response?.rg,
        rg_uf: response?.rg_uf,
        rg_dispatcher: response?.rg_dispatcher,
        rg_dispatch_date: response?.rg_dispatch_date,
        pis: response?.pis,
        address: response?.address,
        address_number: response?.address_number ? Number(response.address_number) : undefined,
        district: response?.district,
        address_city: response?.address_city,
        zipcode: response?.zipcode,
        collects_annual_iss: response?.collects_annual_iss,
        mother_name: response?.mother_name,
        father_name: response?.father_name,
        bank: response?.bank,
        account_type: response?.account_type,
        agency: response?.agency,
        account: response?.account,
        rntrc: response?.rntrc,
        motorist_contacts: response?.motorist_contacts,
      })
    })
  }, [motorist_id, isOpen])

  const handleEditMotorist = useCallback<SubmitHandler<MotoristCompleteFormData>>(
    async data => {
      try {
        const { bank, address_city_id, ...motoristFormData } = data

        await apiServer.put(`/update-motorist/${motorist_id}`, {
          ...motoristFormData,
          bank: bank?.label ? bank.label : undefined,
          address_city_id: address_city_id?.value,
          motorist_contacts: [
            {
              id: data.motorist_contact_id_ref_person_1,
              motorist_id,
              name: data.name_contacts_ref_person_1,
              phone: data.phone_ref_person_1,
              type: data.type_contacts_ref_person_1,
            },
            {
              id: data.motorist_contact_id_ref_person_2,
              motorist_id,
              name: data.name_contacts_ref_person_2,
              phone: data.phone_ref_person_2,
              type: data.type_contacts_ref_person_2,
            },
            {
              id: data.motorist_contact_id_ref_professional_1,
              motorist_id,
              name: data.name_contacts_ref_professional_1,
              phone: data.phone_ref_professional_1,
              type: data.type_contacts_ref_professional_1,
            },
            {
              id: data.motorist_contact_id_ref_professional_2,
              motorist_id,
              name: data.name_contacts_ref_professional_2,
              phone: data.phone_ref_professional_2,
              type: data.type_contacts_ref_professional_2,
            },
          ],
        })
        toast({
          title: 'Motorista editado com sucesso!',
          status: 'success',
          position: 'top-right',
        })

        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [motorist_id, toast, onClose],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleEditMotorist)} noValidate autoComplete="off">
          <ModalHeader>Editar Motorista</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {motorist && (
              <MotoristCompleteForm
                setValue={setValue}
                formState={formState}
                initialData={motorist}
                isEdit={isEdit}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>

            <Ability module="people" action="update-motorist">
              <Button
                type="submit"
                colorScheme="orange"
                leftIcon={<Icon as={RiEditLine} />}
                isLoading={formState.isSubmitting}
              >
                Editar
              </Button>
            </Ability>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
