import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { getNextStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/NextStatusQuotationButton'
import { getPreviousStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/PreviousStatusQuotationButton'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import QuotationType from '../../types/QuotationType'

export interface FormattedQuotation extends QuotationType {
  formatted_type: string
  cargo_require_tracker?: string
  origin_city: string
  destination_city: string
  formatted_suggested_price: string
  formatted_suggested_price_type: string
  created_at_hour: string
  formatted_received_at?: string
  formatted_link_motorist_at?: string
  input_collect_cargo_at?: string
  formatted_delivered_cargo_at?: string
  formatted_denied_at?: string
  link_motorist_at_hour?: string
  collect_item_at_hour?: string
  deliver_item_at_hour?: string
  next_status_quotation?: SelectOption
  previous_status_quotation?: SelectOption
  cargo_value: string
}

export type GetquotationsResponse = {
  total: number
  data: FormattedQuotation[]
}

export interface FiltersQuotationsParams {
  status?: 'pending' | 'to_approve' | 'in_quotation' | 'disabled' | 'finished'
  sector?: string
  page?: number
  per_page?: number
  quotation_number?: string
  initial_date?: string | Date
  final_date?: string | Date
  client_id?: string
  user_id?: string
  seller_id?: string
  motorist_id?: string
  cte_number?: string
  collect_item_at_hour?: string
  deliver_item_at_hour?: string
  formatted_finished_at?: string
}

export async function fetchAllQuotations({
  quotation_number,
  status,
  sector,
  page = 1,
  per_page = 5,
  initial_date,
  final_date,
  client_id,
  user_id,
  seller_id,
  motorist_id,
  cte_number,
}: FiltersQuotationsParams): Promise<GetquotationsResponse> {
  const { data: response } = await apiServer.get<ResponseApiType<GetquotationsResponse>>('/quotations/list', {
    params: {
      quotation_number,
      status,
      sector,
      page,
      per_page,
      initial_date,
      final_date,
      client_id,
      user_id,
      seller_id,
      motorist_id,
      cte_number,
    },
  })
  const { data } = response
  const { total } = data

  const quotations = data.data.map(quotation => {
    const createdAt = new Date(quotation.created_at)
    let input_collect_cargo_at
    let formatted_delivered_cargo_at
    let collect_cargo_at_hour
    let deliver_cargo_at_hour
    let formatted_received_at
    let formatted_denied_at
    let formatted_finished_at

    if (quotation.denied_at) {
      const disabledAt = new Date(String(quotation.denied_at))
      formatted_denied_at = format(disabledAt, 'dd/MM/yyyy HH:mm')
    }

    if (quotation.received_at) {
      const receivedCargoAt = new Date(quotation.received_at)
      formatted_received_at = format(receivedCargoAt, "yyyy-MM-dd'T'HH:mm:ss")
    }

    if (quotation.collect_cargo_at) {
      const collectCargoAt = new Date(quotation.collect_cargo_at)
      input_collect_cargo_at = format(collectCargoAt, "yyyy-MM-dd'T'HH:mm:ss")
      collect_cargo_at_hour = format(collectCargoAt, 'HH:mm:ss')
    }

    if (quotation.finished_at) {
      const finishedAt = new Date(String(quotation.finished_at))
      formatted_finished_at = format(finishedAt, 'dd/MM/yyyy HH:mm')
    }

    if (quotation.delivered_cargo_at) {
      const deliverCargoAt = new Date(quotation.delivered_cargo_at)
      formatted_delivered_cargo_at = format(deliverCargoAt, "yyyy-MM-dd'T'HH:mm:ss")
      deliver_cargo_at_hour = format(deliverCargoAt, 'HH:mm:ss')
    }

    return {
      ...quotation,
      origin_city: `${quotation.origin.name} - ${quotation.origin.state.uf}`,
      destination_city: `${quotation.destination.name} - ${quotation.destination.state.uf}`,
      next_status_quotation: getNextStatusQuotation(quotation.status),
      previous_status_quotation: getPreviousStatusQuotation(quotation.status),
      formatted_suggested_price: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(Number(quotation.suggested_price)),
      created_at: format(createdAt, 'dd/MM'),
      formatted_denied_at,
      created_at_hour: format(createdAt, 'HH:mm'),
      cargo_require_tracker: quotation.cargo?.require_tracker ? 'Sim' : 'Não',
      formatted_received_at,
      collect_cargo_at_hour,
      deliver_cargo_at_hour,
      input_collect_cargo_at,
      formatted_delivered_cargo_at,
      formatted_finished_at,
    }
  })

  return { total, data: quotations }
}

export function useGetAllQuotations(params: FiltersQuotationsParams): UseQueryResult<GetquotationsResponse> {
  return useQuery(['all-quotations', params], () => fetchAllQuotations(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
