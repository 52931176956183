import { format, parse } from 'date-fns'
import { FormattedMotorist } from '../../../../services/endpoints/motorists/getMotorist'
import { CityType } from '../../../../services/types'
import MotoristType, { MotoristContactsType } from '../../../../services/types/MotoristType'
import OwnerType from '../../../../services/types/OwnerType'
import { ResponseOcrCnh } from '../../../../services/types/ResponseOcrInfosimples'
import { TrailerType } from '../../../../services/types/TrailerType'
import { formatDateToEUA } from '../../../../services/utils/dates/formatDateToCountry'
import { isValidOCRDateFormat } from '../../../../services/utils/dates/isValidDate'
import { getLabelBank } from '../../../../services/utils/getLabelBank'
import { alterColorsGenderDatamex } from '../../../../services/utils/vehicleColorsDatamex'
import { AnttFormValues } from '../../../../components/forms/antt/type'
import { MotoristFormValues } from '../../../../components/forms/motorist/type'
import { OwnerFormValues } from '../../../../components/forms/owner/type'
import { TrailerFormValues } from '../../../../components/forms/trailer/types'
import { VehicleFormValues } from '../../../../components/forms/vehicle'
import { formatDateForInitialValue } from '../../../../services/utils/dates/formatDateForInitialValue'

type SplitPersonsMotoristReturn = {
  contactsRefPerson1: Partial<MotoristContactsType>
  contactsRefPerson2: Partial<MotoristContactsType>
  contactsRefProfessional1: Partial<MotoristContactsType>
  contactsRefProfessional2: Partial<MotoristContactsType>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const splitPersonsMotorist = (motorist: Partial<FormattedMotorist>): SplitPersonsMotoristReturn => {
  const [contactsRefPerson1, contactsRefPerson2] =
    motorist.motorist_contacts?.filter(contact => contact.type !== 'COMERCIAL') || []

  const [contactsRefProfessional1, contactsRefProfessional2] =
    motorist.motorist_contacts?.filter(contact => contact.type === 'COMERCIAL') || []

  return { contactsRefPerson1, contactsRefPerson2, contactsRefProfessional1, contactsRefProfessional2 }
}

export const createCNHDefaultValues = (CNH: Partial<ResponseOcrCnh>): Partial<MotoristFormValues> => {
  const formatDateForDefaultValue = (date: string) => {
    if (!isValidOCRDateFormat(date)) return ''
    return format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
  }
  const result: Record<string, string> = {}

  if (CNH.registration) result.driver_license = CNH.registration
  if (CNH.cpf) result.cpf = CNH.cpf
  if (CNH.name) result.name = CNH.name
  if (CNH.category) result.driver_license_category = CNH.category.toUpperCase()
  if (CNH.birthDate) result.birth = formatDateForDefaultValue(CNH.birthDate)
  if (CNH.dispatcher) result.driver_license_dispatcher = CNH.dispatcher.toUpperCase()
  if (CNH.emissionDate) result.driver_license_dispatch_date = formatDateForDefaultValue(CNH.emissionDate)
  if (CNH.firstLicenseDate)
    result.driver_license_first_dispatch_date = formatDateForDefaultValue(CNH.firstLicenseDate)
  if (CNH.dispatcherUf) result.driver_license_dispatcher_uf = CNH.dispatcherUf.toUpperCase()
  if (CNH.validity) result.driver_license_validity = formatDateForDefaultValue(CNH.validity)
  if (CNH.insuranceNumber) result.driver_license_security_code = CNH.insuranceNumber
  if (CNH.mirror) result.driver_license_protocol_number = CNH.mirror
  if (CNH.fatherName) result.father_name = CNH.fatherName
  if (CNH.motherName) result.mother_name = CNH.motherName

  return result
}

/**
 *
 * @param city
 * @returns {label: string; value: string | number}
 */

export const createCityInitialValue = (city?: CityType): { label: string; value: string | number } => {
  if (!city) return { label: '', value: '' }
  return {
    label: `${city?.name} - ${city?.state.uf}` || '',
    value: city?.ibge_id || '',
  }
}

/**
 * ultilidades para o formulario de motorista
 *
 * @param motorist
 * @returns MotoristFormValues
 */
export const createMotoristDefaultValues = (motorist: Partial<FormattedMotorist>): MotoristFormValues => {
  const { contactsRefPerson1, contactsRefPerson2, contactsRefProfessional1, contactsRefProfessional2 } =
    splitPersonsMotorist(motorist)

  const formattedMotoristData = {
    cpf: motorist.cpf || '',
    name: motorist.name || '',
    register_doc: motorist.rg || '',
    rg_uf: motorist.rg_uf || '',
    rg_dispatcher: motorist.rg_dispatcher || '',
    rg_dispatch_date: motorist.rg_dispatch_date ? formatDateForInitialValue(motorist.rg_dispatch_date) : '',
    phone: motorist.phone || '',
    birth: motorist.birth ? formatDateForInitialValue(motorist.birth) : '',
    email: motorist.email || '',
    mother_name: motorist.mother_name || '',
    father_name: motorist.father_name || '',
    driver_license: motorist.driver_license?.register_number || '',
    driver_license_category: motorist.driver_license?.category || '',
    driver_license_dispatcher: motorist.driver_license?.dispatcher || '',
    driver_license_dispatch_date: motorist.driver_license?.dispatch_date
      ? formatDateForInitialValue(motorist.driver_license.dispatch_date)
      : '',
    driver_license_dispatcher_uf: motorist.driver_license?.dispatcher_uf || '',
    driver_license_first_dispatch_date: motorist.driver_license?.first_dispatch_date
      ? formatDateForInitialValue(motorist.driver_license.first_dispatch_date)
      : '',
    driver_license_validity: motorist.driver_license?.validity
      ? formatDateForInitialValue(motorist.driver_license.validity)
      : '',
    driver_license_security_code: motorist.driver_license?.insurance_number || '',
    driver_license_protocol_number: motorist.driver_license?.protocol_number || '',

    address_zipcode: motorist.zipcode || '',
    address: motorist.address || '',
    address_neighborhood: motorist.district || '',
    address_number: motorist.address_number || '',
    address_state_uf: motorist.address_city?.state.uf || '',
    address_city: createCityInitialValue(motorist.address_city),
    name_contacts_ref_person_1: contactsRefPerson1?.name,
    name_contacts_ref_person_2: contactsRefPerson2?.name,
    name_contacts_ref_professional_1: contactsRefProfessional1?.name,
    name_contacts_ref_professional_2: contactsRefProfessional2?.name,
    phone_ref_person_1: contactsRefPerson1?.phone,
    phone_ref_person_2: contactsRefPerson2?.phone,
    phone_ref_professional_1: contactsRefProfessional1?.phone,
    phone_ref_professional_2: contactsRefProfessional2?.phone,
    type_contacts_ref_person_1: contactsRefPerson1?.type,
    type_contacts_ref_person_2: contactsRefPerson2?.type,
    type_contacts_ref_professional_1: contactsRefProfessional1?.type,
    type_contacts_ref_professional_2: contactsRefProfessional2?.type,
  } as MotoristFormValues

  return formattedMotoristData
}

/**
 * Obtém se o anexo de CNH existente, se houver.
 */
export const existingAttachmentId = (attachments: FormattedMotorist['attachments']): boolean => {
  let iFexistingAttachmentId = null
  if (attachments && attachments.length > 0) {
    const attach = attachments.find(item => item.type === 'cnh')
    if (attach) {
      iFexistingAttachmentId = attach.id
    }
  }

  return !!iFexistingAttachmentId
}

/**
 * Cria um payload para enviar os dados do veículo ao servidor.
 *
 * @param {VehicleFormValues} data - Os valores do formulário do veículo.
 * @returns {any} O payload formatado pronto para ser enviado.
 */
export const createVehiclePayload = (data: VehicleFormValues): any => {
  const payload = {
    license_plate: data.license_plate.toUpperCase(),
    city_id: data.city_id.value,
    antt: data.license_plate.toUpperCase(),
    axes: data.axes,
    brand: data.brand,
    capacity_kg: data.capacity_kg,
    capacity_m3: data.capacity_m3,
    capacity_tara: data.capacity_tara,
    chassi: data.chassi,
    color: data.color,
    has_insurance: !!data.has_insurance,
    has_tracker: !!data.has_tracker,
    license_uf: data.license_uf,
    model: data.model,
    model_year: data.model_year,
    release_year: data.release_year,
    renavam: data.renavam,
    vehicle_body_id: data.vehicle_body_id,
    vehicle_category_id: data.vehicle_category_id,
  }

  return payload
}

// sendo usados abaixo

/// ------------------ TRAILER ------------------

/**
 * Cria os valores iniciais para o formulário do trailer.
 *
 * @param {VehicleResponseFormData} [vehicleData] - Dados do veículo para inicializar o formulário (opcional).
 * @returns {VehicleFormValues} Os valores formatados para inicializar o formulário do trailer.
 */
export const createTrailerInitialValues = (trailerData?: TrailerType): TrailerFormValues => {
  if (trailerData) {
    const { city } = trailerData

    const capitalizeWord = (word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }

    return {
      license_plate: trailerData.license_plate,
      renavam: trailerData.renavam,
      brand: trailerData.brand,
      color: capitalizeWord(alterColorsGenderDatamex(trailerData.color)) || '',
      model: trailerData.model,
      model_year: trailerData.model_year?.toString() || '',
      chassi: trailerData.chassi,
      release_year: trailerData.release_year?.toString() || '',
      antt: trailerData.license_plate,
      license_uf: trailerData.license_uf,
      vehicle_body_id: trailerData?.vehicle_body?.id,
      axes: trailerData?.axes || undefined,
      capacity_kg: trailerData?.capacity_kg || undefined,
      capacity_m3: trailerData?.capacity_m3 || undefined,
      capacity_tara: trailerData?.capacity_tara || undefined,
      has_insurance: !!trailerData?.has_insurance,
      has_tracker: !!trailerData?.has_tracker,
      ...(!!city && {
        city_id: createCityInitialValue(city),
      }),
    } as TrailerFormValues
  }

  return {} as TrailerFormValues
}

/// ------------------ CNH ------------------

/**
 * Cria os valores iniciais para os dados obtidos da cnh via API.
 *
 * @param {ResponseOcrCnh} data - Dados obtidos da cnh via API.
 * @returns {AnttFormValues} Os valores formatados para inicializar o formulário da cnh.
 */
export const createCNHInitialValues = (data: Partial<ResponseOcrCnh>): Partial<AnttFormValues> => {
  return {
    type: 'pf',
    document: data.cpf,
    name: data.name,
    birth: formatDateToEUA(data.birthDate),
    rg_dispatch_date: formatDateToEUA(data.emissionDate),
    rg_dispatcher: data.dispatcher,
    rg_uf: data.dispatcherUf?.toUpperCase(),
    rg_ie: data.identity,
    father_name: data.fatherName,
    mother_name: data.motherName,
  }
}

/// ----- Owner / trailer Owner / ANTT Owner -----

/**
 *
 * Cria os valores iniciais para o formulário do proprietário e o proprietário de trailer.
 *
 * @param ownerValues
 * @returns {OwnerFormValues}
 *
 */
export const createOwnerInitialValues = (ownerValues: OwnerType): Partial<OwnerFormValues> => {
  const { type, city } = ownerValues
  const document = type === 'pf' ? ownerValues.cpf || '' : ownerValues.cnpj || ''
  const rg_ie = type === 'pj' ? ownerValues.ie || '' : ownerValues.rg || ''
  return {
    address: ownerValues.address || '',
    address_number: ownerValues.address_number || '',
    rntrc: ownerValues.rntrc || '',
    ...(!!city && {
      city: createCityInitialValue(city),
    }),
    city_uf: ownerValues.city?.state?.uf || '',
    district: ownerValues.district || '',
    document,
    name: ownerValues.name || '',
    phone: ownerValues.phone || '',
    type: ownerValues.type || '',
    zipcode: ownerValues.zipcode || '',
    mother_name: ownerValues.mother_name || '',
    birth: ownerValues.birth ? formatDateForInitialValue(ownerValues.birth) : '',
    email: ownerValues.email || '',
    father_name: ownerValues.father_name || '',
    rg_dispatch_date: ownerValues.rg_dispatch_date
      ? formatDateForInitialValue(ownerValues.rg_dispatch_date)
      : '',
    rg_dispatcher: ownerValues.rg_dispatcher || '',
    rg_ie,
    rg_uf: ownerValues.rg_uf || '',
  }
}

export const convertMotoristToOwner = (motorist: MotoristType): OwnerType => {
  return {
    ...motorist,
    city: motorist?.address_city,
    type: String(motorist?.type) === '1' ? 'pf' : 'pj',
  } as OwnerType
}

/**
 *
 * Cria os valores iniciais para o formulário do Antt.
 *
 * @param ownerValues
 * @returns {OwnerFormValues}
 */

export const createOwnerAnttInitialValues = (ownerValues: OwnerType): Partial<AnttFormValues> => {
  const { type, city, account_type } = ownerValues
  const document = type === 'pf' ? ownerValues.cpf || '' : ownerValues.cnpj || ''
  const rg_ie = type === 'pj' ? ownerValues.ie || '' : ownerValues.rg || ''
  return {
    account: ownerValues?.account || '',
    account_type: account_type || '',
    address: ownerValues.address || '',
    address_number: ownerValues.address_number || '',
    agency: ownerValues.agency || '',
    bank: {
      label: ownerValues?.bank ? getLabelBank(ownerValues?.bank) : '',
      value: ownerValues.bank || '',
    },
    ...(!!city && {
      city: createCityInitialValue(city),
    }),
    city_uf: ownerValues.city?.state?.uf || '',
    district: ownerValues.district || '',
    document,
    name: ownerValues.name || '',
    phone: ownerValues.phone || '',
    type: ownerValues.type || '',
    zipcode: ownerValues.zipcode || '',
    mother_name: ownerValues.mother_name || '',
    birth: ownerValues.birth ? formatDateForInitialValue(ownerValues.birth) : '',
    email: ownerValues.email || '',
    father_name: ownerValues.father_name || '',
    pis: ownerValues.pis || '',
    rntrc: ownerValues.rntrc || '',
    rntrc_type: ownerValues.rntrc_type || '',
    rg_dispatch_date: ownerValues.rg_dispatch_date
      ? formatDateForInitialValue(ownerValues.rg_dispatch_date)
      : '',
    rg_dispatcher: ownerValues.rg_dispatcher || '',
    rg_ie: rg_ie || '',
    rg_uf: ownerValues.rg_uf || '',
  }
}
