import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsPersonCheckFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { toastError } from '../../../config/error/toastError'
import { usePatchRiskAnalysis } from '../../../services/endpoints/risk-analysis/patchRiskAnalysis'

interface DependsOnCheckModalProps {
  risk_analysis_id: string
  isOpen: boolean
  onClose: () => void
}

export function DependsOnCheckModal({
  isOpen,
  onClose,
  risk_analysis_id,
}: DependsOnCheckModalProps): JSX.Element {
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const { setValue } = useForm({})
  const toast = useToast()
  const [depends_on_check, setDepends_on_check] = useState<boolean>()
  const [apt_motorist, setApt_motorist] = useState<boolean>()

  const handleDependsCheck = async () => {
    try {
      if (depends_on_check) {
        await mutateAsyncChangeStatus({
          id: risk_analysis_id,
          depends_on_check,
          status: 'to_check',
          reason: 'A emissão de documentos só será liberada após a confirmação da análise de risco!',
        })
        onClose()
        toast({
          title: 'A emissão de documentos só será liberada após a confirmação da análise de risco!',
          status: 'warning',
          position: 'top-right',
          isClosable: true,
          duration: 8000,
        })
      } else if (apt_motorist) {
        await mutateAsyncChangeStatus({
          id: risk_analysis_id,
          depends_on_check,
          status: 'approved',
          consultation_code: 'PERFIL ADEQUADO AO RISCO',
        })
        onClose()
      } else {
        toastError({ toast, error: 'O motorista só poderá ser aprovado caso esteja apto' })
        onClose()
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setDepends_on_check(undefined)
    setApt_motorist(undefined)
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="md"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Aprovar motorista</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth="300px" spacing={2}>
            <RadioGroup
              name="depends_on_check"
              label="Depende de checagem?"
              setValue={setValue}
              options={[
                { label: 'Sim', value: 'yes' },
                { label: 'Não', value: 'not' },
              ]}
              onSelectOption={op => {
                if (op.value === 'yes') {
                  setDepends_on_check(true)
                  setApt_motorist(undefined)
                } else if (op.value === 'not') {
                  setDepends_on_check(false)
                  setApt_motorist(undefined)
                }
              }}
            />

            {depends_on_check === false && (
              <RadioGroup
                name="apt_motorist"
                label="O motorista está apto para o frete?"
                setValue={setValue}
                options={[
                  { label: 'Sim', value: 'yes' },
                  { label: 'Não', value: 'not' },
                ]}
                onSelectOption={op => {
                  if (op.value === 'yes') {
                    setApt_motorist(true)
                  } else if (op.value === 'not') {
                    setApt_motorist(false)
                  }
                }}
              />
            )}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          {depends_on_check === false && apt_motorist ? (
            <Button
              colorScheme="green"
              isLoading={isLoadingChangeStatus}
              onClick={() => {
                handleDependsCheck()
              }}
              rightIcon={<BsPersonCheckFill />}
            >
              Aprovar
            </Button>
          ) : (
            <Button
              rightIcon={<FaArrowRight />}
              colorScheme="green"
              isLoading={isLoadingChangeStatus}
              isDisabled={
                depends_on_check === undefined || (depends_on_check === false && apt_motorist === undefined)
              }
              onClick={() => {
                handleDependsCheck()
              }}
            >
              Confirmar
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
