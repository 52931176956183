/* eslint-disable consistent-return */
import { format } from 'date-fns'
import { apiServer } from '../../../services/api'
import { ResponseApiType } from '../../../services/types'
import BlackListType, { CheckDocumentAndIsBlackListedExists } from '../../../services/types/BlackListType'
import getOnlyNumbers from '../../../services/utils/getOnlyNumbers'

export const handleCpfIsBlackListed = async (document?: string): Promise<BlackListType | undefined> => {
  if (document) {
    try {
      const { data } = await apiServer.get<ResponseApiType<BlackListType>>('/find-black-list', {
        params: { cpf: getOnlyNumbers(document) },
      })

      const formattedData = {
        ...data.data,
        created_at: format(new Date(data?.data?.created_at || ''), 'dd/MM/yyy'),
      } as BlackListType
      return formattedData
    } catch {
      return undefined
    }
  }
}

export const handleCheckIfCpfIsBlackListed = async (
  document?: string,
): Promise<CheckDocumentAndIsBlackListedExists['isCPFInBlacklist'] | undefined> => {
  if (document) {
    try {
      const { data } = await apiServer.get<ResponseApiType<CheckDocumentAndIsBlackListedExists>>(
        '/find-black-list',
        {
          params: { cpf: getOnlyNumbers(document) },
        },
      )

      if (!data.data.isCPFInBlacklist) {
        return undefined
      }

      return {
        ...data.data.isCPFInBlacklist,
      }
    } catch {
      return undefined
    }
  }
}
