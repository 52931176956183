import {
  Button,
  Grid,
  GridItem,
  GridProps,
  Heading,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { queryClient } from '../../../../config/react-query'
import { FormattedOneFreight } from '../../../../services/endpoints/freights'
import { UpdateContactByClientModal } from '../../../clients/edit/UpdateContactByClientModal'

interface FreightRequestProps extends GridProps {
  freight: FormattedOneFreight
}

const FreightRequest = ({ freight, ...props }: FreightRequestProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const {
    onClose: onCloseUpdateContact,
    isOpen: isOpenUpdateContact,
    onOpen: onOpenUpdateContact,
  } = useDisclosure()
  return (
    <>
      <Grid p="4" borderRadius="md" bg={bg} gap="4" templateColumns="repeat(12, 1fr)" mt="4" {...props}>
        <GridItem
          colSpan={[12]}
          d="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDir={['column', 'row']}
          gridGap="4"
        >
          <Heading fontSize="md" fontWeight="medium">
            Dados do solicitante:
          </Heading>
          <Button
            size="sm"
            colorScheme="orange"
            variant="outline"
            onClick={() => {
              onOpenUpdateContact()
            }}
          >
            Editar solicitante
          </Button>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Nome:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight?.client_contact?.name}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Telefone:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight?.client_contact?.phone}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Telefone 2:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight?.client_contact?.phone2}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Email:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight?.client_contact?.email}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 3]}>
          <Heading fontSize="sm" fontWeight="medium" color="gray.500">
            Cargo:
          </Heading>
          <Text fontWeight="medium" mt="1" fontSize="sm">
            {freight?.client_contact?.role}
          </Text>
        </GridItem>
      </Grid>

      {freight?.client_contact_id && (
        <UpdateContactByClientModal
          onClose={() => {
            onCloseUpdateContact()
            queryClient.invalidateQueries('freight')
          }}
          isOpen={isOpenUpdateContact}
          contactByClient_id={freight.client_contact_id}
        />
      )}
    </>
  )
}

export default FreightRequest
