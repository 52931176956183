import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

export interface InputCheckRiskByBuonny {
  product: number
  document: string
  carter: string
  plates: string[]
  buonny_cargo_type: string
  buonny_cargo_price: string
  motorist_id?: string
  vehicle_id?: string
  freight_id: string
}

export interface ResponseCheckRiskByBuonny {
  statusCode: number
  data: Data
}
interface Motorist {
  name: string
  cpf: string
  vehicle: string
  trailer?: string
  vehicle_license_plate: string
  trailer_license_plate?: string
}
interface Data {
  motorist: Motorist
  buonny: Buonny
}
interface Buonny {
  consulta: string
  status: string
  mensagem: string
  consultas_adequadas_ultimos_12_meses: string
}

async function checkRiskByBuonny(data: InputCheckRiskByBuonny) {
  return apiServer.post('/buonny/check-risk', data)
}

export function useCheckRiskByBuonny(
  options?: UseMutationOptions<AxiosResponse, unknown, InputCheckRiskByBuonny, unknown>,
): UseMutationResult<AxiosResponse<ResponseCheckRiskByBuonny>, unknown, InputCheckRiskByBuonny, unknown> {
  return useMutation(async (data: InputCheckRiskByBuonny) => checkRiskByBuonny(data), options)
}
