import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react'
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  DropAnimation,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { MdDragIndicator } from 'react-icons/md'

interface SortableItemProps {
  id: UniqueIdentifier
  index: number
  renderItem?(args?: any): React.ReactElement
  dragOverlay?: boolean
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

const SortableItem: React.FC<SortableItemProps> = ({ id, renderItem, dragOverlay }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition, setActivatorNodeRef } =
    useSortable({
      id,
    })
  const rowBg = useColorModeValue('gray.200', 'gray.600')

  const transformStyle: React.CSSProperties = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0) ${isDragging ? 'scale(1.01)' : ''}`
      : 'none',
    opacity: isDragging ? (dragOverlay ? 1 : 0.5) : 1,
    transition,
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      rounded="md"
      bg={rowBg}
      mb={1}
      ref={setNodeRef}
      style={transformStyle}
      {...attributes}
      {...(dragOverlay && { 'data-dragging': true })}
    >
      {renderItem?.()}
      <IconButton
        backgroundColor="transparent"
        aria-label="columns"
        size="sm"
        {...listeners}
        ref={setActivatorNodeRef}
        icon={<MdDragIndicator />}
      />
    </Flex>
  )
}

interface Item {
  id: UniqueIdentifier
  columnId: string
  content: JSX.Element
}

const initialItems: Item[] = [
  { id: 1, content: <>Item 1</>, columnId: '1' },
  { id: 2, content: <>Item 2</>, columnId: '2' },
  { id: 3, content: <>Item 3</>, columnId: '3' },
]
interface SortableProps {
  sortables?: Item[]
  onDragEndChange?: (active: string, over: string) => void
}

const Sortable = ({ sortables = initialItems, onDragEndChange }: SortableProps): JSX.Element => {
  const [items, setItems] = useState<Item[]>(sortables)
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const getIndex = (id: UniqueIdentifier) => items.findIndex(item => item.id === id)
  const activeIndex = activeId ? getIndex(activeId) : -1

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={({ active }) => active && setActiveId(active.id)}
      onDragEnd={({ over }) => {
        setActiveId(null)
        if (over && activeIndex !== getIndex(over.id)) {
          const ActiveId = items[activeIndex].columnId
          const OverId = items[getIndex(over.id)].columnId
          const newItems = arrayMove(items, activeIndex, getIndex(over.id))

          setItems(newItems)
          onDragEndChange?.(ActiveId, OverId)
        }
      }}
      onDragCancel={() => setActiveId(null)}
    >
      <SortableContext items={items}>
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            id={item.id}
            index={index}
            renderItem={() => (
              <Flex key={item.id} flex={1}>
                {item.content}
              </Flex>
            )}
          />
        ))}
        {createPortal(
          <DragOverlay adjustScale dropAnimation={dropAnimationConfig}>
            {activeId !== null && (
              <SortableItem
                id={activeId}
                index={activeIndex}
                renderItem={() => <Flex flex={1}>{items[activeIndex].content}</Flex>}
                dragOverlay
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  )
}

export default Sortable
