import { useQuery, UseQueryResult } from 'react-query'
import { checkAllPendencies } from '.'
import { CheckPendenciesParams } from './checkPendencies'

export const hasPendencies = async ({
  freight_id,
  motorist_id,
  vehicle_id,
}: CheckPendenciesParams): Promise<boolean | undefined> => {
  if (
    freight_id &&
    motorist_id &&
    vehicle_id &&
    freight_id !== 'undefined' &&
    freight_id !== 'null' &&
    motorist_id !== 'undefined' &&
    motorist_id !== 'null' &&
    vehicle_id !== 'undefined' &&
    vehicle_id !== 'null'
  ) {
    const data = await checkAllPendencies({
      freight_id,
      motorist_id,
      vehicle_id,
    })

    if (data) {
      if (
        data.motorist.length ||
        data.vehicle.length ||
        !data.vehicleOwner ||
        data.vehicleOwner.length ||
        !data.trailer ||
        !data.trailerOwner ||
        data.trailerOwner.length
      ) {
        return true
      }
      return false
    }
  }
  return true
}

export function useHasPendencies(params: CheckPendenciesParams): UseQueryResult<boolean> {
  return useQuery(['has-pendencies', params], () => hasPendencies(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
