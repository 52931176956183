import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import { RoutePricesFormatted } from './getAllRoutePrices'

export type IGetRoutePricesResponse = RoutePricesFormatted | undefined

async function fetchRoutePrices(id: string | undefined): Promise<IGetRoutePricesResponse | undefined> {
  if (id) {
    const { data: res } = await apiServer.get<ResponseApiType<RoutePricesFormatted>>(
      `/find-route-prices/${id}`,
    )
    const { data } = res
    return data
  }

  return undefined
}

export function useGetRoutePrices(
  id: string | undefined,
): UseQueryResult<IGetRoutePricesResponse | undefined> {
  return useQuery(['find-route-prices', id], () => fetchRoutePrices(id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
