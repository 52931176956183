import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BsReceipt } from 'react-icons/bs'
import { IoIosDocument } from 'react-icons/io'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { useGetStubsFormattedByFreight } from '../../../services/endpoints/freights/getStubsFormattedByFreight'
import { boxWidth } from '../../../services/utils/tableAdaptable'
import { FiltersStubs } from './FiltersStubs'

type StubsFormattedByFreightFilter = {
  freight_number?: string
  cte_number?: string
  status?: 'approved' | 'disapproved' | 'pending'
  page?: number
}

export function ListStubs(): JSX.Element {
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    freight_number?: string
    cte_number?: string
    status?: string
    page?: number
  }>()
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })
  const [filters, setFilters] = useState<StubsFormattedByFreightFilter>()
  const { data: stubs, isLoading } = useGetStubsFormattedByFreight({
    page: filters?.page,
    ...filters,
  })

  const bg = useColorModeValue('white', 'gray.800')

  const filterCte = yup.object().shape({
    freight_number: yup.string(),
    cte_number: yup.string(),
    status: yup.string(),
  })

  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(filterCte),
  })

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const { errors, isSubmitting } = formState

  const handleFilter: SubmitHandler<StubsFormattedByFreightFilter> = data => {
    setFilters({
      freight_number: data.freight_number ? data.freight_number.replace(/\D/g, '') : data.freight_number,
      cte_number: data.cte_number,
      status: data.status,
      page: data.page || 1,
    })
    updateQueryParams({
      status: data.status || undefined,
      freight_number: data.freight_number || undefined,
      cte_number: data.cte_number || undefined,
    })
    setValue('page', 1)
  }

  useEffect(() => {
    const freight_number = getParams('freight_number')
    const cte_number = getParams('cte_number')
    const status = getParams('status')
    const page = getParams('page')
    if (freight_number || cte_number || status || page) {
      setValue('freight_number', freight_number || '')
      setValue('cte_number', cte_number || '')
      setValue('status', status || '')
      setValue('page', Number(page) || '')
      handleSubmit(handleFilter)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <PageHeader
        title="Lista de canhotos"
        icon={BsReceipt}
        rightContent={
          <>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <FiltersStubs errors={errors} setValue={setValue} getValues={getValues} />

          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8" w={boxWidth}>
        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {stubs && stubs.data && stubs.data.length ? (
          <Table mt={4} size="sm" w="100%" overflowX="auto" alignContent="center">
            <Thead>
              <Tr>
                <Th textAlign="center" verticalAlign="middle">
                  FRETE
                </Th>
                <Th textAlign="center" verticalAlign="middle">
                  STATUS
                </Th>
                <Th textAlign="center" verticalAlign="middle">
                  APROVADOS
                </Th>
                <Th textAlign="center" verticalAlign="middle" fontSize="xs" whiteSpace="nowrap">
                  REPROVADOS
                </Th>
                <Th textAlign="center" verticalAlign="middle">
                  CANHOTO
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {stubs?.data?.map(stub => (
                <Tr key={stub.freight.freight_number}>
                  <Td whiteSpace="nowrap" textAlign="center" verticalAlign="middle">
                    <Tooltip label="Visualizar Frete" hasArrow placement="left" arrowSize={15}>
                      <Link to={`/freights/show/${stub.freight.id}`}>
                        <Text fontSize="xs">#{stub.freight.freight_number}</Text>
                      </Link>
                    </Tooltip>
                  </Td>

                  <Td whiteSpace="nowrap" textAlign="center" verticalAlign="middle">
                    <Tag
                      size="md"
                      colorScheme={
                        stub.freight_stub_status === 'Aprovado'
                          ? 'green'
                          : stub.freight_stub_status === 'Incompleto'
                          ? 'red'
                          : 'gray'
                      }
                      borderRadius="full"
                      p="2"
                    >
                      {stub.freight_stub_status}
                    </Tag>
                  </Td>

                  <Td whiteSpace="nowrap" textAlign="center" verticalAlign="middle">
                    {stub.freight_stub_approved_total}/{stub.freight.stubs?.length}
                  </Td>

                  <Td whiteSpace="nowrap" textAlign="center" verticalAlign="middle">
                    {stub.freight_stub_desapproved_total}/{stub.freight.stubs?.length}
                  </Td>
                  <Td whiteSpace="nowrap" textAlign="center" verticalAlign="middle">
                    <Flex justifyContent="center">
                      <Tooltip label="Visualizar Canhoto" hasArrow placement="left" arrowSize={15}>
                        <Link to={`/stubs/${stub.freight.id}`}>
                          <Button
                            leftIcon={<Icon as={IoIosDocument} />}
                            variant="outline"
                            colorScheme="blue"
                            size="sm"
                          >
                            Canhoto
                          </Button>
                        </Link>
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <>
            <Divider mt={4} />
            <Text mt={4}>Nenhum canhoto cadastrado!</Text>
          </>
        )}

        {stubs && stubs.total > 0 && (
          <Box mt={5}>
            <Pagination
              currentPage={filters?.page || 1}
              totalCountOfRegisters={stubs.total}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </Box>
    </Layout>
  )
}
