import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'

export function formatDateForInitialValue(value?: Date | string): string | undefined {
  const formattedDate = value
    ? format(utcToZonedTime(value, 'America/Sao_Paulo'), 'yyyy-MM-dd', {
        timeZone: 'America/Sao_Paulo',
        locale: ptLocale,
      })
    : undefined
  return formattedDate
}
