import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'
import VehicleAttachmentType from '../../../types/VehicleAttachmentType'

export interface CreateAttachToVehicle {
  vehicle_id: string
  type: string
  name: string
  attachment_file: File
}

async function createAttachmentToVehicle({ vehicle_id, type, name, attachment_file }: CreateAttachToVehicle) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  formData.append('vehicle_id', vehicle_id)
  formData.append('type', type)
  formData.append('name', name)
  return apiServer.post<ResponseApiType<VehicleAttachmentType>>('/vehicles/attachments', formData)
}

export function useCreateAttachmentToVehicle(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateAttachToVehicle, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, CreateAttachToVehicle, unknown> {
  return useMutation(async (data: CreateAttachToVehicle) => createAttachmentToVehicle(data), options)
}
