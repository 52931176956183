import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import { FreightContractType } from '../../types/FreightContractType'

export interface FilterFreightContract {
  number?: number
  freight_number?: string
  motorist_name?: string | AutocompleteOption
  emit_date_ini?: string
  emit_date_final?: string
  freight_id?: string
  page?: number
  per_page?: number
  unpaged?: boolean
}

type GetAllFreightContractsResponse = {
  total: number
  data: FreightContractType[]
}

async function fetchFreightContracts(params: FilterFreightContract): Promise<GetAllFreightContractsResponse> {
  const { data: response } = await apiServer.get<ResponseListApiType<FreightContractType[]>>(
    '/freight-contracts',
    { params },
  )
  const { data } = response
  const { total } = response.data
  const FreightContract = data.data
  return { data: FreightContract, total }
}

export function useGetAllFreightContracts(
  params: FilterFreightContract,
): UseQueryResult<GetAllFreightContractsResponse> {
  return useQuery(['list_freight_contracts', params], () => fetchFreightContracts(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
