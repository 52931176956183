import { Box, Button, Divider, Flex, Heading, Input, Select, Switch, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

interface AnttFormProps {
  distance: number | undefined
  setDistance: (value: number) => void
  axesNumber: number
  setAxesNumber: (value: number) => void
  cargoType: string
  setCargoType: (value: string) => void
  handleAnttValueConsultation: () => void
  isLoadingAnttValue: boolean
  anttValue: number | undefined
}

export const AnttForm = ({
  distance,
  setDistance,
  axesNumber,
  setAxesNumber,
  cargoType,
  setCargoType,
  handleAnttValueConsultation,
  isLoadingAnttValue,
  anttValue,
}: AnttFormProps): JSX.Element => {
  const [isVehicleComposition, setIsVehicleComposition] = useState(false)
  const [isHighPerformance, setIsHighPerformance] = useState(false)
  const [isReturnEmpty, setIsReturnEmpty] = useState(false)

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        handleAnttValueConsultation()
      }}
    >
      <Box px="6" py="4">
        <Heading size="sm" fontWeight="bold" mb="4">
          Calcular frete mínimo
        </Heading>
        <Divider />

        <Box py="4">
          <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
            Tipo de Carga
          </Heading>
          <Select
            isRequired
            placeholder="Selecione o tipo de carga"
            value={cargoType}
            onChange={e => setCargoType(e.target.value)}
          >
            <option value="5">Carga Geral</option>
            <option value="12">Carga Granel Pressurizada</option>
            <option value="4">Conteinerizada</option>
            <option value="3">Frigorificada ou Aquecida</option>
            <option value="2">Granel Líquido</option>
            <option value="1">Granel Sólido</option>
            <option value="6">Neogranel</option>
            <option value="11">Perigosa (Carga Geral)</option>
            <option value="10">Perigosa (Conteinerizada)</option>
            <option value="9">Perigosa (Frigorificada ou Aquecida)</option>
            <option value="8">Perigosa (Granel Líquido)</option>
            <option value="7">Perigosa (Granel Sólido)</option>
          </Select>
        </Box>

        <Box py="4">
          <Heading size="sm" color="gray.500" fontSize="sm" textTransform="uppercase">
            Composição do Veículo
          </Heading>
          <Flex direction="column" gap={1} py="2">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Veículo de Composição</Text>
              <Switch size="sm" onChange={() => setIsVehicleComposition(!isVehicleComposition)} />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Veículo de Alto Desempenho</Text>
              <Switch size="sm" onChange={() => setIsHighPerformance(!isHighPerformance)} />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Retorno Vazio</Text>
              <Switch size="sm" onChange={() => setIsReturnEmpty(!isReturnEmpty)} />
            </Flex>
          </Flex>
        </Box>

        <Divider my={2} />

        <Box py="4">
          <Heading size="sm" color="gray.500" fontSize="sm" textTransform="uppercase">
            Distância
          </Heading>
          <Input
            name="distance"
            type="number"
            value={distance}
            onChange={e => setDistance(Number(e.target.value))}
            placeholder="Distância da Rota"
            isRequired
          />
        </Box>

        <Box py="4">
          <Heading size="sm" color="gray.500" fontSize="sm" textTransform="uppercase">
            Número de Eixos
          </Heading>
          <Select
            name="axesNumber"
            value={axesNumber}
            onChange={e => setAxesNumber(Number(e.target.value))}
            placeholder="Selecione o número de eixos"
            isRequired
          >
            {[2, 3, 4, 5, 6, 7, 8, 9].map(eixo => (
              <option key={eixo} value={eixo}>
                {eixo} Eixos
              </option>
            ))}
          </Select>
        </Box>

        <Button type="submit" w="100%" size="lg" mt="6" colorScheme="green" isLoading={isLoadingAnttValue}>
          Consultar valor ANTT
        </Button>

        <Box mt="4">
          <Heading size="sm" fontWeight="bold">
            Valor ANTT
          </Heading>
          <Text>{anttValue ? `${anttValue}` : 'Valor ANTT não consultado'}</Text>
        </Box>
      </Box>
    </form>
  )
}
