/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FindPerson } from '../../services/endpoints/motorists/findMotoristAndOwnerByCpf'
import OwnerType from '../../services/types/OwnerType'

interface State {
  isLoading: boolean
  owner?: OwnerType
  findMotoristAndOwnerByCpf?: FindPerson
}

const initialState: State = {
  isLoading: false,
  owner: undefined,
  findMotoristAndOwnerByCpf: undefined,
}

const ownerSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },

    setOwner(state, action: PayloadAction<OwnerType | undefined>) {
      state.owner = action.payload
    },
    setMotoristAndOwner(state, action: PayloadAction<FindPerson | undefined>) {
      state.findMotoristAndOwnerByCpf = action.payload
    },
  },
})

export const { setOwner, setMotoristAndOwner } = ownerSlice.actions
export default ownerSlice.reducer
