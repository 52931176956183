import { RiCloseCircleLine } from 'react-icons/ri'
import QuotationsButtonForm from '.'
import { FormattedQuotation } from '../../../../services/endpoints/freights'

export interface ButtonProps {
  quotation: FormattedQuotation
}

export default function DisabledStatusQuotationButton({ quotation }: ButtonProps): JSX.Element {
  return (
    <QuotationsButtonForm
      change_status={{ label: 'Desativada', value: 'disabled' }}
      colorScheme="red"
      leftIcon={RiCloseCircleLine}
      quotation={quotation}
      label="Desativar"
    />
  )
}
