import { Flex, Spinner, Alert, AlertIcon, Box, Table, Tbody, Tr, Td, Text } from '@chakra-ui/react'
import Chart from 'react-apexcharts'

import { apexOptions } from '../../apexcharts/FreightsMonitored'

interface IFreightsMonitored {
  freightsMonitoredFinished: number
  freightsFinished: number
}

interface IFreightsMonitoredProps {
  freightsMonitored: IFreightsMonitored
  freightsAerials: number
  isLoading: boolean
  isFetching: boolean
  error: unknown
  bg: string
}

export function FreightsMonitoredCard({
  freightsMonitored,
  freightsAerials,
  isLoading,
  isFetching,
  error,
  bg,
}: IFreightsMonitoredProps): JSX.Element {
  // Aéreos: não se enquadram em nenhuma das categorias abaixo, portanto deve se subtrair os aéreos dos não rastreados e dos rastreados
  // Rastreados: Os finalizados - os aéreos
  // Não rastreados são: Os finalizados - os rastreados - os aéreos
  const trackedFreights = freightsMonitored?.freightsMonitoredFinished - freightsAerials
  const untrackedFreights =
    freightsMonitored?.freightsFinished - freightsMonitored?.freightsMonitoredFinished - freightsAerials

  const dataChart = [trackedFreights, untrackedFreights, freightsAerials]

  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes monitorados. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        freightsMonitored && (
          <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md">
            <Flex justifyContent="space-between" pl="5" pr="5" alignItems="baseline" mb="4" fontWeight="bold">
              <Text fontSize="lg" color="#4682B4">
                RASTREADOS
                {!isLoading && isFetching && <Spinner ml={2} />}
              </Text>

              <Text fontSize="x-large" color="#4682B4">
                {freightsMonitored.freightsMonitoredFinished}
              </Text>
            </Flex>
            <Table size="sm" mb="2">
              <Tbody>
                {freightsMonitored && (
                  <Tr>
                    <Td>
                      <Text fontSize="md">Fretes finalizados</Text>
                    </Td>
                    <Td fontSize="md" isNumeric>
                      {freightsMonitored.freightsFinished}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            <Chart type="pie" height={100} options={apexOptions} series={dataChart} />
          </Box>
        )
      )}
    </>
  )
}
