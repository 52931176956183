import { format } from 'date-fns'
import { useMemo } from 'react'

const useHasFilterAppliedCount = <T>(filters: T): number => {
  return useMemo(() => {
    const filtersApplied = Object.values(filters).filter(value => value !== undefined)
    const today = format(new Date(), 'yyyy-MM-dd')

    return filtersApplied.filter(value => value !== today).filter(value => value !== undefined).length
  }, [filters])
}

export default useHasFilterAppliedCount
