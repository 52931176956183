import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react'
import { RiUserAddLine } from 'react-icons/ri'
import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { PageHeader } from '../../../components/UI/PageHeader'
import { Layout } from '../../../layout'
import { CreateMoristCompleteForm } from './CreateMoristCompleteForm'
import { CreateMoristCompleteQuickForm } from './CreateMoristCompleteQuickForm'

export interface MotoristQuickFormData {
  name: string
  phone: string
  cpf: string
  other_phone: string
  checkin: AutocompleteOption
  checkin_at: Date
  origin: AutocompleteOption
  destination: AutocompleteOption
  prospected_by: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export function CreateMotorist(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')

  return (
    <Layout>
      <PageHeader title="Cadastrar motorista" icon={RiUserAddLine} />
      <Box bg={bg} p="4" borderRadius="8" shadow="md">
        <Tabs colorScheme="orange" variant="enclosed-colored">
          <TabList>
            <Tab>Cadastro Rápido</Tab>
            <Tab>Cadastro Completo</Tab>
          </TabList>
          <TabPanels mt="6">
            <TabPanel>
              <CreateMoristCompleteQuickForm />
            </TabPanel>
            <TabPanel>
              <CreateMoristCompleteForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  )
}
