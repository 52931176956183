import { useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../layout'
import { useGetOneFreight } from '../../../services/endpoints/freights'
import { ListStub } from '../show/cards/list-stub'

type RequestParams = {
  freight_id: string
}

export function ShowStub(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { freight_id } = useParams<RequestParams>()

  const { data: freight } = useGetOneFreight({ freight_id })

  return (
    <Layout>
      {freight && (
        <>
          <ListStub freight={freight} bg={bg} />
        </>
      )}
    </Layout>
  )
}
