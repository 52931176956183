import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

type Params = {
  freight_number: string
  cte_id: string
}

export async function changeCteRelatedFreight({ freight_number, cte_id }: Params): Promise<boolean> {
  const { data } = await apiServer.patch(`update-related-freight`, {
    freight_number,
    cte_id,
  })
  return data
}

export function useChangeCteRelatedFreight(
  options?: UseMutationOptions<boolean, unknown, Params, unknown>,
): UseMutationResult<boolean, unknown, Params, unknown> {
  return useMutation(async (params: Params) => changeCteRelatedFreight(params), options)
}
