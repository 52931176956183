/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dispatch } from '..'
import { apiServer } from '../../services/api'
import { ResponseArrayApiType } from '../../services/types/ResponseApiType'

export interface IMotoristCheckIn {
  motorist_id: string
  motorist_name: string
  motorist_phone: string
  checkin_id: string
  checkin_lat: number | string
  checkin_lng: number | string
  checkin_created_at: Date | string
  checkin_at: Date | string
  city_name: string
  vehicle_body_name: string
  city_id: string
  city_lat: number | string
  city_lng: number | string
  plank_id: string
  count_freights: number | string
  rate_score: number | string
}

const plankCheckInsSlice = createSlice({
  name: 'plankCheckIn',
  initialState: {
    isLoading: false,
    plankCheckIns: [] as IMotoristCheckIn[],
  },
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setPlankCheckIns(state, action: PayloadAction<IMotoristCheckIn[]>) {
      state.plankCheckIns = action.payload
    },
  },
})

export const { setPlankCheckIns } = plankCheckInsSlice.actions
export default plankCheckInsSlice.reducer

function loading(isLoading: boolean) {
  dispatch(plankCheckInsSlice.actions.setIsLoading(isLoading))
}

export function getPlankCheckIns(): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      dispatch(plankCheckInsSlice.actions.setPlankCheckIns([]))
      const { data: response } = await apiServer.get<ResponseArrayApiType<IMotoristCheckIn[]>>(
        `/motorist/list-all-plank-checkins`,
      )
      dispatch(plankCheckInsSlice.actions.setPlankCheckIns(response.data || []))

      loading(false)
    } catch (error) {
      loading(false)
    }
  }
}
