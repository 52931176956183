import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { RateStar } from '../../../../../components/form/RateStar'
import RateFreightType from '../../../../../services/types/RateFreightType'

interface ShowRateFreightModalProps {
  isOpen: boolean
  onClose: () => void
  rate?: RateFreightType
}

export function ShowRateFreightModal({ isOpen, onClose, rate }: ShowRateFreightModalProps): JSX.Element {
  const { setValue } = useForm()

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Visualizar todas as notas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {rate && (
            <VStack spacing="6" mb="6" justifyContent="left">
              <RateStar
                name="behavior_score"
                label="Como você avalia o atendimento do motorista?"
                setValue={setValue}
                initialValue={rate.behavior_score}
                isDisabled
              />
              <RateStar
                name="ponctuality_score"
                label="Como você avalia a pontualidade do motorista?"
                setValue={setValue}
                initialValue={rate.ponctuality_score}
                isDisabled
              />
              <RateStar
                name="reliability_score"
                label="Como você avalia a confiabilidade do frete?"
                setValue={setValue}
                initialValue={rate.reliability_score}
                isDisabled
              />
              <RateStar
                name="cargo_state_score"
                label="Como você avalia a situação da carga na entrega do frete?"
                setValue={setValue}
                initialValue={rate.cargo_state_score}
                isDisabled
              />

              <Textarea
                name="description"
                placeholder="Observações (opcional)"
                setValue={setValue}
                value={rate.description}
                initialValue={rate.description}
                isDisabled
                _disabled={{ opacity: 1 }}
              />
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
