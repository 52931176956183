import { Flex, Box, Button, Heading, useToast, Tooltip } from '@chakra-ui/react'
import { RiMapPin2Fill, RiMapPin2Line, RiDeleteBinLine } from 'react-icons/ri'
import React, { useState } from 'react'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { AutocompleteAsync } from '../../../../../components/form'

export type QualpCityType = {
  cityName: string
  lat: string
  lng: string
}

interface LocationManagerProps {
  origin?: QualpCityType
  destination?: QualpCityType
  places: QualpCityType[]
  setOrigin: (origin: any) => void
  setDestination: (destination: any) => void
  setPlaces: (places: any[]) => void
  errors: any
  setValue: (name: string, value: any) => void
  isBlockedEntity: boolean
}

export const LocationManager: React.FC<LocationManagerProps> = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  places,
  setPlaces,
  errors,
  setValue,
  isBlockedEntity,
}) => {
  const toast = useToast()
  const [stops, setStops] = useState<JSX.Element[]>([])

  const addPlace = (lat: string | number, lng: string | number, uf: string, cityName: string) => {
    const latitude = parseFloat(String(lat))
    const longitude = parseFloat(String(lng))
    if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
      toast({
        title: 'Erro ao adicionar local',
        description: 'As coordenadas de latitude e longitude são inválidas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
      return
    }

    if (places.find((place: QualpCityType) => place.cityName === cityName)) {
      toast({
        title: 'Erro ao adicionar local',
        description: 'Este local já foi adicionado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
      return
    }

    const newPlace = {
      cityName,
      lat: String(lat),
      lng: String(lng),
      uf,
    }

    const updatedPlaces = [...places]
    updatedPlaces.push(newPlace)
    setPlaces(updatedPlaces)
  }

  const removeStop = (index: number) => {
    const updatedPlaces = [...places]
    const updatedStops = [...stops]

    updatedPlaces.splice(index, 1)
    updatedStops.splice(index - 1, 1)

    setPlaces(updatedPlaces)
    setStops(updatedStops)

    toast({
      title: 'Parada removida com sucesso!',
      description: 'Parada removida com sucesso.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
  }

  const addNewStop = () => {
    setStops([
      ...stops,
      <Box key={places.length}>
        <Flex align="center" gridGap="2" justify="space-between">
          <AutocompleteAsync
            name={`address_stop_${places.length}`}
            placeholder="Digite o endereço da parada"
            setValue={setValue}
            onSelectOption={async option => {
              addPlace(option.lat, option.lng, option.uf, option.cityName)
              toast({
                title: 'Parada adicionada com sucesso!',
                description: 'Parada adicionada com sucesso.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top',
              })
            }}
            loadOptions={searchCitiesByName}
          />
          <Button size="lg" colorScheme="red" variant="ghost" p="0" onClick={() => removeStop(places.length)}>
            <RiDeleteBinLine />
          </Button>
        </Flex>
      </Box>,
    ])
  }

  return (
    <Flex flexDir="column" gridGap="6" p="6">
      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="sm" fontWeight="bold">
            Rota
          </Heading>
          <Button colorScheme="blue" size="sm" onClick={addNewStop}>
            Adicionar parada
          </Button>
        </Flex>

        <Heading
          size="sm"
          display="flex"
          fontSize="sm"
          gridGap="2"
          mb="4"
          color="blue.500"
          textTransform="uppercase"
        >
          <RiMapPin2Fill /> Origem
        </Heading>
        <Box>
          <AutocompleteAsync
            isRequired
            initialValue={{
              label: origin ? origin.cityName : '',
              key: origin,
              value: origin?.cityName || '',
            }}
            error={errors.origin}
            name="origin"
            placeholder="Digite o endereço de origem"
            setValue={setValue}
            isDisabled={isBlockedEntity}
            onSelectOption={async option => {
              setOrigin({
                cityName: option.cityName,
                lat: option.lat,
                lng: option.lng,
              })
              toast({
                title: 'Origem adicionada com sucesso!',
                description: 'Origem adicionada com sucesso.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              })
            }}
            loadOptions={searchCitiesByName}
          />
        </Box>
      </Box>

      {stops.length > 0
        ? stops.map(stopComponent => stopComponent)
        : places.length > 2 && (
            <Box>
              <Heading size="small" fontWeight="bold">
                Paradas
              </Heading>
              <Flex direction="column" gap={2}>
                {places.slice(1, -1).map((place, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Flex key={index}>
                    <AutocompleteAsync
                      isRequired
                      initialValue={{
                        label: String(place.cityName || ''),
                        key: place,
                        value: place.cityName || '',
                      }}
                      name="address_stop"
                      placeholder="Digite o endereço da parada"
                      setValue={setValue}
                      onSelectOption={async option => {
                        addPlace(option.lat, option.lng, option.uf, option.cityName)
                        toast({
                          title: 'Parada adicionada com sucesso!',
                          description: 'Parada adicionada com sucesso.',
                          status: 'success',
                          duration: 9000,
                          isClosable: true,
                        })
                      }}
                      loadOptions={searchCitiesByName}
                    />
                    <Tooltip label="Remover parada" hasArrow>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          const size = places.length
                          setPlaces(places.filter(p => p.cityName !== place.cityName))
                          removeStop(size)
                          if (places.length !== size) {
                            toast({
                              title: 'Parada removida com sucesso!',
                              description: 'Parada removida com sucesso.',
                              status: 'success',
                              duration: 9000,
                              isClosable: true,
                            })
                          }
                        }}
                      >
                        <RiDeleteBinLine />
                      </Button>
                    </Tooltip>
                  </Flex>
                ))}
              </Flex>
            </Box>
          )}
      <Box>
        <Heading
          size="sm"
          display="flex"
          fontSize="sm"
          gridGap="2"
          mb="4"
          color="green.500"
          textTransform="uppercase"
        >
          <RiMapPin2Line /> Destino
        </Heading>
        <Box>
          <AutocompleteAsync
            isRequired
            error={errors.destination}
            initialValue={{
              label: destination ? destination.cityName : '',
              key: destination,
              value: destination?.cityName || '',
            }}
            name="destination"
            isDisabled={isBlockedEntity}
            placeholder="Digite o endereço de destino"
            setValue={setValue}
            onSelectOption={async option => {
              setDestination({
                cityName: option.cityName,
                lat: option.lat,
                lng: option.lng,
              })
              toast({
                title: 'Destino adicionado com sucesso!',
                description: 'Destino adicionado com sucesso.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              })
            }}
            loadOptions={searchCitiesByName}
          />
        </Box>
      </Box>
    </Flex>
  )
}
