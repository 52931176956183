import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { BsPersonCheckFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import { RiCheckDoubleLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FreightInfos } from '../../../../../components/card-infos/FreightInfos'
import { MotoristInfos } from '../../../../../components/card-infos/MotoristInfos'
import { TrailerInfos } from '../../../../../components/card-infos/TrailerInfos'
import { VehicleInfos } from '../../../../../components/card-infos/VehicleInfos'
import { ChecklistRiskAnalysisOutOfRangeAlert } from '../../../../../components/checklist-risk-analysis/ChecklistRiskAnalysisOutOfRangeAlert'
import { AlertCardCostRiskAnalysis } from '../../../../../components/CostRiskAnalysis/AlertCardCostRiskAnalysis'
import { Switch } from '../../../../../components/form/Switch'
import { IconHasMonitoringBuonny } from '../../../../../components/IconHasMonitoringBuonny'
import { ReloadButton } from '../../../../../components/ReloadButton'
import { usePutFreight } from '../../../../../services/endpoints/freights/putFreight'
import { useGetChecklistRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/checklist-risk-analysis/getChecklistRiskAnalysis'
import { useCheckCostRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/cost-risk-analysis/checkCostRiskAnalysis'
import { useGetOneRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { useAppDispatch } from '../../../../../store'
import { setRiskAnalysis, setRiskAnalysisId } from '../../../../../store/slices/riskAnalysisSlice'
import { BuonnyOutOfRangeAlert } from '../../../../freights/show/cards/freight-info/BuonnyOutOfRangeAlert'
import DisapprovedButton from '../../../modals/action-buttons/DisapprovedButton'
import ToCorrectButton from '../../../modals/action-buttons/ToCorrectButton'
import ToForceApprovalButton from '../../../modals/action-buttons/ToForceApprovalButton'
import { ApproveMotoristModal } from '../../../modals/ApproveMotoristModal'
import { ChangeStatusModal } from '../../../modals/ChangeStatusModal'
import { CheckMotoristBuonnyModal } from '../../../modals/check-motorist-buonny-modal'
import { DependsOnCheckModal } from '../../../modals/DependsOnCheckModal'
import { NotifyRiskAnalysisModal } from '../../../modals/notifications/NotifyRiskAnalysisModal'

interface RiskAnalysisInfosProps {
  risk_analysis_id: string
  bg: string
}

export function RiskAnalysisInfos({ bg, risk_analysis_id }: RiskAnalysisInfosProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { setValue } = useForm({})
  const toast = useToast()
  const {
    isOpen: isOpenDependsCheck,
    onOpen: onOpenDependsCheck,
    onClose: onCloseDependsCheck,
  } = useDisclosure()
  const {
    isOpen: isOpenApproveMotorist,
    onOpen: onOpenApproveMotorist,
    onClose: onCloseApproveMotorist,
  } = useDisclosure()

  const { data: riskAnalysis, isLoading, isFetching, refetch } = useGetOneRiskAnalysis(risk_analysis_id)
  const { data: checklistRiskAnalysis } = useGetChecklistRiskAnalysis({
    motorist_id: riskAnalysis?.motorist_id,
    vehicle_id: riskAnalysis?.vehicle_id,
    trailer_id: riskAnalysis?.trailer_id,
  })

  useEffect(() => {
    if (riskAnalysis) {
      dispatch(setRiskAnalysis(riskAnalysis))
      dispatch(setRiskAnalysisId(riskAnalysis.id))
    }
  }, [dispatch, riskAnalysis])

  const { mutateAsync: putFreightMutateAsync } = usePutFreight()

  const { data: checkCostRiskAnalysis } = useCheckCostRiskAnalysis({
    risk_analysis_id: riskAnalysis?.id,
  })

  return (
    <>
      {checkCostRiskAnalysis && checkCostRiskAnalysis.length > 0 && (
        <AlertCardCostRiskAnalysis
          risk_manager={checkCostRiskAnalysis[0].cost_risk_analysis_risk_manager}
          data={checkCostRiskAnalysis}
        />
      )}

      {riskAnalysis && (
        <>
          <Box bg={bg} maxW={1100} p="6" borderRadius="8" shadow="md" mb="8">
            {riskAnalysis.freight?.buonny_value_out_of_range && <BuonnyOutOfRangeAlert />}

            {riskAnalysis.freight.origin_id === riskAnalysis.freight.destination_id && (
              <Alert variant="subtle" status="error" borderRadius="md" justifyContent="space-between" mb={4}>
                <Flex>
                  <AlertIcon />
                  <AlertTitle mr={2}>
                    Realizar averbação de forma manual. Este frete dispensa a emissão de CTe. Deve circular
                    com a NFe da carga e o frete ser faturado via NFS-e.
                  </AlertTitle>
                </Flex>
              </Alert>
            )}

            {riskAnalysis.motorist?.total_freights < 1 && riskAnalysis.status !== 'Aprovado' && (
              <ChecklistRiskAnalysisOutOfRangeAlert />
            )}

            <Flex
              direction={['column', 'column', 'column', 'row']}
              alignItems="center"
              justify="space-between"
              gridGap={8}
            >
              <Heading size="lg" fontWeight="normal">
                <Flex gridGap={4} align="center">
                  <Tooltip hasArrow label="Ir para a página do frete.">
                    <Flex gridGap={4}>
                      <Heading fontSize={['x-large', 'xx-large']} fontWeight="normal">
                        <Link to={`/freights/show/${riskAnalysis.freight?.id}`}>
                          Análise do frete #{riskAnalysis.freight?.freight_number}
                        </Link>
                      </Heading>
                    </Flex>
                  </Tooltip>
                  <ReloadButton isFetching={isFetching} refetch={refetch} />
                  <IconHasMonitoringBuonny
                    fontSize="xx-large"
                    has_monitoring_buonny={riskAnalysis.freight?.has_monitoring_buonny}
                  />
                </Flex>
              </Heading>

              {!isLoading && isFetching && <Spinner size="sm" />}

              <Tag colorScheme={riskAnalysis?.color_status} p="2" borderRadius="full" minW="auto">
                <Text fontWeight="medium" fontSize="xl" ml="1">
                  {riskAnalysis?.status}
                </Text>
              </Tag>
            </Flex>

            {riskAnalysis?.consultation_code && (
              <>
                <Alert
                  mt={6}
                  borderRadius="8"
                  status={
                    riskAnalysis?.consultation_code === 'PERFIL ADEQUADO AO RISCO' ? 'success' : 'warning'
                  }
                >
                  <AlertIcon mr={4} />
                  <AlertTitle fontSize="2xl">{riskAnalysis?.consultation_code}</AlertTitle>
                </Alert>
              </>
            )}

            <Divider my={6} />

            <Flex gridGap={2} justify="space-between" align="center" direction={['column', 'column', 'row']}>
              {riskAnalysis && riskAnalysis.id && riskAnalysis.freight && (
                <Box>
                  <Switch
                    setValue={setValue}
                    label="Acionar monitoramento"
                    name="has_monitoring_buonny"
                    initialValue={riskAnalysis.freight.has_monitoring_buonny}
                    onChange={e => {
                      putFreightMutateAsync({
                        freight_id: riskAnalysis.freight_id,
                        has_monitoring_buonny: e.target.checked,
                      })
                      if (e.target.checked) {
                        toast({
                          duration: 5000,
                          isClosable: true,
                          title: 'Monitoramento acionado com sucesso!',
                          position: 'top',
                          status: 'success',
                        })
                      } else {
                        toast({
                          duration: 5000,
                          isClosable: true,
                          title: 'Monitoramento desativado!',
                          position: 'top',
                        })
                      }
                    }}
                  />
                </Box>
              )}

              <NotifyRiskAnalysisModal
                riskAnalysis={riskAnalysis}
                checklistRiskAnalysis={checklistRiskAnalysis}
              />

              <ChangeStatusModal />
            </Flex>

            <Divider my={6} />

            <Heading size="lg" fontWeight="bold" mb={4}>
              Informações do frete
            </Heading>

            {riskAnalysis.freight && <FreightInfos freight={riskAnalysis.freight} />}

            <Divider my={6} />

            <Heading size="lg" fontWeight="bold" mb={4}>
              Informações do motorista
            </Heading>

            {riskAnalysis.motorist && <MotoristInfos motorist={riskAnalysis.motorist} />}

            <Heading size="lg" fontWeight="bold" mb={4}>
              Informações do veículo
            </Heading>

            {riskAnalysis.vehicle && <VehicleInfos vehicle={riskAnalysis.vehicle} />}

            {riskAnalysis.vehicle &&
              riskAnalysis.vehicle.trailers &&
              riskAnalysis.vehicle.trailers.length > 0 &&
              riskAnalysis.vehicle.trailers.map(trailer => (
                <React.Fragment key={trailer.id}>
                  <Divider my={2} />
                  <Heading size="md" fontWeight="normal" mb="4">
                    Dados do reboque
                  </Heading>
                  <TrailerInfos trailer={trailer} />
                </React.Fragment>
              ))}

            {riskAnalysis && riskAnalysis.status !== 'Aprovado' && (
              <>
                <Divider my={6} />
                <Stack direction={['column', 'column', 'column', 'row']} justify="flex-end">
                  {riskAnalysis?.status !== 'Aprovado' && (
                    <>
                      {riskAnalysis?.status !== 'Reprovado' && <DisapprovedButton />}
                      <ToCorrectButton />
                      <Button
                        colorScheme="yellow"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/freights/show/${riskAnalysis.freight_id}/pendencies?motorist_id=${riskAnalysis.motorist_id}&vehicle_id=${riskAnalysis.vehicle_id}&integration=buonny&risk_analysis_id=${riskAnalysis.id}&gr_page=true`
                        }}
                        rightIcon={<FaArrowRight />}
                      >
                        Corrigir cadastros
                      </Button>
                    </>
                  )}

                  <CheckMotoristBuonnyModal />

                  {riskAnalysis?.consultation_code &&
                    riskAnalysis?.consultation_code === 'PERFIL ADEQUADO AO RISCO' &&
                    ['Aguardando', 'A Aprovar', 'Aguardando GR'].includes(riskAnalysis.status) && (
                      <Button
                        size="sm"
                        rightIcon={<RiCheckDoubleLine size={20} />}
                        colorScheme="green"
                        onClick={() => {
                          onOpenDependsCheck()
                        }}
                      >
                        Check list
                      </Button>
                    )}

                  {riskAnalysis.freight &&
                    riskAnalysis.freight.motorist_id &&
                    riskAnalysis.depends_on_check && (
                      <>
                        <Button
                          colorScheme="green"
                          size="sm"
                          onClick={onOpenApproveMotorist}
                          rightIcon={<BsPersonCheckFill />}
                        >
                          Aprovar
                        </Button>
                      </>
                    )}
                  {!['Aprovado'].includes(riskAnalysis.status) && <ToForceApprovalButton />}
                </Stack>
              </>
            )}

            {riskAnalysis && (
              <DependsOnCheckModal
                isOpen={isOpenDependsCheck}
                onClose={onCloseDependsCheck}
                risk_analysis_id={riskAnalysis.id}
              />
            )}
            {riskAnalysis && (
              <ApproveMotoristModal
                isOpen={isOpenApproveMotorist}
                onClose={onCloseApproveMotorist}
                risk_analysis_id={riskAnalysis.id}
              />
            )}
          </Box>
        </>
      )}
    </>
  )
}
