import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FetchCteReportParams } from './getCtes'

interface CteResponse {
  totalValue: number
  totalCtesSuccess: number
  totalCtesCancelled: number
  totalCtesReplaced: number
  totalCtesComplement: number
  totalFreights: number
}

export async function fetchTotalCteValues(data: FetchCteReportParams): Promise<CteResponse> {
  const params = data

  const { data: ctesResponse } = await apiServer.get<{ data: CteResponse }>(`/ctes/calc-value`, {
    params: {
      ...params,
      emit_date_ini: params.emit_date_ini ? `${params.emit_date_ini}T00:00:00` : undefined,
      emit_date_final: params.emit_date_final ? `${params.emit_date_final}T23:59:59` : undefined,
    },
  })

  return ctesResponse.data
}

export function useGetTotalCteValues(data: FetchCteReportParams): UseQueryResult<CteResponse> {
  return useQuery(['getTotalCteValues', data], () => fetchTotalCteValues(data), {
    staleTime: 1000 * 60,
    refetchInterval: 1000 * 30,
  })
}
