export const CNH_OPTIONS = [
  { label: '', value: '' },
  { label: 'A', value: 'A' },
  { label: 'AB', value: 'AB' },
  { label: 'AC', value: 'AC' },
  { label: 'AD', value: 'AD' },
  { label: 'AE', value: 'AE' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
]

export const PERSON_TYPES = [
  { label: '', value: '' },
  { label: 'Amigo', value: 'AMIGO' },
  { label: 'Cônjuge', value: 'CÔNJUGE' },
  { label: 'Parente', value: 'PARENTE' },
  { label: 'Vizinho', value: 'VIZINHO' },
]
