import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiStarLine, RiTruckLine, RiUserAddLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Input } from '../../../components/form/Input'
import { InputMask } from '../../../components/form/InputMask'
import { toastError } from '../../../config/error/toastError'
import { useGetMotorists } from '../../../services/endpoints/motorists/getMotorists'
import { usePostMotoristAttachVehicle } from '../../../services/endpoints/motorists/postMotoristAttachVehicle'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { vehicleCompleteFormSchema } from '../VehicleCompleteForm'

interface LinkVehicleToMotoristModalProps {
  vehicle_id: string
  isOpen: boolean
  onClose: () => void
}

export function LinkVehicleToMotoristModal({
  vehicle_id,
  isOpen,
  onClose,
}: LinkVehicleToMotoristModalProps): JSX.Element {
  const toast = useToast()
  const { setValue, formState } = useForm({
    resolver: yupResolver(vehicleCompleteFormSchema),
  })
  const { errors } = formState
  const [phone, setPhone] = useState<string>()
  const [name, setName] = useState<string>()
  const { data: allMotorists } = useGetMotorists({
    phone: phone?.length === 16 ? phone : undefined,
    name: name || undefined,
    per_page: 3,
  })
  const [motorists, setMotorists] = useState(allMotorists)
  const vehicle = useGetVehicle(String(vehicle_id))

  useEffect(() => {
    setMotorists(allMotorists)
  }, [allMotorists])

  const mutation = usePostMotoristAttachVehicle({
    onSuccess: () => {
      onClose()
      toast({
        title: 'Motorista vinculado com sucesso!',
        status: 'success',
        position: 'top-right',
      })
    },
    onError: (error: any) => {
      toastError({ toast, error })
    },
  })

  const handleLinkMotoristToVehicle = async (motoristId: string, vehicleId: string) => {
    await mutation.mutateAsync({ motoristId, vehicleId })
  }

  useEffect(() => {
    setName('')
    setPhone('')
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box p="8" justifyContent="space-between">
            <Flex
              mb={['6', '8']}
              gridGap="2"
              direction={['column', 'row']}
              align="center"
              justifyContent="space-between"
            >
              <Heading size="lg" fontWeight="normal">
                Todos os motoristas
              </Heading>
              <Button
                size="sm"
                as={Link}
                to={`/motorists/create?license_plate=${vehicle.data?.license_plate}`}
                colorScheme="orange"
                leftIcon={<Icon as={RiUserAddLine} />}
              >
                Cadastrar
              </Button>
            </Flex>

            <Flex
              direction={['column', 'column', 'row']}
              gridGap="6"
              alignItems="center"
              justifyContent="space-between"
            >
              <Input
                name="name"
                error={errors.name}
                setValue={setValue}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}
                h="40px"
                w={['220px', '450px', '300px', '400px', '500px']}
                placeholder="Nome do motorista"
              />

              <InputMask
                mask="(99) 9 9999-9999"
                registerOnlyNumbers
                maskPlaceholder=""
                name="phone"
                h="40px"
                w="220px"
                value={phone}
                onChange={e => {
                  setPhone(e.target.value)
                }}
                error={errors.phone}
                setValue={setValue}
                placeholder="Telefone"
              />
            </Flex>

            <Table d={['block', 'block', 'table']} overflowX="auto" mt="5">
              <Thead>
                <Tr>
                  <Th>Nota</Th>
                  <Th>Motorista</Th>
                  <Th>Últ. Checkin</Th>
                </Tr>
              </Thead>
              <Tbody>
                {motorists &&
                  motorists.data.map(motorist => {
                    return (
                      <Tr key={motorist.id}>
                        <Td textAlign="center">
                          {motorist.rate_score && (
                            <>
                              <Icon as={RiStarLine} color="yellow.500" fontSize={20} />
                              <Text fontWeight="bold" color="yellow.600">
                                {motorist.rate_score}
                              </Text>
                            </>
                          )}
                        </Td>
                        <Td whiteSpace="nowrap">
                          <Box>
                            <Link to={`/motorists/show/${motorist.id}`}>{motorist.name}</Link>
                            <Text mt="1" color="gray.500">
                              {motorist.phone}
                            </Text>
                          </Box>
                        </Td>
                        <Td whiteSpace="nowrap">
                          <Box>
                            <Text>{motorist.last_checkin_city}</Text>
                            <Text fontSize="sm" color="gray.400">
                              {motorist.last_checkin_time}
                            </Text>
                          </Box>
                        </Td>
                        <Td whiteSpace="nowrap">
                          <Button
                            size="sm"
                            colorScheme="green"
                            leftIcon={<Icon as={RiTruckLine} fontSize={18} />}
                            onClick={() => {
                              handleLinkMotoristToVehicle(motorist.id, vehicle_id)
                            }}
                          >
                            Vincular
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
