import { Button, Flex, Grid, GridItem, Icon, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { toastError } from '../../../../../config/error/toastError'
import { queryClient } from '../../../../../config/react-query'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { usePatchRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/patchRiskAnalysis'
import { usePostRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/postRiskAnalysis'
import { usePutRiskAnalysis } from '../../../../../services/endpoints/risk-analysis/putRiskAnalysis'
import { MotoristType, VehicleType } from '../../../../../services/types'
import { BuonnyMotoristFormData } from '../../../../../services/types/MotoristType'
import { scrollToTop } from '../../../../../services/utils/scrollToTop'
import { FormSendToBuonny } from './FormSendToBuonny'

interface SendToBuonnyProps {
  freight: FormattedOneFreight
  motorist: MotoristType
  vehicle: VehicleType
  tabIndex: number
}

export function SendToBuonny({ freight, motorist, vehicle, tabIndex }: SendToBuonnyProps): JSX.Element {
  const { id: freight_id } = freight
  const { id: motorist_id } = motorist
  const { id: vehicle_id, trailers } = vehicle
  const trailer_id = trailers[0]?.id
  const [vehicleModels, setVehicleModels] = useState<AutocompleteOption[]>([])
  const [trailerModels, setTrailerModels] = useState<AutocompleteOption[]>([])
  const hasTrailer = vehicle.trailers && vehicle.trailers.length > 0
  const SendToBuonnyFormSchema = yup.object().shape({
    buonny_cargo_price: yup.string().required('Campo obrigatório!'),
    buonny_cargo_type: yup.string().required('Campo obrigatório!'),
    cnpj_shipper: yup.string().required('Campo obrigatório!'),
    vehicle_color: yup.string().required('Campo obrigatório!'),
    vehicle_technology: yup.object().shape({}),
    vehicle_model: yup
      .object()
      .shape({})
      .when(() => {
        if (vehicleModels && vehicleModels.length > 0) {
          return yup.object().shape({}).required('Campo obrigatório!')
        }
        return yup.object().shape({})
      }),
    vehicle_brand: yup.object().shape({}).required('Campo obrigatório!'),
    trailer_technology: yup.object().shape({}),
    trailer_model: yup
      .object()
      .shape({})
      .when(() => {
        if (hasTrailer && trailerModels && trailerModels.length > 0) {
          return yup.object().shape({}).required('Campo obrigatório!')
        }
        return yup.object().shape({})
      }),
    trailer_brand: yup
      .object()
      .shape({})
      .when(() => {
        if (hasTrailer) {
          return yup.object().shape({}).required('Campo obrigatório!')
        }
        return yup.object().shape({})
      }),
    trailer_color: yup.string().when(() => {
      if (hasTrailer) {
        return yup.string().required('Campo obrigatório!')
      }
      return yup.string()
    }),
  })

  const history = useHistory()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(SendToBuonnyFormSchema),
  })
  const toast = useToast()

  const riskAnalysisId = useQueryParams('risk_analysis_id')
  const grPage = useQueryParams('gr_page')

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } = usePostRiskAnalysis({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Dados enviados com sucesso!',
        position: 'top-right',
      })
      history.push(`/freights/show/${freight.id}`)
      scrollToTop(1050)
    },
    onError: error => {
      toastError({ toast, error })
    },
  })
  const { mutateAsync: mutateAsyncPut, isLoading: isLoadingPut } = usePutRiskAnalysis()
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()

  const handleIncludeMotoristToBuonny = useCallback<SubmitHandler<BuonnyMotoristFormData>>(
    async data => {
      const riskAnalysisData = {
        freight_id,
        motorist_id,
        vehicle_id,
        trailer_id: vehicle.trailers && vehicle.trailers.length > 0 ? trailer_id : undefined,
        // payload freight buonny
        buonny_cargo_type: String(data?.buonny_cargo_type),
        buonny_cargo_price: data?.buonny_cargo_price,
        buonny_contact_name: data?.buonny_contact_name,
        buonny_contact_type: data?.buonny_contact_type,
        buonny_contact_description: data?.buonny_contact_description,
        // payload client buonny
        cnpj_shipper: data.cnpj_shipper,
        // payload vehicles buonny
        vehicle_model_buonny: data?.vehicle_model?.value ? String(data?.vehicle_model?.value) : undefined,
        vehicle_brand_buonny: data?.vehicle_brand?.value ? String(data?.vehicle_brand?.value) : undefined,
        vehicle_technology: data?.vehicle_technology?.value
          ? String(data?.vehicle_technology?.value)
          : undefined,
        vehicle_color: data?.vehicle_color,
        trailer_model_buonny: data?.trailer_model?.value ? String(data?.trailer_model?.value) : undefined,
        trailer_brand_buonny: data?.trailer_brand?.value ? String(data?.trailer_brand?.value) : undefined,
        trailer_technology: data?.trailer_technology?.value
          ? String(data?.trailer_technology?.value)
          : undefined,
        trailer_color: data?.trailer_color,
        has_tracking_system: data?.has_tracking_system,
        tracking_system: data?.tracking_system,
        // payload motorist buonny
        business_contact: data.business_contact,
        personal_contact: data.personal_contact,
        motorist_category: data?.category,
        was_stolen_in_last_24_months: data?.was_stolen_in_last_24_months,
        stolen_times: data?.stolen_times,
        had_accident: data?.had_accident,
        accident_times: data?.accident_times,
        how_long_carry_to_company: data?.how_long_carry_to_company
          ? String(data?.how_long_carry_to_company)
          : undefined,
        carry_times: data?.carry_times,
        respond_for_crime_in_last_10_years: data?.respond_for_crime_in_last_10_years,
        crime_process_numbers: data?.crime_process_numbers,
      }

      if (riskAnalysisId) {
        try {
          await mutateAsyncPut({
            id: riskAnalysisId,
            ...riskAnalysisData,
          })
          toast({
            status: 'success',
            title: 'Dados atualizados com sucesso!',
            position: 'top-right',
          })
          if (grPage) {
            history.push(`/risk-analyst-infos/${riskAnalysisId}`)
            scrollToTop(1500)
          } else {
            await mutateAsyncChangeStatus({ id: riskAnalysisId, status: 'awaiting' })
            history.push(`/freights/show/${freight.id}`)
            scrollToTop(1050)
          }
        } catch (error) {
          toastError({ toast, error })
          history.push(`/freights/show/${freight.id}`)
          scrollToTop(1050)
        }
      } else {
        try {
          await mutateAsyncCreate(riskAnalysisData)
        } catch (error) {
          history.push(`/freights/show/${freight.id}`)
          scrollToTop(1050)
        }
      }
    },

    [
      freight_id,
      motorist_id,
      vehicle_id,
      vehicle.trailers,
      trailer_id,
      riskAnalysisId,
      toast,
      grPage,
      history,
      freight.id,
      mutateAsyncCreate,
      mutateAsyncPut,
      mutateAsyncChangeStatus,
    ],
  )

  useEffect(() => {
    queryClient.invalidateQueries('find-route-points')
    queryClient.invalidateQueries('black-list')
    queryClient.invalidateQueries('freight')
    queryClient.invalidateQueries('motorist')
    queryClient.invalidateQueries('vehicle')
    queryClient.invalidateQueries('check-pendencies')
  }, [tabIndex])

  return (
    <form onSubmit={handleSubmit(handleIncludeMotoristToBuonny)} noValidate>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <FormSendToBuonny
          formState={formState}
          freight={freight}
          motorist={motorist}
          setValue={setValue}
          vehicle={vehicle}
          vehicleModels={vehicleModels}
          trailerModels={trailerModels}
          setVehicleModels={setVehicleModels}
          setTrailerModels={setTrailerModels}
        />
        <GridItem colSpan={12} textAlign="center" mt="8">
          <Flex justify="flex-end" mt={4}>
            <Button
              type="submit"
              size="sm"
              leftIcon={<Icon as={RiCheckLine} />}
              colorScheme="green"
              isLoading={isLoadingCreate || isLoadingPut || isLoadingChangeStatus}
            >
              Enviar para Análise
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </form>
  )
}
