import { Th, Tr } from '@chakra-ui/react'

interface TrComponentProps {
  canEdit: boolean
}

export function TrComponent({ canEdit }: TrComponentProps): JSX.Element {
  return (
    <Tr>
      <Th whiteSpace="nowrap">Frete</Th>
      <Th whiteSpace="nowrap">Tem Tag?</Th>
      <Th whiteSpace="nowrap">Cliente</Th>
      <Th whiteSpace="nowrap">Coleta</Th>
      <Th whiteSpace="nowrap">P. Entrega</Th>
      <Th whiteSpace="nowrap">Origem</Th>
      <Th whiteSpace="nowrap">Destino</Th>
      <Th whiteSpace="nowrap">S.Frete</Th>
      <Th whiteSpace="nowrap">Pedágio</Th>
      <Th whiteSpace="nowrap">VPO</Th>
      <Th whiteSpace="nowrap">Pedágio pago</Th>
      {canEdit && <Th whiteSpace="nowrap">Ação</Th>}
    </Tr>
  )
}
