import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { RiTruckLine, RiCaravanLine, RiEditLine } from 'react-icons/ri'
import { Pagination } from '../../../layout/Pagination'
import { TrailersFiltersParams, useGetAllTrailer } from '../../../services/endpoints/trailers/getAllTrailer'
import { EditTrailerModal } from '../../vehicles/modals/EditTrailerModal'
import { InfoMotoristLinkedToTrailerExists } from '../form-trailers/InfoMotoristLinkedToTrailerExists'
import { InfoOwnerLinkedToTrailerExists } from '../form-trailers/InfoOwnerLinkedToTrailerExists'

interface ListTrailerAccordionProps {
  filters?: TrailersFiltersParams
  onChangePage: (page: number) => void
}

export function ListTrailerAccordion({ filters, onChangePage }: ListTrailerAccordionProps): JSX.Element {
  const toast = useToast()
  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }

  const {
    data: trailers,
    refetch,
    isLoading,
    error: errorList,
  } = useGetAllTrailer({
    ...filters,
    per_page: 5,
  })

  const {
    isOpen: isTrailerFormModalOpen,
    onOpen: onTrailerFormModalOpen,
    onClose: onTrailerFormModalClose,
  } = useDisclosure()
  const {
    onClose: onCloseEditTrailerModal,
    isOpen: isOpenEditTrailerModal,
    // onOpen: onOpenEditTrailerModal,
  } = useDisclosure()

  const [trailerIdToUpdate, setTrailerIdToUpdate] = useState<string>()

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spinner size="lg" />
        </Flex>
      ) : errorList ? (
        <Text>
          Não foi possível carregar dados dos reboques! Por favor tente mais tarde ou contacte a equipe de
          suporte!
        </Text>
      ) : (
        <>
          <Accordion allowToggle overflowX="auto">
            {trailers &&
              trailers.data.map(trailer => (
                <AccordionItem key={trailer.id}>
                  <Flex direction={['column', 'column', 'column', 'row']}>
                    <AccordionButton w="100%" gridGap="4">
                      <AccordionIcon fontSize="xx-large" />

                      {trailer.vehicle_category && (
                        <WrapItem alignItems="center">
                          <Text color="gray.400">Categoria:</Text>
                          <Tag size="lg" borderRadius="full" ml="2">
                            <TagLeftIcon as={RiTruckLine} />
                            <TagLabel>{trailer.vehicle_category?.name}</TagLabel>
                          </Tag>
                        </WrapItem>
                      )}

                      <WrapItem alignItems="center">
                        <Text color="gray.400">Carroceria:</Text>
                        <Tag size="lg" borderRadius="full" ml="2">
                          <TagLeftIcon as={RiCaravanLine} />
                          <TagLabel>{trailer.vehicle_body?.name}</TagLabel>
                        </Tag>
                      </WrapItem>
                      <WrapItem>
                        <Text color="gray.400">Placa:</Text>
                        {trailer.license_plate && (
                          <Tooltip label="Clique para copiar placa" hasArrow>
                            <Text
                              onClick={() => {
                                copyToClipboard(String(trailer.license_plate))
                                toast({
                                  title: 'Copiado com sucesso!',
                                  status: 'success',
                                  position: 'top-right',
                                  duration: 1000,
                                })
                              }}
                              fontWeight="medium"
                              ml="1"
                            >
                              {trailer.license_plate}
                            </Text>
                          </Tooltip>
                        )}
                      </WrapItem>
                    </AccordionButton>
                    <Flex justifyContent="flex-end" alignItems="flex-start" my="2" gridGap="2">
                      <Tooltip label="Editar" hasArrow>
                        <Button
                          size="sm"
                          colorScheme="yellow"
                          onClick={() => {
                            onTrailerFormModalOpen()

                            // onOpenEditTrailerModal();

                            setTrailerIdToUpdate(trailer.id)
                          }}
                        >
                          <Icon as={RiEditLine} />
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Divider />
                  <AccordionPanel pb={4}>
                    <SimpleGrid minChildWidth="250px" spacing="4">
                      <WrapItem>
                        <Text color="gray.400">Fabricação:</Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.release_year}
                        </Text>
                      </WrapItem>
                      <WrapItem>
                        <Text color="gray.400">Marca:</Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.brand}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Modelo:</Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.model}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">UF:</Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.license_uf}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Renavan: </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.renavam}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Chassi: </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.chassi}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">ANTT: </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.antt}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (m3): </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.capacity_m3}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (TARA): </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.capacity_tara}
                        </Text>
                      </WrapItem>

                      <WrapItem>
                        <Text color="gray.400">Capacidade (kg): </Text>
                        <Text fontWeight="medium" ml="1">
                          {trailer.capacity_kg}
                        </Text>
                      </WrapItem>

                      {trailer.has_tracker && (
                        <WrapItem>
                          <Text color="gray.400">Tem rastreador?: </Text>
                          <Text fontWeight="medium" ml="1">
                            Sim
                          </Text>
                        </WrapItem>
                      )}

                      {trailer.has_insurance && (
                        <WrapItem>
                          <Text color="gray.400">Tem seguro?: </Text>
                          <Text fontWeight="medium" ml="1">
                            Sim
                          </Text>
                        </WrapItem>
                      )}
                    </SimpleGrid>
                    <Divider my="6" />
                    <InfoOwnerLinkedToTrailerExists
                      ownerIdToLinkMotorist={trailer.owner_id}
                      trailerId={trailer.id}
                    />
                    <Divider my="6" />
                    <InfoMotoristLinkedToTrailerExists trailerExists={trailer} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
          <Pagination
            currentPage={filters?.page || 1}
            totalCountOfRegisters={Number(trailers?.total)}
            registersPerPage={20}
            onPageChange={onChangePage}
            mt="8"
          />
        </>
      )}
      {trailerIdToUpdate && (
        <>
          <EditTrailerModal
            trailer_id={trailerIdToUpdate}
            isOpen={isTrailerFormModalOpen}
            onClose={() => {
              onTrailerFormModalClose()
              refetch()
            }}
          />

          <EditTrailerModal
            isOpen={isOpenEditTrailerModal}
            onClose={() => {
              onCloseEditTrailerModal()
              refetch()
            }}
            trailer_id={trailerIdToUpdate}
          />
        </>
      )}
    </>
  )
}
