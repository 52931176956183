import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface FreightsReport {
  id: string
  freight_number: number
  transporter: string
  motorist_name: string
  motorist_note: number
  has_app: boolean
  toll_ticket: string
  payment_method: string
  spotx_note_by_client: number
  spotx_note_by_motorist: number
  status: string
  created_at: string
  finished_at: string
  delivered_cargo_at: string
  denied_at: string
  is_quotation: boolean
}

export type FreightsReportResponse = {
  total: {
    all: number
    pending: number
    in_progress: number
    no_show: number
    disabled: number
    finished: number
  }
  freights: FreightsReport[]
}
interface FreightsReportParams {
  initialDateFreights: string
  finalDateFreights: string
}

export async function fetchReportsFreights({
  initialDateFreights,
  finalDateFreights,
}: FreightsReportParams): Promise<FreightsReportResponse> {
  const { data } = await apiServer.get<ResponseApiType<FreightsReportResponse>>(`/freights/report/`, {
    params: {
      initial_date: `${initialDateFreights}T00:00:00`,
      final_date: `${finalDateFreights}T23:59:59`,
    },
  })
  return data.data
}

export function useReportsFreights(params: FreightsReportParams): UseQueryResult<FreightsReportResponse> {
  return useQuery(['reports-freights', params], () => fetchReportsFreights(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
