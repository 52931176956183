import { Box, Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useGetCheckBuonnyHistory } from '../../../../../services/endpoints/risk-management/list-buonny-motorist-check'

interface BuonnyHistoryProps {
  motorist_id: string
  bg: string
}

interface BuonnyHistoryType {
  id: string
  buonny_consult_number: string
  status: string
  created_at: string
  formatted_created_at: string
  origin?: { name: string }
  destination?: { name: string }
}

export function BuonnyHistory({ motorist_id, bg }: BuonnyHistoryProps): JSX.Element {
  const [buonnyHistory, setBuonnyHistory] = useState<BuonnyHistoryType[]>([])
  const { data: fetchedHistory, refetch } = useGetCheckBuonnyHistory({ motorist_id })

  useEffect(() => {
    const loadHistory = async () => {
      await refetch()
      if (fetchedHistory) {
        setBuonnyHistory(fetchedHistory.data)
      }
    }

    loadHistory()
  }, [motorist_id, fetchedHistory, refetch])

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Flex mb={['6', '8']} justify="space-between" align="center" direction={['column', 'row']} gridGap={2}>
        <Heading size="lg" fontWeight="normal">
          Consultas na Buonny
        </Heading>
      </Flex>

      <Table d={['block', 'block', 'table']} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Consulta</Th>
            <Th>Data da consulta</Th>
            <Th>Origem</Th>
            <Th>Destino</Th>
          </Tr>
        </Thead>
        <Tbody>
          {buonnyHistory.map(history => (
            <Tr key={history.id}>
              <Td>{history.status}</Td>
              <Td>{history.buonny_consult_number}</Td>
              <Td>{history.formatted_created_at}</Td>
              <Td>{history.origin?.name}</Td>
              <Td>{history.destination?.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}
