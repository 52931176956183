import { Spinner, Text, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useGetFreightRemainDistance } from '../services/endpoints/freights/getFreightRemainDistance'
import { FormattedAllFreightsInProgress } from '../services/endpoints/freights/getFreightsInProgress'

interface RemainDistanceResultProps {
  freight: FormattedAllFreightsInProgress
}

export function RemainDistanceResult({ freight }: RemainDistanceResultProps): JSX.Element {
  const { data, isLoading } = useGetFreightRemainDistance(freight)

  if (!data?.distanceToRoute && !data?.distance)
    return (
      <Text
        as={Link}
        size="sm"
        px={2}
        target="_blank"
        to={`/freights/show/${freight.id}`}
        _hover={{ textDecoration: 'underline' }}
      >
        Certifique-se que a rota está preenchida
      </Text>
    )

  return (
    <>
      {isLoading ? (
        <>
          <Spinner size="xs" />
        </>
      ) : data && data.distanceToRoute && data.distance ? (
        <Tooltip
          hasArrow
          label={
            data && data.distanceToRoute
              ? `Distância do motorista até a rota escolhida: ${Number(data?.distanceToRoute).toFixed(2)}km`
              : ' '
          }
        >
          {`${Number(data?.distance).toFixed(2)}km`}
        </Tooltip>
      ) : (
        ''
      )}
    </>
  )
}
