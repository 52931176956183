import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export type PostPredictParams = {
  type?: string
  has_toll?: string
  distance?: number
  origin_is_capital?: string
  destination_is_capital?: string
  origin_lat?: string
  origin_lng?: string
  destination_lat?: string
  destination_lng?: string
  width?: string
  height?: string
  depth?: string
  weight?: string
  value?: string
  require_tracker?: string
  has_complement?: string
}

type ResponseApi = {
  prediction: string
}

export async function predictServicePrice(data: PostPredictParams): Promise<ResponseApiType<ResponseApi>> {
  const { data: response } = await apiServer.post<ResponseApiType<ResponseApi>>(
    '/predict-service-price',
    data,
  )

  return response
}

export function usePostPredictServicePrice(
  options?: UseMutationOptions<ResponseApiType<ResponseApi>, unknown, PostPredictParams, unknown>,
): UseMutationResult<ResponseApiType<ResponseApi>, unknown, PostPredictParams, unknown> {
  return useMutation(async (data: PostPredictParams) => predictServicePrice(data), options)
}
