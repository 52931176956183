import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { MdClear } from 'react-icons/md'
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask'
import getOnlyNumbers from '../../services/utils/getOnlyNumbers'

type InputMaskProps = ChakraInputProps &
  ReactInputMaskProps & {
    name: string
    uppercaseAll?: boolean
    setValue: UseFormSetValue<FieldValues>
    clearState?: (value: any | undefined) => void
    initialValue?: string
    label?: string
    error?: FieldError
    registerOnlyNumbers?: boolean
    isLoading?: boolean
  }

const InputMaskBase: ForwardRefRenderFunction<HTMLInputElement, InputMaskProps> = (
  {
    name,
    setValue,
    initialValue,
    label,
    uppercaseAll = false,
    error = null,
    registerOnlyNumbers = false,
    onChange,
    clearState,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    _hover,
    isLoading,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)

  function setInputValue(value: string) {
    if (inputRef.current) {
      const inputDOM = inputRef.current.querySelector('input')
      if (inputDOM) {
        inputDOM.value = value
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target

    if (uppercaseAll) {
      value = value.toUpperCase()
      if (inputRef.current) {
        const inputDOM = inputRef.current.querySelector('input')
        if (inputDOM) {
          inputDOM.value = value
        }
      }
    }

    if (registerOnlyNumbers) {
      value = getOnlyNumbers(value)
    }

    setValue(name, value)

    if (onChange) {
      onChange(e)
    }
  }

  useEffect(() => {
    if (initialValue) {
      let parsedValue = initialValue
      if (registerOnlyNumbers) parsedValue = getOnlyNumbers(initialValue)
      setValue(name, parsedValue)
      setInputValue(initialValue)
    }
  }, [initialValue, registerOnlyNumbers, setValue, name])

  return (
    <FormControl isInvalid={!!error} isDisabled={isDisabled} isRequired={isRequired} ref={inputRef}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}

      <InputGroup size={size} ref={inputRef}>
        <>
          <ChakraInput
            as={ReactInputMask}
            ref={ref}
            id={name}
            name={name}
            focusBorderColor={focusBorderColor}
            bgColor={bgColor || bg}
            variant={variant}
            size={size}
            _hover={_hover || { bgColor: bg }}
            isDisabled={isDisabled}
            _disabled={{
              opacity: 0.7,
              cursor: 'not-allowed',
            }}
            onChange={handleChange}
            disableAutocomplete
            disableGoogleAutocomplete
            autoComplete={autoComplete}
            maskPlaceholder=""
            {...rest}
          />
          {isLoading && (
            <Spinner size="sm" color="orange.500" position="absolute" right="0" top="0" bottom="0" m="auto" />
          )}
        </>

        <Flex ml="2">
          <InputRightElement cursor={isDisabled ? 'not-allowed' : 'pointer'}>
            {isLoading && <Spinner size="sm" position="absolute" right="0" top="0" bottom="0" m="auto" />}
            {!isDisabled && (
              <Icon
                fontSize="md"
                onClick={() => {
                  setInputValue('')
                  setValue(name, undefined)
                  if (clearState) clearState(undefined)
                  if (inputRef.current) {
                    const inputDOM = inputRef.current.querySelector('input')
                    if (inputDOM) {
                      inputDOM.value = ''
                    }
                  }
                }}
                as={MdClear}
              />
            )}
          </InputRightElement>
        </Flex>
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const InputMask = forwardRef(InputMaskBase)
