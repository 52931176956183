import { Button, Flex, Input, Select, Text } from '@chakra-ui/react'
import { Table } from '@tanstack/react-table'
import useTableColors from './useTableColors'

type TablePaginationProps = {
  table: Table<any>
}

const TablePagination = ({ table }: TablePaginationProps): JSX.Element => {
  const { footerBackgroundColor, borderColor } = useTableColors()
  return (
    <Flex
      borderWidth="1px"
      borderBottomRadius="lg"
      borderColor={borderColor}
      borderTopWidth="0px"
      bg={footerBackgroundColor}
      gridGap={2}
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <Flex alignItems="center" gridGap={2}>
        <Button size="sm" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </Button>
        <Button size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </Button>
        <Button size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          {'>'}
        </Button>
        <Button
          size="sm"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </Button>
      </Flex>
      <Flex className="flex items-center gap-1">
        <Text mr={2}>Pagina</Text>
        <strong>
          {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
        </strong>
      </Flex>
      <Flex width="fit-content" alignItems="center">
        <Text whiteSpace="nowrap">Ir para page:</Text>
        <Input
          width="50px"
          height="26px"
          bg="blackAlpha.100"
          size="md"
          fontSize="sm"
          ml={2}
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            table.setPageIndex(page)
          }}
        />
      </Flex>
      <Select
        width="fit-content"
        fontSize="sm"
        size="sm"
        value={table.getState().pagination.pageSize}
        onChange={e => {
          table.setPageSize(Number(e.target.value))
        }}
      >
        {[60, 120, 180].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Exibir {pageSize}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

export default TablePagination
