/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { dispatch } from '..'
import { SelectOption } from '../../components/form/types/SelectOption'
import { queryClient } from '../../config/react-query'
import { DisableData } from '../../pages/freights/edit/DisableFreightModal'
import { AddAttachmentFormData } from '../../pages/freights/show/cards/attachments/AddAttachmentModal'
import { FreightFormData } from '../../pages/operations/forms/FreightAndQuotationForm'
import { apiServer } from '../../services/api'
import { getNextStatus } from '../../services/endpoints/freights/getFreights'
import { FormattedFreightStatusHistory } from '../../services/endpoints/freights/getHistoryFreight'
import { fetchOneFreight, FormattedOneFreight } from '../../services/endpoints/freights/getOneFreight'
import { updateFreight as putFreight } from '../../services/endpoints/freights/putFreight'
import { FreightAttachmentType } from '../../services/types'
import {
  FreightRequestOriginEnum,
  FreightsStatusEnum,
  FreightStatusHistoryDisabledReasonEnum,
} from '../../services/types/EnumTypes'
// aqui
interface LinkMotoristProps {
  freight_id: string
  vehicle_id: string
  motorist_id: string
}

export type ChangeFrightStatusInput = {
  freight_id: string
  status: FreightsStatusEnum | string
  origin?: FreightRequestOriginEnum
  get_coords_freight_status?: string
  disabled_reason?: FreightStatusHistoryDisabledReasonEnum
  description?: string
  status_date?: string
}

export interface DataWaypointsZApi {
  latitude: string
  longitude: string
  created_at: Date
}

export interface CreateManualMonitoring {
  freight_id: string
  ibge_id: number
  type: 'phone' | 'tracker_device'
}

export interface ListManualMonitoring {
  id: string
  freight_id: string
  type: string
  ibge_id: number
  travelled_distance: string
  created_at: string
  city: City
}

interface City {
  ibge_id: number
  name: string
  lat: string
  lng: string
  is_capital: boolean
  state_ibge_id: number
}

interface State {
  isLoading: boolean
  isQuotation: boolean
  client_business_group_id?: string
  btnWppDisabled: boolean
  btnFcmDisabled: boolean
  wppTime: number
  fcmTime: number
  actionFreightType: 'edit' | 'duplicate'
  freightForm?: FreightFormData
  nextStatus: SelectOption | null
  freight: FormattedOneFreight | null
  freights: FormattedOneFreight[]
  statusHistory?: FormattedFreightStatusHistory[]
  pendingFreights: FormattedOneFreight[]
  currentFreights: FormattedOneFreight[]
  finishedFreights: FormattedOneFreight[]
  noShowFreights: FormattedOneFreight[]
  disabledFreights: FormattedOneFreight[]
  createManualMonitoringState: CreateManualMonitoring
  waypointsZApi: DataWaypointsZApi[]
  initialBtnWppMonitoringDisabled: boolean
  initialResStatusWppMonitoring: boolean
  stateTogglePermission: boolean
  btnSendingMsgToMotoristDisabled: boolean
}

const initialState: State = {
  isLoading: false,
  isQuotation: false,
  client_business_group_id: '',
  btnWppDisabled: false,
  initialBtnWppMonitoringDisabled: false,
  initialResStatusWppMonitoring: true,
  btnFcmDisabled: false,
  stateTogglePermission: true,
  btnSendingMsgToMotoristDisabled: false,
  wppTime: 31,
  fcmTime: 31,
  actionFreightType: 'edit',
  freightForm: undefined,
  nextStatus: null,
  freight: null,
  createManualMonitoringState: {
    freight_id: '',
    ibge_id: 0,
    type: 'phone',
  },
  freights: [],
  statusHistory: [],
  pendingFreights: [],
  currentFreights: [],
  finishedFreights: [],
  noShowFreights: [],
  disabledFreights: [],
  waypointsZApi: [],
}

const freightSlice = createSlice({
  name: 'freights',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setIsQuotation(state, action: PayloadAction<boolean>) {
      state.isQuotation = action.payload
    },

    setClientBusinessGroupId(state, action: PayloadAction<string>) {
      state.client_business_group_id = action.payload
    },

    setDisabledBtnWpp(state, action: PayloadAction<boolean>) {
      state.btnWppDisabled = action.payload
    },
    setDisabledBtnFcm(state, action: PayloadAction<boolean>) {
      state.btnFcmDisabled = action.payload
    },
    setWppTime(state, action: PayloadAction<number>) {
      state.wppTime = action.payload
    },
    setFcmTime(state, action: PayloadAction<number>) {
      state.fcmTime = action.payload
    },

    setActionFreightType(state, action: PayloadAction<'edit' | 'duplicate'>) {
      state.actionFreightType = action.payload
    },

    setFreightForm(state, action: PayloadAction<FreightFormData>) {
      state.freightForm = action.payload
    },

    setNextStatus(state, action: PayloadAction<SelectOption | null>) {
      state.nextStatus = action.payload
    },

    setStatus(state, action: PayloadAction<string>) {
      state.freight = {
        ...(state.freight as FormattedOneFreight),
        status: action.payload,
        next_status: getNextStatus(action.payload),
      }
    },

    setLinkMotorist(state, action: PayloadAction<Omit<LinkMotoristProps, 'freight_id'>>) {
      state.freight = {
        ...(state.freight as FormattedOneFreight),
        motorist_id: action.payload.motorist_id,
        vehicle_id: action.payload.vehicle_id,
      }
    },

    setUnkMotorist(state, action: PayloadAction<undefined>) {
      state.freight = {
        ...(state.freight as FormattedOneFreight),
        motorist: action.payload,
        motorist_id: action.payload,
        vehicle: action.payload,
        vehicle_id: action.payload,
      }
    },

    setAttachment(state, action: PayloadAction<FreightAttachmentType>) {
      const { freight } = state
      if (freight && freight.attachments) {
        state.freight = {
          ...(state.freight as FormattedOneFreight),
          attachments: [...freight.attachments, action.payload],
        }
      }
    },

    setDeleteAttachment(state, action: PayloadAction<string>) {
      const { freight } = state
      if (freight && freight.attachments?.length) {
        const attachments = state.freight?.attachments?.filter(i => i.id !== action.payload)
        state.freight = {
          ...(state.freight as FormattedOneFreight),
          attachments,
        }
      }
    },

    setFreightSuccess(state, action: PayloadAction<FormattedOneFreight | null>) {
      state.freight = action.payload
    },

    setWaypointsZApi(state, action: PayloadAction<DataWaypointsZApi[]>) {
      state.waypointsZApi = action.payload
    },

    setFreightStatusHistory(state, action: PayloadAction<FormattedFreightStatusHistory[] | undefined>) {
      state.statusHistory = action.payload
    },

    setInitialBtnWhatsAppByZApi(state, action: PayloadAction<boolean>) {
      state.initialBtnWppMonitoringDisabled = action.payload
    },

    setCreateManualMonitoring(state, action: PayloadAction<any>) {
      state.createManualMonitoringState = action.payload
    },

    setInitialResStatusWhatsAppByZApi(state, action: PayloadAction<boolean>) {
      state.initialResStatusWppMonitoring = action.payload
    },

    setStateTogglePermissionWhatsAppByZApi(state, action: PayloadAction<boolean>) {
      state.stateTogglePermission = action.payload
    },

    setDisabledBtnSendingMsgWhatsAppByZApi(state, action: PayloadAction<boolean>) {
      state.btnSendingMsgToMotoristDisabled = action.payload
    },
  },
})

export const {
  setStatus,
  setIsQuotation,
  setClientBusinessGroupId,
  setActionFreightType,
  setFreightStatusHistory,
  setFreightForm,
  setNextStatus,
  setIsLoading,
  setFreightSuccess,
  setAttachment,
  setDeleteAttachment,
  setDisabledBtnWpp,
  setDisabledBtnFcm,
  setWppTime,
  setFcmTime,
  setWaypointsZApi,
  setInitialBtnWhatsAppByZApi,
  setCreateManualMonitoring,
  setInitialResStatusWhatsAppByZApi,
  setStateTogglePermissionWhatsAppByZApi,
  setDisabledBtnSendingMsgWhatsAppByZApi,
} = freightSlice.actions

export default freightSlice.reducer

function loading(isLoading: boolean) {
  dispatch(freightSlice.actions.setIsLoading(isLoading))
}

export function getFreight(freight_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const freight = await fetchOneFreight({ freight_id })
      if (freight) dispatch(freightSlice.actions.setFreightSuccess(freight))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function createManualMonitoring(
  freight_id: string,
  ibge_id: number,
  type: string,
): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const monitoring = await apiServer.post(`/create-manual-monitoring`, {
        freight_id,
        ibge_id,
        type,
      })
      dispatch(setCreateManualMonitoring(monitoring))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function changeStatusMonitoringWhatsAppByZApiCron(
  freight_id: string,
  state: boolean,
): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await putFreight({ freight_id, can_monitoring_whatsapp: !state })

      dispatch(setStateTogglePermissionWhatsAppByZApi(!state))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}
export function changeWhatsAppSendingIntervalByZApi(
  freight_id: string,
  value: number,
): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.put(`/update-freight/${freight_id}`, {
        whatsapp_notification_interval: value,
        disable_entities_block: true,
      })
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function sendingRequestMonitoringWhatsAppByZApi(freight_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.post(`/geolocation/request-geolocation-by-whatsapp`, {
        freight_id,
      })
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function sendingInitialRequestMonitoringWhatsAppByZApi(freight_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const {
        data: { data },
      } = await apiServer.post(`/geolocation/start-monitoring-whatsapp/${freight_id}`)
      if (data) {
        if (data.message === 'Motorist has already started monitoring') {
          window.location.reload()
          dispatch(freightSlice.actions.setInitialResStatusWhatsAppByZApi(true))
        }
      }
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function updateFreight(
  freight_id: string,
  data: FreightFormData | FormattedOneFreight,
): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.put(`/update-freight/${freight_id}`, data)
      const freightHistory = queryClient.getQueryData<FormattedFreightStatusHistory[]>([
        'freight-status-history',
        freight_id,
      ])
      if (freightHistory) {
        queryClient.setQueryData(
          ['freight-status-history', freight_id],
          [
            ...freightHistory,
            {
              created_at: format(new Date(), "dd/MM 'às' HH:mm"),
              status: data.status,
            },
          ],
        )
      }
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('freights')
      queryClient.invalidateQueries('one-quotation')
      queryClient.invalidateQueries('all-quotations')
      queryClient.invalidateQueries('freights-current')
      queryClient.invalidateQueries('compatible_motorists')
      queryClient.invalidateQueries('contacts_from_client')
      queryClient.invalidateQueries('freight-status-history')
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function changeFrightStatus({
  freight_id,
  status,
  description,
  disabled_reason,
  get_coords_freight_status,
  origin,
  status_date,
}: ChangeFrightStatusInput): () => Promise<unknown> {
  return async () => {
    const statusDate = zonedTimeToUtc(
      status_date ? new Date(String(status_date)) : new Date(),
      'America/Sao_Paulo',
    ).toISOString()
    try {
      loading(true)
      await apiServer.put(`/change-freight-status/${freight_id}`, {
        status,
        description,
        disabled_reason,
        get_coords_freight_status,
        origin,
        status_date: statusDate,
      })
      const freight = queryClient.getQueryData<FormattedOneFreight>(['freight', freight_id])
      queryClient.setQueryData(['freight', freight_id], {
        ...freight,
        status,
        next_status: getNextStatus(status),
      })
      const freightHistory = queryClient.getQueryData<FormattedFreightStatusHistory[]>([
        'freight-status-history',
        freight_id,
      ])
      if (freightHistory) {
        queryClient.setQueryData(
          ['freight-status-history', freight_id],
          [...freightHistory, { created_at: format(new Date(), "dd/MM 'às' HH:mm"), status }],
        )
      }

      queryClient.invalidateQueries('list-freight-in-progress')
      queryClient.invalidateQueries('freights-current')
      queryClient.invalidateQueries('freights')
      queryClient.invalidateQueries('freight-status-history')
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function disableFreight(
  freight_ids: string[],
  { description, disabled_reason, status }: DisableData,
): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      const savePromises = freight_ids.map(async freight_id =>
        apiServer.put(`/change-freight-status/${freight_id}`, {
          status,
          disabled_reason,
          description,
        }),
      )
      await Promise.all(savePromises)
      freight_ids.forEach(freight_id => {
        const freightCache = queryClient.getQueryData<FormattedOneFreight>(['freight', freight_id])
        queryClient.setQueryData(['freight', freight_id], {
          ...freightCache,
          status,
          next_status: getNextStatus(status),
        })
      })
      queryClient.invalidateQueries('freight-status-history')
      queryClient.invalidateQueries('freights-current')
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function linkMotorist({
  freight_id,
  motorist_id,
  vehicle_id,
}: LinkMotoristProps): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.post('/link-motorist-to-freight', { freight_id, motorist_id, vehicle_id })
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('freights')
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function unlinkMotorist(freight_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.post('/unlink-motorist-to-freight', { freight_id })
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('freights')
      queryClient.invalidateQueries('motoristLastCheckin')
      queryClient.invalidateQueries('list_motorist_in_analysis')
      queryClient.invalidateQueries('list-risk-analysis')
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}

export function addAttachment({
  attachment,
  delivery_receipt_at,
  description,
  freight_id,
  name,
  freight_status,
  type,
}: AddAttachmentFormData): () => Promise<unknown> {
  return async () => {
    loading(true)
    let attachId = ''
    try {
      const formData = new FormData()

      formData.append('freight_id', freight_id)
      formData.append('attachment', attachment)
      formData.append('type', 'default')
      if (description) formData.append('description', description)

      if (delivery_receipt_at)
        formData.append('delivery_receipt_at', new Date(delivery_receipt_at).toISOString())

      formData.append('document', type)

      if (name) formData.append('name', name)

      if (freight_status) formData.append('freight_status', freight_status)

      const { data: newAttach } = await apiServer.post<FreightAttachmentType>(
        `/freights/attachments`,
        formData,
      )

      attachId = newAttach.id

      dispatch(freightSlice.actions.setAttachment(newAttach))
    } catch (error) {
      if (attachId) await apiServer.delete(`/freights/attachments/${attachId}`)

      return error
    } finally {
      loading(false)
    }
  }
}

export function deleteAttachmentFreight(attachment_id: string): () => Promise<unknown> {
  return async () => {
    try {
      loading(true)
      await apiServer.delete(`/freights/attachments/${attachment_id}`)
      dispatch(freightSlice.actions.setDeleteAttachment(attachment_id))
    } catch (error) {
      return error
    } finally {
      loading(false)
    }
  }
}
