import { Button, useDisclosure } from '@chakra-ui/react'
import { ChangeStatusFreightExpenseModal } from '../ChangeStatusFinancialTitleModal'

interface ActionButtonFreightExpenseProps {
  status: string
  freight_expense_id: string
  title: string
  color: string
  freight_id: string
  onCloseModal: () => void
}

export default function ActionButtonFreightExpense({
  status,
  freight_expense_id,
  onCloseModal,
  title,
  color,
  freight_id,
}: ActionButtonFreightExpenseProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Button
        colorScheme={color}
        onClick={() => {
          onOpen()
        }}
      >
        {title}
      </Button>

      {freight_expense_id && status && (
        <ChangeStatusFreightExpenseModal
          freight_expense_id={freight_expense_id}
          freight_id={freight_id}
          status={status}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            onCloseModal()
          }}
        />
      )}
    </>
  )
}
