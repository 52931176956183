import { useDisclosure } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { SelectOption } from '../../../../components/form/types/SelectOption'
import { queryClient } from '../../../../config/react-query'
import { FormattedQuotation } from '../../../../services/endpoints/freights'
import QuotationsKanbanButtonForm from '../../kanban-quotations/cards/button-form-cards'
import { ChangeStatusQuotationModal } from '../ChangeStatusQuotationModal'

export interface ButtonProps {
  quotation: FormattedQuotation
  change_status: SelectOption
  colorScheme: string
  rightIcon?: IconType
  leftIcon?: IconType
  label: string
}

export default function QuotationsButtonForm({
  quotation,
  change_status,
  colorScheme,
  rightIcon,
  leftIcon,
  label,
}: ButtonProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <QuotationsKanbanButtonForm
        colorScheme={colorScheme}
        label={label}
        onOpen={onOpen}
        rightIcon={rightIcon}
        leftIcon={leftIcon}
      />

      <ChangeStatusQuotationModal
        quotation={quotation}
        change_status={change_status}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          queryClient.invalidateQueries('all-quotations')
        }}
      />
    </>
  )
}
