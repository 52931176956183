import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

type Input = {
  file: File
  slicePdf?: boolean
}
type Output = {
  imageConverted: string
}

export async function convertPdfToImage(params: Input): Promise<Output> {
  const formData = new FormData()
  formData.append('pdf', params.file)
  formData.append('slicePdf', String(params.slicePdf))
  const { data: response } = await apiServer.post<ResponseApiType<Output>>('/convert-pdf-to-image', formData)
  return response.data
}

export function usePostConvertPdfToImage(
  options?: UseMutationOptions<Output, unknown, Input, unknown>,
): UseMutationResult<Output, unknown, Input, unknown> {
  return useMutation((params: Input) => convertPdfToImage(params), options)
}
