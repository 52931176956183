import {
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Alert,
} from '@chakra-ui/react'
import { BuonnyResponseStatus } from '../../../modals/check-motorist-buonny-modal/CheckBuonny'

export function CheckRiskStatus({ buonnyStatus }: { buonnyStatus: BuonnyResponseStatus }): JSX.Element {
  return (
    <>
      <Alert
        mb={5}
        status={buonnyStatus.status === 'PERFIL ADEQUADO AO RISCO' ? 'success' : 'warning'}
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {buonnyStatus.status}
        </AlertTitle>
        <AlertDescription mt="6">
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th>Número consulta</Th>
                <Td colSpan={3}>{buonnyStatus.consult_number}</Td>
              </Tr>
              <Tr>
                <Th>Motorista</Th>
                <Td colSpan={3}>{buonnyStatus.motorist}</Td>
              </Tr>
              <Tr>
                <Th>CPF</Th>
                <Td colSpan={3}>{buonnyStatus.document}</Td>
              </Tr>
              <Tr>
                <Th>Veículo</Th>
                <Td>{buonnyStatus.vehicle}</Td>
                <Th>Placa</Th>
                <Td>{buonnyStatus.vehicle_license_plate}</Td>
              </Tr>
              <Tr>
                <Th>Carroceria</Th>
                <Td>{buonnyStatus.trailer}</Td>
                <Th>Placa</Th>
                <Td>{buonnyStatus.trailer_license_plate}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Box mt="8">{buonnyStatus.message}</Box>
        </AlertDescription>
      </Alert>
    </>
  )
}
