import {
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'
import React, { useState } from 'react'
import { RiDownloadLine, RiFileExcel2Fill } from 'react-icons/ri'
import { ReportToExcel } from '../../../../../components/reports/ReportToExcel'
import { fetchAllFreights } from '../../../../../services/endpoints/freights/getFreights'
import { calculateTripDuration } from '../../../../../services/utils/calculateTripDuration'

const ReportTollsToExcel = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // filtro do export do excell
  const [initialDate, setInitialDate] = useState<string>(() => {
    return format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd'T'00:00:00")
  })
  const [finalDate, setFinalDate] = useState<string>(() => {
    return format(new Date(), "yyyy-MM-dd'T'23:59:59")
  })

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Icon as={RiFileExcel2Fill} />}
        rightIcon={<Icon as={RiDownloadLine} />}
        colorScheme="green"
      >
        Relatório de pedágio dos fretes
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Relatório de pedágio dos fretes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" alignItems="flex-end">
              <Flex direction={['column', 'row']} alignItems="center" mb="4">
                <Text mx="5">De</Text>
                <Input
                  mt={['1', '']}
                  type="date"
                  name="initial_date"
                  defaultValue={initialDate?.split('T')[0]}
                  onChange={e => {
                    setInitialDate(String(e.target.value))
                  }}
                />

                <Text mr="4" ml="4">
                  Até
                </Text>
                <Input
                  mt={['1', '']}
                  type="date"
                  name="final_date"
                  defaultValue={finalDate?.split('T')[0]}
                  onChange={e => {
                    setFinalDate(String(e.target.value))
                  }}
                />
              </Flex>
              <ReportToExcel
                isLoading={false}
                documentTitle="Relatório de pedágio dos fretes"
                columnsTitle={[
                  'Frete',
                  'Cliente',
                  'Coleta',
                  'P. Entrega',
                  'Origem',
                  'Destino',
                  'Status',
                  'Pedágio',
                  'Pedágio pago',
                ]}
                onGenerateReport={async () => {
                  const report = await fetchAllFreights({
                    initial_date: initialDate,
                    final_date: finalDate,
                    unpaged: true,
                  })
                  return report.data.map(freight => {
                    let deliveryDate
                    let tripDuration
                    if (freight.distance) {
                      const date = new Date()
                      date.setHours(12)
                      tripDuration = calculateTripDuration(freight.distance, 60, date)
                      if (freight.collect_cargo_at) {
                        deliveryDate = new Date(freight.collect_cargo_at)
                        tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)
                        deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
                      }
                    }

                    return [
                      freight?.freight_number,
                      freight?.client?.name,
                      freight?.collect_cargo_at_pt_br,
                      freight?.delivered_cargo_at
                        ? format(new Date(freight.delivered_cargo_at), 'dd/MM/yy')
                        : tripDuration?.relativeTripDuration,
                      freight.origin?.name,
                      freight.destination?.name,
                      freight.formatted_status,
                      freight.toll_ticket,
                    ] as string[]
                  })
                }}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReportTollsToExcel
