import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiUser2Fill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { InputMask } from '../../../components/form/InputMask'
import { SearchBox } from '../../../components/SearchBox'
import { toastError } from '../../../config/error/toastError'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { apiServer } from '../../../services/api'
import { useGetMotorist } from '../../../services/endpoints/motorists/getMotorist'
import { useGetAllOwners } from '../../../services/endpoints/owners/getAllOwners'
import { useGetOwner } from '../../../services/endpoints/owners/getOwner'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'
import { useGetVehicle } from '../../../services/endpoints/vehicles/getVehicle'
import { usePutTrailer } from '../../../services/endpoints/trailers/putTrailer'
import { usePutVehicle } from '../../../services/endpoints/vehicles/putVehicle'
import { OwnerFormSchema } from '../OwnerForm'

interface FindOwnerToLinkToVehicleOrTrailerProps {
  vehicleOrTrailerId: string
  motorist_id?: string
  onClose?: () => void
  refetchVehicles?: () => void
}

export function FindOwnerToLinkToVehicleOrTrailer({
  vehicleOrTrailerId,
  motorist_id,
  onClose,
  refetchVehicles,
}: FindOwnerToLinkToVehicleOrTrailerProps): JSX.Element {
  const integration = useQueryParams('integration')
  const updateVehicle = usePutVehicle()
  const updateTrailer = usePutTrailer()
  const { data: motorist } = useGetMotorist(String(motorist_id))
  const history = useHistory()
  const toast = useToast()
  const { setValue, formState } = useForm({
    resolver: yupResolver(OwnerFormSchema),
  })
  const { errors } = formState
  const [phone, setPhone] = useState<string>()
  const [name, setName] = useState<string>()
  const { data: owners } = useGetAllOwners({
    phone: phone?.length === 16 ? phone : undefined,
    name: name || undefined,
    per_page: 3,
  })

  const { data: vehicle } = useGetVehicle(vehicleOrTrailerId)
  const { data: trailer } = useGetTrailer(vehicleOrTrailerId)
  const { data: motoristIsOwner } = useGetOwner(String(motorist?.id))

  const handleLinkOwnerToVehicle = async (ownerId: string) => {
    try {
      if (vehicle) {
        await updateVehicle.mutateAsync({ id: vehicle.id, owner_id: ownerId })
        if (!vehicle.vehicle_category.has_trailer) {
          if (motorist_id && refetchVehicles) {
            if (!integration) history.push(`/motorists/show/${motorist_id}`)
            refetchVehicles()
          }
        }
      }

      if (trailer) {
        await updateTrailer.mutateAsync({ id: trailer.id, owner_id: ownerId })
        if (motorist_id && refetchVehicles) {
          if (!integration) history.push(`/motorists/show/${motorist_id}`)
          refetchVehicles()
        }
      }
      if (onClose && refetchVehicles) {
        refetchVehicles()
        onClose()
        toast({
          title: 'Proprietário vinculado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        })
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  async function handleAttachMotoristAsOwner(id_motorist: string) {
    if (motoristIsOwner) {
      try {
        if (vehicle) {
          await apiServer.put(`/update-vehicle/${vehicle.id}`, {
            owner_id: motoristIsOwner.id,
          })
          if (!vehicle.vehicle_category.has_trailer) {
            if (motorist_id && refetchVehicles) {
              if (!integration) history.push(`/motorists/show/${motorist_id}`)
              refetchVehicles()
            }
          }
        }

        if (trailer) {
          await apiServer.put(`/update-trailer/${trailer.id}`, {
            owner_id: motoristIsOwner.id,
          })
          if (motorist_id && refetchVehicles) {
            if (!integration) history.push(`/motorists/show/${motorist_id}`)
            refetchVehicles()
          }
        }
        if (vehicle && !vehicle.vehicle_category.has_trailer) {
          if (motorist_id && refetchVehicles) {
            if (!integration) history.push(`/motorists/show/${motorist_id}`)
            refetchVehicles()
          }
        }

        if (onClose && refetchVehicles) {
          refetchVehicles()
          onClose()
        }
        toast({
          title: 'O motorista já era um proprietário e foi vinculado com sucesso',
          isClosable: true,
          position: 'top-right',
          status: 'success',
        })
      } catch (err) {
        const error = err as AxiosError
        toast({
          status: 'error',
          title:
            error.response?.data.message ||
            'Não foi possível cadastrar motorista como proprietário. Tente novamente mais tarde',
          position: 'top-right',
          isClosable: true,
        })
      }
    } else {
      try {
        if (vehicle) {
          await apiServer.post('/create-owner-by-motorist', {
            motorist_id: id_motorist,
            vehicle_id: vehicle.id,
          })
        }
        toast({
          title: 'Motorista cadastrado como proprietário',
          isClosable: true,
          position: 'top-right',
          status: 'success',
        })

        if (trailer) {
          await apiServer.post('/create-owner-by-motorist', {
            motorist_id: id_motorist,
            trailer_id: trailer.id,
          })
          if (motorist_id && refetchVehicles) {
            if (!integration) history.push(`/motorists/show/${motorist_id}`)
            refetchVehicles()
          }
        }
        if (vehicle && !vehicle.vehicle_category.has_trailer) {
          if (motorist_id && refetchVehicles) {
            if (!integration) history.push(`/motorists/show/${motorist_id}`)
            refetchVehicles()
          }
        }

        if (onClose && refetchVehicles) {
          refetchVehicles()
          onClose()
        }
      } catch (err) {
        const error = err as AxiosError
        toastError({
          toast,
          error:
            error || 'Não foi possível cadastrar motorista como proprietário. Tente novamente mais tarde',
        })
      }
    }
  }

  return (
    <Box p="4" justifyContent="center">
      <Flex
        mb={['2', '4']}
        justifyContent="space-between"
        alignContent="center"
        direction={['column', 'row']}
        gridGap="4"
      >
        <Heading fontSize="xx-large" fontWeight="bold">
          Buscar proprietário
        </Heading>
        {motorist_id && (
          <Button
            colorScheme="blue"
            fontSize="md"
            onClick={() => {
              handleAttachMotoristAsOwner(motorist_id)
            }}
          >
            <Icon as={RiUser2Fill} mr="2" /> O motorista é proprietário?
          </Button>
        )}
      </Flex>

      <Flex direction={['column', 'row']} gridGap="6" alignItems="center" justifyContent="center">
        <SearchBox
          onChange={async v => {
            if (v) {
              setName(v)
            } else if (v.length === 0) {
              setName(undefined)
            }
          }}
          placeholder="Nome do proprietário"
          h="45px"
          clearState={() => {
            setName(undefined)
          }}
        />

        <InputMask
          mask="(99) 9 9999-9999"
          registerOnlyNumbers
          maskPlaceholder=""
          name="phone"
          value={phone}
          onChange={e => {
            setPhone(e.target.value)
          }}
          error={errors.phone}
          setValue={setValue}
          placeholder="Telefone"
        />
      </Flex>

      <Table d={['block', 'block', 'table']} overflowX="auto" mt="5">
        <Thead>
          <Tr>
            <Th>proprietário</Th>
            <Th>Telefone</Th>
          </Tr>
        </Thead>
        <Tbody>
          {owners &&
            owners.data.map(owner => {
              return (
                <Tr key={owner.id}>
                  <Td whiteSpace="nowrap">
                    <Box>{owner.name.substring(0, 40)}</Box>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Box>
                      <Text>{owner.phone}</Text>
                    </Box>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Button
                      size="sm"
                      colorScheme="green"
                      leftIcon={<Icon as={RiUser2Fill} fontSize={18} />}
                      isLoading={updateVehicle.isLoading || updateTrailer.isLoading}
                      onClick={() => {
                        handleLinkOwnerToVehicle(owner.id)
                      }}
                    >
                      Vincular
                    </Button>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </Box>
  )
}
