import { queryClient } from '../../../../config/react-query'
import { fetchClientContact } from './getContactFromClient'

// PREFETCH DOS DADOS (Carregar com antecedência e guardar em estado global por 10 minutos)
export async function handlePrefetchContactFromClient(user_id: string): Promise<void> {
  await queryClient.prefetchQuery(
    ['contact_by_client', user_id],
    async () => {
      const res = await fetchClientContact(user_id)
      return res
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  )
}
