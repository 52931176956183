import { AdvanceRequestStatusEnum } from '../types/EnumTypes'

export function advanceRequestStatusLegend(status?: AdvanceRequestStatusEnum): string {
  if (status === AdvanceRequestStatusEnum.AWAITING_ANALYSIS)
    return 'Aguardando responsável realizar a análise'
  if (status === AdvanceRequestStatusEnum.AWAITING_PAYMENT)
    return 'Aguardando o reponsável realizar o pagamento e emitir os comprovantes'
  if (status === AdvanceRequestStatusEnum.PAID) return 'Pagamento e emissão do comprovantes realizado'
  if (status === AdvanceRequestStatusEnum.FINISHED) return 'Solicitação de adiantamento finalizada'
  return 'Solicitação de adiantamento reprovada pelo responsável'
}
