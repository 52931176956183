import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleType } from '../../types'

export interface VehicleResponseFormData extends VehicleType {
  formatted_has_insurance: string
  formatted_has_tracker: string
}

export async function fetchVehicle(vehicle_id: string): Promise<VehicleResponseFormData | undefined> {
  if (vehicle_id && vehicle_id !== 'undefined') {
    const { data: response } = await apiServer.get<ResponseApiType<VehicleResponseFormData>>(
      `/find-vehicle/${vehicle_id}`,
    )
    const { data } = response
    const vehicle = {
      ...data,
      formatted_has_insurance: data.has_insurance ? 'Sim' : 'Não',
      formatted_has_tracker: data.has_tracker ? 'Sim' : 'Não',
      vehicle_category_id: data.vehicle_category?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_category?.id,
      },
      vehicle_body_id: data.vehicle_body?.id && {
        label: data.vehicle_body?.name,
        value: data.vehicle_body.id,
      },
    } as VehicleResponseFormData

    return vehicle
  }
  return undefined
}

type Options = {
  onSuccess?: (data: VehicleResponseFormData) => void
}

export function useGetVehicle(
  vehicle_id: string,
  options?: Options,
): UseQueryResult<VehicleResponseFormData> {
  return useQuery(['vehicle', vehicle_id], () => fetchVehicle(vehicle_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    onSuccess: data => {
      if (options?.onSuccess) options.onSuccess(data)
    },
  })
}
