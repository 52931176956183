import { ListVehicle } from '../../../../components/card-infos/ListVehicle'

interface OwnerVehicleProps {
  owner_id: string
  bg: string
}

export function OwnerVehicle({ owner_id, bg }: OwnerVehicleProps): JSX.Element {
  return <ListVehicle bg={bg} owner_id={owner_id} />
}
