/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiServer } from '../../../api'

export interface UploadAttachToTimeline {
  interactions_timeline_id: string
  file: File
  comment?: string
}

async function uploadAttachToTimeline({
  interactions_timeline_id,
  file,
  comment,
}: UploadAttachToTimeline): Promise<AxiosResponse> {
  const formData = new FormData()
  if (comment) {
    formData.append('comment', comment)
  }
  if (file && interactions_timeline_id) {
    formData.append('attachment', file)
    return apiServer.put(`update-interaction-timeline/${interactions_timeline_id}`, formData)
  }
  return Promise.reject(new Error('Arquivo não encontrado'))
}

export function useUploadAttachToTimeline(
  options?: UseMutationOptions<AxiosResponse, unknown, UploadAttachToTimeline, unknown>,
) {
  return useMutation(async (data: UploadAttachToTimeline) => uploadAttachToTimeline(data), options)
}
