import { AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

export interface CreateTrackerDeviceRequest {
  brand: string
  type: string
  link: string
  login?: string
  password?: string
  note?: string
  vehicle_id?: string | null
  freight_id?: string | null
}

async function postTrackerDevice(data: CreateTrackerDeviceRequest) {
  return apiServer.post(`/tracker-devices`, data)
}
export function useCreateTrackerDevice(
  options?: UseMutationOptions<AxiosResponse, unknown, CreateTrackerDeviceRequest, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, CreateTrackerDeviceRequest, unknown> {
  return useMutation(async (data: CreateTrackerDeviceRequest) => postTrackerDevice(data), options)
}
