import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../../api'
import { ResponseListApiType } from '../../../../types'
import { FreightExpenseHistoryType } from '../../../../types/FreightExpenseType'
import { colorStatus, formatStatusFreightExpense } from '../getAllFinancialTitles'

interface FreightExpenseHistoryFormatted extends FreightExpenseHistoryType {
  color_status: string
}

export type GetFreightExpenseStatusHistoryResponse = {
  total: number
  data: FreightExpenseHistoryFormatted[]
}

interface FilterFreightExpenseStatusHistoryParams {
  page?: number
  per_page?: number
  freight_id?: string
  freight_expense_id?: string
  number_freight?: number
  user_id?: string
  cte_id?: string
  name_recipient?: string
  document_recipient?: string
  paid?: boolean
  status?: string
  event?: string
  initial_date?: Date
  final_date?: Date
}

export async function fetchAllFreightExpenseStatusHistory(
  params: FilterFreightExpenseStatusHistoryParams,
): Promise<GetFreightExpenseStatusHistoryResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<FreightExpenseHistoryFormatted[]>>(
    '/list-freight-expenses-history',
    {
      params,
    },
  )
  const { data } = res
  const { total } = data
  const freightExpenseStatusHistory = data.data.map(freightExpense => {
    return {
      ...freightExpense,
      created_at: format(new Date(freightExpense.created_at), "dd/MM/yy 'às' HH:mm:ss"),
      color_status: colorStatus(freightExpense.status),
      status: formatStatusFreightExpense(freightExpense.status),
    }
  })

  return { total, data: freightExpenseStatusHistory }
}

export function useGetAllFreightExpenseStatusHistory(
  params: FilterFreightExpenseStatusHistoryParams,
): UseQueryResult<GetFreightExpenseStatusHistoryResponse> {
  return useQuery(
    ['list-all-financial-title-history', params],
    () => fetchAllFreightExpenseStatusHistory(params),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
    },
  )
}
