export const conveyers = [
  {
    label: 'SpotX Matriz',
    value: '37.991.170/0001-95',
  },
  {
    label: 'SpotX - SP',
    value: '37.991.170/0002-76',
  },
  {
    label: 'SpotX - MA',
    value: '37.991.170/0003-57',
  },
  {
    label: 'Distrito Federal',
    value: '37.991.170/0004-38',
  },
  {
    label: 'Santa Catarina',
    value: '37.991.170/0005-19',
  },
  {
    label: 'Minas Gerais',
    value: '37.991.170/0006-08',
  },
  {
    label: 'Mato Grosso',
    value: '37.991.170/0007-80',
  },
  {
    label: 'Mato Grosso Do Sul',
    value: '37.991.170/0008-61',
  },
  {
    label: 'Bahia',
    value: '37.991.170/0009-42',
  },
  {
    label: 'Rio Grande Do Sul',
    value: '37.991.170/0010-86',
  },
  {
    label: 'Parana',
    value: '37.991.170/0011-67',
  },
]
