import { differenceInDays } from 'date-fns'
import axios from 'axios'

const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
const api = axios.create({
  baseURL: `https://tile.googleapis.com/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export type GoogleSessionToken = {
  roadmap: string
  satellite: string
  expiry: string
}

type GoogleSessionTokensResponse = {
  session: string
  expiry: string
}

function getGoogleSessionTokensFromStorage(): GoogleSessionToken | null {
  const googleSessionTokens = localStorage.getItem('@spotx:google_session_tokens')
  if (googleSessionTokens) {
    return JSON.parse(googleSessionTokens)
  }
  return null
}

function setGoogleSessionTokensToStorage(googleSessionTokens: GoogleSessionToken): void {
  localStorage.setItem('@spotx:google_session_tokens', JSON.stringify(googleSessionTokens))
}

async function fetchGoogleSessionTokens(): Promise<GoogleSessionToken> {
  const responseRoadmap = await api.post<GoogleSessionTokensResponse>(`/createSession?key=${googleApiKey}`, {
    mapType: 'roadmap',
    language: 'pt-BR',
    region: 'BR',
  })
  const responseSatellite = await api.post<GoogleSessionTokensResponse>(
    `/createSession?key=${googleApiKey}`,
    {
      mapType: 'satellite',
      language: 'pt-BR',
      region: 'BR',
    },
  )

  const result = {
    roadmap: responseRoadmap.data.session,
    satellite: responseSatellite.data.session,
    expiry: responseRoadmap.data.expiry,
  }
  setGoogleSessionTokensToStorage(result)
  return result
}

export async function getGoogleSessionTokens(): Promise<GoogleSessionToken> {
  const googleSessionTokens = getGoogleSessionTokensFromStorage()
  if (!googleSessionTokens) {
    const newGoogleSessionTokens = await fetchGoogleSessionTokens()
    return newGoogleSessionTokens
  }

  const expiry = new Date(+googleSessionTokens.expiry * 1000)
  if (differenceInDays(expiry, new Date()) < 5) {
    const newGoogleSessionTokens = await fetchGoogleSessionTokens()
    return newGoogleSessionTokens
  }

  return googleSessionTokens
}
