import { useColorModeValue } from '@chakra-ui/react'

interface useTableColorsProps {
  backgroundColor: string
  headerBackgroundColor: string
  borderColor: string
  rowBackgroundColor: string
  alternateRowBackgroundColor: string
  rowHoverBackgroundColor: string
  selectedRowBackgroundColor: string
  footerBackgroundColor: string
}

const useTableColors = (): useTableColorsProps => {
  const backgroundColor = useColorModeValue('gray.100', 'gray.800')
  const headerBackgroundColor = useColorModeValue('gray.200', 'gray.600')
  const footerBackgroundColor = useColorModeValue('gray.200', 'gray.600')
  const borderColor = useColorModeValue('gray.300', 'gray.500')
  const rowBackgroundColor = useColorModeValue('gray.100', 'gray.700')
  const alternateRowBackgroundColor = useColorModeValue('gray.50', 'gray.600')
  const rowHoverBackgroundColor = useColorModeValue('gray.50', 'gray.600')
  const selectedRowBackgroundColor = useColorModeValue('blue.200', 'blue.600')

  return {
    backgroundColor,
    headerBackgroundColor,
    borderColor,
    rowBackgroundColor,
    alternateRowBackgroundColor,
    rowHoverBackgroundColor,
    selectedRowBackgroundColor,
    footerBackgroundColor,
  }
}

export default useTableColors
