import { Box, Image, useColorModeValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import logoDarkLineImg from '../../assets/logo-dark-line.png'
import logoLightLineImg from '../../assets/logo-light-line.png'

export function Logo(): JSX.Element {
  const logoSrc = useColorModeValue(logoLightLineImg, logoDarkLineImg)

  return (
    <Box w="auto">
      <Link to="/dashboard">
        <Image w={['20', '40']} p="0" src={logoSrc} alt="SpotX Logo" mt={['2', '']} />
      </Link>
    </Box>
  )
}
