/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { api } from '../../api'

// NÃO MEXER, SOMENTE PAULO VITOR PODE
async function updateCteEventsFetch() {
  return api.post('/datamex/cte/create-event-by-day')
}

export function useUpdateCteEvents(options?: UseMutationOptions<AxiosResponse>) {
  return useMutation(async () => updateCteEventsFetch(), options)
}
