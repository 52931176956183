import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import { TrailerType } from '../../types/TrailerType'

export interface GetResponseAllTrailers {
  total: number
  data: TrailerType[]
}
export interface TrailersFiltersParams {
  page?: number
  per_page?: number
  release_year?: number
  license_plate?: string
  vehicle_categories?: string[]
  vehicle_bodies?: string[]
}

export async function fetchAllTrailer({
  page,
  per_page,
  release_year,
  license_plate,
  vehicle_bodies,
  vehicle_categories,
}: TrailersFiltersParams): Promise<GetResponseAllTrailers> {
  const { data: response } = await apiServer.get<ResponseListApiType<TrailerType[]>>(`/list-trailer`, {
    params: {
      page,
      per_page,
      release_year,
      license_plate,
      vehicle_bodies,
      vehicle_categories,
    },
  })

  const { data } = response

  const { total } = data

  const trailers = data.data

  return { total, data: trailers }
}

export function useGetAllTrailer(params: TrailersFiltersParams): UseQueryResult<GetResponseAllTrailers> {
  return useQuery(['trailers', params], () => fetchAllTrailer(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
