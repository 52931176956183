/* eslint-disable consistent-return */
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import OwnerType from '../../types/OwnerType'

export async function findOwnerByCnpj(cnpj: string): Promise<OwnerType | undefined> {
  if (cnpj && cnpj !== 'undefined') {
    try {
      const { data: res } = await apiServer.get<ResponseApiType<OwnerType>>(`/find-owner`, {
        params: {
          cnpj,
        },
      })
      const owner = res.data as OwnerType
      return owner
    } catch (error) {
      return undefined
    }
  }
}
