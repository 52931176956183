import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ClientBusinessGroup, ResponseApiType } from '../../types'

type FilterClientBusinessGroupParams = {
  name?: string
  page?: number
  per_page?: number
  unpaged?: boolean
}

type ClientBusinessGroupResponse = {
  total: number
  data: ClientBusinessGroup[]
}

async function fetchClientBusinessGroup(
  params: FilterClientBusinessGroupParams,
): Promise<ClientBusinessGroupResponse> {
  const { data } = await apiServer.get<ResponseApiType<ClientBusinessGroupResponse>>(
    '/clients/client-business-group',
    {
      params,
    },
  )

  const { total } = data.data

  return { total, data: data.data.data }
}

export function useGetClientBusinessGroup(
  params: FilterClientBusinessGroupParams,
): UseQueryResult<ClientBusinessGroupResponse> {
  return useQuery(['clientBusinessGroup', params], () => fetchClientBusinessGroup(params))
}
