/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useEffect } from 'react'
import { Flex, Box, useColorMode, Spinner } from '@chakra-ui/react'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { getPlankCheckIns } from '../../../../store/slices/plankCheckInsSlice'

import { Logo } from '../../../../layout/Header/Logo'
import MapCheckIns from './MapCheckIns'

export function ListAllPlankCheckins(): JSX.Element {
  const dispatch = useAppDispatch()

  const { colorMode, toggleColorMode } = useColorMode()
  const { isLoading } = useAppSelector(state => state.plankCheckInsSlice)
  useEffect(() => {
    dispatch(getPlankCheckIns())
    console.clear()
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getPlankCheckIns())
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Flex
      p={0}
      m={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        px="4"
        alignItems="center"
        justifyContent="space-between"
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#1a1c28',
          width: '100%',
          height: '10vh',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Logo />
        <Box>
          {colorMode === 'light' ? (
            <RiMoonLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          ) : (
            <RiSunLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '90vh',
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner
              size="md"
              color="orange"
              __css={{
                marginBottom: '20px',
              }}
            />
            <p>Carregando o mapa...</p>
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '45px',
                position: 'absolute',
                zIndex: 999,
                bottom: 0,
                left: 0,
                marginLeft: 0,
                marginBottom: 35,
              }}
            >
              <div
                style={{
                  backgroundColor: colorMode === 'light' ? 'white' : '#1a1c28',
                  width: '200px',
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#00ff1a',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                    }}
                  />
                  <b
                    style={{
                      fontSize: '12px',
                      color: colorMode === 'dark' ? 'white' : '#333',
                      marginLeft: 5,
                    }}
                  >
                    Enviou a menos de 3 horas.
                  </b>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#ff8c00',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                    }}
                  />
                  <b
                    style={{
                      fontSize: '12px',
                      color: colorMode === 'dark' ? 'white' : '#333',
                      marginLeft: 5,
                    }}
                  >
                    Enviou a mais de 3 horas.
                  </b>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#7700ff',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                    }}
                  />
                  <b
                    style={{
                      fontSize: '12px',
                      color: colorMode === 'dark' ? 'white' : '#333',
                      marginLeft: 5,
                    }}
                  >
                    Enviou a mais de 1 dia.
                  </b>
                </div>
              </div>
            </div>
            <MapCheckIns theme={colorMode} />
          </>
        )}
      </Box>
    </Flex>
  )
}
