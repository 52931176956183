import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdOutlineClear } from 'react-icons/md'
import { RiCalendarLine, RiCheckLine } from 'react-icons/ri'
import * as yup from 'yup'
import { queryClient } from '../../config/react-query'
import { useAuth } from '../../contexts/AuthContext'
import { useGetChecklistItemRiskAnalysis } from '../../services/endpoints/risk-analysis/checklist-risk-analysis/getChecklistItemRiskAnalysis'
import { usePatchChecklistRiskAnalysis } from '../../services/endpoints/risk-analysis/checklist-risk-analysis/patchChecklistRiskAnalysis'
import { Ability } from '../ability'
import { TextArea } from '../form'

interface EditChecklistRiskAnalysisModalProps {
  isOpen: boolean
  onClose: () => void
  checklist_id: string
}

interface IChecklistRiskItemAnalysisResponse {
  id: string
  content_label: string
  type: string
  checklist_risk_analysis_id: string
  comments: string
  content_value: string
  isChecked?: boolean
  image_url?: string
}

interface IEditChecklistRiskAnalysis {
  checklist_id: string
  comments: string | undefined
  status: string
  evaluated_at?: string
  analyst_id: string
  checklist_items?: any[]
}

export function EditChecklistRiskAnalysisModal({
  isOpen,
  onClose,
  checklist_id,
}: EditChecklistRiskAnalysisModalProps): JSX.Element {
  const [observations, setObservations] = useState<{ [key: string]: string }>({})
  const { user: userLogged } = useAuth()
  const [isOpenImageModal, setIsOpenImageModal] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState('')

  const onOpenImageModal = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl)
    setIsOpenImageModal(true)
  }

  const onCloseImageModal = () => {
    setIsOpenImageModal(false)
  }
  const toast = useToast()
  const {
    data: checklists,
    refetch,
    isLoading,
  } = useGetChecklistItemRiskAnalysis({
    checklist_risk_analysis_id: checklist_id,
  })

  const editFreightExpenseFormSchema = yup.object().shape({
    event: yup.string(),
  })
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(editFreightExpenseFormSchema),
  })

  const handleSetObservation = (checklistId: string, value: string) => {
    setObservations(prev => ({ ...prev, [checklistId]: value }))
  }

  const { mutateAsync: mutateAsyncEdit, isLoading: isLoadingUpdate } = usePatchChecklistRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('checklist-risk-analysis')
    },
  })

  const handleEditChecklistRiskAnalysis = async (data: IEditChecklistRiskAnalysis) => {
    try {
      let checklistsUpdated = checklists
      if (checklists) {
        checklistsUpdated = checklists.map((checklist: IChecklistRiskItemAnalysisResponse) => {
          return {
            id: checklist.id,
            comments: observations[checklist.id] || '',
            content_label: checklist.content_label,
            type: checklist.type,
            checklist_risk_analysis_id: checklist.checklist_risk_analysis_id,
            content_value: checklist.content_value,
            image_url: checklist.image_url,
          }
        })
        Object.assign(data, { checklist_items: checklistsUpdated })
      }

      await mutateAsyncEdit({
        checklist_id,
        status: data.status,
        evaluated_at: new Date().toISOString(),
        analyst_id: userLogged.id,
        checklist_items: checklistsUpdated,
      })
      toast({
        title: 'Checklist editado com sucesso',
        status: 'success',
        position: 'top-right',
        duration: 3000,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao editar o checklist',
        status: 'error',
        position: 'top-right',
        duration: 3000,
      })
    }
  }

  useEffect(() => {
    refetch()
  }, [isOpen, refetch])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dados do Checklist</ModalHeader>
          <Divider my={3} />
          <ModalCloseButton />
          <form onSubmit={handleSubmit(handleEditChecklistRiskAnalysis)}>
            <ModalBody>
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                checklists &&
                checklists.length > 0 && (
                  <VStack spacing={6}>
                    {checklists.map((checklist: IChecklistRiskItemAnalysisResponse) => (
                      <React.Fragment key={checklist.id}>
                        <Box
                          w="full"
                          p={4}
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          boxShadow="sm"
                        >
                          {checklist.type === 'checkbox' && (
                            <VStack spacing={3} align="start">
                              <Checkbox isChecked={Boolean(checklist.content_value)}>
                                {checklist.content_label}
                              </Checkbox>
                              <TextArea
                                placeholder="Observações"
                                value={checklist.comments}
                                onChange={e => handleSetObservation(checklist.id, e.target.value)}
                                name="Observações"
                                setValue={setValue}
                              />
                            </VStack>
                          )}
                          {checklist.type === 'image' && (
                            <HStack spacing={4} align="start">
                              <Image
                                src={checklist.image_url}
                                alt="Checklist Image"
                                boxSize="100px"
                                objectFit="cover"
                                onClick={() => onOpenImageModal(String(checklist.image_url))}
                                cursor="pointer"
                              />
                              <VStack align="start" spacing={2} flex="1">
                                <Text fontWeight="bold">{checklist.content_label}</Text>
                                <TextArea
                                  placeholder="Observações"
                                  value={checklist.comments}
                                  onChange={e => handleSetObservation(checklist.id, e.target.value)}
                                  name="Observações"
                                  setValue={setValue}
                                />
                              </VStack>
                            </HStack>
                          )}

                          {checklist.type === 'pdf' && (
                            <HStack spacing={4} align="top" width="full">
                              <Box py={2} borderRadius="md" display="flex" alignItems="center">
                                <Button
                                  colorScheme="blue"
                                  leftIcon={<Icon as={RiCheckLine} />}
                                  onClick={() => window.open(checklist.image_url, '_blank')}
                                >
                                  Visualizar PDF
                                </Button>
                              </Box>
                              <Box flex="1">
                                <TextArea
                                  setValue={setValue}
                                  placeholder="Observações"
                                  value={checklist.comments}
                                  onChange={e => handleSetObservation(checklist.id, e.target.value)}
                                  name="Observações"
                                  height="100%"
                                />
                              </Box>
                            </HStack>
                          )}

                          {checklist.type === 'date' && (
                            <HStack spacing={4} align="top" width="full">
                              <Box p={2} borderRadius="md" display="flex" alignItems="center" mr="2">
                                <Icon as={RiCalendarLine} mr={2} />
                                <Text>Data: {new Date(checklist.content_value).toLocaleDateString()}</Text>
                              </Box>
                              <Box flex="1">
                                <TextArea
                                  setValue={setValue}
                                  placeholder="Observações"
                                  value={checklist.comments}
                                  onChange={e => handleSetObservation(checklist.id, e.target.value)}
                                  name="Observações"
                                  height="100%"
                                  bg="white"
                                  p={2}
                                  borderRadius="md"
                                />
                              </Box>
                            </HStack>
                          )}
                        </Box>
                      </React.Fragment>
                    ))}
                  </VStack>
                )
              )}
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <Ability module="spotHub" action="show-reprove-check-list-risk-analysis">
                  <Button
                    type="submit"
                    colorScheme="red"
                    leftIcon={<Icon as={MdOutlineClear} />}
                    onClick={() => setValue('status', 'reproved')}
                    isLoading={isLoadingUpdate}
                  >
                    Reprovar
                  </Button>
                </Ability>
                <Ability module="spotHub" action="show-aprove-check-list-risk-analysis">
                  <Button
                    type="submit"
                    colorScheme="green"
                    leftIcon={<Icon as={RiCheckLine} />}
                    isLoading={isLoadingUpdate}
                    onClick={() => setValue('status', 'approved')}
                  >
                    Aprovar
                  </Button>
                </Ability>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenImageModal} onClose={onCloseImageModal} size="full">
        <ModalOverlay />
        <Flex align="center" justify="center" height="100vh">
          <ModalContent margin="auto">
            <ModalHeader>Imagem Ampliada</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" alignItems="center" justifyContent="center" height="100%">
              <Image
                src={selectedImageUrl}
                alt="Amplified Image"
                maxW="100%"
                maxH="100vh"
                objectFit="contain"
              />
            </ModalBody>
          </ModalContent>
        </Flex>
      </Modal>
    </>
  )
}
