import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { BuonnyMotoristCheckType, ResponseListApiType } from '../../types'

export interface BuonnyHistoryType extends BuonnyMotoristCheckType {
  formatted_created_at: string
}

type ResponseBuonnyHistory = {
  data: BuonnyHistoryType[]
  total: number
}

export type FiltersBuonnyHistoryType = {
  freight_id?: string
  motorist_id?: string
  origin_id?: number
  destination_id?: number
  document?: string
  vehicle_license_plate?: string
  page?: number
  per_page?: number
}

export const fetchCheckBuonnyHistory = async (
  params: FiltersBuonnyHistoryType,
): Promise<ResponseBuonnyHistory> => {
  const { data: response } = await apiServer.get<ResponseListApiType<BuonnyMotoristCheckType[]>>(
    '/buonny/list-check-risk',
    { params },
  )
  const formattedResponse: BuonnyHistoryType[] = response.data.data.map(item => {
    const createdAt = new Date(item.created_at)
    return {
      ...item,
      formatted_created_at: format(createdAt, "dd/MM/yyyy 'às' HH:mm"),
    }
  })

  return { data: formattedResponse, total: response.data.total }
}

export function useGetCheckBuonnyHistory(
  params: FiltersBuonnyHistoryType,
): UseQueryResult<ResponseBuonnyHistory> {
  return useQuery(['buonny_freight_history', params], () => fetchCheckBuonnyHistory(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
