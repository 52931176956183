import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'

export interface UpdateRiskAnalysis {
  id: string
  freight_id?: string
  motorist_id?: string
  vehicle_id?: string
  consultation_code?: string
  depends_on_check?: boolean
  reason?: string
  forcedStatus?: boolean
}

export const invalidateCache = async (): Promise<void> => {
  queryClient.invalidateQueries('one-risk-analysis')
  queryClient.invalidateQueries('one-risk-analysis-by-options')
  queryClient.invalidateQueries('list-risk-analysis')
  queryClient.invalidateQueries('compatible_motorists')
  queryClient.invalidateQueries('list-risk-analysis-history')
  queryClient.invalidateQueries('list_motorist_in_analysis')
  queryClient.invalidateQueries('find-blocked-entities')
  queryClient.invalidateQueries('list_motorist_in_analysis')
}

export async function putRiskAnalysis({
  id,
  ...data
}: UpdateRiskAnalysis): Promise<AxiosResponse<ResponseApiType<RiskAnalysisType>>> {
  const riskAnalysisUpdated = await apiServer.put(`update-risk-analysis/${id}`, data)
  if (riskAnalysisUpdated) await invalidateCache()
  return riskAnalysisUpdated
}

export function usePutRiskAnalysis(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateRiskAnalysis, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, UpdateRiskAnalysis, unknown> {
  return useMutation(async (data: UpdateRiskAnalysis) => putRiskAnalysis(data), options)
}
