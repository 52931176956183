export default interface CargoType {
  id: string
  name: string
  delivery_at?: string
  description?: string
  width?: number
  height?: number
  depth?: number
  weight?: number
  value?: number
  invoice_image?: string
  require_tracker?: boolean
  has_complement?: boolean
  created_at: string
  updated_at: string
  cargo_category_fretebras_id?: string
  freight_id: string
}

export const cargoCategoryFretebrasId = [
  { label: 'Passageiros', value: '4' },
  { label: 'Animais', value: '1' },
  { label: 'Big Bag', value: '2' },
  { label: 'Caixas', value: '3' },
  { label: 'Diversos', value: '4' },
  { label: 'Fardos', value: '5' },
  { label: 'Fracionada', value: '6' },
  { label: 'Granel', value: '7' },
  { label: 'Metro Cubico', value: '9' },
  { label: 'Milheiro', value: '10' },
  { label: 'Mudança', value: '11' },
  { label: 'Paletes', value: '12' },
  { label: 'Sacos', value: '14' },
  { label: 'Tambor', value: '15' },
  { label: 'Unidades', value: '16' },
  { label: 'Container', value: '19' },
  { label: 'Bobina', value: '20' },
]
