import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import NumberFormat, { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format'

type InputNumberMaskProps = NumberFormatPropsBase &
  ChakraInputProps & {
    name: string
    setValue: UseFormSetValue<FieldValues>
    initialValue?: number
    label?: string
    error?: FieldError
    isCashBRL?: boolean
  }

const InputNumberMaskBase: ForwardRefRenderFunction<HTMLInputElement, InputNumberMaskProps> = (
  {
    name,
    setValue,
    initialValue,
    label,
    error = null,
    onValueChange,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    _hover,
    isCashBRL,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)
  const [props, setProps] = useState(rest)
  const [init, setInit] = useState(initialValue)

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue)
      setInit(initialValue)
    }
  }, [setValue, name, initialValue])

  useEffect(() => {
    if (isCashBRL) {
      setProps(state => ({
        ...state,
        prefix: 'R$ ',
        thousandSeparator: '.',
        decimalSeparator: ',',
        decimalScale: 2,
        fixedDecimalScale: false,
      }))
    }
  }, [isCashBRL])

  return (
    <FormControl isInvalid={!!error} isDisabled={isDisabled} isRequired={isRequired} ref={inputRef}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}
      <ChakraInput
        as={NumberFormat}
        ref={ref}
        id={name}
        name={name}
        focusBorderColor={focusBorderColor}
        bgColor={bgColor || bg}
        variant={variant}
        size={size}
        _hover={_hover || { bgColor: bg }}
        isDisabled={isDisabled}
        _disabled={{
          opacity: 0.7,
          cursor: 'not-allowed',
        }}
        onValueChange={(values: NumberFormatValues) => {
          setValue(name, values.floatValue)
          setInit(values.floatValue)

          if (onValueChange) onValueChange(values)
        }}
        value={init}
        disableAutocomplete
        disableGoogleAutocomplete
        autoComplete={autoComplete}
        {...props}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const InputNumberMask = forwardRef(InputNumberMaskBase)
