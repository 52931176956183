import { Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { queryClient } from '../../../../config/react-query'
import { Layout } from '../../../../layout'
import { useGetOneFreight } from '../../../../services/endpoints/freights'
import { useGetMotorist } from '../../../../services/endpoints/motorists/getMotorist'
import { useInsertProfessionalToBuonny } from '../../../../services/endpoints/risk-management/insert-professional'
import { FreightType, MotoristType } from '../../../../services/types'
import { AdditionalDataTabPanel } from './AdditionalDataTabPanel'
import { CargoTabPanel } from './CargoTabPanel'
import { CategoryTabPanel } from './CategoryTabPanel'
import { ClientTabPanel } from './ClientTabPanel'
import { DriverLicenseTabPanel } from './DriverLicenseTabPanel'
import { IncludeMotoristFormData } from './form-validation/validation'
import { OwnerTabPanel } from './OwnerTabPanel'
import { ProfessionalTabPanel } from './ProfessionalTabPanel'
import { VehicleTabPanel } from './VehicleTabPanel'

interface RequestParams {
  motorist_id: string
  freight_id: string
}

export function IncludeMotoristToBuonny(): JSX.Element {
  const toast = useToast()
  const insertProfissionalToBuonny = useInsertProfessionalToBuonny({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Ficha do profissional enviada para buonny com sucesso!',
        position: 'top-right',
      })
      queryClient.invalidateQueries('one-risk-analysis')
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
    },
  })
  const { motorist_id, freight_id } = useParams<RequestParams>()

  const history = useHistory()

  const [tabIndex, setTabIndex] = useState(0)
  const [motorist, setMotorist] = useState<MotoristType>()
  const [freight, setFreight] = useState<FreightType>()

  const { setValue, handleSubmit, formState } = useForm()

  const { data: fetchedMotorist } = useGetMotorist(motorist_id)
  const { data: fetchedFreight } = useGetOneFreight({ freight_id, relations: ['origin', 'destination'] })

  useEffect(() => {
    if (fetchedMotorist) {
      setMotorist(fetchedMotorist)
    }
    if (fetchedFreight) {
      setFreight(fetchedFreight)
    }
  }, [fetchedMotorist, fetchedFreight])

  const handleIncludeMotorist = useCallback<SubmitHandler<IncludeMotoristFormData>>(
    async data => {
      const origin = {
        name: data.cargo_origin.label,
        state: {
          uf: String(data.cargo_origin.value),
        },
      }

      const destination = {
        name: data.cargo_destination.label,
        state: {
          uf: String(data.cargo_destination.value),
        },
      }

      const return_contact = {
        name: data.buonny_contact_name,
        contact_type: data.buonny_contact_type,
        return_type: 1,
        description: data.buonny_contact_description,
      }

      const professional_birth_city = {
        name: data.professional_birth_city?.label.split(' - ')[0],
        state: {
          uf: data.professional_birth_city?.label.split(' - ')[1],
        },
      }

      const professional_cnh = {
        cnh: data.professional_cnh,
        category: data.professional_cnh_category,
        validity: data.professional_cnh_validity,
        state: data.professional_cnh_uf,
        first_cnh_date: data.professional_cnh_first_date,
        security_code: data.professional_cnh_security_code,
        mopp_initial_date: data.professional_mopp_date,
      }

      const professional_address = {
        state: data.professional_address_uf,
        city: data.professional_address_city,
        zipcode: data.professional_address_cep,
        district: data.professional_address_district,
        address: data.professional_address,
        number: data.professional_address_number,
        complement: data.professional_address_complement,
      }

      const professional_contacts = [
        {
          name: 'Telefone principal',
          contact_type: data.professional_contact_type,
          return_type: data.professional_contact_return_type,
          description: data.professional_contact_value,
        },
      ]

      const professional = {
        image_professional: data.professional_motorist_img,
        category: data.category,
        document: data.professional_cpf,
        name: data.professional_name,
        father_name: data.professional_father_name,
        mother_name: data.professional_mother_name,
        rg: data.professional_rg,
        rg_state: data.professional_uf_rg,
        rg_issue_date: data.professional_rg_dispatch_date,
        birthday: data.professional_birthday,
        birth_city: professional_birth_city,
        cnh: professional_cnh,
        address: professional_address,
        contacts: professional_contacts,
      }

      const vehicle_city_licensed = {
        name: data.vehicle_city?.label.split(' - ')[0],
        state: {
          uf: data.vehicle_city?.label.split(' - ')[1],
        },
      }

      const vehicle_owner_address = {
        state: data.owner_address_uf,
        city: data.owner_address_city,
        zipcode: data.owner_address_cep,
        district: data.owner_address_district,
        address: data.owner_address,
        number: data.owner_address_number,
        complement: data.owner_address_complement,
      }

      const vehicle_owner_contacts = [
        {
          name: 'Contato do proprietario',
          contact_type: data.owner_contact_type,
          return_type: data.owner_contact_return_type,
          description: data.owner_contact_value,
        },
      ]

      const vehicle_owner = {
        document: data.owner_document,
        company_name: data.owner_name,
        rg_ie: data.owner_rg_ie,
        rntrc: data.owner_rntrc,
        address: vehicle_owner_address,
        contacts: vehicle_owner_contacts,
      }

      if (data.motorist_is_owner === 'S') {
        vehicle_owner.document = professional.document
        vehicle_owner.company_name = professional.name
        vehicle_owner.rg_ie = professional.rg
        vehicle_owner.rntrc = data.professional_rntrc
        vehicle_owner.address = professional_address
        vehicle_owner.contacts = professional_contacts
      }

      const vehicle = {
        license_plate: data.vehicle_license_plate,
        chassi: data.vehicle_chassi,
        renavam: data.vehicle_renavam,
        city_licensed: vehicle_city_licensed,
        vehicle_color: data.vehicle_color,
        release_year: data.vehicle_release_year,
        model: data.vehicle_model?.value,
        model_year: data.vehicle_model_year,
        fabricator: data.vehicle_brand?.value,
        technology: data.vehicle_technology?.value,
        owner: vehicle_owner,
      }

      const vehicles = [vehicle]

      if (data.has_cart === 'S') {
        const vehicle_cart_city_licensed = {
          name: data.vehicle_cart_city?.label.split(' - ')[0],
          state: {
            uf: data.vehicle_cart_city?.label.split(' - ')[1],
          },
        }

        const cart_owner_address = {
          state: data.owner_cart_address_uf,
          city: data.owner_cart_address_city,
          zipcode: data.owner_cart_address_cep,
          district: data.owner_cart_address_district,
          address: data.owner_cart_address,
          number: data.owner_cart_address_number,
          complement: data.owner_cart_address_complement,
        }

        const cart_owner_contacts = [
          {
            name: 'Contato do proprietario',
            contact_type: data.owner_cart_contact_type,
            return_type: data.owner_cart_contact_return_type,
            description: data.owner_cart_contact_value,
          },
        ]

        const cart_owner = {
          document: data.owner_cart_document,
          company_name: data.owner_cart_name,
          rg_ie: data.owner_cart_rg_ie,
          rntrc: data.owner_cart_rntrc,
          address: cart_owner_address,
          contacts: cart_owner_contacts,
        }

        if (data.motorist_is_cart_owner === 'S') {
          cart_owner.document = professional.document
          cart_owner.company_name = professional.name
          cart_owner.rg_ie = professional.rg
          cart_owner.rntrc = data.professional_rntrc
          cart_owner.address = professional_address
          cart_owner.contacts = professional_contacts
        }

        if (data.cart_owner_is_vehicle_owner === 'S') {
          cart_owner.document = vehicle_owner.document
          cart_owner.company_name = vehicle_owner.company_name
          cart_owner.rg_ie = vehicle_owner.rg_ie
          cart_owner.rntrc = vehicle_owner.rntrc
          cart_owner.address = vehicle_owner_address
          cart_owner.contacts = vehicle_owner_contacts
        }

        const cart = {
          license_plate: data.vehicle_cart_license_plate,
          chassi: data.vehicle_cart_chassi,
          renavam: data.vehicle_cart_renavam,
          city_licensed: vehicle_cart_city_licensed,
          vehicle_color: data.vehicle_cart_color,
          release_year: data.vehicle_cart_release_year,
          model: data.vehicle_cart_model?.value,
          model_year: data.vehicle_cart_model_year,
          fabricator: data.vehicle_cart_brand?.value,
          technology: data.vehicle_cart_technology?.value,
          owner: cart_owner,
        }

        vehicles.push(cart)
      }

      const additional_data = {
        was_stolen_in_last_24_months: data.was_stolen_in_last_24_months === 'S',
        stolen_times: data.stolen_times,
        had_accident: data.had_accident === 'S',
        accident_times: data.accident_times,
        how_long_carry_to_company: data.how_long_carry_to_company,
        carry_times: data.carry_times,
        has_tracking_system: data.has_tracking_system === 'S',
        tracking_system: data.tracking_system,
        respond_for_crime_in_last_10_years: data.respond_for_crime_in_last_10_years === 'S',
        crime_process_numbers: data.crime_process_numbers,
      }

      const requestData = {
        product: 2,
        cnpj_shipper: '37991170000195',
        cnpj_transporter: '37991170000195',
        // note: '',
        buonny_cargo_type: data.buonny_cargo_type,
        buonny_cargo_price: data.buonny_cargo_price,
        origin,
        destination,
        return_contact,
        professional,
        vehicles,
        additional_data,
      }

      try {
        await insertProfissionalToBuonny.mutateAsync(JSON.parse(JSON.stringify(requestData)))

        toast({
          title: 'Ficha de Profissional incluída com sucesso na Buonny!',
          isClosable: true,
          position: 'top-right',
          status: 'success',
        })

        history.push(`/freights/show/${freight_id}`)
      } catch (err) {
        const error = err as AxiosError
        toast({
          status: 'error',
          title:
            error.response?.data.message ||
            'Não foi possível adicionar o motorista na Buonny. Tente novamente mais tarde',
          position: 'top-right',
          isClosable: true,
        })
      }
    },
    [insertProfissionalToBuonny, toast, history, freight_id],
  )

  return (
    <Layout hasBackground>
      <Heading size="lg" fontWeight="normal" mb="6">
        Buonny - Cadastro de Profissional
      </Heading>

      <Tabs index={tabIndex} variant="enclosed-colored" isFitted colorScheme="orange" onChange={setTabIndex}>
        <TabList overflowX={['auto', 'unset']}>
          <Tab>Cliente</Tab>
          <Tab>Categoria</Tab>
          <Tab>Profissional</Tab>
          <Tab>CNH do profissional</Tab>
          <Tab>Proprietário</Tab>
          <Tab>Veículo</Tab>
          <Tab>Carga</Tab>
          {/* <Tab>Anexos</Tab> */}
          <Tab>Dados complementares</Tab>
        </TabList>

        {motorist && freight && (
          <form onSubmit={handleSubmit(handleIncludeMotorist)} noValidate>
            <TabPanels>
              <TabPanel p="0" pt="6">
                <ClientTabPanel setValue={setValue} tabNav={setTabIndex} formState={formState} />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <CategoryTabPanel setValue={setValue} tabNav={setTabIndex} formState={formState} />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <ProfessionalTabPanel
                  setValue={setValue}
                  tabNav={setTabIndex}
                  motorist={motorist}
                  formState={formState}
                />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <DriverLicenseTabPanel setValue={setValue} tabNav={setTabIndex} formState={formState} />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <OwnerTabPanel
                  setValue={setValue}
                  tabNav={setTabIndex}
                  motorist={motorist}
                  formState={formState}
                />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <VehicleTabPanel
                  setValue={setValue}
                  tabNav={setTabIndex}
                  motorist={motorist}
                  formState={formState}
                />
              </TabPanel>
              <TabPanel p="0" pt="6">
                <CargoTabPanel
                  setValue={setValue}
                  tabNav={setTabIndex}
                  freight={freight}
                  formState={formState}
                />
              </TabPanel>
              {/* <TabPanel p="0" pt="6">
              <AttachmentsTabPanel />
            </TabPanel> */}
              <TabPanel p="0" pt="6">
                <AdditionalDataTabPanel setValue={setValue} tabNav={setTabIndex} formState={formState} />
              </TabPanel>
            </TabPanels>
          </form>
        )}
      </Tabs>
    </Layout>
  )
}
