import { Flex, Grid, GridItem, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { forEach } from 'lodash'
import { useEffect, useState } from 'react'
import { FaRegFilePdf } from 'react-icons/fa'
import { IoImageOutline } from 'react-icons/io5'
import { TbArrowUpRight } from 'react-icons/tb'

type PreviewFilesType = {
  images: any
  displayMode: 'list' | 'grid'
}

type PreviewFileProps = {
  url: string
  name: string
  size?: string
  type: string | null
}

export function PreviewFiles({ images, displayMode }: PreviewFilesType): JSX.Element {
  const [previewFiles, setPreviewFiles] = useState<PreviewFileProps[]>([])
  const fileBorderBorderColor = useColorModeValue('#A0AEC0', 'gray.900')
  const fileIconBorderColor = useColorModeValue('gray.300', 'gray.900')

  useEffect(() => {
    const previews: PreviewFileProps[] = []

    if (Array.isArray(images)) {
      forEach(images, image => {
        const fileName = image.split('/').pop() || ''
        const fileType = fileName.split('.').pop() || ''

        previews.push({
          url: image,
          name: fileName,
          type: fileType,
        })
      })
    } else {
      const fileName = images.split('/').pop() || ''
      const fileType = fileName.split('.').pop() || ''

      previews.push({
        url: images,
        name: fileName,
        type: fileType,
      })
    }

    setPreviewFiles(previews)
  }, [images])

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="2">
      {previewFiles.map(previewFile => (
        <GridItem colSpan={[12, displayMode === 'list' ? 12 : 6]} key={previewFile.url}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            border={2}
            borderStyle="solid"
            borderColor={fileBorderBorderColor}
            borderRadius={5}
            p={3}
            width="100%"
            key={previewFile.url}
          >
            <Flex alignItems="center">
              <Icon
                p={1}
                fontSize={30}
                as={previewFile.type === 'pdf' ? FaRegFilePdf : IoImageOutline}
                border={1}
                borderColor={fileIconBorderColor}
                borderStyle="solid"
                borderRadius={5}
              />
              <Text ml={3}>{previewFile.name}</Text>
              {previewFile.size && (
                <Text ml={3} color="gray.400">
                  {previewFile.size}
                </Text>
              )}
            </Flex>

            <Flex>
              <Icon
                cursor="pointer"
                fontSize={24}
                as={TbArrowUpRight}
                mr={3}
                onClick={() => {
                  window.open(previewFile.url, '_blank')?.focus()
                }}
              />
            </Flex>
          </Flex>
        </GridItem>
      ))}
    </Grid>
  )
}
