import { Button } from '@chakra-ui/react'
import { ImCancelCircle } from 'react-icons/im'
import { useAppDispatch } from '../../../../store'
import { setOpen, setReasonStatusModalProps } from '../../../../store/slices/riskAnalysisSlice'
import { ReasonStatusModal } from '../ReasonStatusModal'

export default function DisapprovedButton(): JSX.Element {
  const dispatch = useAppDispatch()

  return (
    <>
      <Button
        size="sm"
        rightIcon={<ImCancelCircle />}
        onClick={() => {
          dispatch(setOpen())
          dispatch(
            setReasonStatusModalProps({
              title: 'Reprovar esta análise',
              status: 'disapproved',
            }),
          )
        }}
        colorScheme="red"
      >
        Reprovar
      </Button>

      <ReasonStatusModal />
    </>
  )
}
