import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiMapPinLine, RiMoonLine, RiRoadMapLine, RiSunLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { TextArea } from '../../../components/form/TextArea'
import { toastError } from '../../../config/error/toastError'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Logo } from '../../../layout/Header/Logo'
import { usePostClientFeedback } from '../../../services/endpoints/clients/client-feedbacks/postClientFeedbacks'
import { useGetFreightTrackingClient } from '../../../services/endpoints/freights'
import { useGetFreightHistory } from '../../../services/endpoints/freights/getHistoryFreight'
import { useGetMapDirections } from '../../../services/endpoints/freights/getMapDirections'
import { ClientFeedbackShortForm } from '../../clients/ClientFeedbackShortForm'

interface RequestParams {
  freight_id: string
}

interface CreateClientFeedbackData {
  freight_id: string
  because_this_note: string
  spotx_note: number
  punctuality_note: number
  collect_and_delivery_note: number
  seller_note: number
  payment_method_note: number
  informations_note: number
  recommendation_score: number
  why_this_recommendation_score: string
}

const FeedbackFormSchema = yup.object().shape({
  spotx_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number().required('Campo obrigatório'),
    otherwise: yup.number(),
  }),
  because_this_note: yup.string(),
  punctuality_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number(),
    otherwise: yup.number().required('Campo obrigatório'),
  }),
  collect_and_delivery_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number(),
    otherwise: yup.number().required('Campo obrigatório'),
  }),
  seller_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number(),
    otherwise: yup.number().required('Campo obrigatório'),
  }),
  payment_method_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number(),
    otherwise: yup.number().required('Campo obrigatório'),
  }),
  informations_note: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number(),
    otherwise: yup.number().required('Campo obrigatório'),
  }),
  recommendation_score: yup.number(),
  why_this_recommendation_score: yup.string(),
})

export function ClientFeedback(): JSX.Element {
  const shortFeedback = useQueryParams('short-feedback')
  const toast = useToast()
  const card = useColorModeValue('gray.300', 'gray.700')
  const { colorMode, toggleColorMode } = useColorMode()
  const [researchCompleteAnswered, setResearchCompleteAnswered] = useState(false)
  const [researchShortAnswered, setResearchShortAnswered] = useState(false)
  const { freight_id } = useParams<RequestParams>()
  const { data: freight, refetch } = useGetFreightTrackingClient({
    freight_id,
    relations: [
      'client',
      'origin',
      'destination',
      'client_feedback',
      'vehicle',
      'vehicle.vehicle_category',
      'vehicle.vehicle_body',
      'motorist',
    ],
  })
  const getMapDirections = useGetMapDirections(freight?.origin, freight?.destination)
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  })
  const { errors, isSubmitting } = formState
  const { data: statusHistoryResponse } = useGetFreightHistory(String(freight_id))
  const { mutateAsync: postClientFeedback } = usePostClientFeedback()

  useEffect(() => {
    refetch()
  }, [freight_id, statusHistoryResponse, refetch])

  async function handleCreateResearch(data: CreateClientFeedbackData) {
    const researchData = {
      freight_id,
      because_this_note: data.because_this_note,
      spotx_note: data.spotx_note,
      punctuality_note: data.punctuality_note,
      collect_and_delivery_note: data.collect_and_delivery_note,
      seller_note: data.seller_note,
      payment_method_note: data.payment_method_note,
      informations_note: data.informations_note,
      recommendation_score: data.recommendation_score,
      why_this_recommendation_score: data.why_this_recommendation_score,
    }
    try {
      const { data: researchResponse } = await postClientFeedback(researchData)

      if (researchResponse.data.spotx_note) {
        setResearchShortAnswered(true)
        setResearchCompleteAnswered(true)
      }
      toast({
        title: 'Pesquisa concluída com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    if (freight?.client_feedback?.spotx_note) {
      setResearchShortAnswered(true)
    }
  }, [freight?.client_feedback?.spotx_note])

  useEffect(() => {
    if (freight?.client_feedback?.spotx_note) {
      setResearchCompleteAnswered(true)
    }
  }, [freight?.client_feedback?.spotx_note])

  return (
    <Flex p="4" direction="column" overflowY="auto">
      <Flex px="4" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Logo />
        </Flex>
        <Box>
          {colorMode === 'light' ? (
            <RiMoonLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          ) : (
            <RiSunLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          )}
        </Box>
      </Flex>

      {freight && (
        <>
          <Flex
            as="form"
            justifyContent="center"
            alignItems="center"
            gridGap={4}
            mt="4"
            direction={['column']}
            onSubmit={handleSubmit(handleCreateResearch)}
            noValidate
          >
            <Flex
              fontSize={['lg', 'x-large']}
              alignItems="center"
              gridGap={4}
              flexDirection={['column', 'row']}
            >
              <Text fontSize={['lg', 'xx-large']} fontWeight="bold">
                Pesquisa de satisfação com cliente
              </Text>
            </Flex>
            {researchCompleteAnswered && !shortFeedback && (
              <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                <Heading>Muito obrigado por responder a pesquisa.</Heading>
              </Box>
            )}

            {!researchCompleteAnswered && !shortFeedback && (
              <>
                <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize={['md', 'x-large']} fontWeight="bold" mb="4">
                    Informações do frete
                  </Text>
                  <SimpleGrid minChildWidth="150px" spacing="20" gridGap={8}>
                    <Wrap spacing="4" alignItems="center">
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Cliente:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.client.nickname}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Motorista:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.motorist?.name}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Modelo e placa do veículo:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_category?.name} - {freight.vehicle?.license_plate}
                        </Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Carroceria e placa:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_body?.name} - {freight?.vehicle?.body_license_plate}
                        </Text>
                      </WrapItem>

                      {freight && (
                        <WrapItem alignItems="center">
                          <Tag
                            size="lg"
                            borderRadius="full"
                            backgroundColor="whiteAlpha.900"
                            color="gray.700"
                          >
                            <TagLeftIcon fontSize={['xx-small', 'sm']} as={RiMapPinLine} />
                            <TagLabel mr="3" fontSize={['xx-small', 'sm']}>
                              {freight.origin.name} - {freight.origin.state.uf}
                            </TagLabel>
                            {'>'}
                            <TagLeftIcon as={RiRoadMapLine} ml="2" />
                            <TagLabel fontSize={['xx-small', 'md']}>
                              {freight.destination.name} - {freight.destination.state.uf}
                            </TagLabel>
                            <Text fontSize={['xx-small', 'md']} ml="2">
                              ({getMapDirections.data?.metrics.distance})
                            </Text>
                          </Tag>
                        </WrapItem>
                      )}
                    </Wrap>
                  </SimpleGrid>
                </Box>

                {!shortFeedback && (
                  <>
                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual o seu nível de satisfação ao transportar com a SpotX? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="spotx_note"
                        error={errors.spotx_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>
                    <Box bg={card} maxW="620px" w="100%" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="md" fontWeight="bold" my="2">
                        Por quê você deu essa nota? (Fique à vontade para falar o que quiser)
                      </Text>
                      <TextArea
                        name="because_this_note"
                        size="md"
                        setValue={setValue}
                        error={errors.because_this_note}
                      />
                    </Box>
                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual a nota que você daria para o quesito PONTUALIDADE? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="punctuality_note"
                        error={errors.punctuality_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>

                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual a nota que você daria para o quesito COLETA E ENTREGA? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="collect_and_delivery_note"
                        error={errors.collect_and_delivery_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>

                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual a nota que você daria para o atendimento do VENDEDOR? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="seller_note"
                        error={errors.seller_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>

                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual a nota que você daria para as nossas CONDIÇÕES DE PAGAMENTO (Prazo e forma de
                        recebimento) ? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="payment_method_note"
                        error={errors.payment_method_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>

                    <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                      <Text fontSize="lg" fontWeight="bold">
                        Qual a nota que você daria para as INFORMAÇÕES DURANTE O FRETE? *
                      </Text>
                      <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                      <RadioGroup
                        mt="6"
                        direction={['row']}
                        name="informations_note"
                        error={errors.informations_note}
                        setValue={setValue}
                        options={[
                          { label: '0', value: 0 },
                          { label: '1', value: 1 },
                          { label: '2', value: 2 },
                          { label: '3', value: 3 },
                          { label: '4', value: 4 },
                          { label: '5', value: 5 },
                          { label: '6', value: 6 },
                          { label: '7', value: 7 },
                          { label: '8', value: 8 },
                          { label: '9', value: 9 },
                          { label: '10', value: 10 },
                        ]}
                      />
                    </Box>
                  </>
                )}
                <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                  Enviar pesquisa
                </Button>
              </>
            )}
          </Flex>
          <Flex
            as="form"
            justifyContent="center"
            alignItems="center"
            gridGap={4}
            mt="4"
            direction={['column']}
            onSubmit={handleSubmit(handleCreateResearch)}
            noValidate
          >
            {shortFeedback && researchShortAnswered && (
              <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                <Heading>Muito obrigado por sua resposta.</Heading>
              </Box>
            )}

            {!researchShortAnswered && shortFeedback && (
              <>
                <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize={['md', 'x-large']} fontWeight="bold" mb="4">
                    Informações do frete
                  </Text>
                  <SimpleGrid minChildWidth="150px" spacing="20" gridGap={8}>
                    <Wrap spacing="4" alignItems="center">
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Cliente:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.client.nickname}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Motorista:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.motorist?.name}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Modelo e placa do veículo:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_category?.name} - {freight.vehicle?.license_plate}
                        </Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Carroceria e placa:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_body?.name} - {freight?.vehicle?.body_license_plate}
                        </Text>
                      </WrapItem>

                      {freight && (
                        <WrapItem alignItems="center">
                          <Tag
                            size="lg"
                            borderRadius="full"
                            backgroundColor="whiteAlpha.900"
                            color="gray.700"
                          >
                            <TagLeftIcon fontSize={['xx-small', 'sm']} as={RiMapPinLine} />
                            <TagLabel mr="3" fontSize={['xx-small', 'sm']}>
                              {freight.origin.name} - {freight.origin.state.uf}
                            </TagLabel>
                            {'>'}
                            <TagLeftIcon as={RiRoadMapLine} ml="2" />
                            <TagLabel fontSize={['xx-small', 'md']}>
                              {freight.destination.name} - {freight.destination.state.uf}
                            </TagLabel>
                            <Text fontSize={['xx-small', 'md']} ml="2">
                              ({getMapDirections.data?.metrics.distance})
                            </Text>
                          </Tag>
                        </WrapItem>
                      )}
                    </Wrap>
                  </SimpleGrid>
                </Box>

                <RadioGroup
                  hidden
                  name="short_feedback"
                  setValue={setValue}
                  initialValue={shortFeedback ? 1 : 0}
                  options={[{ label: 'Sim', value: 1 }]}
                />

                <ClientFeedbackShortForm card={card} formState={formState} setValue={setValue} />

                <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                  Enviar resposta
                </Button>
              </>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
