import { Box, Icon, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { Ability } from '../../../../components/ability'
import { AlertDialogConfirm } from '../../../../components/AlertDialogConfirm'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { useDeleteOfTheBlackList } from '../../../../services/endpoints/black-list/deleteOfTheBlackList'
import { getFormatEntityType } from '../../../../services/endpoints/black-list/getAllBlackList'
import BlackListType from '../../../../services/types/BlackListType'

interface DeleteEntityInBlackListProps {
  blackList: BlackListType
}

export default function ButtonRemoveOfTheBlackList({ blackList }: DeleteEntityInBlackListProps): JSX.Element {
  const toast = useToast()
  const [deleteEntityInBlackList, setIdToDeleteEntityInBlackList] = useState<BlackListType>()
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()

  const { mutateAsync: mutateAsyncDelete } = useDeleteOfTheBlackList({
    onSuccess: () => {
      queryClient.invalidateQueries('black-list')
      queryClient.invalidateQueries('motorists')
      queryClient.invalidateQueries('compatible_motorists')
    },
  })

  const handleRemoveOfTheBlackList = async (black_list: BlackListType): Promise<void> => {
    try {
      await mutateAsyncDelete(black_list)
      toast({
        title: 'Removido da lista de restrição com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Ability module="operation" action="delete-black-list">
      <Tooltip hasArrow label="Remover da lista de restrição">
        <Box
          cursor="pointer"
          color="green"
          onClick={() => {
            onOpenConfirm()
            setIdToDeleteEntityInBlackList(blackList)
          }}
        >
          <Icon fontSize="2xl" as={RiDeleteBin6Line} />
        </Box>
      </Tooltip>
      {deleteEntityInBlackList && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title={`Remover ${getFormatEntityType(deleteEntityInBlackList.entity_type)}`}
          description={`Tem certeza que deseja remover este ${getFormatEntityType(
            deleteEntityInBlackList.entity_type,
          ).toLocaleLowerCase()} da lista de restrição?`}
          onClose={onCloseConfirm}
          onConfirm={() => handleRemoveOfTheBlackList(deleteEntityInBlackList)}
        />
      )}
    </Ability>
  )
}
