import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowRightDoubleFill, RiCalendarEventLine, RiEqualizerLine, RiMenuLine } from 'react-icons/ri'
import { useAuth } from '../../contexts/AuthContext'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import useQueryParamUpdater from '../../hooks/useQueryParamUpdater'
import { Logo } from '../../layout/Header/Logo'
import { Profile } from '../../layout/Header/Profile'
import { FiltersFreightsParams } from '../../services/endpoints/freights/getFreights'
import { searchMotoristByName } from '../../services/endpoints/motorists/searchMotoristByName'
import { getUserSales } from '../../services/endpoints/users/getUserSales'
import { Autocomplete, AutocompleteAsync, AutocompleteOption, Switch } from '../form'
import OperatorAndClientSelector from '../OperatorAndClientSelector'
import { SearchBox } from '../SearchBox'

interface KanbanFiltersProps {
  setFilters: React.Dispatch<React.SetStateAction<FiltersFreightsParams>>
  isLoading?: boolean
  filters?: FiltersFreightsParams
}

const KanbanFiltersFreight = ({ setFilters, isLoading, filters }: KanbanFiltersProps): JSX.Element => {
  const { upsertQueryParams, getParams } = useQueryParamUpdater<{
    user_id?: string
    user_label?: string
    client_id?: string
    client_label?: string
    initial_date?: string
    final_date?: string
    seller_id?: string
    seller_label?: string
  }>()
  const bg = useColorModeValue('white', 'gray.900')
  const { setValue } = useForm({})

  const {
    getSellerIdToListFreights,
    setSellerIdToListFreights,
    storageSeller,
    setUserIdToListFreights,
    storageUser,
    getUserIdToListFreights,
  } = useAuth()

  const { onOpen: openMenu } = useSidebarDrawer()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenPeriod, onOpen: onOpenPeriod, onClose: onClosePeriod } = useDisclosure()
  const btnRef = useRef()

  // options
  const [motoristOption, setMotoristOption] = useState<AutocompleteOption | undefined>()
  const [generalFreights, setGeneralFreights] = useState(false)

  // filters controls
  const [hasFilterApplied, sethasFilterApplied] = useState(0)

  // estado interno para o filtro
  const [filtersValues, setFiltersValues] = useState<FiltersFreightsParams>({
    initial_date: getParams('initial_date') || format(new Date(), 'yyyy-MM-dd'),
    final_date: getParams('final_date') || format(new Date(), 'yyyy-MM-dd'),
  })

  // handlers

  const handleSetFilter = (key: keyof FiltersFreightsParams, value: string | boolean | undefined) => {
    setFiltersValues(prev => ({ ...prev, [key]: value }))
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'initial' | 'final') => {
    const dateString = event.target.value

    setFiltersValues({
      ...filtersValues,
      [type === 'initial' ? 'initial_date' : 'final_date']: dateString,
    })
  }

  const handleSetTodayPeriod = () => {
    const today = format(new Date(), 'yyyy-MM-dd')
    upsertQueryParams({
      initial_date: undefined,
      final_date: undefined,
    })
    setFiltersValues({ initial_date: today, final_date: today })
    setFilters(prev => ({ ...prev, initial_date: today, final_date: today }))
  }

  const handleApplyFilters = () => {
    upsertQueryParams({
      initial_date: generalFreights ? undefined : filtersValues?.initial_date?.toString(),
      final_date: generalFreights ? undefined : filtersValues?.final_date?.toString(),
    })
    setFilters(prev => ({
      ...prev,

      initial_date: generalFreights ? undefined : filtersValues?.initial_date,
      final_date: generalFreights ? undefined : filtersValues?.final_date,
      freight_number: filtersValues.freight_number,
      seller_id: filtersValues.seller_id,
      cte_number: filtersValues.cte_number,
      place_vehicle: filtersValues.place_vehicle,
      motorist_id: filtersValues.motorist_id,
      has_monitoring_app: filtersValues.has_monitoring_app,
      has_monitoring_buonny: filtersValues.has_monitoring_buonny,
      has_monitoring_whatsapp: filtersValues.has_monitoring_whatsapp,
      has_monitoring_tracker_devices: filtersValues.has_monitoring_tracker_devices,
      onlyWithoutCtes: filtersValues.onlyWithoutCtes,
      collect_initial_date: filtersValues.collect_initial_date,
      collect_final_date: filtersValues.collect_initial_date,
    }))
  }

  const updateFiltersCount = useCallback(() => {
    if (!filters) return

    const filtersName = [
      'freight_number',
      'cte_number',
      'client_id',
      'place_vehicle',
      'user_id',
      'seller_id',
      'motorist_id',
      'has_monitoring_app',
      'has_monitoring_buonny',
      'has_monitoring_whatsapp',
      'has_monitoring_tracker_devices',
      'onlyWithoutCtes',
      'collect_initial_date',
    ]

    const filtersCount = filtersName.reduce((acc, curr) => {
      if (filters[curr as keyof FiltersFreightsParams]) {
        // eslint-disable-next-line no-param-reassign
        acc += 1
      }
      return acc
    }, 0)

    sethasFilterApplied(generalFreights ? filtersCount + 1 : filtersCount)
  }, [filters, generalFreights])

  useEffect(() => {
    updateFiltersCount()
  }, [updateFiltersCount])

  return (
    <Flex align="center" justifyContent="space-between" gridGap="4">
      <Flex>
        <IconButton
          aria-label="Open navigation"
          icon={<Icon as={RiMenuLine} />}
          fontSize="24"
          variant="link"
          onClick={openMenu}
        />

        <Logo />
      </Flex>
      <Flex gridGap="2" align="center">
        <OperatorAndClientSelector
          handleSetCurrentUser={userSelected => {
            setFilters(prev => ({ ...prev, user_id: userSelected?.value.toString() || undefined }))
            setUserIdToListFreights(userSelected)
            upsertQueryParams({
              user_id: userSelected?.value.toString() || undefined,
              user_label: userSelected?.label || undefined,
            })
            getUserIdToListFreights()
          }}
          handleSetCurrentClient={clientSelected => {
            upsertQueryParams({
              client_id: clientSelected?.value.toString() || undefined,
              client_label: clientSelected?.label || undefined,
            })

            setFilters(prev => ({ ...prev, client_id: clientSelected?.value.toString() || undefined }))
          }}
          userSelected={{
            value: storageUser?.value || getParams('user_id'),
            label: storageUser?.label || getParams('user_label'),
          }}
          clientSelected={{
            value: getParams('client_id'),
            label: getParams('client_label'),
          }}
          setValue={setValue}
        />
        <Flex
          gridGap="2"
          align="center"
          display={{ base: 'none', md: 'flex' }}
          opacity={generalFreights ? 0.5 : 1}
          pointerEvents={generalFreights ? 'none' : 'auto'}
        >
          <Divider orientation="vertical" gridGap="2" h="38px" borderColor="gray.400" />
          <Box>
            <Flex align="center" gridGap="2">
              <Flex align="center">
                <Input
                  fontSize="sm"
                  size="md"
                  type="date"
                  maxW="140px"
                  name="initial_date"
                  onChange={e => {
                    handleDateChange(e, 'initial')
                  }}
                  value={filtersValues.initial_date?.toString() || ''}
                  bg={bg}
                />
              </Flex>
              <Text fontSize="sm" color="gray.500" fontWeight="bold">
                <RiArrowRightDoubleFill fontSize="20px" />
              </Text>
              <Flex align="center">
                <Input
                  fontSize="sm"
                  size="md"
                  type="date"
                  maxW="140px"
                  name="initial_date"
                  onChange={e => handleDateChange(e, 'final')}
                  value={filtersValues.final_date?.toString() || ''}
                  bg={bg}
                />
              </Flex>
              <Button
                variant="solid"
                colorScheme="orange"
                size="md"
                onClick={handleApplyFilters}
                disabled={!filtersValues.initial_date || !filtersValues.final_date}
                isLoading={isLoading}
              >
                Aplicar
              </Button>
              <Button
                isLoading={isLoading}
                variant="outline"
                colorScheme="orange"
                size="md"
                onClick={handleSetTodayPeriod}
              >
                Hoje
              </Button>
            </Flex>
          </Box>
        </Flex>

        <Box display={{ base: 'block', md: 'none' }}>
          <Button
            variant="outline"
            colorScheme="gray"
            size="md"
            onClick={onOpenPeriod}
            borderColor="gray.400"
          >
            <RiCalendarEventLine />
          </Button>
        </Box>
        <Divider orientation="vertical" gridGap="2" h="38px" borderColor="gray.400" />
        <Button
          variant="outline"
          colorScheme={hasFilterApplied > 0 ? 'orange' : 'gray'}
          size="md"
          onClick={onOpen}
          borderColor={hasFilterApplied > 0 ? 'orange.500' : 'gray.400'}
        >
          <Text fontWeight="bold" display={{ base: 'none', md: 'block' }}>
            Filtros
          </Text>
          <Box display={{ base: 'block', md: 'none' }}>
            <RiEqualizerLine />
          </Box>
          {hasFilterApplied > 0 ? `(${hasFilterApplied})` : ''}
        </Button>

        <Drawer isOpen={isOpenPeriod} placement="right" size="sm" onClose={onClosePeriod}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody pt="8" display="flex" flexDirection="column" gridGap="2">
              <Flex flexDir="column" gridGap="2">
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    w="100%"
                    type="date"
                    name="initial_date"
                    onChange={e => handleDateChange(e, 'initial')}
                    value={filtersValues.initial_date?.toString() || ''}
                    bg={bg}
                  />
                </Flex>
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    w="100%"
                    type="date"
                    name="initial_date"
                    onChange={e => handleDateChange(e, 'final')}
                    value={filtersValues.final_date?.toString() || ''}
                    bg={bg}
                  />
                </Flex>
              </Flex>
            </DrawerBody>
            <DrawerFooter display="flex" justifyContent="space-between" gridGap="2">
              <Button variant="outline" size="md" mr="2" onClick={onClosePeriod}>
                Fechar
              </Button>
              <Flex gridGap="2">
                <Button variant="outline" colorScheme="orange" size="md" onClick={handleSetTodayPeriod}>
                  Hoje
                </Button>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  size="md"
                  onClick={handleApplyFilters}
                  disabled={!filtersValues.initial_date || !filtersValues.final_date}
                >
                  Aplicar período
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Drawer
          isOpen={isOpen}
          placement="right"
          size="md"
          onClose={onClose}
          finalFocusRef={btnRef as any}
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody pt="8" display="flex" flexDirection="column" gridGap="2">
              <Autocomplete
                name="seller_id"
                placeholder="Filtrar por vendedor"
                setValue={setValue}
                loadOptions={getUserSales}
                defaultValue={storageSeller?.label}
                onSelectOption={userSelected => {
                  if (userSelected.value) {
                    setSellerIdToListFreights(userSelected)
                    getSellerIdToListFreights()
                    handleSetFilter('seller_id', String(userSelected.value))
                  } else {
                    setSellerIdToListFreights()
                    getSellerIdToListFreights()
                  }
                }}
                clearState={() => {
                  handleSetFilter('seller_id', undefined)
                  setSellerIdToListFreights(undefined)
                  getSellerIdToListFreights()
                }}
              />
              <AutocompleteAsync
                name="motorist_id"
                setValue={setValue}
                defaultValue={motoristOption?.label || ''}
                placeholder="Filtrar por motorista"
                loadOptions={searchMotoristByName}
                onSelectOption={motorist => {
                  setMotoristOption(motorist)
                  handleSetFilter('motorist_id', String(motorist?.value))
                }}
                clearState={() => {
                  setMotoristOption(undefined)
                  handleSetFilter('motorist_id', undefined)
                }}
              />
              <SearchBox
                onChange={async v => {
                  handleSetFilter('freight_number', v)
                }}
                clearState={() => {
                  handleSetFilter('freight_number', undefined)
                }}
                defaultValue={filtersValues.freight_number}
                placeholder="Filtrar pelo número"
                type="number"
              />
              <SearchBox
                onChange={async v => {
                  handleSetFilter('place_vehicle', v)
                }}
                defaultValue={filtersValues.place_vehicle}
                clearState={() => handleSetFilter('place_vehicle', undefined)}
                placeholder="Filtrar por placa"
              />
              <SearchBox
                onChange={async v => {
                  handleSetFilter('cte_number', v)
                }}
                defaultValue={filtersValues.cte_number}
                clearState={() => handleSetFilter('cte_number', undefined)}
                placeholder="Filtrar por CTE"
              />

              <FormControl size="sm">
                <FormLabel>Data de coleta:</FormLabel>
                <Input
                  fontSize="sm"
                  size="md"
                  w="100%"
                  placeholder="Filtrar por coleta"
                  type="date"
                  name="initial_date"
                  value={filtersValues.collect_initial_date}
                  onChange={e => handleSetFilter('collect_initial_date', e.target.value)}
                  bg={bg}
                />
              </FormControl>
              <Divider />
              <Switch
                onChange={e => {
                  handleSetFilter('has_monitoring_app', e.target.checked ? true : undefined)
                }}
                defaultChecked={filtersValues.has_monitoring_app}
                label="Filtrar monitorados pelo App"
                setValue={setValue}
                name="has_monitoring_app"
              />
              <Switch
                onChange={e => {
                  handleSetFilter('has_monitoring_buonny', e.target.checked ? true : undefined)
                }}
                defaultChecked={filtersValues.has_monitoring_buonny}
                label="Filtrar monitorados pela buonny"
                setValue={setValue}
                name="has_monitoring_buonny"
              />
              <Switch
                onChange={e => {
                  handleSetFilter('has_monitoring_whatsapp', e.target.checked ? true : undefined)
                }}
                defaultChecked={filtersValues.has_monitoring_whatsapp}
                label="Filtrar monitorados por whatsapp"
                setValue={setValue}
                name="has_monitoring_whatsapp"
              />
              <Switch
                onChange={e => {
                  handleSetFilter('has_monitoring_tracker_devices', e.target.checked ? true : undefined)
                }}
                defaultChecked={filtersValues.has_monitoring_tracker_devices}
                label="Filtrar monitorados por dispositivos"
                setValue={setValue}
                name="has_monitoring_tracker_devices"
              />
              <Switch
                onChange={e => {
                  handleSetFilter('onlyWithoutCtes', e.target.checked ? true : undefined)
                }}
                defaultChecked={filtersValues.onlyWithoutCtes}
                label="Somente fretes sem ctes"
                setValue={setValue}
                name="only_without_ctes"
              />
              <Switch
                onChange={e => {
                  setGeneralFreights(e.target.checked)
                }}
                defaultChecked={generalFreights}
                label="Geral"
                setValue={setValue}
                name="all with and without ctes"
              />
            </DrawerBody>

            <DrawerFooter display="flex" justifyContent="space-between">
              <Button variant="outline" mr={3} onClick={onClose} isLoading={isLoading}>
                Fechar
              </Button>
              <Button colorScheme="orange" onClick={handleApplyFilters} isLoading={isLoading}>
                Aplicar Filtros
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Profile />
      </Flex>
    </Flex>
  )
}

export default KanbanFiltersFreight
