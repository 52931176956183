import { Button } from '@chakra-ui/react'
import { FaEdit } from 'react-icons/fa'
import { useAppDispatch } from '../../../../store'
import { setOpen, setReasonStatusModalProps } from '../../../../store/slices/riskAnalysisSlice'
import { ReasonStatusModal } from '../ReasonStatusModal'

export default function ToCorrectButton(): JSX.Element {
  const dispatch = useAppDispatch()
  return (
    <>
      <Button
        size="sm"
        rightIcon={<FaEdit />}
        onClick={() => {
          dispatch(setOpen())
          dispatch(
            setReasonStatusModalProps({
              title: 'Solicitar correção dos dados',
              status: 'to_correct',
            }),
          )
        }}
        colorScheme="orange"
      >
        Solicitar correção
      </Button>

      <ReasonStatusModal />
    </>
  )
}
