/**
 * Converts a File object to a Base64-encoded string.
 * @param file The File object to convert.
 * @returns A promise that resolves with the Base64-encoded string.
 */
export async function parseImageToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const { result } = reader
      if (typeof result === 'string') {
        resolve(result)
      } else {
        reject(new Error('Failed to convert file to Base64 string'))
      }
    }
    reader.onerror = () => reject(reader.error)
    reader.readAsDataURL(file)
  })
}

export async function parseBase64ToImage(base64: string): Promise<File> {
  const response = await fetch(base64)
  const blob = await response.blob()
  return new File([blob], 'image.jpg')
}
