import { Box, Button, Flex, Input, Stack, StackProps, Text } from '@chakra-ui/react'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { PaginationItem } from './PaginationItem'

interface PaginationProps extends StackProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  siblingsCount?: number
  onPageChange: (page: number) => void
  showControlButtons?: boolean
  showInput?: boolean
}

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)].map((_, i) => from + i + 1).filter(page => page > 0)
}

export function Pagination({
  totalCountOfRegisters,
  registersPerPage = 15,
  currentPage = 1,
  siblingsCount = 1,
  onPageChange,
  direction = ['column', 'row'],
  spacing = '6',
  justify = 'space-between',
  align = 'center',
  showControlButtons = false,
  showInput = false,
  ...rest
}: PaginationProps): JSX.Element {
  const fromCount = (currentPage - 1) * registersPerPage + 1
  const toCount =
    fromCount - 1 + registersPerPage > totalCountOfRegisters
      ? totalCountOfRegisters
      : fromCount - 1 + registersPerPage

  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)

  const previousPages =
    currentPage > 1 ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1) : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
      : []

  return (
    <>
      {totalCountOfRegisters > registersPerPage && (
        <Stack direction={direction} spacing={spacing} justify={justify} align={align} {...rest}>
          <Flex>
            <Box>
              <strong>{fromCount}</strong> - <strong>{toCount}</strong> de{' '}
              <strong>{totalCountOfRegisters}</strong>
            </Box>
          </Flex>
          <Stack direction="row" spacing="2">
            {showInput && (
              <Flex alignItems="center" gridGap="2" mr="2">
                <Text fontSize="sm" color="gray.500">
                  Ir para
                </Text>
                <Input
                  size="sm"
                  p="0"
                  w="8"
                  textAlign="center"
                  rounded="md"
                  type="number"
                  defaultValue={currentPage}
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '')
                    if (Number(e.target.value) > lastPage) {
                      e.target.value = String(lastPage)
                    } else if (Number(e.target.value) < 1) {
                      e.target.value = '1'
                    }
                    onPageChange(Number(e.target.value))
                  }}
                />
              </Flex>
            )}
            {showControlButtons && (
              <Button
                size="sm"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                p="0"
              >
                <Text w="4" textAlign="center">
                  <RiArrowLeftSLine />
                </Text>
              </Button>
            )}
            {currentPage > 1 + siblingsCount && (
              <>
                <PaginationItem number={1} onPageChange={onPageChange} />
                {currentPage > 2 + siblingsCount && (
                  <Text w="4" textAlign="center">
                    ...
                  </Text>
                )}
              </>
            )}
            {previousPages.length > 0 &&
              previousPages.map(page => (
                <PaginationItem key={page} number={page} onPageChange={onPageChange} />
              ))}
            <PaginationItem number={currentPage} isCurrent onPageChange={onPageChange} />
            {nextPages.length > 0 &&
              nextPages.map(page => <PaginationItem key={page} number={page} onPageChange={onPageChange} />)}
            {currentPage + siblingsCount < lastPage && (
              <>
                {currentPage + 1 + siblingsCount < lastPage && (
                  <Text w="4" textAlign="center">
                    ...
                  </Text>
                )}
                <PaginationItem number={lastPage} onPageChange={onPageChange} />
              </>
            )}
            {showControlButtons && (
              <Button
                size="sm"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === lastPage}
                p="0"
              >
                <Text w="4" textAlign="center">
                  <RiArrowRightSLine />
                </Text>
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}
