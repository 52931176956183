import { AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { CreateFreightTrackerDevicesRequest } from './postFreightTrackerDevices'

type DeleteFreightTrackerDevicesRequest = CreateFreightTrackerDevicesRequest

async function deleteFreightTrackerDevices(data: DeleteFreightTrackerDevicesRequest) {
  const { freight_id, tracker_id } = data
  return apiServer.delete(`/freight-tracker-devices`, {
    params: { freight_id, tracker_id },
  })
}
export function useDeleteFreightTrackerDevices(
  options?: UseMutationOptions<AxiosResponse, unknown, DeleteFreightTrackerDevicesRequest, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, DeleteFreightTrackerDevicesRequest, unknown> {
  return useMutation(
    async (data: DeleteFreightTrackerDevicesRequest) => deleteFreightTrackerDevices(data),
    options,
  )
}
