import {
  Box,
  Divider,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { ButtonsActions } from '../../../../../components/datamex/list-mdfes/ButtonsActions'
import { useListMdfesByFreightId } from '../../../../../services/endpoints/datamex/listMdfesByFreightId'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { toastError } from '../../../../../config/error/toastError'
import parseCurrency from '../../../../../services/utils/parseCurrency'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'

interface ListMdfeProps {
  freight: FormattedOneFreight
  bg: string
}

export function ListMdfe({ freight, bg }: ListMdfeProps): JSX.Element | null {
  const toast = useToast()
  const {
    data: mdfes,
    isLoading: isLoadingMdfes,
    error: errorMdfes,
    isError: isErrorMdfes,
  } = useListMdfesByFreightId(freight.id)

  useEffect(() => {
    if (isErrorMdfes) {
      toastError({ toast, error: errorMdfes })
    }
  }, [isErrorMdfes, errorMdfes, toast])

  return freight ? (
    <Box bg={bg} p="4" borderRadius="8" mt="4">
      <Flex justify="space-between" align="center">
        <Heading fontSize="md" fontWeight="medium">
          MDFes
        </Heading>
      </Flex>

      <Divider my="4" />

      {isLoadingMdfes ? (
        <Flex>
          <Spinner />
        </Flex>
      ) : mdfes && mdfes.length > 0 ? (
        <>
          <TableContainer maxW={1000} d={displayAdaptable}>
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Th>Número</Th>
                  <Th>Serie</Th>
                  <Th>CIOT</Th>
                  <Th>Origem</Th>
                  <Th>Destino</Th>
                  <Th>Valor Carga</Th>
                  <Th>Peso Carga</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {mdfes.map(mdfe => (
                  <Tr key={mdfe.id}>
                    <Td>{mdfe.number}</Td>
                    <Td>{mdfe.serie}</Td>
                    <Td>{mdfe.ciot}</Td>
                    <Td>{mdfe.origin}</Td>
                    <Td>{mdfe.destination}</Td>
                    <Td>{parseCurrency(mdfe.cargo_value)}</Td>
                    <Td>{`${mdfe.cargo_weight} Kg`}</Td>
                    <Td>
                      <ButtonsActions mdfe={mdfe} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : errorMdfes ? (
        <Text>Erro ao carregar dados dos MDFes</Text>
      ) : (
        <Text textAlign="center">Nenhum documento encontrado</Text>
      )}
    </Box>
  ) : null
}
