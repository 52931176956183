import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorMode,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiUser2Fill } from 'react-icons/ri'
import * as yup from 'yup'
import { toastError } from '../../config/error/toastError'
import { queryClient } from '../../config/react-query'
import { usePostChecklistRiskAnalysis } from '../../services/endpoints/risk-analysis/checklist-risk-analysis/postChecklistRiskAnalysis'
import { validateDate } from '../../services/utils/dates/isValidDate'
import { Input } from '../form'

interface ICreateChecklistRiskAnalysisProps {
  isOpen: boolean
  onClose: () => void
  creator_id: string
  motorist_id: string
  vehicle_id: string
  trailer_id?: string
  risk_analysis_id: string
}

interface ICreateChecklistRiskAnalysis {
  risk_analysis_id: string
  creator_id: string
  motorist_id: string
  vehicle_id: string
  trailer_id?: string
  analyst_id?: string
  status: string
  evaluated_at?: string
  comments?: string
  checklist_items: ICreateChecklistItem[]
  videoPrintDriver?: File
  videoPrintCNH?: File
  videoPrintVio?: File
  originAndDestinationConfirmed?: boolean
  anttPublicConsultationDone?: boolean
  documentCheckedInVio?: boolean
  freightValueConfirmed?: boolean
  checkType?: string
  pdfFile?: File
  dataVehicleInspect?: Date | string
}

interface ICreateChecklistItem {
  content_label: string
  content_value: string | boolean
  type: string
}

export function CreateChecklistRiskAnalysis({
  isOpen,
  onClose,
  motorist_id,
  risk_analysis_id,
  creator_id,
  vehicle_id,
  trailer_id,
}: ICreateChecklistRiskAnalysisProps): JSX.Element {
  const [isVideoCheckSelected, setIsVideoCheckSelected] = useState(false)
  const [isInspectVehicleSelected, setIsInspectVehicleSelected] = useState(false)
  const toast = useToast()
  const { colorMode } = useColorMode()
  const CreateFormSchema = yup.object().shape({
    checkType: yup.string().required('Tipo de Checagem é obrigatório'),
    videoPrintDriver: isVideoCheckSelected
      ? yup.mixed().required('Print do vídeo: Motorista a frente do veículo, mostrando a placa é obrigatório')
      : yup.mixed(),
    videoPrintCNH: isVideoCheckSelected
      ? yup.mixed().required('Print do vídeo: Motorista segurando a CNH é obrigatório')
      : yup.mixed(),
    videoPrintVio: isVideoCheckSelected
      ? yup.mixed().required('Print do app VIO é obrigatório')
      : yup.mixed(),
    originAndDestinationConfirmed: yup.bool(),
    freightValueConfirmed: yup.bool(),
    documentCheckedInVio: yup.bool(),
    anttPublicConsultationDone: yup.bool(),
    pdfFile: isInspectVehicleSelected
      ? yup.mixed().required('Documento do veículo é obrigatório')
      : yup.mixed(),
    dataVehicleInspect: isInspectVehicleSelected
      ? yup
          .date()
          .typeError('Data inválida')
          .test('is-valid-date', 'Data inválida', validateDate)
          .required('Data é obrigatória')
      : yup.mixed(),
  })
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateFormSchema),
  })

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingCreate } = usePostChecklistRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('checklist-risk-analysis')
    },
  })

  const handleCreateChecklistRiskAnalysis: SubmitHandler<ICreateChecklistRiskAnalysis> = async data => {
    try {
      const formData = new FormData()

      formData.append('checkType', String(data.checkType))

      if (data.videoPrintDriver) {
        formData.append('videoPrintDriver', data.videoPrintDriver)
      }
      if (data.videoPrintCNH) {
        formData.append('videoPrintCNH', data.videoPrintCNH)
      }
      if (data.videoPrintVio) {
        formData.append('videoPrintVio', data.videoPrintVio)
      }

      if (data.originAndDestinationConfirmed !== undefined) {
        formData.append('originAndDestinationConfirmed', String(data.originAndDestinationConfirmed))
      }

      if (data.freightValueConfirmed !== undefined) {
        formData.append('freightValueConfirmed', String(data.freightValueConfirmed))
      }

      if (data.documentCheckedInVio !== undefined) {
        formData.append('documentCheckedInVio', String(data.documentCheckedInVio))
      }

      if (data.anttPublicConsultationDone !== undefined) {
        formData.append('anttPublicConsultationDone', String(data.anttPublicConsultationDone))
      }

      if (data.pdfFile) {
        formData.append('pdfFile', data.pdfFile)
      }

      if (data.dataVehicleInspect) {
        formData.append('dataVehicleInspect', new Date(data.dataVehicleInspect).toISOString())
      }
      formData.append('motorist_id', motorist_id)
      formData.append('risk_analysis_id', risk_analysis_id)
      formData.append('creator_id', creator_id)
      formData.append('vehicle_id', vehicle_id)
      if (trailer_id) formData.append('trailer_id', trailer_id)
      formData.append('checklist_items', JSON.stringify(data.checklist_items))

      await mutateAsyncCreate(formData).then(() => {
        toast({
          status: 'success',
          title: 'Checklist criado com sucesso!',
          position: 'top-right',
          isClosable: true,
        })
      })

      onClose()
    } catch (err) {
      toastError({ toast, error: err || 'Não foi possível criar o item. Tente novamente mais tarde.' })
    }
  }

  const checkType = watch('checkType')
  useEffect(() => {
    setIsVideoCheckSelected(checkType === 'video')
    setIsInspectVehicleSelected(checkType === 'Inspeção Veicular')
  }, [checkType])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />

      <form onSubmit={handleSubmit(handleCreateChecklistRiskAnalysis)} noValidate>
        <ModalContent>
          <ModalHeader>Novo Checklist Obrigatório</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box bg={colorMode === 'light' ? 'white' : 'gray.800'} p={5} borderRadius={4}>
              <FormControl isInvalid={errors.checkType} mb={4}>
                <FormLabel htmlFor="checkType">Tipo de Checagem</FormLabel>
                <Select id="checkType" placeholder="Selecione o tipo" {...register('checkType')}>
                  <option value="video">Checagem de Vídeo</option>
                  <option value="Inspeção Veicular">Checagem de Inspeção Veicular</option>
                </Select>
                <FormErrorMessage>{errors.checkType && errors.checkType.message}</FormErrorMessage>
              </FormControl>

              {isVideoCheckSelected && (
                <>
                  <VStack spacing={4} align="stretch">
                    <Input
                      name="videoPrintDriver"
                      label="Print do vídeo: Motorista a frente do veículo, mostrando a placa"
                      type="file"
                      setValue={setValue}
                      error={errors.videoPrintDriver}
                      isRequired
                    />
                    <Input
                      name="videoPrintCNH"
                      label="Print do vídeo: Motorista segurando a CNH"
                      type="file"
                      setValue={setValue}
                      error={errors.videoPrintCNH}
                      isRequired
                    />
                    <Input
                      name="videoPrintVio"
                      label="Print do app VIO"
                      type="file"
                      setValue={setValue}
                      error={errors.videoPrintVio}
                      isRequired
                    />
                  </VStack>

                  <Box mt={6}>
                    <Text fontSize="lg" mb={2} fontWeight="semibold">
                      Confirmações:
                    </Text>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                      <Checkbox {...register('originAndDestinationConfirmed')}>
                        Origem e destino confirmados?
                      </Checkbox>
                      <Checkbox {...register('freightValueConfirmed')}>
                        Valor do frete combinado foi respondido?
                      </Checkbox>
                      <Checkbox {...register('documentCheckedInVio')}>
                        Documento foi conferido no Vio?
                      </Checkbox>
                      <Checkbox {...register('anttPublicConsultationDone')}>
                        Foi realizada a consulta pública da ANTT?
                      </Checkbox>
                    </Grid>
                  </Box>
                </>
              )}

              {isInspectVehicleSelected && (
                <Flex wrap="wrap" gap="4" mt={5}>
                  <Box width="50%">
                    <Input
                      name="pdfFile"
                      label="Documento do veículo"
                      type="file"
                      setValue={setValue}
                      error={errors.pdfFile}
                      isRequired
                    />
                  </Box>
                  <Box width="50%">
                    <Input
                      name="dataVehicleInspect"
                      label="Data"
                      type="date"
                      setValue={setValue}
                      error={errors.data}
                      isRequired
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="orange" leftIcon={<RiUser2Fill />} isLoading={isLoadingCreate}>
              Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
