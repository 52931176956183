import {
  Box,
  Button,
  Flex,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { HiChevronUpDown, HiMiniChevronDown, HiMiniChevronUp } from 'react-icons/hi2'
import { Pagination } from '../../layout/Pagination'

type TableProps = {
  data: any
  columns: any
  onPageChange: (page: number) => void
  totalCountOfRegisters: number
  currentPage?: number
  isLoading?: boolean
}

const Table = ({
  data,
  columns,
  onPageChange,
  totalCountOfRegisters,
  currentPage = 1,
  isLoading,
}: TableProps): JSX.Element => {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable<any>({
    data,
    columns,
    globalFilterFn: 'auto',
    state: {
      sorting,
    },
    onSortingChange: prev => setSorting(prev),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <TableContainer size="sm" overflowX="auto" height="100%">
        <ChakraTable size="sm" overflowX="auto" height="100%">
          {table.getHeaderGroups().map(headerGroup => (
            <Thead key={headerGroup.id}>
              <Tr>
                {headerGroup.headers.map(header => (
                  <Th key={header.id} borderBottomWidth="1px" borderColor="gray.200" whiteSpace="nowrap">
                    <Flex alignItems="center" gridGap="2" my="2">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <Button size="xs" variant="ghost" onClick={() => header.column.toggleSorting()}>
                        {
                          {
                            asc: <HiMiniChevronDown size={14} />,
                            desc: <HiMiniChevronUp size={14} />,
                            none: <HiChevronUpDown size={14} />,
                          }[(header.column.getIsSorted() as string) || 'none']
                        }
                      </Button>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
          ))}
          <Tbody>
            {table.getRowModel().rows.map(row => (
              <Tr
                key={row.id}
                _hover={{
                  bg: 'gray.200',
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id} py="2" borderBottomWidth="1px" borderColor="gray.200" whiteSpace="nowrap">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      </TableContainer>

      <Box
        p={4}
        pointerEvents={data && totalCountOfRegisters > 0 ? 'auto' : 'none'}
        opacity={data && totalCountOfRegisters > 0 ? 1 : 0}
      >
        {isLoading ? <Flex justifyContent="center">Carregando...</Flex> : null}
        <Pagination
          currentPage={currentPage}
          totalCountOfRegisters={totalCountOfRegisters}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  )
}

export default Table
