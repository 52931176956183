import { Tag, TagLabel, WrapItem, Skeleton } from '@chakra-ui/react'
import { useJsApiLoader } from '@react-google-maps/api'
import React, { useState } from 'react'

declare global {
  interface Window {
    google: any
  }
}

interface WayPoint {
  lat: number | string
  lng: number | string
}

const google_maps_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

interface DistanceCalculatorProps {
  originLastWaypoint: WayPoint
  destinationClient: WayPoint
}

function DistanceCalculator({ originLastWaypoint, destinationClient }: DistanceCalculatorProps): JSX.Element {
  const [distanceBetweenPoints, setDistanceBetweenPoints] = useState<string | undefined>('0 Km')

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: google_maps_api_key,
    language: 'pt_BR',
    libraries: ['places'],
  })

  const { google } = window

  React.useEffect(() => {
    const fetchDirections = async () => {
      if (!google || !google.maps) {
        return
      }
      const directionsService = new google.maps.DirectionsService()
      const results = await directionsService.route({
        origin: {
          lat: Number(originLastWaypoint.lat),
          lng: Number(originLastWaypoint.lng),
        },
        destination: {
          lat: Number(destinationClient.lat),
          lng: Number(destinationClient.lng),
        },
        travelMode: google.maps.TravelMode.DRIVING,
      })
      if (!results) {
        return
      }

      setDistanceBetweenPoints(String(results.routes[0]?.legs[0]?.distance?.text))
    }
    fetchDirections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google])

  if (!isLoaded) {
    return (
      <Skeleton>
        <div>-------------------</div>
        <div>-------------------</div>
      </Skeleton>
    )
  }

  return (
    <>
      <WrapItem alignItems="center" mb={2} mt={2} w="100%">
        <Tag
          size="lg"
          borderRadius="full"
          backgroundColor="orange.200"
          color="black"
          width="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <TagLabel mr="3" p={2} fontSize={['xx-small', 'sm']} whiteSpace="normal">
            O motorista está a {distanceBetweenPoints} do destino.
          </TagLabel>
        </Tag>
      </WrapItem>
    </>
  )
}

export default DistanceCalculator
