import { Box, Divider, Heading, Icon, SimpleGrid, Text, WrapItem } from '@chakra-ui/react'
import { RiStarLine } from 'react-icons/ri'
import { FormattedMotorist } from '../../services/endpoints/motorists/getMotorist'
import { MotoristContactsType } from '../../services/types/MotoristType'
import formatPhoneNumber from '../../services/utils/formatPhone'

interface MotoristInfoProps {
  motorist: FormattedMotorist
}

export function MotoristInfos({ motorist }: MotoristInfoProps): JSX.Element {
  return (
    <>
      <Box>
        <Heading size="md" fontWeight="normal" mb="6">
          Dados pessoais
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="4">
          <WrapItem>
            <Text color="gray.400">Nome:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.name}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Telefone:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.phone}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Contato pessoal:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.personal_contact}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Contato comercial:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.business_contact}
            </Text>
          </WrapItem>

          <WrapItem d="flex" alignItems="center">
            <Text color="gray.400">Nota média:</Text>
            <Text fontWeight="bold" color="yellow.500">
              <Icon as={RiStarLine} fontSize={20} mx="1" />
              {motorist.rate_score}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">E-mail:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.email}
            </Text>
          </WrapItem>

          {/* <WrapItem>
            <Text color="gray.400">Nascimento:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist?.birth && formatDateToBR(String(motorist.birth))}
            </Text>
          </WrapItem> */}
        </SimpleGrid>
      </Box>
      <br />
      <Box>
        <Divider my="2" />

        <Heading size="md" fontWeight="normal" mb="6">
          Contatos do motorista
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="4">
          {motorist.motorist_contacts && motorist.motorist_contacts.length > 0 ? (
            motorist.motorist_contacts.map((contact: MotoristContactsType) => {
              return (
                <>
                  <WrapItem>
                    <Text color="gray.400">Nome contato:</Text>
                    <Text fontWeight="medium" ml="1">
                      {contact.name}
                    </Text>
                  </WrapItem>
                  <WrapItem>
                    <Text color="gray.400">Tipo do contato:</Text>
                    <Text fontWeight="medium" ml="1">
                      {contact.type}
                    </Text>
                  </WrapItem>
                  <WrapItem>
                    <Text color="gray.400">Telefone do contato:</Text>
                    <Text fontWeight="medium" ml="1">
                      {formatPhoneNumber(contact.phone)}
                    </Text>
                  </WrapItem>
                </>
              )
            })
          ) : (
            <>
              <WrapItem>
                <Text color="gray.400">Nome contato:</Text>
                <Text fontWeight="medium" ml="1" />
              </WrapItem>
              <WrapItem>
                <Text color="gray.400">Tipo do contato:</Text>
                <Text fontWeight="medium" ml="1" />
              </WrapItem>
              <WrapItem>
                <Text color="gray.400">Telefone do contato:</Text>
                <Text fontWeight="medium" ml="1" />
              </WrapItem>
            </>
          )}
        </SimpleGrid>
      </Box>

      <Box>
        <SimpleGrid minChildWidth="100px" spacing="6" mt="2">
          {/*
          <WrapItem>
            <Text color="gray.400">Gênero:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.formatted_gender}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Estado civil: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.formatted_marital_status}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Escolaridade: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.school_degree}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Onde nasceu: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.birth_city}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Recolhe ISS anual?: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.collects_annual_iss}
            </Text>
          </WrapItem> */}
        </SimpleGrid>
        <Divider my="2" />
        <Heading size="md" fontWeight="normal" mb="6">
          Endereço
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
          <WrapItem>
            <Text color="gray.400">CEP:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.zipcode}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Endereço:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.address}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Número:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.address_number}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Bairro:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.district}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Cidade: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.address_city?.name}
            </Text>
          </WrapItem>
        </SimpleGrid>
        <Divider mb="2" />
      </Box>

      <Box>
        <Heading size="md" fontWeight="normal" mb="6">
          Documentos
        </Heading>
        <SimpleGrid minChildWidth="300px" spacing="6" mb="2">
          <WrapItem>
            <Text color="gray.400">CPF:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.cpf}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">RG:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.rg}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">Órgão expeditor:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.rg_dispatcher}
            </Text>
          </WrapItem>

          <WrapItem>
            <Text color="gray.400">RG UF:</Text>
            <Text fontWeight="medium" ml="1">
              {motorist.rg_uf}
            </Text>
          </WrapItem>

          {/* <WrapItem>
            <Text color="gray.400">Data de expedição do RG: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.rg_dispatch_date}
            </Text>
          </WrapItem> */}

          <WrapItem>
            <Text color="gray.400">PIS: </Text>
            <Text fontWeight="medium" ml="1">
              {motorist.pis}
            </Text>
          </WrapItem>
        </SimpleGrid>
        <Divider mb="8" />
      </Box>

      {/* <Box>
    <Heading size="md" fontWeight="normal" mb="6">
      Carteira Nacional de Habilitação
    </Heading>
    <SimpleGrid minChildWidth="300px" spacing="6" mb="6">
      <WrapItem>
        <Text color="gray.400">Número de registro:</Text>
        <Text fontWeight="medium" ml="1">
          13215648651-7
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Categoria:</Text>
        <Text fontWeight="medium" ml="1">
          A/B
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Data de expedição:</Text>
        <Text fontWeight="medium" ml="1">
          21/09/2018
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Primeira expedição:</Text>
        <Text fontWeight="medium" ml="1">
          30/07/2015
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Validade: </Text>
        <Text fontWeight="medium" ml="1">
          30 / 05 / 2024
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Órgão expeditor: </Text>
        <Text fontWeight="medium" ml="1">
          DETRAN / CE
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Número do protocolo: </Text>
        <Text fontWeight="medium" ml="1">
          348979651-7
        </Text>
      </WrapItem>

      <WrapItem>
        <Text color="gray.400">Número do seguro: </Text>
        <Text fontWeight="medium" ml="1">
          93465895-8
        </Text>
      </WrapItem>
    </SimpleGrid>
  </Box> */}
    </>
  )
}
