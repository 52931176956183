import { Flex, FlexProps, Heading, Icon } from '@chakra-ui/react'
import { ElementType } from 'react'

export interface PageHeaderProps extends FlexProps {
  title: string
  rightContent?: React.ReactNode
  icon?: ElementType
}

export const PageHeader = ({ title, rightContent, icon, ...rest }: PageHeaderProps): JSX.Element => {
  return (
    <Flex {...rest} justify="space-between" alignItems="center" pb="4" pt="4" borderRadius="8">
      <Heading fontSize="xl" fontWeight="medium" pl="2">
        <Icon as={icon} fontSize="20" mr="4" />
        {title}
      </Heading>
      <Flex {...rest} alignItems="center" gridGap="2">
        {rightContent}
      </Flex>
    </Flex>
  )
}
