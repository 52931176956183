import { Box, Heading } from '@chakra-ui/react'
import { Select } from '../../../../../components/form'

interface FreightTableCategorySelectProps {
  freightType?: string
  setFreightType: (value: string) => void
  errorsAntt: any
  setValue: any
}

export const FreightTableCategorySelect = ({
  freightType,
  setFreightType,
  errorsAntt,
  setValue,
}: FreightTableCategorySelectProps): JSX.Element => {
  return (
    <Box py="4">
      <Heading size="sm" color="gray.500" fontSize="sm" mb="4" textTransform="uppercase">
        Tipo do Frete
      </Heading>
      <Select
        isRequired
        placeholder="Selecione o tipo do frete"
        value={freightType}
        setValue={setValue}
        onChange={e => {
          setFreightType(e.target.value)
        }}
        defaultValue="A"
        initialValue="A"
        error={errorsAntt?.freight_type}
        name="freight_type"
        options={[
          { value: 'A', label: 'Transporte Rodoviário de Carga Lotação (Tabela A)' },
          { value: 'B', label: 'Contratação Apenas do Veículo Automotor de Cargas (Tabela B)' },
          { value: 'C', label: 'Transporte Rodoviário de Carga Lotação de Alto Desempenho (Tabela C)' },
          {
            value: 'D',
            label: 'Contratação Apenas do Veículo Automotor de Cargas de Alto Desempenho (Tabela D)',
          },
        ]}
      />
    </Box>
  )
}
