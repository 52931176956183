import { useEffect, useState } from 'react'
import { getVehicleBodies } from '../services/endpoints/vehicles/getVehicleBodies'
import { getVehicleCategories } from '../services/endpoints/vehicles/getVehicleCategories'

type Option = {
  label: string
  value: string
  [key: string]: any
}

const CACHE_KEY_CATEGORIES = 'vehicleCategories'
const CACHE_KEY_BODIES = 'vehicleBodies'
const CACHE_TIME_LIMIT = 3600 // 1h

// Função auxiliar para verificar se o cache expirou
const isCacheExpired = (cacheKey: string): boolean => {
  const cacheTime = localStorage.getItem(`${cacheKey}_timestamp`)
  if (!cacheTime) return true

  const now = new Date().getTime()
  return now - parseInt(cacheTime, 10) > CACHE_TIME_LIMIT
}

export const useVehicleCategoriesCombox = (): void => {
  useEffect(() => {
    const fetchAndCacheData = async () => {
      if (isCacheExpired(CACHE_KEY_CATEGORIES) || isCacheExpired(CACHE_KEY_BODIES)) {
        const categories = await getVehicleCategories()
        localStorage.setItem(CACHE_KEY_CATEGORIES, JSON.stringify(categories))
        localStorage.setItem(`${CACHE_KEY_CATEGORIES}_timestamp`, new Date().getTime().toString())

        const bodies = await getVehicleBodies()
        localStorage.setItem(CACHE_KEY_BODIES, JSON.stringify(bodies))
        localStorage.setItem(`${CACHE_KEY_BODIES}_timestamp`, new Date().getTime().toString())
      }
    }

    fetchAndCacheData()
  }, [])
}

export const useGetVehicleCategoriesCombox = (): {
  vehicleCategories: Option[]
  vehicleBodies: Option[]
} => {
  const [vehicleCategories, setCategories] = useState<Option[]>([])
  const [vehicleBodies, setBodies] = useState<Option[]>([])

  useEffect(() => {
    const categoriesStorage = localStorage.getItem(CACHE_KEY_CATEGORIES)
    const bodiesStorage = localStorage.getItem(CACHE_KEY_BODIES)

    const parsedCategories = categoriesStorage ? JSON.parse(categoriesStorage) : []
    const parsedBodies = bodiesStorage ? JSON.parse(bodiesStorage) : []

    setCategories(parsedCategories)
    setBodies(parsedBodies)
  }, [])

  return { vehicleCategories, vehicleBodies }
}
