import { CteIssuanceRequestStatusEnum } from '../types/EnumTypes'

export function cteIssuanceRequestStatusLegend(status?: CteIssuanceRequestStatusEnum): string {
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ANALYSIS)
    return 'Aguardando responsável realizar a análise'
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ISSUANCE_CTE) return 'Aguardando emissão do CTe'
  if (status === CteIssuanceRequestStatusEnum.CTE_ISSUED) return 'CTe emitido'
  if (status === CteIssuanceRequestStatusEnum.CTE_RETURN_TO_MOTORIST) return 'CTe retornado ao motorista'
  if (status === CteIssuanceRequestStatusEnum.AWAITING_ADVANCE_REQUEST)
    return 'Aguardando solicitação de adiantamento'
  if (status === CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_ONGOING)
    return 'Solicitação de adiantamento em andamento'
  if (status === CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_FINISHED)
    return 'Solicitação de adiantamento finalizada'
  if (status === CteIssuanceRequestStatusEnum.FINISHED) return 'Solicitação de emissão de CTe finalizada'
  return 'Solicitação de emissão de CTe reprovada pelo responsável'
}
