import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { localStorageKeys } from '../../../contexts/AuthContext'
import { apiServer } from '../../api'
import { ResponseApiType, UserType } from '../../types'

export interface UpdateUserFormData {
  id: string
  name?: string
  email?: string
  password?: string
  passwordConfirmation?: string
  phone?: string
}

async function UpdateUser({ id, ...data }: UpdateUserFormData) {
  const loggedUser = JSON.parse(localStorage.getItem(localStorageKeys.user) || '{}')
  const output = await apiServer.put<ResponseApiType<UserType>>(`/users/${id}`, data)
  if (output.data.statusCode === 200 && loggedUser && loggedUser.id === id) {
    localStorage.setItem(localStorageKeys.user, JSON.stringify(output.data.data))
    queryClient.invalidateQueries('getPermissionsByRoles')
    window.location.reload()
  }
  return output
}

export function usePutUser(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateUserFormData, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, UpdateUserFormData, unknown> {
  return useMutation(async (data: UpdateUserFormData) => UpdateUser(data), options)
}
