import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiFileFind } from 'react-icons/bi'
import { TbTrash } from 'react-icons/tb'
import { queryClient } from '../../config/react-query'
import { Motorist } from '../../services/endpoints/motorists/findMotoristAndOwnerByCpf'
import { useDeleteChecklistRiskAnalysis } from '../../services/endpoints/risk-analysis/checklist-risk-analysis/deleteChecklistRiskAnalysis'
import { useGetChecklistRiskAnalysis } from '../../services/endpoints/risk-analysis/checklist-risk-analysis/getChecklistRiskAnalysis'
import { UserType } from '../../services/types'
import { CreateChecklistRiskAnalysis } from './CreateChecklistRiskAnalysis'
import { EditChecklistRiskAnalysisModal } from './EditChecklistRiskAnalysisModal'

interface IChecklistRiskAnalysisProps {
  risk_analysis_id: string
  user_id: string
  motorist_id: string
  vehicle_id: string
  trailer_id?: string
  bg: string
}

interface IChecklistRiskAnalysisResponse {
  id: string
  creator_id: string
  motorist_id: string
  type: string
  status: string
  color_status: string
  motorist: Motorist
  creator: UserType
  analyst: UserType
  checkType: string
}

export function ChecklistRiskAnalysis({
  bg,
  risk_analysis_id,
  user_id,
  motorist_id,
  vehicle_id,
  trailer_id,
}: IChecklistRiskAnalysisProps): JSX.Element {
  const {
    isOpen: isOpenCreateCostRiskAnalysis,
    onOpen: onOpenCreateCostRiskAnalysis,
    onClose: onCloseCreateCostRiskAnalysis,
  } = useDisclosure()
  const toast = useToast()
  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure()

  const { data: checklistRiskAnalysis, isLoading } = useGetChecklistRiskAnalysis({
    motorist_id,
    vehicle_id,
    trailer_id,
  })

  const { mutateAsync: mutateAsyncDelete } = useDeleteChecklistRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('checklist-risk-analysis')
    },
  })

  const handleDeleteChecklistRiskAnalysis = async (id: string) => {
    try {
      await mutateAsyncDelete(id)

      toast({
        status: 'success',
        title: 'Item deletado com sucesso',
        position: 'top-right',
        isClosable: true,
      })
    } catch (error) {
      toast({
        status: 'error',
        title: error.response?.data.message || 'Não foi possível deletar o item. Tente novamente mais tarde.',
        position: 'top-right',
        isClosable: true,
      })
    }
  }
  const [idToUpdateChecklist, setIdToUpdateChecklist] = useState<string>()

  const onOpenEditChecklistRiskAnalysisModal = (checklistId: string) => {
    setIdToUpdateChecklist(checklistId)
    onOpenChangeStatus()
  }

  useEffect(() => {
    queryClient.invalidateQueries('checklist-risk-analysis')
  }, [])

  return (
    <>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" my="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Checklists Obrigatórios
          </Heading>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={() => {
              onOpenCreateCostRiskAnalysis()
            }}
          >
            + Adicionar novo item
          </Button>
        </Flex>

        <Divider my="6" />

        <>
          {checklistRiskAnalysis && checklistRiskAnalysis.length > 0 ? (
            <TableContainer maxW="1100px">
              {!isLoading ? (
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">Criador</Th>
                      <Th textAlign="center">Tipo de Checagem</Th>
                      <Th textAlign="center">Motorista</Th>
                      <Th textAlign="center">Status</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody flex={1}>
                    {checklistRiskAnalysis?.map((checklist: IChecklistRiskAnalysisResponse) => (
                      <Tr p={2} key={checklist.id}>
                        <Td textTransform="capitalize" textAlign="center">
                          {checklist.creator.name}
                        </Td>
                        <Td textTransform="capitalize" textAlign="center">
                          {checklist.checkType}
                        </Td>
                        <Td textTransform="capitalize" maxW="160px" textAlign="center">
                          {checklist.motorist.name}
                        </Td>
                        <Td textAlign="center">
                          <Text fontSize="xs">
                            <Tag colorScheme={checklist.color_status} p="2" borderRadius="full" gridGap={2}>
                              <Text fontSize="xs" fontWeight="bold">
                                {checklist.status}
                              </Text>
                            </Tag>
                          </Text>
                        </Td>
                        <Td p={2} textAlign="center">
                          <Flex justifyContent="center">
                            <Tooltip hasArrow label="Ver Checklist">
                              <Button size="sm" colorScheme="white">
                                <Box
                                  cursor="pointer"
                                  color="orange"
                                  onClick={() => {
                                    setIdToUpdateChecklist(checklist.id)
                                  }}
                                >
                                  <Icon
                                    fontSize="2xl"
                                    as={BiFileFind}
                                    onClick={() => onOpenEditChecklistRiskAnalysisModal(checklist.id)}
                                  />
                                </Box>
                              </Button>
                            </Tooltip>
                            <Tooltip label="Deletar registro" hasArrow placement="bottom">
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleDeleteChecklistRiskAnalysis(checklist.id)}
                              >
                                <Icon as={TbTrash} />
                              </Button>
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <>
                  <Flex justifyContent="center">
                    <Spinner />
                  </Flex>
                </>
              )}
            </TableContainer>
          ) : (
            <Flex justifyContent="center">
              <Text>Nenhum item registrado</Text>
            </Flex>
          )}
        </>

        {isOpenCreateCostRiskAnalysis && (
          <CreateChecklistRiskAnalysis
            isOpen={isOpenCreateCostRiskAnalysis}
            onClose={onCloseCreateCostRiskAnalysis}
            creator_id={user_id}
            motorist_id={motorist_id}
            vehicle_id={vehicle_id}
            trailer_id={trailer_id}
            risk_analysis_id={risk_analysis_id}
          />
        )}

        {isOpenChangeStatus && (
          <EditChecklistRiskAnalysisModal
            checklist_id={String(idToUpdateChecklist)}
            isOpen={isOpenChangeStatus}
            onClose={() => {
              onCloseChangeStatus()
            }}
          />
        )}
      </Box>
    </>
  )
}
