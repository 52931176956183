import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { AxiosResponse } from 'axios'
import { publicApiServer } from '../../../publicApi'
import { ResponseApiType } from '../../../types'
import { IClientFeedbackData } from '../reportsFeedbacksClient'

export interface CreateClientFeedbackFormData {
  freight_id: string
  because_this_note: string
  spotx_note: number
  punctuality_note: number
  collect_and_delivery_note: number
  seller_note: number
  payment_method_note: number
  informations_note: number
  recommendation_score: number
  why_this_recommendation_score: string
}

async function createClientFeedback(data: CreateClientFeedbackFormData): Promise<AxiosResponse> {
  return publicApiServer.post('/create-client-feedbacks', data)
}
export function usePostClientFeedback(
  options?: UseMutationOptions<
    AxiosResponse<ResponseApiType<IClientFeedbackData>>,
    unknown,
    CreateClientFeedbackFormData,
    unknown
  >,
): UseMutationResult<
  AxiosResponse<ResponseApiType<IClientFeedbackData>>,
  unknown,
  CreateClientFeedbackFormData,
  unknown
> {
  return useMutation((data: CreateClientFeedbackFormData) => createClientFeedback(data), options)
}
