import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'

async function deleteTrackerDevice(id: string) {
  return apiServer.delete(`/tracker-devices/${id}`)
}
export function useDeleteTrackerDevice(
  options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, string, unknown> {
  return useMutation(async (data: string) => deleteTrackerDevice(data), options)
}
