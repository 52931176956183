import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightType, ResponseApiType } from '../../types'

type ResponseApi = {
  total: number
  data: {
    freight: FreightType
    freight_stub_status: string
    freight_stub_approved_total: number
    freight_stub_desapproved_total: number
    freight_stub_status_formated: string
  }[]
}

type FetchStubsFormattedParams = {
  page?: number
  per_page?: number
  freight_number?: string
  cte_number?: string
  status?: 'approved' | 'disapproved' | 'pending'
}

async function StubsFormattedByFreight(params?: FetchStubsFormattedParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.get<ResponseApiType<ResponseApi>>('stubs/formatted', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useGetStubsFormattedByFreight(
  params?: FetchStubsFormattedParams,
): UseQueryResult<ResponseApi> {
  return useQuery(['stubs_formatted', params], () => StubsFormattedByFreight(params), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  })
}
