import { Box, Grid, GridItem, GridItemProps, useMediaQuery } from '@chakra-ui/react'
import MetricCard from '../../../components/metricCard'
import { GetMetricsResponse } from '../../../services/endpoints/metrics/getMetrics'

export interface ListProps {
  monthMetrics: GetMetricsResponse['metrics']
  yearMetrics: GetMetricsResponse['metrics']
  subMonth: GetMetricsResponse['metrics']
  filters: { initial_date: Date; final_date: Date }
}

const removeStringAndConvertToNumber = (metric: string) => {
  if (!metric) return 0
  return Number(metric.replace(/[^0-9]/g, ''))
}
const ResponsiveGridItem = ({ children, ...props }: GridItemProps): JSX.Element => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  return <GridItem {...(isLargerThan800 ? props : { colSpan: 17 })}>{children}</GridItem>
}

const List = ({ monthMetrics, yearMetrics, subMonth, filters }: ListProps): JSX.Element => {
  return (
    <Box py="8" display="flex" flexDirection="row" width="100%">
      <Grid gap={3} templateColumns="repeat(17, 1fr)" width="100%">
        <ResponsiveGridItem colSpan={6} rowStart={1} rowEnd={4}>
          <MetricCard
            label="Custo por quilômetro"
            value={monthMetrics.costPerKilometer}
            yearValue={yearMetrics.costPerKilometer}
            subMonthValue={subMonth.costPerKilometer}
            filters={filters}
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem colSpan={6} rowStart={1} rowEnd={4}>
          <MetricCard
            label="Tempo médio de Viagem por KM"
            value={monthMetrics.transportCycleTime}
            yearValue={yearMetrics.transportCycleTime}
            subMonthValue={subMonth.transportCycleTime}
            filters={filters}
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem colSpan={4} colEnd={17}>
          <MetricCard
            label="Pontualidade Entrega"
            value={monthMetrics.deliveryPunctuality}
            yearValue={yearMetrics.deliveryPunctuality}
            subMonthValue={subMonth.deliveryPunctuality}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} rowEnd={5}>
          <MetricCard
            label="NPS do Motorista"
            value={monthMetrics.thirdPartyServiceQuality}
            yearValue={yearMetrics.thirdPartyServiceQuality}
            subMonthValue={subMonth.thirdPartyServiceQuality}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} rowEnd={5}>
          <MetricCard
            label="NPS do Cliente"
            value={String(monthMetrics.customerSatisfaction)}
            yearValue={yearMetrics.customerSatisfaction}
            subMonthValue={subMonth.customerSatisfaction}
            filters={filters}
          />
        </ResponsiveGridItem>
        <ResponsiveGridItem colSpan={5} rowEnd={6}>
          <MetricCard
            label="Rastreados"
            value={{
              Satélite: monthMetrics.tracked_by_satellite,
              Web: monthMetrics.tracked_by_web,
              Total: `${String(
                (removeStringAndConvertToNumber(monthMetrics.tracked_by_satellite) +
                  removeStringAndConvertToNumber(monthMetrics.tracked_by_web)) /
                  100,
              )}%`,
            }}
            yearValue={{
              Satélite: yearMetrics.tracked_by_satellite,
              Web: yearMetrics.tracked_by_web,
              Total: `${String(
                (removeStringAndConvertToNumber(yearMetrics.tracked_by_satellite) +
                  removeStringAndConvertToNumber(yearMetrics.tracked_by_web)) /
                  100,
              )}%`,
            }}
            subMonthValue={{
              Satélite: subMonth.tracked_by_satellite,
              Web: subMonth.tracked_by_web,
              Total: `${String(
                (removeStringAndConvertToNumber(subMonth.tracked_by_satellite) +
                  removeStringAndConvertToNumber(subMonth.tracked_by_web)) /
                  100,
              )}%`,
            }}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} rowEnd={5} colEnd={13}>
          <MetricCard
            label="Taxa de Feedbacks do Cliente"
            value={monthMetrics.clientFeedbackRate}
            yearValue={yearMetrics.clientFeedbackRate}
            subMonthValue={subMonth.clientFeedbackRate}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} colEnd={17}>
          <MetricCard
            label="Taxa de Incidentes"
            value={monthMetrics.incidentRate}
            yearValue={yearMetrics.incidentRate}
            subMonthValue={subMonth.incidentRate}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} colEnd={17} rowEnd={4}>
          <MetricCard
            label="Pontualidade Coleta"
            value={monthMetrics.collectionPunctuality}
            yearValue={yearMetrics.collectionPunctuality}
            subMonthValue={subMonth.collectionPunctuality}
            filters={filters}
          />
        </ResponsiveGridItem>

        <ResponsiveGridItem colSpan={4} colEnd={17}>
          <MetricCard
            label="SLA"
            value={monthMetrics.slaAdherence}
            yearValue={yearMetrics.slaAdherence}
            subMonthValue={subMonth.slaAdherence}
            filters={filters}
          />
        </ResponsiveGridItem>
      </Grid>
    </Box>
  )
}

export default List
