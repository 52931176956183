import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { RiStarFill, RiStarLine } from 'react-icons/ri'

interface StarProps extends ButtonProps {
  selected?: boolean
}

export function Star({ selected = false, color, ...rest }: StarProps): JSX.Element {
  const StarIcon = selected ? RiStarFill : RiStarLine
  const hover = rest.isDisabled ? undefined : 'yellow.500'

  return (
    <Button
      variant="unstyled"
      fontSize="28"
      color={selected ? 'yellow.500' : color}
      transition="font-size 0.2s"
      _hover={{ color: hover }}
      {...rest}
    >
      <Icon as={StarIcon} />
    </Button>
  )
}
