/* eslint-disable consistent-return */
import { apiServer } from '../../api'
import { MotoristType, ResponseApiType } from '../../types'
import OwnerType from '../../types/OwnerType'
import getOnlyNumbers from '../../utils/getOnlyNumbers'
import { FormattedMotorist } from './getMotorist'

export async function findMotoristByCpf(
  cpf: string,
): Promise<FormattedMotorist | MotoristType | OwnerType | undefined> {
  if (cpf && cpf !== 'undefined') {
    try {
      const { data: res } = await apiServer.get<ResponseApiType<OwnerType>>(
        `/find-motorist-by-cpf/${getOnlyNumbers(cpf)}`,
      )
      const owner = res.data as OwnerType
      return owner
    } catch (error) {
      return undefined
    }
  }
}
