/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { FiChevronDown, FiChevronUp, FiSearch, FiX } from 'react-icons/fi'
import { FormattedMessage } from '../../../components/intl'
import { Permissions } from '../../../services/types/Authorization'

export interface ModulePermissions extends Permissions {
  value: boolean
}

interface onChangeProps {
  can: ModulePermissions[]
  cannot: ModulePermissions[]
  permissionToRemove: ModulePermissions[]
  module: string
}

interface IModule {
  name: string
  moduleKey: string
  actions: string[]
  can: Permissions[]
  cannot: Permissions[]
  onChange?: (value: onChangeProps) => void
}

const Module = ({ name, moduleKey, actions, can, cannot, onChange }: IModule): JSX.Element => {
  const [actionsControl, setActionsControl] = useState<{
    [key: string]: boolean
  }>({})
  const [filterValue, setFilterValue] = useState('')
  const { isOpen, onToggle } = useDisclosure()
  const bg = useColorModeValue('white', 'gray.800')

  const handleChange = (valueChanged: { [key: string]: boolean }) => {
    const cannotDraft: ModulePermissions[] = []
    const canDraft: ModulePermissions[] = can.map(permission => {
      if (valueChanged[permission.action]) {
        return { ...permission, value: true }
      }
      return { ...permission, value: false }
    })
    const permissionToRemoveDraft: ModulePermissions[] = []

    Object.keys(valueChanged).forEach(key => {
      cannotDraft.push({ module: moduleKey, action: key, value: valueChanged[key] })
      canDraft.push({ module: moduleKey, action: key, value: valueChanged[key] })
    })

    onChange?.({
      cannot: cannotDraft,
      can: canDraft,
      module: moduleKey,
      permissionToRemove: permissionToRemoveDraft,
    })
  }

  const itensFiltered = useMemo(() => {
    if (filterValue === '') return actions

    return actions.filter(item => item.toLowerCase().includes(filterValue.toLowerCase())) || []
  }, [filterValue, actions])

  const handleToggleAction = (actionName: string, value: boolean) => {
    const draft = { ...actionsControl, [actionName]: value }
    setActionsControl(draft)
    handleChange(draft)
  }

  const handleToggleAll = (value: boolean) => {
    let draft = {}
    actions.forEach(act => {
      setActionsControl(prev => ({ ...prev, [act]: value }))
      draft = { ...draft, [act]: value }
    })
    handleChange(draft)
  }

  useEffect(() => {
    const isAllCheck = can.some(c => {
      if (c.action === '*') {
        if (c.module === '*') {
          return true
        }
      }
      return false
    })

    if (isAllCheck) {
      actions.forEach(act => {
        setActionsControl(prev => ({ ...prev, [act]: true }))
      })
    }

    cannot.forEach(cannotRule => {
      if (cannotRule.module !== moduleKey) return // return actions that are not in this module
      setActionsControl(prev => {
        return { ...prev, [cannotRule.action]: false }
      })
    })
  }, [])

  return (
    <Box bg={bg} rounded="md" borderRadius="lg" mb={4} p={4}>
      <Flex justifyContent="space-between" align="center" onClick={onToggle} cursor="pointer">
        <Heading size="sm">
          <FormattedMessage id={name} />
        </Heading>
        <IconButton
          aria-label="Open navigation"
          variant="ghost"
          icon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />}
          fontSize="24"
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box mt={2}>
          <Flex align="center" justifyContent="space-between">
            <Text color="gray.300" fontWeight="bold">
              Marque o que esse cargo pode fazer:
            </Text>
            <Flex align="center" gridGap={4}>
              <Button size="sm" variant="outline" onClick={() => handleToggleAll(true)}>
                Marcar todos
              </Button>
              <Button size="sm" variant="outline" onClick={() => handleToggleAll(false)}>
                Desmarcar todos
              </Button>
            </Flex>
          </Flex>
          <Flex py={4}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray.300" />
              </InputLeftElement>
              <Input
                value={filterValue}
                placeholder="buscar action"
                onChange={({ target }) => setFilterValue(target.value)}
              />
              <InputRightElement onClick={() => setFilterValue('')}>
                <FiX color="green.500" />
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Box mt={4}>
            <FormControl as={SimpleGrid} columns={{ base: 2, lg: 3, sm: 2 }}>
              {itensFiltered.map(act => (
                <Flex Key={act} gridGap={4}>
                  <Switch
                    mt={2}
                    id={act}
                    isChecked={actionsControl && actionsControl[act]}
                    onChange={({ target }) => handleToggleAction(act, target.checked)}
                  />
                  <FormLabel htmlFor={act}>
                    <FormattedMessage id={act} />
                    <Text size="sm" color="gray.300" fontSize="14px">
                      {act}
                    </Text>
                  </FormLabel>
                </Flex>
              ))}
            </FormControl>
          </Box>
        </Box>
      </Collapse>
      {isOpen && (
        <Flex justifyContent="flex-end">
          <IconButton
            onClick={onToggle}
            aria-label="Open navigation"
            variant="ghost"
            icon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />}
            fontSize="24"
          />
        </Flex>
      )}
    </Box>
  )
}

export default Module
