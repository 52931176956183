import axios from 'axios'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

export const CACHE_VERSION_KEY = '@spotx:cache-version'

export async function fetchVersion(): Promise<string> {
  const response = await axios.get<string>(`/hash-version.txt`, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

  const cache = String(response.data)

  return cache || 'no-version'
}
export function useGetVersion(options?: UseQueryOptions<string, unknown>): UseQueryResult<string, unknown> {
  return useQuery<string, unknown>(['cache'], () => fetchVersion(), {
    ...options,
    staleTime: 1000 * 60 * 100,
    refetchOnWindowFocus: 'always',
  })
}
