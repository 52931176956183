import { Box, Icon, Tooltip, useDisclosure } from '@chakra-ui/react'
import { ImWarning } from 'react-icons/im'
import BlackListType from '../../../../services/types/BlackListType'
import { ShowInfosBlackListModal } from '../ShowInfosBlackListModal'

export interface ButtonShowInfosBlackListProps {
  black_list: BlackListType
}

export default function ButtonShowInfosBlackList({ black_list }: ButtonShowInfosBlackListProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Tooltip hasArrow label="Mais informações">
        <Box
          cursor="pointer"
          color="orange"
          onClick={() => {
            onOpen()
          }}
        >
          <Icon as={ImWarning} fontSize={20} />
        </Box>
      </Tooltip>

      <ShowInfosBlackListModal black_list={black_list} isOpen={isOpen} onClose={onClose} />
    </>
  )
}
