import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { FormattedQuotation } from '../../../services/endpoints/freights'
import {
  KanbanCardDisabled,
  KanbanCardFinished,
  KanbanCardInQuotation,
  KanbanCardOpen,
  KanbanCardPending,
} from './cards'

interface KanbanListCurrentProps {
  type: 'pending' | 'in_quotation' | 'to_approve' | 'finished' | 'disabled'
  title: string
  colorProps: {
    color: string
  }
  total: number
  quotations: FormattedQuotation[]
  isLoading?: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export function KanbanListQuotation({
  type,
  title,
  colorProps,
  quotations,
  total,
  isLoading,
  setPage,
}: KanbanListCurrentProps): JSX.Element | null {
  const bg = useColorModeValue('white', 'gray.800')

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPage(state => state + 1)
      }
    })

    if (quotations.length > 4 && quotations.length < total && document.querySelector(`#sentry${type}`)) {
      intersectionObserver.observe(document.querySelector(`#sentry${type}`) as Element)
    }

    return () => intersectionObserver.disconnect()
  }, [quotations.length, setPage, total, type])

  return (
    <Box minW="320px" w="100%">
      <Box bg={bg} borderRadius={10} shadow="lg">
        <Flex alignItems="baseline" justify="space-between">
          <Text px={['1', '2']} fontSize="small" mb={1} color={colorProps.color}>
            {title}
          </Text>
          <Text px={['1', '2']} fontSize="sm" mb={1} color={colorProps.color}>
            {total}
          </Text>
        </Flex>

        {isLoading ? (
          <Flex justifyContent="center">
            <Spinner mb="8" thickness="4px" speed="0.65s" emptyColor="gray.200" color={colorProps.color} />
          </Flex>
        ) : quotations ? (
          <Box
            px={['1', '2']}
            maxH={['calc(100vh - 110px)', 'calc(100vh - 110px)']}
            overflowY="auto"
            overflowX="hidden"
          >
            {quotations.map(quotation => {
              switch (type) {
                case 'pending':
                  return <KanbanCardOpen key={quotation.id} quotation={quotation} />
                case 'in_quotation':
                  return <KanbanCardInQuotation key={quotation.id} quotation={quotation} />
                case 'to_approve':
                  return <KanbanCardPending key={quotation.id} quotation={quotation} />
                case 'finished':
                  return <KanbanCardFinished key={quotation.id} quotation={quotation} />
                case 'disabled':
                  return <KanbanCardDisabled key={quotation.id} quotation={quotation} />
                default:
                  return ''
              }
            })}
            {quotations.length > 4 && quotations.length < total && (
              <Flex justifyContent="center" id={`sentry${type}`}>
                <Spinner
                  mb="8"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color={colorProps.color}
                />
              </Flex>
            )}
          </Box>
        ) : (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription justifyContent="center">
              <Text fontSize="lg" color="red.500" fontWeight="bold">
                Erro ao carregar fretes!
              </Text>
              Verifique sua conexão com a internet ou entre em contato com o administrador do sistema.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </Box>
  )
}
