import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleType } from '../../types'
import { GetResponseCarsApi } from '../../types/GetResponseCarsApi'
import { searchCityByCityNameAndStateName } from '../../utils/searchCityByCityNameAndStateName'

export interface VehicleResponseFormData extends VehicleType {
  formatted_has_insurance: string
  formatted_has_tracker: string
}
export const formatColorForBuonny = (color: string): string => {
  if (color === 'AMARELA') return 'AMARELO'
  if (color === 'BRANCA') return 'BRANCO'
  if (color === 'PRETA') return 'PRETO'
  if (color === 'ROXA') return 'ROXO'
  if (color === 'VERMELHA') return 'VERMELHO'
  return color.toUpperCase()
}

export async function searchVehicleByLicensePlate(license_plate: string): Promise<GetResponseCarsApi> {
  const { data } = await apiServer.get<ResponseApiType<GetResponseCarsApi>>(
    `/vehicles/wdapi-placas/${license_plate}`,
  )

  const cityByCarsApi = await searchCityByCityNameAndStateName({
    cityName: String(data.data?.city),
    uf: String(data.data?.uf),
  })

  const dataCar = {
    ...data.data,
    cityComplete: cityByCarsApi,
    color: formatColorForBuonny(data.data.color.toUpperCase()),
  }

  return dataCar
}

export function useGetVehicleDataByCarApi(license_plate: string): UseQueryResult<GetResponseCarsApi> {
  return useQuery(['vehicles/wdapi-placas', license_plate], () => searchVehicleByLicensePlate(license_plate))
}
