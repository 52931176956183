import * as yup from 'yup'

export type VehicleFormValues = {
  renavam: string
  license_plate: string
  vehicle_category_id: string
  vehicle_body_id: string
  release_year: string
  model_year: string
  axes: number
  brand: string
  model: string
  chassi: string
  capacity_m3: number
  capacity_tara: number
  capacity_kg: number
  antt: string
  color: string
  has_insurance: boolean
  has_tracker: boolean
  city_id: {
    label: string
    value: number | string
  }
  license_uf: string
}

export const VehicleSchema = yup.object().shape({
  renavam: yup.string().required('Renavam obrigatório'),
  license_plate: yup.string().required('Placa obrigatória'),
  vehicle_category_id: yup.string().required('Categoria obrigatória'),
  vehicle_body_id: yup.string().required('Cor obrigatória'),
  release_year: yup.string().required('Ano obrigatório'),
  model_year: yup.string().required('Ano obrigatório'),
  axes: yup.string().required('Eixos obrigatórios'),
  brand: yup.string().required('Marca obrigatória'),
  model: yup.string().required('Modelo obrigatório'),
  chassi: yup.string().required('Chassi obrigatório'),
  capacity_m3: yup.number().required('Capacidade obrigatória'),
  capacity_tara: yup.number().required('Capacidade obrigatória'),
  capacity_kg: yup.number().required('Capacidade obrigatória'),
  antt: yup.string().required('Antt obrigatória'),
  color: yup.string().required('Cor obrigatória'),
  has_insurance: yup.boolean(),
  has_tracker: yup.boolean(),
  city_id: yup.object().required('Cidade obrigatória'),
  license_uf: yup.string().required('UF obrigatória'),
})
