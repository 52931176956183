import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

interface OwnerTypeReturn {
  id: string
  name: string
}

export async function searchOwnerByName(name?: string): Promise<AutocompleteOption[]> {
  if (name) {
    const { data: res } = await apiServer.get<ResponseApiType<{ data: OwnerTypeReturn[] }>>('/list-owner', {
      params: {
        name,
      },
    })

    return res.data.data.map(owner => {
      const label = owner.name
      const value = owner.id

      return { label, value }
    })
  }
  return []
}
