import { AutocompleteOption } from '../../components/form/types/AutocompleteOption'
import { searchCitiesByName } from '../endpoints/cities/searchCities'

interface FindParams {
  cityName: string
  stateName?: string
  uf?: string
}

export async function searchCityByCityNameAndStateName(
  params: FindParams,
): Promise<AutocompleteOption | undefined> {
  const cityArray = await searchCitiesByName(params.cityName)

  const city = cityArray.find(
    infoCity => infoCity.stateName === params.stateName || infoCity.uf === params.uf,
  )

  return city
}
