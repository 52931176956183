import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'

export interface UpdateAttachToVehicle {
  id: string
  attachment_file: File
  type?: string
  name?: string
  description?: string
  vehicle_id?: string
}

async function updateAttachmentToVehicle({ id, attachment_file, vehicle_id }: UpdateAttachToVehicle) {
  const formData = new FormData()
  formData.append('attachment_file', attachment_file)
  if (vehicle_id) formData.append('vehicle_id', vehicle_id)
  formData.append('type', 'crlv')
  formData.append('name', 'CRLV do veículo')
  return apiServer.patch(`/vehicles/attachments/${id}`, formData)
}

export function useUpdateAttachmentToVehicle(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateAttachToVehicle, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, UpdateAttachToVehicle, unknown> {
  return useMutation(async (data: UpdateAttachToVehicle) => updateAttachmentToVehicle(data), options)
}
