import * as yup from 'yup'

const currentYear = new Date().getFullYear()

export type TrailerFormValues = {
  id?: string
  renavam: string
  license_plate: string
  vehicle_body_id: string
  release_year: string
  model_year: string
  axes: number
  brand: string
  model: string
  chassi: string
  capacity_m3: number
  capacity_tara: number
  capacity_kg: number
  antt: string
  color: string
  has_insurance: boolean
  has_tracker: boolean
  city_id: {
    label: string
    value: number | string
  }
  license_uf: string
}

export const TrailerSchema = yup.object().shape({
  id: yup.string(),
  vehicle_body_id: yup.string().required('Cor obrigatória'),
  brand: yup.string().required('Marca obrigatória'),
  model: yup.string().required('Modelo obrigatório'),
  chassi: yup.string().required('Chassi obrigatório'),
  antt: yup.string().required('Antt obrigatória'),
  color: yup.string().required('Cor obrigatória'),
  has_insurance: yup.boolean(),
  has_tracker: yup.boolean(),
  city_id: yup.object().required('Cidade obrigatória'),
  license_uf: yup.string().required('UF obrigatória'),
  license_plate: yup.string().min(7, 'Placa incompleta').required('Campo obrigatório'),
  axes: yup.number().required('Campo obrigatório'),
  renavam: yup
    .string()
    .min(9, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .max(11, 'O campo Renavam deve conter entre 9 e 11 caracteres.')
    .required('Campo obrigatório'),
  release_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  model_year: yup
    .string()
    .required('Campo obrigatório')
    .max(currentYear, 'Não pode ser maior que o ano atual'),
  capacity_m3: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_tara: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
  capacity_kg: yup
    .number()
    .max(2139999999, 'O valor excede limite de 2.139.999.999')
    .min(1, 'O valor não pode ser zero')
    .required('Campo obrigatório'),
})
