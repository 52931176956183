import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'

export const rolesRequesters = [
  { label: '', value: '' },
  { label: 'Solicitante', value: 'requester' },
  { label: 'Supervisor de solicitante', value: 'requester_supervisor' },
]

type Input = {
  id: string
  name?: string
  role?: string
  email?: string
  phone?: string
  phone2?: string
  client_id?: string
  last_monthly_nps_at?: string
  client_business_group_id: string
}

async function createContactFromClient(data: Input) {
  return apiServer.post('/clients/contacts', data)
}

export function usePostContactFromClient(
  options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>,
): UseMutationResult<AxiosResponse, unknown, Input, unknown> {
  return useMutation(async (data: Input) => createContactFromClient(data), options)
}
