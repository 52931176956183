import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { Authorization, ResponseApiType } from '../../types'
import { Permissions } from '../../types/Authorization'
import { UserRolesOptions } from '../../types/AuthorizationModulesTypes'

type Input = {
  roles: UserRolesOptions[]
  isUpdateManyRoles?: boolean
  can?: Permissions[]
  cannot?: Permissions[]
}
type Output = Authorization[]

async function putPermissions(input: Input) {
  const output = await apiServer.put<ResponseApiType<Output>>('/authorization/permissions', { ...input })
  return output
}

export function usePutPermissions(
  options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, Input, unknown> {
  return useMutation(async (input: Input) => putPermissions(input), options)
}
