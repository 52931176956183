import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiEditLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { apiServer } from '../../../services/api'
import { MotoristType, ResponseApiType } from '../../../services/types'
import OwnerType from '../../../services/types/OwnerType'
import {
  MotoristCompleteForm,
  MotoristCompleteFormData,
  motoristCompleteFormSchema,
} from '../MotoristCompleteForm'

interface CreateMotoristCompleteFormModalProps {
  isOpen: boolean
  onClose: () => void
  ownerExistsData?: OwnerType
}

export function CreateMotoristCompleteFormModal({
  isOpen,
  onClose,
  ownerExistsData,
}: CreateMotoristCompleteFormModalProps): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(motoristCompleteFormSchema),
  })

  const handleEditOrDuplicateMotorist = useCallback<SubmitHandler<MotoristCompleteFormData>>(
    async data => {
      const { bank, address_city_id, ...motoristFormData } = data
      try {
        const { data: res } = await apiServer.post<ResponseApiType<MotoristType>>(`/create-motorist`, {
          ...motoristFormData,
          bank: bank?.label ? bank.label : undefined,
          address_city_id: address_city_id?.value ? address_city_id.value : undefined,
          motorist_contacts: [
            {
              name: motoristFormData.name_contacts_ref_person_1,
              phone: motoristFormData.phone_ref_person_1,
              type: motoristFormData.type_contacts_ref_person_1,
            },
            {
              name: motoristFormData.name_contacts_ref_person_2,
              phone: motoristFormData.phone_ref_person_2,
              type: motoristFormData.type_contacts_ref_person_2,
            },
            {
              name: motoristFormData.name_contacts_ref_professional_1,
              phone: motoristFormData.phone_ref_professional_1,
              type: motoristFormData.type_contacts_ref_professional_1,
            },
            {
              name: motoristFormData.name_contacts_ref_professional_2,
              phone: motoristFormData.phone_ref_professional_2,
              type: motoristFormData.type_contacts_ref_professional_2,
            },
          ],
        })
        const newMotorist = res.data
        toast({
          title: 'Motorista cadastrado com sucesso!',
          status: 'success',
          position: 'top-right',
        })

        history.push(`/motorists/show/${newMotorist.id}`)
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [toast, history],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl" scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleEditOrDuplicateMotorist)} noValidate autoComplete="off">
          <ModalHeader>Cadastro Completo de Motorista</ModalHeader>
          <ModalCloseButton />
          {ownerExistsData && (
            <Box px={6}>
              <Alert status="info" mb="4" borderRadius="4">
                <AlertIcon />
                <Box>
                  Um proprietário com nome <strong>{ownerExistsData.name}</strong> foi encontrado com o CPF
                  digitado, você poderá cadastra-lo como motorista
                </Box>
              </Alert>
            </Box>
          )}
          <ModalBody>
            <MotoristCompleteForm
              setValue={setValue}
              ownerExistsData={ownerExistsData}
              formState={formState}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiEditLine} />}
              isLoading={formState.isSubmitting}
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
