import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { CEP, searchAddressByCep } from '../services/utils/searchAddressByCep'

const useSearchAddress = (
  onSuccess: (data: CEP) => void,
  onError: (error: any) => void,
): {
  loadingAddress: boolean
  searchAddress: (search: string) => void
} => {
  const [loadingAddress, setLoadingAddress] = useState(false)

  const searchAddress = useRef(
    debounce(
      async (search: string) => {
        if (search.replace(/\D/g, '').length < 5) return
        setLoadingAddress(true)

        try {
          const data = await searchAddressByCep(search)

          if (data) {
            onSuccess(data)
          }
        } catch (error) {
          onError(error)
        } finally {
          setLoadingAddress(false)
        }
      },
      500,
      {},
    ),
  ).current

  useEffect(() => {
    return () => {
      searchAddress.cancel()
    }
  }, [searchAddress])

  return { loadingAddress, searchAddress }
}

export default useSearchAddress
