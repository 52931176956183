/* eslint-disable no-restricted-syntax */
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType, UserType } from '../../../types'
import { Motorist } from '../../motorists/findMotoristAndOwnerByCpf'

interface IGetChecklistRiskAnalysisParams {
  risk_analysis_id?: string
  motorist_id?: string
  vehicle_id?: string
  trailer_id?: string
}

export interface IChecklistRiskAnalysisResponse {
  id: string
  risk_analysis_id: string
  creator_id: string
  created_at: Date
  updated_at: Date
  motorist_id: string
  vehicle_id: string
  analyst_id: string
  status: string
  comments: string
  evaluated_at: Date
  user_id: string
  type: string
  color_status: string
  motorist: Motorist
  creator: UserType
  analyst: UserType
  checkType: string
}

async function getChecklistRiskAnalysis(
  params: IGetChecklistRiskAnalysisParams,
): Promise<ResponseApiType<IChecklistRiskAnalysisResponse>[] | undefined> {
  if (params && Object.keys(params).length > 0) {
    const { data: checklistRiskAnalysis } = await apiServer.get(`/list-risk-analysis-checklist`, {
      params,
    })

    for (const item of checklistRiskAnalysis.data) {
      if (item.status === 'approved') {
        item.color_status = 'green'
        item.status = 'Aprovado'
      } else if (item.status === 'reproved') {
        item.color_status = 'red'
        item.status = 'Reprovado'
      } else if (item.status === 'pending') {
        item.color_status = 'orange'
        item.status = 'Pendente'
      }
    }

    return checklistRiskAnalysis.data
  }
  return undefined
}

export function useGetChecklistRiskAnalysis(
  params: IGetChecklistRiskAnalysisParams,
): UseQueryResult<IChecklistRiskAnalysisResponse[] | undefined> {
  return useQuery(['checklist-risk-analysis', params], () => getChecklistRiskAnalysis(params))
}
