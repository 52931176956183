import { Button, Divider, Grid, GridItem, HStack, Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { RiAddLine, RiArrowLeftLine } from 'react-icons/ri'
import { Input } from '../../../../components/form/Input'
import { RadioGroup } from '../../../../components/form/RadioGroup'

interface AdditionalDataTabPanelProps {
  setValue: UseFormSetValue<FieldValues>
  tabNav: (tabIndex: number) => void
  formState: FormState<FieldValues>
}

export function AdditionalDataTabPanel({
  setValue,
  tabNav,
  formState,
}: AdditionalDataTabPanelProps): JSX.Element {
  const [stolenTimes, setStolenTimes] = useState(false)
  const [accidentTimes, setAccidentTimes] = useState(false)
  const [carryTimes, setCarryTimes] = useState(false)
  const [TrackingSystem, setTrackingSystem] = useState(false)
  const [crimeProccessNumbers, setCrimeProccessNumbers] = useState(false)
  const { errors } = formState

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={[12, 7]}>
          <RadioGroup
            name="was_stolen_in_last_24_months"
            label="Foi roubado nos últimos 24 meses?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.was_stolen_in_last_24_months}
            onChange={e => {
              if (e === 'S') setStolenTimes(true)
              else setStolenTimes(false)
            }}
          />
        </GridItem>

        {stolenTimes && (
          <GridItem colSpan={[12, 2]}>
            <Input
              type="number"
              name="stolen_times"
              label="Quantas vezes"
              setValue={setValue}
              error={errors.stolen_times}
            />
          </GridItem>
        )}

        <GridItem colSpan={12} my="3">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 7]}>
          <RadioGroup
            name="had_accident"
            label="Já se envolveu em acidente?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.had_accident}
            onChange={e => {
              if (e === 'S') setAccidentTimes(true)
              else setAccidentTimes(false)
            }}
          />
        </GridItem>

        {accidentTimes && (
          <GridItem colSpan={[12, 2]}>
            <Input
              type="number"
              name="accident_times"
              label="Quantas vezes"
              setValue={setValue}
              error={errors.accident_times}
            />
          </GridItem>
        )}

        <GridItem colSpan={12} my="3">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 7]}>
          <RadioGroup
            name="how_long_carry_to_company"
            label="Quanto tempo transportou para esta empresa?"
            setValue={setValue}
            options={[
              { label: 'Anos', value: 5 },
              { label: 'Meses', value: 6 },
              { label: 'Vezes', value: 7 },
              { label: 'Nunca transportou', value: 40 },
            ]}
            initialValue={40}
            direction="column"
            error={errors.how_long_carry_to_company}
            onChange={e => {
              if (e !== '40') setCarryTimes(true)
              else setCarryTimes(false)
            }}
          />
        </GridItem>

        {carryTimes && (
          <GridItem colSpan={[12, 2]}>
            <Input
              type="number"
              name="carry_times"
              label="Quantidade"
              setValue={setValue}
              error={errors.carry_times}
            />
          </GridItem>
        )}

        <GridItem colSpan={12} my="3">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 7]}>
          <RadioGroup
            name="has_tracking_system"
            label="Possui algum sistema de rastreamento?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.has_tracking_system}
            onChange={e => {
              if (e === 'S') setTrackingSystem(true)
              else setTrackingSystem(false)
            }}
          />
        </GridItem>

        {TrackingSystem && (
          <GridItem colSpan={[12, 5]}>
            <Input name="tracking_system" label="Qual?" setValue={setValue} error={errors.tracking_system} />
          </GridItem>
        )}

        <GridItem colSpan={12} my="3">
          <Divider />
        </GridItem>

        <GridItem colSpan={[12, 7]}>
          <RadioGroup
            name="respond_for_crime_in_last_10_years"
            label="Respondeu ou responde a processo (s) crime (s) nos últimos 10 anos?"
            setValue={setValue}
            options={[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' },
            ]}
            initialValue="N"
            error={errors.respond_for_crime_in_last_10_years}
            onChange={e => {
              if (e === 'S') setCrimeProccessNumbers(true)
              else setCrimeProccessNumbers(false)
            }}
          />
        </GridItem>

        {crimeProccessNumbers && (
          <GridItem colSpan={[12, 5]}>
            <Input
              type="number"
              name="crime_process_numbers"
              label="Informar número do (s) processo (s) no CNJ (Conselho Nacional Justiça)"
              setValue={setValue}
              error={errors.crime_process_numbers}
            />
          </GridItem>
        )}
      </Grid>

      <HStack
        spacing="4"
        justifyContent="center"
        mt="6"
        pt="6"
        borderTopWidth="1px"
        borderTopColor="gray.200"
      >
        <Button leftIcon={<Icon as={RiArrowLeftLine} />} colorScheme="yellow" onClick={() => tabNav(0)}>
          Voltar
        </Button>
        <Button type="submit" leftIcon={<Icon as={RiAddLine} />} colorScheme="orange">
          Cadastrar
        </Button>
      </HStack>
    </>
  )
}
