import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaArrowRight } from 'react-icons/fa'
import { RiUser2Fill } from 'react-icons/ri'
import { Ability } from '../../../../components/ability'
import { Select } from '../../../../components/form/Select'
import { queryClient } from '../../../../config/react-query'
import { financialTitleStatusOptions } from '../../../../services/endpoints/freights/financial/getAllFinancialTitles'
import { usePutFreightExpense } from '../../../../services/endpoints/freights/financial/putFinancialTitle'

interface ForceStatusFinancialModalProps {
  freight_expense_id: string
  onCloseModal: () => void
}

export function ForceStatusFinancialModal({
  freight_expense_id,
  onCloseModal,
}: ForceStatusFinancialModalProps): JSX.Element {
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = usePutFreightExpense({
    onSuccess: () => {
      queryClient.invalidateQueries('freight')
      queryClient.invalidateQueries('financial-title')
      queryClient.invalidateQueries('list-all-financial-title-attachments')
      queryClient.invalidateQueries('attachments')
      queryClient.invalidateQueries('list-all-financial-title-history')
      queryClient.invalidateQueries('list-all-financial-title')
      onCloseModal()
    },
  })
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()
  const [status, setStatus] = useState<string>()
  const { setValue } = useForm({})

  const handleChangeStatus = async () => {
    if (status) {
      await mutateAsyncUpdate({
        id: freight_expense_id,
        status,
        paid: status === 'finished',
      })

      toast({
        title: 'Alteração realizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 8000,
      })

      onClose()
    }
  }

  useEffect(() => {
    setStatus(undefined)
  }, [isOpen])

  return (
    <Ability module="spotHub" action="show-force-status">
      <Button rightIcon={<RiUser2Fill />} onClick={onOpen} colorScheme="purple">
        Forçar status
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        closeOnOverlayClick={false}
        size="md"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Mudar status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid minChildWidth="300px" spacing={2}>
              <Select
                name="status"
                label="Status"
                setValue={setValue}
                onSelectOption={op => {
                  setStatus(op.value)
                }}
                options={financialTitleStatusOptions}
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              rightIcon={<FaArrowRight />}
              colorScheme="green"
              isLoading={isLoadingUpdate}
              isDisabled={!status}
              onClick={() => {
                handleChangeStatus()
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Ability>
  )
}
