import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { apiServer } from '../../../api'
import { ResponseListApiType } from '../../../types'
import FreightExpenseType from '../../../types/FreightExpenseType'
import { formattedTransporter } from '../../../utils/formattedTransporter'
import parseCurrency from '../../../utils/parseCurrency'
import { eventOptions } from './PostFinancialTitle'

export interface FormattedFreightExpenseType extends Omit<FreightExpenseType, 'paid'> {
  paid: string
  color_status: string
  valueFormatted: string
  payment_at_formatted: string
  created_at_formatted: string
  updated_at_formatted: string
}

export type GetFreightExpenseResponse = {
  total: number
  data: FormattedFreightExpenseType[]
}

export interface FilterFreightExpenseParams {
  page?: number
  per_page?: number
  freight_id?: string
  freight_number?: string
  user_id?: AutocompleteOption
  cte_number?: string
  name_recipient?: string
  document_recipient?: string
  paid?: boolean
  status?: string
  event?: string
  initial_date?: string
  final_date?: string
  unpaged?: boolean
  order_by_created_at?: boolean
  user_label?: string
}

export function formatEvent(event: string): string {
  const option = eventOptions.find(opt => opt.value === event)
  return option?.label || ''
}

export const financialTitleStatusOptions = [
  { label: 'Selecione', value: '' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Rejeitado', value: 'rejected' },
  { label: 'Cancelado', value: 'canceled' },
  {
    label: 'Aguardando Comprovação',
    value: 'awaiting_proof',
  },
  { label: 'Finalizado', value: 'finished' },
]

export function formatStatusFreightExpense(status: string): string {
  const option = financialTitleStatusOptions.find(opt => opt.value === status)
  return option?.label || ''
}

export function colorStatus(status: string): string {
  if (status === 'pending') return 'gray'
  if (status === 'rejected') return 'red'
  if (status === 'canceled') return 'yellow'
  if (status === 'finished') return 'green'
  if (status === 'awaiting_proof') return 'orange'
  return ''
}

export async function fetchAllFreightExpense(
  params: FilterFreightExpenseParams,
): Promise<GetFreightExpenseResponse> {
  const { data: res } = await apiServer.get<ResponseListApiType<FormattedFreightExpenseType[]>>(
    '/list-freight-expense',
    {
      params,
    },
  )

  const { data } = res
  const { total } = data

  const FreightExpense = data.data.map((expense: FormattedFreightExpenseType) => {
    return {
      ...expense,
      created_at_formatted: format(new Date(expense.created_at), 'dd/MM/yy - HH:mm'),
      updated_at_formatted: format(new Date(expense.updated_at), 'dd/MM/yy - HH:mm'),
      receipt_at: expense.receipt_at ? format(new Date(expense.receipt_at), "dd/MM/yy 'às' HH:mm") : '',
      payment_at_formatted: expense.payment_at
        ? format(new Date(expense.payment_at), "dd/MM/yyyy 'às' HH:mm")
        : '',
      transporter: formattedTransporter(expense.transporter),
      event: formatEvent(expense.event),
      status: formatStatusFreightExpense(expense.status),
      paid: expense.paid ? 'Sim' : 'Não',
      valueFormatted: parseCurrency(expense.value),
      color_status: colorStatus(expense.status),
    }
  })

  return { total, data: FreightExpense }
}

export function useGetAllFreightExpense(
  params: FilterFreightExpenseParams,
): UseQueryResult<GetFreightExpenseResponse> {
  return useQuery(['list-all-financial-title', params], () => fetchAllFreightExpense(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
