import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'
import { forEach } from 'lodash'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import { TextArea } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { usePutLoadingRequest } from '../../../../../services/endpoints/freights/loading-request/PutLoadingRequest'
import { useUploadLoadingRequestAttachments } from '../../../../../services/endpoints/freights/loading-request/UploadLoadingRequestAttachments'
import {
  LoadingRequestAttachmentTypeEnum,
  LoadingRequestStatusEnum,
} from '../../../../../services/types/EnumTypes'

type UploadLoadingRequestReceiptsModalProps = {
  isOpen: boolean
  onClose: () => void
  e_frete: boolean
}

type RequestParams = {
  id: string
}

type UploadReceiptsLoadingRequest = {
  loadingRequestReceipt: File
  advanceRequestReceipt?: File
  balanceRequestReceipt?: File
  payment_observation?: string
}

const UpdateLoadingRequestFormSchema = yup.object().shape({
  payment_observation: yup.string().when('e_frete', {
    is: true,
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
})

export function UploadLoadingRequestReceiptsModal({
  isOpen,
  onClose,
  e_frete,
}: UploadLoadingRequestReceiptsModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateLoadingRequestFormSchema),
  })
  const { id } = useParams<RequestParams>()

  const { errors } = formState

  const { mutateAsync: updateLoadingRequestStatus, isLoading } = usePutLoadingRequest({
    onSuccess: () => {
      toast({
        title: 'Solicitação de carregamento atualizada com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const {
    mutateAsync: uploadLoadingRequestAttachments,
    isLoading: isLoadingUploadLoadingRequestAttachments,
  } = useUploadLoadingRequestAttachments({
    onSuccess: () => {
      toast({
        title: 'Anexo enviado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUploadReceiptsLoadingRequest: SubmitHandler<UploadReceiptsLoadingRequest> = async data => {
    const uploadFormData = {
      status: LoadingRequestStatusEnum.PAID,
      payment_observation: data.payment_observation,
    }

    const receipts = [
      {
        attachment: data.loadingRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.RECEIPT,
      },
      {
        attachment: data.advanceRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.ADVANCE_RECEIPT,
      },
      {
        attachment: data.balanceRequestReceipt,
        type: LoadingRequestAttachmentTypeEnum.BALANCE_RECEIPT,
      },
    ]

    await updateLoadingRequestStatus({ id, data: uploadFormData })

    forEach(receipts, async receipt => {
      if (receipt && receipt.attachment) {
        const formData = new FormData()

        formData.append('loading_request_id', id)
        formData.append('type', receipt.type)
        formData.append('attachment', receipt.attachment)

        await uploadLoadingRequestAttachments({ data: formData })
      }
    })

    onClose()
  }

  useEffect(() => {
    setValue('e_frete', e_frete)
  }, [setValue, e_frete])

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Anexar comprovantes de pagamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUploadReceiptsLoadingRequest)} noValidate>
          <ModalBody>
            {e_frete && (
              <TextArea
                name="payment_observation"
                h="150"
                error={errors.payment_observation}
                label="Observação de pagamento"
                setValue={setValue}
              />
            )}

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="loadingRequestReceipt"
              label="Solicitação de carregamento"
            />

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="balanceRequestReceipt"
              label="Solicitação de saldo"
            />

            <DropzoneImageUpload
              setValue={setValue}
              displayMode="list"
              name="advanceRequestReceipt"
              label="Solicitação de adiantamento"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoading || isLoadingUploadLoadingRequestAttachments}
              isDisabled={isLoading || isLoadingUploadLoadingRequestAttachments}
            >
              Avançar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
