import { useQuery, UseQueryResult } from 'react-query'
import { AutocompleteOption } from '../../../components/form'
import { apiServer } from '../../api'
import { ClientBusinessGroup, ClientType, ResponseApiType, ResponseListApiType } from '../../types'

type GetClientsResponse = {
  total: number
  data: ClientType[]
}

interface FilterClientData {
  orderByName?: 'asc' | 'desc'
  nickname?: string
  name?: string
  page?: number
  per_page?: number
  clientBusinessGroupId?: string
}

async function fetchClients(params: FilterClientData): Promise<GetClientsResponse> {
  const { data } = await apiServer.get<ResponseApiType<GetClientsResponse>>('list-client', {
    params,
  })
  const { total } = data.data
  const clients = data.data.data

  return { total, data: clients }
}

export function useGetClients(params: FilterClientData): UseQueryResult<GetClientsResponse> {
  return useQuery(['clients', params], () => fetchClients(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}

export async function searchClientGroup(name: string): Promise<AutocompleteOption[]> {
  if (name) {
    const { data: res } = await apiServer.get<ResponseListApiType<ClientBusinessGroup[]>>(
      '/clients/client-business-group',
      {
        params: {
          name,
        },
      },
    )
    const { data: clientGroupData } = res

    return clientGroupData.data.map(clientGroup => {
      const label = clientGroup.name
      const value = clientGroup.id

      return { label, value }
    })
  }
  return []
}
