import { Box, SlideFade, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export interface FilterProps {
  initialShowFilter?: boolean
  showFilter?: boolean
  children: React.ReactNode
}

const Filter = ({ initialShowFilter, showFilter, children }: FilterProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  return (
    <SlideFade in={showFilter || initialShowFilter}>
      <Box
        bg={bg}
        p="4"
        py="4"
        mb="4"
        borderRadius="md"
        shadow="md"
        animation="slideIn 0.5s ease-in-out"
        display={showFilter ? 'block' : 'none'}
      >
        {children}
      </Box>
    </SlideFade>
  )
}

export default Filter
