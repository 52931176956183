import { Heading, SimpleGrid, WrapItem, Flex, Tooltip, Link, Text } from '@chakra-ui/react'
import { LinkOrUnlinkOwner } from '../../../components/vehicles/LinkOrUnlinkOwner'
import { useGetOwner } from '../../../services/endpoints/owners/getOwner'
import { useGetTrailer } from '../../../services/endpoints/trailers/getTrailer'

interface TrailerOwnerInfosProps {
  trailer_id: string
  motorist_id?: string
}

export function TrailerOwnerInfos({ trailer_id, motorist_id }: TrailerOwnerInfosProps): JSX.Element {
  const { data: Trailer } = useGetTrailer(trailer_id)
  const { data: owner } = useGetOwner(String(Trailer?.owner_id))

  return (
    <Flex justifyContent="center" direction="column" flex={1} mt={4}>
      {trailer_id && (
        <>
          {owner && (
            <Heading size="md" my="3">
              Proprietário
            </Heading>
          )}
          <SimpleGrid minChildWidth="200px" spacing="3">
            {owner && (
              <>
                <Tooltip label="Ir para pagina do proprietário" hasArrow>
                  <WrapItem alignItems="center" as={Link} href={`/owners/show/${owner.id}`}>
                    <Text fontWeight="bold" color="gray.400">
                      Nome:
                    </Text>
                    <Text fontWeight="medium" ml="1">
                      {owner.name}
                    </Text>
                  </WrapItem>
                </Tooltip>
                <WrapItem alignItems="center">
                  <Text fontWeight="bold" color="gray.400">
                    Telefone:
                  </Text>
                  <Text fontWeight="medium" ml="1">
                    {owner?.phone}
                  </Text>
                </WrapItem>
              </>
            )}
            <WrapItem>
              <LinkOrUnlinkOwner motorist_id={motorist_id} owner={owner} trailer_id={trailer_id} />
            </WrapItem>
          </SimpleGrid>
        </>
      )}
    </Flex>
  )
}
