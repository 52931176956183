/* eslint-disable no-unused-vars */
import { ChangeEventHandler, useRef, useState } from 'react'
import { Flex, Icon, Input, useColorModeValue } from '@chakra-ui/react'
import { RiSearchLine } from 'react-icons/ri'
import { debounce } from 'lodash'

export function HeaderSearchBox(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [text, setText] = useState('')

  const delayedSearch = useRef(
    debounce((value: string) => {
      setText(value)
    }, 1000),
  ).current

  const handleSearch: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target
    delayedSearch(value)
  }

  return (
    <Flex
      as="label"
      flex="1"
      py="4"
      px="8"
      ml="7"
      maxW={400}
      alignSelf="center"
      position="relative"
      bg={bg}
      borderRadius="full"
      shadow="sm"
    >
      <Input
        variant="unstyled"
        px="4"
        mr="4"
        onChange={handleSearch}
        placeholder="Buscar na plataforma"
        _placeholder={{ color: 'gray.400' }}
      />
      <Icon as={RiSearchLine} fontSize="20" />
    </Flex>
  )
}
