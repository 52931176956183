import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { format, parse } from 'date-fns'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowRightDoubleFill, RiCalendarEventLine, RiEqualizerLine, RiMenuLine } from 'react-icons/ri'
import { useAuth } from '../../contexts/AuthContext'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import useHasFilterAppliedCount from '../../hooks/useHasFilterAppliedCount'
import { Logo } from '../../layout/Header/Logo'
import { Profile } from '../../layout/Header/Profile'
import { FiltersQuotationsParams } from '../../services/endpoints/freights'
import { getUserSales } from '../../services/endpoints/users/getUserSales'
import { Autocomplete, AutocompleteOption, Switch } from '../form'
import OperatorAndClientSelector from '../OperatorAndClientSelector'
import { SearchBox } from '../SearchBox'

const formateDateToValue = (date: string | Date) => {
  return format(new Date(date || format(new Date(), 'yyyy-MM-dd')), 'yyyy-MM-dd')
}

interface KanbanFiltersProps {
  setFilters: React.Dispatch<React.SetStateAction<FiltersQuotationsParams | undefined>>
  isLoading?: boolean
  filters?: FiltersQuotationsParams
}

const KanbanFiltersQuotation = ({ setFilters, isLoading, filters = {} }: KanbanFiltersProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.900')
  const {
    storageUser,
    setUserIdToListFreights,
    getUserIdToListFreights,
    storageSeller,
    setSellerIdToListFreights,
    getSellerIdToListFreights,
  } = useAuth()
  const { onOpen: openMenu } = useSidebarDrawer()

  const { isOpen: isOpenFilter, onOpen: onOpenFilter, onClose: onCloseFilter } = useDisclosure()
  const { isOpen: isOpenPeriod, onOpen: onOpenPeriod, onClose: onClosePeriod } = useDisclosure()

  const btnRefFilter = useRef()

  const [generalFreights, setGeneralFreights] = useState(false)
  const [filtersValues, setFiltersValues] = useState<FiltersQuotationsParams>({
    user_id: storageUser?.value || undefined,
    initial_date: new Date(),
    final_date: new Date(),
    quotation_number: undefined,
  })

  const { setValue } = useForm({})

  const handleSetCurrentClient = (clientSelected: AutocompleteOption | undefined) => {
    setFilters(prev => ({ ...prev, client_id: clientSelected?.value.toString() || undefined }))
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'initial' | 'final') => {
    const dateString = event.target.value
    const newDate = parse(dateString, 'yyyy-MM-dd', new Date())

    setFiltersValues({
      ...filtersValues,
      [type === 'initial' ? 'initial_date' : 'final_date']: newDate,
    })
  }

  const handleSetTodayPeriod = () => {
    const today = new Date()
    const formattedDate = format(today, 'yyyy-MM-dd')

    setFiltersValues({ initial_date: today, final_date: today })
    setFilters(prev => ({ ...prev, initial_date: formattedDate, final_date: formattedDate }))
  }

  const handleSetFilter = (key: keyof FiltersQuotationsParams, value: string | boolean | undefined) => {
    setFiltersValues({ ...filtersValues, [key]: value === '' ? undefined : value })
  }

  const handleSetGeneral = (value: boolean) => {
    setGeneralFreights(value)
  }

  const handleApplyFilters = () => {
    setFilters(prev => ({
      ...prev,
      initial_date: generalFreights ? undefined : format(filtersValues?.initial_date as Date, 'yyyy-MM-dd'),
      final_date: generalFreights ? undefined : format(filtersValues?.final_date as Date, 'yyyy-MM-dd'),
      quotation_number: filtersValues.quotation_number,
      seller_id: filtersValues.seller_id,
    }))
  }

  const hasFilterAppliedCount = useHasFilterAppliedCount<FiltersQuotationsParams>(filters)

  return (
    <Flex align="center" justifyContent="space-between" gridGap="4">
      <Flex>
        <IconButton
          aria-label="Open navigation"
          icon={<Icon as={RiMenuLine} />}
          fontSize="24"
          variant="link"
          onClick={openMenu}
        />

        <Logo />
      </Flex>
      <Flex gridGap="2" align="center">
        <Flex gridGap="2" align="center">
          <OperatorAndClientSelector
            handleSetCurrentUser={userSelected => {
              setUserIdToListFreights(userSelected)
              getUserIdToListFreights()
            }}
            handleSetCurrentClient={clientSelected => {
              handleSetCurrentClient(clientSelected)
            }}
            userSelected={storageUser}
            setValue={setValue}
          />
          <Flex gridGap="2" align="center" display={{ base: 'none', md: 'flex' }}>
            <Divider orientation="vertical" gridGap="2" h="38px" borderColor="gray.400" />
            <Box>
              <Flex align="center" gridGap="2">
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    type="date"
                    maxW="140px"
                    name="initial_date"
                    onChange={e => {
                      handleDateChange(e, 'initial')
                    }}
                    value={formateDateToValue(filtersValues.initial_date || '')}
                    bg={bg}
                  />
                </Flex>
                <Text fontSize="sm" color="gray.500" fontWeight="bold">
                  <RiArrowRightDoubleFill fontSize="20px" />
                </Text>
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    type="date"
                    maxW="140px"
                    name="initial_date"
                    onChange={e => handleDateChange(e, 'final')}
                    value={formateDateToValue(filtersValues.final_date || '')}
                    bg={bg}
                  />
                </Flex>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  size="md"
                  onClick={handleApplyFilters}
                  disabled={!filtersValues.initial_date || !filtersValues.final_date}
                  isLoading={isLoading}
                >
                  Aplicar
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="orange"
                  size="md"
                  onClick={handleSetTodayPeriod}
                >
                  Hoje
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Box display={{ base: 'block', md: 'none' }}>
          <Button
            variant="outline"
            colorScheme="gray"
            size="md"
            onClick={onOpenPeriod}
            borderColor="gray.400"
          >
            <RiCalendarEventLine />
          </Button>
        </Box>
        <Divider orientation="vertical" gridGap="2" h="38px" borderColor="gray.400" />
        <Button
          variant="outline"
          colorScheme={hasFilterAppliedCount > 0 ? 'orange' : 'gray'}
          size="md"
          onClick={onOpenFilter}
          borderColor={hasFilterAppliedCount > 0 ? 'orange.500' : 'gray.400'}
        >
          <Text fontWeight="bold" display={{ base: 'none', md: 'block' }}>
            Filtros
          </Text>
          <Box display={{ base: 'block', md: 'none' }}>
            <RiEqualizerLine />
          </Box>
          {hasFilterAppliedCount > 0 ? `(${hasFilterAppliedCount})` : ''}
        </Button>

        <Drawer isOpen={isOpenPeriod} placement="right" size="sm" onClose={onClosePeriod}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody pt="8" display="flex" flexDirection="column" gridGap="2">
              <Flex flexDir="column" gridGap="2">
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    w="100%"
                    type="date"
                    name="initial_date"
                    onChange={e => handleDateChange(e, 'initial')}
                    value={formateDateToValue(filtersValues.initial_date || '')}
                    bg={bg}
                  />
                </Flex>
                <Flex align="center">
                  <Input
                    fontSize="sm"
                    size="md"
                    w="100%"
                    type="date"
                    name="initial_date"
                    onChange={e => handleDateChange(e, 'final')}
                    value={formateDateToValue(filtersValues.final_date || '')}
                    bg={bg}
                  />
                </Flex>
              </Flex>
            </DrawerBody>
            <DrawerFooter display="flex" justifyContent="space-between" gridGap="2">
              <Button variant="outline" size="md" mr="2" onClick={onClosePeriod}>
                Fechar
              </Button>
              <Flex gridGap="2">
                <Button variant="outline" colorScheme="orange" size="md" onClick={handleSetTodayPeriod}>
                  Hoje
                </Button>
                <Button
                  variant="solid"
                  colorScheme="orange"
                  size="md"
                  onClick={handleApplyFilters}
                  disabled={!filtersValues.initial_date || !filtersValues.final_date}
                >
                  Aplicar período
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <Drawer
          isOpen={isOpenFilter}
          placement="right"
          size="md"
          onClose={onCloseFilter}
          finalFocusRef={btnRefFilter as any}
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody pt="8" display="flex" flexDirection="column" gridGap="2" tabIndex={-1}>
              <Autocomplete
                name="seller_id"
                placeholder="Filtrar por vendedor"
                setValue={setValue}
                loadOptions={getUserSales}
                initialValue={storageSeller}
                defaultValue={storageSeller?.label}
                onSelectOption={userSelected => {
                  if (userSelected.value) {
                    setSellerIdToListFreights(userSelected)
                    getSellerIdToListFreights()
                    handleSetFilter('seller_id', String(userSelected.value))
                  } else {
                    setSellerIdToListFreights()
                    getSellerIdToListFreights()
                  }
                }}
                clearState={() => {
                  handleSetFilter('seller_id', undefined)
                  setSellerIdToListFreights(undefined)
                  getSellerIdToListFreights()
                }}
              />

              <SearchBox
                tabIndex={-1}
                defaultValue={filtersValues.quotation_number || ''}
                onChange={async v => {
                  handleSetFilter('quotation_number', v)
                }}
                clearState={() => {
                  handleSetFilter('quotation_number', '')
                }}
                placeholder="Filtrar pelo número"
              />
              <Divider my="2" />
              <Switch
                tabIndex={-1}
                onChange={e => {
                  handleSetGeneral(e.target.checked)
                }}
                defaultChecked={generalFreights}
                label="Geral"
                setValue={setValue}
                name="all with and without ctes"
              />
            </DrawerBody>

            <DrawerFooter display="flex" flexDir="column" gridGap="2">
              <Flex justifyContent="space-between" w="100%">
                <Button variant="outline" mr={3} onClick={onCloseFilter} isDisabled={isLoading}>
                  Fechar
                </Button>
                <Button
                  colorScheme="orange"
                  onClick={handleApplyFilters}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Aplicar Filtros
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Profile />
      </Flex>
    </Flex>
  )
}

export default KanbanFiltersQuotation
