import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Heading,
  Button,
  Icon,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useEffect } from 'react'
import { RiAttachmentLine, RiDownload2Line } from 'react-icons/ri'
import { useGetMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { MotoristAttachmentType } from '../../../../../services/types'
import { AddAttachmentModal } from './AddAttachmentModal'

interface MotoristAttachmentsProps {
  motorist_id: string
  bg: string
}

export function MotoristAttachments({ motorist_id, bg }: MotoristAttachmentsProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [attachments, setAttachments] = useState<MotoristAttachmentType[]>()

  const { data: fetchedMotorist } = useGetMotorist(motorist_id)

  useEffect(() => {
    if (fetchedMotorist && fetchedMotorist.attachments) {
      setAttachments(fetchedMotorist.attachments)
    }
  }, [fetchedMotorist])

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Flex mb={['6', '8']} justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Anexos
        </Heading>

        <Button size="sm" colorScheme="orange" leftIcon={<Icon as={RiAttachmentLine} />} onClick={onOpen}>
          Adicionar
        </Button>
      </Flex>

      <Table d={['block', 'block', 'table']} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Tipo</Th>
            <Th>Descrição</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {attachments &&
            attachments.length > 0 &&
            attachments.map(attachment => (
              <Tr key={attachment.id}>
                <Td>{attachment.type}</Td>
                <Td>{attachment.name}</Td>
                <Td textAlign="right">
                  {attachment.attachment_file_url && (
                    <Button
                      as={Link}
                      href={attachment.attachment_file_url}
                      target="_blank"
                      rel="noreferrer"
                      size="sm"
                      variant="outline"
                      colorScheme="orange"
                      leftIcon={<Icon as={RiDownload2Line} />}
                    >
                      Baixar
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      <AddAttachmentModal
        motorist_id={motorist_id}
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
      />
    </Box>
  )
}
