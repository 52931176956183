import { RefObject, useEffect } from 'react'

function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | KeyboardEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | KeyboardEvent) => {
      // Verifica se o clique foi fora do elemento referenciado
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event)
      }
    }

    // Adiciona o listener de eventos para cliques e pressionamento de teclas
    document.addEventListener('mousedown', listener as EventListener)
    document.addEventListener('touchstart', listener as EventListener)

    // Remove o listener ao desmontar o componente
    return () => {
      document.removeEventListener('mousedown', listener as EventListener)
      document.removeEventListener('touchstart', listener as EventListener)
    }
  }, [ref, handler])
}

export default useClickOutside
