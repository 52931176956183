import {
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FreightType } from '../../../services/types'

type ShowFreightInfoModalProps = {
  isOpen: boolean
  onClose: () => void
  freight: FreightType
}

export function ShowFreightInfoModal({ isOpen, onClose, freight }: ShowFreightInfoModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Endereço de entrega e coleta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap="4">
            <GridItem colSpan={[12, 4]}>
              <Flex flexDirection="column">
                <Text color="gray.400">Endereço de coleta: </Text>
                <Text fontWeight="medium">{freight.collect_complement}</Text>
              </Flex>
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <Flex flexDirection="column">
                <Text color="gray.400">Endereço de entrega: </Text>
                <Text fontWeight="medium">{freight.delivery_complement}</Text>
              </Flex>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
