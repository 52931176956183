import {
  Button,
  Collapse,
  Flex,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { RiArrowDownSLine, RiArrowUpSLine, RiAttachmentLine, RiErrorWarningLine } from 'react-icons/ri'
import { DateStatus } from '.'
import { SetNextStatusModal } from '../../../pages/freights/show/cards/selected-motorist/SetNextStatusModal'
import { FreightAttachmentsTypeEnum } from '../../../services/types/EnumTypes'
import { useAppSelector } from '../../../store'
import { AccordionTables } from './accordionTables/AccordionTables'

const phaseStatus = 'in_origin'

export function InOriginPhase(): JSX.Element {
  const { isOpen: showMoreInfo, onToggle: toggleMoreInfo } = useDisclosure()
  const { freight, statusHistory, isLoading } = useAppSelector(state => state.freightSlice)
  const filteredAttachments = freight?.attachments?.filter(
    attach => attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.DEFAULT,
  )
  const filteredOccurences = freight?.attachments?.filter(
    attach =>
      attach?.freight_status === phaseStatus && attach?.type === FreightAttachmentsTypeEnum.OCCURRENCE,
  )

  const history = statusHistory?.find(statusOfPhase => statusOfPhase.status === 'collecting')

  return (
    <Flex justifyContent="space-between" direction="column" mb="4">
      <Flex direction="row">
        <Text
          onClick={toggleMoreInfo}
          cursor="pointer"
          size="sm"
          color={
            ['collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
              String(freight?.status),
            )
              ? 'green.500'
              : 'gray.400'
          }
          fontWeight="bold"
          fontSize="sm"
        >
          <Button
            color="white"
            backgroundColor={
              ['collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
                String(freight?.status),
              )
                ? 'green.500'
                : 'gray.400'
            }
            borderRadius="100%"
            size="xs"
            mr="4"
            p="0"
          >
            1
          </Button>
          Saiu para coleta
        </Text>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          direction={['column', 'row']}
          gridGap="1"
          ml="2"
          mb={['-5', '0']}
        >
          {isLoading
            ? freight?.status === 'in_origin' && <Spinner size="md" color="orange" />
            : filteredAttachments &&
              filteredAttachments.length > 0 && (
                <Tag size="sm" backgroundColor="orange" borderRadius="full">
                  <TagLeftIcon as={RiAttachmentLine} />
                  <TagLabel>{filteredAttachments.length}</TagLabel>
                </Tag>
              )}
          {isLoading ? (
            <Spinner size="md" color="red.400" />
          ) : (
            filteredOccurences &&
            filteredOccurences.length > 0 && (
              <Tag size="sm" mx="1" borderRadius="full" backgroundColor="red.400">
                <TagLeftIcon as={RiErrorWarningLine} />
                <TagLabel>{filteredOccurences.length}</TagLabel>
              </Tag>
            )
          )}
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb="-3" mt="-2">
        <Flex alignItems="center" direction={['column', 'row', 'row']} ml="10" my="2" gridGap="2">
          {[
            'in_origin',
            'collecting',
            'ready',
            'on_road',
            'in_destination',
            'delivered',
            'finished',
          ].includes(String(freight?.status)) && (
            <>
              {['collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'].includes(
                String(freight?.status),
              ) && (
                <Flex justifyContent="flex-start">
                  {isLoading ? (
                    freight?.status === 'in_origin' && <Spinner size="md" />
                  ) : (
                    <DateStatus freight={freight} history={history} />
                  )}
                </Flex>
              )}
            </>
          )}
          {freight?.status === 'in_origin' && <SetNextStatusModal />}
        </Flex>

        <Flex mt="-7">
          <IconButton
            onClick={toggleMoreInfo}
            variant="ghost"
            size="sm"
            aria-label="More info"
            icon={!showMoreInfo ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
          />
        </Flex>
      </Flex>

      <Collapse in={showMoreInfo} animateOpacity>
        <AccordionTables showMoreInfo={showMoreInfo} toggleMoreInfo={toggleMoreInfo} status={phaseStatus} />
      </Collapse>
    </Flex>
  )
}
