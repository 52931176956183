/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, UseMutationOptions } from 'react-query'
import { QualpCityType } from '../../../pages/pricing/route-planner/show/components/LocationManager'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface IPostPlanRouteInput {
  optimized_route: boolean
  optimized_route_destination: string
  calculate_return: boolean
  alternative_routes: string
  avoid_locations: boolean
  avoid_locations_key: string
  type_route: string
  vehicle_category: string
  axes_number: string
  cargo_type: string
  origin: QualpCityType
  destination: QualpCityType
  steps: any[]
  freight_category?: string
}

export interface IPostPlanRouteApiInput {
  locations: QualpCityType[]
  alternative_routes: string
  avoid_locations: boolean
  avoid_locations_key: string
  axes_number: string
  calculate_return: boolean
  cargo_type: string
  destination: QualpCityType
  origin: QualpCityType
  optimized_route: false
  optimized_route_destination: string
  steps: any[]
  type_route: string
  vehicle_category: string
}

export type QualpRoute = {
  distance: string
  distance_unpaved: string
  duration: string
  start_address: string
  end_address: string
  start_coordinate: string
  end_coordinate: string
  rotograma?: any
  tolls?: any[]
  freight_table?: any
  scales?: any[]
  polyline: string
  simplified_polyline: string
}

export type QualpDataResponseType = {
  distance: string
  unpaved_distance: {
    km: string
    percentage: string
  }
  duration: string
  start_address: string
  end_address: string
  start_coordinate: string
  end_coordinate: string
  tolls: Array<{
    id_api: string
    name: string
    tariff: Record<number, number>
    special_toll: boolean
  }>
  scales: Array<{
    id: number
    lat: number
    lng: number
    name: string
  }>
  qualp_link: string
  locations: string[]
  transaction_id: number
  selected_router: string
  calculate_return: boolean
  optimize_route: boolean
  routes: QualpRoute[]
}

type IPostPlanRouteResponse = {
  message: string
  data: QualpDataResponseType
}

async function postPlanRoute(data: IPostPlanRouteInput) {
  const locations = [data.origin, data.destination]
  data.steps.forEach(step => {
    locations.push({
      cityName: step.city,
      lat: step.lat,
      lng: step.lng,
    })
  })
  Object.assign(data, { locations })
  const { data: response } = await apiServer.post<ResponseApiType<IPostPlanRouteResponse>>(
    '/plain-route',
    data,
  )
  return response.data
}

export function usePostPlanRoute(
  options?: UseMutationOptions<IPostPlanRouteResponse, unknown, IPostPlanRouteInput, unknown>,
) {
  return useMutation<IPostPlanRouteResponse, unknown, IPostPlanRouteInput>(
    async (data: IPostPlanRouteInput) => postPlanRoute(data),
    options,
  )
}
