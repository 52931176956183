/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Icon, Tooltip, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { RiFileExcel2Fill, RiDownloadLine } from 'react-icons/ri'
import * as XLSX from 'xlsx'
import { toastError } from '../../config/error/toastError'
import { useAuth } from '../../contexts/AuthContext'

const bookType: XLSX.BookType = 'xls'

interface ReportToExcelProps {
  columnsTitle: string[]
  documentTitle: string
  onGenerateReport: () => Promise<any[][]>
  isLoading: boolean
}
export function ReportToExcel({
  columnsTitle,
  documentTitle,
  onGenerateReport,
  isLoading,
}: ReportToExcelProps): JSX.Element {
  const { user } = useAuth()
  const [isGenerating, setIsGenerating] = useState(false)
  const toast = useToast()
  let wb = XLSX.utils.book_new()
  const columnsTitleUpperCase = columnsTitle.map(i => i.toUpperCase())

  const downloadXLSX = async () => {
    setIsGenerating(true)
    try {
      const columns = await onGenerateReport()
      wb.Props = {
        Title: documentTitle,
        Subject: 'Relatório',
        Author: user.name,
        CreatedDate: new Date(),
      }

      wb.SheetNames.push('Relatório 01')

      const ws = XLSX.utils.aoa_to_sheet([columnsTitleUpperCase, ...columns], {
        cellDates: true,
        dateNF: 'dd/mm/yyyy',
      })
      wb.Sheets['Relatório 01'] = ws

      XLSX.writeFile(wb, `${documentTitle}.${bookType}`, {
        bookType,
        type: 'binary',
      })

      wb = XLSX.utils.book_new()
    } catch (error) {
      toastError({ toast, error })
    } finally {
      setIsGenerating(false)
    }
  }

  return (
    <>
      <Tooltip label={`Baixar ${documentTitle}`}>
        <Flex>
          <Button
            onClick={downloadXLSX}
            isDisabled={isGenerating}
            isLoading={isLoading}
            leftIcon={<Icon as={RiFileExcel2Fill} />}
            rightIcon={<Icon as={RiDownloadLine} />}
            colorScheme="green"
          >
            {documentTitle}
          </Button>
        </Flex>
      </Tooltip>
    </>
  )
}
