import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { TrailerType } from '../../types/TrailerType'

type Input = Partial<TrailerType & { disable_entities_block?: boolean }>
export async function updateTrailer({
  id,
  ...data
}: Input): Promise<AxiosResponse<ResponseApiType<TrailerType>>> {
  return apiServer.put(`/update-trailer/${id}`, { ...data })
}

export function usePutTrailer(
  options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>,
): UseMutationResult<AxiosResponse<ResponseApiType<TrailerType>>, unknown, Input, unknown> {
  return useMutation(async (data: Input) => updateTrailer(data), options)
}
