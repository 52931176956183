/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Flex, Heading, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { RiMapPin2Fill, RiMapPin2Line } from 'react-icons/ri'
import { QualpCityType } from './LocationManager'

type RouteDiretionProps = {
  origin: QualpCityType
  destination: QualpCityType
}

const RouteDiretion = ({ origin, destination }: RouteDiretionProps): JSX.Element => {
  return (
    <Flex alignItems="center" mb={4}>
      <Box>
        <Heading
          size="sm"
          display="flex"
          fontSize="sm"
          gridGap="2"
          color="blue.500"
          textTransform="uppercase"
        >
          <RiMapPin2Fill /> Origem
        </Heading>
        <Text as="span" fontWeight="bold" color={useColorModeValue('gray.700', 'gray.200')}>
          {origin.cityName}
        </Text>
      </Box>
      <Box px={8}>
        <Icon as={FaArrowRight} color="gray.500" mx={2} />
      </Box>
      <Box>
        <Heading
          size="sm"
          display="flex"
          fontSize="sm"
          gridGap="2"
          color="green.500"
          textTransform="uppercase"
        >
          <RiMapPin2Line /> Destino
        </Heading>
        <Text as="span" fontWeight="bold" color={useColorModeValue('gray.700', 'gray.200')}>
          {destination.cityName}
        </Text>
      </Box>
    </Flex>
  )
}

export default RouteDiretion
