import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType, ResponseOcrCnh } from '../../../types'

export function usePostDriversLicenseInformations(
  options?: UseMutationOptions<ResponseOcrCnh, Error, string, unknown>,
): UseMutationResult<ResponseOcrCnh, Error, string> {
  return useMutation<ResponseOcrCnh, Error, string>(async (cnhImage: string) => {
    const { data: response } = await apiServer.post<ResponseApiType<ResponseOcrCnh>>('/ocr/drivers-license', {
      cnh_base64_or_url: cnhImage,
    })
    return response.data
  }, options)
}
