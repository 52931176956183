import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine, RiDirectionLine } from 'react-icons/ri'
import * as yup from 'yup'
import { InputNumberMask } from '../../../components/form/InputNumberMask'
import { SelectOption } from '../../../components/form/types/SelectOption'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../../services/api'
import { FormattedQuotation } from '../../../services/endpoints/freights'

interface changeStatusProps {
  isOpen: boolean
  onClose: () => void
  quotation: FormattedQuotation
  change_status?: SelectOption
}

interface SetNextStatusFormData {
  // should_notificate: boolean;
  suggested_price: number
}

export function ChangeStatusForm({
  isOpen,
  onClose,
  quotation,
  change_status,
}: changeStatusProps): JSX.Element {
  const toast = useToast()
  const ChangeStatusFormSchema = yup.object().shape({
    suggested_price: yup.number().when(() => {
      if (change_status?.value === 'in_quotation') {
        return yup.number().min(1, 'Não pode ser zero').required('Campo obrigatório')
      }
      return yup.number()
    }),
  })

  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(ChangeStatusFormSchema),
  })
  const { errors } = formState
  const handleUpdateQuotation: SubmitHandler<SetNextStatusFormData> = async data => {
    try {
      await apiServer.put(`quotations/${quotation.id}`, {
        status: change_status?.value,
        suggested_price: data.suggested_price,
      })
      onClose()
      toast({
        title: 'Status alterado com sucesso!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
      queryClient.invalidateQueries(`all-quotations`)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleUpdateQuotation)}>
          <ModalHeader>Alterar status da cotação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex align="center">
              O status será alterado para
              <Tag size="lg" borderRadius="full" colorScheme="green" ml="4">
                <TagLeftIcon as={RiDirectionLine} />
                <TagLabel>{change_status?.label}</TagLabel>
              </Tag>
            </Flex>
            <Stack spacing="6" mt="6">
              {change_status?.value === 'in_quotation' && (
                <InputNumberMask
                  isRequired
                  name="suggested_price"
                  label="Valor a ofertar (fretebras)"
                  isCashBRL
                  error={errors.suggested_price}
                  setValue={setValue}
                  initialValue={Number(quotation.suggested_price)}
                />
              )}
              {/* <Switch
                name="should_notificate"
                label="Enviar notificação"
                setValue={setValue}
                error={errors.should_notificate}
              /> */}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              leftIcon={<Icon as={RiCheckLine} />}
              isLoading={formState.isSubmitting}
            >
              Alterar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
