import { useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../layout'
import { QuotationInfo } from './cards/quotation-info'

interface RequestParams {
  quotation_id: string
}

export function ShowQuotation(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { quotation_id } = useParams<RequestParams>()

  return (
    <Layout>
      <QuotationInfo quotation_id={quotation_id} bg={bg} />
    </Layout>
  )
}
