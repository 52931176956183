import { Button, Flex, Stack, useColorModeValue, Image, useToast, Link } from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Input } from '../components/form/Input'
import logoLightImg from '../assets/logo-light.png'
import logoDarkImg from '../assets/logo-dark.png'
import { useAuth } from '../contexts/AuthContext'
import { toastError } from '../config/error/toastError'
import { InputPassword } from '../components/form/InputPassword'

interface SignInFormData {
  email: string
  password: string
}

const signInFormSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: yup.string().required('Senha é obrigatória'),
})

export function SignIn(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const logoSrc = useColorModeValue(logoLightImg, logoDarkImg)
  const toast = useToast()
  const history = useHistory()
  const { signIn } = useAuth()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(signInFormSchema),
  })
  const { errors } = formState

  const handleSignIn: SubmitHandler<SignInFormData> = async data => {
    try {
      await signIn(data)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <Flex direction="column" w="100%" h="100vh" align="center" justify="center" p="4">
      <Image src={logoSrc} alt="SpotX Logo" w={120} mb="8" />
      <Flex
        as="form"
        w="100%"
        maxW={360}
        bg={bg}
        shadow="md"
        p="8"
        borderRadius={8}
        direction="column"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Stack spacing="4">
          <Input name="email" type="email" label="E-mail" setValue={setValue} error={errors.email} />
          <InputPassword name="password" label="Senha" setValue={setValue} error={errors.password} />
        </Stack>

        <Button type="submit" mt="6" colorScheme="orange" size="lg" isLoading={formState.isSubmitting}>
          Entrar
        </Button>

        <Link
          to="/recover-password"
          mt="1.5"
          align="left"
          color="orange.500"
          fontSize="sm"
          onClick={() => {
            history.push('/send-recover-link')
          }}
        >
          Esqueci minha senha
        </Link>
      </Flex>
    </Flex>
  )
}
