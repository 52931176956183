import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  Text,
  useToast,
} from '@chakra-ui/react'

import { format } from 'date-fns-tz'
import { RiMapPinLine } from 'react-icons/ri'
import { ListManualMonitoring } from '../store/slices/freightSlice'
import { apiServer } from '../services/api'
import { FormattedOneFreight } from '../services/endpoints/freights'
import { toastError } from '../config/error/toastError'

type Props = {
  freight: FormattedOneFreight
  freight_distance_total: number
}

export function PopoverWaypoints({ freight_distance_total, freight }: Props): JSX.Element {
  const toast = useToast()
  const [listManualMonitoring, setListManualMonitoring] = useState<ListManualMonitoring[]>([])
  async function searchListManualMonitoring(freight_id: string) {
    await apiServer
      .get(`/list-manual-monitoring?freight_id=${freight_id}`)
      .then(res => {
        const { data } = res.data
        setListManualMonitoring(data as ListManualMonitoring[])

        return data
      })
      .catch(error => {
        toastError({ toast, error })
      })
  }

  useEffect(() => {
    searchListManualMonitoring(freight.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freight])
  return (
    <>
      {listManualMonitoring?.length > 0 && (
        <Box height="100%">
          <Popover>
            <PopoverTrigger>
              <Button rightIcon={<RiMapPinLine />} colorScheme="green">
                Histórico de localização
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontWeight="bold" fontSize={['md', 'md', 'lg']}>
                <Text mt={5}>
                  Foi percorrido {listManualMonitoring[0].travelled_distance || 0} de{' '}
                  {freight_distance_total || 0} km
                </Text>
              </PopoverHeader>
              <PopoverBody overflowY="auto">
                {listManualMonitoring.map(item => (
                  <Box fontSize={['xs', 'sm', 'sm']} key={item.id} maxH="60vh">
                    Cidade: <b>{item.city.name}</b> percorreu <b>{item.travelled_distance || 0}</b> de{' '}
                    <b>{freight_distance_total || 0} Km</b> em{' '}
                    <b>
                      {format(new Date(item.created_at), 'dd/MM/yyyy', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </b>
                    <Divider my={2} />
                  </Box>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </>
  )
}
