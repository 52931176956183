import { CteIssuanceRequestStatusEnum } from '../types/EnumTypes'

export function formatCteIssuanceRequestStatus(status: CteIssuanceRequestStatusEnum): string {
  const loadingRequestStatus = {
    awaiting_analysis: 'Aguardando análise',
    rejected: 'Reprovado',
    awaiting_issuance_cte: 'Aguardando emição do CTe',
    cte_issued: 'CTe emitido',
    cte_return_to_motorist: 'CTe retornado ao motorista',
    awaiting_advance_request: 'Aguardando solic. de adiantamento',
    advance_request_ongoing: 'Solic. de adiantamento em andamento',
    advance_request_finished: 'Solic. de adiantamento finalizada',
    finished: 'Finalizado',
  }

  return loadingRequestStatus[status]
}
