import React from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { UseFormSetValue, FieldValues } from 'react-hook-form'
import { Select } from '../../../../../components/form'

const truckOptions = [
  { value: '2', label: 'Caminhão leve, ônibus, caminhão-trator e furgão (2 eixos, rodagem dupla)' },
  { value: '3', label: 'Automóvel e caminhonete com semirreboque (3 eixos, rodagem simples)' },
  { value: '4', label: 'Caminhão, caminhão-trator com semirreboque e ônibus (3 eixos, rodagem dupla)' },
  { value: '5', label: 'Automóvel e caminhonete com reboque (4 eixos, rodagem simples)' },
  { value: '6', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (4 eixos, rodagem dupla)' },
  { value: '7', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (5 eixos, rodagem dupla)' },
  { value: '8', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (6 eixos, rodagem dupla)' },
  { value: '9', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (7 eixos, rodagem dupla)' },
  { value: '10', label: 'Caminhão com reboque, caminhão-trator com semi-reboque (8 eixos, rodagem dupla)' },
]

interface TruckTypeSelectProps {
  value?: number
  setValue: UseFormSetValue<FieldValues>
  setTruckCategory: (event: React.ChangeEvent<HTMLSelectElement>) => void
  setAxesNumber: (axes: number) => void
  errors?: any
}

export const TruckTypeSelect = ({
  value,
  setTruckCategory,
  setValue,
  setAxesNumber,
  errors,
}: TruckTypeSelectProps): JSX.Element => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value
    const selectedOption = truckOptions.find(option => option.value === selectedCategory)

    if (selectedOption) {
      const { label } = selectedOption
      const axesMatch = label.match(/\((\d+) eixos/)

      if (axesMatch) {
        const axesNumber = parseInt(axesMatch[1], 10)
        setAxesNumber(axesNumber)
        setValue('axesNumber', axesNumber)
      }

      setTruckCategory(e)
    }
  }

  return (
    <FormControl>
      <FormLabel>Selecione o tipo de caminhão</FormLabel>
      <Select
        placeholder="Selecione o tipo de caminhão"
        initialValue={value?.toString()}
        onChange={handleSelectChange}
        setValue={setValue}
        name="truckCategory"
        options={truckOptions}
        error={errors}
      />
      {errors?.truckType && <span>{errors.truckType}</span>}
    </FormControl>
  )
}
