import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import AppInfosType from '../../../types/AppInfosType'
import { formatDateInTime } from '../../../utils/dates/formatDateInTime'

export interface IRequestAppInfos {
  motorist_id: string
  freight_id: string
  has_motorist: boolean
}

async function fetchAppInfos({
  motorist_id,
  freight_id,
  has_motorist,
}: IRequestAppInfos): Promise<AppInfosType | undefined> {
  if (
    motorist_id &&
    freight_id &&
    has_motorist &&
    freight_id !== 'undefined' &&
    freight_id !== 'null' &&
    motorist_id !== 'undefined' &&
    motorist_id !== 'null'
  ) {
    const {
      data: { data },
    } = await apiServer.get<{ data: AppInfosType }>(`/motorists/app-infos`, {
      params: { motorist_id, freight_id },
    })

    if (data) {
      const appInfosFormatted = {
        id: data.id || '',
        motorist_id: data.motorist_id || '',
        freight_id: data.freight_id || '',
        first_install_at: data.first_install_at ? formatDateInTime(data.first_install_at) : 'Sem dados!',
        last_open_app_at: data.last_open_app_at ? formatDateInTime(data.last_open_app_at) : 'Sem dados!',
        last_open_my_freight_at: data.last_open_my_freight_at
          ? formatDateInTime(data.last_open_my_freight_at)
          : 'Sem dados!',
        created_at: formatDateInTime(data.created_at),
        updated_at: formatDateInTime(data.updated_at),
      }

      return appInfosFormatted
    }
  }
  return undefined
}

export function useGetAppInfos({
  motorist_id,
  freight_id,
  has_motorist,
}: IRequestAppInfos): UseQueryResult<AppInfosType> {
  return useQuery(
    ['app-infos', motorist_id, freight_id],
    () => fetchAppInfos({ motorist_id, freight_id, has_motorist }),
    {
      staleTime: 1000 * 60 * 10, // 10 minutes,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
