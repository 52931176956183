import { getClientBusinessGroup } from '../../../../services/endpoints/clients/getClientsBusinessGroupSegment'

export async function validateClientBusinessGroupOptions(name?: string): Promise<string | null> {
  const groups = await getClientBusinessGroup(name)
  const alreadyExistsName = groups.find(group => group.label === name)

  if (alreadyExistsName) {
    return 'Já existe um grupo com este nome'
  }

  return null
}
